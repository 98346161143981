import { Field } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const unifyFieldValue = (fields: any[]): Field[] => {
	if (!fields) return fields;

	const unified_fields = fields?.map((field) => {
		field.value = (field.simpleValue || field.objectValue || field.objectSetValue || field.value) ?? null;
		if (field.simpleValue === false) {
			field.value = false;
		}
		delete field.simpleValue;

		if (field.objectValue) {
			field.objectValue.fields = unifyFieldValue(field.objectValue?.fields);
		}
		delete field.objectValue;

		if (field.objectSetValue) {
			field.objectSetValue.map((object_container: any) => {
				object_container.fields = unifyFieldValue(object_container.fields);
			});
		}

		delete field.objectSetValue;

		return field;
	});

	return unified_fields;
};

export default unifyFieldValue;
