import { Typography } from '@mui/material';
import FilterInputBoolean from './FilterInputBoolean';
import FilterInputText from './FilterInputText';
import FilterInputSmartText from './FilterInputSmartText';
import FilterInputInteger from './FilterInputInteger';
import FilterInputSingleSelect from './FilterInputSingleSelect';
import FilterInputDocument from './FilterInputDocument'; // TODO: Add on backend filter for the document - if file available or not
import FilterInputDateSet from './FilterInputDateSet'; // TODO: Add on backend filter for the document - if file available or not
import FilterInputDate from './FilterInputDate';
import FilterInputDatetime from './FilterInputDatetime';
import FilterInputMultiSelect from './FilterInputMultiSelect';
import FilterInputDecimal from './FilterInputDecimal';
import FilterInputLocation from './FilterInputLocation';
import FilterInputMonetaryValue from './FilterInputMonetaryValue';
import { InputKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { FilterInputBaseProps } from './types';
import FilterInputAutoIncrementId from './FilterInputAutoIncrementId';
import FilterInputDocumentSet from './FilterInputDocumentSet';
import FilterInputImage from './FilterInputImage';
import FilterInputImageSet from './FilterInputImageSet';
import FilterInputSmartTextSetDeprecated from './FilterInputSmartTextSetDeprecated';
import FilterInputTextSetDeprecated from './FilterInputTextSetDeprecated';
import FilterInputSmartTextSet from './FilterInputSmartTextSet';
import FilterInputTextSet from './FilterInputTextSet';

interface FilterInputProps extends FilterInputBaseProps {
	fieldTechName: string;
	inputType: InputKind;
	inputOptions: any;
	optionsUiConfig: { label: string; value: string }[];
	useDeprecatedFilterInputs?: boolean;
}

const FilterInput = ({
	label,
	noLabel = false,
	fieldTechName,
	inputType,
	inputOptions,
	disabled,
	required,
	optionsUiConfig,
	useDeprecatedFilterInputs = true,
}: FilterInputProps) => {
	const commonProps = {
		label: label,
		techName: fieldTechName,
		disabled: disabled,
		noLabel: noLabel,
		required: required,
		useDeprecatedFilterInputs
	};

	if (InputKind.Text === inputType) return <FilterInputText {...commonProps} />;
	if (InputKind.SmartText === inputType) return <FilterInputSmartText {...commonProps} />;
	if (InputKind.TextSet === inputType) {
		if (useDeprecatedFilterInputs)
			return <FilterInputTextSetDeprecated {...commonProps} />;

		return <FilterInputTextSet {...commonProps} />

	}
	if (InputKind.SmartTextSet === inputType) {
		if (useDeprecatedFilterInputs)
			return <FilterInputSmartTextSetDeprecated {...commonProps} />;

		return <FilterInputSmartTextSet {...commonProps} />
	}

	if (InputKind.Integer === inputType)
		return (
			<FilterInputInteger
				{...commonProps}
				unit={inputOptions?.unit}
				min={inputOptions?.min_value}
				max={inputOptions?.max_value}
			/>
		);
	if (InputKind.AutoincId === inputType) return <FilterInputAutoIncrementId {...commonProps} />;

	if (InputKind.Decimal === inputType)
		return (
			<FilterInputDecimal
				{...commonProps}
				unit={inputOptions?.unit}
				min={inputOptions?.min_value}
				max={inputOptions?.max_value}
			/>
		);

	if (InputKind.Boolean === inputType) return <FilterInputBoolean {...commonProps} />;

	if (InputKind.Select === inputType)
		return (
			<FilterInputSingleSelect
				optionsUiConfig={optionsUiConfig}
				{...commonProps}
				options={inputOptions?.values}
			/>
		);

	if (InputKind.Document === inputType)
		return <FilterInputDocument name={fieldTechName} disabled={disabled} />;
	if (InputKind.DocumentSet === inputType)
		return <FilterInputDocumentSet name={fieldTechName} disabled={disabled} />;
	if (InputKind.Image === inputType)
		return <FilterInputImage name={fieldTechName} disabled={disabled} />;
	if (InputKind.ImageSet === inputType)
		return <FilterInputImageSet name={fieldTechName} disabled={disabled} />;

	if (InputKind.Date === inputType) return <FilterInputDate {...commonProps} />;
	if (InputKind.DateSet === inputType) return <FilterInputDateSet {...commonProps} options={inputOptions?.values} />;

	if (InputKind.MultiSelect === inputType)
		return (
			<FilterInputMultiSelect optionsUiConfig={optionsUiConfig} {...commonProps} options={inputOptions?.values} />
		);

	if (InputKind.MonetaryValue === inputType)
		return (
			<FilterInputMonetaryValue {...commonProps} min={inputOptions?.min_value} max={inputOptions?.max_value} />
		);

	if (InputKind.Datetime === inputType) return <FilterInputDatetime {...commonProps} />;

	if (InputKind.Location === inputType) return <FilterInputLocation {...commonProps} required={required} />;

	return <Typography>{`Filter input for ${inputType} is coming soon.`}</Typography>;
};

export default FilterInput;
