const ROUTES = {
	// DOMAIN
	DOMAIN: '/',
	// GENERAL
	NOT_FOUND_PAGE: '/public/not-found',
	PLATFORM_ERROR: '/platform-four-o-four',

	// // GENERAL
	LANDING_PAGE: '/public/welcome',
	ABOUT: '/public/about-us',
	CONTACT: '/public/contact',

	// we do not care if user is logged-in or not
	PUBLIC: '/public',
	// user is logged in, but not onboarded
	PRIVATE: '/private',
	// user is successfully onboarded
	PROTECTED: '/protected',

	AUTH: '/auth',

	// SECURITY
	// user cannot be logged in
	SIGN_IN: '/auth/sign-in',
	SIGN_UP: '/auth/sign-up',
	FORGOT_PASSWORD: '/auth/forgot-password',
	RESET_PASSWORD: '/auth/reset-password', // includes query param "data"
	VERIFY_USER: '/auth/verify-account', // includes query param "data" which encodes email, token, and marketplace_name
	SIGN_OUT: '/auth/sign-out',

	// CURRENT USER
	PARTICIPANT_REJECTED: '/private/me/participant-rejected',
	PARTICIPANT_ONBOARDING: '/private/me/participant-onboarding',

	MY_ACCOUNT: '/protected/account',
	PARTICIPANT_PROFILE: '/protected/account/me',
	PAYMENTS_AND_TRANSFERS: '/protected/account/payments-and-transfers',
	MY_CREDENTIALS: '/protected/account/credentials-and-security',

	MY_REFERRALS: '/protected/referrals',

	// SUPPLY
	SUPPLY_ONBOARDING: '/protected/supply-onboarding/:supplyId',
	MY_IMPORTS: '/protected/bulk-imports',
	UPDATE_MY_SUPPLY: '/protected/supplies/:supplyId/edit',
	MY_SUPPLIES: '/protected/supplies',
	CREATE_SUPPLY: '/protected/supplies?mode=create',
	MY_COLLECTIONS: '/protected/collections',
	UPDATE_MY_COLLECTION: '/protected/collections/:collectionId/edit',

	// MATCH
	CREATE_MATCH: '/protected/match/create',
	MATCH_FLOW: '/protected/match/flow/:matchId',

	MATCHING_TOOLS: '/protected/matching-tools/',
	HOSTED_CHECKOUT: '/protected/:transactionTypeTechName/checkout/:transactionId/:paymentId',
	BANK_TRANSFER_CHECKOUT: '/protected/:transactionTypeTechName/bank-transfer-checkout/:transactionId/:paymentId',

	PAYMENT_PROCESSING_FOR_TRANSACTION:
		'/protected/:transactionTypeTechName/payment-processing/:transactionId/:paymentId',

	// DISCOVERY
	HOME: '/public/search-home',
	DISCOVERED_TRANSACTION_SUPPLIES: '/public/consumer/browse-supplies/:transactionTypeTechName', // Includes filter params
	DISCOVERED_TRANSACTION_SUPPLY_DETAILS:
		'/public/consumer/discovered-supply/:transactionTypeTechName/details/:supplyId', // Includes filter params
	DISCOVERED_TRANSACTION_PROVIDERS: '/public/consumer/discover-supply-provider/:transactionTypeTechName', // Includes filter params
	DISCOVERED_TRANSACTION_PROVIDER_DETAILS:
		'/public/consumer/discovered-supply-provider/:transactionTypeTechName/details/:providerId', // Includes filter params

	//DASHBOARD
	DASHBOARD: '/protected/dashboard',

	//CONNECTIONS
	MY_CONNECTIONS: '/protected/connections/:connectionTechName',
	CONNECTION_DETAILS: '/protected/connections/:connectionTechName/details/:id',
	DISCOVER_CONNECTION_PARTICIPANTS: '/protected/connections/:connectionTechName/discovered-participants/',

	CREATE_REQUEST_REVERSE_TRANSACTION: '/protected/reverse-auction-transactions/:transactionTypeTechName/create',
	REVERSE_AUCTION_TRANSACTION_DETAILS:
		'/protected/reverse-auction-transactions/:transactionTypeTechName/details/:transactionId',

	// TRANSACTIONS
	MY_SINGLE_DIRECT_TRANSACTIONS: '/protected/single-direct-transactions/:transactionTypeTechName',
	SINGLE_DIRECT_TRANSACTION_DETAILS:
		'/protected/single-direct-transactions/:transactionTypeTechName/details/:transactionId',

	MY_TRANSACTIONS: '/protected/transactions',

	//SHOPPING CART
	SHOPPING_CART: '/protected/shopping-cart',
	MY_MULTI_DIRECT_TRANSACTIONS: '/protected/multi-direct-transactions/:transactionTypeTechName',
	MULTI_DIRECT_TRANSACTION_DETAILS:
		'/protected/multi-direct-transactions/:transactionTypeTechName/details/:transactionId',

	MY_MULTI_DIRECT_MATCHES: '/protected/multi-direct-matches/:transactionTypeTechName',
	MY_MULTI_DIRECT_MATCHES_DETAILS: '/protected/multi-direct-matches/:transactionTypeTechName/details/:transactionId',

	// OBJECTS
	MY_SHARED_OBJECTS: '/protected/my-shared-objects',
	UPDATE_MY_SHARED_OBJECT: '/protected/my-shared-objects/:id',
};

export default ROUTES;

export const ROUTES_WHITELIST = [
	ROUTES.LANDING_PAGE,
	ROUTES.CONTACT,
	ROUTES.ABOUT,
	ROUTES.SIGN_IN,
	ROUTES.SIGN_UP,
	ROUTES.VERIFY_USER,
	ROUTES.FORGOT_PASSWORD,
	ROUTES.RESET_PASSWORD,
];
