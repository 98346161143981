import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { Form } from 'react-final-form';

import { useParams } from 'react-router-dom';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import Grid from '@mui/material/Grid';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';

import PageWidth from '@rdv-fo/components/layout/PageWidth';
import {
	selectLoading,
	loadMyCollection,
	selectMyCollection,
	selectMySupplies,
	CollectionItem,
	selectMyCollectionItems,
	updateMyCollection,
	updateCollectionFailed,
	loadMySupplies,
} from '@rdv-fo/store/slices/supplySlice';
import ROUTES from '@rdv-fo/common/routes';
import { Button, Divider, Stack, Typography } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import FieldInputText from '@rdv-fo/components/fields/input/FieldInputText';
import CollectionItemsTable from '@rdv-fo/components/supply/collections/CollectionItemsTable';
import AddSupplyToCollectionDialog from '@rdv-fo/components/supply/collections/AddSupplyToCollectionDialog';
import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';
import { FieldCategoryKind, Supply } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { Edit } from '@mui/icons-material';

const mapSupplyToCollectionItem = (supplies: Supply[]): CollectionItem[] =>
	supplies?.reduce((arr: CollectionItem[], supply: Supply) => {
		const variantSpecificFieldValue = supply?.fields?.find(
			(field) => field.field_type?.is_variant_identifier
		)?.value;
		const newItem: CollectionItem = {
			id: supply.id,
			name: findFieldByCategory(supply?.fields, FieldCategoryKind.Name)?.value,
			image: findFieldByCategory(supply?.fields, FieldCategoryKind.MainImage)?.value,
			variantId: variantSpecificFieldValue,
		};
		if (supply.onboarded_at) arr.push(newItem);
		return arr;
	}, []) ?? [];

const UpdateMyCollectionPage = () => {
	const { collectionId } = useParams() as any;
	const dispatch = useAppDispatch();
	const collection = useAppSelector(selectMyCollection);
	const collectionItems = useAppSelector(selectMyCollectionItems);
	const supplies = useAppSelector(selectMySupplies);
	const loading = useAppSelector(selectLoading);

	const mySupplies = mapSupplyToCollectionItem(supplies);

	const [openAddSupplyToCollectionDialog, setOpenAddSupplyToCollectionDialog] = useState<boolean>(false);

	const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(false);

	const handleSubmitUpdateCollectionForm = async (values: any) => {
		const colletionItemIds = values.items?.map((ci: any) => ci.id);
		const resolvedAction = await dispatch(
			updateMyCollection({
				id: collectionId,
				name: values.name,
				ids_supply: values.itemOrderTouched ? colletionItemIds : undefined,
			})
		);
		if (resolvedAction.type === updateCollectionFailed.type) return resolvedAction;
	};

	const handleRemoveCollectionItem = async (supplyId: string) => {
		const colletionItemIdsWithoutRemovedSupply = collectionItems.reduce((acc, ci): any => {
			if (ci.id !== supplyId) return [...acc, ci.id];
			return acc;
		}, []);
		const resolvedAction = await dispatch(
			updateMyCollection({ id: collectionId, ids_supply: colletionItemIdsWithoutRemovedSupply })
		);
		if (resolvedAction.type === updateCollectionFailed.type) return resolvedAction;
	};
	const handleSubmitAddsSupplyToCollection = async (values: { supply_ids: string[] }) => {
		const resolvedAction = await dispatch(updateMyCollection({ id: collectionId, ids_supply: values.supply_ids }));
		if (resolvedAction.type === updateCollectionFailed.type) return resolvedAction;

		return setOpenAddSupplyToCollectionDialog(false);
	};

	const initFormValues = (collection: any, items: CollectionItem[]) => {
		return {
			name: collection?.name,
			items,
		};
	};

	useEffect(() => {
		dispatch(loadMyCollection({ id: collectionId }));
		const loadingSpinnerTimeout = setTimeout(() => {
			setLoadingSpinnerAllowed(true);
		}, 500);
		dispatch(loadMySupplies({}));

		return () => {
			clearTimeout(loadingSpinnerTimeout);
		};
	}, []);

	return loading ? (
		<Grid container sx={{ height: 'calc(100vh - 150px)' }}>
			{loadingSpinnerAllowed ? <LoadingIndicator loading={loading} /> : null}
		</Grid>
	) : (
		<PageWidth maxWidth="md">
			<AddSupplyToCollectionDialog
				selectedSupplies={collectionItems}
				open={openAddSupplyToCollectionDialog}
				supplies={mySupplies}
				onClose={() => setOpenAddSupplyToCollectionDialog(false)}
				onSubmit={handleSubmitAddsSupplyToCollection}
			/>
			<Form
				onSubmit={handleSubmitUpdateCollectionForm}
				initialValues={initFormValues(collection, collectionItems ?? [])}
			>
				{({ handleSubmit, submitting, pristine, values, form }) => {
					const itemOrderTouched = values.itemOrderTouched;
					return (
						<form onSubmit={handleSubmit} noValidate>
							<Stack direction="column" spacing={0}>
								<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
									<BackButton label="Collections" to={ROUTES.MY_COLLECTIONS} />
								</Stack>

								<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
									<Typography variant="h1" display="inline">
										Collection details
									</Typography>
									{/* 
// @ts-ignore */}
									<LoadingButton
										variant="contained"
										size="small"
										color="primary"
										startIcon={<SaveIcon />}
										type="submit"
										disabled={!itemOrderTouched && pristine}
										loading={submitting}
									>
										Save
									</LoadingButton>
								</Stack>
							</Stack>
							<Stack mt={4} direction="column" spacing={2} maxWidth={'50%'}>
								<FieldInputText
									techName="name"
									label={'Name'}
									disabled={submitting || loading}
									required={true}
								/>
							</Stack>
							<Stack mt={4} direction="column" spacing={2}>
								<Divider />
							</Stack>

							<Stack mt={4} direction="column" spacing={0}>
								<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
									<Typography variant="h4" display="inline">
										Items
									</Typography>
									<Button
										variant="text"
										size="small"
										color="primary"
										disabled={submitting || loading}
										startIcon={<Edit />}
										onClick={() => setOpenAddSupplyToCollectionDialog(true)}
									>
										Manage
									</Button>
								</Stack>
							</Stack>
							<Stack mt={4} direction="column" spacing={0}>
								<CollectionItemsTable
									form={form}
									items={values.items}
									onRemove={handleRemoveCollectionItem}
								/>
							</Stack>
						</form>
					);
				}}
			</Form>
		</PageWidth>
	);
};

export default UpdateMyCollectionPage;
