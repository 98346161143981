import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface FieldValueImageSetProps {
    value: any;
    className: string;
}

const FieldValueImageSet = ({ value, className }: FieldValueImageSetProps) => {
    let unavailable = value === null || value === undefined || value?.length == 0;

    return (
        <Grid container spacing={1} direction='column'>
            {unavailable ? (
                <Typography className={className}>
                    <b>n.a.</b>
                </Typography>
            ) : (
                <>
                    {value?.map((image: any) => (
                        <Grid item>
                            <img
                                key={image.id}
                                src={image?.url}
                                alt={''}
                                style={{ maxWidth: 200, maxHeight: 200, objectFit: 'scale-down' }}
                            />
                        </Grid>
                    ))}
                </>
            )}
        </Grid>
    );
};

export default FieldValueImageSet;
