import { useLocation } from 'react-router-dom';

import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { getIsMarketplaceDomainLocation } from '@rdv-fo/app/lib/url';
import { useEffect } from 'react';
import { replaceCurrentRouteWith } from '@rdv-fo/store/slices/uiSlice';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import isParticipantOnboarded from '@rdv-fo/services/randevuApi/helpers/isParticipantOnboarded';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { selectPlatformName } from '@rdv-fo/store/slices/platformSlice';

const DomainPage = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const platformName = useAppSelector(selectCurrentUser);
	const currentUser = useAppSelector(selectPlatformName);
	const marketplaceNameFromPath = getIsMarketplaceDomainLocation(location.pathname)
		? location.pathname.slice(1)
		: null;

	const marketplaceName = marketplaceNameFromPath || platformName;

	useEffect(() => {
		if (currentUser && !isParticipantOnboarded(currentUser))
			return dispatch(replaceCurrentRouteWith(routeBuilder(ROUTES.PARTICIPANT_ONBOARDING)));

		if (marketplaceName) {
			dispatch(replaceCurrentRouteWith(routeBuilder(ROUTES.LANDING_PAGE)));
		}
	}, [marketplaceName, dispatch, marketplaceNameFromPath, currentUser]);

	return null;
};

export default DomainPage;
