import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, DateTimePicker, Switches } from 'mui-rff';
import SearchIcon from '@mui/icons-material/Search';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FilterInput from '@rdv-fo/components/fields/filter/FilterInput';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import { Accordion, AccordionSummary, AccordionDetails } from './common/RandevuAccordion';
import { AvailabilityManagementKind, MatchingUnitKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { AVAILABILITY_QUERY_PARAMS } from '@rdv-fo/app/lib/discovery';
import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';

const parseDate = (value) => {
	if (value === undefined || value === null) return null;

	return value?.toISOString().split('T')[0];
};

const formatDate = (value) => {
	if (value === undefined || value === null) return null;
	return new Date(value);
};

const parseDatetime = (value) => ([null, undefined, ''].includes(value) ? null : value?.toISOString());
const formatDatetime = (value) => ([null, undefined, ''].includes(value) ? null : new Date(value));

const FiltersContainer = ({
	supplyFilters, // FieldType[]
	disabled,
	showInputs, // FIXME: probably is this one loading?
	showClearBtn,
	matchingUnitType, // FIXME: SET PROPER TYPE
	onClearFilterInputs,
	availabilityManagementKind,
	onDiscover,
	numberOfSelectedFilters,
	useDeprecatedFilterInputs = true,
}) => {
	const sxAccordion = {
		backgroundColor: 'white',
		borderWidth: 1,
		borderRadius: 2.5,
		borderStyle: 'solid',
		borderColor: RDV_NEUTRAL_20,
	};

	return (
		<>
			{showInputs ? (
				<>
					<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
						<Typography variant="h3">
							{numberOfSelectedFilters > 0 ? `Filters (${numberOfSelectedFilters})` : 'Filters'}
						</Typography>
						<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
							{MatchingUnitKind.PerDay === matchingUnitType && (
								<Accordion elevation={0}>
									<AccordionSummary
										sx={sxAccordion}
										expandIcon={<ExpandMoreIcon sx={{ color: 'black', width: 20, height: 20 }} />}
									>
										<Typography variant="body1">Dates</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 1 }}>
										<Stack
											direction="column"
											justifyContent="flex-start"
											alignItems="stretch"
											spacing={2}
										>
											<LocalizationProvider dateAdapter={DateFnsUtils}>
												<DatePicker
													sx={{ marginRight: 2 }}
													name={AVAILABILITY_QUERY_PARAMS.START_DATE}
													margin="dense"
													InputProps={{ onKeyDown: (e) => e.preventDefault() }}
													size="small"
													inputFormat="dd. MMM yyyy"
													fullWidth
													fieldProps={{
														parse: parseDate,
														format: formatDate,
														inputVariant: 'outlined',
														clearable: true,
														placeholder: 'From',
													}}
												/>
											</LocalizationProvider>
											<LocalizationProvider dateAdapter={DateFnsUtils}>
												<DatePicker
													name={AVAILABILITY_QUERY_PARAMS.END_DATE}
													margin="dense"
													size="small"
													InputProps={{ onKeyDown: (e) => e.preventDefault() }}
													inputFormat="dd. MMM yyyy"
													fullWidth
													fieldProps={{
														parse: parseDate,
														format: formatDate,
														inputVariant: 'outlined',
														clearable: true,
														placeholder: 'To',
													}}
												/>
											</LocalizationProvider>
										</Stack>
									</AccordionDetails>
								</Accordion>
							)}
							{MatchingUnitKind.PerHour === matchingUnitType && (
								<Accordion elevation={0}>
									<AccordionSummary
										sx={sxAccordion}
										expandIcon={<ExpandMoreIcon sx={{ color: 'black', width: 20, height: 20 }} />}
									>
										<Typography variant="body1">Times</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 1 }}>
										<Stack
											direction="column"
											justifyContent="flex-start"
											alignItems="stretch"
											spacing={2}
										>
											<LocalizationProvider dateAdapter={DateFnsUtils}>
												<DateTimePicker
													sx={{ marginRight: 2 }}
													name={AVAILABILITY_QUERY_PARAMS.START_DATE_TIME}
													variant="inline"
													margin="dense"
													size="small"
													InputProps={{ onKeyDown: (e) => e.preventDefault() }}
													fullWidth
													inputFormat="dd/MM/yyyy hh:mm a"
													fieldProps={{
														inputVariant: 'outlined',
														clearable: true,
														placeholder: 'From',
														parse: parseDatetime,
														format: formatDatetime,
													}}
												/>
											</LocalizationProvider>
											<LocalizationProvider dateAdapter={DateFnsUtils}>
												<DateTimePicker
													name={AVAILABILITY_QUERY_PARAMS.END_DATE_TIME}
													variant="inline"
													margin="dense"
													size="small"
													InputProps={{ onKeyDown: (e) => e.preventDefault() }}
													fullWidth
													inputFormat="dd/MM/yyyy hh:mm a"
													fieldProps={{
														inputVariant: 'outlined',
														clearable: true,
														placeholder: 'To',
														parse: parseDatetime,
														format: formatDatetime,
													}}
												/>
											</LocalizationProvider>
										</Stack>
									</AccordionDetails>
								</Accordion>
							)}

							{supplyFilters?.map((field_type) => {
								return (
									<Accordion key={field_type.tech_name} elevation={0}>
										<AccordionSummary
											sx={sxAccordion}
											expandIcon={
												<ExpandMoreIcon sx={{ color: 'black', width: 20, height: 20 }} />
											}
										>
											<Typography variant="body1">
												{field_type?.ui_config?.label ?? field_type.name}
											</Typography>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 1 }}>
											<Grid container>
												<FilterInput
													label={field_type?.ui_config?.label ?? field_type?.name}
													noLabel={true}
													optionsUiConfig={field_type?.ui_config?.options}
													fieldTechName={field_type?.tech_name}
													inputType={field_type?.input_type}
													inputOptions={field_type?.input_options}
													required={field_type?.is_required}
													disabled={disabled}
													useDeprecatedFilterInputs={useDeprecatedFilterInputs}
												/>
											</Grid>
										</AccordionDetails>
									</Accordion>
								);
							})}
						</Stack>

						{availabilityManagementKind === AvailabilityManagementKind.Default && (
							<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
								<Switches
									name="rdv_available_supply_only"
									data={[{ label: 'Show only available', value: true }]}
								/>
							</Stack>
						)}
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
							{showClearBtn && (
								<Button
									margin="dense"
									sx={{ fontWeight: 600 }}
									variant="text"
									color="inherit"
									onClick={onClearFilterInputs}
								>
									Clear all
								</Button>
							)}

							<Button
								margin="dense"
								variant="contained"
								color="primary"
								onClick={onDiscover}
								type="submit"
								startIcon={<SearchIcon />}
							>
								Search
							</Button>
						</Stack>
					</Stack>
				</>
			) : (
				<LoadingIndicator loading={!showInputs} height={15} />
			)}
		</>
	);
};

export default FiltersContainer;
