import Typography from '@mui/material/Typography';

interface FieldValueSingleSelectProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
	optionsUiConfig: { label: string, value: string }[];
}
const FieldValueSingleSelect = ({ fieldTechName, value, className, optionsUiConfig }: FieldValueSingleSelectProps) => {
	value = optionsUiConfig?.find((option) => option.value === value)?.label ?? value

	return (
		<Typography id={fieldTechName} className={className}>
			{value === null ? 'n.a.' : value}
		</Typography>
	);
};

export default FieldValueSingleSelect;
