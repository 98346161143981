import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import GoogleMapReact from 'google-map-react';
import { useHistory, useLocation, useParams } from 'react-router';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import {
	selectLoading,
	discoverTransactionProviderDetails,
	selectDiscoveredProviderDetails,
	discoverTransactionSupplies,
	selectLoadingProvider,
	selectDiscoveredProviderSupplies,
} from '@rdv-fo/store/slices/discoverySlice';
import { selectToken } from '@rdv-fo/store/slices/authSlice';
import GeneralLayout from '@rdv-fo/components/layout/types/GeneralLayout';
import PageWidth from '@rdv-fo/components/layout/PageWidth';
import FieldDisplayValue from '@rdv-fo/components/fields/display/FieldDisplayValue';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import CardMedia from '@mui/material/CardMedia';
import {
	SupplyCollection,
	Field,
	FieldCategoryKind,
	InputKind, DiscoveredSupply
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';

import RoomIcon from '@mui/icons-material/Room';

import BackButton from '@rdv-fo/components/common/BackButton';
import SupplyCard from '@rdv-fo/components/supply/SupplyCard';
import ConditionalFieldDisplay from '@rdv-fo/components/fields/display/ConditionalFieldDisplay';
import CollectionCard from '@rdv-fo/components/supply/collections/CollectionCard';
import DiscoveredSupplies from '@rdv-fo/components/discovery/DiscoveredSupplies';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

type QueryParams = {
	transactionTypeTechName: string;
	providerId: string;
};

const DiscoveredTransactionProviderDetailsPage = () => {
	const { providerId, transactionTypeTechName } = useParams<QueryParams>();
	const location = useLocation();

	const { selectedCollectionIdParam } = usePrimitiveQueryParams() as any;
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));
	const token = useAppSelector(selectToken);
	const discovering = useAppSelector(selectLoading);
	const discoveringProvider = useAppSelector(selectLoadingProvider);
	const discoveredProvider = useAppSelector(selectDiscoveredProviderDetails);
	const providerSupplies = useAppSelector(selectDiscoveredProviderSupplies);
	const history = useHistory();
	const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(false);

	const [selectedCollectionId, setSelectedCollectionId] = useState<string | undefined>(selectedCollectionIdParam);

	const providerCollections = discoveredProvider?.collections ?? [];

	if (!googleMapsApiKey) console.error('No google maps api key found');

	useEffect(() => {
		if (token) {
			dispatch(
				discoverTransactionProviderDetails({
					id_participant: providerId,
					transaction_tech_name: transactionTypeTechName,
				})
			);
		}
	}, [providerId, dispatch, token, transactionTypeTechName]);

	useEffect(() => {
		if (selectedCollectionId) {
			dispatch(
				discoverTransactionSupplies({
					transaction_tech_name: transactionTypeTechName,
					where: {
						ids_collections: [selectedCollectionId],
					},
				})
			);
			history.replace({ search: `?selectedCollectionIdParam=${selectedCollectionId}` });
		}
	}, [selectedCollectionId, selectedCollectionIdParam]);

	useEffect(() => {
		setTimeout(() => {
			setLoadingSpinnerAllowed(true);
		}, 500);
	}, []);

	const fieldName = findFieldByCategory(discoveredProvider?.fields, FieldCategoryKind.Name);
	const fieldDescription = findFieldByCategory(discoveredProvider?.fields, FieldCategoryKind.Description);
	const fieldMainImage = findFieldByCategory(discoveredProvider?.fields, FieldCategoryKind.MainImage);
	const fieldLocation = discoveredProvider?.fields.find(
		(field: Field) => field.field_type.input_type === InputKind.Location
	);

	const dominantFields = [
		fieldDescription?.field_type?.tech_name,
		fieldName?.field_type?.tech_name,
		fieldMainImage?.field_type?.tech_name,
		fieldLocation?.field_type?.tech_name,
	].filter((tech_name: string | undefined) => tech_name);

	const discoveredProviderFilteredFields = discoveredProvider?.fields.filter(
		(field: Field) => !dominantFields.includes(field.field_type.tech_name)
	);

	const discoveredProviderFilteredFieldValues = {};
	discoveredProviderFilteredFields?.forEach((field) =>
		Object.assign(discoveredProviderFilteredFieldValues, { [field?.field_type?.tech_name]: field?.value })
	);

	const MapMarker = () => (
		<RoomIcon sx={{ color: theme.palette.secondary.main, marginLeft: '-10px', marginTop: '-10px' }} />
	);

	const mainImageAvailable = fieldMainImage?.value?.url !== undefined;

	const handleToggleCollection = (id: string) => {
		if (selectedCollectionId !== id) {
			setSelectedCollectionId(id);
			return;
		}

		if (selectedCollectionId === id) {
			const queryParams = new URLSearchParams(location.search);

			queryParams.delete('selectedCollectionIdParam');
			history.replace({
				search: queryParams.toString(),
			});

			setSelectedCollectionId(undefined);
			return;
		}
	};

	return (
		<GeneralLayout>
			<PageWidth paddingTop={Number(theme.spacing(2))} paddingBottom={Number(theme.spacing(2))}>
				{discoveringProvider ? (
					<Grid container sx={{ height: 'calc(100vh - 64px)' }}>
						{loadingSpinnerAllowed ? <LoadingIndicator loading={discoveringProvider} /> : null}
					</Grid>
				) : (
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="stretch"
						spacing={4}
						sx={{ pl: { xs: 2 }, pr: { xs: 2 } }}
					>
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="stretch"
							spacing={2}
							sx={{
								mt: 4,
							}}
						>
							<Box>
								<BackButton label="Back" toVariant="native" />
							</Box>

							{mainImageAvailable && (
								<CardMedia
									component="img"
									sx={{
										height: '100%',
										width: '100%',
										objectFit: 'contain',
									}}
									image={fieldMainImage?.value?.url}
									alt={fieldName?.value}
								/>
							)}

							<Typography component="h1" variant="h1">
								{fieldName?.value ?? 'Provider details'}
							</Typography>

							<Typography variant="body1">{fieldDescription?.value}</Typography>

							{fieldLocation?.value && (
								<Box sx={{ height: 80, borderRadius: 1.5, width: '100%' }}>
									<GoogleMapReact
										yesIWantToUseGoogleMapApiInternals
										bootstrapURLKeys={{
											key: googleMapsApiKey ?? 'missing_google_maps_key',
											libraries: ['places'],
										}}
										defaultCenter={{
											lat: fieldLocation?.value?.lat,
											lng: fieldLocation?.value?.lng,
										}}
										defaultZoom={14}
									>
										<MapMarker />
									</GoogleMapReact>
								</Box>
							)}

							<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0.5}>
								{discoveredProviderFilteredFields?.map((field: Field) => {
									return (
										<ConditionalFieldDisplay
											key={field.field_type.tech_name}
											fields={discoveredProviderFilteredFields}
											values={discoveredProviderFilteredFieldValues}
											conditions={field?.field_type?.conditions ?? []}
										>
											<FieldDisplayValue
												label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
												labelProps={{
													width: isScreenSmall ? 4 : 2,
												}}
												optionsUiConfig={field?.field_type?.ui_config?.options ?? []}
												fieldTechName={field?.field_type.tech_name}
												inputType={field?.field_type.input_type}
												inputOptions={field?.field_type.input_options}
												value={field.value}
											/>
										</ConditionalFieldDisplay>
									);
								})}
							</Stack>
						</Stack>

						{providerCollections?.length > 0 && (
							<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={4}>
								<Typography component="h2" variant="h2">
									Collections
								</Typography>
								<Box>
									<Grid container spacing={4} width={'100%'}>
										{providerCollections?.map((collection: SupplyCollection) => {
											return (
												<Grid item key={collection.id} xs={12} sm={6} md={2.4}>
													<CollectionCard
														id={collection.id}
														name={collection.name}
														onClick={handleToggleCollection}
														numberOfItems={collection.supplies_count ?? 0}
														isSelected={collection.id === selectedCollectionId}
													/>
												</Grid>
											);
										})}
									</Grid>
								</Box>
							</Stack>
						)}
						{selectedCollectionId && (
							<>
								<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={4}>
									<Typography component="h2" variant="h2">
										Catalog
									</Typography>
									<Box>
										{discovering && (
											<Grid container sx={{ height: 'calc(100vh - 64px)' }}>
												{loadingSpinnerAllowed && (
													<LoadingIndicator loading={discoveringProvider} />
												)}
											</Grid>
										)}
										{!discovering && (
											<DiscoveredSupplies transactionTypeTechName={transactionTypeTechName} />
										)}
									</Box>
								</Stack>
							</>
						)}

						{!selectedCollectionId && providerSupplies?.length > 0 && (
							<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={4}>
								<Typography component="h2" variant="h2">
									Catalog
								</Typography>
								<Box>
									<Grid container spacing={4}>
										{providerSupplies.map((supply: DiscoveredSupply) => {
											return (
												<Grid item key={supply.id} xs={12} sm={6} md={4}>
													<SupplyCard
														supply={supply}
														transactionTypeTechName={transactionTypeTechName}
													/>
												</Grid>
											);
										})}
									</Grid>
								</Box>
							</Stack>
						)}
					</Stack>
				)}
			</PageWidth>
		</GeneralLayout>
	);
};

export default DiscoveredTransactionProviderDetailsPage;
