import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { Box, Stack, Skeleton, Button } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import { useState } from 'react';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from 'react-router-dom';

import {
	loadMySupplies,
	selectMySupplies,
	selectLoading,
	selectMySupplyTypes,
	createSupply,
	createSupplyFailed,
} from '@rdv-fo/store/slices/supplySlice';
import SuppliesTable from '@rdv-fo/components/supply/SuppliesTable';
import CreateSupplyDialog, { CreateSupplyFormValues } from '@rdv-fo/components/supply/CreateSupplyDialog';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import SupplyStatusTabs from '@rdv-fo/components/supply/SupplyStatusTabs';
import { SupplyMappers, SupplyStatusTab, VALID_SUPPLY_QUERY_PARAM_STATUS } from '@rdv-fo/components/supply/supplyHelpers';

const MySuppliesPage = () => {
	const { mode, status } = usePrimitiveQueryParams() as any;
	const sharedObjects = useAppSelector(selectMySharedObjects);
	const dispatch = useAppDispatch();
	const mySupplies = useAppSelector(selectMySupplies);
	const mySupplyTypes = useAppSelector(selectMySupplyTypes);
	const loading = useAppSelector(selectLoading);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);

	const bulkImportEnabled = mySupplyTypes?.find((st) => st.bulk_import_enabled) !== undefined;
	const [openCreateSupplyDialog, setOpenCreateSupplyDialog] = useState(false);

	const [selectedStatusTab, setSelectedStatusTab] = useState(status ?? SupplyStatusTab.enabled);

	const handleSubmitCreateSupplyForm = async (values: CreateSupplyFormValues) => {
		const resolvedAction = await dispatch(
			createSupply({ tech_name: values.tech_name, fields: [{ tech_name: 'name', value: values.name }] })
		);

		if (resolvedAction.type === createSupplyFailed.type) return resolvedAction.payload;
	};

	useEffect(() => {
		let selectedTab = 'enabled' as string;
		if (status && typeof status === 'string') {
			if (VALID_SUPPLY_QUERY_PARAM_STATUS.includes(status)) selectedTab = status;
		}

		setSelectedStatusTab(selectedTab as SupplyStatusTab);
	}, [status]);

	useEffect(() => {
		const filter = SupplyMappers.toSupplyStatusFilter(selectedStatusTab) ?? {};
		dispatch(loadMySupplies({ where: filter }));

		if (mode === 'create') {
			setOpenCreateSupplyDialog(true);
		}
	}, [dispatch, mode, selectedStatusTab]);

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
				<Box>
					<BackButton />
				</Box>

				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<Typography component="h1" variant="h1">
						Manage supplies
					</Typography>
					{!loading && (
						<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
							{bulkImportEnabled && (
								<Button
									color="primary"
									variant="text"
									size="medium"
									startIcon={<UploadOutlinedIcon />}
									component={RouterLink}
									to={routeBuilder(ROUTES.MY_IMPORTS)}
								>
									Add via upload
								</Button>
							)}
							<Button
								color="primary"
								variant="contained"
								size="medium"
								startIcon={<AddIcon />}
								onClick={() => setOpenCreateSupplyDialog(true)}
							>
								Add new
							</Button>
						</Stack>
					)}
				</Stack>
			</Stack>
			<Box sx={{ mt: 5 }}>
				{loading ? (
					<Stack direction="column" sx={{ pt: 4 }}>
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
					</Stack>
				) : (
					<>
						<SupplyStatusTabs
							selectedTab={selectedStatusTab}
						/>
						<CreateSupplyDialog
							sharedObjects={sharedObjects}
							supplyTypes={mySupplyTypes}
							open={openCreateSupplyDialog}
							sharedObjectTypes={sharedObjectTypes}
							onClose={() => setOpenCreateSupplyDialog(false)}
							onSubmit={handleSubmitCreateSupplyForm}
						/>
						<SuppliesTable supplies={mySupplies} onAdd={() => alert('Cooming soon')} />
					</>
				)}
			</Box>
		</>
	);
};

export default MySuppliesPage;
