export const SUPPLY_STATUS = {
	ONBOARDING: {
		value: 'ONBOARDING',
		label: 'Onboarding',
	},
	ONBOARDED: {
		value: 'ONBOARDED',
		label: 'Onboarded',
	},

	REJECTED: {
		value: 'REJECTED',
		label: 'Rejected',
	},
};

export default SUPPLY_STATUS;
