import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Box, Skeleton, Divider, useMediaQuery } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import ROUTES from '@rdv-fo/common/routes';
import { RdvTab, RdvTabs } from '@rdv-fo/components/common/tabs/RdvTabs';
import { useEffect, useState } from 'react';
import { RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';

import {
	loadMySupplyTypes,
	selectMySupplyTypes,
	loadMyImportJobReports,
	selectMyImportJobReports,
	selectLoading,
	selectLoadingImportReports,
	requestSupplyDataImportJob,
	requestSupplyDataImportJobFailed,
} from '@rdv-fo/store/slices/supplySlice';
import { useHistory } from 'react-router';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import ImportSupplyDataForm, {
	ImportSupplyDataFormValues,
} from '@rdv-fo/components/supply/import/ImportSupplyDataForm';
import ImportJobReportsTable from '@rdv-fo/components/supply/import/ImportJobReportsTable';
import { useTheme } from '@emotion/react';
import ImportJobReportCard from '@rdv-fo/components/supply/import/ImportJobReportCard';
import ImportTemplateOptions from '@rdv-fo/components/supply/import/ImportTemplateOptions';
import { downloadAssetToHardDisk } from '@rdv-fo/app/lib/downloadAssetsToHardDisk';
import { sendErrorToasty } from '@rdv-fo/store/slices/uiSlice';

interface MyImportsPageQueryParams {
	tab: 'templates' | 'upload' | 'reports';
}

const MyImportsPage = () => {
	const theme = useTheme() as any; // FIXME: @Rokva set proper TS Type for the theme object
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { tab } = usePrimitiveQueryParams() as MyImportsPageQueryParams;
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const mySupplyTypes = useAppSelector(selectMySupplyTypes);
	const importReports = useAppSelector(selectMyImportJobReports);
	const loadingSupplyTypes = useAppSelector(selectLoading);
	const loadingImports = useAppSelector(selectLoadingImportReports);
	const loading = loadingImports || loadingSupplyTypes;

	const [selectedTab, setSelectedTab] = useState<'templates' | 'upload' | 'reports'>('templates');

	useEffect(() => {
		dispatch(loadMyImportJobReports());
		dispatch(loadMySupplyTypes());
	}, [dispatch]);

	useEffect(() => {
		if (!tab) return history.replace(`${ROUTES.MY_IMPORTS}?tab=templates`);

		setSelectedTab(tab);
	}, [tab, dispatch, setSelectedTab, history]);

	const reportsFound = importReports?.length;

	const handleSubmitUploadSupplyData = async (values: ImportSupplyDataFormValues) => {
		const resolvedAction = await dispatch(
			requestSupplyDataImportJob({
				supply_tech_name: values.selected_supply_tech_name,
				id_template: values.id_template,
				auto_activate_supplies: values.auto_activate_supplies,
				data_file: values.data_file,
			})
		);

		if (resolvedAction.type === requestSupplyDataImportJobFailed.type) return resolvedAction.payload;

		history.push(`${ROUTES.MY_IMPORTS}?tab=reports`);
	};

	const handleDownloadFile = async (url: string, physicalFileName: string) => {
		const response = await downloadAssetToHardDisk(url, physicalFileName);

		if (response instanceof Error) dispatch(sendErrorToasty(response.message));
	};

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<BackButton label="Manage supplies" to={ROUTES.MY_SUPPLIES} />
				<Typography component="h1" variant="h1">
					Bulk uploads
				</Typography>
			</Stack>

			<Box sx={{ mt: 5 }}>
				<Stack direction="row">
					<RdvTabs value={selectedTab ?? 'templates'}>
						<RdvTab
							value="templates"
							label={'Download spreadsheet templates'}
							onClick={() => {
								history.push(`${ROUTES.MY_IMPORTS}?tab=templates`);
							}}
						/>

						<RdvTab
							value="upload"
							label={'Upload spreadsheets'}
							onClick={() => {
								history.push(`${ROUTES.MY_IMPORTS}?tab=upload`);
							}}
						/>
						<RdvTab
							value="reports"
							label={'Import status report'}
							onClick={() => {
								history.push(`${ROUTES.MY_IMPORTS}?tab=reports`);
							}}
						/>
					</RdvTabs>
				</Stack>
				<Divider sx={{ color: RDV_NEUTRAL_20, mb: 4 }} />
				{loading && (
					<Stack direction="column" sx={{ pt: 4 }}>
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
					</Stack>
				)}
				{selectedTab === 'templates' && (
					<ImportTemplateOptions supplyTypes={mySupplyTypes} onDownload={handleDownloadFile} />
				)}
				{selectedTab === 'upload' && (
					<ImportSupplyDataForm supplyTypes={mySupplyTypes} onSubmit={handleSubmitUploadSupplyData} />
				)}
				{selectedTab === 'reports' && (
					<>
						{reportsFound ? (
							<>
								{!isMobileScreen && (
									<ImportJobReportsTable items={importReports} onDownloadFile={handleDownloadFile} />
								)}
								{isMobileScreen && (
									<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
										{importReports?.map((report) => (
											<ImportJobReportCard
												key={report.id}
												jobId={report.id}
												finishedAt={report.finished_at}
												startedAt={report.started_at}
												requestedAt={report.requested_at}
												reportUrl={report.report?.url}
												reportName={report.report?.name + '.xlsx'}
												templateVersion={report.template?.name}
												dataName={report?.data?.name}
												dataUrl={report?.data?.url}
												totalRecords={report.total_number_of_records ?? undefined}
												successfulRecords={report.records_processed_successfully ?? undefined}
												onDownloadFile={handleDownloadFile}
											/>
										))}
									</Stack>
								)}
							</>
						) : (
							<Stack
								direction="column"
								justifyContent="center"
								alignItems="center"
								spacing={2}
								sx={{ mt: 6 }}
							>
								<Typography variant="subtitle1">No import status reports yet</Typography>
								<Typography variant="body2">Your import reports will appear here</Typography>
							</Stack>
						)}
					</>
				)}
			</Box>
		</>
	);
};

export default MyImportsPage;
