import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DiscoverSupplySearchBar from '../discovery/DiscoverSupplySearchBar';
import ProviderDashboardBar from '../ProviderDashboardBar';
import ReverseAuctionRequestDashboardBar from '../ReverseAuctionRequestDashboardBar';
import PageWidth from '../layout/PageWidth';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import { selectGlobalUiConfig, selectLandingPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { selectDefaultTransactionType } from '@rdv-fo/store/slices/transactionSlice';
import { MatchTypeRoleKind, TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

import { ASSETS } from '@rdv-fo/app/assets';
import { Box, Stack, useTheme } from '@mui/material';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		heroSection: {
			[theme.breakpoints.up('xs')]: {
				height: '700px',
			},
			[theme.breakpoints.up('sm')]: {
				height: '620px',
			},
			[theme.breakpoints.up('md')]: {
				height: '600px',
			},
			[theme.breakpoints.up('lg')]: {
				height: '700px',
			},
			[theme.breakpoints.up('xl')]: {
				height: '1000px',
			},
		},
		heroWrapper: {
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			position: 'relative',
			width: '100%',
			height: '100%',
			display: 'flex',
		},
		heroContent: {
			paddingLeft: 0,
			paddingRight: 0,
			height: '100%',
		},

		item: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			padding: 0,
		},
	};
});

const HeroSection = ({ width }) => {
	const classes = useStyles();

	const landing_page_config = useAppSelector(selectLandingPageConfig);
	const global_ui_config = useAppSelector(selectGlobalUiConfig);
	const currentUser = useAppSelector(selectCurrentUser);
	const defaultTransactionType = useAppSelector(selectDefaultTransactionType);

	const defaultTTIsDirectMatcher = defaultTransactionType?.type === TransactionKind.SingleDirect;
	const defaultTransactionTypeIsReverseAuction = defaultTransactionType?.type === TransactionKind.ReverseAuction;
	const isProvider = defaultTransactionType?.my_role.includes(MatchTypeRoleKind.Provider);
	const isConsumer = defaultTransactionType?.my_role?.includes(MatchTypeRoleKind.Consumer);
	const isGuestAllowedForDefaultTT = defaultTransactionType?.my_role === MatchTypeRoleKind.Guest;
	const isDiscoveryAllowed = defaultTransactionType?.root_type?.match_type?.supply_discovery_enabled;

	const heroImage = landing_page_config?.hero?.image_url;

	const isSmallScreen = /xs|sm|md/.test(width);
	let reversibleGridProps = {
		direction: isSmallScreen ? 'column-reverse' : 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	};

	let renderSecondarySlogan = global_ui_config?.secondary_slogan !== 'undefined';

	const renderDashboardBarComponent = () => {
		// User is logged in
		if (currentUser) {
			if (isDiscoveryAllowed && defaultTTIsDirectMatcher && isConsumer) return <DiscoverSupplySearchBar />;
			if (defaultTransactionTypeIsReverseAuction && isConsumer)
				return (
					<ReverseAuctionRequestDashboardBar transactionTypeTechName={defaultTransactionType?.tech_name} />
				);
			if (isProvider) return <ProviderDashboardBar />;
		} else {
			// Not logged in
			if (isGuestAllowedForDefaultTT && defaultTTIsDirectMatcher && isDiscoveryAllowed)
				return <DiscoverSupplySearchBar />;
		}
		return null;
	};

	return (
		<>
			<section id="hero-section" className={classes.heroSection}>
				<Box
					id="hero-wrapper"
					className={classes.heroWrapper}
					sx={{
						backgroundImage: {
							md: `url(${heroImage ? heroImage : ASSETS.RANDEVU.LANDING_PAGE_HERO_IMAGE})`,
						},
					}}
				>
					<PageWidth>
						<Grid
							container
							direction="column"
							spacing={4}
							className={classes.heroContent}
							{...reversibleGridProps}
							alignContent="space-around"
							alignItems="center"
						>
							<Grid
								item
								xs={12}
								sm={6}
								sx={{
									height: { md: '100%' },
									mt: {
										sm: 15,
									},
								}}
								className={classes.item}
							>
								<Stack spacing={4} sx={{ p: { xs: 4, sm: 4 } }}>
									<Typography component="h1" variant="h1" color="primary">
										{global_ui_config?.primary_slogan}
									</Typography>
									{renderSecondarySlogan && (
										<Typography component="h2" variant="h4" color="secondary">
											{global_ui_config?.secondary_slogan}
										</Typography>
									)}

									{renderDashboardBarComponent()}
								</Stack>
							</Grid>
						</Grid>
					</PageWidth>
				</Box>
			</section>
		</>
	);
};

export default HeroSection;
