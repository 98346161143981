import React from 'react';
import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';

import SupplyOnboardingForm from '@rdv-fo/components/supply/SupplyOnboardingForm';

const SupplyOnboardingPage = () => {
	let { supplyId } = useParams();

	return (
		<>
			<Container maxWidth="md" fixed>
				<SupplyOnboardingForm supplyId={supplyId} />
			</Container>
		</>
	);
};

export default SupplyOnboardingPage;
