import { Grid, Typography } from '@mui/material';
import React from 'react'

interface FieldValueDocumentSetProps {
    value: any;
    className: string;
    fieldTechName: string;
}

const FieldValueDocumentSet = ({ value, className, fieldTechName }: FieldValueDocumentSetProps) => {
    let unavailable = value === null || value === undefined || value?.length == 0;

    return (
        <Grid container spacing={1} direction='column'>
            {unavailable ? (
                <Typography className={className}>
                    <b>n.a.</b>
                </Typography>
            ) : (
                <>
                    {value?.map((value: any) => (
                        <Grid item>
                            <Typography
                                id={fieldTechName}
                                className={className}
                                component="a"
                                target="_blank"
                                rel="noopener"
                                href={value?.url}
                                download={value?.url}
                            >
                                <b>{value === null ? 'n.a.' : value?.file_name}</b>
                            </Typography>
                        </Grid>
                    ))}
                </>
            )}
        </Grid>
    );
}

export default FieldValueDocumentSet