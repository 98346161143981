import { styled } from '@mui/material/styles';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { RDV_NEUTRAL_100 } from '@rdv-fo/styles/theme';

const RdvTabs = styled((props: TabsProps) => <Tabs {...props} />)(() => ({
	root: {
		borderBottom: '1px solid #e8e8e8',
		textTransform: 'none',
	},
}));

const RdvTab = (props: TabProps) => (
	<Tab
		disableRipple
		{...props}
		sx={{
			textTransform: 'none',
			minWidth: 12,
			padding: 0,
			mr: 2.5,
			color: RDV_NEUTRAL_100,
			fontWeight: 600,
			'&:hover': {
				color: 'primary',
				opacity: 1,
			},
			selected: {
				fontWeight: 800,
				textTransform: 'none',
			},
		}}
	/>
);

export { RdvTabs, RdvTab };
