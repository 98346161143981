import { useEffect, useMemo } from 'react';
import createDecorator from 'final-form-focus';
import { Form } from 'react-final-form';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import arrayMutators from 'final-form-arrays';
import { formatDateTimeHumanFriendly } from '@rdv-fo/app/lib/datetime';

import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import FieldInput from '../fields/input/FieldInput';
import ConditionalFieldInput from '../fields/input/ConditionalFieldInput';
import {
	updateSupply,
	updateSupplyStatusChanged,
	updateSupplyFailed,
	selectUpdateSupplyStatus,
} from '@rdv-fo/store/slices/supplySlice';
import SupplyStatusChip from '@rdv-fo/components/supply/SupplyStatusChip';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';
import {
	AvailabilityManagementKind,
	AvailabilityTrackingKind,
	FieldAccessKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { mapFieldsToFormValues, mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { Stack } from '@mui/material';
import { findFieldByTechName } from '@rdv-fo/app/lib/fieldsHelper';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import FieldInputInteger from '../fields/input/FieldInputInteger';

const SupplyUpdateForm = ({ supply }) => {
	const dispatch = useAppDispatch();
	const updateSupplyStatus = useAppSelector(selectUpdateSupplyStatus);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);
	const focusOnError = useMemo(() => createDecorator(), []);
	const sharedObjects = useAppSelector(selectMySharedObjects);

	const updating = updateSupplyStatus === API_STATUS.LOADING;

	const simpleStockAvailabilityEnabled =
		supply?.availability?.availability_management_type === AvailabilityManagementKind.Default &&
		supply?.availability?.availability_tracking_type === AvailabilityTrackingKind.SimpleStock;

	useEffect(() => {
		if (updateSupplyStatus === API_STATUS.SUCCEEDED) {
			setTimeout(function () {
				dispatch(updateSupplyStatusChanged(API_STATUS.IDLE));
			}, 1500);
		}
	}, [updateSupplyStatus, dispatch]);

	const handleSubmitUpdateSupplyForm = async (values, form) => {
		const dirtyFields = getDirtyFields({ ...values }, form);

		const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypeIds, supply?.fields);

		const resolvedAction = await dispatch(
			updateSupply({
				id: supply.id,
				dirty_fields: fieldInputs,
				current_fields: supply?.fields,
				remaining_qty: values['remaining_qty'],
			})
		);
		if (resolvedAction?.type === updateSupplyFailed.type) return resolvedAction.payload;
	};

	const supplyNameValue = findFieldByTechName(supply?.fields, 'name')?.value;

	const supplyCreatedAt = formatDateTimeHumanFriendly(supply?.created_at);

	const initUpdateMySupplyForm = (supply) => {
		let initialValues = mapFieldsToFormValues(supply?.fields, sharedObjectTypeIds);

		if (simpleStockAvailabilityEnabled) {
			initialValues.remaining_qty = supply?.availability?.data?.remaining_qty;
		}

		return initialValues;
	};

	return (
		<Form
			onSubmit={handleSubmitUpdateSupplyForm}
			initialValues={initUpdateMySupplyForm(supply)}
			decorators={[focusOnError]}
			mutators={{
				...arrayMutators,
			}}
		>
			{(props) => {
				const { handleSubmit, pristine, submitting, hasValidationErrors } = props;

				const saveButtonEnabled = !pristine && !hasValidationErrors;

				return (
					<form onSubmit={handleSubmit} noValidate>
						<Grid
							id="form-header"
							container
							justifyContent="space-between"
							sx={{
								paddingBottom: 6,
							}}
						>
							<Grid item>
								<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
									<Typography variant="h1" display="inline">
										{supplyNameValue}
									</Typography>

									<SupplyStatusChip
										status={supply?.status}
										onboardingStateLabel={supply?.onboarding_progress?.status}
									/>
								</Stack>

								<Typography variant="body2" color="textSecondary">
									{`${supply?.type?.name} - ${supply?.id} - Created on ${supplyCreatedAt} `}
								</Typography>
							</Grid>
							<Grid item>
								<LoadingButton
									disabled={!saveButtonEnabled}
									variant="contained"
									size="small"
									color="primary"
									startIcon={<SaveIcon />}
									type="submit"
									loading={submitting}
								>
									Save
								</LoadingButton>
							</Grid>
						</Grid>

						<Grid container id="form-content" spacing={3}>
							{simpleStockAvailabilityEnabled && (
								<Grid item xs={12}>
									<FieldInputInteger
										techName="remaining_qty"
										label="Available quantity"
										disabled={updating}
										minValue={0}
									/>
								</Grid>
							)}

							{supply?.fields?.map((field) => {
								return (
									<ConditionalFieldInput
										key={field?.field_type.tech_name}
										fields={supply?.fields}
										conditions={field?.field_type?.conditions}
									>
										<Grid item key={field.field_type.tech_name} xs={12}>
											<FieldInput
												sharedObjects={sharedObjects}
												label={field?.field_type?.ui_config?.label ?? field.field_type?.name}
												optionsUiConfig={field?.field_type?.ui_config?.options}
												helperText={field?.field_type?.ui_config?.helper_text}
												fieldTechName={field?.field_type.tech_name}
												inputType={field?.field_type.input_type}
												inputOptions={field?.field_type.input_options}
												required={field?.field_type.is_required}
												sharedObjectTypes={sharedObjectTypes}
												disabled={field.my_access === FieldAccessKind.Read || updating}
												objectTypeFields={field?.object_type?.fields}
											/>
										</Grid>
									</ConditionalFieldInput>
								);
							})}
						</Grid>
					</form>
				);
			}}
		</Form>
	);
};

export default SupplyUpdateForm;
