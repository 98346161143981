import { Typography } from '@mui/material';
import { RdvMultiSelectFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldMultiSelectCellViewProps {
	value: RdvMultiSelectFieldValue;
	optionsUiConfig: { label: string, value: string }[];
}
const FieldMultiSelectCellView = ({ value, optionsUiConfig }: FieldMultiSelectCellViewProps) => {
	if (value == null) return <Typography>-</Typography>;

	value = value.map((value) => optionsUiConfig.find((valueConfig) => valueConfig.value === value)?.label ?? value)

	const formattedValue = value?.join(', ');

	return <Typography variant="body2">{formattedValue}</Typography>;
};

export default FieldMultiSelectCellView;
