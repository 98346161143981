import { uniqBy } from 'lodash';
import { MRT_ColumnDef, MRT_Cell } from 'material-react-table';
import {
	Field,
	FieldType,
	ObjectType,
	InputKind,
	ObjectField,
	Connection, Payment, Transfer,
	SupplyVariant,
	Object,
	TransferStatusKind,
	PaymentStatusKind,
	Participant,
	PaymentMethodKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import { RdvFieldValue } from '@rdv-fo/services/randevuApi/types/field';
import TransferStatusChip from '@rdv-fo/components/transfers/TransferStatusChip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, IconButton, Stack, } from '@mui/material';
import PaymentStatusChip from '@rdv-fo/components/payments/PaymentStatusChip';
import InternalNavigator from '@rdv-fo/components/common/InternalNavigator';
import ROUTES from '@rdv-fo/common/routes';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import ConnectionStatusChip from '@rdv-fo/components/connections/ConnectionStatusChip';
import { findFieldByTechName } from './fieldsHelper';
import { createConnectionWithDiscoveredParticipantFailed } from '@rdv-fo/store/slices/connectionSlice';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
export type RdvCellValue = {
	value: RdvFieldValue;
	input_type: InputKind;
};

export const DEFAULT_STYLES_MRT_TABLE = {
	muiTablePaperProps: {
		elevation: 0,
		sx: {
			borderRadius: '0',
		},
	},
	muiTableProps: {
		sx: { tableLayout: 'fixed' },
	},
	muiTableHeadCellProps: {
		sx: {
			backgroundColor: RDV_NEUTRAL_10,
			fontSize: '0.889rem',
			fontWeight: 600,
			letterSpacing: 0,
		},
	},
	muiTableBodyProps: {
		sx: {
			'&:hover': {
				backgroundColor: RDV_NEUTRAL_20,
			},
		},
	},

	muiLinearProgressProps: {
		sx: { display: 'none' },
	},
	muiCircularProgressProps: {
		size: 55,
	},
	muiSkeletonProps: {
		animation: 'pulse' as const,
		height: 28,
	},
};

export enum PaginationOperationKind {
	before,
	after,
}

export const DEFAULT_OPTIONS_MRT_TABLE = {
	enablePinning: false,
	enableSorting: false,
	enableGlobalFilter: false,
	enableColumnFilters: false, //disable all column filters
	enableColumnFilterModes: false,
	manualPagination: false,
	enableColumnActions: false,
	enableDensityToggle: false,
};

export const DEFAULT_PAGE_SIZE = 20;

export const buildColumns = (fields: any, object_types: ObjectType[]): MRT_ColumnDef<any>[] => {
	const colDefs = fields?.map((field_type: FieldType) => {
		if ([InputKind.Object].includes(field_type.input_type)) {
			const id_object_type = field_type?.input_options?.id_object_type;

			const object_type_definition: ObjectType | undefined = object_types?.find(
				(object_type: ObjectType) => object_type.id === id_object_type
			);
			if (object_type_definition === undefined)
				throw new Error(
					`Field ${field_type.tech_name} is an object, but the reference to the object type is missing`
				);

			const columnDefinitionsObjectFieldTypes = object_type_definition.fields.reduce(
				(acc: any, object_field_type: any) => {
					return {
						...acc,
						columns: [
							...acc.columns,
							{
								accessorFn: (originalRow: any) => {
									const object = originalRow[field_type.tech_name];

									const objectField =
										object?.[`${field_type.tech_name}_${object_field_type.tech_name}`];

									return objectField;
								},
								header: object_field_type?.ui_config?.label ?? object_field_type.name,
								Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
									const data = cell.getValue<RdvCellValue>();

									return <FieldView inputKind={data?.input_type} value={data?.value} />;
								},
							},
						],
					};
				},
				{
					id: field_type.tech_name,
					header: field_type?.ui_config?.label ?? field_type.name,
					columns: [],
				}
			);

			return columnDefinitionsObjectFieldTypes;
		}

		// Non object input types
		return {
			accessorKey: field_type.tech_name,
			header: field_type?.ui_config?.label ?? field_type.name,
			Cell: ({ cell }: { cell: MRT_Cell<any> }) => {
				const data = cell.getValue<RdvCellValue>();
				return <FieldView inputKind={field_type?.input_type} value={data?.value} />;
			},
		};
	});

	return colDefs ?? [];
};

export const buildConnectionsTableColumns = (fields: Field[]): MRT_ColumnDef<any>[] => {
	const defaultParticipantColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<string>();
				return <FieldView inputKind={InputKind.Text} value={data} />;
			},
		},
		{
			accessorKey: 'status',
			header: 'Status',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <ConnectionStatusChip loading={false} status={data.value as any} />;
			},
		},

	];

	const fieldTypes = fields?.map((field) => field.field_type) ?? [];
	const objectTypes = fields?.map((field: any) => field.object_type).filter((field) => field) ?? [];

	const fieldColumns = buildColumns(fieldTypes, objectTypes);

	const columns = uniqBy(defaultParticipantColumns.concat(fieldColumns), function (column: MRT_ColumnDef<any>) {
		return column.accessorKey;
	});

	return columns;
};
export const buildDiscoveredConnectionParticipantsTableColumns = (
	fields: Field[],
	connectionTechName: string,
	history: any,
	onConnectParticipant: (id: string) => any,
	onOpenParticipantDetails: (id: string) => void
): MRT_ColumnDef<any>[] => {
	const defaultParticipantColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'name',
			header: 'Name',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'first_name',
			header: 'First name',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'last_name',
			header: 'Last name',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'actions',
			enableHiding: false,
			enablePinning: false,
			maxSize: 40,
			header: '',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as any;
				let connectButtonEnabled = true;
				return data?.value?.connectionId ? (
					<Button
						variant="text"
						onClick={() => {
							history.push(ROUTES.CONNECTION_DETAILS.replace(":connectionTechName", connectionTechName).replace(':id', data?.value?.connectionId));
						}}
					>
						{' '}
						View connection{' '}
					</Button>
				) : (
					<Stack padding={0} margin={0} spacing={1} direction="row">
						<Button
							variant="text"
							disabled={!connectButtonEnabled}
							onClick={() => {
								onOpenParticipantDetails(data?.value?.id);
							}}
						>
							Details
						</Button>
						<Button
							variant="contained"
							disabled={!connectButtonEnabled}
							onClick={async () => {
								connectButtonEnabled = false;
								const resolvedActionType = await onConnectParticipant(data?.value?.id);

								if (resolvedActionType === createConnectionWithDiscoveredParticipantFailed.type)
									connectButtonEnabled = true;
							}}
						>
							Connect
						</Button>
					</Stack>
				);
			},
		},
	];

	// const fieldTypes = fields?.map((field) => field.field_type) ?? [];
	// const objectTypes = fields?.map((field: any) => field.object_type).filter((field) => field) ?? [];

	// const fieldColumns = buildColumns(fieldTypes, objectTypes);

	// const columns = uniqBy(defaultParticipantColumns.concat(fieldColumns), function (column: MRT_ColumnDef<any>) {
	// 	return column.accessorKey;
	// });

	return defaultParticipantColumns;
};

interface BuildTransderTableColumnsArgs {
	onOpenDrawer: (id: string | null) => void;
}

export const buildTransfersTableColumns = ({ onOpenDrawer }: BuildTransderTableColumnsArgs): MRT_ColumnDef<any>[] => {
	const columns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_sent',
			header: 'Sent',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: '_amount',
			header: 'Amount',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: '_status',
			header: 'Status',
			Cell: ({ cell, row }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <TransferStatusChip status={data.value as TransferStatusKind} transferError={row.original.error} />;
			},
		},
		{
			accessorKey: '_orderId',
			header: 'Order ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: '_details',
			header: '',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return (
					<IconButton onClick={() => onOpenDrawer(data.value as string)}>
						<ArrowForwardIcon />
					</IconButton>
				);
			},
		},
	];

	return columns;
};

export const buildTransfersTableRows = (transfers: Transfer[]): any[] => {
	const rows = transfers?.map((transfer) => {
		return {
			_sent: { value: transfer?.succeeded_at, input_type: InputKind.Datetime },
			_amount: {
				value: formatPriceAmount({ amount: transfer?.amount?.amount, currency: transfer?.amount?.currency }),
				input_type: InputKind.Text,
			},
			_status: { value: transfer?.status, input_type: InputKind.Text },
			_orderId: { value: transfer?.order?.id, input_type: InputKind.Text },
			_details: { value: transfer?.id, input_type: InputKind.Text },
			error: transfer.error,
		};
	});

	return rows;
};
export const buildPaymentsTableColumns = (): MRT_ColumnDef<any>[] => {
	const columns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: '_amount',
			header: 'Amount',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'payment_method',
			header: 'Payment method',
			Cell: ({ cell, row }) => {
				const data = cell.getValue<PaymentMethodKind | null>();

				const paymentMethodDetails = row.original.payment_method_details;

				if (PaymentMethodKind.SepaTransfer === data) return <FieldView inputKind={InputKind.Text} value={"Bank transfer"} />;
				if (PaymentMethodKind.Card === data) return <FieldView inputKind={InputKind.Text} value={`•••• ${paymentMethodDetails?.last4}`} />;

				if (null === data)
					return <FieldView inputKind={InputKind.Text} value={"-"} />;

				return <FieldView inputKind={InputKind.Text} value={"-"} />;

			},
		},
		{
			accessorKey: '_status',
			header: 'Status',
			Cell: ({ cell, row }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;

				return <PaymentStatusChip status={data.value as any} paymentError={row.original.payment_error} />;
			},
		},
		{
			accessorKey: '_date',
			header: 'Date',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: '_idTrx',
			header: 'Transaction ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;

				return <FieldView inputKind={InputKind.Text} value={data.value} />;

			},
		},
	];

	return columns;
};

const determinePaymentDateFromStatus = (payment: Payment) => {
	switch (payment.status) {
		case PaymentStatusKind.Authorized:
			return payment.authorized_at;
		case PaymentStatusKind.Captured:
			return payment.captured_at;
		case PaymentStatusKind.Failed:
			return payment.failed_at;
		case PaymentStatusKind.Completed:
			return payment.completed_at;
		case PaymentStatusKind.CheckoutRequested:
			return payment.checkout_requested_at;
		case PaymentStatusKind.Prepared:
			return payment.prepared_at;
	}
};

export const buildPaymentsTableRows = (payments: Payment[]): any[] => {
	const rows = payments?.map((payment: Payment) => {
		return {
			_id: { value: payment?.id, input_type: InputKind.Text },
			_amount: {
				value: formatPriceAmount({ amount: payment?.amount?.amount, currency: payment?.amount?.currency }),
				input_type: InputKind.Text,
			},
			_status: { value: payment?.status, input_type: InputKind.Text },
			_date: { value: determinePaymentDateFromStatus(payment), input_type: InputKind.Datetime },
			_idTrx: { value: payment?.id_transaction, input_type: InputKind.Text },
			payment_method: payment.payment_method,
			payment_method_details: payment.payment_method_details,
			payment_error: payment.error
		};
	});

	return rows;
};
export const buildConnectionsTableRows = (connections: Connection[]): any[] => {
	const rows = connections.map((connection) => {
		const fieldsValues = connection.participant.fields?.reduce((acc, field: Field) => {
			const inputType = field.field_type.input_type;

			if (inputType === InputKind.Object) {
				const object_field_type = { ...field } as ObjectField;

				let res = undefined;
				if (object_field_type.value === null) {
					res = object_field_type.object_type?.fields?.reduce((innerAcc, objectFieldType: FieldType) => {
						return {
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectFieldType.tech_name}`]: {
									value: null,
									input_type: objectFieldType.input_type,
								},
							},
						};
					}, {});
				} else {
					res = object_field_type.value?.fields?.reduce((innerAcc, objectField: Field) => {
						let valueWithUiConfig = undefined;
						if (objectField.field_type?.input_type === InputKind.Select)
							valueWithUiConfig = objectField?.field_type?.ui_config?.options?.find(
								(opt) => opt.value === objectField.value
							)?.label;
						if (objectField.field_type?.input_type === InputKind.MultiSelect)
							valueWithUiConfig = objectField.value?.map(
								(value: string) =>
									objectField?.field_type?.ui_config?.options?.find((opt) => opt.value === value)
										?.label
							);
						return {
							...innerAcc,
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectField.field_type.tech_name}`]: {
									value: valueWithUiConfig === undefined ? objectField.value : valueWithUiConfig,
									input_type: objectField.field_type.input_type,
								},
							},
						};
					}, {});
				}

				return { ...acc, ...res };
			}
			let valueWithUiConfig = undefined;
			if (field.field_type?.input_type === InputKind.Select)
				valueWithUiConfig = field?.field_type?.ui_config?.options?.find(
					(opt) => opt.value === field.value
				)?.label;
			if (field.field_type?.input_type === InputKind.MultiSelect)
				valueWithUiConfig = field.value?.map(
					(value: string) => field?.field_type?.ui_config?.options?.find((opt) => opt.value === value)?.label
				);
			return {
				...acc,
				[field.field_type.tech_name]: {
					value: valueWithUiConfig === undefined ? field.value : valueWithUiConfig,
				},
			};
		}, {});

		return {
			_id: connection.id,
			status: { value: connection.status, input_type: InputKind.Text },
			//email: { value: connection?.participant?.users?.[0]?.email, input_type: InputKind.Text },
			...fieldsValues,
		};
	});

	return rows;
};
export const buildDiscoveredConnectionParticipantsTableRows = (
	participants: Participant[],
	connections: Connection[]
): any[] => {
	const rows = participants.map((participant) => {
		const connection = connections.find((connection) => connection.participant.id === participant.id);

		return {
			_id: { value: participant.id, input_type: InputKind.Text },
			first_name: {
				value: findFieldByTechName(participant?.fields, 'first_name')?.value,
				input_type: InputKind.Text,
			},
			last_name: {
				value: findFieldByTechName(participant?.fields, 'last_name')?.value,
				input_type: InputKind.Text,
			},
			name: { value: findFieldByTechName(participant?.fields, 'name')?.value, input_type: InputKind.Text },
			actions: { value: { id: participant.id, connectionId: connection?.id }, input_type: InputKind.Text },
		};
	});

	return rows;
};
export const buildSharedObjectsTableColumns = (fields: Field[]): MRT_ColumnDef<any>[] => {
	const defaultParticipantColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
	];

	const fieldTypes = fields?.map((field) => field.field_type) ?? [];
	const objectTypes = fields?.map((field: any) => field.object_type).filter((field) => field) ?? [];

	const fieldColumns = buildColumns(fieldTypes, objectTypes);

	const columns = uniqBy(defaultParticipantColumns.concat(fieldColumns), function (column: MRT_ColumnDef<any>) {
		return column.accessorKey;
	});

	return columns;
};

export const buildSharedObjectsTableRows = (sharedObjects: Object[]): any[] => {
	const rows = sharedObjects.map((sharedObject) => {
		const fieldsValues = sharedObject.fields?.reduce((acc, field: Field) => {
			const inputType = field.field_type.input_type;

			if (inputType === InputKind.Object) {
				const object_field_type = { ...field } as ObjectField;

				let res = undefined;
				if (object_field_type.value === null) {
					res = object_field_type.object_type?.fields?.reduce((innerAcc, objectFieldType: FieldType) => {
						return {
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectFieldType.tech_name}`]: {
									value: null,
									input_type: objectFieldType.input_type,
								},
							},
						};
					}, {});
				} else {
					res = object_field_type.value?.fields?.reduce((innerAcc, objectField: Field) => {
						let valueWithUiConfig = undefined;
						if (objectField.field_type?.input_type === InputKind.Select)
							valueWithUiConfig = objectField?.field_type?.ui_config?.options?.find(
								(opt) => opt.value === objectField.value
							)?.label;
						if (objectField.field_type?.input_type === InputKind.MultiSelect)
							valueWithUiConfig = objectField.value?.map(
								(value: string) =>
									objectField?.field_type?.ui_config?.options?.find((opt) => opt.value === value)
										?.label
							);
						return {
							...innerAcc,
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectField.field_type.tech_name}`]: {
									value: valueWithUiConfig === undefined ? objectField.value : valueWithUiConfig,
									input_type: objectField.field_type.input_type,
								},
							},
						};
					}, {});
				}

				return { ...acc, ...res };
			}
			let valueWithUiConfig = undefined;
			if (field.field_type?.input_type === InputKind.Select)
				valueWithUiConfig = field?.field_type?.ui_config?.options?.find(
					(opt) => opt.value === field.value
				)?.label;
			if (field.field_type?.input_type === InputKind.MultiSelect)
				valueWithUiConfig = field.value?.map(
					(value: string) => field?.field_type?.ui_config?.options?.find((opt) => opt.value === value)?.label
				);
			return {
				...acc,
				[field.field_type.tech_name]: {
					value:
						valueWithUiConfig === undefined || valueWithUiConfig?.[0] === undefined
							? field.value
							: valueWithUiConfig,
				},
			};
		}, {});

		return {
			_id: { value: sharedObject.id, input_type: InputKind.Text },
			...fieldsValues,
		};
	});

	return rows;
};

export const buildSupplyVariantTableColumns = (
	field_types: FieldType[],
	object_types: ObjectType[],
	onEdit: (supplyId: string) => void
): MRT_ColumnDef<any>[] => {
	const defaultSupplyColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
	];

	const fieldColumns = buildColumns(field_types, object_types);

	const columns = uniqBy(defaultSupplyColumns.concat(fieldColumns), function (column: MRT_ColumnDef<any>) {
		return column.accessorKey;
	});

	return columns;
};

export const buildSupplyVariantRows = (supplies: SupplyVariant[]): any[] => {
	const rows = supplies?.map((supply) => {
		const fieldsValues = supply.fields?.reduce((acc, field: Field) => {
			const inputType = field.field_type.input_type;

			if (inputType === InputKind.Object) {
				const object_field_type = { ...field } as ObjectField;

				let res = undefined;
				if (object_field_type.value === null) {
					res = object_field_type.object_type?.fields?.reduce((innerAcc, objectFieldType: FieldType) => {
						//console.log('innerAcc:', innerAcc);
						return {
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectFieldType.tech_name}`]: {
									value: null,
									input_type: objectFieldType.input_type,
								},
							},
						};
					}, {});
				} else {
					res = object_field_type.value?.fields?.reduce((innerAcc, objectField: Field) => {
						let valueWithUiConfig = undefined;
						if (objectField.field_type?.input_type === InputKind.Select)
							valueWithUiConfig = objectField?.field_type?.ui_config?.options?.find(
								(opt) => opt.value === objectField.value
							)?.label;
						if (objectField.field_type?.input_type === InputKind.MultiSelect)
							valueWithUiConfig = objectField.value?.map(
								(value: string) =>
									objectField?.field_type?.ui_config?.options?.find((opt) => opt.value === value)
										?.label
							);
						return {
							...innerAcc,
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectField.field_type.tech_name}`]: {
									value: valueWithUiConfig === undefined ? objectField.value : valueWithUiConfig,
									input_type: objectField.field_type.input_type,
								},
							},
						};
					}, {});
				}

				return { ...acc, ...res };
			}

			let valueWithUiConfig = undefined;
			if (field.field_type?.input_type === InputKind.Select)
				valueWithUiConfig = field?.field_type?.ui_config?.options?.find(
					(opt) => opt.value === field.value
				)?.label;
			if (field.field_type?.input_type === InputKind.MultiSelect)
				valueWithUiConfig = field.value?.map(
					(value: string) => field?.field_type?.ui_config?.options?.find((opt) => opt.value === value)?.label
				);
			return {
				...acc,
				[field.field_type.tech_name]: {
					value: valueWithUiConfig === undefined ? field.value : valueWithUiConfig,
					input_type: inputType,
				},
			};
		}, {});

		return {
			_id: { value: supply.id, input_type: InputKind.Text },
			_details: supply.id,
			_isActive: supply.is_active,

			...fieldsValues,
		};
	});

	return rows;
};

