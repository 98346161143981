import makeStyles from '@mui/styles/makeStyles';
import { RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';

const useChipStyles = makeStyles((theme) => ({
	gray: {
		color: 'black',
		fontWeight: 600,
		backgroundColor: RDV_NEUTRAL_20,
	},
	red: {
		color: 'rgb(97, 26, 21)',
		fontWeight: 600,
		backgroundColor: 'rgb(253, 236, 234)',
	},
	blue: {
		color: 'rgb(13, 60, 97)',
		fontWeight: 600,
		backgroundColor: 'rgb(232, 244, 253)',
	},
	green: {
		color: 'rgb(30, 70, 32)',
		fontWeight: 600,
		backgroundColor: 'rgb(237, 247, 237)',
	},
	orange: {
		color: 'rgb(102, 60, 0)',
		fontWeight: 600,
		backgroundColor: 'rgb(255, 244, 229)',
	},
}));

export default useChipStyles;
