import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ASSETS } from '@rdv-fo/app/assets';
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import { useAppSelector } from '@rdv-fo/store/configureStore';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import ROUTES from '@rdv-fo/common/routes';
import { selectInitializingPlatform } from '@rdv-fo/store/slices/platformSlice';
import { Redirect } from 'react-router-dom';

const PlatformErrorPage = () => {
	const initPlatform = useAppSelector(selectInitializingPlatform);
	const currentUser = useAppSelector(selectCurrentUser);

	if (initPlatform === 'succeeded') {
		let targetRoute = ROUTES.HOME;
		if (currentUser) targetRoute = ROUTES.DASHBOARD;

		return <Redirect to={targetRoute} push={true} />;
	}
	return (
		<Container sx={{ pt: 8, pb: 2 }}>
			<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
				<Typography align="center" component="h1" variant="h3">
					Your platform could not be loaded
				</Typography>
				<Typography variant="h4">422 Unprocessable Request</Typography>
				<Typography align="center" sx={{ pl: 8, pr: 8, maxWidth: 'md' }}>
					Possible reasons: 1) you did not push to production, 2) you did not enable the frontoffice, 3)
					semantically incorrect configuration, or 4) the requested platform does not exist.
				</Typography>
				<Box sx={{ maxWidth: { xs: 400, md: 700 }, maxHeight: { xs: 400, md: 700 } }}>
					<img
						src={ASSETS.RANDEVU.PLATFORM_NOT_FOUND}
						alt="Platform not found"
						style={{ width: '100%', height: '100%' }}
					/>
				</Box>
			</Stack>
		</Container>
	);
};
export default PlatformErrorPage;
