import { Link as RouterLink } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { CardActionArea, CardMedia, Stack } from '@mui/material';
import { ASSETS } from '@rdv-fo/app/assets';

import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';
import {
    FieldCategoryKind,
    Provider,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RDV_NEUTRAL_00 } from '@rdv-fo/styles/theme';

interface DiscoveredProviderCardProps {
    provider: Provider;
    transactionTypeTechName: string;
}

const DiscoveredProviderCard = ({ provider, transactionTypeTechName }: DiscoveredProviderCardProps) => {
    const fieldName = findFieldByCategory(provider?.fields, FieldCategoryKind.Name);
    const fieldProfileImage = findFieldByCategory(provider?.fields, FieldCategoryKind.ProfileImage);

    return (
        <>
            <Card
                sx={{
                    borderRadius: 1.5,
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >
                <CardActionArea
                    component={RouterLink}
                    to={routeBuilder(ROUTES.DISCOVERED_TRANSACTION_PROVIDER_DETAILS, [
                        [':transactionTypeTechName', transactionTypeTechName],
                        [':providerId', provider.id_participant],
                    ])}
                    sx={{ height: '100%', objectFit: 'contain' }}
                >
                    <CardMedia
                        component="img"
                        src={fieldProfileImage?.value?.url || ASSETS.RANDEVU.IMAGE_UNAVAILABLE}
                        title={fieldName?.value}
                        sx={{ height: 200, objectFit: 'contain' }}
                    />
                    <CardContent
                        sx={{
                            backgroundColor: RDV_NEUTRAL_00,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            overflowWrap: 'break-word',
                            height: '100%',
                        }}
                    >
                        <Stack direction="column" justifyContent="flex-end" alignItems="stretch" spacing={1}>
                            {fieldName && (
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {fieldName?.value ?? '-'}
                                </Typography>
                            )}
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
};

export default DiscoveredProviderCard;
