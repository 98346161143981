import NavigationTile from '@rdv-fo/components/dashboard/NavigationTile';
import { Stack, Typography } from '@mui/material';
import { filterDashboardTileAndCurrentUserType } from '@rdv-fo/app/lib/dashboardHelpers';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { selectDashboardPageConfig, selectInitializingPlatform } from '@rdv-fo/store/slices/platformSlice';
import { useEffect } from 'react';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

interface RdvDashboardTileProps {
	title: string;
	target_route: string;
}

const DashboardPage = () => {
	const dispatch = useAppDispatch();
	const page_config = useAppSelector(selectDashboardPageConfig) ?? ({} as any);
	const currentUserTypeId = useAppSelector(selectCurrentUser)?.type?.id;
	const renderedTiles = page_config?.tiles?.filter((tile: any) =>
		filterDashboardTileAndCurrentUserType(currentUserTypeId, tile)
	);
	const { enabled: isDashboardPageEnabled } = page_config;

	const initPlatform = useAppSelector(selectInitializingPlatform);

	useEffect(() => {
		if (!isDashboardPageEnabled && initPlatform === 'succeeded')
			dispatch(goToRoute(routeBuilder(ROUTES.LANDING_PAGE)));
	}, [isDashboardPageEnabled, dispatch, initPlatform]);

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<Typography component="h1" variant="h1">
					{page_config?.title}
				</Typography>
			</Stack>
			<Box sx={{ mt: 5 }}>
				<Grid
					container
					direction="row"
					width="100%"
					spacing={2}
					justifyContent="flex-start"
					alignItems="flex-start"
				>
					{renderedTiles?.map(({ title, target_route }: RdvDashboardTileProps, index: number) => {
						return (
							<Grid key={index} item xs={12} md={4}>
								<NavigationTile title={title} routeTo={target_route} />
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};

export default DashboardPage;
