import { Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { ObjectFormField } from '@rdv-fo/app/lib/formHelpers';
import { FieldType, Object, ObjectType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import FieldInput from './FieldInput';
import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { useForm } from 'react-final-form';
import ConditionalFieldInput from './ConditionalFieldInput';

interface FieldInputObjectSetProps extends FieldInputBaseProps {
	objectTypeFields: FieldType[];
	maxObjects?: number;
	minObjects?: number;
	sharedObjectTypes: ObjectType[];
	sharedObjects: Object[];
}
const missingValue = ['', undefined, null];

const buildObjectSkeleton = (objectFieldTypes: FieldType[]): ObjectFormField => {
	const object = objectFieldTypes?.reduce(
		(acc, field_type: FieldType) => {
			return { fields: { ...acc.fields, [field_type.tech_name]: null } };
		},
		{ fields: {} }
	);

	return object;
};

interface DocumentSetInputOptions {
	required: boolean;
	maxObjects?: number;
	minObjects?: number;
}

const validate = (value: any, options: DocumentSetInputOptions) => {
	const { maxObjects, minObjects, required } = options;

	if (!required && missingValue.includes(value)) return undefined;

	if (required && missingValue.includes(value))
		return { [FORM_ERROR]: VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION };

	if (maxObjects && value?.length > maxObjects)
		return {
			[FORM_ERROR]: VALIDATION_MESSAGE.MAX_NUMBER_OF_OBJECTS_EXCEEDED.replace(
				'{files_count}',
				String(maxObjects)
			),
		};
	if (minObjects && value?.length < minObjects)
		return {
			[FORM_ERROR]: VALIDATION_MESSAGE.MIN_NUMBER_OF_OBJECTS_NOT_SATISFIED.replace(
				'{files_count}',
				String(minObjects)
			),
		};

	return undefined;
};

export const FieldInputObjectSet = ({
	techName,
	label,
	labelProps,
	disabled = false,
	required = false,
	objectTypeFields = [],
	maxObjects,
	minObjects,
	sharedObjects,
	sharedObjectTypes
}: FieldInputObjectSetProps) => {
	const theme = useTheme();
	const handleValidate = (values: any[]) => {
		const a = validate(values, { minObjects, maxObjects, required });
		return a;
	};
	const form = useForm();

	return (
		<>
			<div>
				<LabelFieldInput
					text={label}
					htmlFor={techName}
					required={required}
					showOptional={labelProps?.showOptional}
				/>
				<div style={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(2) }}>
					<FieldArray name={techName} validate={handleValidate}>
						{({ fields, meta: { error } }) => {
							return (
								<>
									<Grid item container spacing={2} xs={12} sx={{ marginLeft: 3, marginTop: 2 }}>
										{error && (
											<Typography variant="overline" color="red">
												{error[FORM_ERROR]}
											</Typography>
										)}

										{fields?.map((name, index) => {
											return (
												<Grid item xs={11} key={index}>
													<div
														style={{
															paddingTop: theme.spacing(2),
															paddingBottom: theme.spacing(2),
															display: 'flex',
															justifyContent: 'flex-start',
															gap: theme.spacing(2),
														}}
													>
														<Typography sx={{ fontWeight: 600 }}>
															{index + 1 + '.'}
														</Typography>
														<Button
															variant="text"
															color="primary"
															onClick={() => {
																if (index === 0 && fields.length === 1)
																	form.change(techName, []);
																else fields.remove(index);
															}}
															size="small"
															startIcon={<ClearIcon />}
															style={{ padding: 1 }}
															disabled={disabled}
														>
															Remove
														</Button>
													</div>
													{objectTypeFields?.map((field_type: FieldType) => {
														return (
															<div
																style={{ marginBottom: theme.spacing(1) }}
																key={`${techName}.${field_type.tech_name}`}
															>
																<ConditionalFieldInput
																	key={field_type?.tech_name}
																	parentStateTechName={`${name}.fields`}
																	fields={objectTypeFields?.map((field) => {
																		return { ...field, field_type: { ...field } };
																	}) as any}
																	conditions={field_type?.conditions ?? []}
																>

																	<FieldInput
																		fieldTechName={`${name}.fields.${field_type.tech_name}`}
																		label={
																			field_type.ui_config?.label ?? field_type.name
																		}
																		sharedObjects={sharedObjects}
																		optionsUiConfig={field_type?.ui_config?.options ?? undefined}
																		helperText={field_type.ui_config?.helper_text ?? ''}
																		inputType={field_type.input_type}
																		inputOptions={field_type.input_options}
																		disabled={disabled}
																		sharedObjectTypes={sharedObjectTypes}
																	/>
																</ConditionalFieldInput>

															</div>
														);
													})}

													<br />

													<br />
													<br />
												</Grid>
											);
										})}
									</Grid>

									<Button
										size="small"
										color="primary"
										startIcon={<AddIcon />}
										disabled={disabled}
										onClick={() => {
											fields.push(buildObjectSkeleton(objectTypeFields));
										}}
									>
										Add new
									</Button>
								</>
							);
						}}
					</FieldArray>
				</div>
			</div>
		</>
	);
};

export default FieldInputObjectSet;
