import { Typography } from '@mui/material';
import { RdvImageSetFieldValue } from '@rdv-fo/services/randevuApi/types/field';

import FieldImageCellView from './FieldImageCellView';

interface FieldImageSetCellViewProps {
	value: RdvImageSetFieldValue;
}
const FieldImageSetCellView = ({ value }: FieldImageSetCellViewProps) => {
	if (value === null || value === undefined || value?.length === 0)
		return <Typography variant="body2">n.a.</Typography>;

	if (value?.length === 1) return <FieldImageCellView value={value[0]} />;

	return <Typography variant="body2">{`${value.length} images`}</Typography>;
};

export default FieldImageSetCellView;
