import { Field } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RdvObjectFieldValue } from '@rdv-fo/services/randevuApi/types/field/';
import FieldDisplayValue from './FieldDisplayValue';
import Grid from '@mui/material/Grid';
import { FieldValueStylingProps, LabelFieldValueProps } from './types';
import ConditionalFieldDisplay from './ConditionalFieldDisplay';

interface FieldValueObjectProps {
	value?: RdvObjectFieldValue;
	className: any;
	fieldTechName: string;
	valueProps?: FieldValueStylingProps;
	labelProps?: LabelFieldValueProps;
}
const FieldValueObject = ({ fieldTechName, value, labelProps, valueProps, className }: FieldValueObjectProps) => {
	const object = value;

	const objectFieldValues = {};
	object?.fields?.forEach((field) =>
		Object.assign(objectFieldValues, { [field?.field_type?.tech_name]: field?.value })
	);

	return (
		<>
			<Grid container direction="column" spacing={1}>
				{object === null ? 'n.a.' : undefined}
				{object?.fields?.map((objectField: Field) => {
					return (
						<ConditionalFieldDisplay
							key={objectField?.field_type?.tech_name}
							fields={object?.fields}
							values={objectFieldValues}
							conditions={objectField?.field_type?.conditions ?? []}
						>
							<Grid item key={objectField?.field_type.tech_name}>
								<FieldDisplayValue
									label={objectField?.field_type.name}
									labelProps={labelProps}
									fieldTechName={objectField?.field_type.tech_name}
									inputType={objectField?.field_type.input_type}
									inputOptions={objectField?.field_type.input_options}
									value={objectField?.value}
									valueProps={valueProps}
								/>
							</Grid>
						</ConditionalFieldDisplay>
					);
				})}
			</Grid>
		</>
	);
};

export default FieldValueObject;
