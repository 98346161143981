import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import ParticipantOnboardingPage from '@rdv-fo/pages/ParticipantOnboardingPage';
import ParticipantRejectedPage from '@rdv-fo/pages/ParticipantRejectedPage';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import Header from '../../landing-page/Header';
import { useTheme } from '@mui/material';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		layoutContent: {
			width: '100%',
			padding: theme.spacing(3),
			backgroundColor: theme.palette.common.white,
		},
		pageContent: {
			display: 'flex',
			justifyContent: 'center',
		},
	};
});

const PrivateLayout = () => {
	const classes = useStyles();

	return (
		<>
			<Header pageGroup="userOnboarding" />
			<main className={classes.layoutContent}>
				<div id="page-content" className={classes.pageContent}>
					<div>
						<Switch>
							<Route
								exact
								path={routeBuilder(ROUTES.PARTICIPANT_ONBOARDING)}
								component={ParticipantOnboardingPage}
							/>
							<Route
								exact
								path={routeBuilder(ROUTES.PARTICIPANT_REJECTED)}
								component={ParticipantRejectedPage}
							/>

							<Redirect to={routeBuilder(ROUTES.LANDING_PAGE)} />
						</Switch>
					</div>
				</div>
			</main>
		</>
	);
};

export default PrivateLayout;
