import { Button, Stack, Typography } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { RDV_NEUTRAL_10 } from '@rdv-fo/styles/theme';
import { DataImportJobStatusReport } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import ImportJobReportStatusChip from './ImportJobReportStatusChip';
import { formatDateTimeHumanFriendly } from '@rdv-fo/app/lib/datetime';

export const buildImportJobReportsTableRows = (importJobReports: DataImportJobStatusReport[]): any[] => {
	const rows = importJobReports?.map((importJobReport) => {
		return {
			file_name_requested_at: {
				file_name: importJobReport?.data?.name,
				requested_at: importJobReport.requested_at,
			},
			template_version: { value: importJobReport?.template?.name },
			data_records: {
				total: importJobReport.total_number_of_records ?? 0,
				success: importJobReport.records_processed_successfully ?? 0,
			},
			status: { started_at: importJobReport.started_at, finished_at: importJobReport.finished_at, report_exists: Boolean(importJobReport.report?.url) },
			report: { url: importJobReport.report?.url, physical_name: importJobReport.report?.name + '.xlsx' },
			_id: { value: importJobReport.id },
		};
	});


	return rows;
};

interface BuildImportJobReportsTableColumnsArgs {
	onDownloadFile: (url: string, fileName: string) => void;
}
export const buildImportJobReportsTableColumns = ({
	onDownloadFile,
}: BuildImportJobReportsTableColumnsArgs): MRT_ColumnDef<any>[] => {
	const defaultColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: 'file_name_requested_at',
			header: 'File name / Submitted on',
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				return (
					<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
						<Typography variant="body1" fontWeight="600">
							{data?.file_name}
						</Typography>
						<Typography variant="body2" color="textSecondary">
							{formatDateTimeHumanFriendly(data?.requested_at)}
						</Typography>
					</Stack>
				);
			},
		},
		{
			accessorKey: 'template_version',
			header: 'Template',
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				return <Typography variant="body2">{data?.value}</Typography>;
			},
		},
		{
			accessorKey: 'data_records',
			header: 'Data records',
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();

				if (!data?.total) return <Typography>{`-`}</Typography>;

				return <Typography>{`${data?.success} / ${data?.total}`}</Typography>;
			},
		},
		{
			accessorKey: '_id',
			header: 'Job ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				return <Typography variant="body2">{data?.value}</Typography>;
			},
		},

		{
			accessorKey: 'status',
			header: '',
			Cell: ({ cell, row }) => {
				const data = cell.getValue<any>();

				return <ImportJobReportStatusChip finishedAt={data?.finished_at} startedAt={data?.started_at} reportExists={data?.report_exists} />;
			},
		},

		{
			accessorKey: 'report',
			header: 'Report',
			Cell: ({ cell, row }) => {
				const data = cell.getValue<any>();

				return (
					<>
						{data?.url && (
							<Button
								color="primary"
								variant="text"
								size="small"
								onClick={() => onDownloadFile(data?.url, data?.file_name)}
							>
								Download
							</Button>
						)}
					</>
				);
			},
		},
	];

	return defaultColumns;
};

interface ImportJobReportsTableProps {
	items: DataImportJobStatusReport[];
	onDownloadFile: (url: string, fileName: string) => void;
}

const ImportJobReportsTable = ({ items = [], onDownloadFile }: ImportJobReportsTableProps) => {
	const columnDef = buildImportJobReportsTableColumns({ onDownloadFile });

	const rows = buildImportJobReportsTableRows(items) ?? [];

	return (
		<>
			<MaterialReactTable
				initialState={{
					columnVisibility: {
						_id: true,
					},
					pagination: { pageSize: 50, pageIndex: 0 },
				}}
				columns={columnDef}
				enablePinning={false}
				enableTopToolbar={false}
				data={rows}
				enableTableHead={true}
				enableSorting={false}
				enablePagination={false}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnFilters={false} //disable all column filters
				enableColumnFilterModes={false}
				rowCount={items?.length}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				positionActionsColumn="last"
				enableRowActions={false}
				enableFullScreenToggle={false}
				enableColumnActions={false}
				enableRowOrdering={false}
				enableHiding={true}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				renderBottomToolbar={
					items?.length > 0 &&
					(() => {
						let label = `${items?.length} import jobs`;
						if (items?.length === 1) label = `${items?.length} import job`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default ImportJobReportsTable;
