import { TextField } from 'mui-rff';
import LabelFilterInput from './LabelFilterInput';
import { FilterInputBaseProps } from './types';

// FIXME: set proper type
const parse = (value: any) => {
	if (['', undefined, null].includes(value)) return null;
	return parseFloat(value);
};
// FIXME: set proper type
const format = (value: any) => {
	if (['', undefined, null].includes(value)) return '';
	return value;
};

interface FilterInputDecimalProps extends FilterInputBaseProps {
	min?: number;
	max?: number;
	unit?: string;
}

interface DecimalTextFieldInputProps {
	type: string;
	inputMode: 'numeric' | 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'decimal' | undefined;
	pattern: string;
	min?: number;
	max?: number;
	step: number;
}

const FilterInputDecimal = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
	min,
	max,
	unit,
}: FilterInputDecimalProps) => {
	let labelWithUnit = unit ? `${label} (${unit})` : label;

	const inputProps: DecimalTextFieldInputProps = {
		type: 'number',
		inputMode: 'numeric', // FIXME: MOVE TO STRING AND FORBID INPUTTING ANYTHING ELSE THAN DIGITS
		pattern: '[0-9]*',
		...(min && { min: min }),
		...(max && { max: max }),
		step: 0.01,
	};

	const fieldProps = {
		parse,
		format,
	};

	return (
		<>
			{noLabel === false && (
				<LabelFilterInput text={labelWithUnit ?? ''} htmlFor={techName} required={required} />
			)}

			<TextField
				id={`${techName}.min_value`}
				name={`${techName}.min_value`}
				disabled={disabled}
				variant="outlined"
				size="small"
				margin="dense"
				placeholder="Min"
				fullWidth
				fieldProps={fieldProps}
				inputProps={inputProps} // FIXME: remove ts-ignore from the above of the file and fix inputProps issue
			/>
			<TextField
				id={`${techName}.max_value`}
				name={`${techName}.max_value`}
				disabled={disabled}
				variant="outlined"
				fullWidth
				size="small"
				margin="dense"
				placeholder="Max"
				fieldProps={fieldProps}
				inputProps={inputProps} // FIXME: remove ts-ignore from the above of the file and fix inputProps issue
			/>
		</>
	);
};

export default FilterInputDecimal;
