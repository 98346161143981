import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingIndicator from './LoadingIndicator';
interface TableFrameProps {
	id: string;
	loading?: boolean;
	skipCounter?: boolean;
	itemsCount?: number;
	itemsCountLabel?: string;
	header: any; // FIXME: put proper type
}
const TableFrame: React.FC<TableFrameProps> = ({
	id,
	loading,
	itemsCount,
	itemsCountLabel,
	skipCounter = false,
	header,
	children,
}) => {

	return (
		<>
			<Grid id={`table-${id}`} container spacing={3}>
				<Grid id={`table-header-${id}`} container item xs={12} spacing={0}>
					{header}
				</Grid>
				<LoadingIndicator loading={loading} />
				{!loading && children}
			</Grid>
			{!loading && (
				<Typography color="textSecondary" marginTop={6}>
					{itemsCount} {itemsCountLabel}
				</Typography>
			)}
		</>
	);
};

export default TableFrame;
