import ERROR_CODES, { ERROR_CODES_INTERNAL } from './errors';

export const extractErrorMessages = (errors) => {
	if (!Array.isArray(errors) || !errors.length) throw new Error('errors object must be non-empty array');

	return errors.reduce((acc, error, index) => {
		const errorMessage = error.extensions?.remark || error.message;
		if (index === 0) return errorMessage;
		return errorMessage ? `${acc} \n ${errorMessage}` : acc;
	}, '');
};

export const hasSubmissionErrors = (errors) => errors?.length > 0;

export const isInvalidCredentials = (errors) => {
	return (
		Array.isArray(errors) &&
		errors.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.AUTHENTICATION_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.INVALID_CREDENTIALS
		).length === 1
	);
};

export const isDuplicate = (errors) => {
	return (
		Array.isArray(errors) &&
		errors.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.REQUEST_INPUT_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.NAME_ALREADY_IN_USE
		).length === 1
	);
};

export const isInvalidObjectState = (errors) => {
	return (
		Array.isArray(errors) &&
		errors.filter((error) => error.extensions?.internal_code === ERROR_CODES.INTERNAL.INVALID_OBJECT_STATE)
			.length === 1
	);
};

export const isInvalidParameter = (errors) => {
	return (
		Array.isArray(errors) &&
		errors.filter((error) => error.extensions?.internal_code === ERROR_CODES.INTERNAL.INVALID_PARAMETERS).length ===
			1
	);
};
export const errorContainsRemark = (errors, remark) => {
	return Array.isArray(errors) && errors.filter((error) => error.extensions?.remark === remark).length === 1;
};

export const isSessionExpired = (errors) => {
	if (!Array.isArray(errors)) return false;

	return (
		errors?.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.AUTHENTICATION_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.INVALID_TOKEN_IN_REQUEST
		).length === 1
	);
};

export const isNotFound = (errors) => {
	if (!Array.isArray(errors)) return false;

	return (
		errors?.filter(
			(error) =>
				error.extensions?.code === ERROR_CODES.GLOBAL.REQUEST_INPUT_ERROR &&
				error.extensions?.internal_code === ERROR_CODES.INTERNAL.OBJECT_NOT_FOUND
		).length === 1
	);
};

export const isNotPushedToProd = (errors) => {
	if (!Array.isArray(errors)) return false;
	return Boolean(errors?.find((error) => error.extensions?.remark === 'No push to production ever executed'));
};

export const isBackofficeUser = (errors) => {
	if (!errors) return false;

	const errorCodes = errors?.map((error) => error.extensions?.internal_code);

	return errorCodes.includes(ERROR_CODES_INTERNAL.MARKETPLACE_USER_ROLE_REQUIRED);
};

const FETCH_ERRORS = ['ERR_CONNECTION_REFUSED', 'ERR_CONNECTION_SSL_REFUSED', 'UNEXPECTED_ERROR'];

export const isFetchError = (errors) => {
	if (!errors) return false;

	const errorCodes = errors?.map((error) => error.extensions.code);

	return errorCodes.some((item) => FETCH_ERRORS.includes(item));
};
