import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	MutationUpdateParticipantArgs,
	MutationCloseMyParticipantAccountArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

const UPDATE_PARTICIPANT = gql`
	mutation UPDATE_PARTICIPANT($id: ID!, $fields: [FieldInput!]) {
		updateParticipant(id: $id, fields: $fields)
	}
`;

const updateParticipant = ({ id, fields }: MutationUpdateParticipantArgs) => {
	let variables = { id, fields: undefined } as MutationUpdateParticipantArgs;

	if (fields !== null && fields !== undefined && fields.length > 0) {
		variables.fields = fields;
	}

	return JSON.stringify({
		query: print(UPDATE_PARTICIPANT),
		variables,
	});
};

const CLOSE_MY_PARTICIPANT_ACCOUNT = gql`
	mutation CLOSE_MY_PARTICIPANT_ACCOUNT($id_participant: ID!, $current_password: String!) {
		closeMyParticipantAccount(id_participant: $id_participant, current_password: $current_password)
	}
`;

const closeMyParticipantAccount = ({ id_participant, current_password }: MutationCloseMyParticipantAccountArgs) => {
	return JSON.stringify({
		query: print(CLOSE_MY_PARTICIPANT_ACCOUNT),
		variables: { id_participant, current_password },
	});
};

export { updateParticipant, closeMyParticipantAccount };
