import { Divider, Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Form } from 'react-final-form';
import ClearIcon from '@mui/icons-material/Clear';
import { AvailabilityManagementKind, AvailabilityTrackingKind, Field, FieldAccessKind, ObjectType, SupplyVariant } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import ConditionalFieldInput from '../fields/input/ConditionalFieldInput';
import FieldInput from '../fields/input/FieldInput';
import arrayMutators from 'final-form-arrays';
import { FormApi } from 'final-form';
import FieldInputInteger from '../fields/input/FieldInputInteger';

const mapSupplyVariantFieldValuesToFormValues = (fields: Field[]): any => {
	const initValues = {};
	fields?.forEach((field) => Object.assign(initValues, { [field.field_type.tech_name]: field.value }));
	return initValues;
};

const createSupplyVariantInitialFormValues = (supplyVariant: SupplyVariant) => {
	const initValues = mapSupplyVariantFieldValuesToFormValues(supplyVariant?.fields)

	Object.assign(initValues, { remaining_qty: supplyVariant?.availability?.data?.remaining_qty ?? null })

	return initValues;
}

interface CreateOrUpdateSupplyVariantDialogProps {
	open: boolean;
	supplyVariant: SupplyVariant;
	variantFields: Field[];
	mode: 'create' | 'update';
	sharedObjectTypes: ObjectType[];
	sharedObjects: any[]; //TODO: @Rokva type
	onClose: () => void;
	onSubmit: (values: any, form: FormApi) => any;
}

const CreateOrUpdateSupplyVariantDialog = ({
	open,
	supplyVariant,
	variantFields,
	mode,
	sharedObjectTypes,
	sharedObjects,
	onClose,
	onSubmit,
}: CreateOrUpdateSupplyVariantDialogProps) => {

	const simpleStockAvailabilityEnabled =
		supplyVariant?.availability?.availability_management_type === AvailabilityManagementKind.Default &&
		supplyVariant?.availability?.availability_tracking_type === AvailabilityTrackingKind.SimpleStock;
	return (
		<Dialog
			aria-labelledby="create-or-update-supply-variant-dialog"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			BackdropProps={{
				sx: {
					backdropFilter: 'blur(5px)',
					backgroundColor: 'rgba(0,0,30,0.5)',
				},
			}}
		>
			<DialogTitle id="create-or-update-supply-variant-dialog-title">
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h6" fontWeight={700}>
						{mode === 'create' ? 'Add new variant' : 'Edit variant'}
					</Typography>
					<IconButton onClick={onClose}>
						<ClearIcon fontSize="medium" />
					</IconButton>
				</Stack>
			</DialogTitle>
			<Divider />
			<Form
				onSubmit={onSubmit}
				initialValues={createSupplyVariantInitialFormValues(supplyVariant)}
				mutators={{
					...arrayMutators,
				}}
				render={({ handleSubmit, submitting, pristine }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								{mode === 'update' && simpleStockAvailabilityEnabled && <FieldInputInteger techName='remaining_qty' label='Quantity' />}
								{variantFields?.map((field: Field) => {
									const readOnlyField = FieldAccessKind.Read === field.my_access;

									if (field.field_type.is_variant_identifier || field.field_type.is_variant_specific)
										return (
											<ConditionalFieldInput
												key={field?.field_type.tech_name}
												fields={supplyVariant?.fields}
												conditions={field?.field_type?.conditions as any}
											>
												<Grid item xs={12}>
													<FieldInput
														label={
															field.field_type?.ui_config?.label ?? field.field_type?.name
														}
														sharedObjects={sharedObjects}
														sharedObjectTypes={sharedObjectTypes}
														optionsUiConfig={field?.field_type?.ui_config?.options ?? undefined}
														helperText={field.field_type?.ui_config?.helper_text}
														fieldTechName={field.field_type.tech_name}
														disabled={submitting || readOnlyField}
														inputType={field?.field_type?.input_type}
														required={field?.field_type?.is_required}
														inputOptions={field?.field_type.input_options}
														objectTypeFields={(field as any)?.object_type?.fields}
													/>
												</Grid>
											</ConditionalFieldInput>
										);
								})}

								<Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
									<Button
										variant="text"
										onClick={() => onClose()}
										color="inherit"
										disabled={submitting}
									>
										Cancel
									</Button>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={pristine || submitting}
									>
										Save
									</Button>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default CreateOrUpdateSupplyVariantDialog;
