import { useFormState } from 'react-final-form';
import { Field, FieldConditionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface IsConditionFullFilledArgs {
    currentValue: any;
    operator: any;
    expectedValue: any[];
}

const isConditionFullFilled = ({ currentValue, operator, expectedValue }: IsConditionFullFilledArgs) => {
    switch (operator) {
        case FieldConditionKind.ValueEqualTo:
            return JSON.stringify(currentValue) === JSON.stringify(expectedValue);
        case FieldConditionKind.ValueNotEqualTo:
            return JSON.stringify(currentValue) !== JSON.stringify(expectedValue);
        case FieldConditionKind.ValueProvided:
            if (currentValue?.length > 0)
                return true;
            if (currentValue?.length === 0)
                return false;
            return !['', null, undefined].includes(currentValue);

        case FieldConditionKind.ValueIsIn:
            if (typeof currentValue === 'string')
                return expectedValue.includes(currentValue);

            return expectedValue.filter((value) => currentValue?.includes(value))?.length > 0;

        case FieldConditionKind.ValueIsNotIn:
            if (typeof currentValue === 'string')
                return !expectedValue.includes(currentValue);

            return expectedValue.filter((value) => currentValue?.includes(value))?.length === 0;

        default:
            return false;
    }
};

interface AreConditionsFulfilledProps {
    values: any;
    fields: Field[];
    conditions: any[];
}

// Assumes AND operators between all conditions
const areConditionsFulfilled = ({ values, fields, conditions }: AreConditionsFulfilledProps) => {
    let conditionsFulfillment = conditions?.map((condition) => {
        const dependsOnField = fields?.find((field) => field.field_type.id === condition?.payload.id_source);
        const fieldTechName = dependsOnField?.field_type.tech_name ?? '';
        const fieldValue = values[fieldTechName];
        const isFieldMonetaryValue = fieldValue?.amount

        const expectedValue = isFieldMonetaryValue ? condition.payload.value * 100 : condition.payload.value;
        const operator = condition.type;

        return isConditionFullFilled({ currentValue: isFieldMonetaryValue ? values[fieldTechName].amount : values[fieldTechName], operator, expectedValue });
    });

    return !conditionsFulfillment.includes(false);
};

interface ConditionalFieldDisplayProps {
    fields: Field[];
    conditions: any[];
    values: { [key: string]: any }
    children: JSX.Element;
}

const ConditionalFieldDisplay = ({ fields, values, conditions, children }: ConditionalFieldDisplayProps) => {
    let hasConditions = conditions?.length > 0;

    if (!hasConditions) return children;

    return areConditionsFulfilled({ values, fields, conditions }) ? children : null;
};

export default ConditionalFieldDisplay;
