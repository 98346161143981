import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import { Box, Divider, Grid, Paper, Skeleton, Stack } from '@mui/material';
import ShoppingCartEmptyState from '@rdv-fo/components/transaction/multi-direct/ShoppingCartEmptyState';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import {
	loadMyActiveShoppingCart,
	manualTransitionTriggeredAndNewShoppingCartCreated,
	removeItemFromShoppingCart,
	selectLoading,
	selectMyActiveShoppingCart,
	triggerActiveShoppingCartManualTransition,
	updateShoppingCartItem,
	updateShoppingCartItemFailed,
} from '@rdv-fo/store/slices/shoppingCartSlice';
import ShoppingCartItems from '@rdv-fo/components/transaction/multi-direct/ShoppingCartItems';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import RemoveShoppingCartItemDialog from '@rdv-fo/components/transaction/multi-direct/RemoveShoppingCartItemDialog';
import { findFieldByTechName } from '@rdv-fo/app/lib/fieldsHelper';
import FlowManualActionsPanel from '@rdv-fo/components/transaction/FlowManualActionsPanel';
import EditShoppingCartItemDrawer from '@rdv-fo/components/transaction/multi-direct/EditShoppingCartItemDrawer';
import {
	loadMySharedObjects,
	loadMySharedObjectTypes,
	selectMySharedObjects,
	selectMySharedObjectTypes,
} from '@rdv-fo/store/slices/sharedObjectsSlice';
import { pick } from 'lodash';
import { mapFormValuesToParameterInputs } from '@rdv-fo/app/lib/formHelpers';
import { calculateSupplyPrice, selectCalculatedSupplyPrice } from '@rdv-fo/store/slices/discoverySlice';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import ShoppingCartPaymentSuccessView from '@rdv-fo/components/transaction/multi-direct/ShoppingCartPaymentSuccessView';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_50 } from '@rdv-fo/styles/theme';
import { CurrencyKind, MatchStatusKind, RandevuEventKind, TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { useHistory } from 'react-router';
import ROUTES from '@rdv-fo/common/routes';
import TransactionPaymentCheckoutButton from '@rdv-fo/components/transaction/TransactionPaymentCheckoutButton';

const ShoppingCartPage = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();
	const calculatedSupplyPrice = useAppSelector(selectCalculatedSupplyPrice);
	const shoppingCart = useAppSelector(selectMyActiveShoppingCart);
	const sharedObjects = useAppSelector(selectMySharedObjects);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const paymentTypes = shoppingCart?.type?.payment_types;
	const [currentItemId, setCurrentItemId] = useState<string | null>(null);
	const currentItem = currentItemId ? shoppingCart?.items?.find((item: any) => item.id === currentItemId) : null;


	useEffect(() => {
		dispatch(loadMyActiveShoppingCart());
		dispatch(loadMySharedObjectTypes());
		dispatch(loadMySharedObjects({ where: {} }));
	}, []);

	const loading = useAppSelector(selectLoading);

	const [openRemoveItemDialog, setOpenRemoveItemDialog] = useState<boolean>(false);
	const [openEditItemDrawer, setOpenEditItemDrawer] = useState<boolean>(false);

	const matchParameterTypes = shoppingCart?.type?.match_configuration?.match_parameter_types;
	const matchParameterTechNames =
		shoppingCart?.type?.match_configuration?.match_parameter_types?.map((mt) => mt.tech_name) ?? [];
	const priceAffectingTechNames = matchParameterTechNames;


	const supplyTypeName = (shoppingCart?.type?.root_type as any)?.match_type?.supply_type?.name;
	const transactionTypeTechName = shoppingCart?.type?.tech_name ?? '';

	const shoppingCartItemsExist = shoppingCart?.items?.length && shoppingCart?.items?.length > 0;
	const matchParameterTypeTechNamesWithoutQty: any =
		shoppingCart?.type?.match_configuration?.match_parameter_types?.reduce((acc, mpt): any => {
			if (mpt?.tech_name === 'qty') return acc;

			return [...acc, mpt?.tech_name];
		}, []);
	const matchParameterTypeTechNames: any = shoppingCart?.type?.match_configuration?.match_parameter_types?.map(
		(mpt): any => mpt?.tech_name
	);
	const isMatchConfigurationPricingEnabled = shoppingCart?.type?.match_configuration?.pricing_enabled ?? false;

	let totalArticles = 0;
	shoppingCart?.items?.forEach((item) => (totalArticles = totalArticles + (item?.price?.total_before_tax ?? 0)));

	let totalDiscounts = 0;
	shoppingCart?.items?.forEach((item) => {
		const discountItems = item?.price?.discount_items;
		discountItems?.forEach((discountItem) => {
			totalDiscounts = totalDiscounts + (discountItem?.value ?? 0);
		});
	});

	const shoppingCartItemsGroupedByProvider = {} as any;
	shoppingCart?.items?.forEach((item) => {
		const currItems = shoppingCartItemsGroupedByProvider?.[item.id_provider] ?? [];
		Object.assign(shoppingCartItemsGroupedByProvider, { [item.id_provider]: [...currItems, item] });
	});

	let totalFees = 0;
	shoppingCart?.items?.forEach((item) => (totalFees = totalFees + (item?.price?.total_fees ?? 0)));

	const nextSteps = shoppingCart?.state?.next_steps ?? [];
	const message = shoppingCart?.state?.message ?? '';

	const handleOpenRemoveItemDialog = (itemId: string) => {
		setCurrentItemId(itemId);
		setOpenRemoveItemDialog(true);
	};
	const handleCloseRemoveItemDialog = () => {
		setOpenRemoveItemDialog(false);
		setCurrentItemId(null);
	};

	const handleOpenEditItemDrawer = (itemId: string) => {
		setCurrentItemId(itemId);
		setOpenEditItemDrawer(true);
	};
	const handleCloseEditItemDrawer = () => {
		setOpenEditItemDrawer(false);
		setCurrentItemId(null);
	};

	const handleRemoveItemFromShoppingCart = async () => {
		await dispatch(removeItemFromShoppingCart({ itemId: currentItem!.id, cartId: shoppingCart!.id }));
		setOpenRemoveItemDialog(false);
		setCurrentItemId(null);
	};

	const currentItemName = findFieldByTechName(currentItem?.supply_fields ?? [], 'name')?.value ?? '';

	const handleTriggerManualTransition = async (objectId: string, transitionTechName: string) => {
		const resolvedAction = await dispatch(
			triggerActiveShoppingCartManualTransition({
				idTransaction: objectId,
				transitionTechName: transitionTechName,
			})
		);
		if (resolvedAction.type === manualTransitionTriggeredAndNewShoppingCartCreated.type)
			history.push(ROUTES.MULTI_DIRECT_TRANSACTION_DETAILS.replace(':transactionId', objectId).replace(":transactionTypeTechName", transactionTypeTechName));
	};

	const handleSubmitEditShoppingCartForm = async (values: any, form: any) => {
		const { submit_type, ...restValues } = values;
		const dirtyFields = getDirtyFields({ ...restValues }, form);

		const checkNewPrice = submit_type === 'calculate_price';
		if (checkNewPrice && isMatchConfigurationPricingEnabled) {
			form.change('submit_type', undefined);
			return handleSubmitCalculateSupplyPrice(values);
		}

		if (checkNewPrice && !isMatchConfigurationPricingEnabled) {
			form.change('submit_type', undefined);
			return;
		}

		const fieldInputs = Object.entries(dirtyFields).map(([key, value]) => {
			return { tech_name: key, value: value };
		});

		const resolvedAction = await dispatch(
			updateShoppingCartItem({ cartId: shoppingCart?.id ?? '', itemId: currentItemId ?? '', fields: fieldInputs })
		);

		if (resolvedAction.type === updateShoppingCartItemFailed.type) return resolvedAction.payload;

		handleCloseEditItemDrawer();
	};

	const handleSubmitCalculateSupplyPrice = (values: any) => {
		if (isMatchConfigurationPricingEnabled) {
			const matchParameterValues = pick(values, priceAffectingTechNames);

			const parameter_inputs = mapFormValuesToParameterInputs(
				matchParameterValues,
				matchParameterValues,
				matchParameterTypes
			);

			dispatch(
				calculateSupplyPrice({
					id_supply: currentItem?.id_supply ?? '',
					match_parameters: parameter_inputs ?? [],
				})
			);
		}
	};

	const buildEditFormInitialValues = (fields: any[]) => {
		const initValues = {};
		fields.forEach((field) => Object.assign(initValues, { [field?.field_type?.tech_name]: field?.value }));
		return initValues;
	};

	const cumulativeSupplyPrice = {} as any;
	shoppingCart?.items?.forEach((item) => {
		if (Object.keys(cumulativeSupplyPrice).length < 1) {
			Object.assign(cumulativeSupplyPrice, item?.price);
			if (item?.price?.unit_price && item?.price?.qty)
				Object.assign(cumulativeSupplyPrice, { total_price: item.price.unit_price * item.price.qty });
		} else {
			if (item?.price?.unit_price && item?.price?.qty)
				cumulativeSupplyPrice.total_price = cumulativeSupplyPrice.total_price +=
					item.price.unit_price * item.price.qty;
			cumulativeSupplyPrice.total_before_tax = cumulativeSupplyPrice.total_before_tax +=
				item?.price?.total_before_tax;
			cumulativeSupplyPrice.total_taxes = cumulativeSupplyPrice.total_taxes += item?.price?.total_taxes;
			cumulativeSupplyPrice.total_fees = cumulativeSupplyPrice.total_fees += item?.price?.total_fees;
			cumulativeSupplyPrice.total_consumer_fees = cumulativeSupplyPrice.total_consumer_fees +=
				item?.price?.total_consumer_fees;
			cumulativeSupplyPrice.discount_items = [
				...cumulativeSupplyPrice.discount_items,
				...(item?.price?.discount_items ? item?.price?.discount_items : []),
			];
			cumulativeSupplyPrice.addon_items = [
				...cumulativeSupplyPrice.addon_items,
				...(item?.price?.addon_items ? item?.price?.addon_items : []),
			];
			cumulativeSupplyPrice.tax_items = [
				...cumulativeSupplyPrice.tax_items,
				...(item?.price?.tax_items ? item?.price?.tax_items : []),
			];
			cumulativeSupplyPrice.platform_fee_items = [
				...cumulativeSupplyPrice.platform_fee_items,
				...(item?.price?.platform_fee_items ? item?.price?.platform_fee_items : []),
			];
		}
	});


	const isPaymentSuccessful = Boolean(shoppingCart?.items?.find((item) => item.status === MatchStatusKind.Paid));
	const status = shoppingCart?.state?.status ?? '';

	const isPaymentStepAvailable = shoppingCart?.state?.next_steps?.find(
		(step) => step.config.event === RandevuEventKind.PaymentPrepared
	);


	const currency = shoppingCart?.chargeable?.currency ?? CurrencyKind?.Eur;
	return (
		<>
			{currentItem && (
				<EditShoppingCartItemDrawer
					showPrice={isMatchConfigurationPricingEnabled}
					initialValues={buildEditFormInitialValues(currentItem?.fields ?? [])}
					loading={loading}
					calculatedSupplyPrice={calculatedSupplyPrice}
					onSubmit={handleSubmitEditShoppingCartForm}
					matchParameterTypes={shoppingCart?.type?.match_configuration?.match_parameter_types ?? []}
					sharedObjectTypes={sharedObjectTypes}
					sharedObjects={sharedObjects}
					matchParameterTypeTechNames={matchParameterTypeTechNames}
					currentItem={currentItem}
					open={openEditItemDrawer}
					onClose={handleCloseEditItemDrawer}
				/>
			)}
			<RemoveShoppingCartItemDialog
				onRemoveItemFromShoppingCart={handleRemoveItemFromShoppingCart}
				itemName={currentItemName}
				onClose={handleCloseRemoveItemDialog}
				open={openRemoveItemDialog}
				loading={loading}
			/>
			<Stack direction="row" width="100%" justifyContent="space-between">
				<Typography component="h1" variant="h1">
					Shopping cart
				</Typography>
				<Box>
					{(nextSteps?.length > 0 || message) && (
						<FlowManualActionsPanel
							objectId={shoppingCart?.id ?? ''}
							nextSteps={nextSteps}
							message={''}
							onTriggerManualTransition={handleTriggerManualTransition}
							loading={loading}
							buttonVariant="contained"
						/>
					)}
				</Box>
			</Stack>
			{shoppingCartItemsExist ? (
				<Grid container direction="row" sx={{ mt: 5 }} justifyContent="space-between">
					<Grid item xs={12} md={7}>
						{loading ? (
							<Stack direction="column" spacing={5}>
								<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
									<AccountCircleOutlinedIcon fontSize="medium" />
									<Skeleton width={200} height={30} />
								</Stack>
								<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
									<Stack direction="row" spacing={2}>
										<Stack direction="column">
											<Skeleton width={150} height={150} />
										</Stack>
										<Stack direction="column" spacing={1}>
											<Skeleton />
											<Box>
												<Skeleton width={100} />
												<Skeleton width={100} />
												<Skeleton width={100} />
												<Skeleton width={100} />
											</Box>
										</Stack>
									</Stack>
								</Stack>
								<Divider />
								<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
									<AccountCircleOutlinedIcon fontSize="medium" />
									<Skeleton width={200} height={30} />
								</Stack>
								<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
									<Stack direction="row" spacing={2}>
										<Stack direction="column">
											<Skeleton width={150} height={150} />
										</Stack>
										<Stack direction="column" spacing={1}>
											<Skeleton />
											<Box>
												<Skeleton width={100} />
												<Skeleton width={100} />
												<Skeleton width={100} />
												<Skeleton width={100} />
											</Box>
										</Stack>
									</Stack>
								</Stack>
							</Stack>
						) : (
							<ShoppingCartItems
								showPrice={isMatchConfigurationPricingEnabled}
								onOpenEditItemDrawer={handleOpenEditItemDrawer}
								onOpenRemoveItemDialog={handleOpenRemoveItemDialog}
								items={Object.values(shoppingCartItemsGroupedByProvider)}
								matchParameterTypeTechNames={matchParameterTypeTechNamesWithoutQty}
							/>
						)}
					</Grid>
					<Grid item xs={12} md={4}>
						<Stack direction="column" spacing={1}>
							<Typography variant="h4">Summary</Typography>
							<>
								{isPaymentSuccessful ? (
									<Stack direction="column" spacing={2}>
										<Stack direction="column" spacing={-1}>
											<Typography variant="overline" component="p" sx={{ color: RDV_NEUTRAL_50 }}>
												{status}
											</Typography>
											<Typography variant="body1" fontWeight={'bold'}>
												{message}
											</Typography>
										</Stack>
										<ShoppingCartPaymentSuccessView />
									</Stack>
								) : (
									<>
										<Typography variant="overline" component="p" sx={{ color: RDV_NEUTRAL_50 }}>
											{status}
										</Typography>
										{message && (
											<Stack
												component={Paper}
												elevation={0}
												sx={{ backgroundColor: RDV_NEUTRAL_10 }}
												padding={4}
												justifyContent="center"
												alignItems="center"
												spacing={2}
											>
												<Typography variant="h4">{message}</Typography>
											</Stack>
										)}
									</>
								)}
								{isMatchConfigurationPricingEnabled && (
									<>
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">Total price</Typography>
											{loading ? (
												<Skeleton />
											) : (
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: cumulativeSupplyPrice?.total_price,
														currency,
													})}
												</Typography>
											)}
										</Stack>

										{loading ? (
											<Skeleton />
										) : (
											cumulativeSupplyPrice?.addon_items &&
											cumulativeSupplyPrice?.addon_items?.length > 0 &&
											cumulativeSupplyPrice?.addon_items.map((add_on: any) => (
												<Stack
													direction="row"
													sx={{ width: '100%' }}
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={2}
												>
													<Typography fontWeight="bold">{add_on?.description}</Typography>
													<Typography fontWeight="bold">
														{formatPriceAmount({
															amount: add_on?.value,
															currency,
														})}
													</Typography>
												</Stack>
											))
										)}
										{loading ? (
											<Skeleton />
										) : (
											cumulativeSupplyPrice?.discount_items &&
											cumulativeSupplyPrice?.discount_items?.length > 0 &&
											cumulativeSupplyPrice?.discount_items.map((discount: any) => (
												<Stack
													direction="row"
													sx={{ width: '100%' }}
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={2}
												>
													<Typography fontWeight="bold">{discount?.description}</Typography>
													<Typography fontWeight="bold">
														-
														{formatPriceAmount({
															amount: discount?.value,
															currency,
														})}
													</Typography>
												</Stack>
											))
										)}
										{loading ? (
											<Skeleton />
										) : (
											cumulativeSupplyPrice?.platform_fee_items?.length > 0 &&
											cumulativeSupplyPrice?.platform_fee_items.map((platform_fee: any) => (
												<Stack
													direction="row"
													sx={{ width: '100%' }}
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={2}
												>
													<Typography fontWeight="bold">
														{platform_fee?.description}
													</Typography>
													<Typography fontWeight="bold">
														{formatPriceAmount({
															amount: platform_fee?.value,
															currency,
														})}
													</Typography>
												</Stack>
											))
										)}
										<Divider flexItem />

										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">Total before taxes</Typography>
											{loading ? (
												<Skeleton />
											) : (
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: cumulativeSupplyPrice?.total_before_tax,
														currency,
													})}
												</Typography>
											)}
										</Stack>

										{loading ? (
											<Skeleton />
										) : (
											cumulativeSupplyPrice?.tax_items?.length > 0 &&
											cumulativeSupplyPrice?.tax_items.map((tax: any) => (
												<Stack
													direction="row"
													sx={{ width: '100%' }}
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={2}
												>
													<Typography fontWeight="bold">{tax?.description}</Typography>
													<Typography fontWeight="bold">
														{formatPriceAmount({
															amount: tax?.value,
															currency,
														})}
													</Typography>
												</Stack>
											))
										)}
										<Divider flexItem />
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">Total</Typography>
											{loading ? (
												<Skeleton />
											) : (
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount:
															cumulativeSupplyPrice?.total_before_tax +
															cumulativeSupplyPrice?.total_taxes,
														currency,
													})}
												</Typography>
											)}
										</Stack>

										{cumulativeSupplyPrice?.total_consumer_fees ? (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">Total consumer fees</Typography>
												{loading ? (
													<Skeleton />
												) : (
													<Typography fontWeight="bold">
														{formatPriceAmount({
															amount: cumulativeSupplyPrice?.total_consumer_fees,
															currency,
														})}
													</Typography>
												)}
											</Stack>
										) : (
											<></>
										)}
										<Divider flexItem />
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">Adjusted total</Typography>
											{loading ? (
												<Skeleton />
											) : (
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount:
															cumulativeSupplyPrice?.total_before_tax +
															cumulativeSupplyPrice?.total_taxes +
															cumulativeSupplyPrice?.total_fees,
														currency,
													})}
												</Typography>
											)}
										</Stack>
										{isPaymentStepAvailable && (
											<Box pt={2}>

												{(paymentTypes?.length ?? 0) === 0 && <Typography>Cannot continue to payment because no payment type is assigned to current transaction type.</Typography>}


												{(paymentTypes?.length ?? 0) >= 1 &&

													<Stack
														direction="column"
														justifyContent="flex-start"
														alignItems="stretch"
														spacing={2}
													>


														{paymentTypes?.map(pt => {

															const btnLabel = `${isPaymentStepAvailable?.action_message} ${pt.name?.toLowerCase()}` ?? 'Checkout'
															const acceptedPaymentMethods = pt.payment_methods?.[0]?.accepted_methods ?? []


															return (
																<TransactionPaymentCheckoutButton
																	redirectBackTo={TransactionKind.MultipleDirect}
																	transactionTechName={transactionTypeTechName}
																	label={btnLabel}
																	transactionId={shoppingCart?.id}
																	acceptedPaymentMethods={acceptedPaymentMethods}
																	paymentTypeTechName={pt.tech_name}
																/>
															)
														})

														}
													</Stack>}

											</Box>
										)}
									</>
								)}
							</>
						</Stack>
					</Grid>
				</Grid>
			) : (
				<Box sx={{ mt: 5 }}>
					{loading ? (
						<Skeleton height={300} />
					) : (
						<ShoppingCartEmptyState
							supplyTypeName={supplyTypeName}
							transactionTypeTechName={transactionTypeTechName}
						/>
					)}
				</Box>
			)}
		</>
	);
};

export default ShoppingCartPage;
