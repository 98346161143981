import { Button, FormHelperText, Stack, Typography, useTheme } from '@mui/material';
import { Field } from 'react-final-form';

import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import LabelFieldInput from './LabelFieldInput';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import isFileSizeAcceptable from '@rdv-fo/app/lib/fileSizeValidator';
import { FieldInputBaseProps } from './types';
import { RdvFileFieldValue } from '@rdv-fo/services/randevuApi/types/field/';

interface ImageInputOptions {
	required: boolean;
	maxSize?: number;
}

const validate = (value: File | RdvFileFieldValue | undefined, options: ImageInputOptions) => {
	const { maxSize, required } = options;

	// no need to validate already updated  file
	if (value as RdvFileFieldValue) return undefined;

	// no need to validate that its mandatory
	if (!required && value === undefined) return undefined;

	// validate its mandatory
	if (required && value === undefined) return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	const js_file = value as File;

	if (maxSize && js_file && !isFileSizeAcceptable({ maxAllowedSize: maxSize, fileSize: js_file.size }))
		return VALIDATION_MESSAGE.MAX_FILE_SIZE_VIOLATION.replace('{max_size}', String(maxSize));

	return undefined;
};

const getAcceptedImageFormats = (allowedTypes: string[] | undefined) =>
	allowedTypes?.map((type) => 'image/' + type.toLowerCase()).join(', ');

interface FieldInputImageProps extends FieldInputBaseProps {
	allowedTypes: string[];
	maxSize?: number;
}

const FieldInputImage = ({
	techName,
	label,
	labelProps,
	disabled = false,
	required = false,
	allowedTypes,
	maxSize,
}: FieldInputImageProps) => {
	const theme = useTheme();

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>

			{maxSize && (
				<Typography color="textSecondary" variant="body2">
					{`Maximum allowed file size: ${maxSize} MB`}
				</Typography>
			)}
			<div>
				<Field name={techName} validate={(value) => validate(value, { required, maxSize })}>
					{({ input: { value, onChange, ...input }, meta }) => {
						const { submitError, dirtySinceLastSubmit, error, pristine, submitFailed } = meta;

						let selectedImageUrl = undefined;
						if (value && value instanceof File) selectedImageUrl = URL.createObjectURL(value);
						if (value && !(value instanceof File)) selectedImageUrl = value.url;

						const acceptedImages = getAcceptedImageFormats(allowedTypes);

						return (
							<>
								{((error && !pristine) || (error && submitFailed)) && (
									<FormHelperText error={true}>{error}</FormHelperText>
								)}
								{submitError && !dirtySinceLastSubmit && (
									<FormHelperText error={true}>{submitError}</FormHelperText>
								)}
								{!selectedImageUrl && (
									<>
										<br />
										<Button
											variant="outlined"
											color="primary"
											component="label"
											htmlFor={techName}
											size="small"
											startIcon={<CloudUploadOutlinedIcon />}
											sx={{ marginTop: 2 }}
											disabled={disabled}
										>
											Choose Image
											<input
												{...input}
												id={techName}
												name={techName}
												type="file"
												hidden
												accept={acceptedImages}
												required={required}
												onChange={({ target }) => {
													// TODO: FIXME PREVENT CHOOSING FILES THAT ARE BIGGER THAN ALLOWED
													if (target.files !== null && target.files.length > 0)
														onChange(target.files[0]);
												}}
											/>
										</Button>
										<br />
										<br />
									</>
								)}

								{selectedImageUrl && (
									<>
										<div>
											<img
												src={selectedImageUrl}
												alt={label}
												style={{
													maxWidth: 200,
													maxHeight: 200,
													marginTop: theme.spacing(4),
													borderRadius: 15,
												}}
											></img>
										</div>
										<div>
											<Button
												variant="text"
												color="primary"
												startIcon={<ClearIcon />}
												onClick={() => {
													return onChange(null);
												}}
												size="small"
												disabled={disabled}
											>
												Remove image
											</Button>
										</div>
									</>
								)}
							</>
						);
					}}
				</Field>
			</div>
		</Stack>
	);
};

export default FieldInputImage;
