import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useHistory } from 'react-router';
import ROUTES from '@rdv-fo/common/routes';
import {
    InputKind,
    ShoppingCartOrderLight,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DEFAULT_OPTIONS_MRT_TABLE, DEFAULT_STYLES_MRT_TABLE, } from '@rdv-fo/app/lib/tableHelpers';
import TransactionStatusChip from '../TransactionStatusChip';
import { useMemo } from 'react';
import { TransactionMappers, MultiDirectMatchesRow } from '@rdv-fo/app/lib/transactionHelpers';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';


const NoDataView = () => {
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="subtitle1">No orders yet</Typography>
            <Typography variant="body2">Your orders will appear hear</Typography>
        </Stack>
    );
};

interface MultiDirectMatchesTableProps {
    transactionTechName: string;
    matches: ShoppingCartOrderLight[];
    loading: boolean;
}

const MultiDirectMatchesTable = ({ transactionTechName, matches, loading }: MultiDirectMatchesTableProps) => {
    const history = useHistory();

    const columns = useMemo<MRT_ColumnDef<MultiDirectMatchesRow>[]>(
        () => [
            {
                accessorKey: '_id',
                header: 'ID',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Text} value={data} />;
                },
            },
            {
                accessorKey: 'state',
                header: 'State',
                Cell: ({ cell, row }) => {
                    const data = cell.getValue<string>();
                    return (
                        <TransactionStatusChip
                            status={data}
                            createdAt={row.original?.created_at}
                            startedAt={row.original?.initiated_at}
                            cancelledAt={row.original?.cancelled_at}
                            terminatedAt={row.original?.terminated_at}
                        />
                    );
                },
            },
            {
                accessorKey: 'gross_value',
                header: 'Gross value',
                Cell: ({ cell, row }) => {
                    const data = cell.getValue<number | undefined>();

                    let arg: any = { hideCurrency: true }

                    if (row.original.currency) {
                        arg = {
                            currency: row.original.currency
                        }
                    }


                    if (data) return <FieldView inputKind={InputKind.Text} value={formatPriceAmount({
                        amount: data,
                        ...(arg)

                    })} />

                    return (
                        <FieldView inputKind={InputKind.Text} value={"-"} />
                    );
                },
            },
            {
                accessorKey: 'created_at',
                header: 'Created',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Datetime} value={data} />;
                },
            },
            {
                accessorKey: 'initiated_at',
                header: 'Started',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Datetime} value={data} />;
                },
            },
            {
                accessorKey: 'terminated_at',
                header: 'Finished',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Datetime} value={data} />;
                },
            },


        ],
        []
    );

    const rows = useMemo(
        () => TransactionMappers.toMultiDirectMatchesRow(matches),
        [matches]
    );

    return (
        <>
            <MaterialReactTable
                {...DEFAULT_STYLES_MRT_TABLE}
                {...DEFAULT_OPTIONS_MRT_TABLE}
                initialState={{
                    columnVisibility: {
                        _id: false,
                    },
                    pagination: { pageSize: 50, pageIndex: 0 },
                    isLoading: loading
                }}
                columns={columns}
                enablePinning={false}
                data={rows}
                enableTableHead={true}
                enableSorting={false}
                enablePagination={false}
                enableDensityToggle={false}
                enableGlobalFilter={false}
                enableColumnFilters={false} //disable all column filters
                enableColumnFilterModes={false}
                rowCount={matches?.length}
                muiTableBodyProps={{
                    children: matches?.length < 1 ? <NoDataView /> : null,
                }}
                positionActionsColumn="last"
                enableRowActions={true}
                renderRowActions={({ row }) => {
                    const transactionId = row.original._id;
                    const targetRoute = routeBuilder(ROUTES.MY_MULTI_DIRECT_MATCHES_DETAILS, [
                        [':transactionId', transactionId], [":transactionTypeTechName", transactionTechName]
                    ]);
                    return (
                        <IconButton onClick={() => history.push(targetRoute)}>
                            <ArrowForwardIcon />
                        </IconButton>
                    );
                }}
                enableFullScreenToggle={false}
                enableColumnActions={false}
                enableRowOrdering={false}
                enableHiding={true}
                enableTableFooter={false}
                renderBottomToolbar={
                    matches?.length > 0 &&
                    (() => {
                        let label = `${matches?.length} orders`;
                        if (matches?.length === 1) label = `${matches?.length} order`;

                        return (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
                                {label}
                            </Typography>
                        );
                    })
                }
            />
        </>
    );
};

export default MultiDirectMatchesTable;
