import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { validators } from '@rdv-fo/app/lib/validation';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { closeMyParticipantAccount, closeMyParticipantAccountFailed, signOut } from '@rdv-fo/store/slices/authSlice';
import { useState } from 'react';
import { FormApi } from 'final-form';

export type CloseMyParticipantAccountFormValues = {
	current_password: string;
	screen: 'enter-password' | 'account-closed';
};

interface CloseMyParticipantAccountDialogProps {
	open: boolean;
	participantId: string;
	onClose: () => void;
}

const CloseMyParticipantAccountDialog = ({ open, participantId, onClose }: CloseMyParticipantAccountDialogProps) => {
	const dispatch = useAppDispatch();
	const [showPassword, setShowPassword] = useState(false);

	const fieldValidators = {
		current_password: [validators.required],
	};

	const handleSubmitCloseMyParticipantAccount = async (
		values: CloseMyParticipantAccountFormValues,
		form: FormApi<CloseMyParticipantAccountFormValues>
	) => {
		const resolvedAction = await dispatch(
			closeMyParticipantAccount({ current_password: values.current_password, id_participant: participantId })
		);

		if (resolvedAction.type === closeMyParticipantAccountFailed.type) return resolvedAction.payload;



		form.change('screen', 'account-closed');
	};

	return (
		<Form
			onSubmit={handleSubmitCloseMyParticipantAccount}
			validate={(values) => validators.validate(fieldValidators, values)}
		>
			{({ handleSubmit, submitting, pristine, hasValidationErrors, values: { screen } }) => {
				const disableFormElement = submitting || pristine || hasValidationErrors;

				return (
					<Dialog
						fullWidth
						open={open}
						maxWidth="sm"
						onClose={() => {
							if (submitting) return undefined;
							if (screen === 'account-closed') return undefined;
							onClose();
						}}
					>
						<DialogTitle variant="h4">
							Close account
							{screen !== 'account-closed' && (
								<IconButton
									aria-label="close"
									disabled={submitting}
									onClick={() => {
										onClose();
									}}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
										color: 'black',
									}}
								>
									<CloseIcon />
								</IconButton>
							)}
						</DialogTitle>
						<Divider />
						<DialogContent>
							<form id="close-participant-account-form" onSubmit={handleSubmit}>
								<Stack direction="column" spacing={4} alignItems="stretch" sx={{ pt: 2 }}>
									{screen !== 'account-closed' && (
										<TextField
											id="current_password"
											name="current_password"
											required={true}
											variant="outlined"
											size="small"
											type={showPassword ? 'text' : 'password'}
											fullWidth
											label="Current password"
											placeholder="Enter your current password"
											disabled={submitting}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowPassword(!showPassword)}
															onMouseDown={() => setShowPassword(!showPassword)}
															size="large"
															disabled={submitting}
														>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									)}

									{screen === 'account-closed' && (
										<Typography>
											Your account has been successfully closed. After clicking on ok button,
											you will be logged out and would not able to log in again.
										</Typography>
									)}
								</Stack>

								{screen !== 'account-closed' && (
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
										sx={{ mt: 6, mb: 0.5 }}
									>
										<Button
											color="inherit"
											size="small"
											onClick={() => {
												onClose();
											}}
											disabled={submitting}
										>
											Cancel
										</Button>
										<Stack direction="row" spacing={2}>
											<LoadingButton
												color="error"
												type="submit"
												variant="contained"
												form="close-participant-account-form"
												loading={submitting}
												disabled={disableFormElement}
												size="small"
												onClick={handleSubmit}
											>
												Confirm
											</LoadingButton>
										</Stack>
									</Stack>
								)}
								{screen === 'account-closed' && (
									<Stack
										direction="row"
										justifyContent="flex-end"
										alignItems="center"
										spacing={2}
										sx={{ mt: 6, mb: 0.5 }}
									>
										<Button
											color="primary"
											variant="contained"
											size="small"
											onClick={() => {
												dispatch(signOut());
											}}
										>
											Ok
										</Button>
									</Stack>
								)}
							</form>
						</DialogContent>
					</Dialog>
				);
			}}
		</Form>
	);
};

export default CloseMyParticipantAccountDialog;
