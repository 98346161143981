import routeBuilder from '@rdv-fo/common/routeBuilder';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from '@rdv-fo/store/configureStore';
import localEnvConfig from '@rdv-fo/common/.localDevelopmentConfig';

import { isNotFound, isNotPushedToProd, isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import randevu from '@rdv-fo/services/randevuApi';
import { goToRoute, sendErrorToasty, sendWarningToasty } from './uiSlice';
import ROUTES from '@rdv-fo/common/routes';
import {
	MarketplaceEnvironmentKind,
	IntegrationProviderLight,
	ParticipantType,
	FrontofficeUiConfig,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { forceGuest, loadCurrentUser } from './authSlice';
import { transactionTypesLoaded } from './transactionSlice';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { loadMySharedObjectTypes, sharedObjectsLoaded, sharedObjectTypesLoaded } from './sharedObjectsSlice';

interface PlatformSliceState {
	loading: boolean;
	initializing: 'idle' | 'loading' | 'failed' | 'succeeded';
	errors: any | null;
	platform_name: string | null;
	platform_display_name: string | null;
	isPlatformInitComplete: boolean;
	ui_config: FrontofficeUiConfig | null;
	participant_types: ParticipantType[] | null;
	integration_providers: IntegrationProviderLight[] | null;
	public_key: string | null;
	rdv_fo_enabled: boolean | null;
}

const initialState: PlatformSliceState = {
	loading: false,
	initializing: 'loading',
	errors: null,
	platform_name: '',
	platform_display_name: '',
	isPlatformInitComplete: false,
	ui_config: null,
	participant_types: null,
	integration_providers: null,
	public_key: null,
	rdv_fo_enabled: null,
};

const slice = createSlice({
	name: 'platform',
	initialState: initialState,
	reducers: {
		initPlatformRequested: (
			platform: PlatformSliceState,
		) => {
			platform.initializing = 'loading';
			platform.errors = null;
		},
		initPlatformFailed: (platform: PlatformSliceState) => {
			platform.initializing = 'failed';
			platform.errors = null;
		},
		platformInitialized: (
			platform: PlatformSliceState,
			action: PayloadAction<{
				platform_name: string;
				platform_display_name: string;
				participant_types: ParticipantType[];
				integration_providers: IntegrationProviderLight[];
				ui_config: FrontofficeUiConfig;
				public_key: string;
				rdv_fo_enabled: boolean;
			}>
		) => {
			const {
				public_key,
				platform_name,
				platform_display_name,
				participant_types,
				integration_providers,
				ui_config,
				rdv_fo_enabled,
			} = action.payload;

			platform.platform_name = platform_name;
			platform.platform_display_name = platform_display_name;
			platform.participant_types = participant_types;
			platform.integration_providers = integration_providers;
			platform.ui_config = ui_config;
			platform.public_key = public_key;
			platform.rdv_fo_enabled = rdv_fo_enabled;

			platform.errors = null;
		},
		publicKeyLoaded: (platform: PlatformSliceState, action: PayloadAction<string>) => {
			platform.public_key = action.payload;

			platform.errors = null;
		},
		platformInitializationCompleted: (platform: PlatformSliceState) => {
			platform.initializing = 'succeeded';
		},

		platformParticipantTypesChanged: (platform: PlatformSliceState, action) => {
			platform.participant_types = [...action.payload];
		},
		setPlatformInitComplete: (platform: PlatformSliceState) => {
			platform.isPlatformInitComplete = true;
		},
	},
});

export const {
	initPlatformRequested,
	initPlatformFailed,
	platformInitialized,
	platformInitializationCompleted,
	platformParticipantTypesChanged,
	publicKeyLoaded,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

interface InitializePlatformArgs {
	platform_name?: string;
	domain_name?: string;
	init_mode: 'guest' | 'participant' | 'none';
	redirectTo?: string;
}

export const initializePlatform =
	({ platform_name, domain_name, init_mode, redirectTo }: InitializePlatformArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(initPlatformRequested());

			let platform_environment = MarketplaceEnvironmentKind.Sandbox;
			const isLocalRuntimeEnvironment = domain_name?.includes('localhost');
			const isCustomDomain = !domain_name?.includes('randevu.technology') && !isLocalRuntimeEnvironment;

			if (!isCustomDomain) {
				platform_environment = domain_name?.includes('-sandbox')
					? MarketplaceEnvironmentKind.Sandbox
					: MarketplaceEnvironmentKind.Production;

				domain_name = undefined;
			} else {
				platform_environment = MarketplaceEnvironmentKind.Production;
				platform_name = undefined;
			}

			if (isLocalRuntimeEnvironment) {
				platform_name = localEnvConfig.PLATFORM_NAME;
				platform_environment = localEnvConfig.ENVIRONMENT as MarketplaceEnvironmentKind;
				domain_name = undefined;
			}

			if (!platform_name && !isCustomDomain) {
				dispatch(goToRoute(ROUTES.PLATFORM_ERROR));
				dispatch(initPlatformFailed());
			}

			const token = getState().auth.token;
			const currentUser = getState().auth.currentUser;
			let randevuService = null;

			randevuService = new randevu({}); // default service

			const { config, errors } = await randevuService.platforms.getFrontofficeConfiguration({
				environment: platform_environment,
				marketplace_name: platform_name,
				domain_name,
			});

			if (isNotFound(errors)) {
				dispatch(initPlatformFailed());
				if (isLocalRuntimeEnvironment)
					dispatch(
						sendErrorToasty(`Platform "${platform_name}" not found. Please check your local development config`)
					);

				return dispatch(goToRoute(ROUTES.PLATFORM_ERROR));
			}

			if (config?.rdv_fo_enabled === false) {
				dispatch(goToRoute(ROUTES.PLATFORM_ERROR));
				return dispatch(initPlatformFailed());
			}

			if (isNotPushedToProd(errors)) {
				dispatch(initPlatformFailed());
				const message = `Platform "${platform_name}" has not been pushed to production yet. Please go to Backofice and push your platform to production first.`;
				dispatch(sendWarningToasty(message));
				return dispatch(goToRoute(ROUTES.PLATFORM_ERROR));
			}

			if (isSessionExpired(errors))
				return handleSessionExpired({ dispatch, state: getState(), failedAction: initPlatformFailed } as any);

			if (!config?.public_key) return dispatch(initPlatformFailed())

			dispatch(publicKeyLoaded(config!.public_key));

			if (token && !currentUser) {
				randevuService = new randevu({ token, apiKey: config!.public_key }); // guest service

				const { my_transaction_types, errors: guestErrors } =
					await randevuService.transactions.guestTransactionTypes();

				if (isSessionExpired(guestErrors))
					return handleSessionExpired({ dispatch, state: getState(), failedAction: initPlatformFailed } as any);

				dispatch(transactionTypesLoaded(my_transaction_types));
			}

			if (Array.isArray(config?.participant_types) && config?.participant_types?.length === 0) {
				dispatch(initPlatformFailed());
				const message =
					'Your platform does not have participant types configured. Please add at least one participant type from your backoffice account.';
				dispatch(sendWarningToasty(message));
				return dispatch(goToRoute(ROUTES.PLATFORM_ERROR));
			}

			const landing_page_enabled = config?.ui_config?.landing_page?.enabled;
			const dashboard_page_enabled = config?.ui_config?.dashboard_page?.enabled;

			if (!landing_page_enabled && !dashboard_page_enabled) {
				dispatch(initPlatformFailed());
				dispatch(
					sendErrorToasty(
						'Both landing page and dashboard page are disabled. Go to randevu Backoffice to enable at least one of these two.'
					)
				);
				return dispatch(goToRoute(ROUTES.PLATFORM_ERROR));
			}


			dispatch(
				platformInitialized({
					platform_name: config!.marketplace_name,
					platform_display_name: config!.marketplace_display_name,
					participant_types: config!.participant_types,
					integration_providers: config!.integration_providers,
					ui_config: config!.ui_config,
					public_key: config!.public_key,
					rdv_fo_enabled: config!.rdv_fo_enabled ?? false,
				})
			);

			if (init_mode === 'guest') await dispatch(forceGuest());
			if (init_mode === 'participant') await dispatch(loadCurrentUser());

			if (redirectTo) dispatch(goToRoute(routeBuilder(redirectTo)));

			return dispatch(platformInitializationCompleted());
		};

/////////////////////
//   SELECTORS     //
/////////////////////
export const selectLoading = (state: RootState) => state.platform?.loading;
export const selectInitializingPlatform = (state: RootState) => state.platform.initializing;
export const selectIsPlatformInitComplete = (state: RootState) => state.platform?.isPlatformInitComplete;
export const selectPlatformName = (state: RootState) => state.platform.platform_name;
export const selectPlatformDisplayName = (state: RootState) => state.platform.platform_display_name;
export const selectPlatformParticipantTypes = (state: RootState) => state.platform?.participant_types;
export const selectPlatformConnectionsPageConfig = (state: RootState) => state.platform?.ui_config?.connections_page;
export const selectPlatformConnectedParticipantPageConfig = (state: RootState) =>
	state.platform?.ui_config?.connected_participant_page;
export const selectPlatformPublicKey = (state: RootState) => state.platform.public_key;
export const selectFoEnabled = (state: RootState) => state.platform.rdv_fo_enabled;

export const selectStripeIntegration = (state: RootState) =>
	state.platform.integration_providers?.find(
		(integration: IntegrationProviderLight) => integration.name.toUpperCase() === 'STRIPE'
	);

export const selectDashboardPageConfig = (state: RootState) => state.platform?.ui_config?.dashboard_page;
export const selectGlobalUiConfig = (state: RootState) => state.platform?.ui_config?.global_settings;
export const selectPlatformSignUpClaim = (state: RootState) =>
	state.platform?.ui_config?.global_settings?.sign_up_claim ?? 'Join the platform';
export const selectLandingPageConfig = (state: RootState) => state.platform?.ui_config?.landing_page;
export const selectForgotPasswordPageConfig = (state: RootState) => state.platform?.ui_config?.reset_password_page;
export const selectResetPasswordPageConfig = (state: RootState) => state.platform?.ui_config?.choose_new_password_page;
export const selectSignInPagePageConfig = (state: RootState) => state.platform?.ui_config?.sign_in_page;
export const selectSignUpPageConfig = (state: RootState) => state.platform?.ui_config?.sign_up_page;
export const selectParticipantProfilePageConfig = (state: RootState) =>
	state.platform?.ui_config?.participant_account_page;
export const selectVerifyParticipantAccountPageConfig = (state: RootState) =>
	state.platform?.ui_config?.verify_participant_account_page;
export const selectPlatformContactDetails = (state: RootState) => state?.platform?.ui_config?.global_settings?.external_links;
export const selectOnboardingPageConfig = (state: RootState) => state.platform?.ui_config?.onboarding_page;
