import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';

import ROUTES from '@rdv-fo/common/routes';
import ForgotPasswordPage from '@rdv-fo/pages/ForgotPasswordResetPage';
import ResetPasswordPage from '@rdv-fo/pages/ResetPasswordPage';
import VerifyParticipantAccountPage from '@rdv-fo/pages/VerifyParticipantAccountPage';
import SignInPage from '@rdv-fo/pages/SignInPage';
import SignUpPage from '@rdv-fo/pages/SignUpPage';

import routeBuilder from '@rdv-fo/common/routeBuilder';
import Header from '../../landing-page/Header';
import Footer from '../../landing-page/Footer';
import { useTheme } from '@mui/material';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		authPages: {
			backgroundColor: theme.palette.common.white,
			width: '100%',
			marginTop: '-10px',
			paddingTop: '10px',
		},
		authPage: {
			height: '100%',
			width: '100%',
			overflow: 'hidden',
		},
		authPageContent: {
			maxWidth: '1235px',
			margin: '0px auto',
			padding: 0,
			height: '100%',
		},
		footer: {
			flexShrink: 0,

			minHeight: '5vw',
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(8),
		},
	};
});

const AuthLayout = () => {
	const classes = useStyles();

	return (
		<>
			<Header pageGroup="auth" />

			<main className={classes.authPages}>
				<div className={classes.authPage}>
					<div className={classes.authPageContent}>
						<Switch>
							<Route exact path={routeBuilder(ROUTES.SIGN_IN)} component={SignInPage} />
							<Route exact path={routeBuilder(ROUTES.SIGN_UP)} component={SignUpPage} />
							<Route exact path={routeBuilder(ROUTES.FORGOT_PASSWORD)} component={ForgotPasswordPage} />
							<Route exact path={routeBuilder(ROUTES.RESET_PASSWORD)} component={ResetPasswordPage} />
							<Route
								exact
								path={routeBuilder(ROUTES.VERIFY_USER)}
								component={VerifyParticipantAccountPage}
							/>
							<Redirect to={ROUTES.SIGN_IN} />
						</Switch>
					</div>
				</div>
			</main>
			<Divider />
			<footer className={classes.footer}>
				<Footer />
			</footer>
		</>
	);
};

export default AuthLayout;
