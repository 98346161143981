import { useEffect } from 'react';
import { format } from 'date-fns';
import { Form } from 'react-final-form';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '@rdv-fo/store/configureStore';

import SubTransactionsTable from '../../SubTransactionsTable';
import FieldDisplayValue from '@rdv-fo/components/fields/display/FieldDisplayValue';
import FilterInput from '../../fields/filter/FilterInput';
import { loadMyTransaction } from '@rdv-fo/store/slices/transactionSlice';
import { MatchTypeRoleKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import ConditionalFieldDisplay from '../../fields/display/ConditionalFieldDisplay';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';

const TransactionReverseAuction = ({
	transactionId,
	participantMatchRole,
	availabilityRequirement,
	matchingToolFields,
	supplyFilterFieldsWithType,
	subTransactions,
	transactionTypeTechName,
}) => {
	const dispatch = useAppDispatch();
	const isConsumer = participantMatchRole === MatchTypeRoleKind.Consumer;
	const { parentTransactionId } = usePrimitiveQueryParams();

	// FIXME: use date helper to format the date
	const datetimeFormat = `dd.MM.yy HH:mm`;
	const dateFormat = `dd.MM.yy`;

	// const interval = useRef(null);

	useEffect(() => {
		if (isConsumer) {
			//interval.current = setInterval(async () => {
			dispatch(loadMyTransaction({ id_transaction: transactionId, id_parent_transaction: parentTransactionId }));
			//}, 5000);
		}

		// return () => {
		// 	clearInterval(interval.current);
		// };
	}, [dispatch, isConsumer, transactionId]);

	const supplyFiltersInitialValues = supplyFilterFieldsWithType?.reduce(
		(acc, field) => ({ ...acc, [field.field_type.tech_name]: field.value }),
		{}
	);

	const availabilityNameMap = {
		quantity: 'Quantity',
		start_date: 'Start date',
		end_date: 'End date',
		start_datetime: 'Start date and time',
		end_datetime: 'End date and time',
	};

	const matchingToolFieldValues = {};
	matchingToolFields?.forEach((field) =>
		Object.assign(matchingToolFieldValues, { [field?.field_type?.tech_name]: field?.value })
	);

	return (
		<>
			<Grid container justifyContent="space-between" spacing={4}>
				<Grid item xs={12}>
					<Grid id="availability-and-filters-section" component="section" container spacing={2}>
						<Grid item xs={12}>
							<Typography component="h2" variant="h2">
								Request
							</Typography>
						</Grid>
						{/* Availability */}
						<Grid item xs={12}>
							{availabilityRequirement &&
								Object.entries(availabilityRequirement).map((a) => {
									const availabilityName = a[0];
									const availabilityValue = a[1];
									if (availabilityValue === null || availabilityValue === undefined) return null;

									return (
										<div key={availabilityName}>
											<Grid container spacing={1}>
												<Grid item>
													<Typography>
														<b>{availabilityNameMap[availabilityName]}:</b>
													</Typography>
												</Grid>
												<Grid item>
													<Typography>
														{availabilityName.includes('date') // FIXME: use date helper to format the date
															? format(new Date(availabilityValue), dateFormat)
															: availabilityValue}
													</Typography>
												</Grid>
											</Grid>
										</div>
									);
								})}
						</Grid>

						{/* Matching tool fields */}
						<Grid item xs={12}>
							{matchingToolFields?.map((field) => {
								return (
									<Grid key={field?.field_type.tech_name}>
										<ConditionalFieldDisplay
											key={field?.field_type?.tech_name}
											fields={matchingToolFields}
											values={matchingToolFieldValues}
											conditions={field?.field_type?.conditions}
										>
											<FieldDisplayValue
												fieldTechName={field?.field_type.tech_name}
												label={field?.field_type.ui_config?.label ?? field?.field_type.name}
												inputType={field?.field_type.input_type}
												inputOptions={field?.field_type.input_options}
												optionsUiConfig={field?.field_type?.ui_config?.options}
												value={field.value}
											/>
										</ConditionalFieldDisplay>
									</Grid>
								);
							})}
						</Grid>
						{/* Supply filters */}
						<Grid item xs={12}>
							<Typography component="h2" variant="subtitle1">
								Filters
							</Typography>
						</Grid>
						<Grid sx={{ marginLeft: 1, marginTop: 2 }}>
							<Form onSubmit={() => null} initialValues={supplyFiltersInitialValues}>
								{({ handleSubmit, form, submitting }) => {
									return supplyFilterFieldsWithType?.map((field, index) => {
										return (
											<form key={index} onSubmit={handleSubmit}>
												<FilterInput
													label={field?.field_type.name}
													fieldTechName={field?.field_type.tech_name}
													inputType={field?.field_type?.input_type}
													inputOptions={field?.field_type?.input_options}
													required={field?.field_type?.is_required}
													disabled={true} // FIXME: DO I NEED THE RFF-FORM AS THE WRAPPER?
												/>
											</form>
										);
									});
								}}
							</Form>
						</Grid>
					</Grid>
				</Grid>

				{/* Matches & SubTransactions */}
				<Grid item xs={12}>
					<Grid id="matches-section" component="section" container spacing={3}>
						<Grid item xs={12}>
							<Typography component="h2" variant="h2">
								Matches
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{subTransactions?.length ? (
							<SubTransactionsTable
								items={subTransactions}
								transactionTypeTechName={transactionTypeTechName}
								parentTransactionId={transactionId}
							/>
						) : (
							<Typography sx={{ mt: 2 }}>No matches found yet</Typography>
						)}
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12}></Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default TransactionReverseAuction;
