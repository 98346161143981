import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';

import Typography from '@mui/material/Typography';

import { loadMyTransactions, selectMyTransactions, selectLoading } from '@rdv-fo/store/slices/transactionSlice';
import TransactionsTable from '@rdv-fo/components/transaction/TransactionsTable';
import { Box, Stack, Skeleton } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import { MatchTypeRoleKind, TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { selectTransactionTypes } from '@rdv-fo/store/slices/transactionSlice';

const MyTransactionsPage = () => {
	const dispatch = useAppDispatch();
	const myTransactionTypes = useAppSelector(selectTransactionTypes)
	const myReverseAuctionTransactions = useAppSelector(selectMyTransactions);
	const loading = useAppSelector(selectLoading);


	useEffect(() => {
		dispatch(loadMyTransactions({ order: 'NEWEST_FIRST' }));
	}, [dispatch]);


	const transaction = myReverseAuctionTransactions?.[0];
	let transactionTypeTechName = transaction?.type?.tech_name;
	if (transaction?.my_role === MatchTypeRoleKind.Provider) {

		const reverseAuctionTransactionTypes = myTransactionTypes?.filter(tt => tt.type === TransactionKind.ReverseAuction)
		transactionTypeTechName = reverseAuctionTransactionTypes?.[0]?.tech_name
	}

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<BackButton />
				<Typography component="h1" variant="h1">
					Manage transactions
				</Typography>
			</Stack>
			<Box sx={{ mt: 5 }}>
				{loading ? (
					<Stack direction="column" sx={{ pt: 4 }}>
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
					</Stack>
				) : (
					<>
						<TransactionsTable transactionTypeTechName={transactionTypeTechName} disabled={loading} transactions={myReverseAuctionTransactions} />
					</>
				)}
			</Box>
		</>
	);
};

export default MyTransactionsPage;
