export const MATCH_TYPE_ROLE_KIND = {
	CONSUMER: {
		value: 'CONSUMER',
		label: 'Consumer',
	},

	PROVIDER: {
		value: 'PROVIDER',
		label: 'Provider',
	},
	CONSUMER_OR_PROVIDER: {
		value: 'CONSUMER_OR_PROVIDER',
		label: 'Participant',
	},

	GUEST: {
		value: 'GUEST',
		label: 'Guest',
	},
};

export default MATCH_TYPE_ROLE_KIND;
