import { Stack, useTheme } from '@mui/material';
import { InputKind, Object, ObjectType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { Select } from 'mui-rff';
import { useFormState } from 'react-final-form';
import FieldValueObject from '../display/FieldValueObject';
import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';

interface FieldInputSharedObjectProps extends FieldInputBaseProps {
    sharedObjectTypes: ObjectType[];
    sharedObjects: Object[];
}

export const FieldInputSharedObject = ({
    techName,
    label,
    labelProps,
    disabled = false,
    required = false,
    sharedObjectTypes,
    sharedObjects
}: FieldInputSharedObjectProps) => {
    const theme = useTheme();

    const currentlySelectedOptionId = useFormState()?.values?.[techName];
    const sharedObjectIds = sharedObjects?.map((so) => so.id);
    const sharedObjectOptions = [...sharedObjects?.map((so) => {
        const label = so?.fields?.find((field) => [InputKind.Text, InputKind.Select].includes(field?.field_type?.input_type))?.value
        return { label: label ?? so.id, value: so.id }
    })]
    if (!sharedObjectIds?.includes(currentlySelectedOptionId)) sharedObjectOptions.push({ label: currentlySelectedOptionId, value: currentlySelectedOptionId })
    const currentlySelectedOption = sharedObjects?.find((so) => so.id === currentlySelectedOptionId)
    const currentlySelectedOptionValue = useFormState()?.values?.[`${techName}_field`];


    return (
        <>
            <div>
                <LabelFieldInput
                    text={label}
                    htmlFor={techName}
                    required={required}
                    showOptional={labelProps?.showOptional}
                />
                <Stack direction='column' sx={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(2) }} spacing={2}>
                    <Select data={sharedObjectOptions} name={techName} required={required} disabled={disabled} label={label} />
                    <FieldValueObject fieldTechName={techName} value={currentlySelectedOption ?? { ...currentlySelectedOptionValue?.value, id: currentlySelectedOptionId }} className="" />
                </Stack>
            </div>
        </>
    );
};

export default FieldInputSharedObject;
