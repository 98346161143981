import { Chip as MuiChip } from '@mui/material/';

import useChipStyles from './chipColors';
import { MatchStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import getEnumKeyByValue from '@rdv-fo/app/lib/getEnumKeyByValue';
const MatchStatusChip = ({ status }) => {
	const classes = useChipStyles();

	let colorClass;
	let value;

	switch (status) {
		case MatchStatusKind.Pending:
			colorClass = classes.blue;
			value = getEnumKeyByValue(MatchStatusKind.Pending, MatchStatusKind);
			break;

		case MatchStatusKind.Declined:
			colorClass = classes.red;
			value = getEnumKeyByValue(MatchStatusKind.Declined, MatchStatusKind);
			break;

		case MatchStatusKind.Rejected:
			colorClass = classes.red;
			value = getEnumKeyByValue(MatchStatusKind.Rejected, MatchStatusKind);
			break;

		case MatchStatusKind.Closed:
			colorClass = classes.green;
			value = getEnumKeyByValue(MatchStatusKind.Closed, MatchStatusKind);
			break;

		default:
			colorClass = classes.chipGray;
			value = status;
	}

	const customClasses = `${colorClass} ${classes.chip}`;

	return <MuiChip label={value} className={customClasses} size="small" />;
};

export default MatchStatusChip;
