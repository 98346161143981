import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Chip, Typography, Skeleton, Button, Divider } from '@mui/material';
import { Box, Stack } from '@mui/material';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';

import { loadMyPayment, selectPayment, selectLoading } from '@rdv-fo/store/slices/paymentSlice';

import { ReactNode } from 'react';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import { CurrencyKind, PaymentStatusKind, TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { replaceCurrentRouteWith } from '@rdv-fo/store/slices/uiSlice';

interface RowProps {
	label: string | ReactNode;
	value: string | ReactNode;
}

const Row = ({ label, value }: RowProps) => {
	return (
		<Stack direction="row">
			<Box sx={{ minWidth: '180px', maxWidth: '240px', flex: '0 0 20%' }}>
				<Typography color="textSecondary">{label}</Typography>
			</Box>

			{typeof value === 'string' ? (
				<Box>
					<Typography>{value}</Typography>
				</Box>
			) : (
				<>{value}</>
			)}
		</Stack>
	);
};

interface DataDisplayRowProps {
	label: string | ReactNode;
	value: string | ReactNode;
}

interface DataDisplayTableProps {
	data: DataDisplayRowProps[];
}
const DataDisplayTable = ({ data }: DataDisplayTableProps) => {
	return (
		<Stack direction="column" spacing={1} sx={{ width: '100%' }}>
			{data.map((record, index) => (
				<Row key={index} label={record.label} value={record.value} />
			))}
		</Stack>
	);
};

interface BankTransferCheckoutPageQueryParams {
	redirectBackTo: TransactionKind.SingleDirect | TransactionKind.MultipleDirect | TransactionKind.ReverseAuction
}

const BankTransferCheckoutPage = () => {
	const dispatch = useAppDispatch();
	const { paymentId, transactionId, transactionTypeTechName } = useParams<{ transactionTypeTechName: string, paymentId: string; transactionId: string }>();
	const { redirectBackTo, } = usePrimitiveQueryParams() as BankTransferCheckoutPageQueryParams;

	const payment = useAppSelector(selectPayment);
	const loading = useAppSelector(selectLoading);

	useEffect(() => {
		dispatch(loadMyPayment({ id_payment: paymentId }));
	}, [paymentId, transactionId, dispatch]);

	const bankDetails = payment?.payment_method_details?.financial_addresses?.[0]?.iban;

	const bankData = [
		{
			label: 'IBAN',
			value: bankDetails?.iban,
		},
		{
			label: 'BIC',
			value: bankDetails?.bic,
		},
		{
			label: 'Country',
			value: bankDetails?.country,
		},
		{
			label: 'Account holder name',
			value: bankDetails?.account_holder_name,
		},
		{
			label: 'Reference',
			value: transactionId,
		},
	];

	const isPaid = PaymentStatusKind.Captured === payment?.status;
	let label = 'Awaiting payment';
	let color = 'default' as 'default' | 'success';
	if (isPaid) {
		label = 'Paid';
		color = 'success' as 'success';
	}



	const reverseAuctionDetailsRoute = routeBuilder(ROUTES.REVERSE_AUCTION_TRANSACTION_DETAILS, [
		[':transactionTypeTechName', transactionTypeTechName],
		[':transactionId', transactionId],
	]);

	let singleDirectTransactionDetailsRoute = routeBuilder(ROUTES.SINGLE_DIRECT_TRANSACTION_DETAILS, [
		[':transactionTypeTechName', transactionTypeTechName],
		[':transactionId', transactionId],
	]);

	let multiDirectTransactionDetailsRoute = routeBuilder(ROUTES.MULTI_DIRECT_TRANSACTION_DETAILS, [
		[':transactionTypeTechName', transactionTypeTechName],
		[':transactionId', transactionId],
	]);

	let backToRoute = singleDirectTransactionDetailsRoute;

	if (TransactionKind.MultipleDirect === redirectBackTo) backToRoute = multiDirectTransactionDetailsRoute;
	if (TransactionKind.ReverseAuction === redirectBackTo) backToRoute = reverseAuctionDetailsRoute;

	return (
		<>
			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
				<Typography component="h1" variant="h3" sx={{ fontWeight: 700 }}>
					Bank transfer checkout
				</Typography>
				{loading && <Skeleton width="100%" height={50} />}
				{!loading && <Chip color={color} label={label} size="small" />}
			</Stack>

			<Box sx={{ mt: 5 }}>
				{loading && <LoadingIndicator loading={loading} height={15} />}

				{!loading && (
					<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
						<Box>
							<Typography variant="body1">Amount to transfer</Typography>
							<Typography variant="h4">
								{formatPriceAmount({
									amount: payment?.amount?.amount ?? 0,
									currency: payment?.amount?.currency ?? CurrencyKind.Eur,
								})}
							</Typography>
						</Box>
						<Box>
							<Typography variant="body1" gutterBottom sx={{ fontWeight: 600 }}>
								Bank information
							</Typography>
							<Typography variant="body2" color="textSecondary">
								Transfer funds using the following bank information:
							</Typography>
							<Box sx={{ pt: 2, pb: 2 }}>
								<DataDisplayTable data={bankData} />
							</Box>
							<Typography variant="body2" color="textSecondary">
								If you can, please include the reference mentioned above when you send the bank transfer
							</Typography>
						</Box>
					</Stack>
				)}

				<Box sx={{ mt: 4 }}>
					<Divider sx={{ mb: 2 }} />
					{!isPaid && (
						<Typography sx={{ mb: 2 }} color="textSecondary" variant="body2">
							You can continue after successful payment
						</Typography>
					)}
					<Button
						color="primary"
						variant="contained"
						disabled={!isPaid || loading}
						onClick={() => dispatch(replaceCurrentRouteWith(backToRoute))}
					>
						Continue
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default BankTransferCheckoutPage;
