import {
	QueryMySingleDirectTransactionArgs,
	QueryMySingleDirectTransactionForProviderArgs,
	QueryMySingleDirectTransactionsArgs,
	QueryMySingleDirectTransactionsForProviderArgs,
	QueryMyTransactionArgs,
	QueryMyShoppingCartArgs,
	QueryMyShoppingCartOrderArgs,
	QueryMyShoppingCartOrdersArgs,
	QueryMyShoppingCartsArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	FIELDS_WITH_FIELD_TYPE_FRAGMENT,
	FIELDS_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT,
	CHARGEABLE_FRAGMENT,
	FLOW_STATE_FRAGMENT,
	PRICE_FRAGMENT,
	DEPRECATED_TRANSACTION_STATE_FRAGMENT,
} from '../fragments';

const MY_TRANSACTIONS = gql`
	query MY_TRANSACTIONS {
		myTransactions {
			id
			id_parent_transaction
			state {
				status
				status_tech_name
			}
			my_role
			sub_transactions_count
			type {
				type
				name
				tech_name
			}

			created_at
			initiated_at
			terminated_at
			cancelled_at
		}
	}
`;

const myTransactions = () => {
	return JSON.stringify({
		query: print(MY_TRANSACTIONS),
	});
};
const MY_TRANSACTION = gql`
	query MY_TRANSACTION($id: ID!) {
		myTransaction(id: $id) {
			id
			type {
				id
				type
				tech_name
				match_configuration {
					pricing_enabled
				}
				payment_types {
					name
					tech_name
					payment_methods {
						accepted_methods
						not_accepted_methods
					}
				}
			}
			id_parent_transaction
			my_role
			forcedly_terminated
			state {
				status
				status_tech_name
				message
				next_steps {
					type
					action_message
					config
				}
			}
			created_at
			initiated_at
			terminated_at
			cancelled_at
			chargeable {
				...ChargeableFragment
			}
			sub_transactions {
				id
				created_at
				terminated_at
				state {
					status
					status_tech_name
				}
				consumer_fields {
					...FieldsFragment
				}
				provider_fields {
					...FieldsFragment
				}
				match_fields {
					...FieldsFragment
				}
				supply_fields {
					...FieldWithVariantsFragment
				}
				order_fields {
					...FieldsFragment
				}
			}
			matches {
				id
				provider_declined_at
				consumer_declined_at
				backoffice_rejected_at
				consumer_accepted_at
				provider_accepted_at
				backoffice_approved_at
				price_override_unlocked
				price {
					qty
					addon_items {
						description
						value
					}
					discount_items {
						description
						value
					}
					tax_items {
						description
						value
					}
					platform_fee_items {
						description
						value
						is_provider_fee
						is_consumer_fee
					}
					unit_price
					currency
					total_before_tax
					total_taxes

					total_fees
				}
				fields {
					...FieldsFragment
				}
				supply_fields {
					...FieldWithVariantsFragment
				}
				provider_fields {
					...FieldsFragment
				}
				consumer_fields {
					...FieldsFragment
				}
			}

			order {
				#id # Throwing ERROR  ACCESS ERROR
				# last_execution_atThrowing ERROR  ACCESS ERROR
				#type {
				#	id Throwing ERROR  ACCESS ERROR
				#	type Throwing ERROR  ACCESS ERROR
				#}
				supply_filter {
					tech_name
					value
				}
				consumer_filter {
					tech_name
					value
				}
				provider_filter {
					tech_name
					value
				}
				availability {
					quantity
					start_datetime
					end_datetime
					start_date
					end_date
				}
				fields {
					...FieldsFragment
				}
			}
		}
	}
	${CHARGEABLE_FRAGMENT}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}

	${FIELDS_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT}
`;

const myTransaction = ({ id }: QueryMyTransactionArgs) => {
	return JSON.stringify({
		query: print(MY_TRANSACTION),
		variables: {
			id: String(id),
		},
	});
};

const MY_SINGLE_DIRECT_TRANSACTION = gql`
	query MY_SINGLE_DIRECT_TRANSACTION($id: ID!) {
		mySingleDirectTransaction(id: $id) {
			id
			created_at
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
			forcedly_terminated
			type {
				id
				name
				tech_name
				flow_type {
					id
				}
				payment_types {
					name
					tech_name
					payment_methods {
						accepted_methods
						not_accepted_methods
					}
				}
				root_type {
					... on MatchingToolType {
						id
						name
					}
				}
			}
			state {
				...FlowStateFragment
			}
			request {
				requester {
					id
					first_name
					last_name
					email
				}
				requester_participant {
					id
					type {
						id
						name
					}
					fields {
						...FieldsFragment
					}
				}
				fields {
					...FieldsFragment
				}
			}
			match {
				id
				status
				provider_accepted_at
				consumer_accepted_at
				backoffice_approved_at
				backoffice_rejected_at
				consumer_declined_at
				provider_declined_at
				created_at
				closed_at
				price {
					...PriceFragment
				}
				fields {
					...FieldsFragment
				}
				provider_fields {
					...FieldsFragment
				}
				supply_fields {
					...FieldsFragment
				}
			}
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
	${FLOW_STATE_FRAGMENT}
	${PRICE_FRAGMENT}
`;
const mySingleDirectTransactionForConsumer = ({ id }: QueryMySingleDirectTransactionArgs) => {
	return JSON.stringify({
		query: print(MY_SINGLE_DIRECT_TRANSACTION),
		variables: {
			id: String(id),
		},
	});
};
const MY_SINGLE_DIRECT_TRANSACTION_FOR_PROVIDER = gql`
	query MY_SINGLE_DIRECT_TRANSACTION($id: ID!) {
		mySingleDirectTransactionForProvider(id: $id) {
			id
			created_at
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
			forcedly_terminated
			type {
				id
				name
				tech_name
				flow_type {
					id
				}
				root_type {
					... on MatchingToolType {
						id
						name
					}
				}
			}
			state {
				...FlowStateFragment
			}
			request {
				requester {
					id
					first_name
					last_name
					email
				}
				fields {
					...FieldsFragment
				}
			}
			match {
				id
				status
				provider_accepted_at
				consumer_accepted_at
				backoffice_approved_at
				backoffice_rejected_at
				consumer_declined_at
				provider_declined_at
				created_at
				closed_at
				price {
					...PriceFragment
				}
				fields {
					...FieldsFragment
				}
				consumer_fields {
					...FieldsFragment
				}
				supply {
					fields {
						...FieldsFragment
					}
				}
			}
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
	${FLOW_STATE_FRAGMENT}
	${PRICE_FRAGMENT}
`;
const mySingleDirectTransactionForProvider = ({ id }: QueryMySingleDirectTransactionForProviderArgs) => {
	return JSON.stringify({
		query: print(MY_SINGLE_DIRECT_TRANSACTION_FOR_PROVIDER),
		variables: {
			id: String(id),
		},
	});
};

const MY_SINGLE_DIRECT_TRANSACTIONS_FOR_CONSUMER = gql`
	query MY_SINGLE_DIRECT_TRANSACTIONS_FOR_CONSUMER(
		$where: SingleDirectTransactionFilter!
		$before: String
		$after: String
		$first: Int
		$last: Int
	) {
		mySingleDirectTransactions(where: $where, before: $before, after: $after, first: $first, last: $last) {
			total_count
			page_info {
				has_next_page
				has_previous_page
				start_cursor
				end_cursor
			}
			edges {
				cursor
				node {
					id
					created_at
					prepared_at
					initiated_at
					terminated_at
					type {
						tech_name
					}
					cancelled_at
					forcedly_terminated
					state {
						...FlowStateFragment
					}
					request {
						requester {
							id
							first_name
							last_name
						}
					}
					match {
						status
						fields {
							...FieldsFragment
						}
						provider_fields {
							...FieldsFragment
						}
						supply_fields {
							...FieldsFragment
						}
					}
				}
			}
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
	${FLOW_STATE_FRAGMENT}
`;

const mySingleDirectTransactionsForConsumer = ({
	where,
	after,
	before,
	first,
	last,
}: QueryMySingleDirectTransactionsArgs) => {
	return JSON.stringify({
		query: print(MY_SINGLE_DIRECT_TRANSACTIONS_FOR_CONSUMER),
		variables: { where, after, before, first, last },
	});
};
const MY_SINGLE_DIRECT_TRANSACTIONS_FOR_PROVIDER = gql`
	query MY_SINGLE_DIRECT_TRANSACTIONS_FOR_PROVIDER(
		$where: SingleDirectTransactionFilter!
		$before: String
		$after: String
		$first: Int
		$last: Int
	) {
		mySingleDirectTransactionsForProvider(
			where: $where
			before: $before
			after: $after
			first: $first
			last: $last
		) {
			total_count
			page_info {
				has_next_page
				has_previous_page
				start_cursor
				end_cursor
			}
			edges {
				cursor
				node {
					id
					created_at
					prepared_at
					initiated_at
					terminated_at
					type {
						tech_name
					}
					cancelled_at
					forcedly_terminated
					state {
						...FlowStateFragment
					}
					request {
						requester {
							id
							first_name
							last_name
						}
					}
					match {
						status
						fields {
							...FieldsFragment
						}
						consumer_fields {
							...FieldsFragment
						}
						supply {
							id
							fields {
								...FieldsFragment
							}
						}
					}
				}
			}
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
	${FLOW_STATE_FRAGMENT}
`;

const mySingleDirectTransactionsForProvider = ({
	where,
	after,
	before,
	first,
	last,
}: QueryMySingleDirectTransactionsForProviderArgs) => {
	return JSON.stringify({
		query: print(MY_SINGLE_DIRECT_TRANSACTIONS_FOR_PROVIDER),
		variables: { where, after, before, first, last },
	});
};

const MY_ACTIVE_SHOPPING_CART = gql`
	query MY_ACTIVE_SHOPPING_CART {
		myActiveShoppingCart {
			id
			type {
				id
				tech_name
				payment_types {
					name
					tech_name
					payment_methods {
						accepted_methods
						not_accepted_methods
					}
				}
				root_type {
					... on MatchingToolType {
						match_type {
							supply_type {
								name
							}
						}
					}
					... on MatchType {
						supply_type {
							name
						}
					}
				}
				match_configuration {
					id
					name
					tech_name
					matching_unit_type
					availability_management_type
					pricing_enabled
					match_parameter_types {
						name
						tech_name
						input_type
						input_options
						is_required
						initial_value
						ui_config {
							label
							helper_text
						}
					}
				}
			}
			fields {
				...FieldsFragment
			}
			state {
				...DeprecatedTransactionStateFragment
			}
			items {
				id
				id_supply
				id_provider
				fields {
					...FieldsFragment
				}
				provider_fields {
					...FieldsFragment
				}
				supply_fields {
					...FieldsFragment
				}
				status
				state {
					...DeprecatedTransactionStateFragment
				}
				price {
					qty
					addon_items {
						description
						value
					}
					discount_items {
						description
						value
					}
					tax_items {
						description
						value
					}
					platform_fee_items {
						description
						value
						is_provider_fee
						is_consumer_fee
					}
					unit_price
					currency
					total_before_tax
					total_taxes

					total_fees
				}
			}
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
			chargeable {
				...ChargeableFragment
			}
		}
	}
	${CHARGEABLE_FRAGMENT}
	${DEPRECATED_TRANSACTION_STATE_FRAGMENT}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;
const myActiveShoppingCart = () => {
	return JSON.stringify({
		query: print(MY_ACTIVE_SHOPPING_CART),
	});
};

const MY_SHOPPING_CART = gql`
	query MY_SHOPPING_CART($id_cart: ID!) {
		myShoppingCart(id_cart: $id_cart) {
			id
			type {
				id
				tech_name
				root_type {
					... on MatchingToolType {
						match_type {
							supply_type {
								name
							}
						}
					}
					... on MatchType {
						supply_type {
							name
						}
					}
				}
				match_configuration {
					id
					name
					tech_name
					matching_unit_type
					availability_management_type
					pricing_enabled
					match_parameter_types {
						name
						tech_name
						input_type
						input_options
						is_required
						initial_value
						ui_config {
							label
							helper_text
						}
					}
				}
			}
			fields {
				...FieldsFragment
			}
			state {
				...DeprecatedTransactionStateFragment
			}
			items {
				id
				id_supply
				id_provider
				fields {
					...FieldsFragment
				}
				provider_fields {
					...FieldsFragment
				}
				supply_fields {
					...FieldsFragment
				}
				status
				state {
					...DeprecatedTransactionStateFragment
				}
				price {
					qty
					addon_items {
						description
						value
					}
					discount_items {
						description
						value
					}
					tax_items {
						description
						value
					}
					platform_fee_items {
						description
						value
						is_provider_fee
						is_consumer_fee
					}
					unit_price
					currency
					total_before_tax
					total_taxes

					total_fees
				}
			}
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
			chargeable {
				...ChargeableFragment
			}
		}
	}
	${DEPRECATED_TRANSACTION_STATE_FRAGMENT}
	${CHARGEABLE_FRAGMENT}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;
const myShoppingCart = ({ id_cart }: QueryMyShoppingCartArgs) => {
	return JSON.stringify({
		query: print(MY_SHOPPING_CART),
		variables: { id_cart },
	});
};

const MY_MULTI_DIRECT_TRANSACTIONS = gql`
	query MY_MULTI_DIRECT_TRANSACTIONS($where: ShoppingCartFilter!, $limit: Int = 20, $offset: Int = 0) {
		myShoppingCarts(where: $where, limit: $limit, offset: $offset) {
			id
			request {
				requester {
					first_name
					last_name
					email
				}
				requester_participant {
					id
					fields {
						...FieldsFragment
					}
				}
				fields {
					...FieldsFragment
				}
			}
			state {
				status
				status_tech_name
			}
			created_at
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;
const myMultiDirectTransactions = ({ where, limit, offset }: QueryMyShoppingCartsArgs) => {
	return JSON.stringify({
		query: print(MY_MULTI_DIRECT_TRANSACTIONS),
		variables: { where, limit, offset },
	});
};

const MY_SHOPPING_CART_ORDERS = gql`
	query MY_SHOPPING_CART_ORDERS($where: ShoppingCartOrderFilter!) {
		myShoppingCartOrders(where: $where) {
			id
			created_at
			request {
				requester {
					first_name
					last_name
					email
				}
				requester_participant {
					id
					fields {
						...FieldsFragment
					}
				}
				fields {
					...FieldsFragment
				}
			}
			state {
				status
				status_tech_name
			}
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
			order {
				id
				total_before_tax
				total_taxes
				total_provider_fees
				gross_value
				net_value
				currency
				order_items {
					value
					description
					type
					data
				}
			}
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const myShoppingCartOrders = ({ where }: QueryMyShoppingCartOrdersArgs) => {
	return JSON.stringify({
		query: print(MY_SHOPPING_CART_ORDERS),
		variables: { where },
	});
};

const MY_SHOPPING_CART_ORDER = gql`
	query MY_SHOPPING_CART_ORDER($id_cart_order: ID!) {
		myShoppingCartOrder(id_cart_order: $id_cart_order) {
			id
			created_at
			state {
				...DeprecatedTransactionStateFragment
			}
			request {
				requester {
					first_name
					last_name
					email
				}
				requester_participant {
					id
					fields {
						...FieldsFragment
					}
				}
				fields {
					...FieldsFragment
				}
			}
			type {
				match_configuration {
					pricing_enabled
				}
			}
			items {
				id
				id_transaction
				created_at

				closed_at
				canceled_at
				paid_at

				consumer_accepted_at
				provider_accepted_at
				backoffice_approved_at
				consumer_declined_at
				provider_declined_at
				backoffice_rejected_at

				forcedly_terminated
				initiated_at
				terminated_at
				cancelled_at

				consumer_fields {
					...FieldsFragment
				}
				supply_fields {
					...FieldsFragment
				}
				fields {
					...FieldsFragment
				}
				status
				state {
					...DeprecatedTransactionStateFragment
				}
				price {
					qty
					addon_items {
						description
						value
					}
					discount_items {
						description
						value
					}
					tax_items {
						description
						value
					}
					platform_fee_items {
						description
						value
						is_provider_fee
						is_consumer_fee
					}
					unit_price
					currency
					total_before_tax
					total_taxes

					total_fees
				}
			}
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
			order {
				id
				total_before_tax
				total_taxes
				total_provider_fees
				gross_value
				net_value
				order_items {
					value
					description
					type
					data
				}
			}
		}
	}
	${DEPRECATED_TRANSACTION_STATE_FRAGMENT}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const myShoppingCartOrder = ({ id_cart_order }: QueryMyShoppingCartOrderArgs) => {
	return JSON.stringify({
		query: print(MY_SHOPPING_CART_ORDER),
		variables: { id_cart_order },
	});
};

export {
	myTransactions,
	myTransaction,
	mySingleDirectTransactionsForConsumer,
	mySingleDirectTransactionsForProvider,
	mySingleDirectTransactionForConsumer,
	mySingleDirectTransactionForProvider,
	myActiveShoppingCart,
	myMultiDirectTransactions,
	myShoppingCart,
	myShoppingCartOrders,
	myShoppingCartOrder,
};
