export const ASSETS = {
	RANDEVU: {
		PLATFORM_NOT_FOUND: '/assets/pageNotFound.png',
		SECURITY_PAGE_DEFAULT_COVER_IMAGE: '/assets/randevuAuthPageCoverImage.jpg',
		LANDING_PAGE_HERO_IMAGE: '/assets/randevuFrontendHero.jpg',
		LANDING_PAGE_STEPS_SECTION: {
			STEP_1: '/assets/randevuFrontendStep1.png',
			STEP_2: '/assets/randevuFrontendStep2.png',
			STEP_3: '/assets/randevuFrontendStep3.png',
		},
		LANDING_PAGE_USP_SECTION: {
			USP_1: '/assets/randevuFrontendUSP1.png',
			USP_2: '/assets/randevuFrontendUSP2.png',
			USP_3: '/assets/randevuFrontendUSP3.png',
		},
		IMAGE_UNAVAILABLE: '/assets/imageUnavailable.jpg',
	},
};
