import { Radios } from 'mui-rff';
import LabelFilterInput from './LabelFilterInput';

const parse = (value: 'yes' | 'no' | '') => {
	if (value === 'yes') return true;
	if (value === 'no') return false;

	return undefined;
};
const format = (value: boolean) => {
	if (value === true) return 'yes';
	if (value === false) return 'no';

	return '';
};

interface FilterInputDocumentProps {
	name: string;
	disabled: boolean;
	label?: string
}

const FilterInputDocument = ({ name, disabled, label }: FilterInputDocumentProps) => {

	const options = [
		{ label: 'Available', value: 'yes' },
		{ label: 'Unavailable', value: 'no' },
		{ label: 'Not relevant', value: '' },
	];

	return (
		<>
			{label ? <LabelFilterInput text={name} htmlFor={name} required /> : null}
			<Radios name={`${name}.is_provided`} disabled={disabled} data={options} fieldProps={{ parse, format }} />
		</>
	);
};

export default FilterInputDocument;
