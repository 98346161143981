import { Typography } from '@mui/material';
import {
	RdvAutoIncFieldValue,
	RdvBooleanFieldValue,
	RdvDateFieldValue,
	RdvDatetimeFieldValue,
	RdvDecimalFieldValue,
	RdvDocumentFieldValue,
	RdvDocumentSetFieldValue,
	RdvFieldValue,
	RdvImageFieldValue,
	RdvImageSetFieldValue,
	RdvIntegerFieldValue,
	RdvLocationFieldValue,
	RdvMonetaryValueFieldValue,
	RdvMultiSelectFieldValue,
	RdvObjectSetFieldValue,
	RdvSelectFieldValue,
	RdvTextFieldValue,
	RdvDateSetFieldValue,
} from '@rdv-fo/services/randevuApi/types/field';
import { InputKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

import FieldAutoIncCellView from './FieldAutoIncCellView';
import FieldBooleanCellView from './FieldBooleanCellView';
import FieldDateCellView from './FieldDateCellView';
import FieldDateSetCellView from './FieldDateSetCellView';
import FieldDatetimeCellView from './FieldDatetimeCellView';
import FieldDecimalCellView from './FieldDecimalCellView';
import FieldDocumentCellView from './FieldDocumentCellView';
import FieldDocumentSetCellView from './FieldDocumentSetCellView';
import FieldImageCellView from './FieldImageCellView';
import FieldImageSetCellView from './FieldImageSetCellView';
import FieldIntegerCellView from './FieldIntegerCellView';
import FieldLocationCellView from './FieldLocationCellView';
import FieldMonetaryValueCellView from './FieldMonetaryValueCellView';
import FieldMultiSelectCellView from './FieldMultiSelectCellView';
import FieldObjectSetCellView from './FieldObjectSetCellView';
import FieldSelectCellView from './FieldSelectCellView';
import FieldTextCellView from './FieldTextCellView';

interface FieldViewProps {
	inputKind: InputKind;
	value: RdvFieldValue;
	optionsUiConfig?: { label: string; value: string }[];
}
const FieldView = ({ value, inputKind, optionsUiConfig }: FieldViewProps) => {
	switch (inputKind) {
		case InputKind.AutoincId:
			return <FieldAutoIncCellView value={value as RdvAutoIncFieldValue} />;
		case InputKind.Boolean:
			return <FieldBooleanCellView value={value as RdvBooleanFieldValue} />;

		case InputKind.Integer:
			return <FieldIntegerCellView value={value as RdvIntegerFieldValue} />;

		case InputKind.Image:
			return <FieldImageCellView value={value as RdvImageFieldValue} />;

		case InputKind.ImageSet:
			return <FieldImageSetCellView value={value as RdvImageSetFieldValue} />;

		case InputKind.Document:
			return <FieldDocumentCellView value={value as RdvDocumentFieldValue} />;

		case InputKind.DocumentSet:
			return <FieldDocumentSetCellView value={value as RdvDocumentSetFieldValue} />;

		case InputKind.Decimal:
			return <FieldDecimalCellView value={value as RdvDecimalFieldValue} />;

		case InputKind.Location:
			return <FieldLocationCellView value={value as RdvLocationFieldValue} />;

		case InputKind.Date:
			return <FieldDateCellView value={value as RdvDateFieldValue} />;

		case InputKind.DateSet:
			return <FieldDateSetCellView value={value as RdvDateSetFieldValue} />;

		case InputKind.Datetime:
			return <FieldDatetimeCellView value={value as RdvDatetimeFieldValue} />;

		case InputKind.MultiSelect:
			return (
				<FieldMultiSelectCellView
					optionsUiConfig={optionsUiConfig ?? []}
					value={value as RdvMultiSelectFieldValue}
				/>
			);

		case InputKind.Select:
			return <FieldSelectCellView optionsUiConfig={optionsUiConfig ?? []} value={value as RdvSelectFieldValue} />;

		case InputKind.Text:
			return <FieldTextCellView value={value as RdvTextFieldValue} />;
		case InputKind.SmartText:
			return <FieldTextCellView value={value as RdvTextFieldValue} />;

		case InputKind.MonetaryValue:
			return <FieldMonetaryValueCellView value={value as RdvMonetaryValueFieldValue} />;

		case InputKind.ObjectSet:
			return <FieldObjectSetCellView value={value as RdvObjectSetFieldValue} />;

		default:
			return <Typography>{`CellView component for ${inputKind} is coming soon..`}</Typography>;
	}
};

export default FieldView;
