import { VALIDATION_MESSAGE } from './validationMessages';
// Go through all validation functions for the field and return error message of the first one
const validateField = (fieldValidators, fieldName, value) => {
	return fieldValidators[fieldName]?.reduce((acc, validator) => {
		return acc ? acc : validator(value);
	}, undefined);
};

export const validators = {
	// Validation functions
	required: (value) => (value ? undefined : VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION),
	noSpecialChars: (value) =>
		/^[a-z 0-9-]+$/i.test(value) ? undefined : VALIDATION_MESSAGE.SPECIAL_CHARACTERS_VIOLATION,
	email: (value) =>
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
			value
		)
			? undefined
			: VALIDATION_MESSAGE.EMAIL_VIOLATION,
	hasNumber: (value) => (/\d/.test(value) ? undefined : VALIDATION_MESSAGE.NO_NUMBER_VIOLATION),
	minimum8chars: (value) => (value?.length > 7 ? undefined : VALIDATION_MESSAGE.MINIMUM_8_CHARACTERS_VIOLATION),
	hasUpperCase: (value) => (/[A-Z]/.test(value) ? undefined : VALIDATION_MESSAGE.NO_UPPERCASE_VIOLATION),
	hasLowerCase: (value) => (/[a-z]/.test(value) ? undefined : VALIDATION_MESSAGE.NO_LOWERCASE_VIOLATION),
	hasSpecialChars: (value) =>
		/[^a-zA-Z0-9\s]/.test(value) ? undefined : VALIDATION_MESSAGE.NO_SPECIAL_CHARACTERS_VIOLATION,
	// Generate errors object of form { fieldName1: 'Errror message 1', ... }
	validate: (fieldValidators, values) => {
		const errors = Object.keys(fieldValidators).reduce((acc, fieldName) => {
			return { ...acc, [fieldName]: validateField(fieldValidators, fieldName, values[fieldName]) };
		}, {});
		return errors;
	},
};
