
import { InputAdornment, Stack } from '@mui/material';

import { TextField } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';

import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';
import { CurrencyKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const missingValue = ['', undefined, null];

interface MonetaryValueInputOptions {
	required: boolean;
	minValue?: number;
	maxValue?: number;
}
const validate = (value: any, options: MonetaryValueInputOptions) => {
	const { minValue, maxValue, required } = options;

	// if the value is not required and the user did not provide any value, we do not want to validate it
	// if the value is not required, we validate the value only if its provided
	if (!required && missingValue.includes(value?.amount)) return undefined;

	if (required && missingValue.includes(value?.amount)) return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	if (minValue && value?.amount < minValue)
		return VALIDATION_MESSAGE.MIN_VALUE_VIOLATION.replace('{min_value}', String(minValue / 100));
	if (maxValue && value?.amount > maxValue)
		return VALIDATION_MESSAGE.MAX_VALUE_VIOLATION.replace('{max_value}', String(maxValue / 100));

	return undefined;
};

interface FieldInputMonetaryValueProps extends FieldInputBaseProps {
	minValue?: number;
	maxValue?: number;
	currency?: CurrencyKind;
}

interface MonetaryValueTextFieldInputProps {
	type: string;
	inputMode: 'numeric' | 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'decimal' | undefined;
	pattern: string;
	min: number;
	max?: number;
	step: number;
}

const FieldInputMonetaryValue = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	minValue,
	maxValue,
	currency = CurrencyKind.Eur,
}: FieldInputMonetaryValueProps) => {
	let min = minValue || 0;

	const inputProps: MonetaryValueTextFieldInputProps = {
		type: 'number',
		inputMode: 'numeric',
		pattern: '[0-9]*',
		min: min / 100,
		...(maxValue && { max: maxValue / 100 }),
		step: 0.01,
	};

	const textFieldInputProps = {
		...(currency && { startAdornment: <InputAdornment position="start">{currency}</InputAdornment> }),
	};

	const fieldProps = {
		parse: (
			value: any // FIXME: set proper type
		) =>
			value
				? {
					currency: currency,
					amount: parseInt((value * 100).toFixed(0)),
				}
				: null,
		format: (value: any) => (value?.amount ? value.amount / 100 : ''),
		validate: (value: any) => validate(value, { required, minValue, maxValue }),
	};

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>

			<TextField
				id={techName}
				name={techName}
				disabled={disabled}
				required={required}
				variant="outlined"
				size="small"
				margin="dense"
				fullWidth
				helperText={helperText}
				fieldProps={fieldProps}
				inputProps={inputProps} // FIXME: remove ts-ignore from the above of the file and fix inputProps issue
				InputProps={textFieldInputProps}
			/>
		</Stack>
	);
};

export default FieldInputMonetaryValue;
