import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	QueryGetPaymentArgs,
	QueryMyPaymentsArgs,
	QueryMyTransfersArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

const GET_MY_PAYMENT = gql`
	query GET_MY_PAYMENT($id: ID!) {
		myPayment(id: $id) {
			id
			id_transaction
			amount {
				amount
				currency
			}
			payment_method
			payment_method_details
			error {
				error_code
				error_message
			}
			status
			prepared_at
			completed_at
			failed_at
		}
	}
`;

const myPayment = ({ id }: QueryGetPaymentArgs) => {
	return JSON.stringify({
		query: print(GET_MY_PAYMENT),
		variables: {
			id: String(id),
		},
	});
};
const MY_PAYMENTS = gql`
	query MY_PAYMENTS($where: PaymentFilter, $before: String, $after: String, $first: Int, $last: Int) {
		myPayments(where: $where, before: $before, after: $after, first: $first, last: $last) {
			total_count
			page_info {
				end_cursor
				start_cursor
				has_next_page
				has_previous_page
			}
			edges {
				cursor
				node {
					id
					id_transaction
					amount {
						amount
						currency
					}
					payment_method
					payment_method_details
					error {
						error_code
						error_message
					}
					status
					authorized_at
					completed_at
					checkout_requested_at
					prepared_at
					captured_at
					failed_at
				}
			}
		}
	}
`;

const myPayments = ({ where, after, before, first, last }: QueryMyPaymentsArgs) => {
	return JSON.stringify({
		query: print(MY_PAYMENTS),
		variables: { where, after, before, first, last },
	});
};
const MY_TRANSFERS = gql`
	query MY_TRANSFERS($where: TransferFilter, $before: String, $after: String, $first: Int, $last: Int) {
		myTransfers(where: $where, before: $before, after: $after, first: $first, last: $last) {
			total_count
			page_info {
				end_cursor
				start_cursor
				has_next_page
				has_previous_page
			}
			edges {
				cursor
				node {
					id
					amount {
						amount
						currency
					}
					error
					order {
						id
						net_value
						gross_value
						currency
						order_items {
							value
							description
							type
						}
					}
					status
					succeeded_at
				}
			}
		}
	}
`;

const myTransfers = ({ where, after, before, first, last }: QueryMyTransfersArgs) => {
	return JSON.stringify({
		query: print(MY_TRANSFERS),
		variables: { where, after, before, first, last },
	});
};

export { myPayment, myTransfers, myPayments };
