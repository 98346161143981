import { useMemo } from 'react';
import createDecorator from 'final-form-focus';
import { Form } from 'react-final-form';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import arrayMutators from 'final-form-arrays';

import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import FieldInput from '../fields/input/FieldInput';
import ConditionalFieldInput from '../fields/input/ConditionalFieldInput';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import {
    Field,
    FieldAccessKind,
    InputKind,
    ObjectField,
    ObjectType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { mapFieldsToFormValues, mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { Stack } from '@mui/material';
import { updateSharedObject, updateSharedObjectFailed } from '@rdv-fo/store/slices/sharedObjectsSlice';
import { FormApi } from 'final-form';

interface SharedObjectUpdateFormProps {
    sharedObject: any; //FIXME @Rokva -> add proper type
    sharedObjects: any; //FIXME @Rokva -> add proper type
    sharedObjectTypes: any; //FIXME @Rokva -> add proper type
}

const SharedObjectUpdateForm = ({ sharedObject, sharedObjects, sharedObjectTypes }: SharedObjectUpdateFormProps) => {
    const dispatch = useAppDispatch();
    const focusOnError = useMemo(() => createDecorator(), []);
    const sharedObjectTypeIds = sharedObjectTypes.map((sharedObjectType: ObjectType) => sharedObjectType.id);

    const handleSubmitUpdateSharedObject = async (values: any, form: FormApi) => {
        const dirtyFields = getDirtyFields({ ...values }, form);

        const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypeIds, sharedObject?.fields);

        const resolvedAction = await dispatch(updateSharedObject({ fields: fieldInputs, id: sharedObject.id }))

        if (resolvedAction?.type === updateSharedObjectFailed.type) return resolvedAction.payload;
    };

    const sharedObjectFirstFieldValue = sharedObject?.fields?.find((field: Field) => [InputKind.Text, InputKind.Select].includes(field?.field_type?.input_type))?.value

    const initUpdateMySharedObjectForm = (sharedObject: any) => {
        let initialValues = mapFieldsToFormValues(sharedObject?.fields, []);

        return initialValues;
    };

    return (
        <Form
            onSubmit={handleSubmitUpdateSharedObject}
            initialValues={initUpdateMySharedObjectForm(sharedObject)}
            decorators={[focusOnError]}
            mutators={{
                ...arrayMutators,
            }}
        >
            {(props) => {
                const { handleSubmit, pristine, submitting, hasValidationErrors } = props;

                const saveButtonEnabled = !pristine && !hasValidationErrors;

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid
                            id="form-header"
                            container
                            justifyContent="space-between"
                            sx={{
                                paddingBottom: 6,
                            }}
                        >
                            <Grid item>
                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                    <Typography variant="h1" display="inline">
                                        {sharedObjectFirstFieldValue ?? sharedObject?.id}
                                    </Typography>

                                </Stack>

                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    disabled={!saveButtonEnabled}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    loading={submitting}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>

                        <Grid container id="form-content" spacing={3}>
                            {sharedObject?.fields?.map((field: Field) => {
                                return (
                                    <ConditionalFieldInput
                                        key={field?.field_type.tech_name}
                                        fields={sharedObject?.fields}
                                        conditions={field?.field_type?.conditions ?? []}
                                    >
                                        <Grid item key={field.field_type.tech_name} xs={12}>
                                            <FieldInput
                                                sharedObjects={sharedObjects}
                                                label={field?.field_type?.ui_config?.label ?? field.field_type?.name}
                                                optionsUiConfig={field?.field_type?.ui_config?.options ?? []}
                                                helperText={field?.field_type?.ui_config?.helper_text}
                                                fieldTechName={field?.field_type.tech_name}
                                                inputType={field?.field_type.input_type}
                                                inputOptions={field?.field_type.input_options}
                                                required={field?.field_type.is_required}
                                                sharedObjectTypes={sharedObjectTypes}
                                                disabled={field.my_access === FieldAccessKind.Read || submitting}
                                                objectTypeFields={(field as ObjectField)?.object_type?.fields}
                                            />
                                        </Grid>
                                    </ConditionalFieldInput>
                                );
                            })}
                        </Grid>
                    </form>
                );
            }}
        </Form>
    );
};

export default SharedObjectUpdateForm;
