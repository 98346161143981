import Typography from '@mui/material/Typography';
import { formatDateHumanFriendly } from '@rdv-fo/app/lib/datetime';
import { RdvDateSetFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldDateCellViewProps {
	value: RdvDateSetFieldValue;
}
const FieldDateCellView = ({ value }: FieldDateCellViewProps) => {
	if (value === null || value?.length === 0) return <Typography variant="body2">{'-'}</Typography>;
	if (value?.length > 3) return <Typography variant="body2">{`${value.length} dates`}</Typography>;

	return <Typography variant="body2">{value.map((date) => formatDateHumanFriendly(date)).join(', ')}</Typography>;
};

export default FieldDateCellView;
