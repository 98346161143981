import { Divider, Icon, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Form } from 'react-final-form';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkboxes } from 'mui-rff';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import { useTheme } from '@mui/material';
import { CollectionItem } from '@rdv-fo/store/slices/supplySlice';
import Banner from '@rdv-fo/components/common/Banner';

export type AddSupplyFormValues = {
	ids: string[];
};

interface CheckboxOptionProps {
	name?: string;
	image?: string;
	variantId?: string;
} 

const CheckboxOption = ({ image, name, variantId }: CheckboxOptionProps) => {
	const theme = useTheme();

	return (
		<Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
			{image ? (
				<img src={image} width="40px" height="40px" style={{ objectFit: 'scale-down', }} />
			) : (
				<Icon sx={{ width: 40, height: 40, backgroundColor: theme.palette.grey[300], borderRadius: 1 }} />
			)}
			{name ? (
				<Typography variant="body1">{`${name} ${variantId ? `- ${variantId}` : ''}`}</Typography>
			) : (
				<Typography variant="body2">n.a</Typography>
			)}
		</Stack>
	);
};

const mapSuppliesToCheckboxData = (supplies: CollectionItem[]) => {
	return supplies.map((collection_item) => {
		return {
			label: (
				<CheckboxOption
					variantId={collection_item.variantId}
					name={collection_item.name}
					image={collection_item.image?.url}
				/>
			),
			value: collection_item.id,
		};
	});
};


interface AddSupplyToCollectionDialogProps {
	open: boolean;
	supplies: any[];
	selectedSupplies: any[];
	onClose: () => void;
	onSubmit: (values: any) => any;
}

const AddSupplyToCollectionDialog = ({
	open,
	supplies,
	selectedSupplies,
	onClose,
	onSubmit,
}: AddSupplyToCollectionDialogProps) => {
	const checkboxData = mapSuppliesToCheckboxData(supplies);

	const initFormValues = (selectedSupplies: CollectionItem[]) => {
		return {
			supply_ids: selectedSupplies.map((sp) => sp.id),
		};
	};
	return (
		<Dialog aria-labelledby="add-supply-to-collection" open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle id="add-supply-to-collection-title">
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h4">Manage collection</Typography>
					<IconButton onClick={onClose}>
						<ClearIcon fontSize="medium" />
					</IconButton>
				</Stack>
			</DialogTitle>
			<Divider />
			<Form
				onSubmit={onSubmit}
				initialValues={initFormValues(selectedSupplies ?? [])}
				render={({ handleSubmit, submitting, pristine, values }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								{checkboxData.length < 1 && <Banner text={'No available items'} />}
								<Checkboxes name="supply_ids" required={true} data={checkboxData} />

								<Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
									<Button
										variant="text"
										onClick={() => onClose()}
										size="medium"
										color="inherit"
										disabled={submitting}
									>
										Cancel
									</Button>
									{/* 
                                    // @ts-ignore */}
									<LoadingButton
										type="submit"
										disabled={pristine || submitting}
										loading={submitting}
										variant="contained"
										size="small"
										color="primary"
									>
										Confirm
									</LoadingButton>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default AddSupplyToCollectionDialog;
