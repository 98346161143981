import React, { useEffect } from 'react';

import UserSignedUpView from '@rdv-fo/components/security/ParticipantSignedUpView';
import ParticipantsNotFoundView from '@rdv-fo/components/ParticipantsNotFoundView';
import SignUpForm from '@rdv-fo/components/security/SignUpForm';
import {
	selectLoading,
	selectPlatformParticipantTypes,
	selectPlatformDisplayName,
} from '@rdv-fo/store/slices/platformSlice';
import { selectUserSignUpStatus, userSignUpStatusChanged, selectErrors } from '@rdv-fo/store/slices/authSlice';
import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';
import AuthPageStyle from '@rdv-fo/components/layout/AuthPageStyle';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import { selectSignUpPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';

const SignUpPage = () => {
	const dispatch = useAppDispatch();
	const { pttn: participantTypeTechName } = usePrimitiveQueryParams();

	const { form, description, title, eyebrow, style, image_position, image_url, form_submitted_view } = useAppSelector(selectSignUpPageConfig) ?? {};

	const loading = useAppSelector(selectLoading);
	const platformParticipantTypes = useAppSelector(selectPlatformParticipantTypes);

	const platformDisplayName = useAppSelector(selectPlatformDisplayName);
	const userSignUpStatus = useAppSelector(selectUserSignUpStatus);
	const errors = useAppSelector(selectErrors);

	const participantsUnavailable = platformParticipantTypes === undefined || platformParticipantTypes?.length < 1;
	let showSuccessView = API_STATUS.SUCCEEDED === userSignUpStatus;
	let showSignUpForm = showSuccessView === false && participantsUnavailable === false;

	useEffect(() => {
		dispatch(userSignUpStatusChanged(API_STATUS.IDLE));
	}, [dispatch]);

	return (
		<>
			{participantsUnavailable && <ParticipantsNotFoundView show={true} platformName={platformDisplayName} />}

			<AuthPageStyle
				style={style}
				imagePosition={image_position}
				image={image_url}
			>
				{showSuccessView && <UserSignedUpView viewUiConfig={form_submitted_view} />}
				{showSignUpForm && (
					<SignUpForm
						loading={loading}
						participantTypes={platformParticipantTypes}
						errors={errors}
						title={title}
						eyebrow={eyebrow}
						form={form}
						description={description}
						preselectedParticipantTypeTechName={participantTypeTechName}
					/>
				)}
			</AuthPageStyle>
		</>
	);
};

export default SignUpPage;
