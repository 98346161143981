import gql from 'graphql-tag';
import { print } from 'graphql';
import { QueryGetFrontofficeConfigurationArgs } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const GET_FRONTOFFICE_CONFIGURATION = gql`
	query GET_FRONTOFFICE_CONFIGURATION(
		$environment: MarketplaceEnvironmentKind!
		$marketplace_name: String
		$domain_name: String
	) {
		getFrontofficeConfiguration(
			environment: $environment
			marketplace_name: $marketplace_name
			domain_name: $domain_name
		) {
			rdv_fo_enabled
			marketplace_name
			marketplace_display_name
			participant_types {
				name
				tech_name
			}
			integration_providers {
				id
				name
				is_active
			}
			public_key
			ui_config {
				global_settings {
					favicon_url
					logo_url
					favicon_url
					logo_url
					logo_in_header
					marketplace_name_in_header
					primary_color
					secondary_color
					primary_slogan
					secondary_slogan
					sign_up_claim
					external_links {
						terms
						privacy
						imprint
						email
						linkedin
						instagram
						twitter
						facebook
						support
					}
				}
				landing_page {
					enabled
					hero {
						image_url
					}
					unique_selling_points {
						enabled
						title
						text_1_title
						text_1_subtitle
						text_1_description
						image_1_url
						text_2_title
						text_2_subtitle
						text_2_description
						image_2_url
						text_3_title
						text_3_subtitle
						text_3_description
						image_3_url
					}
					how_it_works {
						enabled
						title
						step_1_name
						step_1_title
						step_1_description
						image_1_url
						step_2_name
						step_2_title
						step_2_description
						image_2_url
						step_3_name
						step_3_title
						step_3_description
						image_3_url
					}
					about {
						enabled
						title
						subtitle
						text
						contact_button_enabled
					}
				}
				sign_up_page {
					style
					image_url
					image_position
					title
					eyebrow
					description
					form {
						field_email {
							label
							placeholder
						}
						field_participant_type {
							label
							input_control
						}
						field_first_name {
							label
							placeholder
						}
						field_last_name {
							label
							placeholder
						}
						button_submit {
							label
						}
						redirect_to_sign_in {
							text
							link_label
						}
					}
					form_submitted_view {
						title
						eyebrow
						description
					}
				}
				sign_in_page {
					style
					image_url
					image_position
					title
					eyebrow
					description
					form {
						field_email {
							label
							placeholder
						}
						field_password {
							label
							placeholder
						}
						button_submit {
							label
						}
						redirect_to_sign_up {
							text
							link_label
						}
					}
				}
				connections_page {
					title {
						label
						ids_participant_type
					}
				}
				connected_participant_page {
					title
				}
				dashboard_page {
					enabled
					title
					tiles {
						title
						target_route
						ids_participant_type
					}
				}
				onboarding_page {
					title
				}
				reset_password_page {
					style
					image_url
					image_position
					title
					description
					form {
						field_email {
							label
							placeholder
						}
						button_submit {
							label
						}
						redirect_to_sign_in {
							text
							link_label
						}
					}
					form_submitted_view {
						title
						description
					}
				}
				choose_new_password_page {
					title
					form {
						field_password {
							label
							placeholder
						}
						button_submit {
							label
						}
					}
				}
				verify_participant_account_page {
					style
					image_url
					image_position
					title
					description
					form {
						field_password {
							label
							placeholder
						}
						button_submit {
							label
						}
					}
				}
				participant_account_page {
					title
					description
					profile_section {
						tab_name
						title
						button_save {
							label
						}
					}
					payment_section {
						tab_name
						title
					}
				}
			}
		}
	}
`;

const foConfig = ({ environment, marketplace_name, domain_name }: QueryGetFrontofficeConfigurationArgs) => {
	return JSON.stringify({
		query: print(GET_FRONTOFFICE_CONFIGURATION),
		variables: {
			environment,
			marketplace_name: marketplace_name,
			domain_name: domain_name,
		},
	});
};

export { foConfig };
