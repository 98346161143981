import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import ROUTES from '@rdv-fo/common/routes';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { ParticipantStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const isOnboarded = (currentUser) => currentUser?.status === ParticipantStatusKind.Onboarded;

const PrivateRoute = ({ path, component: Component, render, ...rest }) => {
	let currentUser = useAppSelector(selectCurrentUser);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (!currentUser) {
					console.log('User is not signed-in. Redirect to sign-in page..');
					return (
						<Redirect
							to={{
								pathname: routeBuilder(ROUTES.SIGN_IN),
								state: { from: props.location },
							}}
						/>
					);
				}

				if (isOnboarded(currentUser)) {
					console.log('User is already onboarded. Redirect to landing page..');
					return (
						<Redirect
							to={{
								pathname: routeBuilder(ROUTES.LANDING_PAGE),
								state: { from: props.location },
							}}
						/>
					);
				}

				return Component ? <Component {...props} /> : render(props);
			}}
		/>
	);
};

export default PrivateRoute;
