import { TextField } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { FieldInputBaseProps } from './types';

import LabelFieldInput from './LabelFieldInput';
import { parseToNull } from '@rdv-fo/app/lib/formHelpers';
import { Stack } from '@mui/material';

const missingValue = ['', undefined, null];

interface TextInputOptions {
	required: boolean;
	minLength?: number;
	maxLength?: number;
}

const validate = (value: string, options: TextInputOptions) => {
	const { minLength, maxLength, required } = options;

	// if the value is not required and the user did not provide any value, we do not want to validate it
	// if the value is not required, we validate the value only if its provided
	if (!required && missingValue.includes(value)) return undefined;

	if (minLength && value?.length < minLength)
		return VALIDATION_MESSAGE.MIN_LENGTH_VIOLATION.replace('{min_length}', String(minLength));
	if (maxLength && value?.length > maxLength)
		return VALIDATION_MESSAGE.MAX_LENGTH_VIOLATION.replace('{max_length}', String(maxLength));

	if (required && (missingValue.includes(value) || !value?.trim()))
		return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	return undefined;
};

interface FieldInputTextProps extends FieldInputBaseProps {
	minLength?: number;
	maxLength?: number;
	keepNull?: boolean;
}
const FieldInputText = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	minLength,
	maxLength,
	keepNull = false,
}: FieldInputTextProps) => {
	const fieldProps = {
		validate: (value: string) => validate(value, { required, minLength, maxLength }),
		...(keepNull && { parse: parseToNull }),
	};

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>
			<TextField
				id={techName}
				name={techName}
				disabled={disabled}
				required={required}
				helperText={helperText}
				variant="outlined"
				size="small"
				margin="dense"
				fullWidth
				fieldProps={fieldProps}
			/>
		</Stack>
	);
};

export default FieldInputText;
