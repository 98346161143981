import { Box, Stack, Typography } from '@mui/material';
import { findFieldByCategory, findFieldByTechName } from '@rdv-fo/app/lib/fieldsHelper';
import { CurrencyKind, FieldCategoryKind, ShoppingCartItemForProvider } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import React from 'react';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import FieldDisplayValue from '@rdv-fo/components/fields/display/FieldDisplayValue';
import ConditionalFieldDisplay from '@rdv-fo/components/fields/display/ConditionalFieldDisplay';

interface ShoppingCartItemProps {
	imgUrl: string;
	fields: any;
	qty: number | undefined;
	itemPrice: number | undefined;
	totalPrice: number | undefined;
	name: string;
	showPrice: boolean;
	fees: any[];
	discounts: any[];
	currency: CurrencyKind;
}

const ShoppingCartItem = ({
	imgUrl,
	showPrice,
	fields,
	qty,
	itemPrice,
	totalPrice,
	name,
	fees,
	discounts,
	currency,
}: ShoppingCartItemProps) => {
	const fieldValues = {};
	fields?.forEach((field: any) => Object.assign(fieldValues, { [field?.field_type?.tech_name]: field?.value }));

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
			<Stack direction="row" spacing={2}>
				<Stack direction="column">
					<img src={imgUrl} alt="" width="150px" height="100px" style={{ objectFit: 'scale-down' }} />
				</Stack>
				<Stack direction="column" spacing={1}>
					<Typography variant="h4">{name}</Typography>
					<Box>
						<Typography variant="body1">Quantity: {qty}</Typography>
						{showPrice && (
							<Typography variant="body1">
								Item price: {itemPrice ? formatPriceAmount({ amount: itemPrice, currency }) : 'n.a'}
							</Typography>
						)}
						{showPrice && (
							<Typography variant="body1">
								Total: {totalPrice ? formatPriceAmount({ amount: totalPrice, currency }) : 'n.a'}
							</Typography>
						)}
						{fields?.map((field: any) => (
							<ConditionalFieldDisplay
								key={field?.field_type?.tech_name}
								fields={fields}
								values={fieldValues}
								conditions={field?.field_type?.conditions ?? []}
							>
								<FieldDisplayValue
									key={field?.field_type.tech_name}
									label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
									fieldTechName={field?.field_type.tech_name}
									inputType={field?.field_type.input_type}
									inputOptions={field?.field_type.input_options}
									optionsUiConfig={field?.field_type?.ui_config?.options}
									value={field.value}
								/>
							</ConditionalFieldDisplay>
						))}
						{showPrice &&
							discounts.map((discount) => (
								<>
									<Typography fontWeight="bold">{discount?.description}</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount: discount?.value,
											currency,
										})}
									</Typography>
								</>
							))}
						{showPrice &&
							fees.map((fee) => (
								<>
									<Typography fontWeight="bold">{fee?.description}</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount: fee?.value,
											currency,
										})}
									</Typography>
								</>
							))}
					</Box>
				</Stack>
			</Stack>
		</Stack>
	);
};

interface ShoppingCartItemsProps {
	items: ShoppingCartItemForProvider[];
	showPrice: boolean;
}

const ShoppingCartItemsForProvider = ({ items, showPrice }: ShoppingCartItemsProps) => {
	return (
		<Stack direction="column" spacing={5}>
			{items.map((item) => {
				const consumerSupplyFees = item?.price?.platform_fee_items?.filter((fee) => fee.is_consumer_fee) ?? [];
				const discountItems = item?.price?.discount_items ?? [];
				const qtyField = findFieldByTechName(item?.fields, 'qty')?.value;
				return (
					<>
						<ShoppingCartItem
							currency={item?.price?.currency ?? CurrencyKind.Eur}
							showPrice={showPrice}
							discounts={discountItems}
							fields={item?.fields}
							qty={item?.price?.qty ?? qtyField}
							itemPrice={item?.price?.unit_price}
							totalPrice={item?.price?.total_before_tax}
							name={findFieldByCategory(item?.supply_fields, FieldCategoryKind.Name)?.value}
							imgUrl={findFieldByCategory(item?.supply_fields, FieldCategoryKind.MainImage)?.value?.url}
							fees={consumerSupplyFees}
						/>
					</>
				);
			})}
		</Stack>
	);
};

export default ShoppingCartItemsForProvider;
