import Typography from '@mui/material/Typography';
import { formatDateTimeHumanFriendly } from '@rdv-fo/app/lib/datetime';
import { RdvDatetimeFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldDatetimeCellViewProps {
	value: RdvDatetimeFieldValue;
}
const FieldDatetimeCellView = ({ value }: FieldDatetimeCellViewProps) => {
	return <Typography variant="body2">{value == null ? '-' : formatDateTimeHumanFriendly(value)}</Typography>;
};

export default FieldDatetimeCellView;
