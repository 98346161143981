import { DateTimePicker } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';
import { FieldInputBaseProps } from './types';
import { LocalizationProvider } from '@mui/x-date-pickers';

import LabelFieldInput from './LabelFieldInput';
import { parse, format } from '@rdv-fo/app/lib/datetime';
import { Stack } from '@mui/material';

interface DatetimeInputOptions {
	required: boolean;
}
const validate = (value: any, options: DatetimeInputOptions) => {
	const { required } = options;

	if (required && (value === null || value === undefined)) return 'Please fill in this field';

	return undefined;
};

interface FieldInputDatetimeProps extends FieldInputBaseProps {
	pastOnly?: boolean;
	futureOnly?: boolean;
	unit?: string;
}

export const FieldInputDatetime = ({
	techName,
	label,
	labelProps,
	disabled = false,
	required = false,
	pastOnly = false,
	futureOnly = false,
}: FieldInputDatetimeProps) => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>

			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>
			<LocalizationProvider dateAdapter={DateFnsUtils}>
				<DateTimePicker
					name={techName}
					required={required}
					InputProps={{ onKeyDown: (e) => e.preventDefault() }}
					inputFormat="dd/MM/yyyy hh:mm a"
					fieldProps={{
						parse,
						format,
						validate: (value) => validate(value, { required }),
						disabled,
						disableFuture: pastOnly,
						disablePast: futureOnly,
						inputVariant: 'outlined',
						clearable: required,
					}}
				/>
			</LocalizationProvider>
		</Stack>
	);
};

export default FieldInputDatetime;
