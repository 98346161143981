import { createSlice } from '@reduxjs/toolkit';

import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';
import { sendInfoToasty, sendSuccessToasty, sendUnexpectedErrorToasty } from './uiSlice';
import randevu from '@rdv-fo/services/randevuApi';
import { isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { sleep } from '@rdv-fo/services/sleep';
import { loadMyTransaction } from './transactionSlice';

const slice = createSlice({
	name: 'match',
	initialState: {},
	reducers: {
		updateMatchRequested: (match, action) => {
			match.loading = true;
			match.errors = null;
		},
		updateMatchFailed: (match, action) => {
			match.loading = false;
			match.errors = action.payload;
		},

		matchUpdated: (match, action) => {
			match.loading = false;
		},
		acceptMatchRequested: (match, action) => {
			match.errors = null;
			match.acceptMatchStatus = API_STATUS.LOADING;
		},
		acceptMatchFailed: (match, action) => {
			match.errors = action.payload;
			match.acceptMatchStatus = API_STATUS.FAILED;
		},
		matchAccepted: (match, action) => {
			match.acceptMatchStatus = API_STATUS.SUCCEEDED;
		},
		declineMatchRequested: (match, action) => {
			match.errors = null;
			match.declineMatchStatus = API_STATUS.LOADING;
		},
		declineMatchFailed: (match, action) => {
			match.errors = action.payload;
			match.declineMatchStatus = API_STATUS.FAILED;
		},
		matchDeclined: (match, action) => {
			match.declineMatchStatus = API_STATUS.SUCCEEDED;
		},
	},
});

export const {
	updateMatchRequested,
	updateMatchFailed,
	matchUpdated,
	createDirectMatcherStatusChanged,
	acceptMatchRequested,
	acceptMatchFailed,
	matchAccepted,
	declineMatchRequested,
	declineMatchFailed,
	matchDeclined,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

export const updateTransactedMatch =
	({ id_transaction, dirty_fields = [], current_fields = [] }) =>
	async (dispatch, getState) => {
		dispatch(updateMatchRequested());

		const randevuService = new randevu({
			token: getState().auth.token,
			apiKey: getState().platform.public_key,
		});

		// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
		const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

		if (isSessionExpired(expiredSession))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: updateMatchFailed });

		const { field_inputs: dirty_fields_with_uploaded_files, errors: uploadErrors } =
			await randevuService.files.uploadFieldInputsFiles({
				field_inputs: dirty_fields,
				fields: current_fields,
			});

		const { updated, errors } = await randevuService.transactions.updateTransactedMatch({
			id_transaction,
			fields: dirty_fields_with_uploaded_files,
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(updateMatchFailed(errors));
		}

		await sleep(1000);

		dispatch(loadMyTransaction({ id_transaction: id_transaction }));
		return dispatch(matchUpdated());
	};

export const acceptTransactedMatch =
	({ id_transaction }) =>
	async (dispatch, getState) => {
		console.log(`Accept match..`);

		dispatch(acceptMatchRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { accepted, errors } = await randevuService.transactions.acceptTransactedMatch({ id_transaction });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: acceptMatchFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(acceptMatchFailed(errors));
		}

		await dispatch(sendSuccessToasty('Match accepted'));
		return dispatch(matchAccepted());
	};

export const declineTransactedMatch =
	({ id_transaction }) =>
	async (dispatch, getState) => {
		console.log(`Decline match..`);

		dispatch(declineMatchRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { declined, errors } = await randevuService.transactions.declineTransactedMatch({ id_transaction });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: declineMatchFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(declineMatchFailed(errors));
		}

		await dispatch(sendInfoToasty('Match declined'));

		return dispatch(matchDeclined());
	};
