import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Container } from '@mui/material';

interface LoadingIndicatorProps {
	loading?: boolean;
	height?: any; // FIXME: use proper type
}
const LoadingIndicator = ({ loading, height, }: LoadingIndicatorProps) => {
	const theme = useTheme();
	if (!loading) return null;

	return (
		<Container
			id="loading-indicator"
			maxWidth="sm"
			sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
			style={{ height: height ? theme.spacing(height) : 'initial' }}
		>
			<CircularProgress color="primary" />
		</Container>
	);
};

export default LoadingIndicator;
