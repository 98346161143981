import { Chip as MuiChip } from '@mui/material/';

import useChipStyles from '../common/chip/chipColors';
import { SupplyStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface SupplyStatusChipChip {
	size?: 'small' | 'medium';
	status: SupplyStatusKind;
	onboardingStateLabel?: string;
}
const SupplyStatusChip = ({ status, onboardingStateLabel = 'Onboarding', size }: SupplyStatusChipChip) => {
	const classes = useChipStyles();

	let colorClass;
	let value;

	switch (status) {
		case SupplyStatusKind.Onboarded:
			colorClass = classes.green;
			value = 'Onboarded';
			break;

		case SupplyStatusKind.Onboarding:
			colorClass = classes.blue;
			value = onboardingStateLabel;
			break;

		case SupplyStatusKind.Rejected:
			colorClass = classes.red;
			value = 'Rejected';
			break;

		default:
			colorClass = classes.blue;
			value = status;
	}

	const customClasses = `${colorClass} `;

	return <MuiChip label={value} className={customClasses} size={size} />;
};

export default SupplyStatusChip;
