import {
	FieldFilterInput,
	FieldParameterInput,
	MatchingUnitKind,
	InputKind,
	FieldType,
	NestedFieldFilter,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

export const AVAILABILITY_QUERY_PARAMS = {
	START_DATE_TIME: 'rdv_fo_av_start_datetime',
	END_DATE_TIME: 'rdv_fo_av_end_datetime',
	START_DATE: 'rdv_fo_av_start_date',
	END_DATE: 'rdv_fo_av_end_date',
	QUANTITY: 'qty',
};
export const TRANSACTION_TYPE_QUERY_PARAM = {
	TRANSACTION_TYPE_TECH_NAME: 'rdv_fo_tt_tech_name',
};

interface QueryParams {
	[key: string]: any;
}

export const parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords = (
	queryParams: QueryParams
): {
	filters: FieldFilterInput[];
	available_supply_only: Boolean;
	availability_params: FieldParameterInput[] | undefined;
	transaction_type_tech_name: string;
} => {
	const availabilityParamNames = Object.entries(AVAILABILITY_QUERY_PARAMS).map(
		(availabilityParam) => availabilityParam[1]
	);
	const transactionTypeParamNames = Object.entries(TRANSACTION_TYPE_QUERY_PARAM).map((tt) => tt[1]);

	const filters = Object.keys(queryParams)
		.filter((param_name: string) => !availabilityParamNames.includes(param_name))
		.filter((param_name: string) => !transactionTypeParamNames.includes(param_name))
		.filter((param_name: string) => param_name !== 'rdv_available_supply_only')
		.map((param_name: string) => ({ tech_name: param_name, value: queryParams[param_name] }));

	// Build availability record
	let availabilityRecord: any = {};
	Object.keys(queryParams)
		.filter((param_name: string) => availabilityParamNames.includes(param_name))
		.map((param_name: string) => ({
			[param_name.replace('rdv_fo_av_', '')]: queryParams[param_name],
		}))
		.map((constraint: any) => Object.assign(availabilityRecord, constraint));

	// use undefined is not availability record entry is available
	const availability_field_params =
		Object.keys(availabilityRecord)?.length > 0
			? Object.keys(availabilityRecord).map((av: string) => {
				return { tech_name: av, value: availabilityRecord[av] };
			})
			: undefined;

	const transaction_type_tech_name = Object.keys(queryParams)
		.filter((param_name: string) => transactionTypeParamNames.includes(param_name))
		.map((param_name: string) => queryParams[param_name])
		.pop();

	let available_supply_only = Object.keys(queryParams)
		.filter((param_name: string) => param_name === 'rdv_available_supply_only')
		.map((param_name: string) => queryParams[param_name])
		.pop();

	available_supply_only = available_supply_only?.[0] ?? false;

	return {
		filters,
		availability_params: availability_field_params,
		transaction_type_tech_name,
		available_supply_only,
	};
};

const mapTextFieldFilterInputToTextNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			text: {
				contains: fieldFilter.value
			}
		}
	}
}
const mapSmartTextFieldFilterInputToSmartTextNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			smart_text: {
				contains: fieldFilter.value
			}
		}
	}
}
const mapBooleanFieldFilterInputToBooleanNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			boolean: {
				equals: fieldFilter.value
			}
		}
	}
}
const mapTextSetFieldFilterInputToTextSetNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			text_set: {
				is_set: fieldFilter.value.is_provided
			}
		}
	}
}
const mapSmartTextSetFieldFilterInputToSmartTextSetNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			smart_text_set: {
				is_set: fieldFilter.value.is_provided
			}
		}
	}
}
const mapIntegerFieldFilterInputToIntegerNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			integer: {
				gte: fieldFilter.value.min_value,
				lte: fieldFilter.value.max_value
			}
		}
	}
}
const mapMonetaryValueFieldFilterInputToMonetaryValueNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			monetary_value: {
				gte: fieldFilter.value.min_value,
				lte: fieldFilter.value.max_value
			}
		}
	}
}
const mapDecimalFieldFilterInputToDecimalNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {

		tech_name: fieldFilter.tech_name,
		value: {
			decimal: {
				gte: fieldFilter.value.min_value,
				lte: fieldFilter.value.max_value
			}
		}
	}
}
const mapImageSetFieldFilterInputToImageSetNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			image_set: {
				is_set: fieldFilter.value?.is_provided
			}
		}
	}
}
const mapImageFieldFilterInputToImageNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			image: {
				is_set: fieldFilter.value.is_provided
			}
		}
	}
}
const mapDocumentFieldFilterInputToDocumentNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			document: {
				is_set: fieldFilter.value.is_provided
			}
		}
	}
}
const mapDocumentSetFieldFilterInputToDocumentSetNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			document_set: {
				is_set: fieldFilter.value.is_provided
			}
		}
	}
}
const mapDateFieldFilterInputToDateNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			date: {
				gte: fieldFilter.value.min_date,
				lte: fieldFilter.value.max_date
			}
		}
	}
}
const mapDateSetFieldFilterInputToDateSetNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			date_set: {
				in: fieldFilter.value.values
			}
		}
	}
}
const mapDateTimeFieldFilterInputToDateTimeNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			datetime: {
				gt: fieldFilter.value.min_date,
				lt: fieldFilter.value.max_date
			}
		}
	}
}
const mapSelectFieldFilterInputToSelectNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			select: {
				equals: fieldFilter.value.values?.[0] // TODO @Rokva - Dirty workaround, should be fixed in the component since behaviour of the filter has changed
			}
		}
	}
}
const mapMultiSelectFieldFilterInputToMultiSelectNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			multi_select: {
				equals: fieldFilter.value.values
			}
		}
	}
}
const mapLocationTimeSetFieldFilterInputToLocationTimeSetNestedFieldFilterInput = (fieldFilter: FieldFilterInput): NestedFieldFilter => {
	return {
		tech_name: fieldFilter.tech_name,
		value: {
			location: {
				radius: {
					point: {
						lng: fieldFilter.value.lng,
						lat: fieldFilter.value.lat
					},
					radius: parseInt(fieldFilter.value.radius)
				}
			}
		}
	}
}

export const mapFieldFiltersToNestedFieldFilters = (fieldFilters: FieldFilterInput[], fieldTypes: FieldType[]): NestedFieldFilter[] => {
	return fieldFilters.map((fieldFilter): NestedFieldFilter => {
		const inputType = fieldTypes.find((fieldType) => fieldType.tech_name === fieldFilter.tech_name)?.input_type;

		if (!inputType) throw new Error(`Field type not found for field filter ${fieldFilter.tech_name}`);

		switch (inputType) {
			case InputKind.Text:
				return mapTextFieldFilterInputToTextNestedFieldFilterInput(fieldFilter);
			case InputKind.Boolean:
				return mapBooleanFieldFilterInputToBooleanNestedFieldFilterInput(fieldFilter);
			case InputKind.TextSet:
				return mapTextSetFieldFilterInputToTextSetNestedFieldFilterInput(fieldFilter);
			case InputKind.SmartText:
				return mapSmartTextFieldFilterInputToSmartTextNestedFieldFilterInput(fieldFilter);
			case InputKind.SmartTextSet:
				return mapSmartTextSetFieldFilterInputToSmartTextSetNestedFieldFilterInput(fieldFilter);
			case InputKind.Integer:
				return mapIntegerFieldFilterInputToIntegerNestedFieldFilterInput(fieldFilter);
			case InputKind.MonetaryValue:
				return mapMonetaryValueFieldFilterInputToMonetaryValueNestedFieldFilterInput(fieldFilter);
			case InputKind.Decimal:
				return mapDecimalFieldFilterInputToDecimalNestedFieldFilterInput(fieldFilter);
			case InputKind.ImageSet:
				return mapImageSetFieldFilterInputToImageSetNestedFieldFilterInput(fieldFilter);
			case InputKind.Image:
				return mapImageFieldFilterInputToImageNestedFieldFilterInput(fieldFilter);
			case InputKind.Document:
				return mapDocumentFieldFilterInputToDocumentNestedFieldFilterInput(fieldFilter);
			case InputKind.DocumentSet:
				return mapDocumentSetFieldFilterInputToDocumentSetNestedFieldFilterInput(fieldFilter);
			case InputKind.Date:
				return mapDateFieldFilterInputToDateNestedFieldFilterInput(fieldFilter);
			case InputKind.DateSet:
				return mapDateSetFieldFilterInputToDateSetNestedFieldFilterInput(fieldFilter);
			case InputKind.Datetime:
				return mapDateTimeFieldFilterInputToDateTimeNestedFieldFilterInput(fieldFilter);
			case InputKind.Select:
				return mapSelectFieldFilterInputToSelectNestedFieldFilterInput(fieldFilter);
			case InputKind.MultiSelect:
				return mapMultiSelectFieldFilterInputToMultiSelectNestedFieldFilterInput(fieldFilter);
			case InputKind.Location:
				return mapLocationTimeSetFieldFilterInputToLocationTimeSetNestedFieldFilterInput(fieldFilter);
			case InputKind.Participant:
				throw new Error('Not implemented')
			case InputKind.ParticipantSet:
				throw new Error('Not implemented')
		}

		throw new Error('Input type not found')
	})
}

export const determineAvailabilityFields = (matching_unit: MatchingUnitKind): any[] => {
	let availabilityFields: any[] = [];
	switch (matching_unit) {
		case MatchingUnitKind.PerUnit:
			availabilityFields.push({
				id: AVAILABILITY_QUERY_PARAMS.QUANTITY,
				tech_name: AVAILABILITY_QUERY_PARAMS.QUANTITY,
				input_type: InputKind.Integer,
				name: 'Quantity',
				is_required: true,
				initial_value: {
					value: 1
				}
			});
			break;
		case MatchingUnitKind.PerEvent:
			availabilityFields.push({
				id: AVAILABILITY_QUERY_PARAMS.QUANTITY,
				tech_name: AVAILABILITY_QUERY_PARAMS.QUANTITY,
				input_type: InputKind.Integer,
				name: 'Quantity',
				is_required: true,
				initial_value: {
					value: 1
				}
			});
			break;
		case MatchingUnitKind.PerDay:
			availabilityFields.push({
				id: AVAILABILITY_QUERY_PARAMS.START_DATE,
				tech_name: AVAILABILITY_QUERY_PARAMS.START_DATE,
				input_type: InputKind.Date,
				name: 'Start date',
				is_required: true,
			});
			availabilityFields.push({
				id: AVAILABILITY_QUERY_PARAMS.END_DATE,
				tech_name: AVAILABILITY_QUERY_PARAMS.END_DATE,
				input_type: InputKind.Date,
				name: 'End date',
				is_required: true,
			});

			break;
		case MatchingUnitKind.PerHour:
			availabilityFields.push({
				id: AVAILABILITY_QUERY_PARAMS.START_DATE_TIME,
				tech_name: AVAILABILITY_QUERY_PARAMS.START_DATE_TIME,
				input_type: InputKind.Datetime,
				name: 'Start date',
				is_required: true,
			});
			availabilityFields.push({
				id: AVAILABILITY_QUERY_PARAMS.END_DATE_TIME,
				tech_name: AVAILABILITY_QUERY_PARAMS.END_DATE_TIME,
				input_type: InputKind.Datetime,
				name: 'End date',
				is_required: true,
			});
			break;
		default:
			availabilityFields = [];
	}
	return availabilityFields;
};
