import { Button, Paper, Stack, Typography } from '@mui/material';
import ROUTES from '@rdv-fo/common/routes';
import { RDV_NEUTRAL_10 } from '@rdv-fo/styles/theme';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';

interface ShoppingCartEmptyStateProps {
    supplyTypeName: string;
    transactionTypeTechName: string;
}

const ShoppingCartEmptyState = ({ supplyTypeName, transactionTypeTechName }: ShoppingCartEmptyStateProps) => {
    return (
        <Stack
            component={Paper}
            sx={{ backgroundColor: RDV_NEUTRAL_10 }}
            direction="column"
            elevation={0}
            paddingY={10}
            width="100%"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Typography variant="h4">Your shopping cart is empty</Typography>
            <Typography variant="body1" maxWidth={600} textAlign="center">
                You don’t have anything in your shopping cart. Let’s change that, use the link below to start browsing.{' '}
            </Typography>
            <Button
                variant="contained"
                component={Link}
                to={`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES?.replace(
                    ':transactionTypeTechName',
                    transactionTypeTechName
                )}`}
            >
                {capitalize(`Browse ${supplyTypeName}`.toLowerCase())}
            </Button>
        </Stack>
    );
};

export default ShoppingCartEmptyState;
