import LabelFilterInput from './LabelFilterInput';
import { Select } from 'mui-rff';
import MenuItem from '@mui/material/MenuItem';
import { FilterInputBaseProps } from './types';

interface FilterInputSingleSelectProps extends FilterInputBaseProps {
	options: string[];
	optionsUiConfig?: { label: string, value: string }[];
}

const FilterInputSingleSelect = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
	options,
	optionsUiConfig
}: FilterInputSingleSelectProps) => {
	let selectData = [];

	if (!required) selectData.push({ label: ' -- None -- ', value: null as any });

	options?.forEach((opt: string) => {
		const label = optionsUiConfig?.find((optionConfig) => optionConfig.value === opt)?.label;
		selectData.push({ label: label ?? opt, value: opt });
	});

	return (
		<>
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}
			<Select
				name={`${techName}.values`}
				id={techName}
				variant="outlined"
				margin="dense"
				disabled={disabled}
				fullWidth
				multiple
			>
				<MenuItem value="" />
				{selectData?.map((opt) => (
					<MenuItem key={opt.value} value={opt.value}>
						{opt.label}
					</MenuItem>
				))}
			</Select>
		</>
	);
};

export default FilterInputSingleSelect;
