import { useMemo } from 'react';
import createDecorator from 'final-form-focus';
import { Grid } from '@mui/material';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import LoadingButton from './LoadingButton';
import FieldInput from '../fields/input/FieldInput';
import ConditionalFieldInput from '@rdv-fo/components/fields/input/ConditionalFieldInput';
import LoadingIndicator from './LoadingIndicator';
import { FieldAccessKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { mapFieldsToFormValues } from '@rdv-fo/app/lib/formHelpers';

const OnboardingForm = ({
	fields,
	sharedObjectTypes,
	sharedObjects,
	onSubmit,
	submitText = 'Continue',
	showSubmitBtn = true,
	loading = false,
}) => {
	const focusOnError = useMemo(() => createDecorator(), []);
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);
	return (
		<>
			{loading ? (
				<>
					<br />
					<br />
					<LoadingIndicator loading={loading} />
					<br />
					<br />
					<br />
					<br />
				</>
			) : (
				<Form
					onSubmit={onSubmit}
					initialValues={mapFieldsToFormValues(fields, sharedObjectTypeIds)}
					decorators={[focusOnError]}
					mutators={{
						...arrayMutators,
					}}
				>
					{(props) => {
						const { handleSubmit, submitting, submitFailed, dirtySinceLastSubmit, pristine, errors } =
							props;

						return (
							<form onSubmit={handleSubmit} noValidate>
								<Grid container spacing={3}>
									{fields?.map((field) => {
										const readOnlyField = FieldAccessKind.Read === field.my_access;
										return (
											<ConditionalFieldInput
												key={field?.field_type.tech_name}
												fields={fields}
												conditions={field?.field_type?.conditions}
											>
												<Grid item xs={12}>
													<FieldInput
														label={
															field.field_type?.ui_config?.label ?? field.field_type?.name
														}
														sharedObjects={sharedObjects}
														optionsUiConfig={field?.field_type?.ui_config?.options}
														helperText={field.field_type?.ui_config?.helper_text}
														fieldTechName={field.field_type.tech_name}
														disabled={submitting || readOnlyField}
														readOnlyField={readOnlyField}
														sharedObjectTypes={sharedObjectTypes}
														inputType={field?.field_type?.input_type}
														inputOptions={field?.field_type.input_options}
														objectTypeFields={field?.object_type?.fields}
														required={field?.field_type?.is_required}
													/>
												</Grid>
											</ConditionalFieldInput>
										);
									})}
									{showSubmitBtn ? (
										<Grid item xs={12}>
											<LoadingButton
												loading={submitting}
												disabled={
													pristine || submitting || (submitFailed && !dirtySinceLastSubmit)
												}
												type="submit"
												variant="contained"
												color="primary"
											>
												{submitText}
											</LoadingButton>
										</Grid>
									) : null}
								</Grid>
							</form>
						);
					}}
				</Form>
			)}
		</>
	);
};

export default OnboardingForm;
