import { Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Form } from 'react-final-form';
import ClearIcon from '@mui/icons-material/Clear';
import { InputKind, SupplyType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { FormApi } from 'final-form';
import { Select } from 'mui-rff';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import FieldInput from '@rdv-fo/components/fields/input/FieldInput';
import LabelFieldInput from '@rdv-fo/components/fields/input/LabelFieldInput';
import Alert from '@mui/material/Alert';

export type CreateCollectionFormValues = {
	name: string;
};

interface CreateCollectionDialogProps {
	open: boolean;
	supplyTypes: SupplyType[];
	onClose: () => void;
	onSubmit: (values: any, form: FormApi) => any;
}

const CreateCollectionDialog = ({ open, supplyTypes, onClose, onSubmit }: CreateCollectionDialogProps) => {
	//const showSupplyTypeOptions = supplyTypes.filter((st) => st.flow_type.is_active)?.length > 1;
	const showSupplyTypeOptions = false;
	const noActiveSupplyTypes = supplyTypes.filter((st) => st.flow_type.is_active)?.length < 1;

	const selectSupplyTypeData = supplyTypes
		?.filter((st) => st.flow_type.is_active)
		?.map((st) => ({ label: st.name, value: st.tech_name }));

	return (
		<Dialog aria-labelledby="create-collection" open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle id="create-collection-title">
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h4">Add new collection</Typography>
					<IconButton onClick={onClose}>
						<ClearIcon fontSize="medium" />
					</IconButton>
				</Stack>
			</DialogTitle>
			<Divider />
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, submitting, pristine, values }) => (
					<form onSubmit={handleSubmit} noValidate>
						<DialogContent>
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={2}
								textAlign="left"
							>
								{noActiveSupplyTypes && (
									<Alert severity="warning">
										<Typography variant="body1" fontWeight="medium">
											The platform operator did not enable any supply type. Please contact the
											platform operator to enable the feature.
										</Typography>
									</Alert>
								)}
								{showSupplyTypeOptions && (
									<>
										<LabelFieldInput
											text="For what supply type do you want to create a collection?"
											htmlFor="tech_name"
											required={true}
											showOptional={false}
										/>

										<Select
											name="tech_name"
											disabled={submitting}
											required={true}
											data={selectSupplyTypeData}
											variant="outlined"
											formControlProps={{ margin: 'dense', size: 'small' }}
											fullWidth
										/>
									</>
								)}
								{!noActiveSupplyTypes && (
									<FieldInput
										label={'Name'}
										fieldTechName="name"
										inputType={InputKind.Text}
										inputOptions={{}}
										sharedObjectTypes={[]}
										sharedObjects={[]}
										required={true}
										disabled={submitting}
									/>
								)}
								<Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
									<Button
										variant="text"
										onClick={() => onClose()}
										size="medium"
										color="inherit"
										disabled={submitting}
									>
										Cancel
									</Button>
									<LoadingButton
										type="submit"
										disabled={pristine || submitting}
										loading={submitting}
										variant="contained"
										size="small"
										color="primary"
									>
										Create
									</LoadingButton>
								</Stack>
							</Stack>
						</DialogContent>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default CreateCollectionDialog;
