import { Paper, Box, Stack, Typography } from '@mui/material';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';

interface AccountCardProps {
	icon: React.ReactChild;
	title: string;
	description: string;
	routeTo?: string;
	comingSoon?: boolean;
}
const AccountCard = ({ icon, title, description, routeTo, comingSoon }: AccountCardProps) => {
	const dispatch = useAppDispatch();

	return (
		<Paper
			sx={{ p: 3, borderRadius: '12px', cursor: 'pointer' }}
			elevation={2}
			onClick={() => {
				if (comingSoon) {
					alert('Coming soon');
					return;
				}

				if (routeTo) dispatch(goToRoute(routeBuilder(routeTo)));
			}}
		>
			<Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={2} height={160}>
				<Box>{icon}</Box>
				<Box>
					<Stack
						direction="column"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
						sx={{ height: '100%' }}
					>
						<Typography variant="h4">{title}</Typography>
						<Typography mt={2} variant="body1" color="textSecondary">
							{description}
						</Typography>
					</Stack>
				</Box>
			</Stack>
		</Paper>
	);
};

export default AccountCard;
