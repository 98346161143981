import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Box, Button, Divider } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import ROUTES from '@rdv-fo/common/routes';
import { useState } from 'react';
import { selectPlatformName } from '@rdv-fo/store/slices/platformSlice';

import { useAppSelector } from '@rdv-fo/store/configureStore';

import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import UpdateMyEmailDialog from '@rdv-fo/components/participant/UpdateMyEmailDialog';
import UpdateMyPasswordDialog from '@rdv-fo/components/participant/UpdateMyPasswordDialog';
import { getTimeAgoString } from '@rdv-fo/app/lib/datetime';
import CloseMyParticipantAccountDialog from '@rdv-fo/components/participant/CloseMyParticipantAccountDialog';

const MyCredentialsPage = () => {
	const currentUser = useAppSelector(selectCurrentUser) as any;
	const platformName = useAppSelector(selectPlatformName);

	const [openChangeEmailDialog, setOpenChangeEmailDialog] = useState<boolean>(false);
	const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState<boolean>(false);
	const [openCloseMyParticipantAccountDialog, setOpenCloseMyParticipantAccountDialog] = useState<boolean>(false);

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<BackButton label="Account" to={ROUTES.MY_ACCOUNT} />
				<Typography component="h1" variant="h1">
					Login & Security
				</Typography>
			</Stack>

			<UpdateMyEmailDialog open={openChangeEmailDialog} onClose={() => setOpenChangeEmailDialog(false)} />
			<UpdateMyPasswordDialog open={openChangePasswordDialog} onClose={() => setOpenChangePasswordDialog(false)} />
			<CloseMyParticipantAccountDialog open={openCloseMyParticipantAccountDialog} onClose={() => setOpenCloseMyParticipantAccountDialog(false)} participantId={currentUser?.id ?? ""} />
			<Box sx={{ mt: 5 }}>
				<Stack direction="column" spacing={4}>
					<Stack direction="column" spacing={2}>
						<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
							<Stack direction="column">
								<Typography variant="subtitle2">Email</Typography>
								<Typography variant="body1" color="textSecondary">
									{currentUser?.email}
								</Typography>
							</Stack>
							<Button
								color="primary"
								variant="outlined"
								onClick={() => setOpenChangeEmailDialog(true)}
								size="small"
							>
								Change
							</Button>
						</Stack>
						<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
							<Stack direction="column">
								<Typography variant="subtitle2">Password</Typography>
								<Typography variant="body1" color="textSecondary">
									{getTimeAgoString(currentUser?.password_updated_at)}
								</Typography>
							</Stack>
							<Button color="primary" variant="outlined" onClick={() => setOpenChangePasswordDialog(true)}
								size="small">
								Change
							</Button>
						</Stack>
					</Stack>
					<Divider />
					<Stack direction="column" spacing={2}>
						<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
							<Stack direction="column">
								<Typography variant="body1">Close your account</Typography>
								<Typography variant="body1" color="textSecondary">
									{`If you decide to use ${platformName} again, you’ll need to create a new account`}
								</Typography>
							</Stack>



							<Button
								color="error"
								variant="outlined"
								onClick={() => setOpenCloseMyParticipantAccountDialog(true)}
								size="small"
							>
								Close
							</Button>
						</Stack>

					</Stack>
				</Stack>
			</Box>
		</>
	);
};

export default MyCredentialsPage;
