import { useState } from 'react';

import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';

import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { Link as RouterLink } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import ROUTES from '@rdv-fo/common/routes';
import RandevuInputLabel from '@rdv-fo/components/common/RandevuInputLabel';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { signIn, signInUserFailed, loadCurrentUser, loadCurrentUserFailed } from '@rdv-fo/store/slices/authSlice';
import FormError from '../FormError';
import { validators } from '@rdv-fo/app/lib/validation';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import { Button, Stack } from '@mui/material';

const SignInForm = ({ sx, title, eyebrow, description, form }) => {
	const dispatch = useAppDispatch();
	const [showPassword, setShowPassword] = useState(false);

	const { 'test-username': testUsername, 'test-password': testPassword } = usePrimitiveQueryParams();

	const initialValues = { email: testUsername, password: testPassword };
	const doSubmit = async (values) => {
		const resolvedAction = await dispatch(
			signIn({
				email: values.email,
				password: values.password,
			})
		);

		if (resolvedAction.type === signInUserFailed.type) return resolvedAction.payload;

		const resolvedActionTwo = await dispatch(loadCurrentUser());
		if (resolvedActionTwo?.type === loadCurrentUserFailed.type) return resolvedActionTwo.payload;
	};

	const fieldValidators = {
		email: [validators.email],
		password: [validators.required],
	};

	return (
		<Container maxWidth="sm" id="sign-in-form-max-width" sx={sx}>
			<Stack direction="column" spacing={1} sx={{ marginBottom: 4 }}>
				<Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
					{eyebrow}
				</Typography>
				<Typography component="h1" variant="h1" sx={{ wordBreak: 'break-word' }}>
					{title}
				</Typography>
				<Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
					{description}
				</Typography>
			</Stack>

			<Form
				onSubmit={doSubmit}
				initialValues={initialValues}
				validate={(values) => validators.validate(fieldValidators, values)}
			>
				{({ handleSubmit, submitError, submitting, submitFailed, dirtySinceLastSubmit }) => {
					const submitButtonDisabled = submitting || (submitFailed && !dirtySinceLastSubmit);

					return (
						<form onSubmit={handleSubmit} method="POST" id="sign-in-form" noValidate>
							<Grid container spacing={2}>
								{submitError && !dirtySinceLastSubmit && (
									<Grid item xs={12}>
										<FormError message={submitError} />
									</Grid>
								)}
								<Grid item xs={12}>
									<RandevuInputLabel text={form?.field_email?.label} htmlFor="email" />
									<TextField
										autoFocus
										id="email"
										name="email"
										placeholder={form?.field_email?.placeholder}
										type="email"
										variant="outlined"
										margin="normal"
										disabled={submitting}
										required
										fullWidth
										autoComplete="email"
									/>
								</Grid>
								<Grid item xs={12}>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={0.5}
									>
										<RandevuInputLabel text={form?.field_password?.label} htmlFor="password" />

										<Button
											variant="text"
											color="primary"
											sx={{ fontWeight: 800 }}
											component={RouterLink}
											to={routeBuilder(ROUTES.FORGOT_PASSWORD)}
										>
											{form?.field_password?.placeholder}
										</Button>
									</Stack>
									<TextField
										name="password"
										id="password"
										type={showPassword ? 'text' : 'password'}
										variant="outlined"
										margin="normal"
										disabled={submitting}
										required
										fullWidth
										autoComplete="current-password"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword(!showPassword)}
														onMouseDown={() => setShowPassword(!showPassword)}
														size="large"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sx={{
										marginTop: 2,
										paddingLeft: 1,
									}}
								>
									<Grid container spacing={2} justifyContent="space-between" alignItems="center">
										<Grid item>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={0.5}
											>
												<Typography variant="body1">
													{form?.redirect_to_sign_up?.text}
												</Typography>

												<Button
													variant="text"
													color="primary"
													sx={{ fontWeight: 800 }}
													component={RouterLink}
													to={routeBuilder(ROUTES.SIGN_UP)}
												>
													{form?.redirect_to_sign_up?.link_label}
												</Button>
											</Stack>
										</Grid>

										<Grid item>
											<LoadingButton
												type="submit"
												form="sign-in-form"
												loading={submitting}
												disabled={submitButtonDisabled}
												fullWidth
											>
												{form?.button_submit?.label}
											</LoadingButton>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					);
				}}
			</Form>
		</Container>
	);
};

export default SignInForm;
