import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button, Divider, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PageWidth from '../layout/PageWidth';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import { selectTransactionTypes } from '@rdv-fo/store/slices/transactionSlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import {
	selectDashboardPageConfig,
	selectGlobalUiConfig,
	selectPlatformDisplayName,
	selectPlatformSignUpClaim,
	selectLandingPageConfig,
} from '@rdv-fo/store/slices/platformSlice';
import { DRAWER_WIDTH } from '@rdv-fo/app/constants';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import { selectMySupplyTypes } from '@rdv-fo/store/slices/supplySlice';
import { signOut } from '@rdv-fo/store/slices/authSlice';
import {
	TransactionKind,
	MatchTypeRoleKind,
	TransactionType,
	ConnectionType,
	MatchType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { selectMyActiveShoppingCart } from '@rdv-fo/store/slices/shoppingCartSlice';
import { selectMyConnectionTypes } from '@rdv-fo/store/slices/connectionSlice';
import { capitalize } from 'lodash';

interface HeaderProps {
	pageGroup: 'landing' | 'auth' | 'userOnboarding';
	window: any;
}

const Header = ({ pageGroup = 'landing', window }: HeaderProps) => {
	const theme = useTheme();

	const dispatch = useAppDispatch();

	const global_ui_config = useAppSelector(selectGlobalUiConfig);
	const currentUser = useAppSelector(selectCurrentUser);
	const currentUserSupplyType = useAppSelector(selectMySupplyTypes);
	const platformSingUpClaim = useAppSelector(selectPlatformSignUpClaim);
	const dashboardPageEnabled = useAppSelector(selectDashboardPageConfig)?.enabled;
	const landingPageEnabled = useAppSelector(selectLandingPageConfig)?.enabled;
	const transactionTypes = useAppSelector(selectTransactionTypes);
	const platformDisplayName = useAppSelector(selectPlatformDisplayName) ?? '';
	const shoppingCart = useAppSelector(selectMyActiveShoppingCart);
	const connectionTypes = useAppSelector(selectMyConnectionTypes);
	const [openNavigationDrawer, setOpenNavigationDrawer] = useState(false);

	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isLoggedIn = currentUser !== null;
	let isAuthPageGroup = pageGroup === 'auth';
	let isParticipantOnboardingPageGroup = pageGroup === 'userOnboarding';

	const isProvider = isLoggedIn && currentUserSupplyType?.length >= 1;

	const isMultiDirectEnabledAndUserConsumer =
		transactionTypes?.filter(
			(tt: TransactionType) => tt.type === TransactionKind.MultipleDirect && tt.my_role?.includes('CONSUMER')
		)?.length >= 1;

	const activeTransactionTypes = transactionTypes?.filter((tt) => tt.is_active) ?? [];

	const enabledSingleDirectTransactions =
		activeTransactionTypes.filter((tt) => TransactionKind.SingleDirect === tt.type) ?? [];

	const enabledMultiDirectTransactions =
		activeTransactionTypes.filter((tt) => TransactionKind.MultipleDirect === tt.type) ?? [];

	const enabledReverseAuctionTransactions =
		activeTransactionTypes.filter((tt) => TransactionKind.ReverseAuction === tt.type) ?? [];

	const handleGoToRoute = (targetRoute: string) => {
		setOpenNavigationDrawer(false);
		dispatch(goToRoute(routeBuilder(targetRoute)));
	};

	const handleSignOut = () => {
		dispatch(signOut({ options: { redirectTo: ROUTES.LANDING_PAGE } }));
	};

	let targetRoute = ROUTES.LANDING_PAGE;

	if (dashboardPageEnabled) targetRoute = ROUTES.DASHBOARD;
	if (landingPageEnabled) targetRoute = ROUTES.LANDING_PAGE;

	const sxNavigationItemBold = { variant: 'body1', fontWeight: 'bold', pl: 3 };
	const sxNavigationItem = { variant: 'body1', pl: 3 };

	return (
		<>
			<AppBar position="static" color="inherit" elevation={0} sx={{ zIndex: 1000 }}>
				<PageWidth>
					<Toolbar disableGutters>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
							sx={{ width: '100%' }}
						>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
								{global_ui_config?.logo_in_header === true && global_ui_config?.logo_url && (
									<Button to={routeBuilder(targetRoute)} component={RouterLink}>
										<img
											style={{ height: theme.spacing(5) }}
											src={global_ui_config?.logo_url}
											alt={platformDisplayName}
										/>
									</Button>
								)}
								{global_ui_config?.marketplace_name_in_header === true && (
									<Button color="inherit" to={routeBuilder(ROUTES.HOME)} component={RouterLink}>
										<Typography
											variant="subtitle1"
											component={RouterLink}
											color="inherit"
											sx={{ textDecoration: 'none' }}
											to={routeBuilder(ROUTES.HOME)}
										>
											{platformDisplayName}
										</Typography>
									</Button>
								)}
							</Stack>

							<Box sx={{ flex: 1 }} />

							<Stack
								id="header-navigation"
								direction="row"
								justifyContent="flex-end"
								alignItems="center"
								spacing={1}
							>
								{!isLoggedIn && (
									<>
										{!isMobileScreen && !isAuthPageGroup && (
											<>
												{/* <Button
													variant="text"
													color="inherit"
													component={RouterLink}
													to={routeBuilder(ROUTES.CONTACT)}
												>
													Contact
												</Button> */}
												<Button
													variant="text"
													color="inherit"
													component={RouterLink}
													to={routeBuilder(ROUTES.SIGN_UP)}
												>
													{platformSingUpClaim}
												</Button>
											</>
										)}

										{!isAuthPageGroup && (
											<Button
												variant="text"
												color="inherit"
												sx={{ fontWeight: 700 }}
												component={RouterLink}
												to={routeBuilder(ROUTES.SIGN_IN)}
											>
												Log in
											</Button>
										)}
										{isMobileScreen && (
											<Button
												variant="text"
												color="inherit"
												endIcon={<MenuIcon />}
												sx={{ fontWeight: 700 }}
												onClick={() => setOpenNavigationDrawer(true)}
											/>
										)}
									</>
								)}
								{isLoggedIn && (
									<>
										{dashboardPageEnabled &&
											!isMobileScreen &&
											!isParticipantOnboardingPageGroup && (
												<Button
													variant="text"
													color="inherit"
													component={RouterLink}
													to={routeBuilder(ROUTES.DASHBOARD)}
												>
													Dashboard
												</Button>
											)}
										{isMultiDirectEnabledAndUserConsumer && (
											<Button
												variant="text"
												color="inherit"
												component={RouterLink}
												to={routeBuilder(ROUTES.SHOPPING_CART)}
											>
												Cart (
												<Typography fontWeight={700}>
													{shoppingCart?.items?.length ?? <Skeleton width={10} />}
												</Typography>
												)
											</Button>
										)}

										{!isParticipantOnboardingPageGroup && (
											<Button
												variant="text"
												color="inherit"
												onClick={() => setOpenNavigationDrawer(true)}
												endIcon={<MenuIcon />}
												sx={{ fontWeight: 700 }}
											>
												{currentUser?.first_name}
											</Button>
										)}
										{isParticipantOnboardingPageGroup && (
											<Button
												variant="text"
												color="inherit"
												onClick={() => {
													handleSignOut();
													setOpenNavigationDrawer(false);
												}}
												endIcon={<LogoutIcon />}
												sx={{ fontWeight: 700 }}
											>
												Log out
											</Button>
										)}
									</>
								)}
							</Stack>
						</Stack>
					</Toolbar>
				</PageWidth>
				<Divider />
			</AppBar>

			<Drawer
				open={openNavigationDrawer}
				onClose={() => setOpenNavigationDrawer(false)}
				sx={{
					width: DRAWER_WIDTH,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: DRAWER_WIDTH,
						boxSizing: 'border-box',
					},
					p: 5,
				}}
				anchor="right"
				variant="temporary"
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				<Stack
					id="sidebar-navigation-header"
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					sx={{ p: 5, pb: 1 }}
				>
					{!currentUser && <Typography variant="subtitle1">Welcome!</Typography>}
					{currentUser && <Typography variant="subtitle1">Hi {currentUser?.first_name}</Typography>}
					<IconButton aria-label="close menu" color="inherit" onClick={() => setOpenNavigationDrawer(false)}>
						<CloseIcon sx={{ fontSize: 22 }} />
					</IconButton>
				</Stack>

				<Box sx={{ width: '100%' }} id="sidebar-navigation-body">
					<nav>
						<List>
							{/** SECTION: SIGN-IN & SIGN-UP  */}
							{!isLoggedIn && (
								<>
									<ListItem disablePadding>
										<ListItemButton
											onClick={() => {
												handleGoToRoute(ROUTES.SIGN_IN);
											}}
										>
											<ListItemText
												primary="Log in"
												primaryTypographyProps={{ sx: sxNavigationItemBold }}
											/>
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton
											onClick={() => {
												handleGoToRoute(ROUTES.SIGN_UP);
											}}
										>
											<ListItemText
												primary={platformSingUpClaim}
												primaryTypographyProps={{ sx: sxNavigationItem }}
											/>
										</ListItemButton>
									</ListItem>
								</>
							)}

							{/** SECTION: CREATING & STARTING TRANSACTIONS SECTION FOR GUESTS USERS */}
							{!isLoggedIn && (
								<>
									{enabledSingleDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Guest === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.supply_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = tt?.ui_config?.prepare
												? tt?.ui_config?.prepare
												: capitalize(
													`Browse ${(tt?.root_type as any)?.match_type?.supply_type?.name
														}s`.toLowerCase()
												);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}

									{enabledSingleDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Guest === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.provider_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = capitalize(
												`Browse ${((tt?.root_type as any)?.match_type as MatchType)?.provider_type
													?.name ?? 'provider'
													}s`.toLowerCase()
											);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_PROVIDERS.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}

									{enabledMultiDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Guest === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.supply_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = tt?.ui_config?.prepare
												? tt?.ui_config?.prepare
												: capitalize(
													`Browse ${(tt?.root_type as any)?.match_type?.supply_type?.name
														}s`.toLowerCase()
												);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}
									{enabledMultiDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Guest === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.provider_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = capitalize(
												`Browse ${((tt?.root_type as any)?.match_type as MatchType)?.provider_type
													?.name ?? 'provider'
													}s`.toLowerCase()
											);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_PROVIDERS.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}
								</>
							)}

							{/** SECTION: CREATING & STARTING TRANSACTIONS SECTION FOR LOGGED IN USERS */}
							{isLoggedIn && (
								<>
									{enabledSingleDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Consumer === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.supply_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = tt?.ui_config?.prepare
												? tt?.ui_config?.prepare
												: capitalize(
													`Browse ${(tt?.root_type as any)?.match_type?.supply_type?.name
														}s`.toLowerCase()
												);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}

									{enabledSingleDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Consumer === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.provider_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = capitalize(
												`Browse ${((tt?.root_type as any)?.match_type as MatchType)?.provider_type
													?.name ?? 'provider'
													}s`.toLowerCase()
											);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_PROVIDERS.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}

									{enabledMultiDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Consumer === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.supply_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = tt?.ui_config?.prepare
												? tt?.ui_config?.prepare
												: capitalize(
													`Browse ${(tt?.root_type as any)?.match_type?.supply_type?.name
														}s`.toLowerCase()
												);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}
									{enabledMultiDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Consumer === tt.my_role)
										?.filter(
											(tt: TransactionType) =>
												(tt?.root_type as any).match_type.provider_discovery_enabled === true
										)
										.map((tt) => {
											const navLabel = capitalize(
												`Browse ${((tt?.root_type as any)?.match_type as MatchType)?.provider_type
													?.name ?? 'provider'
													}s`.toLowerCase()
											);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																`${ROUTES.DISCOVERED_TRANSACTION_PROVIDERS.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)}`
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}

									{enabledReverseAuctionTransactions
										?.filter((tt) => MatchTypeRoleKind.Consumer === tt.my_role)
										.map((tt) => {
											const navLabel = tt?.ui_config?.prepare
												? tt?.ui_config?.prepare
												: capitalize(
													`Request ${(tt?.root_type as any)?.match_type?.supply_type?.name
														}s`.toLowerCase()
												);

											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																ROUTES.CREATE_REQUEST_REVERSE_TRANSACTION.replace(":transactionTypeTechName", tt.tech_name)
															);
														}}
													>
														<ListItemText
															primary={navLabel}
															primaryTypographyProps={{ sx: sxNavigationItemBold }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}
								</>
							)}

							{isProvider && (
								<ListItem disablePadding>
									<ListItemButton
										onClick={() => {
											handleGoToRoute(ROUTES.CREATE_SUPPLY);
										}}
									>
										<ListItemText
											primary="Add supply"
											primaryTypographyProps={{ sx: sxNavigationItemBold }}
										/>
									</ListItemButton>
								</ListItem>
							)}
							{isLoggedIn && dashboardPageEnabled && (
								<ListItem disablePadding>
									<ListItemButton
										onClick={() => {
											handleGoToRoute(ROUTES.DASHBOARD);
										}}
									>
										<ListItemText
											primary="Dashboard"
											primaryTypographyProps={{ sx: sxNavigationItem }}
										/>
									</ListItemButton>
								</ListItem>
							)}
						</List>
					</nav>

					{isLoggedIn && (
						<>
							<Divider sx={{ mt: 1, mb: 1 }} />
							<nav>
								<List>
									{/** SECTION: MANAGE TRANSACTIONS */}
									{enabledSingleDirectTransactions.map((tt) => {
										return (
											<ListItem disablePadding key={tt.tech_name}>
												<ListItemButton
													onClick={() => {
														handleGoToRoute(
															ROUTES.MY_SINGLE_DIRECT_TRANSACTIONS.replace(
																':transactionTypeTechName',
																tt.tech_name
															)
														);
													}}
												>
													<ListItemText
														primary={capitalize(`Manage ${tt?.name}`.toLowerCase())}
														primaryTypographyProps={{ sx: sxNavigationItem }}
													/>
												</ListItemButton>
											</ListItem>
										);
									})}

									{enabledMultiDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Consumer === tt.my_role)
										.map((tt) => {
											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																ROUTES.MY_MULTI_DIRECT_TRANSACTIONS.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)
															);
														}}
													>
														<ListItemText
															primary={capitalize(`Manage ${tt?.name}`.toLowerCase())}
															primaryTypographyProps={{ sx: sxNavigationItem }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}
									{enabledMultiDirectTransactions
										?.filter((tt) => MatchTypeRoleKind.Provider === tt.my_role)

										.map((tt) => {
											return (
												<ListItem disablePadding key={tt.tech_name}>
													<ListItemButton
														onClick={() => {
															handleGoToRoute(
																ROUTES.MY_MULTI_DIRECT_MATCHES.replace(
																	':transactionTypeTechName',
																	tt.tech_name
																)
															);
														}}
													>
														<ListItemText
															primary={capitalize(
																`Manage ${tt?.name} orders`.toLowerCase()
															)}
															primaryTypographyProps={{ sx: sxNavigationItem }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}

									{enabledReverseAuctionTransactions.length > 0 && (
										<>
											<ListItem disablePadding>
												<ListItemButton
													onClick={() => {
														handleGoToRoute(ROUTES.MY_TRANSACTIONS);
													}}
												>
													<ListItemText
														primary="Manage transactions"
														primaryTypographyProps={{ sx: sxNavigationItem }}
													/>
												</ListItemButton>
											</ListItem>
										</>
									)}

									{/** SECTION: CONNECTION */}
									{connectionTypes?.length > 0 && (
										<>
											{connectionTypes
												?.filter((ct: ConnectionType) => ct.is_active)
												.map((ct: ConnectionType) => {
													return (
														<ListItem disablePadding key={ct.tech_name}>
															<ListItemButton
																onClick={() => {
																	handleGoToRoute(
																		ROUTES.MY_CONNECTIONS.replace(
																			':connectionTechName',
																			ct.tech_name
																		)
																	);
																}}
															>
																<ListItemText
																	primary={capitalize(
																		`Manage ${ct?.name}`.toLowerCase()
																	)}
																	primaryTypographyProps={{ sx: sxNavigationItem }}
																/>
															</ListItemButton>
														</ListItem>
													);
												})}
										</>
									)}

									{/** SECTION: SUPPLY */}
									{isProvider && (
										<>
											<ListItem disablePadding>
												<ListItemButton
													onClick={() => {
														handleGoToRoute(ROUTES.MY_SUPPLIES);
													}}
												>
													<ListItemText
														primary="Manage supplies"
														primaryTypographyProps={{ sx: sxNavigationItem }}
													/>
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton
													onClick={() => {
														handleGoToRoute(ROUTES.MY_COLLECTIONS);
													}}
												>
													<ListItemText
														primary="Manage supply collections"
														primaryTypographyProps={{ sx: sxNavigationItem }}
													/>
												</ListItemButton>
											</ListItem>
										</>
									)}

									{/** OTHER NAVIGATION ITEMS */}
									<ListItem disablePadding>
										<ListItemButton
											onClick={() => {
												handleGoToRoute(ROUTES.PAYMENTS_AND_TRANSFERS);
											}}
										>
											<ListItemText
												primary="Manage payments"
												primaryTypographyProps={{ sx: sxNavigationItem }}
											/>
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton
											onClick={() => {
												handleGoToRoute(ROUTES.MY_SHARED_OBJECTS);
											}}
										>
											<ListItemText
												primary="My shared objects"
												primaryTypographyProps={{ sx: sxNavigationItem }}
											/>
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton
											onClick={() => {
												handleGoToRoute(ROUTES.MY_REFERRALS);
											}}
										>
											<ListItemText
												primary="Referrals"
												primaryTypographyProps={{ sx: sxNavigationItem }}
											/>
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton
											onClick={() => {
												handleGoToRoute(ROUTES.MY_ACCOUNT);
											}}
										>
											<ListItemText
												primary="Account"
												primaryTypographyProps={{ sx: sxNavigationItem }}
											/>
										</ListItemButton>
									</ListItem>
								</List>
							</nav>
						</>
					)}

					{isLoggedIn && (
						<>
							<Divider sx={{ mt: 1, mb: 1 }} />
							<nav>
								<List>
									<ListItem disablePadding>
										<ListItemButton
											onClick={() => {
												handleSignOut();
												setOpenNavigationDrawer(false);
											}}
										>
											<ListItemText
												primary="Log out"
												primaryTypographyProps={{ sx: sxNavigationItem }}
											/>
										</ListItemButton>
									</ListItem>
								</List>
							</nav>
						</>
					)}
				</Box>
			</Drawer>
		</>
	);
};

export default Header;
