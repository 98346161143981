import { uniqBy } from 'lodash';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink } from 'react-router-dom';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import ROUTES from '@rdv-fo/common/routes';
import {
	Field,
	FieldType,
	InputKind,
	ObjectField,
	Supply,
	SupplyStatusKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import { RdvCellValue, buildColumns } from '@rdv-fo/app/lib/tableHelpers';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import SupplyStatusChip from '@rdv-fo/components/supply/SupplyStatusChip';
import { findFieldByTechName } from '@rdv-fo/app/lib/fieldsHelper';
import SupplyIsActiveIndicator from './SupplyIsActivateIndicator';


export const buildSuppliesTableRows = (supplies: Supply[]): any[] => {
	const rows = supplies.map((supply) => {
		const fieldsValues = supply.fields?.reduce((acc, field: Field) => {
			const inputType = field.field_type.input_type;

			if (inputType === InputKind.Object) {
				const object_field_type = { ...field } as ObjectField;

				let res = undefined;
				if (object_field_type.value === null) {
					res = object_field_type.object_type?.fields?.reduce((innerAcc, objectFieldType: FieldType) => {
						return {
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectFieldType.tech_name}`]: {
									value: null,
									input_type: objectFieldType.input_type,
								},
							},
						};
					}, {});
				} else {
					res = object_field_type.value?.fields?.reduce((innerAcc, objectField: Field) => {
						return {
							...innerAcc,
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectField.field_type.tech_name}`]: {
									value: objectField.value,
									input_type: objectField.field_type.input_type,
								},
							},
						};
					}, {});
				}

				return { ...acc, ...res };
			}

			return {
				...acc,
				[field.field_type.tech_name]: {
					value: field.value,
				},
			};
		}, {});

		const supplyName = findFieldByTechName(supply.fields, 'name')?.value;
		return {
			_id: { value: supply.id, input_type: InputKind.Text },
			name: { value: supplyName, input_type: InputKind.Text },

			type: { value: supply.type?.name, input_type: InputKind.Text },
			is_active: { value: supply.is_active, input_type: InputKind.Boolean },
			variants_count: {
				value: [0, null, undefined].includes(supply.variants?.length) ? '-' : supply.variants?.length,
				input_type: InputKind.Text,
			},
			onboarding: {
				value: {
					status: supply.status,
					onboarding_state: supply.onboarding_progress?.status ?? '',
				},
				input_type: InputKind.Text,
			},
			created_at: { value: supply.created_at, input_type: InputKind.Datetime },
			onboarded_at: { value: supply.onboarded_at, input_type: InputKind.Datetime },
			rejected_at: { value: supply.rejected_at, input_type: InputKind.Datetime },
			...fieldsValues,
		};
	});

	return rows;
};

export const buildSuppliesTableColumns = (fields: Field[]): MRT_ColumnDef<any>[] => {
	const defaultSupplyColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'name',
			header: 'Item',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={InputKind.Text} value={data.value} />;
			},
		},
		{
			accessorKey: 'type',
			header: 'Type',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'is_active',
			header: 'Status',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <SupplyIsActiveIndicator isActive={Boolean(data.value)} />;
			},
		},
		{
			accessorKey: 'variants_count',
			header: 'Variants',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'onboarding',
			header: 'State',
			Cell: ({ cell }) => {
				const data = cell.getValue<any>() as any;

				return (
					<>
						<SupplyStatusChip
							status={data?.value?.status}
							onboardingStateLabel={data?.value?.onboarding_state}
							size="small"
						/>
					</>
				);
			},
		},
		{
			accessorKey: 'onboarded_at',
			header: 'Onboarded',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'rejected_at',
			header: 'Rejected',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'created_at',
			header: 'Created',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
	];

	const fieldTypes = fields?.map((field) => field.field_type) ?? [];
	const objectTypes = fields?.map((field: any) => field.object_type).filter((field) => field) ?? [];

	const fieldColumns = buildColumns(fieldTypes, objectTypes);

	const columns = uniqBy(defaultSupplyColumns.concat(fieldColumns), function (column: MRT_ColumnDef<any>) {
		return column.accessorKey;
	});

	return defaultSupplyColumns;
};

const NoDataView = () => {
	return (
		<Stack mt={2} direction="column" justifyContent="center" alignItems="center" spacing={2} >
			<Typography variant="subtitle1">No supplies yet</Typography>
			<Typography variant="body2">Your supplies will appear hear</Typography>
		</Stack>
	);
};

interface SuppliesTableProps {
	supplies: Supply[];
	onAdd: () => any;
}

const SuppliesTable = ({ supplies = [], onAdd }: SuppliesTableProps) => {
	const transactionColumnDefinitions = buildSuppliesTableColumns(supplies?.[0]?.fields ?? []);

	const transactionRow = buildSuppliesTableRows(supplies);

	return (
		<>
			<MaterialReactTable
				initialState={{
					showColumnFilters: true,
					pagination: { pageSize: 50, pageIndex: 0 },
					columnVisibility: {
						_id: false,
						name: true,
						status: true,
						state: true,
						is_active: true,
						type: true,
						variants_count: true,
						created_at: false,
						onboarded_at: false,
						rejected_at: false,
					},
				}}

				columns={transactionColumnDefinitions}
				enablePinning={false}
				data={transactionRow}
				enableTableHead={true}
				enableTopToolbar={false}
				enableSorting={false}
				enablePagination={false}
				enableDensityToggle={false}
				enableGlobalFilter={true}
				enableColumnActions={false}
				enableHiding={false}
				enableColumnFilters={false}
				enableColumnFilterModes={false}
				rowCount={supplies?.length}
				muiTableBodyProps={{
					children: supplies?.length < 1 ? <NoDataView /> : null,
				}}
				muiTableHeadCellFilterTextFieldProps={{
					size: "small",
					margin: "dense",
					variant: 'outlined',
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={{
					sx: {
						'&:hover': {
							backgroundColor: RDV_NEUTRAL_20,
						},
					},
				}}
				positionActionsColumn="last"
				enableRowActions={true}
				renderRowActions={({ row }) => {
					const supplyId = row.original?._id?.value;
					const routeSupplyOnboarding = routeBuilder(ROUTES.SUPPLY_ONBOARDING, [[':supplyId', supplyId]]);
					const routeUpdateMySupply = routeBuilder(ROUTES.UPDATE_MY_SUPPLY, [[':supplyId', supplyId]]);

					let targetRoute = routeSupplyOnboarding;

					if (
						[SupplyStatusKind.Onboarded, SupplyStatusKind.Rejected].includes(row.original.onboarding.status)
					)
						targetRoute = routeUpdateMySupply;

					return (
						<IconButton component={RouterLink} to={targetRoute} aria-label="view details" size="small">
							<ArrowForwardIcon fontSize="small" sx={{ color: 'black' }} />
						</IconButton>
					);
				}}
				enableFullScreenToggle={false}
				enableRowOrdering={false}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				renderBottomToolbar={
					supplies?.length > 0 &&
					(() => {
						let label = `${supplies?.length} supplies`;
						if (supplies?.length === 1) label = `${supplies?.length} supply`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default SuppliesTable;
