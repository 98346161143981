import { MutationReferParticipantUserArgs, Referral } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import * as referralQueries from './referralQueries';
import * as referralMutations from './referralMutations';

class ReferralsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async myReferrals(): Promise<{ referrals: Referral[]; errors: any }> {
		const payload = referralQueries.myReferrals();

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const referrals = data?.myReferrals?.map((referral: Referral) => {
			//const fields = unifyFieldValue(referral?.referee?.fields);
			//if (referral) referral.referee.fields = fields;

			return referral;
		});

		return { referrals: referrals, errors };
	}
	async referParticipantUser({
		participant_tech_name,
		first_name,
		last_name,
		email,
		fields,
	}: MutationReferParticipantUserArgs): Promise<{ referral: Referral | null; errors: any }> {
		const payload = referralMutations.referParticipantUser({
			participant_tech_name,
			first_name,
			last_name,
			email,
			fields,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { referral: data?.referParticipantUser, errors };
	}
}

export default ReferralsService;
