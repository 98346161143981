import gql from 'graphql-tag';
import { print } from 'graphql';
import { FIELDS_WITH_FIELD_TYPE_FRAGMENT } from '../fragments';

const GUEST_TRANSACTION_TYPES = gql`
	query GUEST_TRANSACTION_TYPES {
		myTransactionTypes {
			id
			type
			name
			tech_name
			forced_termination_enabled
			cascading_termination
			my_role
			is_default
			is_active
			ui_config
			match_configuration {
				id
				name
				tech_name
				matching_unit_type
				availability_management_type
				match_parameter_types {
					name
					tech_name
					input_type
					input_options
					is_required
					initial_value
					ui_config {
						label
						helper_text
					}
				}
			}
			root_type {
				... on MatchingToolType {
					type
					match_type {
						id
						matching_unit_type
						provider_discovery_enabled
						provider_type {
							id
							name
						}
						supply_discovery_enabled
						supply_type {
							id
							name
							tech_name
							match_configurations {
								pricing_enabled
							}
							fields {
								id
								name
								initial_value
								tech_name
								category
								input_type
								input_options
								is_required
								conditions {
									type
									payload
								}
							}
						}
					}
				}
				... on MatchType {
					id
					matching_unit_type
					provider_discovery_enabled
					supply_discovery_enabled
					supply_type {
						id
						name
						match_configurations {
							pricing_enabled
						}
						fields {
							id
							tech_name
							initial_value
							name
							category
							input_type
							input_options
							is_required
							conditions {
								type
								payload
							}
						}
					}
					provider_type {
						id
						name
					}
				}
			}
		}
	}
`;

const guestTransactionTypes = () => {
	return JSON.stringify({
		query: print(GUEST_TRANSACTION_TYPES),
	});
};

const MY_TRANSACTION_TYPES = gql`
	query MY_TRANSACTION_TYPES {
		myTransactionTypes {
			id
			type
			tech_name
			name
			my_role
			is_default
			is_active
			forced_termination_enabled
			cascading_termination
			match_configuration {
				id
				name
				tech_name
				matching_unit_type
				availability_management_type
				match_parameter_types {
					name
					tech_name
					input_type
					input_options
					is_required
					initial_value
					ui_config {
						label
						helper_text
					}
				}
			}
			root_type {
				... on MatchingToolType {
					id
					type
					fields {
						id
						tech_name
						initial_value
						name
						object_fields {
							name
							tech_name
							id
							input_options
							ui_config {
								label
								helper_text
								options {
									label
									value
								}
								input_control
							}
							category
							input_type
							is_required
							conditions {
								type
								payload
							}
						}
						category
						input_type
						input_options
						is_required
						ui_config {
							label
							helper_text
							options {
								label
								value
							}
							input_control
						}
						conditions {
							type
							payload
						}
					}
					match_type {
						id
						matching_unit_type
						supply_discovery_enabled
						provider_discovery_enabled
						provider_type {
							id
							name
							tech_name
							fields {
								id
								tech_name
								initial_value
								name
								object_fields {
									name
									tech_name
									id
									input_options
									ui_config {
										label
										helper_text
										options {
											label
											value
										}
										input_control
									}
									category
									input_type
									is_required
									conditions {
										type
										payload
									}
								}
								category
								input_type
								input_options
								ui_config {
									label
									helper_text
									options {
										label
										value
									}
									input_control
								}
								is_required
								conditions {
									type
									payload
								}
							}
						}
						consumer_type {
							id
							name
							tech_name
						}
						supply_type {
							id
							name
							match_configurations {
								pricing_enabled
							}
							tech_name
							fields {
								id
								tech_name
								name
								initial_value
								category
								matching_weight
								input_type
								ui_config {
									label
									helper_text
									options {
										label
										value
									}
									input_control
								}
								input_options
								is_required
								conditions {
									type
									payload
								}
							}
						}
					}
				}
				... on MatchType {
					id
					matching_unit_type
					supply_discovery_enabled
					provider_discovery_enabled
					provider_type {
						id
						name
						tech_name
						fields {
							id
							tech_name
							initial_value
							name
							object_fields {
								name
								tech_name
								id
								input_options
								ui_config {
									label
									helper_text
									options {
										label
										value
									}
									input_control
								}
								category
								input_type
								is_required
								conditions {
									type
									payload
								}
							}
							category
							input_type
							input_options
							ui_config {
								label
								helper_text
								options {
									label
									value
								}
								input_control
							}
							is_required
							conditions {
								type
								payload
							}
						}
					}
					consumer_type {
						id
						name
						tech_name
					}
					supply_type {
						id
						name
						tech_name
						match_configurations {
							pricing_enabled
						}
						fields {
							id
							tech_name
							initial_value
							name
							object_fields {
								name
								tech_name
								id
								input_options
								ui_config {
									label
									helper_text
									options {
										label
										value
									}
									input_control
								}
								category
								input_type
								is_required
								conditions {
									type
									payload
								}
							}
							category
							input_type
							input_options
							ui_config {
								label
								helper_text
								options {
									label
									value
								}
								input_control
							}
							is_required
							conditions {
								type
								payload
							}
						}
					}
				}
			}
			ui_config
		}
	}
`;

const myTransactionTypes = () => {
	return JSON.stringify({
		query: print(MY_TRANSACTION_TYPES),
	});
};

export { guestTransactionTypes, myTransactionTypes };
