import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	MutationAcceptConnectionArgs,
	MutationCreateConnectionArgs,
	MutationDeclineConnectionArgs,
	MutationUpdateConnectionArgs,
	MutationUpdateParticipantArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

const UPDATE_CONNECTION = gql`
	mutation UPDATE_CONNECTION($id: ID!, $fields: [FieldInput!]) {
		updateConnection(id: $id, fields: $fields) {
			id
		}
	}
`;

const updateConnection = ({ id, fields }: MutationUpdateConnectionArgs) => {
	let variables = { id, fields: undefined } as MutationUpdateParticipantArgs;

	if (fields !== null && fields !== undefined && fields.length > 0) {
		variables.fields = fields;
	}

	return JSON.stringify({
		query: print(UPDATE_CONNECTION),
		variables,
	});
};

const CREATE_CONNECTION = gql`
	mutation CREATE_CONNECTION($connection_tech_name: String!, $id_participant: ID!, $fields: [FieldInput!]) {
		createConnection(connection_tech_name: $connection_tech_name,id_participant: $id_participant, fields: $fields){
			id
			status
			established_at
			created_at
			accepted_at
			declined_at
			participant_accepted_at
			participant_declined_at
			backoffice_approved_at
			backoffice_rejected_at
			fields {
				... on SimpleField {
					my_access
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								my_access
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									input_options
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									input_options
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
						}
					}
				}
			}
			participant {
				id
				fields {
					... on SimpleField {
						simpleValue: value
						my_access
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
					}
					... on ObjectField {
						my_access
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										id
										name
										tech_name
										input_type
										input_options
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
									}
								}
							}
						}
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
								input_options
							}
						}
					}

					... on ObjectSetField {
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									my_access
									field_type {
										id
										name
										tech_name
										input_type
										input_options
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
									}
								}
							}
						}
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
							}
						}
					}
				}
			}
		}
	}
`;

const createConnection = ({ connection_tech_name, id_participant, fields }: MutationCreateConnectionArgs) => {
	return JSON.stringify({
		query: print(CREATE_CONNECTION),
		variables: {
			connection_tech_name,
			id_participant,
			fields,
		},
	});
};

const ACCEPT_CONNECTION = gql`
	mutation ACCEPT_CONNECTION($id: ID!) {
		acceptConnection(id: $id)
	}
`;
const acceptConnection = ({ id }: MutationAcceptConnectionArgs) => {
	return JSON.stringify({
		query: print(ACCEPT_CONNECTION),
		variables: {
			id,
		},
	});
};

const DECLINE_CONNECTION = gql`
	mutation DECLINE_CONNECTION($id: ID!) {
		declineConnection(id: $id)
	}
`;

const declineConnection = ({ id }: MutationDeclineConnectionArgs) => {
	return JSON.stringify({
		query: print(DECLINE_CONNECTION),
		variables: {
			id,
		},
	});
};

export { updateConnection, createConnection, acceptConnection, declineConnection };
