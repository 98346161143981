import React from 'react';
import decodeToJSONObject from '@rdv-fo/app/lib/decodeToJSONObject';
import ConfirmParticipantAccountForm from '@rdv-fo/components/security/ConfirmParticipantAccountForm';
import MissingEmailVerificationParamsView from '@rdv-fo/components/security/MissingEmailVerificationParamsView';
import AuthPageStyle from '@rdv-fo/components/layout/AuthPageStyle';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import { selectVerifyParticipantAccountPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const VerifyParticipantAccountPage = () => {
	const { data } = usePrimitiveQueryParams();
	const { style, image_position, image_url, title, form, description } = useAppSelector(selectVerifyParticipantAccountPageConfig) ?? {};

	const { token, email } = decodeToJSONObject(data);

	const showInvalidUrl = [data, token, email].includes(undefined);

	return (
		<>
			{showInvalidUrl && <MissingEmailVerificationParamsView />}
			<AuthPageStyle
				style={style}
				imagePosition={image_position}
				image={image_url}
			>
				{!showInvalidUrl && <ConfirmParticipantAccountForm token={token} email={email} title={title} description={description} form={form} />}
			</AuthPageStyle>
		</>
	);
};

export default VerifyParticipantAccountPage;
