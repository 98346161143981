import { TextField } from 'mui-rff';
import LabelFilterInput from './LabelFilterInput';
import { FilterInputBaseProps } from './types';

// FIXME: set proper type
const parse = (value: any) => {
	if (['', undefined, null].includes(value)) return null;
	return value;
};

// FIXME: set proper type
const format = (value: any) => {
	if (['', undefined, null].includes(value)) return '';
	return value;
};

interface FilterInputSmartTextProps extends FilterInputBaseProps {}

const FilterInputSmartText = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
}: FilterInputSmartTextProps) => {
	return (
		<>
			{noLabel === false && <LabelFilterInput text={label ?? ``} htmlFor={techName} required={required} />}
			<TextField
				name={techName}
				type="text"
				variant="outlined"
				size="small"
				margin="dense"
				disabled={disabled}
				fullWidth={true}
				fieldProps={{
					parse,
					format,
				}}
			/>
		</>
	);
};

export default FilterInputSmartText;
