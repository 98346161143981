import { Box } from '@mui/material';
import Header from '@rdv-fo/components/landing-page/Header';
import Footer from '@rdv-fo/components/landing-page/Footer';

const GeneralLayout = ({ children }) => {
	return (
		<>
			<Box
				component="div"
				sx={{
					minHeight: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					backgroundColor: 'white',
				}}
			>
				<Header />
				<Box component="main" sx={{ flexGrow: 1 }}>
					{children}
				</Box>
				<Box
					component="footer"
					sx={{
						flexShrink: 0,
						minHeight: '5vw',
						pt: 8,
						pb: 8,
					}}
				>
					<Footer />
				</Box>
			</Box>
		</>
	);
};

export default GeneralLayout;
