import { Box, Divider, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { findFieldByCategory, } from '@rdv-fo/app/lib/fieldsHelper';
import OrderDetailsSummary from '@rdv-fo/components/transaction/multi-direct/OrderDetailsSummary';
import ShoppingCartItemsForProvider from '@rdv-fo/components/transaction/multi-direct/ShoppingCartItemsForProvider';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import FlowManualActionsPanel from '@rdv-fo/components/transaction/FlowManualActionsPanel';
import { CurrencyKind, FieldCategoryKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import {
	loadMyShoppingCartOrder,
	selectLoading,
	selectMyOrder,
	triggerMultiDirectOrderManualTransition,
} from '@rdv-fo/store/slices/shoppingCartSlice';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_50 } from '@rdv-fo/styles/theme';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

const MultiDirectOrderDetailsPage = () => {
	const dispatch = useAppDispatch();
	const { transactionId, transactionTypeTechName } = useParams<{ transactionTypeTechName: string, transactionId: string }>();
	const order = useAppSelector(selectMyOrder);
	const loading = useAppSelector(selectLoading);

	useEffect(() => {
		if (transactionId) dispatch(loadMyShoppingCartOrder({ id_cart_order: transactionId }));
	}, [transactionId, dispatch]);

	const cumulativeSupplyPrice = {} as any;
	order?.items?.forEach((item) => {
		if (Object.keys(cumulativeSupplyPrice).length < 1) {
			Object.assign(cumulativeSupplyPrice, item?.price);
			if (item?.price?.unit_price && item?.price?.qty)
				Object.assign(cumulativeSupplyPrice, { total_price: item.price.unit_price * item.price.qty });
		} else {
			if (item?.price?.unit_price && item?.price?.qty)
				cumulativeSupplyPrice.total_price = cumulativeSupplyPrice.total_price +=
					item.price.unit_price * item.price.qty;
			cumulativeSupplyPrice.total_before_tax = cumulativeSupplyPrice.total_before_tax +=
				item?.price?.total_before_tax;
			cumulativeSupplyPrice.total_taxes = cumulativeSupplyPrice.total_taxes += item?.price?.total_taxes;
			cumulativeSupplyPrice.total_fees = cumulativeSupplyPrice.total_fees += item?.price?.total_fees;
			cumulativeSupplyPrice.total_consumer_fees = cumulativeSupplyPrice.total_consumer_fees +=
				item?.price?.total_consumer_fees;
			cumulativeSupplyPrice.discount_items = [
				...cumulativeSupplyPrice.discount_items,
				...(item?.price?.discount_items ? item?.price?.discount_items : []),
			];
			cumulativeSupplyPrice.addon_items = [
				...cumulativeSupplyPrice.addon_items,
				...(item?.price?.addon_items ? item?.price?.addon_items : []),
			];
			cumulativeSupplyPrice.tax_items = [
				...cumulativeSupplyPrice.tax_items,
				...(item?.price?.tax_items ? item?.price?.tax_items : []),
			];
			cumulativeSupplyPrice.platform_fee_items = [
				...cumulativeSupplyPrice.platform_fee_items,
				...(item?.price?.platform_fee_items ? item?.price?.platform_fee_items : []),
			];
		}
	});

	const handleTriggerManualTransition = (objectId: string, transitionTechName: string) => {
		dispatch(
			triggerMultiDirectOrderManualTransition({
				id_transaction: transactionId,
				transition_tech_name: transitionTechName,
			})
		);
	};

	const nextSteps = order?.state?.next_steps ?? [];
	const message = order?.state?.message ?? '';
	const status = order?.state?.status ?? '';
	const shoppingCartItemsExist = order?.items?.length && order?.items?.length > 0;
	const orderDetails = order?.order;
	const consumerFirstName =
		order?.items?.[0] && findFieldByCategory(order?.items?.[0]?.consumer_fields, FieldCategoryKind.FirstName)?.value;
	const consumerLastName =
		order?.items?.[0] && findFieldByCategory(order?.items?.[0]?.consumer_fields, FieldCategoryKind.LastName)?.value;
	const consumerName =
		order?.items?.[0] && findFieldByCategory(order?.items?.[0]?.consumer_fields, FieldCategoryKind.Name)?.value;

	const isMatchConfigurationPricingEnabled = order?.type?.match_configuration?.pricing_enabled ?? false;

	const currency = order?.order?.currency ?? CurrencyKind.Eur;

	return (
		<>
			<Stack direction="row" width="100%" justifyContent="space-between">
				<Grid item>
					<Typography component="h1" variant="h1">
						Order details
					</Typography>

					<Typography variant="body2" color="textSecondary">
						{loading ? <Skeleton width={100} /> : `${order?.id} `}
					</Typography>
				</Grid>
				<Box>
					{(nextSteps?.length > 0 || message) && (
						<FlowManualActionsPanel
							objectId={transactionId ?? ''}
							nextSteps={nextSteps}
							message={''}
							onTriggerManualTransition={handleTriggerManualTransition}
							loading={loading}
							buttonVariant="contained"
						/>
					)}
				</Box>
			</Stack>
			{shoppingCartItemsExist ? (
				<Grid container direction="row" sx={{ mt: 5 }} justifyContent="space-between">
					<Grid item xs={12} md={7}>
						{loading ? (
							<Stack direction="column" spacing={5}>
								<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
									<Stack direction="row" spacing={2}>
										<Stack direction="column">
											<Skeleton width={150} height={150} />
										</Stack>
										<Stack direction="column" spacing={1}>
											<Skeleton />
											<Box>
												<Skeleton width={100} />
												<Skeleton width={100} />
												<Skeleton width={100} />
												<Skeleton width={100} />
											</Box>
										</Stack>
									</Stack>
								</Stack>
							</Stack>

						) : (
							<ShoppingCartItemsForProvider
								showPrice={isMatchConfigurationPricingEnabled}
								items={order.items}
							/>
						)}
					</Grid>
					<Grid item xs={12} md={4}>
						<Stack direction="column" spacing={1}>
							<Typography variant="h4">Summary</Typography>
							<Stack direction="column" spacing={-1}>
								<>
									<Typography variant="overline" component="p" sx={{ color: RDV_NEUTRAL_50 }}>
										{status}
									</Typography>
									{message && (
										<Stack
											component={Paper}
											elevation={0}
											sx={{ backgroundColor: RDV_NEUTRAL_10 }}
											padding={4}
											justifyContent="center"
											alignItems="center"
											spacing={2}
										>
											<Typography variant="h4">{message}</Typography>
										</Stack>
									)}
								</>
							</Stack>
							<br />
							{orderDetails ? (
								<OrderDetailsSummary
									order={orderDetails}
									consumerName={consumerName}
									consumerUserFirstLastName={`${consumerFirstName} ${consumerLastName}`}
								/>
							) : (
								<>
									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">Total price</Typography>
										{loading ? (
											<Skeleton />
										) : (
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount: cumulativeSupplyPrice?.total_price,
													currency,
												})}
											</Typography>
										)}
									</Stack>

									{loading ? (
										<Skeleton />
									) : (
										cumulativeSupplyPrice?.addon_items &&
										cumulativeSupplyPrice?.addon_items?.length > 0 &&
										cumulativeSupplyPrice?.addon_items.map((add_on: any) => (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">{add_on?.description}</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: add_on?.value,
														currency,
													})}
												</Typography>
											</Stack>
										))
									)}
									{loading ? (
										<Skeleton />
									) : (
										cumulativeSupplyPrice?.discount_items &&
										cumulativeSupplyPrice?.discount_items?.length > 0 &&
										cumulativeSupplyPrice?.discount_items.map((discount: any) => (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">{discount?.description}</Typography>
												<Typography fontWeight="bold">
													-
													{formatPriceAmount({
														amount: discount?.value,
														currency,
													})}
												</Typography>
											</Stack>
										))
									)}
									{loading ? (
										<Skeleton />
									) : (
										cumulativeSupplyPrice?.platform_fee_items?.length > 0 &&
										cumulativeSupplyPrice?.platform_fee_items.map((platform_fee: any) => (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">{platform_fee?.description}</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: platform_fee?.value,
														currency,
													})}
												</Typography>
											</Stack>
										))
									)}
									<Divider flexItem />

									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">Total before taxes</Typography>
										{loading ? (
											<Skeleton />
										) : (
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount: cumulativeSupplyPrice?.total_before_tax,
													currency,
												})}
											</Typography>
										)}
									</Stack>

									{loading ? (
										<Skeleton />
									) : (
										cumulativeSupplyPrice?.tax_items?.length > 0 &&
										cumulativeSupplyPrice?.tax_items.map((tax: any) => (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">{tax?.description}</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: tax?.value,
														currency,
													})}
												</Typography>
											</Stack>
										))
									)}
									<Divider flexItem />
									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">Total</Typography>
										{loading ? (
											<Skeleton />
										) : (
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount:
														cumulativeSupplyPrice?.total_before_tax +
														cumulativeSupplyPrice?.total_taxes,
													currency,
												})}
											</Typography>
										)}
									</Stack>

									{loading ? (
										<Skeleton />
									) : cumulativeSupplyPrice?.total_consumer_fees ? (
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">Total consumer fees</Typography>
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount: cumulativeSupplyPrice?.total_consumer_fees,
													currency,
												})}
											</Typography>
										</Stack>
									) : (
										<></>
									)}
									<Divider flexItem />
									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">Adjusted total</Typography>
										{loading ? (
											<Skeleton />
										) : (
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount:
														cumulativeSupplyPrice?.total_before_tax +
														cumulativeSupplyPrice?.total_taxes +
														cumulativeSupplyPrice?.total_fees,
													currency,
												})}
											</Typography>
										)}
									</Stack>
								</>
							)}
						</Stack>
					</Grid>
				</Grid>
			) : (
				<Box sx={{ mt: 5 }}>
					{loading ? (
						<Skeleton height={300} />
					) : (
						<Stack
							component={Paper}
							sx={{ backgroundColor: RDV_NEUTRAL_10 }}
							direction="column"
							elevation={0}
							paddingY={10}
							width="100%"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Typography variant="h4">This order is empty</Typography>
						</Stack>
					)}
				</Box>
			)}
		</>
	);
};

export default MultiDirectOrderDetailsPage;
