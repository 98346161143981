import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import isFileSizeAcceptable from '@rdv-fo/app/lib/fileSizeValidator';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { RdvFileFieldValue } from '@rdv-fo/services/randevuApi/types/field/';
import { Field } from 'react-final-form';

import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';

interface DocumentInputOptions {
	required: boolean;
	maxSize?: number;
}
const validate = (value: File | RdvFileFieldValue | undefined, options: DocumentInputOptions) => {
	const { maxSize, required } = options;

	// no need to validate already updated  file
	if (value as RdvFileFieldValue) return undefined;

	// no need to validate that its mandatory
	if (!required && value === undefined) return undefined;

	// validate its mandatory
	if (required && value === undefined) return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	const js_file = value as File;

	if (maxSize && js_file && !isFileSizeAcceptable({ maxAllowedSize: maxSize, fileSize: js_file.size }))
		return VALIDATION_MESSAGE.MAX_FILE_SIZE_VIOLATION.replace('{max_size}', String(maxSize));

	return undefined;
};

const getAcceptedDocumentFormats = (allowedTypes: string[] | undefined) =>
	allowedTypes?.map((type) => '.' + type.toLowerCase()).join(', ');

interface FieldInputDocumentProps extends FieldInputBaseProps {
	allowedTypes: string[];
	maxSize?: number;
}

const FieldInputDocument = ({
	techName,
	label,
	labelProps,
	disabled = false,
	required = false,
	allowedTypes,
	maxSize,
}: FieldInputDocumentProps) => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>

			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>
			<div>
				<Field name={techName} validate={(value) => validate(value, { required, maxSize })}>
					{({ input: { value, onChange, ...input }, meta }) => {
						const { submitError, dirtySinceLastSubmit, error, pristine, submitFailed } = meta;
						let selectedDocumentUrl: any = undefined;
						if (value && value instanceof File) selectedDocumentUrl = URL.createObjectURL(value);
						if (value && !(value instanceof File)) selectedDocumentUrl = value.url;

						const acceptedDocumentFormats = getAcceptedDocumentFormats(allowedTypes);

						return (
							<>
								{((error && !pristine) || (error && submitFailed)) && (
									<FormHelperText error={true}>{error}</FormHelperText>
								)}
								{submitError && !dirtySinceLastSubmit && (
									<FormHelperText error={true}>{submitError}</FormHelperText>
								)}
								{!selectedDocumentUrl && (
									<>
										<br />
										<Typography variant="body2" color="textSecondary">
											Allowed formats: {acceptedDocumentFormats}
										</Typography>
										{maxSize && (
											<Typography color="textSecondary" variant="body2">
												{`Maximum allowed file size: ${maxSize} MB`}
											</Typography>
										)}
										<Button
											variant="outlined"
											color="primary"
											component="label"
											size="small"
											startIcon={<CloudUploadOutlinedIcon />}
											sx={{ marginTop: 1 }}
											disabled={disabled}
										>
											Choose document
											<input
												{...input}
												id={techName}
												name={techName}
												type="file"
												hidden
												accept={acceptedDocumentFormats}
												onChange={({ target }) => {
													// TODO: FIXME PREVENT CHOOSING FILES THAT ARE BIGGER THAN ALLOWED
													if (target.files !== null && target.files.length > 0)
														onChange(target.files[0]);
												}}
											/>
										</Button>
										<br />
										<br />
									</>
								)}

								{selectedDocumentUrl && (
									<>
										<div>
											<br />
											<Typography
												color="textSecondary"
												variant="body2"
												component="a"
												target="_blank"
												rel="noopener"
												href={selectedDocumentUrl}
												download={value?.name}
											>
												{value?.name || value?.file_name}
											</Typography>
											<br />
											<br />
										</div>
										<div>
											<Button
												variant="text"
												color="primary"
												startIcon={<ClearIcon />}
												onClick={(e) => {
													return onChange(null);
												}}
												size="small"
												disabled={disabled}
											>
												Remove document
											</Button>
										</div>
										<br />
									</>
								)}
							</>
						);
					}}
				</Field>
			</div>
		</Stack>
	);
};

export default FieldInputDocument;
