import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import ROUTES from '@rdv-fo/common/routes';
import {
	selectDefaultTransactionType,
	setSelectedTransactionType,
	selectTransactionTypes,
} from '@rdv-fo/store/slices/transactionSlice';

const ReverseAuctionRequestDashboardBar = ({ transactionTypeTechName }) => {
	const dispatch = useAppDispatch();
	const [raised, setRaised] = useState(false);
	const toggleRaised = () => setRaised(!raised);
	const defaultTransactionType = useAppSelector(selectDefaultTransactionType);
	const myTransactionTypes = useAppSelector(selectTransactionTypes);

	const contextTransactionType =
		myTransactionTypes?.find((tt) => tt.tech_name === transactionTypeTechName) ?? defaultTransactionType;

	const createReverseAuctionTransactionRoute = ROUTES.CREATE_REQUEST_REVERSE_TRANSACTION.replace(
		':transactionTypeTechName',
		contextTransactionType?.tech_name
	);

	return (
		<>
			<Card sx={{ width: '100%' }} raised={raised} onMouseOver={toggleRaised} onMouseOut={toggleRaised}>
				<CardContent
					sx={{
						paddingTop: 5,
						paddingLeft: 3,
						paddingRight: 3,
						'&:last-child': {
							paddingBottom: 5,
						},
					}}
				>
					<Button
						size="large"
						color="primary"
						variant="contained"
						sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}
						component={RouterLink}
						to={createReverseAuctionTransactionRoute}
						onClick={() => dispatch(setSelectedTransactionType(defaultTransactionType))}
					>
						{contextTransactionType?.ui_config?.prepare ?? 'Create request'}
					</Button>
				</CardContent>
			</Card>
		</>
	);
};

export default ReverseAuctionRequestDashboardBar;
