import randevu from '@rdv-fo/services/randevuApi';
import { isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import {
	FieldInput,
	MutationCreateSharedObjectArgs,
	MutationUpdateSharedObjectArgs,
	Object,
	ObjectType,
	SharedObjectFilter,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { sendErrorToasty, sendSuccessToasty, sendUnexpectedErrorToasty } from './uiSlice';
import type { AppDispatch, RootState } from '@rdv-fo/store/configureStore';
import THUNKS from '../thunkMap';

interface SharedObjectsSliceState {
	items: Object[];
	item: Object | null;
	types: ObjectType[];
	loading: boolean;
	errors: any | null; // FIXME: set proper type
}

const initialState: SharedObjectsSliceState = {
	items: [],
	item: null,
	types: [],
	loading: false,
	errors: null,
};

const slice = createSlice({
	name: 'sharedObjects',
	initialState,
	reducers: {
		loadSharedObjectTypesRequested: (sharedObjects: SharedObjectsSliceState) => {
			sharedObjects.types = [];
			sharedObjects.loading = true;
			sharedObjects.errors = null;
		},
		loadSharedObjectTypesFailed: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<any>) => {
			sharedObjects.loading = false;
			sharedObjects.errors = action.payload;
		},
		sharedObjectTypesLoaded: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<ObjectType[]>) => {
			sharedObjects.types = action.payload;
			sharedObjects.errors = null;
			sharedObjects.loading = false;
		},
		loadSharedObjectsRequested: (sharedObjects: SharedObjectsSliceState) => {
			sharedObjects.items = [];
			sharedObjects.loading = true;
			sharedObjects.errors = null;
		},
		loadSharedObjectsFailed: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<any>) => {
			sharedObjects.loading = false;
			sharedObjects.errors = action.payload;
		},
		sharedObjectsLoaded: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<Object[]>) => {
			sharedObjects.items = action.payload;
			sharedObjects.errors = null;
			sharedObjects.loading = false;
		},
		createSharedObjectRequested: (sharedObjects: SharedObjectsSliceState) => {
			sharedObjects.item = null;
			sharedObjects.loading = true;
			sharedObjects.errors = null;
		},
		createSharedObjectFailed: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<any>) => {
			sharedObjects.loading = false;
			sharedObjects.errors = action.payload;
		},
		sharedObjectCreated: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<Object[]>) => {
			sharedObjects.items = action.payload;
			sharedObjects.errors = null;
			sharedObjects.loading = false;
		},
		updateSharedObjectRequested: (sharedObjects: SharedObjectsSliceState) => {
			sharedObjects.item = null;
			sharedObjects.loading = true;
			sharedObjects.errors = null;
		},
		updateSharedObjectFailed: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<any>) => {
			sharedObjects.loading = false;
			sharedObjects.errors = action.payload;
		},
		sharedObjectUpdated: (sharedObjects: SharedObjectsSliceState, action: PayloadAction<Object[]>) => {
			sharedObjects.items = action.payload;
			sharedObjects.errors = null;
			sharedObjects.loading = false;
		},
	},
});

export const {
	loadSharedObjectTypesRequested,
	loadSharedObjectTypesFailed,
	sharedObjectTypesLoaded,
	loadSharedObjectsRequested,
	loadSharedObjectsFailed,
	sharedObjectsLoaded,
	createSharedObjectRequested,
	createSharedObjectFailed,
	sharedObjectCreated,
	updateSharedObjectRequested,
	updateSharedObjectFailed,
	sharedObjectUpdated,
} = slice.actions;

export default slice.reducer;

/////////////////////
// ACTION CREATORS //
/////////////////////

export const loadMySharedObjectTypes = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadSharedObjectTypesRequested());

	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

	const { object_types, errors } = await randevuService.objects.mySharedObjectTypes();

	if (isSessionExpired(errors))
		return handleSessionExpired({
			dispatch, state: getState(), failedAction: loadSharedObjectTypesFailed, reauthenticationCallback: {
				callbackThunkKey: THUNKS.LOAD_MY_SHARED_OBJECT_TYPES.key,
				payload: {},
			},
		});

	if (errors || object_types === null) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadSharedObjectTypesFailed(errors));
	}

	return dispatch(sharedObjectTypesLoaded(object_types));
};

interface loadMySharedObjectsRequest {
	where?: SharedObjectFilter;
}

export const loadMySharedObjects =
	({ where = {} }: loadMySharedObjectsRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(loadSharedObjectsRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { objects, errors } = await randevuService.objects.mySharedObjects({ where });

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch, state: getState(), failedAction: loadSharedObjectsFailed, reauthenticationCallback: {
						callbackThunkKey: THUNKS.LOAD_MY_SHARED_OBJECTS.key,
						payload: { where },
					},
				});

			if (errors || objects === null) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(loadSharedObjectsFailed(errors));
			}

			return dispatch(sharedObjectsLoaded(objects));
		};

type CreateSharedObjectRequest = MutationCreateSharedObjectArgs & MutationUpdateSharedObjectArgs;

export const createSharedObject =
	({ tech_name, fields, is_public, id }: CreateSharedObjectRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(createSharedObjectRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { object, errors } = await randevuService.objects.createSharedObject({ tech_name, is_public });

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch, state: getState(), failedAction: createSharedObjectFailed, reauthenticationCallback: {
						callbackThunkKey: THUNKS.CREATE_SHARED_OBJECT.key,
						payload: { tech_name, fields, id },
					},
				});

			if (errors || object === null) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(createSharedObjectFailed(errors));
			}

			const { field_inputs: dirtyFieldsWithUploadedFiles } = await randevuService.files.uploadFieldInputsFiles({
				field_inputs: fields ?? [],
				fields: object?.fields ?? [],
			});

			const { updated, errors: updateErrors } = await randevuService.objects.updateSharedObject({
				fields: dirtyFieldsWithUploadedFiles,
				id: object.id,
			});

			if (updateErrors || updated !== true) {
				dispatch(sendUnexpectedErrorToasty(updateErrors));
				return dispatch(createSharedObjectFailed(errors));
			}

			const { objects, errors: getErrors } = await randevuService.objects.mySharedObjects({
				where: { tech_name: tech_name },
			});

			return dispatch(sharedObjectCreated(objects ?? []));
		};

export type UpdateSharedObjectRequest = {
	fields: FieldInput[];
	id: string;
};

export const updateSharedObject =
	({ fields, id }: UpdateSharedObjectRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateSharedObjectRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const currentSharedObject = getState().sharedObjects.items.find((so) => so.id == id);

			if (!currentSharedObject) {
				dispatch(sendErrorToasty(`Shared object not found, id: ${id}`));
				dispatch(updateSharedObjectFailed)
			}

			const { field_inputs: dirtyFieldsWithUploadedFiles } = await randevuService.files.uploadFieldInputsFiles({
				field_inputs: fields,
				fields: currentSharedObject?.fields ?? [],
			});

			const { updated, errors: updateErrors } = await randevuService.objects.updateSharedObject({
				fields: dirtyFieldsWithUploadedFiles,
				id: id,
			});

			if (isSessionExpired(updateErrors))
				return handleSessionExpired({
					dispatch, state: getState(), failedAction: updateSharedObjectFailed, reauthenticationCallback: {
						callbackThunkKey: THUNKS.UPDATE_SHARED_OBJECT.key,
						payload: { fields, id },
					},
				});

			if (updateErrors || updated !== true) {
				dispatch(sendUnexpectedErrorToasty(updateErrors));
				return dispatch(updateSharedObjectFailed(updateErrors));
			}

			const { objects, errors: fetchErrors } = await randevuService.objects.mySharedObjects({});

			dispatch(sendSuccessToasty('Saved'))
			return dispatch(sharedObjectUpdated(objects ?? []));
		};
/////////////////////
//   SELECTORS     //
/////////////////////
export const selectMySharedObjects = (state: RootState) => state.sharedObjects.items;
export const selectMySharedObject = (state: RootState) => state.sharedObjects.item;
export const selectMySharedObjectTypes = (state: RootState) => state.sharedObjects.types;
export const selectLoading = (state: RootState) => state.sharedObjects.loading;
