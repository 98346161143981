import { Button, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { buildSupplyVariantRows, buildSupplyVariantTableColumns } from '@rdv-fo/app/lib/tableHelpers';
import { FieldType, ObjectType, SupplyVariant } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_100, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import MaterialReactTable, {
	MRT_ShowHideColumnsButton,
	MRT_FullScreenToggleButton,
	MRT_ToggleDensePaddingButton,
} from 'material-react-table';

import EditIcon from '@mui/icons-material/Edit';

interface SuppliesTableProps {
	fields: { field_type: FieldType; object_type: ObjectType }[];
	supplies: SupplyVariant[];
	loading: boolean;
	onEditSupplyVariant: (supplyId: string) => void;
	onToggleIsActive: (id: string, isActive: boolean) => void;
}

const SupplyVariantsTable = ({ fields, supplies, onEditSupplyVariant, loading, onToggleIsActive }: SuppliesTableProps) => {
	const objectTypes = fields.map((field) => field.object_type).filter((ft) => ft);
	const fieldTypes = fields.map((field) => field.field_type);
	const variantFieldTypes = fieldTypes.filter((ft) => ft?.is_variant_identifier || ft?.is_variant_specific);
	const supplyColumns = buildSupplyVariantTableColumns(variantFieldTypes, objectTypes as any, onEditSupplyVariant);
	const supplyData = buildSupplyVariantRows(supplies);

	return (
		<>
			<MaterialReactTable
				state={{ isLoading: loading }}
				initialState={{
					columnVisibility: {
						_id: false,
					}, //hide id column by default
					pagination: { pageSize: 50, pageIndex: 0 },
				}}
				columns={supplyColumns}
				data={supplyData}
				enablePinning
				enableSorting={false}
				enableColumnActions={false}
				enableDensityToggle={true}
				enableGlobalFilter={false}
				enableColumnFilters={false} //disable all column filters
				enableColumnFilterModes={false}
				muiTablePaginationProps={{
					rowsPerPageOptions: [5, 15, 30, 50, 100],
					showFirstButton: false,
					showLastButton: false,
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				renderTopToolbar={({ table }) => {
					return (
						<Stack direction="column" width="100%">
							<Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%">
								<MRT_FullScreenToggleButton sx={{ color: RDV_NEUTRAL_100 }} table={table} />
								<MRT_ShowHideColumnsButton sx={{ color: RDV_NEUTRAL_100 }} table={table} />
								<MRT_ToggleDensePaddingButton sx={{ color: RDV_NEUTRAL_100 }} table={table} />
							</Stack>
						</Stack>
					);
				}}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				muiTableBodyProps={{
					sx: {
						'&:hover': {
							backgroundColor: RDV_NEUTRAL_20,
						},
					},
				}}
				enableRowActions={true}
				positionActionsColumn="last"
				renderRowActions={({ row }) => {
					const supplyVariantId = row.original?._id?.value;
					const isActive = row.original?._isActive;

					return (
						<Stack direction='row' spacing={2}>

							<IconButton
								onClick={() => onEditSupplyVariant(supplyVariantId)}
								aria-label="view details"
								size="small"
							>
								<EditIcon fontSize="small" sx={{ color: 'black' }} />
							</IconButton>
							<Button
								color={isActive ? 'error' : 'success'}
								size="small"
								disableElevation
								variant="outlined"
								sx={{ textTransform: 'none' }}
								onClick={() => onToggleIsActive(supplyVariantId, isActive)}
							>
								{isActive ? 'Unlist' : 'Enlist'}
							</Button>
						</Stack>
					);
				}}
			/>
		</>
	);
};

export default SupplyVariantsTable;
