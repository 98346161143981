import React, { useEffect } from 'react'

import Typography from '@mui/material/Typography';

import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { loadMyShoppingCartOrders, selectMyMultiDirectTransactions, selectLoading } from '@rdv-fo/store/slices/shoppingCartSlice';
import { ShoppingCartOrderLight } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import MultiDirectMatchesTable from '@rdv-fo/components/transaction/multi-direct/MultiDirectMatchesTable';
import { useParams } from 'react-router';


const ListMyMultiDirectMatchesPage = () => {
    const dispatch = useAppDispatch();
    const { transactionTypeTechName } = useParams<{ transactionTypeTechName: string }>();

    const orders = useAppSelector(selectMyMultiDirectTransactions) as ShoppingCartOrderLight[];
    const loading = useAppSelector(selectLoading)

    useEffect(() => {
        dispatch(loadMyShoppingCartOrders({ where: { transaction_tech_name: transactionTypeTechName } }));
    }, [dispatch, transactionTypeTechName])


    return (
        <>
            <Stack direction="row" width='100%' justifyContent='space-between'>
                <Typography component="h1" variant="h1">
                    Orders
                </Typography>
            </Stack>
            {orders && <MultiDirectMatchesTable matches={orders} loading={loading} transactionTechName={transactionTypeTechName} />}
        </>
    )
}

export default ListMyMultiDirectMatchesPage