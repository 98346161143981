import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from '@rdv-fo/store/configureStore';

import ROUTES from '@rdv-fo/common/routes';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { ParticipantStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const isOnboarded = (currentUser) => currentUser?.status === ParticipantStatusKind.Onboarded;

const getTargetRoute = (currentUser) => {
	const { status } = currentUser;

	if (status === ParticipantStatusKind.Rejected) return ROUTES.PARTICIPANT_REJECTED;
	if (status === ParticipantStatusKind.PendingEmailVerification) return ROUTES.USER_VERIFICATION_REQUIRED;
	return ROUTES.USER_ONBOARDING;
};

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
	const currentUser = useAppSelector(selectCurrentUser);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (!currentUser) {
					console.log('User not signed-in, redirect to sign-in page...');
					return (
						<Redirect
							to={{
								pathname: routeBuilder(ROUTES.SIGN_IN),
								state: { from: props.location },
							}}
						/>
					);
				}
				if (!isOnboarded(currentUser)) {
					console.log(`User is ${currentUser.status} status. Redirect to appropriate page..`);

					return (
						<Redirect
							to={{
								pathname: routeBuilder(getTargetRoute(currentUser)),
								state: { from: props.location },
							}}
						/>
					);
				}

				return Component ? <Component {...props} /> : render(props);
			}}
		/>
	);
};

export default ProtectedRoute;
