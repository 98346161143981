import config from '../../common/config';
import Features from './features';

class randevu {
	constructor(args = {}) {
		this._baseURL = config.apiGateway;
		this._headers = { 'Content-Type': 'application/json' };

		const { token, apiKey } = args;
		if (token) this.withAuth(token);
		if (apiKey) this.withApiKey(apiKey);

		this.payments = new Features.PaymentsService(this);
		this.transactions = new Features.TransactionsService(this);
		this.auth = new Features.AuthService(this);
		this.files = new Features.FilesService(this);
		this.platforms = new Features.PlatformService(this);
		this.supplies = new Features.SuppliesService(this);
		this.participants = new Features.ParticipantsService(this);
		this.connections = new Features.ConnectionsService(this);
		this.objects = new Features.ObjectsService(this);
		this.referrals = new Features.ReferralService(this);
	}

	_handleFetchErrors(fetchErrors) {
		let response = {};

		if ('Failed to fetch' === fetchErrors.message) {
			console.error('Network problems detected. User is probably without internet..');

			response.errors = [
				{
					message: 'Poor network connection detected. Please check your internet connection and try again.',
					extensions: { code: 'ERR_CONNECTION_REFUSED' },
				},
			];

			return response;
		} else if (fetchErrors?.message?.includes('NetworkError when attempting to fetch resource')) {
			console.log("SSL Certificate is not accepted by user's browser.");
			response.errors = [
				{
					message:
						'Shoot! It seems that secure connection to your browser cannot be established. Please try again later.',
					extensions: { code: 'ERR_CONNECTION_SSL_REFUSED' },
				},
			];
			return response;
		}

		// Unexpected and unhandled fetch errors
		response.errors = [
			{
				message: 'Poor network connection detected. Please try again later.',
				extensions: { code: 'UNEXPECTED_ERROR' },
			},
		];

		return response;
	}

	setHeader(key, value) {
		this._headers[key] = value;
		return this;
	}

	withAuth(token) {
		this._headers['Authorization'] = token;
		return this;
	}

	withApiKey(apiKey) {
		this._headers['x-randevu-key'] = apiKey;
		return this;
	}

	call({ payload }) {
		return fetch(this._baseURL, {
			method: 'POST',
			headers: { ...this._headers },
			body: payload,
		})
			.then((res) => res.json())
			.catch(this._handleFetchErrors);
	}

	assertPublicApiKey() {
		const apiKey = this._headers['x-randevu-key'];

		if (
			(!apiKey?.startsWith('pk-sand_') && apiKey?.startsWith('pk-prod_')) ||
			(apiKey?.startsWith('pk-sand_') && !apiKey?.startsWith('pk-prod_'))
		)
			throw new Error('Public api key is supposed to start with pk-sand_ or pk-prod_');
		if (!apiKey) throw new Error(`Public API Key is missing.`);
		return this;
	}
}

export default randevu;
