import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// A custom hook that reads primitive query params,
// i.e. it does not support array or object syntax
export const usePrimitiveQueryParams = () => {
	const qps = queryString.parse(useLocation().search);
	const parsedQueryParams = {};
	Object.entries(qps).map(([key, value]) => Object.assign(parsedQueryParams, {[key]: value === 'true' ? true : value === 'false' ? false : value}))
	return parsedQueryParams;
};

export const useCustomQueryParams = (qps) => {
	return queryString.parse(qps);
};
