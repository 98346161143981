import { Avatar, Typography } from '@mui/material';
import { RdvImageFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldImageCellViewProps {
	value: RdvImageFieldValue;
}
const FieldImageCellView = ({ value }: FieldImageCellViewProps) => {
	if (value === null || value === undefined) return <Typography>n.a.</Typography>;

	return <Avatar alt={value?.name} src={value?.url} variant="square" />;
};

export default FieldImageCellView;
