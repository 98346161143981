import LabelFilterInput from './LabelFilterInput';
import { FilterInputBaseProps } from './types';

import { TextField } from 'mui-rff';
import { InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';

interface FilterInputMonetaryValueProps extends FilterInputBaseProps {
	min?: number;
	max?: number;
	unit?: string;
	currency?: 'EUR' | 'USD'; // TODO: backend has to define supported currencies
}

interface MonetaryValueTextFieldInputProps {
	type: string;
	inputMode: 'numeric' | 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'decimal' | undefined;
	min?: number;
	max?: number;
	step: number;
}

const FilterInputMonetaryValue = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
	min,
	max,
	unit,
	currency = 'EUR',
}: FilterInputMonetaryValueProps) => {
	const inputProps: MonetaryValueTextFieldInputProps = {
		type: 'number',
		inputMode: 'numeric',
		...(min && { min: min / 100 }),
		...(max && { max: max / 100 }),
		step: 1,
	};

	const fieldProps = {
		parse: (value: any) => (value ? value * 100 : undefined),
		format: (value: any) => (value ? value / 100 : ''),
	};

	const textFieldInputProps = {
		...{ endAdornment: <InputAdornment position="start">{currency}</InputAdornment> },
	};

	return (
		<Grid container>
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}
			<TextField
				id={`${techName}.min_value`}
				name={`${techName}.min_value`}
				disabled={disabled}
				variant="outlined"
				size="small"
				margin="dense"
				fullWidth
				placeholder="Min"
				fieldProps={fieldProps}
				inputProps={inputProps} // FIXME: remove ts-ignore from the above of the file and fix inputProps issue
				InputProps={textFieldInputProps}
			/>
			<TextField
				id={`${techName}.max_value`}
				name={`${techName}.max_value`}
				disabled={disabled}
				variant="outlined"
				size="small"
				margin="dense"
				fullWidth
				placeholder="Max"
				fieldProps={fieldProps}
				inputProps={inputProps} // FIXME: remove ts-ignore from the above of the file and fix inputProps issue
				InputProps={textFieldInputProps}
			/>
		</Grid>
	);
};

export default FilterInputMonetaryValue;
