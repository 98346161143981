import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { FieldArray } from 'react-final-form-arrays';
import { TextField } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';

import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';
import { useForm } from 'react-final-form';

const missingValue = ['', undefined, null];

const validate = (
	value: string,
	options: {
		required: boolean;
		minLength?: number;
		maxLength?: number;
	}
) => {
	const { minLength, maxLength, required } = options;

	// if the value is not required and the user did not provide any value, we do not want to validate it
	// if the value is not required, we validate the value only if its provided
	if (!required && missingValue.includes(value)) return undefined;

	if (minLength && value?.length < minLength)
		return VALIDATION_MESSAGE.MIN_LENGTH_VIOLATION.replace('{min_length}', String(minLength));
	if (maxLength && value?.length > maxLength)
		return VALIDATION_MESSAGE.MAX_LENGTH_VIOLATION.replace('{max_length}', String(maxLength));

	if (required && (missingValue.includes(value) || !value?.trim()))
		return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	return undefined;
};

interface FieldInputTextSetProps extends FieldInputBaseProps {
	minLength?: number;
	maxLength?: number;
	minItems?: number;
	maxItems?: number;
}

export const FieldInputTextSet = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	minLength,
	maxLength,
	minItems,
	maxItems,
}: FieldInputTextSetProps) => {
	const textFieldProps = {
		validate: (value: string) => validate(value, { required: true, minLength, maxLength }),
	};
	const form = useForm();

	return (
		<>
			<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
				<LabelFieldInput
					text={label}
					htmlFor={techName}
					required={required}
					showOptional={labelProps?.showOptional}
				/>
				<FieldArray name={`${techName}`}>
					{({ fields, meta }) => {
						const numberOfItemsInArray = fields.length;

						return (
							<>
								<Stack spacing={2}>
									{fields?.map((name, index) => {
										return (
											<Box key={index}>
												<Stack
													direction="row"
													justifyContent="flex-start"
													alignItems="flex-start"
													spacing={4}
												>
													<TextField
														id={name}
														name={name}
														disabled={disabled}
														required={required}
														variant="outlined"
														size="small"
														margin="dense"
														helperText={helperText}
														fullWidth
														fieldProps={textFieldProps}
													/>

													<Button
														variant="text"
														color="primary"
														onClick={() => {
															if (index === 0 && fields.length === 1)
																form.change(techName, null);
															else fields.remove(index);
														}}
														size="small"
														startIcon={<ClearIcon />}
														style={{ padding: 1 }}
														disabled={disabled}
													>
														Remove
													</Button>
												</Stack>
											</Box>
										);
									})}
									<Box>
										{maxItems && (
											<>
												{numberOfItemsInArray !== undefined &&
													numberOfItemsInArray < maxItems ? (
													<Button
														size="small"
														color="primary"
														startIcon={<AddIcon />}
														disabled={disabled}
														onClick={() => {
															fields.push(undefined);
														}}
													>
														Add new
													</Button>
												) : (
													<Typography variant="body2" color="textSecondary">
														You are not allowed to add more because the maximum of{' '}
														{maxItems} allowed items is reached.
													</Typography>
												)}
											</>
										)}

										{maxItems === undefined && (
											<Button
												size="small"
												color="primary"
												startIcon={<AddIcon />}
												disabled={disabled}
												onClick={() => {
													fields.push(undefined);
												}}
											>
												Add new
											</Button>
										)}
									</Box>
								</Stack>
							</>
						);
					}}
				</FieldArray>
			</Stack>
		</>
	);
};

export default FieldInputTextSet;
