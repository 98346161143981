import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	MutationRequestMerchantAccountConnectionArgs,
	MutationPrepareTransactionPaymentArgs,
	MutationRequestPaymentCheckoutConnectionArgs,
	MutationRequestPayoutArgs,
	MutationRequestStripeDashboardSignInLinkArgs,
	MutationRequestBankTransferCheckoutArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

const REQUEST_MERCHANT_ACCOUNT_CONNECTION = gql`
	mutation REQUEST_MERCHANT_ACCOUNT_CONNECTION(
		$id_integration_provider: String!
		$return_url: String
		$refresh_url: String
	) {
		requestMerchantAccountConnection(
			id_integration_provider: $id_integration_provider
			return_url: $return_url
			refresh_url: $refresh_url
		) {
			url
		}
	}
`;

export const requestMerchantAccountConnection = ({
	id_integration_provider,
	refresh_url,
	return_url,
}: MutationRequestMerchantAccountConnectionArgs) => {
	return JSON.stringify({
		query: print(REQUEST_MERCHANT_ACCOUNT_CONNECTION),
		variables: {
			id_integration_provider,
			refresh_url,
			return_url,
		},
	});
};

const PREPARE_TRANSACTION_PAYMENT = gql`
	mutation PREPARE_TRANSACTION_PAYMENT($id_transaction: ID!, $tech_name: String!) {
		prepareTransactionPayment(id_transaction: $id_transaction, tech_name: $tech_name) {
			id
			amount {
				amount
				currency
			}
			status
			payment_method
			payment_method_details
		}
	}
`;

export const prepareTransactionPayment = ({ id_transaction, tech_name }: MutationPrepareTransactionPaymentArgs) => {
	return JSON.stringify({
		query: print(PREPARE_TRANSACTION_PAYMENT),
		variables: {
			id_transaction,
			tech_name,
		},
	});
};

const REQUEST_PAYMENT_CHECKOUT_CONNECTION = gql`
	mutation REQUEST_PAYMENT_CHECKOUT_CONNECTION($id_payment: ID!, $success_url: String, $cancel_url: String) {
		requestPaymentCheckoutConnection(id_payment: $id_payment, success_url: $success_url, cancel_url: $cancel_url) {
			id_payment
			url
		}
	}
`;

export const requestPaymentCheckoutConnection = ({
	id_payment,
	success_url,
	cancel_url,
}: MutationRequestPaymentCheckoutConnectionArgs) => {
	return JSON.stringify({
		query: print(REQUEST_PAYMENT_CHECKOUT_CONNECTION),
		variables: {
			id_payment,
			success_url,
			cancel_url,
		},
	});
};

const REQUEST_BANK_TRANSFER_CHECKOUT = gql`
	mutation REQUEST_BANK_TRANSFER_CHECKOUT($id_payment: ID!) {
		requestBankTransferCheckout(id_payment: $id_payment) {
			id
			amount {
				amount
				currency
			}
			status
			payment_method
			payment_method_details
		}
	}
`;

export const requestBankTransferCheckout = ({ id_payment }: MutationRequestBankTransferCheckoutArgs) => {
	return JSON.stringify({
		query: print(REQUEST_BANK_TRANSFER_CHECKOUT),
		variables: {
			id_payment,
		},
	});
};

const REQUEST_PAYOUT = gql`
	mutation REQUEST_PAYOUT($id_merchant_account: ID!) {
		requestPayout(id_merchant_account: $id_merchant_account)
	}
`;

export const requestPayout = ({ id_merchant_account }: MutationRequestPayoutArgs) => {
	return JSON.stringify({
		query: print(REQUEST_PAYOUT),
		variables: {
			id_merchant_account,
		},
	});
};

const REQUEST_STRIPE_DASHBOARD_LINK = gql`
	mutation REQUEST_PAYOUT($id_merchant_account: ID!) {
		requestStripeDashboardSignInLink(id_merchant_account: $id_merchant_account)
	}
`;

export const requestStripeDashboardSignInLink = ({
	id_merchant_account,
}: MutationRequestStripeDashboardSignInLinkArgs) => {
	return JSON.stringify({
		query: print(REQUEST_STRIPE_DASHBOARD_LINK),
		variables: {
			id_merchant_account,
		},
	});
};
