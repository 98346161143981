import { Stack, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { buildConnectionsTableColumns, buildConnectionsTableRows, buildDiscoveredConnectionParticipantsTableColumns, buildDiscoveredConnectionParticipantsTableRows } from '@rdv-fo/app/lib/tableHelpers';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { Connection, Participant } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface DiscoveredConnectionParticipantsTableProps {
    discoveredConnectionParticipants: Participant[];
    connections: Connection[];
    connectionTypeTechName: string;
    loading: boolean;
    disabled: boolean;
    onOpenParticipantDetails: (id: string) => void;
    onConnectParticipant: (id: string) => void;
}

const DiscoveredConnectionParticipantsTable = ({
    discoveredConnectionParticipants = [],
    connections,
    connectionTypeTechName,
    onOpenParticipantDetails,
    loading,
    disabled,
    onConnectParticipant
}: DiscoveredConnectionParticipantsTableProps) => {
    const history = useHistory();
    const connectionColumns = buildDiscoveredConnectionParticipantsTableColumns(discoveredConnectionParticipants?.[0]?.fields, connectionTypeTechName, history, onConnectParticipant, onOpenParticipantDetails);

    const connectionRows = buildDiscoveredConnectionParticipantsTableRows(discoveredConnectionParticipants, connections);

    return (
        <>
            <MaterialReactTable
                initialState={{
                    pagination: { pageSize: 50, pageIndex: 0 },
                    columnVisibility: {
                        _id: false
                    },
                }}
                columns={connectionColumns}
                enablePinning={false}
                data={connectionRows}
                enableTableHead={true}
                enableSorting={false}
                enablePagination={false}
                enableDensityToggle={false}
                enableGlobalFilter={false}
                enableColumnFilters={false} //disable all column filters
                enableColumnFilterModes={false}
                enableColumnActions={false}
                rowCount={discoveredConnectionParticipants?.length}
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: '0',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        '&:hover': {
                            backgroundColor: RDV_NEUTRAL_20,
                        },
                    },
                }}
                enableRowActions={false}
                enableFullScreenToggle={false}
                enableRowOrdering={false}
                enableHiding={false}
                muiTableHeadCellProps={{
                    sx: {
                        backgroundColor: RDV_NEUTRAL_10,
                        fontSize: '0.889rem',
                        fontWeight: 600,
                        letterSpacing: 0,
                        lineHeight: '1.4rem',
                    },
                }}
                enableTableFooter={false}
                renderBottomToolbar={
                    discoveredConnectionParticipants?.length > 0 &&
                    (() => {
                        let label = `${discoveredConnectionParticipants?.length} participants`;
                        if (discoveredConnectionParticipants?.length === 1) label = `${discoveredConnectionParticipants?.length} participants`;

                        return (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
                                {label}
                            </Typography>
                        );
                    })
                }
            />
        </>
    );
};

export default DiscoveredConnectionParticipantsTable;
