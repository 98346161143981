import gql from 'graphql-tag';
import { print } from 'graphql';
import { MutationCreateSharedObjectArgs, MutationUpdateSharedObjectArgs } from '../../types/generatedTypes';
import { FIELDS_WITH_FIELD_TYPE_FRAGMENT } from '../fragments';

const CREATE_SHARED_OBJECT = gql`
	mutation CREATE_SHARED_OBJECT($tech_name: String!, $is_public: Boolean!) {
        createSharedObject(tech_name: $tech_name, is_public: $is_public)  {
            id
            id_object_type
            fields {
				...FieldsFragment
			}
        }
    }
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const createSharedObject = ({ tech_name, is_public }: MutationCreateSharedObjectArgs) => {
    return JSON.stringify({
        query: print(CREATE_SHARED_OBJECT),
        variables: { tech_name, is_public }
    });
};

const UPDATE_SHARED_OBJECT = gql`
	mutation UPDATE_SHARED_OBJECT($id: ID!, $fields: [FieldInput!]) {
        updateSharedObject(id: $id, fields: $fields)
    }
`;

const updateSharedObject = ({ id, fields }: MutationUpdateSharedObjectArgs) => {
    return JSON.stringify({
        query: print(UPDATE_SHARED_OBJECT),
        variables: { id, fields }
    });
};

export { createSharedObject, updateSharedObject }