import { Chip as MuiChip, Skeleton } from '@mui/material/';

import { ReferralStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import getEnumKeyByValue from '@rdv-fo/app/lib/getEnumKeyByValue';
import useChipStyles from '@rdv-fo/components/common/chip/chipColors';

interface ReferralStatusChipProps {
	status?: ReferralStatusKind;
	loading?: boolean;
	size?: 'small' | 'medium';
}
const ReferralStatusChip = ({ status, loading = false, size = 'medium' }: ReferralStatusChipProps) => {
	const classes = useChipStyles();

	if (loading) return <Skeleton variant="rounded" width={80} height={35} />;

	let colorClass;
	let value;

	switch (status) {
		case ReferralStatusKind.Pending:
			colorClass = classes.blue;
			value = getEnumKeyByValue(ReferralStatusKind.Pending, ReferralStatusKind);
			break;

		case ReferralStatusKind.Accepted:
			colorClass = classes.green;
			value = getEnumKeyByValue(ReferralStatusKind.Accepted, ReferralStatusKind);
			break;

		case ReferralStatusKind.Activated:
			colorClass = classes.green;
			value = getEnumKeyByValue(ReferralStatusKind.Activated, ReferralStatusKind);
			break;

		default:
			colorClass = classes.gray;
			value = 'Unknown';
	}

	const customClasses = `${colorClass}`;

	return <MuiChip label={value} className={customClasses} size={size} />;
};

export default ReferralStatusChip;
