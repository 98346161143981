import { Link, Typography } from '@mui/material';
import { RdvLocationFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldLocationCellViewProps {
	value: RdvLocationFieldValue;
}
const FieldLocationCellView = ({ value }: FieldLocationCellViewProps) => {
	if (value == null) return <Typography>n.a.</Typography>;

	const googleUrlTemplate = `http://www.google.com/maps/place/${value?.lat},${value?.lng}`;

	return (
		<Link href={googleUrlTemplate} target="#">
			{value?.input}
		</Link>
	);
};

export default FieldLocationCellView;
