export const TRANSACTION_STATUS = {
	ONGOING: {
		value: 'ONGOING',
		label: 'Ongoing',
	},
	CHECKOUT: {
		value: 'CHECKOUT',
		label: 'Checkout',
	},
	PAID: {
		value: 'PAID',
		label: 'Paid',
	},
	FAILED: {
		value: 'FAILED',
		label: 'Failed',
	},
	DECLINED: {
		value: 'DECLINED',
		label: 'Declined',
	},
	REJECTED: {
		value: 'REJECTED',
		label: 'Rejected',
	},
};

export default TRANSACTION_STATUS;
