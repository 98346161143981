import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@rdv-fo/store/configureStore';

import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import { Box, Stack } from '@mui/material';
import { selectConnectParticipantsLoading, selectDiscoveredConnectionParticipants, selectLoading } from '@rdv-fo/store/slices/connectionSlice';
import DiscoveredConnectionParticipantsTable from './DiscoveredConnectionParticipantsTable';
import { Connection } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const NoConnectionsFound = () => {
    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h3" align="center">
                No results found 😕
            </Typography>
            <Typography variant="subtitle2" align="center">
                Please try again with new arguments.
            </Typography>
        </Stack>
    );
};

interface DiscoveredConnectionsProps {
    connectionTypeTechName: string;
    connections: Connection[];
    onOpenParticipantDetails: (id: string) => void;
    onConnectParticipant: (id: string) => void;
}

const DiscoveredConnections = ({ connectionTypeTechName, connections, onOpenParticipantDetails, onConnectParticipant }: DiscoveredConnectionsProps) => {
    const discoveredConnectionParticipants = useAppSelector(selectDiscoveredConnectionParticipants);
    const loading = useAppSelector(selectLoading);
    const loadingConnectParticipants = useAppSelector(selectConnectParticipantsLoading);

    let noResultsFound = discoveredConnectionParticipants !== null && discoveredConnectionParticipants?.length < 1

    return (
        <Box sx={{ height: '100%' }}>
            {loading && (
                <Box sx={{ mt: 16 }}>
                    <LoadingIndicator loading={loading} />
                </Box>
            )}

            {!loading && (
                <Box>
                    {noResultsFound && <NoConnectionsFound />}
                    {!noResultsFound && <DiscoveredConnectionParticipantsTable onOpenParticipantDetails={onOpenParticipantDetails} connectionTypeTechName={connectionTypeTechName} connections={connections} onConnectParticipant={onConnectParticipant} loading={loadingConnectParticipants} disabled={false} discoveredConnectionParticipants={discoveredConnectionParticipants} />}
                </Box>
            )}
        </Box>
    );
};

export default DiscoveredConnections;
