import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useHistory } from 'react-router';
import ROUTES from '@rdv-fo/common/routes';
import {
    Field,
    SingleDirectTransactionForConsumer,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import { DEFAULT_OPTIONS_MRT_TABLE, DEFAULT_PAGE_SIZE, DEFAULT_STYLES_MRT_TABLE, RdvCellValue } from '@rdv-fo/app/lib/tableHelpers';
import TransactionStatusChip from '../TransactionStatusChip';
import { useMemo } from 'react';
import { TransactionMappers, SingleDirectTransactionRow } from '@rdv-fo/app/lib/transactionHelpers';

export const buildSingleDirectTransactionsTableColumns = (fields: Field[]): MRT_ColumnDef<SingleDirectTransactionRow>[] => {
    const defaultTransactionColumns: MRT_ColumnDef<SingleDirectTransactionRow>[] = [
        {
            accessorKey: 'created_at',
            header: 'Created',
            Cell: ({ cell }) => {
                const data = cell.getValue<RdvCellValue>() as RdvCellValue;
                return <FieldView inputKind={data.input_type} value={data.value} />;
            },
        },
        {
            accessorKey: '_id',
            header: 'ID',
            Cell: ({ cell }) => {
                const data = cell.getValue<RdvCellValue>() as RdvCellValue;
                return <FieldView inputKind={data.input_type} value={data.value} />;
            },
        },
        {
            accessorKey: 'initiated_at',
            header: 'Started',
            Cell: ({ cell }) => {
                const data = cell.getValue<RdvCellValue>() as RdvCellValue;
                return <FieldView inputKind={data.input_type} value={data.value} />;
            },
        },
        {
            accessorKey: 'terminated_at',
            header: 'Finished',
            Cell: ({ cell }) => {
                const data = cell.getValue<RdvCellValue>() as RdvCellValue;
                return <FieldView inputKind={data.input_type} value={data.value} />;
            },
        },
        {
            accessorKey: 'status',
            header: 'State',
            Cell: ({ cell, row }) => {
                const data = cell.getValue<RdvCellValue>() as RdvCellValue;

                return (
                    <TransactionStatusChip
                        status={data?.value}
                        createdAt={row?.original?.created_at?.value}
                        startedAt={row?.original?.initiated_at?.value}
                        cancelledAt={row?.original?.cancelled_at?.value}
                        terminatedAt={row?.original?.terminated_at?.value}
                    />
                );
            },
        },
    ];

    const fieldTypes = fields?.map((field) => field.field_type) ?? [];
    const objectTypes = fields?.map((field: any) => field.object_type).filter((field) => field) ?? [];

    // const fieldColumns = buildColumns(fieldTypes, objectTypes);

    const columns = defaultTransactionColumns;

    return columns;
};

interface SingleDirectTransactionsForConsumerTableProps {
    transactions: SingleDirectTransactionForConsumer[];
    transactionTypeTechName: string;
    loading: boolean;
    totalCount: number;
    currentPageIndex: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    onNextPage: () => void;
    onPreviousPage: () => void;
}

const SingleDirectTransactionsForConsumerTable = ({ transactions = [], transactionTypeTechName, loading, totalCount, currentPageIndex, hasNextPage, hasPreviousPage, onNextPage, onPreviousPage }: SingleDirectTransactionsForConsumerTableProps) => {
    const transactionColumnDefinitions = useMemo(() => buildSingleDirectTransactionsTableColumns(transactions?.[0]?.request.fields ?? []), [transactionTypeTechName]);
    const history = useHistory();

    const data = useMemo(
        () => TransactionMappers.toSingleDirectTransactionRowForConsumer(transactions),
        [transactions, transactionTypeTechName]
    );

    return (
        <>
            <MaterialReactTable
                {...DEFAULT_STYLES_MRT_TABLE}
                {...DEFAULT_OPTIONS_MRT_TABLE}
                state={{
                    isLoading: loading,
                    pagination: { pageSize: DEFAULT_PAGE_SIZE, pageIndex: currentPageIndex },
                }}
                columns={transactionColumnDefinitions}
                data={data ?? []}
                rowCount={totalCount}
                manualPagination
                muiTablePaginationProps={{
                    rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
                    showFirstButton: false,
                    showLastButton: false,
                    backIconButtonProps: {
                        disabled: !hasPreviousPage || loading,
                        onClick: onPreviousPage,
                    },
                    nextIconButtonProps: {
                        disabled: !hasNextPage || loading,
                        onClick: onNextPage,
                    },
                }}
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        cursor: 'pointer',
                    },
                    onClick: () => {
                        const dataRecord = row.original;
                        // fixme: make row anchor element
                        history.push(
                            ROUTES.SINGLE_DIRECT_TRANSACTION_DETAILS.replace(
                                ':transactionTypeTechName',
                                transactionTypeTechName
                            ).replace(':transactionId', dataRecord._id.value as string)
                        );
                    },
                })}
            />
        </>
    );
};

export default SingleDirectTransactionsForConsumerTable;
