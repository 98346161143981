import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface FieldValueLocationProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueLocation = ({ fieldTechName, value, className }: FieldValueLocationProps) => {
	const renderValue = value ? value.input : 'n.a.';
	return (
		<Tooltip title={renderValue}>
			<Typography id={fieldTechName} className={className}>
				{renderValue}
			</Typography>
		</Tooltip>
	);
};

export default FieldValueLocation;
