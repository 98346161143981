import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { pick } from 'lodash';

import Grid from '@mui/material/Grid';

import CreateReverseAuctionRequestForm from '@rdv-fo/components/transaction/reverse-auction/CreateReverseAuctionRequestForm';
import { initiateReverseAuctionTransaction } from '@rdv-fo/store/slices/transactionSlice';
import { selectCurrentTransactionType, selectTransactionTypes } from '@rdv-fo/store/slices/transactionSlice';
import {
	determineAvailabilityFields,
	parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords,
} from '@rdv-fo/app/lib/discovery';
import { TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { useParams } from 'react-router';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';

const CreateReverseAuctionRequestPage = () => {
	const dispatch = useAppDispatch();
	const { transactionTypeTechName } = useParams();

	const currentTransactionType = useAppSelector(selectCurrentTransactionType);
	const myTransactionTypes = useAppSelector(selectTransactionTypes);
	const sharedObjects = useAppSelector(selectMySharedObjects);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);

	const contextTransactionType =
		myTransactionTypes?.length === 1
			? currentTransactionType
			: myTransactionTypes.find(
					(tt) => tt.type === TransactionKind.ReverseAuction && tt.tech_name === transactionTypeTechName
			  );

	const supplyFilterFieldsTypes = contextTransactionType?.root_type.match_type.supply_type.fields.filter(
		(field) => field?.matching_weight > 0
	);
	const matchingOrderFieldTypes = contextTransactionType?.root_type?.fields;
	const matchingUnitType = contextTransactionType?.root_type.match_type.matching_unit_type;

	const availabilityFields = determineAvailabilityFields(matchingUnitType);

	const determineCreateReverseAuctionOrderFormInitialValues = (fieldTypes) => {
		const initValues = {};
		fieldTypes.forEach((fieldType) => {
			Object.assign(initValues, { [fieldType?.tech_name]: fieldType?.initial_value?.value });
		});

		return initValues;
	};

	const handleSubmitCreateReverseAuctionRequestForm = async (values, form) => {
		const dirtyValues = getDirtyFields(values, form);
		const cleanValues = {};
		Object.entries(dirtyValues).forEach(([key, value]) => {
			// Only use values that are not null and are not empty arrays
			if (Array.isArray(value) && value.length > 0) Object.assign(cleanValues, { [key]: value });
			if (value != null && !Array.isArray(value?.values) && !Array.isArray(value))
				Object.assign(cleanValues, { [key]: value });
			if (value != null && Array.isArray(value?.values) && value?.values?.length > 0)
				Object.assign(cleanValues, { [key]: value });
		});
		const { availability_field_params: availability = { quantity: 1 } } =
			parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords({
				...values,
			});

		const supplyFieldsValues = pick(
			cleanValues,
			supplyFilterFieldsTypes?.map((field) => field.tech_name)
		);

		const supplyFilterFields = Object.keys(supplyFieldsValues).map((key) => ({
			tech_name: key,
			value: supplyFieldsValues[key],
		}));

		const matchingToolFieldsFormValues = pick(
			cleanValues,
			matchingOrderFieldTypes?.map((field) => field.tech_name)
		);

		const matchingToolFields = matchingOrderFieldTypes
			?.map((matchingToolFieldType) => {
				const key = Object.keys(matchingToolFieldsFormValues).find(
					(mttFieldTechName) => mttFieldTechName === matchingToolFieldType.tech_name
				);
				if (key) {
					const matchingToolField = {
						value: matchingToolFieldsFormValues[key],
						field_type: {
							...matchingToolFieldType,
						},
					};

					return matchingToolField;
				}
			})
			.filter((val) => val !== undefined);

		// MAP MATCHING TOOL FIELD FORM VALUE TO RANDEVU FIELD INPUT
		const matchingToolFieldInputs = mapFormValuesToFieldInputs(
			matchingToolFieldsFormValues,
			cleanValues,
			sharedObjectTypeIds,
			matchingToolFields
		);

		await dispatch(
			initiateReverseAuctionTransaction({
				transaction_tech_name: transactionTypeTechName,
				supply_filter: supplyFilterFields,
				provider_filter: [],
				consumer_filter: [],
				matching_tool_dirty_fields: matchingToolFieldInputs,
				matching_tool_fields: matchingToolFields,
			})
		);
	};

	return (
		<Grid id="input-fields-for-indirect-match-header" container spacing={2} sx={{ mb: 6 }}>
			<CreateReverseAuctionRequestForm
				sharedObjectTypes={sharedObjectTypes}
				sharedObjects={sharedObjects}
				transactionTechName={transactionTypeTechName}
				supplyFilterFieldsTypes={supplyFilterFieldsTypes}
				matchingOrderFieldTypes={matchingOrderFieldTypes}
				availabilityFields={availabilityFields}
				initValues={determineCreateReverseAuctionOrderFormInitialValues([
					...(supplyFilterFieldsTypes ?? []),
					...(matchingOrderFieldTypes ?? []),
					...(availabilityFields ?? []),
				])}
				submitButtonLabel={contextTransactionType?.ui_config?.initiate ?? 'Submit your request'}
				onSubmit={handleSubmitCreateReverseAuctionRequestForm}
			/>
		</Grid>
	);
};

export default CreateReverseAuctionRequestPage;
