import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@rdv-fo/store/configureStore';

import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import { selectDiscoverSupplyStatus, selectDiscoveredSupplies } from '@rdv-fo/store/slices/discoverySlice';
import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';
import SupplyCard from '../supply/SupplyCard';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Stack } from '@mui/material';

const NoSuppliesFound = () => {
	return (
		<Stack sx={{ mt: 4 }} direction="column" spacing={2}>
			<Typography variant="h3" align="center">
				No results found 😕
			</Typography>
			<Typography variant="subtitle2" align="center">
				Please try again with new arguments.
			</Typography>
		</Stack>
	);
};

const DiscoveredSupplies = ({ transactionTypeTechName }) => {
	const discoverSupplyStatus = useAppSelector(selectDiscoverSupplyStatus);
	const discoveredSupplies = useAppSelector(selectDiscoveredSupplies);

	let discovering = discoverSupplyStatus === API_STATUS.LOADING;
	let noResultsFound =
		discoverSupplyStatus === API_STATUS.SUCCEEDED &&
		(discoveredSupplies === null || discoveredSupplies?.length < 1);

	const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoadingSpinnerAllowed(true);
		}, 500);
	}, []);

	return (
		<Box sx={{ height: '100%' }}>
			{discovering && loadingSpinnerAllowed && (
				<Box sx={{ mt: 16 }}>
					<LoadingIndicator loading={discovering} />
				</Box>
			)}

			{!discovering && (
				<Box>
					{noResultsFound && <NoSuppliesFound />}
					<Grid container spacing={4}>
						{discoveredSupplies?.map((supply) => {
							return (
								<Grid item key={supply.id} xs={12} sm={6} md={4}>
									<SupplyCard supply={supply} transactionTypeTechName={transactionTypeTechName} />
								</Grid>
							);
						})}
					</Grid>
				</Box>
			)}
		</Box>
	);
};

export default DiscoveredSupplies;
