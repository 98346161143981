import { isEmpty } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import randevu from '../../services/randevuApi/randevuService';
import {
	goToRoute,
	redirectExternal,
	sendUnexpectedErrorToasty,
	sendSuccessToasty,
	sendErrorToasty,
	sendInfoToasty,
} from './uiSlice';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import { AppDispatch, RootState } from '../configureStore';
import {
	MutationRequestPayoutArgs,
	MutationRequestStripeDashboardSignInLinkArgs,
	Payment,
	Transfer,
	MutationPrepareTransactionPaymentArgs,
	MutationRequestBankTransferCheckoutArgs,
	PageInfo,
	QueryMyPaymentsArgs,
	QueryMyTransfersArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface PaymentSliceState {
	loading: boolean;
	errors: any | null;
	item: Payment | null;
	items: Payment[] | null;
	pageInfo: PageInfo | null;
	transfers: Transfer[] | null;
	transfersPageInfo: PageInfo | null;

	requestingMerchantAccountPayout: string | undefined;
	requestingMerchantAccountExternalUrl: string | undefined;
}

const initialState: PaymentSliceState = {
	loading: false,
	requestingMerchantAccountPayout: undefined,
	requestingMerchantAccountExternalUrl: undefined,
	errors: null,
	item: null,
	items: null,
	pageInfo: null,
	transfers: null,
	transfersPageInfo: null,
};

const slice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		preparePaymentRequested: (payment) => {
			payment.loading = true;
			payment.errors = null;
		},
		preparePaymentFailed: (payment, action: PayloadAction<any>) => {
			payment.loading = false;
			payment.errors = action.payload;
		},
		paymentPrepared: (payment, action: PayloadAction<Payment>) => {
			payment.item = action.payload;
			payment.loading = false;
		},
		obtainPspPaymentUrlRequested: (payment) => {
			payment.loading = true;
			payment.errors = null;
		},
		obtainPspPaymentUrlFailed: (payment, action: PayloadAction<any>) => {
			payment.loading = false;
			payment.errors = action.payload;
		},
		pspPaymentUrlObtained: (payment, action: PayloadAction<string>) => {
			payment.loading = false;
		},
		loadMyPaymentRequested: (payment) => {
			payment.loading = true;
			payment.item = null;
			payment.errors = null;
		},
		loadMyPaymentFailed: (payment, action: PayloadAction<any>) => {
			payment.loading = false;
			payment.errors = action.payload;
		},
		myPaymentLoaded: (payment, action: PayloadAction<Payment>) => {
			payment.loading = false;
			payment.item = action.payload;
		},
		loadMyTransfersRequested: (payment) => {
			payment.loading = true;
			payment.transfers = null;
			payment.errors = null;
		},
		loadMyTransfersFailed: (payment, action: PayloadAction<any>) => {
			payment.loading = false;
			payment.errors = action.payload;
		},
		myTransfersLoaded: (payment, action: PayloadAction<{ transfers: Transfer[]; page_info: PageInfo }>) => {
			payment.loading = false;
			payment.transfers = action.payload.transfers.sort((a: Transfer, b: Transfer) => {
				return new Date(b.succeeded_at).getTime() - new Date(a.succeeded_at).getTime();
			});
			payment.transfersPageInfo = action.payload.page_info;
		},
		loadMyPaymentsRequested: (payment) => {
			payment.loading = true;
			payment.items = null;
			payment.pageInfo = null;
			payment.errors = null;
		},
		loadMyPaymentsFailed: (payment, action: PayloadAction<any>) => {
			payment.loading = false;
			payment.errors = action.payload;
		},
		myPaymentsLoaded: (payment, action: PayloadAction<{ payments: Payment[]; page_info: PageInfo }>) => {
			payment.loading = false;
			payment.items = action.payload.payments;
			payment.pageInfo = action.payload.page_info;
		},
		requestPayoutRequested: (payment, action: PayloadAction<string>) => {
			payment.requestingMerchantAccountPayout = action.payload;
			payment.loading = true;
			payment.errors = null;
		},
		requestPayoutFailed: (payment, action: PayloadAction<any>) => {
			payment.requestingMerchantAccountPayout = undefined;
			payment.loading = false;
			payment.errors = action.payload;
		},
		payoutRequested: (payment) => {
			payment.requestingMerchantAccountPayout = undefined;
			payment.loading = false;
		},
		obtainMerchantAccountDashboardLinkRequested: (payment, action: PayloadAction<string>) => {
			payment.requestingMerchantAccountExternalUrl = action.payload;
			payment.errors = null;
		},
		obtainMerchantAccountDashboardLinkFailed: (payment, action: PayloadAction<any>) => {
			payment.requestingMerchantAccountExternalUrl = undefined;
			payment.loading = false;
			payment.errors = action.payload;
		},
		merchantAccountDashboardLinkObtained: (payment, action: PayloadAction<string>) => {
			payment.requestingMerchantAccountExternalUrl = undefined;
			payment.loading = false;
		},
	},
});

export const {
	preparePaymentRequested,
	preparePaymentFailed,
	paymentPrepared,
	obtainPspPaymentUrlRequested,
	obtainPspPaymentUrlFailed,
	pspPaymentUrlObtained,
	loadMyPaymentRequested,
	loadMyPaymentFailed,
	myPaymentLoaded,
	loadMyTransfersRequested,
	loadMyTransfersFailed,
	myTransfersLoaded,
	loadMyPaymentsRequested,
	loadMyPaymentsFailed,
	myPaymentsLoaded,
	requestPayoutRequested,
	requestPayoutFailed,
	payoutRequested,
	obtainMerchantAccountDashboardLinkRequested,
	obtainMerchantAccountDashboardLinkFailed,
	merchantAccountDashboardLinkObtained,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

export const prepareTransactionPayment =
	({ id_transaction, tech_name }: MutationPrepareTransactionPaymentArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log('Prepare payment..');

		dispatch(preparePaymentRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { payment, errors } = await randevuService.payments.prepareTransactionPayment({
			id_transaction,
			tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: preparePaymentFailed });

		if (!isEmpty(errors) || !payment) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(preparePaymentFailed(errors));
		}

		return dispatch(paymentPrepared(payment));
	};

export const requestBankTransferCheckout =
	({ id_payment }: MutationRequestBankTransferCheckoutArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(preparePaymentRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { payment, errors } = await randevuService.payments.requestBankTransferCheckout({
			id_payment,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: preparePaymentFailed });

		if (!isEmpty(errors) || !payment) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(preparePaymentFailed(errors));
		}

		return dispatch(paymentPrepared(payment));
	};

interface GoToPaymentCheckoutRequest {
	id_payment: string;
	success_url: string;
	cancel_url: string;
}

export const goToHostedPaymentCheckout =
	({ id_payment, success_url, cancel_url }: GoToPaymentCheckoutRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		// 'Go to checkout -> 1. Obtain payment URL from PSP..');

		dispatch(obtainPspPaymentUrlRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { url, errors } = await randevuService.payments.requestPaymentCheckoutConnection({
			id_payment,
			success_url,
			cancel_url,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: obtainPspPaymentUrlFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));

			return dispatch(obtainPspPaymentUrlFailed(errors));
		}

		dispatch(pspPaymentUrlObtained(url));

		return await dispatch(redirectExternal({ url: url, keepHistory: true }));
	};

interface LoadMyPaymentRequest {
	id_payment: string;
}

export const loadMyPayment =
	({ id_payment }: LoadMyPaymentRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my payment ${id_payment}`);

		dispatch(loadMyPaymentRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { payment, errors } = await randevuService.payments.myPayment({
			id: id_payment,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: loadMyPaymentFailed });

		if (errors || !payment) {
			dispatch(sendUnexpectedErrorToasty(errors));

			return dispatch(loadMyPaymentFailed(errors));
		}

		return dispatch(myPaymentLoaded(payment));
	};

export const loadMyTransfers =
	({ where, after, before, first, last }: QueryMyTransfersArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my transfers`);

		dispatch(loadMyTransfersRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { transfer_connection, errors } = await randevuService.payments.myTransfers({
			where,
			after,
			before,
			first,
			last,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: loadMyPaymentFailed });

		if (errors || !transfer_connection) {
			dispatch(sendUnexpectedErrorToasty(errors));

			return dispatch(loadMyTransfersFailed(errors));
		}

		const transfers = transfer_connection.edges.map((edge) => edge.node);

		return dispatch(myTransfersLoaded({ transfers, page_info: transfer_connection.page_info }));
	};
export const loadMyPayments =
	({ where, after, before, first, last }: QueryMyPaymentsArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my payments`);

		dispatch(loadMyPaymentsRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { payment_connection, errors } = await randevuService.payments.myPayments({
			where,
			after,
			before,
			first,
			last,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: loadMyPaymentFailed });

		if (errors || !payment_connection) {
			dispatch(sendUnexpectedErrorToasty(errors));

			return dispatch(loadMyPaymentsFailed(errors));
		}

		const payments = payment_connection.edges.map((edge) => edge.node);
		return dispatch(myPaymentsLoaded({ payments, page_info: payment_connection.page_info }));
	};

export const requestPayout =
	({ id_merchant_account }: MutationRequestPayoutArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Request payout from ${id_merchant_account}`);

		dispatch(requestPayoutRequested(id_merchant_account));

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { requested, errors } = await randevuService.payments.requestPayout({
			id_merchant_account,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: requestPayoutFailed,
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));

			return dispatch(requestPayoutFailed(errors));
		}
		if (requested !== true) {
			dispatch(
				sendErrorToasty(
					'Something went wrong on our side and we could not request payout from your merchant account.'
				)
			);
			return dispatch(requestPayoutFailed(errors));
		}

		dispatch(sendSuccessToasty('Requested payout for your total available balance'));

		return dispatch(payoutRequested());
	};

export const requestStripeDashboardLink =
	({ id_merchant_account }: MutationRequestStripeDashboardSignInLinkArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Request Stripe Express Dashboard link`);

		dispatch(obtainMerchantAccountDashboardLinkRequested(id_merchant_account));

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { url, errors } = await randevuService.payments.requestStripeDashboardSignInLink({
			id_merchant_account,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: obtainMerchantAccountDashboardLinkFailed,
			});
		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));

			return dispatch(obtainMerchantAccountDashboardLinkFailed(errors));
		}
		if (!url) {
			dispatch(
				sendUnexpectedErrorToasty(
					'An error happened on our side when trying to obtain the Dashboard URL for your merchant account.'
				)
			);

			return dispatch(obtainMerchantAccountDashboardLinkFailed(null));
		}

		dispatch(sendInfoToasty('Redirecting you...'));
		dispatch(redirectExternal({ url, keepHistory: false }));
		return dispatch(merchantAccountDashboardLinkObtained(url));
	};

/////////////////////
//   SELECTORS     //
/////////////////////
export const selectLoading = (state: RootState) => state.payment.loading;
export const selectPayment = (state: RootState) => state.payment.item;
export const selectPayments = (state: RootState) => state.payment.items;
export const selectPaymentsPageInfo = (state: RootState) => state.payment.pageInfo;
export const selectTransfers = (state: RootState) => state.payment.transfers;
export const selectTransfersPageInfo = (state: RootState) => state.payment.transfersPageInfo;
export const selectRequestingMerchantAccountPayout = (state: RootState) =>
	state.payment.requestingMerchantAccountPayout;
export const selectRequestingMerchantAccountExternalUrl = (state: RootState) =>
	state.payment.requestingMerchantAccountExternalUrl;
