import makeStyles from '@mui/styles/makeStyles';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';
import { RDV_BLACK } from '@rdv-fo/styles/theme';

interface LabelFilterInputProps {
	htmlFor: string;
	text: string;
	required: boolean;
	className?: any;
}
const LabelFilterInput = ({ htmlFor, text, required, className }: LabelFilterInputProps) => {

	return (
		<div>
			<FormLabel sx={{ color: RDV_BLACK, fontWeight: 500 }} htmlFor={htmlFor}>
				{text}
				{!required && (
					<Typography color="textSecondary" display="inline" paddingLeft={1} sx={{ fontSize: '0.8em', }}>
						Optional
					</Typography>
				)}
			</FormLabel>
		</div>
	);
};

export default LabelFilterInput;
