import React, { useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import createDecorator from 'final-form-focus';
import Stack from '@mui/material/Stack';
import BackButton from '@rdv-fo/components/common/BackButton';
import ROUTES from '@rdv-fo/common/routes';

import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import ConditionalFieldInput from '@rdv-fo/components/fields/input/ConditionalFieldInput';
import arrayMutators from 'final-form-arrays';

import RandevuInputLabel from '@rdv-fo/components/common/RandevuInputLabel';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import {
	linkParticipantAccountToStripeConnectAccount,
	loadCurrentUser,
	selectConnectToStripeAccountStatus,
	selectCurrentUser,
	updateMyFieldsFailed,
} from '@rdv-fo/store/slices/authSlice';
import { selectParticipantProfilePageConfig, selectStripeIntegration } from '@rdv-fo/store/slices/platformSlice';
import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';
import FieldInput from '@rdv-fo/components/fields/input/FieldInput';
import { updateMyFields } from '@rdv-fo/store/slices/authSlice';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import { sendSuccessToasty } from '@rdv-fo/store/slices/uiSlice';
import {
	FieldAccessKind,
	FieldCategoryKind,
	IntegrationProviders,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';
import { mapFieldsToFormValues, mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { Box } from '@mui/material';
import { selectPlatformDisplayName } from '@rdv-fo/store/slices/platformSlice';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';

const StripeConnectedAccountStatus = ({ config }) => {
	if (!config) return null;

	let {
		id_connected_account,
		email_stripe_account,
		approved,
		pending,
		details_required,
		rejected,
		name_connected_account,
	} = config;

	let message = '';
	let icon = '';

	if (approved) {
		icon = <DoneIcon size="small" color="primary" />;
		message = 'Successfully linked to your Stripe Account';
	} else if (pending) {
		icon = <AutorenewIcon size="small" color="primary" />;
		message = 'Stripe is still processing your account';
	} else if (details_required || id_connected_account) {
		icon = <ErrorOutlineIcon size="small" color="primary" />;
		message = 'Stripe requires more information from you';
	}

	return (
		<Grid container item spacing={1}>
			<Grid item>{icon}</Grid>
			<Grid item>
				<Typography>{message}</Typography>
				{approved && (
					<FormHelperText>{` Connected to ${name_connected_account} (${email_stripe_account} / ${id_connected_account})`}</FormHelperText>
				)}
			</Grid>
		</Grid>
	);
};

const ParticipantProfilePage = () => {
	const dispatch = useAppDispatch();
	const platformDisplayName = useAppSelector(selectPlatformDisplayName);
	const currentUser = useAppSelector(selectCurrentUser);
	const { title, profile_section } = useAppSelector(selectParticipantProfilePageConfig) ?? {};

	const fields = currentUser?.fields;
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes)
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id)
	const firstNameField = findFieldByCategory(fields, FieldCategoryKind.FirstName);
	const lastNameField = findFieldByCategory(fields, FieldCategoryKind.LastName);
	const sharedObjects = useAppSelector(selectMySharedObjects);

	const focusOnError = useMemo(() => createDecorator(), []);

	//** FIXME: DELETE LEFT OVERS FROM PAYMENTS FROM THIS PAGE */
	const debuggingPaymentsVisible = false;

	const connectingToStripe = API_STATUS.LOADING === useAppSelector(selectConnectToStripeAccountStatus);
	const stripeIntegration = useAppSelector(selectStripeIntegration);

	const isStripeIntegrationEnabled = stripeIntegration?.is_active;
	const isStripeIntegrationConfigured = stripeIntegration !== null && stripeIntegration !== undefined;

	const handleConnectToStripeConnect = async () => {
		console.log('Handle connect to Stripe Connect Account ..');
		const currentUrl = window.location.href;

		await dispatch(
			linkParticipantAccountToStripeConnectAccount({
				id_integration_provider: stripeIntegration?.id,
				refresh_url: currentUrl,
				return_url: currentUrl,
			})
		);
	};
	//** FIXME: DELETE LEFT OVERS FROM PAYMENTS FROM THIS PAGE */

	const handleSubmitUpdateParticipantForm = async (values, form) => {
		const dirtyFields = getDirtyFields({ ...values }, form);

		const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypeIds, currentUser?.fields);

		const resolvedAction = await dispatch(
			updateMyFields({
				id: currentUser.id,
				dirty_fields: fieldInputs,
				current_fields: currentUser?.fields,
			})
		);

		if (resolvedAction.type === updateMyFieldsFailed.type) return resolvedAction.payload;

		dispatch(sendSuccessToasty('Saved'));
	};

	useEffect(() => {
		// refresh current user on whenever this page loads
		// because we always needs the latest user data
		dispatch(loadCurrentUser());
	}, []);
	const merchantAccount = currentUser?.account?.merchant_accounts?.find((mac) => mac.payment_provider_kind === IntegrationProviders.Stripe);
	const stripeConfig = merchantAccount?.data;
	const neverConnectedToStripe = merchantAccount?.id_psp_account ? false : true;
	const requiresFurtherStripeOnboarding =
		Object.keys(stripeConfig ?? {})?.length === 1 && currentUser?.account?.merchant_account?.id_psp_account != null;

	const stripeButtonLabel = neverConnectedToStripe ? 'Link your Stripe Account' : 'Go to your Stripe Account';

	const filteredFields = fields.filter((field) => !['first_name', 'last_name'].includes(field.field_type.tech_name));

	const composeInitialValues = (fields, participantEmail) => {
		let initialValues = mapFieldsToFormValues(fields, sharedObjectTypeIds);
		initialValues.email = participantEmail;

		return initialValues;
	};

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<BackButton label="Account" to={ROUTES.MY_ACCOUNT} />
				<Typography component="h1" variant="h1">
					{title}
				</Typography>
			</Stack>

			<Box sx={{ mt: 5 }}>
				{debuggingPaymentsVisible && (
					<Card elevation={1} square={false}>
						<CardContent sx={{ padding: 4 }}>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Typography component="h6" variant="h6" color="textPrimary">
										Payments
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={7}>
									<Typography>
										{`For you to receive the money from our side, you
																		must have a Stripe Account and link it
																		to your ${platformDisplayName} account. Stripe is our trustworthy
																		partner that handles payments and makes sure
																		everything goes smoothy.`}
									</Typography>
								</Grid>

								<StripeConnectedAccountStatus config={{ ...stripeConfig, id_connected_account: merchantAccount?.id_psp_account }} />

								{neverConnectedToStripe && (
									<Grid item xs={12} sm={7}>
										<Typography>
											If you do not have Stripe Account, you will first have to create one. If you
											already have one, you will have to link it. Either way, clicking on the
											button below handles both cases.
										</Typography>
									</Grid>
								)}

								{stripeConfig?.details_required && (
									<Grid item xs={12} sm={7}>
										<Typography>
											Linking to your Stripe Account was not successful. Please go to Stripe to
											finish the process.
										</Typography>
									</Grid>
								)}

								{(stripeConfig?.details_required ||
									neverConnectedToStripe ||
									requiresFurtherStripeOnboarding) && (
										<Grid item xs={12}>
											{!isStripeIntegrationConfigured && (
												<>
													<Typography color="secondary">
														Please configure your Stripe integration in your Backoffice account
														to use this feature.
													</Typography>
												</>
											)}
											{isStripeIntegrationConfigured && !isStripeIntegrationEnabled && (
												<>
													<Typography color="secondary">
														Please enable your Stripe integration in your Backoffice account to
														use this feature.
													</Typography>
												</>
											)}
											{isStripeIntegrationEnabled && (
												<LoadingButton
													loading={connectingToStripe}
													disabled={connectingToStripe}
													variant="contained"
													color="primary"
													size="small"
													type="submit"
													onClick={handleConnectToStripeConnect}
												>
													{stripeButtonLabel}
												</LoadingButton>
											)}
										</Grid>
									)}
							</Grid>
						</CardContent>
					</Card>
				)}
				<Form
					initialValues={composeInitialValues(currentUser?.fields, currentUser?.email)}
					decorators={[focusOnError]}
					onSubmit={handleSubmitUpdateParticipantForm}
					mutators={{
						...arrayMutators,
					}}
				>
					{({ handleSubmit, pristine, submitError, submitting, submitFailed, dirtySinceLastSubmit }) => {
						return (
							<form onSubmit={handleSubmit} method="POST" id="update-current-user-form">
								<Grid container spacing={4}>
									{firstNameField ? (
										<Grid item xs={12} sm={6}>
											<RandevuInputLabel
												text={
													firstNameField?.field_type?.ui_config?.label ??
													firstNameField?.field_type?.name
												}
												htmlFor="first_name"
											/>
											<TextField
												id="first_name"
												disabled={firstNameField.my_access === FieldAccessKind.Read}
												name={firstNameField?.field_type.tech_name}
												variant="outlined"
												margin="normal"
												helperText={firstNameField?.field_type?.ui_config?.helper_text ?? ''}
												size="small"
												fullWidth
											/>
										</Grid>
									) : null}

									{lastNameField ? (
										<Grid item xs={12} sm={6}>
											<RandevuInputLabel
												text={
													lastNameField?.field_type?.ui_config?.label ??
													lastNameField?.field_type?.name
												}
												htmlFor={lastNameField?.field_type.tech_name}
											/>
											<TextField
												id="last_name"
												disabled={lastNameField?.my_access === FieldAccessKind.Read}
												name={lastNameField?.field_type.tech_name}
												variant="outlined"
												margin="normal"
												helperText={lastNameField?.field_type?.ui_config?.helper_text ?? ''}
												size="small"
												fullWidth
											/>
										</Grid>
									) : null}

									<Grid item xs={12}>
										<RandevuInputLabel text="Email" htmlFor="email" />
										<TextField
											id="email"
											name="email"
											variant="outlined"
											margin="normal"
											size="small"
											fullWidth
											disabled={true}
										/>
									</Grid>

									{/* Fields other than first and last name */}
									<Grid item xs={12}>
										{filteredFields.map((field) => {
											const readOnlyField = FieldAccessKind.Read === field.my_access;

											const disabledFieldInput = submitting || readOnlyField;

											return (
												<ConditionalFieldInput
													key={field?.field_type.tech_name}
													fields={fields}
													conditions={field?.field_type?.conditions}
												>
													<Grid container spacing={3}>
														<Grid item xs={12}>
															<FieldInput
																label={
																	field.field_type?.ui_config?.label ??
																	field.field_type?.name
																}
																sharedObjects={sharedObjects}
																sharedObjectTypes={sharedObjectTypes}
																helperText={field.field_type?.ui_config?.helper_text}
																optionsUiConfig={field?.field_type?.ui_config?.options}
																fieldTechName={field.field_type.tech_name}
																disabled={disabledFieldInput}
																required={field?.field_type.is_required}
																inputType={field?.field_type?.input_type}
																inputOptions={field?.field_type.input_options}
																objectTypeFields={field?.object_type?.fields}
															/>
														</Grid>
													</Grid>
												</ConditionalFieldInput>
											);
										})}
									</Grid>
									<Grid item xs={12}>
										<LoadingButton
											type="submit"
											form="update-current-user-form"
											loading={submitting}
											disabled={submitting || pristine || (submitFailed && !dirtySinceLastSubmit)}
										>
											{profile_section.button_save.label}
										</LoadingButton>
									</Grid>
								</Grid>
							</form>
						);
					}}
				</Form>
			</Box>
		</>
	);
};

export default ParticipantProfilePage;
