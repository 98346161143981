import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { useLocation, } from 'react-router';
import Stack from '@mui/material/Stack';
import arrayMutators from 'final-form-arrays';

import { Grid, Skeleton, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import {
    setSearchBarInputs,
    setSelectedFilters,
} from '@rdv-fo/store/slices/discoverySlice';
import ROUTES from '@rdv-fo/common/routes';
import { replaceCurrentRouteWith } from '@rdv-fo/store/slices/uiSlice';
import { buildQueryParams, parseDiscoveryFilters } from '@rdv-fo/app/lib/url';
import { removeFilterKeys } from '@rdv-fo/app/lib/supply';
import FiltersContainer from '@rdv-fo/components/FiltersContainer';
import { FieldType } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface ConnectionDiscoveryFiltersProps {
    connectionTechName: string;
    participantFieldTypes: FieldType[]
    disabled: boolean;
    loading: boolean;
    numberOfSelectedFilters?: number;
}
const ConnectionDiscoveryFilters = ({ connectionTechName, loading, participantFieldTypes, disabled, numberOfSelectedFilters }: ConnectionDiscoveryFiltersProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const queryParams = parseDiscoveryFilters(location.search);

    const submitDiscoveryFilters = async (values: any) => {
        await dispatch(setSearchBarInputs(removeFilterKeys(values)));
        await dispatch(
            replaceCurrentRouteWith(
                `${ROUTES.DISCOVER_CONNECTION_PARTICIPANTS.replace(':connectionTechName', connectionTechName)}${buildQueryParams(values, participantFieldTypes)}`
            )
        );
    };

    const handleClearFilterInputs = async (form: any, connectionTechName: string) => {
        form.reset({});
        dispatch(setSelectedFilters({}));
        dispatch(
            replaceCurrentRouteWith(ROUTES.DISCOVER_CONNECTION_PARTICIPANTS.replace(':connectionTechName', connectionTechName))
        );
    };

    return (
        <>
            {loading &&
                <Stack direction="column" sx={{ pt: 4 }}>
                    <Skeleton width="100%" height={50} />
                    <Skeleton width="100%" height={50} />
                    <Skeleton width="100%" height={50} />
                    <Skeleton width="100%" height={50} />
                </Stack>
            }
            {!loading && participantFieldTypes?.length < 1 && (
                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                    <Typography variant="subtitle1">
                        You did not enable any filters for discovery within the given connection type context
                    </Typography>
                    <Typography variant="subtitle2">Please use the Backoffice application to enable filters</Typography>
                </Stack>
            )}
            {participantFieldTypes?.length > 0 && (
                <Grid item xs={12}>
                    <Form
                        onSubmit={submitDiscoveryFilters}
                        initialValues={queryParams}
                        mutators={{
                            ...arrayMutators,
                        }}
                    >
                        {({ handleSubmit, form }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <FiltersContainer
                                        showInputs={true}
                                        availabilityManagementKind={undefined}
                                        matchingUnitType={undefined}
                                        supplyFilters={participantFieldTypes}
                                        disabled={disabled}
                                        showClearBtn
                                        onDiscover={handleSubmit}
                                        numberOfSelectedFilters={numberOfSelectedFilters}
                                        onClearFilterInputs={() => {
                                            handleClearFilterInputs(form, connectionTechName);
                                        }}
                                    />
                                </form>
                            );
                        }}
                    </Form>
                </Grid>
            )}
        </>
    );
};

export default ConnectionDiscoveryFilters;
