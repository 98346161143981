import React from 'react';
import { TextField } from 'mui-rff';

import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

const AddressFields = ({ name, label }) => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', gap: 20 }}>
				<Typography component="h2" variant="h6" fontWeight={700}>
					{label}
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField name={`${name}.firstName`} label="First Name" variant="outlined" required />
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField name={`${name}.lastName`} label="Last Name" variant="outlined" required />
			</Grid>
			<Grid item xs={12} md={7}>
				<TextField name={`${name}.streetAddress`} label="Street Address" variant="outlined" required />
			</Grid>
			<Grid item xs={12} md={5}>
				<TextField name={`${name}.streetAddressLineTwo`} label="Street Line 2 (optional)" variant="outlined" />
			</Grid>
			<Grid item xs={12} md={4}>
				<TextField name={`${name}.zipCode`} label="Zip" variant="outlined" required />
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField name={`${name}.state`} label="State" variant="outlined" required />
			</Grid>
		</Grid>
	);
};

export default AddressFields;
