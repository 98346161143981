import { print } from 'graphql';
import gql from 'graphql-tag';

const MY_REFERRALS = gql`
	query MY_REFERRALS {
		myReferrals {
			created_at
			status
			referee {
				id
				type {
					name
					id
				}
				user {
					id
					first_name
					last_name
					email
				}
			}
		}
	}
`;

const myReferrals = () => {
	return JSON.stringify({
		query: print(MY_REFERRALS),
	});
};

export { myReferrals };
