import {
	MutationLoginParticipantArgs,
	MutationRegisterParticipantArgs,
	MutationRequestParticipantPasswordResetArgs,
	MutationResetParticipantPasswordArgs,
	MutationVerifyParticipantArgs,
	MutationTriggerManualParticipantOnboardingTransitionArgs,
	MutationUpdateMyEmailArgs,
	MutationUpdateMyPasswordArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import gql from 'graphql-tag';
import { print } from 'graphql';

const LOGIN_PARTICIPANT = gql`
	mutation LOGIN_PARTICIPANT($email: String!, $password: String!) {
		loginParticipant(email: $email, password: $password) {
			token
		}
	}
`;
const signIn = ({ email, password }: MutationLoginParticipantArgs) => {
	return JSON.stringify({
		query: print(LOGIN_PARTICIPANT),
		variables: {
			email: String(email),
			password: String(password),
		},
	});
};

const LOGIN_AS_GUEST_PARTICIPANT = gql`
	mutation {
		getGuestToken {
			token
		}
	}
`;
const signInAsGuest = () => {
	return JSON.stringify({
		query: print(LOGIN_AS_GUEST_PARTICIPANT),
	});
};

const LOGOUT_ME = gql`
	mutation LOGOUT_ME {
		logoutMe
	}
`;

const signOut = () => JSON.stringify({ query: print(LOGOUT_ME) });

const REQUEST_PARTICIPANT_PASSWORD_RESET = gql`
	mutation REQUEST_PARTICIPANT_PASSWORD_RESET($email: String!) {
		requestParticipantPasswordReset(email: $email)
	}
`;

const requestParticipantPasswordReset = ({ email }: MutationRequestParticipantPasswordResetArgs) => {
	return JSON.stringify({
		query: print(REQUEST_PARTICIPANT_PASSWORD_RESET),
		variables: {
			email: String(email),
		},
	});
};

const REGISTER_PARTICIPANT = gql`
	mutation REGISTER_PARTICIPANT(
		$email: String!
		$first_name: String!
		$last_name: String!
		$participant_tech_name: String!
	) {
		registerParticipant(
			email: $email
			first_name: $first_name
			last_name: $last_name
			participant_tech_name: $participant_tech_name
		)
	}
`;

const registerParticipant = ({
	first_name,
	last_name,
	email,
	participant_tech_name,
}: MutationRegisterParticipantArgs) => {
	return JSON.stringify({
		query: print(REGISTER_PARTICIPANT),
		variables: {
			email: String(email),
			first_name: String(first_name),
			last_name: String(last_name),
			participant_tech_name: String(participant_tech_name),
		},
	});
};

const RESET_PARTICIPANT_PASSWORD = gql`
	mutation RESET_PARTICIPANT_PASSWORD($token: String!, $password: String!) {
		resetParticipantPassword(token: $token, password: $password)
	}
`;

export const resetParticipantPassword = ({ password, token }: MutationResetParticipantPasswordArgs) => {
	return JSON.stringify({
		query: print(RESET_PARTICIPANT_PASSWORD),
		variables: {
			password: String(password),
			token: String(token),
		},
	});
};

const VERIFY_PARTICIPANT_ACCOUNT = gql`
	mutation VERIFY_PARTICIPANT_ACCOUNT($token: String!, $password: String!) {
		verifyParticipant(token: $token, password: $password)
	}
`;

export const verifyParticipantAccount = ({ password, token }: MutationVerifyParticipantArgs) => {
	return JSON.stringify({
		query: print(VERIFY_PARTICIPANT_ACCOUNT),
		variables: {
			password: String(password),
			token: String(token),
		},
	});
};

const TRIGGER_MANUAL_PARTICIPANT_ONBOARDING_TRANSITION = gql`
	mutation TRIGGER_MANUAL_PARTICIPANT_ONBOARDING_TRANSITION($id_participant: ID!, $transition_tech_name: String) {
		triggerManualParticipantOnboardingTransition(
			id_participant: $id_participant
			transition_tech_name: $transition_tech_name
		)
	}
`;

const triggerManualParticipantOnboardingTransition = ({
	id_participant,
	transition_tech_name,
}: MutationTriggerManualParticipantOnboardingTransitionArgs) => {
	return JSON.stringify({
		query: print(TRIGGER_MANUAL_PARTICIPANT_ONBOARDING_TRANSITION),
		variables: { id_participant, transition_tech_name },
	});
};

const UPDATE_MY_EMAIL = gql`
	mutation UPDATE_MY_EMAIL($current_password: String!, $new_email: String!) {
		updateMyEmail(current_password: $current_password, new_email: $new_email)
	}
`;

const updateMyEmail = ({ current_password, new_email }: MutationUpdateMyEmailArgs) => {
	return JSON.stringify({
		query: print(UPDATE_MY_EMAIL),
		variables: { current_password, new_email },
	});
};
const UPDATE_MY_PASSWORD = gql`
	mutation UPDATE_MY_PASSWORD($current_password: String!, $new_password: String!) {
		updateMyPassword(current_password: $current_password, new_password: $new_password)
	}
`;

const updateMyPassword = ({ current_password, new_password }: MutationUpdateMyPasswordArgs) => {
	return JSON.stringify({
		query: print(UPDATE_MY_PASSWORD),
		variables: { current_password, new_password },
	});
};

export {
	registerParticipant,
	signIn,
	signInAsGuest,
	signOut,
	requestParticipantPasswordReset,
	triggerManualParticipantOnboardingTransition,
	updateMyEmail,
	updateMyPassword,
};
