import { Dialog, DialogActions, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import React from 'react'
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import ClearIcon from '@mui/icons-material/Clear';

interface RemoveShoppingCartItemDialogProps {
    itemName: string;
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onRemoveItemFromShoppingCart: () => void;
}

const RemoveShoppingCartItemDialog = ({ itemName, open, loading, onClose, onRemoveItemFromShoppingCart }: RemoveShoppingCartItemDialogProps) => {
    return (
        <Dialog aria-labelledby="create-supply" open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle id="create-supply-title">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">Removing item from your shopping cart</Typography>
                    <IconButton onClick={onClose}>
                        <ClearIcon fontSize="medium" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider sx={{ marginY: 1 }} />
            <DialogContent>
                <Typography variant='body1'>
                    Are you sure you want to remove {itemName} from your shopping cart?
                </Typography>
            </DialogContent>
            <Divider sx={{ marginY: 1 }} />
            <DialogActions >
                <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
                    <Button
                        variant="text"
                        onClick={onClose}
                        size="medium"
                        color="inherit"
                        disabled={loading}
                    >
                        Cancel
                    </Button>

                    <Button
                        disabled={loading}
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={onRemoveItemFromShoppingCart}
                    >
                        Remove item
                    </Button>
                </Stack>
            </DialogActions >
        </Dialog >
    )
}

export default RemoveShoppingCartItemDialog