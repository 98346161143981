import Typography from '@mui/material/Typography';

import { formatDatetimeShort } from '@rdv-fo/app/lib/datetime';

interface FieldValueDatetimeProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueDatetime = ({ fieldTechName, value, className }: FieldValueDatetimeProps) => {
	return (
		<Typography id={fieldTechName} className={className}>
			{value === null ? 'n.a.' : formatDatetimeShort(value)}
		</Typography>
	);
};

export default FieldValueDatetime;
