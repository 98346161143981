import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import PageWidth from '../layout/PageWidth';
import { ASSETS } from '@rdv-fo/app/assets';
import { Stack } from '@mui/material';
import { useAppSelector } from '@rdv-fo/store/configureStore';
import { selectLandingPageConfig } from '@rdv-fo/store/slices/platformSlice';

const HowItWorksSection = () => {
	const landing_page_config = useAppSelector(selectLandingPageConfig);

	return (
		<>
			<section id="steps-section">
				<PageWidth padding={0} paddingLeft={0} paddingRight={0} paddingBottom={0} paddingTop={0} maxWidth="lg">
					<Typography variant="h2" align="center" sx={{ mb: 8, mt: 8 }}>
						{landing_page_config?.how_it_works?.title ?? 'How it works'}
					</Typography>

					<Grid
						container
						sx={{ p: { xs: 4, sm: 4 } }}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={4}
					>
						<Grid item xs={12} md={4}>
							<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
								<img
									src={
										landing_page_config?.how_it_works?.image_1_url ??
										ASSETS.RANDEVU.LANDING_PAGE_STEPS_SECTION.STEP_1
									}
									alt="step-1"
									width={300}
									height={200}
								/>
								<Stack
									direction="column"
									spacing={1}
									sx={{
										justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
										alignItems: { xs: 'center', sm: 'center', md: 'stretch' },
									}}
								>
									<Typography color="primary">
										{landing_page_config?.how_it_works?.step_1_name ?? 'Step 1'}
									</Typography>
									<Typography variant="h3">
										{landing_page_config?.how_it_works?.step_1_title ?? 'Tell us your needs'}
									</Typography>
									<Typography variant="body1">
										{landing_page_config?.how_it_works?.step_1_description ??
											'Enter what you need and let the platform work for you. It is that easy.'}
									</Typography>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4}>
							<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
								<img
									src={
										landing_page_config?.how_it_works?.image_2_url ??
										ASSETS.RANDEVU.LANDING_PAGE_STEPS_SECTION.STEP_2
									}
									alt="step-2"
									width={300}
									height={200}
								/>
								<Stack
									direction="column"
									spacing={1}
									sx={{
										justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
										alignItems: { xs: 'center', sm: 'center', md: 'stretch' },
									}}
								>
									<Typography color="primary">
										{landing_page_config?.how_it_works?.step_2_name ?? 'Step 2'}
									</Typography>
									<Typography variant="h3">
										{landing_page_config?.how_it_works?.step_2_title ?? 'The best offer for you'}
									</Typography>
									<Typography variant="body1">
										{landing_page_config?.how_it_works?.step_2_description ??
											'The greatest offer is waiting for you in just after a few clicks.'}
									</Typography>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4}>
							<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
								<img
									src={
										landing_page_config?.how_it_works?.image_3_url ??
										ASSETS.RANDEVU.LANDING_PAGE_STEPS_SECTION.STEP_3
									}
									alt="step-3"
									width={300}
									height={200}
								/>
								<Stack
									direction="column"
									spacing={1}
									sx={{
										justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
										alignItems: { xs: 'center', sm: 'center', md: 'stretch' },
									}}
								>
									<Typography color="primary">
										{landing_page_config?.how_it_works?.step_3_name ?? 'Step 3'}
									</Typography>
									<Typography variant="h3">
										{landing_page_config?.how_it_works?.step_3_title ?? 'Enjoy the success'}
									</Typography>
									<Typography variant="body1">
										{landing_page_config?.how_it_works?.step_3_description ??
											'Now celebrate a bit what you achieved. Even though it was pretty easy to do it, give yourself a few taps on your shoulders.'}
									</Typography>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</PageWidth>
			</section>
		</>
	);
};

export default HowItWorksSection;
