import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { useLocation, useParams } from 'react-router';

import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

import { selectToken } from '@rdv-fo/store/slices/authSlice';
import { parseDiscoveryFilters } from '@rdv-fo/app/lib/url';
import { parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords } from '@rdv-fo/app/lib/discovery';
import {
    createConnectionFailed,
    createConnectionWithDiscoveredParticipant,
    discoverConnectionParticipants,
    loadMyConnections,
    loadMyConnectionTypes,
    selectDiscoveredConnectionParticipants,
    selectLoading,
    selectMyConnections,
    selectMyConnectionTypes,
} from '@rdv-fo/store/slices/connectionSlice';
import BackButton from '@rdv-fo/components/common/BackButton';
import ROUTES from '@rdv-fo/common/routes';
import DiscoveredConnections from '@rdv-fo/components/connections/discovery/DiscoveredConnections';
import ConnectionDiscoveryFilters from '@rdv-fo/components/connections/discovery/ConnectionDiscoveryFilters';
import DiscoveredConnectionParticipantDetailsDialog from '@rdv-fo/components/connections/discovery/DiscoveredConnecitonParticipantDetailsDialog';
import RdvDrawer from '@rdv-fo/components/common/RdvDrawer';
import DiscoveredConnectionsMobileView from '@rdv-fo/components/connections/discovery/DiscoveredConnectionsMobileView';

const DiscoveredConnectionPage = () => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { connectionTechName } = useParams<{ connectionTechName: string }>();

    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const loading = useAppSelector(selectLoading);
    const location = useLocation();
    const connections = useAppSelector(selectMyConnections);
    const discoveredParticipants = useAppSelector(selectDiscoveredConnectionParticipants);

    const [openFiltersDrawer, setOpenFiltersDrawer] = useState<boolean>(false);
    const [openParticipantDetails, setOpenParticipantDetails] = useState<{ open: boolean; id: string | null }>({
        open: false,
        id: null,
    });

    const currentParticipant = discoveredParticipants?.find(
        (participant) => participant.id === openParticipantDetails.id
    );

    const connectionTypes = useAppSelector(selectMyConnectionTypes);
    const currentConnectionType = connectionTypes.find((ct) => ct.tech_name === connectionTechName);

    const queryParams = parseDiscoveryFilters(location.search);
    const numberOfFilters = queryParams ? Object.keys(queryParams)?.length : 0;

    useEffect(() => {
        dispatch(loadMyConnections({ where: { connection_tech_name: connectionTechName } }));
        dispatch(loadMyConnectionTypes());
    }, [dispatch, connectionTechName]);

    useEffect(() => {
        if (token && connectionTechName) {
            const queryParams = parseDiscoveryFilters(location.search);

            const { filters } = parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords(queryParams);

            dispatch(
                discoverConnectionParticipants({
                    connection_tech_name: connectionTechName,
                    where: { fields: filters },
                })
            );
            if (openFiltersDrawer) setOpenFiltersDrawer(false);
        }
    }, [dispatch, location.search, token, connectionTechName]);

    const handleOpenParticipantDetails = (id: string) => {
        setOpenParticipantDetails({ id: id, open: true });
    };
    const handleCloseParticipantDetails = () => {
        setOpenParticipantDetails({ id: null, open: false });
    };

    const handleConnectParticipant = async (id: string) => {
        const resolvedAction = await dispatch(
            createConnectionWithDiscoveredParticipant({
                connectionTypeTechName: connectionTechName,
                idParticipant: id,
                fields: [],
            })
        );

        if (resolvedAction.type === createConnectionFailed.type) return resolvedAction.payload;

        setOpenParticipantDetails({ id: null, open: false });

        return resolvedAction.type;
    };

    return (
        <Box>
            <DiscoveredConnectionParticipantDetailsDialog
                onConnect={handleConnectParticipant}
                participantId={openParticipantDetails.id}
                open={openParticipantDetails.open}
                onClose={handleCloseParticipantDetails}
                participantFields={currentParticipant?.fields ?? []}
            />
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <BackButton
                        label="Connections"
                        to={ROUTES.MY_CONNECTIONS.replace(':connectionTechName', connectionTechName)}
                    />
                    <Typography component="h1" variant="h1">
                        Browse participants
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ minHeight: 'calc(100vh - 64px)' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ height: '100%' }}
                    spacing={4}
                >
                    <Grid item xs={12} sm={3} sx={{ height: 'auto', mt: 2 }}>
                        {isMobileScreen ? (
                            <>
                                <Stack direction="row" width="100%" justifyContent="flex-end">
                                    <Button variant="text" color="inherit" onClick={() => setOpenFiltersDrawer(true)}>
                                        Filters ({numberOfFilters})
                                    </Button>
                                </Stack>
                                <RdvDrawer
                                    open={openFiltersDrawer}
                                    title=""
                                    loading={false}
                                    onClose={() => setOpenFiltersDrawer(false)}
                                >
                                    <ConnectionDiscoveryFilters
                                        connectionTechName={connectionTechName}
                                        numberOfSelectedFilters={numberOfFilters}
                                        disabled={loading}
                                        loading={loading}
                                        participantFieldTypes={currentConnectionType?.participant_type?.fields ?? []}
                                    />
                                </RdvDrawer>
                            </>
                        ) : (
                            <ConnectionDiscoveryFilters
                                connectionTechName={connectionTechName}
                                disabled={loading}
                                loading={loading}
                                numberOfSelectedFilters={numberOfFilters}
                                participantFieldTypes={currentConnectionType?.participant_type?.fields ?? []}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={9} sx={{ height: '100%', overflowY: 'scroll' }}>
                        {isMobileScreen ? (
                            <DiscoveredConnectionsMobileView
                                onOpenParticipantDetails={handleOpenParticipantDetails}
                                connections={connections}
                                onConnectParticipant={handleConnectParticipant}
                                connectionTypeTechName={connectionTechName}
                            />
                        ) : (
                            <DiscoveredConnections
                                onOpenParticipantDetails={handleOpenParticipantDetails}
                                connections={connections}
                                onConnectParticipant={handleConnectParticipant}
                                connectionTypeTechName={connectionTechName}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default DiscoveredConnectionPage;
