import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	MutationUpdateSupplyArgs,
	MutationCreateSupplyArgs,
	MutationTriggerManualSupplyOnboardingTransitionArgs,
	MutationActivateSupplyArgs,
	MutationCreateSupplyVariantArgs,
	MutationUpdateMySimpleStockSupplyAvailabilityArgs,
	MutationCreateCollectionArgs,
	MutationUpdateCollectionArgs,
	MutationRequestSupplyDataImportJobArgs,
	MutationDeactivateSupplyArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

const CREATE_SUPPLY = gql`
	mutation CREATE_SUPPLY($tech_name: String!, $fields: [FieldInput!]) {
		createSupply(tech_name: $tech_name, fields: $fields) {
			id
			status
		}
	}
`;
const createSupply = ({ tech_name, fields }: MutationCreateSupplyArgs) => {
	return JSON.stringify({
		query: print(CREATE_SUPPLY),
		variables: {
			tech_name: tech_name,
			fields: fields,
		},
	});
};

const UPDATE_MY_SIMPLE_STOCK_SUPPLY_AVAILABILITY = gql`
	mutation UPDATE_MY_SIMPLE_STOCK_SUPPLY_AVAILABILITY($id_supply: ID!, $remaining_qty: Int!) {
		updateMySimpleStockSupplyAvailability(id_supply: $id_supply, remaining_qty: $remaining_qty) {
			remaining_qty
		}
	}
`;
const updateMySimpleStockSupplyAvailability = ({
	id_supply,
	remaining_qty,
}: MutationUpdateMySimpleStockSupplyAvailabilityArgs) => {
	return JSON.stringify({
		query: print(UPDATE_MY_SIMPLE_STOCK_SUPPLY_AVAILABILITY),
		variables: {
			id_supply,
			remaining_qty,
		},
	});
};
const CREATE_SUPPLY_VARIANT = gql`
	mutation CREATE_SUPPLY_VARIANT($tech_name: String!, $variant_fields: [FieldInput!], $variant_group: String!) {
		createSupplyVariant(tech_name: $tech_name, variant_fields: $variant_fields, variant_group: $variant_group) {
			id
			is_active
			status
			variant_group
			onboarding_progress {
				next_steps {
					type
					action_message
					config
				}
				status
				status_tech_name
				message
			}
			fields {
				... on SimpleField {
					my_access
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						is_dynamic
						is_variant_identifier
						is_variant_specific
						conditions {
							type
							payload
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								my_access
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_dynamic
									is_variant_identifier
									is_variant_specific
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_dynamic
						is_variant_identifier
						is_variant_specific
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					my_access
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									is_dynamic
									is_variant_identifier
									is_variant_specific
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						is_dynamic
						is_variant_identifier
						is_variant_specific
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}
			}
		}
	}
`;
const createSupplyVariant = ({ tech_name, variant_fields, variant_group }: MutationCreateSupplyVariantArgs) => {
	return JSON.stringify({
		query: print(CREATE_SUPPLY_VARIANT),
		variables: {
			tech_name: tech_name,
			variant_fields: variant_fields,
			variant_group: variant_group,
		},
	});
};

const UPDATE_SUPPLY = gql`
	mutation UPDATE_SUPPLY($id: ID!, $fields: [FieldInput!]) {
		updateSupply(id: $id, fields: $fields)
	}
`;
const updateSupply = ({ id, fields }: MutationUpdateSupplyArgs) => {
	return JSON.stringify({
		query: print(UPDATE_SUPPLY),
		variables: {
			id: id,
			fields: fields,
		},
	});
};

const ACTIVATE_SUPPLY = gql`
	mutation ACTIVATE_SUPPLY($id: ID!) {
		activateSupply(id: $id) {
			id
			status
			is_active
			type {
				name
			}
			onboarding_progress {
				next_steps {
					type
					action_message
					config
				}
				status
				status_tech_name
				message
			}
			fields {
				... on SimpleField {
					my_access
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								my_access
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					my_access
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}
			}
		}
	}
`;
const activateSupply = ({ id }: MutationActivateSupplyArgs) => {
	return JSON.stringify({
		query: print(ACTIVATE_SUPPLY),
		variables: {
			id: id,
		},
	});
};

const DEACTIVATE_SUPPLY = gql`
	mutation DEACTIVATE_SUPPLY($id: ID!) {
		deactivateSupply(id: $id) {
			id
			status
			is_active
			type {
				name
			}
			onboarding_progress {
				next_steps {
					type
					action_message
					config
				}
				status
				status_tech_name
				message
			}
			fields {
				... on SimpleField {
					my_access
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								my_access
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					my_access
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}
			}
		}
	}
`;
const deactivateSupply = ({ id }: MutationDeactivateSupplyArgs) => {
	return JSON.stringify({
		query: print(DEACTIVATE_SUPPLY),
		variables: {
			id: id,
		},
	});
};

const TRIGGER_MANUAL_SUPPLY_ONBOARDING_TRANSITION = gql`
	mutation TRIGGER_MANUAL_SUPPLY_ONBOARDING_TRANSITION($id_supply: ID!, $transition_tech_name: String!) {
		triggerManualSupplyOnboardingTransition(id_supply: $id_supply, transition_tech_name: $transition_tech_name)
	}
`;

const triggerManualSupplyOnboardingTransition = ({
	id_supply,
	transition_tech_name,
}: MutationTriggerManualSupplyOnboardingTransitionArgs) => {
	return JSON.stringify({
		query: print(TRIGGER_MANUAL_SUPPLY_ONBOARDING_TRANSITION),
		variables: { id_supply, transition_tech_name },
	});
};

const CREATE_COLLECTION = gql`
	mutation CREATE_COLLECTION($name: String!) {
		createCollection(name: $name) {
			id
			name
			supplies_count
			created_at
		}
	}
`;
const createCollection = ({ name }: MutationCreateCollectionArgs) => {
	return JSON.stringify({
		query: print(CREATE_COLLECTION),
		variables: {
			name,
		},
	});
};

const UPDATE_MY_COLLECTION = gql`
	mutation UPDATE_MY_COLLECTION($id: ID!, $name: String, $ids_supply: [ID!]) {
		updateCollection(id: $id, name: $name, ids_supply: $ids_supply) {
			id
			name
			supplies_count
			created_at
		}
	}
`;

const updateMyCollection = ({ id, name, ids_supply }: MutationUpdateCollectionArgs) => {
	return JSON.stringify({
		query: print(UPDATE_MY_COLLECTION),
		variables: {
			id: String(id),
			name,
			ids_supply,
		},
	});
};

const REQUEST_SUPPLY_DATA_IMPORT_JOB = gql`
	mutation REQUEST_SUPPLY_DATA_IMPORT_JOB(
		$supply_tech_name: String!
		$id_template: ID!
		$id_data_file: ID!
		$auto_activate_supplies: Boolean!
	) {
		requestSupplyDataImportJob(
			supply_tech_name: $supply_tech_name
			id_template: $id_template
			id_data_file: $id_data_file
			auto_activate_supplies: $auto_activate_supplies
		) {
			id

			requested_at
			started_at
			finished_at

			template {
				id
				url
				name
				file_name
			}
			data {
				id
				url
				name
				file_name
			}
			report {
				id
				url
				name
				file_name
			}

			total_number_of_records
			records_processed_successfully
			records_with_errors
			has_errors

			# requested_by {
			# 	first_name
			# 	last_name
			# 	email
			# }
			# requested_on_behalf
		}
	}
`;
const requestSupplyDataImportJob = ({
	supply_tech_name,
	id_template,
	auto_activate_supplies,
	id_data_file,
}: MutationRequestSupplyDataImportJobArgs) => {
	return JSON.stringify({
		query: print(REQUEST_SUPPLY_DATA_IMPORT_JOB),
		variables: {
			supply_tech_name,
			id_template,
			auto_activate_supplies,
			id_data_file,
		},
	});
};

export {
	createSupply,
	updateSupply,
	createSupplyVariant,
	triggerManualSupplyOnboardingTransition,
	activateSupply,
	deactivateSupply,
	updateMySimpleStockSupplyAvailability,
	createCollection,
	updateMyCollection,
	requestSupplyDataImportJob,
};
