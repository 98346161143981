import { Switches } from 'mui-rff';

import { FieldInputBaseProps } from './types';

type FieldInputBooleanProps = FieldInputBaseProps;
export const FieldInputBoolean = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
}: FieldInputBooleanProps) => {
	return (
		<>
			<Switches
				formControlLabelProps={{
					disabled: disabled,
				}}
				name={techName}
				required={required}
				data={{ label: label ?? '', value: true, disabled: disabled }}
				color="primary"
				helperText={helperText}
			/>
		</>
	);
};

export default FieldInputBoolean;
