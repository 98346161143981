import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';
import randevu from '@rdv-fo/services/randevuApi';
import { isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { sendUnexpectedErrorToasty } from './uiSlice';
import THUNKS from '@rdv-fo/store/thunkMap';

import {
	Provider,
	QueryCalculateSupplyPriceArgs,
	SupplyPrice,
	AvailabilityTrackingKind,
	QueryDiscoverTransactionSuppliesArgs,
	DiscoveredSupply,
	QueryDiscoverTransactionSupplyDetailsArgs,
	QueryDiscoverTransactionProviderDetailsArgs,
	QueryDiscoverTransactionProvidersArgs,
	PageInfo,
	QueryDiscoverTransactionSuppliesNewArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { AppDispatch, RootState } from '@rdv-fo/store/configureStore';
import { isEmpty } from 'lodash';

interface DiscoverSliceState {
	loading: boolean;
	loadingProvider: boolean;
	errors: any | null;
	supplies: DiscoveredSupply[];
	supply: DiscoveredSupply | null;
	provider: Provider | null;
	providers: Provider[];
	provider_supplies: DiscoveredSupply[];
	calculatingSupplyPrice: boolean;
	pageInfo: PageInfo | null;
	price: SupplyPrice | null;
	isSupplyAvailable: Boolean;

	selectedFilters: any; // FIXME: CHECK IF IT CAN BE REMOVED
	searchBarInputs: any; // FIXME: CHECK IF IT CAN BE REMOVED
	loadDiscoverFiltersStatus: any; // FIXME: CHECK IF IT CAN BE REMOVED
	discoverSupplyStatus: any; // FIXME: CHECK IF IT CAN BE REMOVED
}

const initialState: DiscoverSliceState = {
	loading: false,
	loadingProvider: false,
	errors: null,
	supplies: [],
	supply: null,
	provider: null,
	pageInfo: null,
	providers: [],
	provider_supplies: [],
	selectedFilters: {}, // FIXME: CHECK IF IT CAN BE REMOVED
	searchBarInputs: {}, // FIXME: CHECK IF IT CAN BE REMOVED
	loadDiscoverFiltersStatus: API_STATUS.IDLE, // FIXME: CHECK IF IT CAN BE REMOVED
	discoverSupplyStatus: API_STATUS.IDLE, // FIXME: CHECK IF IT CAN BE REMOVED
	calculatingSupplyPrice: false,
	price: null,
	isSupplyAvailable: false,
};

const slice = createSlice({
	name: 'discovery',
	initialState,
	reducers: {
		discoverSuppliesRequested: (discovery: DiscoverSliceState) => {
			discovery.loading = true;
			discovery.supplies = [];
			discovery.errors = null;

			discovery.discoverSupplyStatus = API_STATUS.LOADING;
		},
		discoverSuppliesFailed: (discovery: DiscoverSliceState, action?: PayloadAction<any | null>) => {
			discovery.loading = false;
			discovery.errors = action?.payload;
			discovery.discoverSupplyStatus = API_STATUS.FAILED;
		},
		suppliesDiscovered: (discovery: DiscoverSliceState, action: PayloadAction<{ supplies: DiscoveredSupply[], page_info: PageInfo }>) => {
			discovery.loading = false;
			discovery.supplies = action.payload.supplies;
			discovery.pageInfo = action.payload.page_info;
			discovery.discoverSupplyStatus = API_STATUS.SUCCEEDED;
		},
		discoverProvidersRequested: (discovery: DiscoverSliceState) => {
			discovery.loading = true;
			discovery.providers = [];
			discovery.errors = null;
		},
		discoverProvidersFailed: (discovery: DiscoverSliceState, action?: PayloadAction<any | null>) => {
			discovery.loading = false;
			discovery.errors = action?.payload;
		},
		providersDiscovered: (discovery: DiscoverSliceState, action: PayloadAction<{ providers: Provider[], page_info: PageInfo }>) => {
			discovery.loading = false;
			discovery.providers = action.payload.providers;
			discovery.pageInfo = action.payload.page_info;
		},
		discoverMoreProvidersRequested: (discovery: DiscoverSliceState) => {
			discovery.errors = null;
		},
		discoverMoreProvidersFailed: (discovery: DiscoverSliceState, action?: PayloadAction<any | null>) => {
			discovery.errors = action?.payload;
		},
		moreProvidersDiscovered: (discovery: DiscoverSliceState, action: PayloadAction<{ providers: Provider[], page_info: PageInfo }>) => {
			discovery.providers = [...discovery.providers, ...action.payload.providers];
			discovery.pageInfo = action.payload.page_info;
		},
		discoverMoreSuppliesRequested: (discovery: DiscoverSliceState) => {
			discovery.errors = null;
		},
		discoverMoreSuppliesFailed: (discovery: DiscoverSliceState, action?: PayloadAction<any | null>) => {
			discovery.errors = action?.payload;
		},
		moreSuppliesDiscovered: (discovery: DiscoverSliceState, action: PayloadAction<{ supplies: DiscoveredSupply[], page_info: PageInfo }>) => {
			discovery.supplies = [...discovery.supplies, ...action.payload.supplies];
			discovery.pageInfo = action.payload.page_info;
		},

		discoverSupplyDetailsRequested: (discovery: DiscoverSliceState) => {
			discovery.loading = true;
			discovery.errors = null;
			discovery.price = null;
			discovery.supply = null;
		},
		discoverSupplyDetailsFailed: (discovery: DiscoverSliceState, action?: PayloadAction<any | null>) => {
			discovery.loading = false;
			discovery.errors = action?.payload;
		},

		supplyDetailsDiscovered: (discovery: DiscoverSliceState, action: PayloadAction<DiscoveredSupply>) => {
			discovery.loading = false;
			discovery.errors = null;
			discovery.supply = action.payload;
		},

		discoverProviderDetailsRequested: (discovery: DiscoverSliceState) => {
			discovery.loadingProvider = true;
			discovery.errors = null;
			discovery.provider = null;
			discovery.provider_supplies = [];
		},
		discoverProviderDetailsFailed: (discovery: DiscoverSliceState, action?: PayloadAction<any | null>) => {
			discovery.loadingProvider = false;
			discovery.errors = action?.payload;
		},

		providerDetailsDiscovered: (
			discovery: DiscoverSliceState,
			action: PayloadAction<{ provider: Provider; provider_supplies: DiscoveredSupply[] }>
		) => {
			discovery.loadingProvider = false;
			discovery.errors = null;
			discovery.provider = action.payload.provider;
			discovery.provider_supplies = action.payload.provider_supplies;
		},

		filtersUpdated: (discovery: DiscoverSliceState, action: PayloadAction<any>) => {
			discovery.selectedFilters = action.payload;
		},
		setSearchBarInputs: (discovery: DiscoverSliceState, action: PayloadAction<any>) => {
			discovery.searchBarInputs = action.payload;
		},

		calculateSupplyPriceRequested: (discovery: DiscoverSliceState) => {
			discovery.calculatingSupplyPrice = true;
			discovery.price = null;
		},
		calculateSupplyPriceFailed: (discovery: DiscoverSliceState, action: PayloadAction<any>) => {
			discovery.calculatingSupplyPrice = false;
			discovery.errors = action.payload;
		},
		supplyPriceCalculated: (discovery: DiscoverSliceState, action: PayloadAction<SupplyPrice>) => {
			discovery.calculatingSupplyPrice = false;
			discovery.price = action.payload;
		},
		availabilityLoaded: (discovery: DiscoverSliceState, action: PayloadAction<Boolean>) => {
			discovery.isSupplyAvailable = action.payload;
		},
	},
});

export const {
	discoverSuppliesRequested,
	discoverSuppliesFailed,
	availabilityLoaded,
	suppliesDiscovered,
	discoverSupplyDetailsRequested,
	discoverSupplyDetailsFailed,
	supplyDetailsDiscovered,
	discoverProviderDetailsRequested,
	discoverProviderDetailsFailed,
	providerDetailsDiscovered,
	filtersUpdated,
	setSearchBarInputs, // FIXME: GET RID OF setSearchBarInputs
	discoverProvidersRequested,
	discoverProvidersFailed,
	providersDiscovered,
	calculateSupplyPriceRequested,
	calculateSupplyPriceFailed,
	supplyPriceCalculated,
	discoverMoreProvidersRequested,
	discoverMoreProvidersFailed,
	moreProvidersDiscovered,
	discoverMoreSuppliesRequested,
	discoverMoreSuppliesFailed,
	moreSuppliesDiscovered,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

export const discoverTransactionSupplies =
	({
		transaction_tech_name,
		where,
		availability_params,
		available_supply_only,
		first,
		after
	}: QueryDiscoverTransactionSuppliesNewArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Discover supplies..`);

			dispatch(discoverSuppliesRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { supplies, page_info, errors } = await randevuService.transactions.discoverTransactionSupplies({
				transaction_tech_name,
				where,
				availability_params,
				available_supply_only,
				first,
				after
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({ dispatch, state: getState(), failedAction: discoverSuppliesFailed });

			if (!isEmpty(errors) || supplies === null || supplies === undefined) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(discoverSuppliesFailed(errors));
			}

			return dispatch(suppliesDiscovered({ supplies, page_info }));
		};
export const discoverMoreTransactionSupplies =
	({
		transaction_tech_name,
		where,
		availability_params,
		available_supply_only,
		after,
		first
	}: QueryDiscoverTransactionSuppliesNewArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Discover supplies..`);

			dispatch(discoverMoreSuppliesRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { supplies, page_info, errors } = await randevuService.transactions.discoverTransactionSupplies({
				transaction_tech_name,
				where,
				availability_params,
				available_supply_only,
				after,
				first
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({ dispatch, state: getState(), failedAction: discoverMoreSuppliesFailed });

			if (!isEmpty(errors) || supplies === null || supplies === undefined) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(discoverMoreSuppliesFailed(errors));
			}

			return dispatch(moreSuppliesDiscovered({ supplies, page_info }));
		};
export const discoverTransactionProviders =
	({
		transaction_tech_name,
		where,
		after,
		before,
		first,
		last
	}: QueryDiscoverTransactionProvidersArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Discover providers..`);

			dispatch(discoverProvidersRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { providers, page_info, errors } = await randevuService.transactions.discoverTransactionProviders({
				transaction_tech_name,
				where,
				after,
				before,
				first,
				last
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({ dispatch, state: getState(), failedAction: discoverProvidersFailed });

			if (!isEmpty(errors) || providers === null || providers === undefined) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(discoverProvidersFailed(errors));
			}

			return dispatch(providersDiscovered({ providers, page_info }));
		};
export const discoverMoreTransactionProviders =
	({
		transaction_tech_name,
		where,
		after,
		before,
		first,
		last
	}: QueryDiscoverTransactionProvidersArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Discover providers..`);

			dispatch(discoverMoreProvidersRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { providers, page_info, errors } = await randevuService.transactions.discoverTransactionProviders({
				transaction_tech_name,
				where,
				after,
				before,
				first,
				last
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({ dispatch, state: getState(), failedAction: discoverProvidersFailed });

			if (!isEmpty(errors) || providers === null || providers === undefined) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(discoverMoreProvidersFailed(errors));
			}

			return dispatch(moreProvidersDiscovered({ providers, page_info }));
		};

export const discoverTransactionSupplyDetails =
	({ id_supply, transaction_tech_name }: QueryDiscoverTransactionSupplyDetailsArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(discoverSupplyDetailsRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { supply, errors } = await randevuService.transactions.discoverTransactionSupplyDetails({
				id_supply,
				transaction_tech_name,
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({ dispatch, state: getState(), failedAction: discoverSupplyDetailsFailed });

			if (!isEmpty(errors) || !supply) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(discoverSupplyDetailsFailed(errors));
			}

			if (AvailabilityTrackingKind.SimpleStock === supply.availability?.availability_tracking_type) {
				const { is_available, errors: availabilityErrors } = await randevuService.supplies.isSupplyAvailable({
					id_supply,
					availability_params: [{ tech_name: 'qty', value: 1 }],
				});

				if (availabilityErrors) {
					dispatch(sendUnexpectedErrorToasty(errors));
					return dispatch(discoverSupplyDetailsFailed(errors));
				}

				dispatch(availabilityLoaded(is_available));
			}

			const isMatchConfigurationPricingEnabled = supply?.type?.match_configurations?.[0]?.pricing_enabled;

			if (isMatchConfigurationPricingEnabled) {
				const { price } = await randevuService.supplies.calculateSupplyPrice({
					id_supply,
					match_parameters: [{ tech_name: 'qty', value: 1 }],
				});

				if (price) dispatch(supplyPriceCalculated(price));
			}

			return dispatch(supplyDetailsDiscovered(supply));
		};

export const discoverTransactionProviderDetails =
	({ id_participant, transaction_tech_name }: QueryDiscoverTransactionProviderDetailsArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(discoverProviderDetailsRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { provider, errors } = await randevuService.transactions.discoverTransactionProviderDetails({
				id_participant,
				transaction_tech_name,
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({ dispatch, state: getState(), failedAction: discoverProviderDetailsFailed });

			if (errors || !provider) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(discoverProviderDetailsFailed(errors));
			}

			const { supplies: provider_supplies, errors: provderSuppliesErrors } =
				await randevuService.transactions.discoverTransactionSupplies({
					transaction_tech_name,
					where: {
						ids_providers: [id_participant],
					},
				});

			return dispatch(providerDetailsDiscovered({ provider, provider_supplies }));
		};

export const calculateSupplyPrice =
	({ id_supply, match_parameters }: QueryCalculateSupplyPriceArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(calculateSupplyPriceRequested());

			const randevuService = new randevu({
				token: getState().auth.token,
				apiKey: getState().platform.public_key,
			});

			const { price, errors } = await randevuService.supplies.calculateSupplyPrice({
				id_supply,
				match_parameters,
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: calculateSupplyPriceFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.CALCULATE_SUPPLY_PRICE.key,
						payload: { id_supply, match_parameters },
					},
				});

			if (errors || price === null) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(calculateSupplyPriceFailed(errors));
			}

			return dispatch(supplyPriceCalculated(price));
		};

// FIXME: verify if it can be removed from the code base
export const setSelectedFilters = (selectedFilters: any) => async (dispatch: AppDispatch) => {
	dispatch(filtersUpdated(selectedFilters));
};

/////////////////////
//   SELECTORS     //
/////////////////////
export const selectLoading = (state: RootState) => state.discovery.loading;
export const selectLoadingProvider = (state: RootState) => state.discovery.loadingProvider;
export const selectDiscoveredSupplies = (state: RootState) => state.discovery.supplies;
export const selectDiscoveredSupplyDetails = (state: RootState) => state.discovery.supply;
export const selectDiscoveredProviderDetails = (state: RootState) => state.discovery.provider;
export const selectDiscoveredProviders = (state: RootState) => state.discovery.providers;
export const selectDiscoveredProviderSupplies = (state: RootState) => state.discovery.provider_supplies;
export const selectCalculatedSupplyPrice = (state: RootState) => state.discovery.price;
export const selectCalculatingSupplyPrice = (state: RootState) => state.discovery.calculatingSupplyPrice;
export const selectDiscoveryPageInfo = (state: RootState): PageInfo | null => state.discovery.pageInfo;

// FXIME: verify if it can be removed from the code base
export const selectDiscoverSupplyStatus = (state: RootState) => state.discovery.discoverSupplyStatus;
export const selectSearchBarInputs = (state: RootState) => state.discovery.searchBarInputs;
