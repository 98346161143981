import gql from 'graphql-tag';
import { print } from 'graphql';

import {
	QueryCalculateSupplyPriceArgs,
	QueryIsSupplyAvailableArgs,
	QueryMySuppliesArgs,
	QueryMySupplyArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

const MY_SUPPLY_TYPES = gql`
	query MY_SUPPLY_TYPES {
		mySupplyTypes {
			id
			name
			flow_type {
				is_active
			}
			tech_name
			bulk_import_enabled
			data_import_templates {
				id
				is_active
				name
				version
				template {
					url
					name
				}
			}
			fields {
				id
				tech_name
				name
				object_fields {
					id
					input_options
					ui_config {
						label
						helper_text
						options {
							label
							value
						}
						input_control
					}
					category
					input_type
					is_required
					conditions {
						type
						payload
					}
				}
				category
				input_type
				ui_config {
					label
					helper_text
					options {
						label
						value
					}
					input_control
				}
				input_options
				is_required
				conditions {
					type
					payload
				}
			}
		}
	}
`;
const mySupplyTypes = () => {
	return JSON.stringify({
		query: print(MY_SUPPLY_TYPES),
		variables: {},
	});
};
const IS_SUPPLY_AVAILABLE = gql`
	query IS_SUPPLY_AVAILABLE($id_supply: ID!, $availability_params: [FieldParameterInput!]!) {
		isSupplyAvailable(id_supply: $id_supply, availability_params: $availability_params)
	}
`;
const isSupplyAvailable = ({ id_supply, availability_params }: QueryIsSupplyAvailableArgs) => {
	return JSON.stringify({
		query: print(IS_SUPPLY_AVAILABLE),
		variables: { id_supply, availability_params },
	});
};

const GET_MY_SUPPLY = gql`
	query GET_MY_SUPPLY($id: ID!) {
		mySupply(id: $id) {
			id
			is_active
			status
			variant_group
			created_at
			availability
			type {
				name
				tech_name
			}
			onboarding_progress {
				next_steps {
					type
					action_message
					config
				}
				status
				status_tech_name
				message
			}
			fields {
				... on SimpleField {
					my_access
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						category
						is_variant_identifier
						is_variant_specific
						conditions {
							type
							payload
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								my_access
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_variant_identifier
									is_variant_specific
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_variant_identifier
						is_variant_specific
						is_required
						is_nullable
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					my_access
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									is_variant_identifier
									is_variant_specific
									input_type
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									is_required
									is_nullable
									category
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						is_required
						is_nullable
						is_variant_identifier
						is_variant_specific
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}
			}
			variants {
				id
				is_active
				status
				variant_group
				fields {
					... on SimpleField {
						my_access
						simpleValue: value
						field_type {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							input_options
							is_required
							is_variant_identifier
							is_variant_specific
							is_nullable
							category
							conditions {
								type
								payload
							}
						}
					}
					... on ObjectField {
						my_access
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									my_access
									simpleValue: value
									field_type {
										id
										name
										tech_name
										input_type
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
										input_options
										is_variant_identifier
										is_variant_specific
										is_required
										is_nullable
										category
										conditions {
											type
											payload
										}
									}
								}
							}
						}
						field_type {
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							input_options
							is_required
							is_variant_identifier
							is_variant_specific
							is_nullable
							category
							conditions {
								type
								payload
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
								input_options
							}
						}
					}

					... on ObjectSetField {
						my_access
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										id
										name
										tech_name
										input_type
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
										is_variant_identifier
										is_variant_specific
										input_options
										is_required
										is_nullable
										category
										conditions {
											type
											payload
										}
									}
								}
							}
						}
						field_type {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							input_options
							is_variant_identifier
							is_variant_specific
							is_required
							is_nullable
							category
							conditions {
								type
								payload
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
								input_options
							}
						}
					}
				}
				availability
			}
		}
	}
`;

const mySupply = ({ id }: QueryMySupplyArgs) => {
	return JSON.stringify({
		query: print(GET_MY_SUPPLY),
		variables: {
			id: String(id),
		},
	});
};

const MY_SUPPLIES = gql`
	query MY_SUPPLIES($where: MySupplyFilter) {
		mySupplies(where: $where) {
			id
			is_active
			type {
				name
				id
			}
			status
			onboarded_at
			fields {
				... on SimpleField {
					my_access
					simpleValue: value
					field_type {
						name
						tech_name
						is_variant_identifier
						ui_config {
							label
							helper_text
							options {
								label
								value
							}
							input_control
						}
						category
						conditions {
							type
							payload
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								my_access
								simpleValue: value
								field_type {
									name
									tech_name
									category
									is_variant_identifier
									ui_config {
										label
										helper_text
										options {
											label
											value
										}
										input_control
									}
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						name
						tech_name
						is_variant_identifier
						ui_config {
							label
							helper_text
							options {
								label
								value
							}
							input_control
						}
						category
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							conditions {
								type
								payload
							}
						}
					}
				}

				... on ObjectSetField {
					id
					my_access
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									name
									tech_name
									is_variant_identifier
									conditions {
										type
										payload
									}
									category
								}
							}
						}
					}
					field_type {
						name
						tech_name
						is_variant_identifier
						conditions {
							type
							payload
						}
						category
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							conditions {
								type
								payload
							}
						}
					}
				}
			}
			variant_group
			variants {
				id
				is_active
				status
				fields {
					... on SimpleField {
						my_access
						simpleValue: value
						field_type {
							name
							tech_name
							is_variant_identifier
							ui_config {
								label
								helper_text
								options {
									label
									value
								}
								input_control
							}
							category
							conditions {
								type
								payload
							}
						}
					}
					... on ObjectField {
						my_access
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									my_access
									simpleValue: value
									field_type {
										name
										tech_name
										category
										is_variant_identifier
										ui_config {
											label
											helper_text
											options {
												label
												value
											}
											input_control
										}
										conditions {
											type
											payload
										}
									}
								}
							}
						}
						field_type {
							name
							tech_name
							is_variant_identifier
							ui_config {
								label
								helper_text
								options {
									label
									value
								}
								input_control
							}
							category
							conditions {
								type
								payload
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								conditions {
									type
									payload
								}
							}
						}
					}

					... on ObjectSetField {
						id
						my_access
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										name
										tech_name
										is_variant_identifier
										conditions {
											type
											payload
										}
										category
									}
								}
							}
						}
						field_type {
							name
							tech_name
							is_variant_identifier
							conditions {
								type
								payload
							}
							category
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								conditions {
									type
									payload
								}
							}
						}
					}
				}
			}
			onboarding_progress {
				status
			}
		}
	}
`;

const mySupplies = ({ where }: QueryMySuppliesArgs) => {
	return JSON.stringify({
		query: print(MY_SUPPLIES),
		variables: {
			where,
		},
	});
};

const CALCULATE_SUPPLY_PRICE = gql`
	query CALCULATE_SUPPLY_PRICE($id_supply: ID!, $match_parameters: [FieldParameterInput!]!) {
		calculateSupplyPrice(id_supply: $id_supply, match_parameters: $match_parameters) {
			qty
			addon_items {
				description
				value
			}
			discount_items {
				description
				value
			}
			tax_items {
				description
				value
			}
			platform_fee_items {
				description
				value
				is_provider_fee
				is_consumer_fee
			}
			unit_price
			currency
			total_before_tax
			total_taxes

			total_fees
		}
	}
`;

const calculateSupplyPrice = ({ id_supply, match_parameters }: QueryCalculateSupplyPriceArgs) => {
	return JSON.stringify({
		query: print(CALCULATE_SUPPLY_PRICE),
		variables: {
			id_supply,
			match_parameters,
		},
	});
};

const GET_MY_COLLECTION = gql`
	query GET_MY_COLLECTION($id: ID!) {
		myCollection(id: $id) {
			id
			name
			supplies_count
			created_at
		}
	}
`;

const myCollection = ({ id }: QueryMySupplyArgs) => {
	return JSON.stringify({
		query: print(GET_MY_COLLECTION),
		variables: {
			id: String(id),
		},
	});
};

const MY_COLLECTIONS = gql`
	query {
		myCollections {
			id
			name
			supplies_count
			created_at
		}
	}
`;

const myCollections = () => {
	return JSON.stringify({
		query: print(MY_COLLECTIONS),
	});
};

const MY_DATA_IMPORT_JOB_REPORTS = gql`
	query MY_DATA_IMPORT_JOB_REPORTS {
		myDataImportJobReports {
			id

			requested_at
			started_at
			finished_at

			template {
				id
				url
				name
				file_name
			}
			data {
				id
				url
				name
				file_name
			}
			report {
				id
				url
				name
				file_name
			}

			total_number_of_records
			records_processed_successfully
			records_with_errors
			has_errors

			# requested_by {
			# 	first_name
			# 	last_name
			# 	email
			# }
			# requested_on_behalf
		}
	}
`;
const myDataImportJobReports = () => {
	return JSON.stringify({
		query: print(MY_DATA_IMPORT_JOB_REPORTS),
		variables: {},
	});
};

export {
	mySupply,
	mySupplies,
	mySupplyTypes,
	calculateSupplyPrice,
	isSupplyAvailable,
	myCollections,
	myCollection,
	myDataImportJobReports,
};
