import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';

export const buildBackUrlForSuccessfulPayment = ({ transactionId, transactionTechName, paymentId, redirectBackTo }) => {
	const route = routeBuilder(ROUTES.PAYMENT_PROCESSING_FOR_TRANSACTION, [
		[':transactionTypeTechName', transactionTechName],
		[':transactionId', transactionId],
		[':paymentId', paymentId],
	]);
	const successUrl = `${window.location.origin}${route}?redirectBackTo=${redirectBackTo}`;

	return successUrl;
};
export const buildBackUrlForFailedPayment = ({ transactionId, transactionTechName, paymentId, redirectBackTo }) => {
	const route = routeBuilder(ROUTES.PAYMENT_PROCESSING_FOR_TRANSACTION, [
		[':transactionTypeTechName', transactionTechName],
		[':transactionId', transactionId],
		[':paymentId', paymentId],
	]);
	const successUrl = `${window.location.origin}${route}?redirectBackTo=${redirectBackTo}`;

	return successUrl;
};
