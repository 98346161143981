
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { Link as RouterLink } from 'react-router-dom';

import { Form } from 'react-final-form';
import { Radios, Select, TextField } from 'mui-rff';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Stack } from '@mui/material';

import ROUTES from '@rdv-fo/common/routes';
import RandevuInputLabel from '@rdv-fo/components/common/RandevuInputLabel';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { signUpParticipant, signUpUserFailed } from '@rdv-fo/store/slices/authSlice';
import FormError from '../FormError';
import { Container } from '@mui/material';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import { validators } from '@rdv-fo/app/lib/validation';
import { FoParticipantTypeInputControlsKind, ParticipantType } from '@rdv-fo/services/randevuApi/types/generatedTypes';

type SignUpFormValues = {
	first_name: string;
	last_name: string;
	email: string;
	participant_tech_name: string;
};

interface FieldConfig {
	label: string;
	placeholder: string;
	link_label: string;
	text: string;
}
interface FieldParticipantTypeConfig {
	text: string;
	link_label: string;
	input_control: FoParticipantTypeInputControlsKind;
	label: string;
}

interface SignUpFormConfig {
	field_email: FieldConfig;
	field_participant_type: FieldParticipantTypeConfig;
	field_first_name: FieldConfig;
	field_last_name: FieldConfig;
	button_submit: FieldConfig;
	redirect_to_sign_in: FieldConfig;
}

interface SignUpFormProps {
	loading: boolean;
	preselectedParticipantTypeTechName?: string;
	participantTypes: ParticipantType[];
	form: SignUpFormConfig;
	description: string;
	title: string;
	eyebrow: string;
	errors: string;
}
const SignUpForm = ({
	loading,
	preselectedParticipantTypeTechName,
	participantTypes = [],
	form,
	description,
	title,
	eyebrow,
	errors,
}: SignUpFormProps) => {
	const dispatch = useAppDispatch();

	const participantTypesSelectOptions = participantTypes?.map((participantType) => ({
		label: participantType.name,
		value: participantType.tech_name,
	}));

	const handleSubmitSignUpForm = async (values: SignUpFormValues) => {
		const resolvedAction = await dispatch(
			signUpParticipant({
				first_name: values.first_name,
				last_name: values.last_name,
				email: values.email,
				participant_tech_name: values.participant_tech_name,
			})
		);

		// @ts-ignore
		if (resolvedAction.type === signUpUserFailed.type) return resolvedAction.payload;
	};

	const fieldValidators = {
		first_name: [validators.required],
		last_name: [validators.required],
		email: [validators.email],
	};

	const initFormState = (
		participantTypes: ParticipantType[],
		preselectedParticipantTypeTechName: string | undefined
	) => {
		if (preselectedParticipantTypeTechName === undefined)
			return { participant_tech_name: participantTypesSelectOptions?.[0]?.value };

		const validTechName = participantTypes?.find((pt) => pt.tech_name === preselectedParticipantTypeTechName);

		if (validTechName) return { participant_tech_name: preselectedParticipantTypeTechName };

		return { participant_tech_name: participantTypesSelectOptions?.[0]?.value };
	};

	const validTechName =
		participantTypes?.find((pt) => pt.tech_name === preselectedParticipantTypeTechName) !== undefined;

	const showParticipantTypeSelector = participantTypes?.length !== 1 && !validTechName;

	return (
		<Container maxWidth="sm">
			<Stack direction="column" spacing={1} sx={{ marginBottom: 4 }}>
				<Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
					{eyebrow}
				</Typography>
				<Typography component="h1" variant="h1" sx={{ wordBreak: 'break-word' }}>
					{title}
				</Typography>
				<Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
					{description}
				</Typography>
			</Stack>

			<Form
				onSubmit={handleSubmitSignUpForm}
				initialValues={initFormState(participantTypes, preselectedParticipantTypeTechName)}
				validate={(values) => validators.validate(fieldValidators, values)}
			>
				{({
					handleSubmit,
					pristine,
					submitSucceeded,
					submitError,
					submitting,
					submitFailed,
					dirtySinceLastSubmit,
				}) => {
					return (
						<form onSubmit={handleSubmit} id="sign-up-form">
							<Grid container spacing={2}>
								{submitError && (
									<Grid item xs={12}>
										<FormError message={submitError} />
									</Grid>
								)}
								<Grid item xs={12} sm={6}>
									<RandevuInputLabel text={form?.field_first_name?.label} htmlFor="firstName" />
									<TextField
										id="firstName"
										name="first_name"
										placeholder={form?.field_first_name?.placeholder}
										variant="outlined"
										margin="normal"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<RandevuInputLabel text={form?.field_last_name?.label} htmlFor="lastName" />
									<TextField
										id="lastName"
										name="last_name"
										placeholder={form?.field_last_name?.placeholder}
										variant="outlined"
										margin="normal"
									/>
								</Grid>
								<Grid item xs={12}>
									<RandevuInputLabel text={form?.field_email?.label} htmlFor="email" />
									<TextField
										id="email"
										name="email"
										placeholder={form?.field_email?.placeholder}
										variant="outlined"
										margin="normal"
										autoComplete="email"
									/>
								</Grid>
								{showParticipantTypeSelector && (
									<Grid item xs={12}>
										<RandevuInputLabel
											text={form?.field_participant_type?.label}
											htmlFor="participant_tech_name"
										/>
										<br />
										<br />
										{participantTypesSelectOptions ? (
											form?.field_participant_type?.input_control ===
												FoParticipantTypeInputControlsKind.Dropdown ? (
												<Select
													name="participant_tech_name"
													data={participantTypesSelectOptions}
													disabled={loading}
												/>
											) : (
												<Radios
													name="participant_tech_name"
													required={true}
													data={participantTypesSelectOptions}
												/>
											)
										) : (
											<></>
										)}
									</Grid>
								)}
								<Grid item xs={12} marginTop={2} paddingLeft={1}>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={4}
									>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={0}
										>
											<Typography variant="body1" component="span">
												{form?.redirect_to_sign_in?.text}
											</Typography>
											<Button
												variant="text"
												color="primary"
												sx={{ fontWeight: 800 }}
												component={RouterLink}
												to={routeBuilder(ROUTES.SIGN_IN)}
											>
												{form?.redirect_to_sign_in?.link_label}
											</Button>
										</Stack>
										<LoadingButton
											type="submit"
											loading={submitting}
											disabled={submitting || (submitFailed && !dirtySinceLastSubmit)}
											fullWidth={false}
										>
											{form?.button_submit?.label}
										</LoadingButton>
									</Stack>
								</Grid>
							</Grid>
						</form>
					);
				}}
			</Form>
		</Container>
	);
};

export default SignUpForm;
