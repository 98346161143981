import * as participantMutations from './participantMutations';
import {
	MutationCloseMyParticipantAccountArgs,
	MutationUpdateParticipantArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

class ParticipantsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async updateParticipant({
		id,
		fields,
	}: MutationUpdateParticipantArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = participantMutations.updateParticipant({ id, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { updated: data?.updateParticipant, errors };
	}
	async closeMyParticipantAccount({
		id_participant,
		current_password,
	}: MutationCloseMyParticipantAccountArgs): Promise<{ closed: boolean; errors: any }> {
		const payload = participantMutations.closeMyParticipantAccount({ id_participant, current_password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { closed: data?.closeMyParticipantAccount ?? false, errors };
	}
}

export default ParticipantsService;
