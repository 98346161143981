import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { capitalize } from 'lodash';

import Typography from '@mui/material/Typography';

import { selectLoading, loadMySingleDirectTransactions, selectMySingleDirectTransactions, selectMySingleDirectTransactionsPaginationMetadata, loadMyTransactionTypes, selectTransactionTypes, loadMySingleDirectTransactionsForProvider } from '@rdv-fo/store/slices/transactionSlice';
import { Box, Stack, } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import { useParams } from 'react-router';
import SingleDirectTransactionsForConsumerTable from '@rdv-fo/components/transaction/single-direct/SingleDirectTransactionsForConsumerTable';
import { DEFAULT_PAGE_SIZE, PaginationOperationKind } from '@rdv-fo/app/lib/tableHelpers';
import { MatchTypeRoleKind, SingleDirectTransactionForConsumer, SingleDirectTransactionForProvider, TransactionType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import SingleDirectTransactionsForProviderTable from '@rdv-fo/components/transaction/single-direct/SingleDirectTransactionsForProviderTable';

const MySingleDirectTransactionsPage = () => {
    const dispatch = useAppDispatch();
    const { transactionTypeTechName } = useParams<{ transactionTypeTechName: string }>();
    const myTransactions = useAppSelector(selectMySingleDirectTransactions);
    const paginationMetadata = useAppSelector(selectMySingleDirectTransactionsPaginationMetadata);
    const loading = useAppSelector(selectLoading);
    const transactionTypes = useAppSelector(selectTransactionTypes);
    const currentTransactionType = transactionTypes.find((tt: TransactionType) => tt.tech_name == transactionTypeTechName);

    const pageInfo = paginationMetadata?.page_info;
    const hasNextPage = pageInfo?.has_next_page;
    const hasPreviousPage = pageInfo?.has_previous_page;
    const pageStartCursor = pageInfo?.start_cursor ?? null;
    const pageEndCursor = pageInfo?.end_cursor ?? null;

    const participantRole = transactionTypes?.find((tt: TransactionType) => tt.tech_name == transactionTypeTechName)?.my_role;
    const isConsumer = participantRole == MatchTypeRoleKind.Consumer

    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [paginationCursor, setPaginationCursor] = useState<string | null>(null);
    const [paginationOperation, setPaginationOperation] = useState<PaginationOperationKind>(
        PaginationOperationKind.after
    );

    const handleNextPage = () => {
        setPaginationCursor(pageEndCursor);
        setPaginationOperation(PaginationOperationKind.after);
        setCurrentPageIndex((previous) => previous + 1);
    };
    const handlePreviosPage = () => {
        setPaginationCursor(pageStartCursor);
        setPaginationOperation(PaginationOperationKind.before);
        setCurrentPageIndex((previous) => previous - 1);
    };

    useEffect(() => {
        dispatch(loadMyTransactionTypes({ asGuest: false }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadMyTransactionTypes({ asGuest: false }));
        if (isConsumer && transactionTypes)
            dispatch(loadMySingleDirectTransactions({ where: { transaction_tech_name: transactionTypeTechName } }));
        else if (transactionTypes)
            dispatch(loadMySingleDirectTransactionsForProvider({ where: { transaction_tech_name: transactionTypeTechName } }));
    }, [dispatch, transactionTypes?.length]);

    useEffect(() => {
        if (paginationCursor && isConsumer)
            dispatch(
                loadMySingleDirectTransactions({
                    where: {
                        transaction_tech_name: transactionTypeTechName,
                    },
                    ...(paginationOperation == PaginationOperationKind.before && {
                        before: paginationCursor,
                        last: DEFAULT_PAGE_SIZE,
                    }),
                    ...(paginationOperation == PaginationOperationKind.after && {
                        after: paginationCursor,
                        first: DEFAULT_PAGE_SIZE,
                    }),
                } as any)
            );
        else if (paginationCursor)
            dispatch(
                loadMySingleDirectTransactionsForProvider({
                    where: {
                        transaction_tech_name: transactionTypeTechName,
                    },
                    ...(paginationOperation == PaginationOperationKind.before && {
                        before: paginationCursor,
                        last: DEFAULT_PAGE_SIZE,
                    }),
                    ...(paginationOperation == PaginationOperationKind.after && {
                        after: paginationCursor,
                        first: DEFAULT_PAGE_SIZE,
                    }),
                } as any)
            );
    }, [paginationCursor, paginationOperation]);

    return (
        <>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                <BackButton />
                <Typography component="h1" variant="h1">
                    {capitalize(`Manage ${currentTransactionType?.name?.toLowerCase()}`)}
                </Typography>
            </Stack>
            <Box sx={{ mt: 5 }}>
                {isConsumer ?
                    <SingleDirectTransactionsForConsumerTable loading={loading} transactions={myTransactions as SingleDirectTransactionForConsumer[]} transactionTypeTechName={transactionTypeTechName} totalCount={paginationMetadata?.total_count ?? 0}
                        currentPageIndex={currentPageIndex}
                        hasNextPage={Boolean(hasNextPage)}
                        hasPreviousPage={Boolean(hasPreviousPage)}
                        onNextPage={handleNextPage}
                        onPreviousPage={handlePreviosPage} />
                    :
                    <SingleDirectTransactionsForProviderTable loading={loading} transactions={myTransactions as SingleDirectTransactionForProvider[]} transactionTypeTechName={transactionTypeTechName} totalCount={paginationMetadata?.total_count ?? 0}
                        currentPageIndex={currentPageIndex}
                        hasNextPage={Boolean(hasNextPage)}
                        hasPreviousPage={Boolean(hasPreviousPage)}
                        onNextPage={handleNextPage}
                        onPreviousPage={handlePreviosPage} />
                }
            </Box>
        </>
    );
};

export default MySingleDirectTransactionsPage;
