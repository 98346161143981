import { Chip as MuiChip, Skeleton } from '@mui/material/';

import { ConnectionStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import getEnumKeyByValue from '@rdv-fo/app/lib/getEnumKeyByValue';
import useChipStyles from '@rdv-fo/components/common/chip/chipColors';

interface ConnectionStatusChipProps {
	status?: ConnectionStatusKind;
	loading?: boolean;
	size?: 'small' | 'medium';
}
const ConnectionStatusChip = ({ status, loading = false, size = 'medium' }: ConnectionStatusChipProps) => {
	const classes = useChipStyles();

	if (loading) return <Skeleton variant="rounded" width={80} height={35} />;

	let colorClass;
	let value;

	switch (status) {
		case ConnectionStatusKind.Establishing:
			colorClass = classes.blue;
			value = getEnumKeyByValue(ConnectionStatusKind.Establishing, ConnectionStatusKind);
			break;

		case ConnectionStatusKind.Declined:
			colorClass = classes.red;
			value = getEnumKeyByValue(ConnectionStatusKind.Declined, ConnectionStatusKind);
			break;

		case ConnectionStatusKind.Rejected:
			colorClass = classes.red;
			value = getEnumKeyByValue(ConnectionStatusKind.Rejected, ConnectionStatusKind);
			break;

		case ConnectionStatusKind.Established:
			colorClass = classes.green;
			value = getEnumKeyByValue(ConnectionStatusKind.Established, ConnectionStatusKind);
			break;

		default:
			colorClass = classes.gray;
			value = 'Unknown';
	}

	const customClasses = `${colorClass}`;

	return <MuiChip label={value} className={customClasses} size={size} />;
};

export default ConnectionStatusChip;
