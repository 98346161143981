import {
	InputKind,
	MatchingToolKind,
	ShoppingCartLight,
	SingleDirectTransactionForConsumer,
	SingleDirectTransactionForProvider,
	ShoppingCartOrderLight,
	CurrencyKind,
	TransactionStatusKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RdvCellValue } from './tableHelpers';
// FIXME: set proper type
export const isTransactionDirectMatcher = (trx: any) => trx?.root_object?.type?.type === MatchingToolKind.DirectMatcher;

// FIXME: set proper type
export const isTransactionMatchingOrder = (trx: any) => trx?.root_object?.type?.type === MatchingToolKind.MatchingOrder;

export type SingleDirectTransactionRow = {
	_id: RdvCellValue;
	created_at: RdvCellValue;
	initiated_at: RdvCellValue;
	cancelled_at: RdvCellValue;
	terminated_at: RdvCellValue;
	status: { value?: string | null; input_type: InputKind };
};
export type SingleDirectTransactionForConsumerColumns = {
	_id: RdvCellValue;
	created_at: RdvCellValue;
	initiated_at: RdvCellValue;
	terminated_at: RdvCellValue;
	cancelled_at: RdvCellValue;
	status: { value?: string | null; input_type: InputKind };
};
export type SingleDirectTransactionForProviderColumns = {
	_id: RdvCellValue;
	created_at: RdvCellValue;
	initiated_at: RdvCellValue;
	terminated_at: RdvCellValue;
	cancelled_at: RdvCellValue;
	status: { value?: string | null; input_type: InputKind };
};

export interface MultiDirectTransactionRow {
	_id: string;
	created_at: string;
	initiated_at?: string;
	terminated_at?: string;
	cancelled_at: string;
	state?: string | null;
}

export type MultiDirectMatchesRow = {
	_id: string;
	created_at: string;
	initiated_at?: string;
	terminated_at?: string;
	cancelled_at: string;
	state?: string | null;
	gross_value?: number | null;
	currency?: CurrencyKind | null;
};

export class TransactionMappers {
	static toSingleDirectTransactionRowForConsumer(
		transactions: SingleDirectTransactionForConsumer[]
	): SingleDirectTransactionRow[] {
		const rows = transactions?.map((transaction) => {
			return {
				_id: { value: transaction.id, input_type: InputKind.Text },
				created_at: { value: transaction.created_at, input_type: InputKind.Datetime },
				initiated_at: { value: transaction.initiated_at, input_type: InputKind.Datetime },
				terminated_at: { value: transaction.terminated_at, input_type: InputKind.Datetime },
				cancelled_at: { value: transaction.cancelled_at, input_type: InputKind.Datetime },
				status: { value: transaction.state?.status, input_type: InputKind.Text },
			};
		});

		return rows;
	}
	static toSingleDirectTransactionRowForProvider(
		transactions: SingleDirectTransactionForProvider[]
	): SingleDirectTransactionRow[] {
		const rows = transactions?.map((transaction) => {
			return {
				_id: { value: transaction.id, input_type: InputKind.Text },
				created_at: { value: transaction.created_at, input_type: InputKind.Datetime },
				initiated_at: { value: transaction.initiated_at, input_type: InputKind.Datetime },
				terminated_at: { value: transaction.terminated_at, input_type: InputKind.Datetime },
				cancelled_at: { value: transaction.cancelled_at, input_type: InputKind.Datetime },
				status: { value: transaction.state?.status, input_type: InputKind.Text },
			};
		});

		return rows;
	}

	static toMultiDirectTransactionRow(transactions: ShoppingCartLight[]): MultiDirectTransactionRow[] {
		const rows = transactions?.map((transaction) => {
			return {
				_id: transaction.id,
				created_at: transaction.created_at,
				initiated_at: transaction.initiated_at,
				terminated_at: transaction.terminated_at,
				cancelled_at: transaction.cancelled_at,
				state: transaction.state?.status,
			};
		});

		return rows;
	}
	static toMultiDirectMatchesRow(transactions: ShoppingCartOrderLight[]): MultiDirectMatchesRow[] {
		const rows = transactions?.map((transaction) => {
			return {
				_id: transaction.id,
				created_at: transaction.created_at,
				initiated_at: transaction.initiated_at,
				terminated_at: transaction.terminated_at,
				cancelled_at: transaction.cancelled_at,
				state: transaction.state?.status,
				gross_value: transaction.order?.gross_value,
				currency: transaction.order?.currency,
			};
		});

		return rows;
	}
}
