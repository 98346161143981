import gql from 'graphql-tag';
import { print } from 'graphql';

const QUERY_ME = gql`
	query ME {
		me {
			id
			email
			first_name
			last_name
			password_updated_at
		}
	}
`;

const getCurrentUser = () => JSON.stringify({ query: print(QUERY_ME) });

const QUERY_MY_PARTICIPANTS = gql`
	query MY_PARTICIPANTS {
		myParticipants {
			id
			type {
				id
				tech_name
				name
			}
			status
			account {
				merchant_accounts {
					id
					payment_provider_kind
					id_psp_account
					data
				}
			}
			id_onboarding_flow
			onboarding_progress {
				status
				status_tech_name
				message
				next_steps {
					type
					config
					action_message
				}
			}
			fields {
				... on SimpleField {
					simpleValue: value
					my_access
					field_type {
						input_options
						id
						name
						tech_name
						input_type
						category
						is_required
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						conditions {
							type
							payload
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									is_required
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						is_required
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					my_access
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								my_access
								field_type {
									id
									name
									tech_name
									input_type
									is_required
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
									input_options
									conditions {
										type
										payload
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						is_required
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
						input_options
						conditions {
							type
							payload
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}
			}
		}
	}
`;

const getMyParticipants = () => JSON.stringify({ query: print(QUERY_MY_PARTICIPANTS) });

const VERIFY_VALID_SESSION = gql`
	query VERIFY_VALID_SESSION {
		me {
			id
		}
	}
`;

const verifyValidSession = () => JSON.stringify({ query: print(VERIFY_VALID_SESSION) });

export { getCurrentUser, verifyValidSession, getMyParticipants };
