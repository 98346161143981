import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';

import { Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';

import { goToHostedPaymentCheckout, selectLoading } from '@rdv-fo/store/slices/paymentSlice';
import { LoadingButtonAdvance } from '@rdv-fo/components/common/LoadingButton';
import { buildBackUrlForSuccessfulPayment, buildBackUrlForFailedPayment } from '@rdv-fo/app/lib/paymentUrlGenerator';
import { TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface RedirectToHostedStripePaymentCheckoutProps {
	paymentId: string;
	transactionId: string;
	transactionTechName: string;
	redirectBackTo: TransactionKind.SingleDirect | TransactionKind.MultipleDirect | TransactionKind.ReverseAuction

}
const RedirectToHostedStripePaymentCheckout = ({ paymentId, transactionId, transactionTechName, redirectBackTo }: RedirectToHostedStripePaymentCheckoutProps) => {
	const dispatch = useAppDispatch();
	const obtainingLink = useAppSelector(selectLoading);

	const handleGoToPaymentCheckout = async (values: any) => {
		const successUrl = buildBackUrlForSuccessfulPayment({ transactionId, transactionTechName, paymentId, redirectBackTo });
		const cancelUrl = buildBackUrlForFailedPayment({ transactionId, transactionTechName, paymentId, redirectBackTo });

		await dispatch(
			goToHostedPaymentCheckout({ id_payment: paymentId, success_url: successUrl, cancel_url: cancelUrl })
		);
	};

	return (

		<Stack
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			spacing={4}
		>

			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={1}
			>
				<div style={{ display: 'flex', alignItems: 'center', gap: 10, }}>
					<LockIcon fontSize="small" />
					<Typography>Secure Payment</Typography>
				</div>

				<Typography color="textSecondary">
					Your payment is secured by an industry best encryption service – Stripe
				</Typography>
			</Stack>

			<LoadingButtonAdvance
				loading={obtainingLink}
				disabled={obtainingLink}
				variant="contained"
				color="primary"
				endIcon={<ArrowForwardIcon />}
				sx={{ textTransform: 'none', fontWeight: 600, fontSize: '0.875rem' }}
				onClick={handleGoToPaymentCheckout}
			>
				Go to payment
			</LoadingButtonAdvance>
		</Stack>
	);
};

export default RedirectToHostedStripePaymentCheckout;
