import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import TransactionStatusChip from '../TransactionStatusChip';
import { Box, Stack } from '@mui/material';
import { forceSingleDirectTransactionTerminationConsumer, forceSingleDirectTransactionTerminationProvider, forceTransactionTermination } from '@rdv-fo/store/slices/transactionSlice';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import BackButton from '@rdv-fo/components/common/BackButton';
import { formatDateTimeHumanFriendly } from '@rdv-fo/app/lib/datetime';
import { MatchTypeRoleKind, TransactionStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface TransactionHeaderProps {
    transactionTechName: string,
    transactionId: string,
    hasTransactionBeenTerminatedForecdly: boolean,
    isForceTerminationEnabled: boolean,
    createdAt: string,
    startedAt: string,
    terminatedAt: string,
    cancelledAt: string,
    status: TransactionStatusKind,
    participantRole: MatchTypeRoleKind
}

const SingleDirectTransactionHeader = ({
    transactionTechName,
    transactionId,
    hasTransactionBeenTerminatedForecdly,
    isForceTerminationEnabled,
    createdAt,
    startedAt,
    terminatedAt,
    cancelledAt,
    status,
    participantRole
}: TransactionHeaderProps) => {
    const dispatch = useAppDispatch();

    const handleTerminateTransaction = () => {
        if (participantRole == MatchTypeRoleKind.Consumer)
            dispatch(forceSingleDirectTransactionTerminationConsumer({ id_transaction: transactionId }));
        else if (participantRole == MatchTypeRoleKind.Provider)
            dispatch(forceSingleDirectTransactionTerminationProvider({ id_transaction: transactionId }));
    };

    const isTerminated = terminatedAt !== null && terminatedAt !== undefined;

    const formattedCreatedAt = formatDateTimeHumanFriendly(createdAt);
    const formattedStartedAt = formatDateTimeHumanFriendly(startedAt);
    const formattedTerminatedAt = formatDateTimeHumanFriendly(terminatedAt);

    return (
        <Grid id="transaction-header" container component="section">
            <Grid item xs={12}>
                <BackButton label="Back" toVariant="native" />
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center" spacing={4}>
                    <Grid item xs={12} sm={8} alignItems="center" gap={2}>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                            <Typography component="h1" variant="h1">
                                Transaction Details
                            </Typography>
                            <TransactionStatusChip
                                status={status}
                                createdAt={createdAt}
                                startedAt={startedAt}
                                terminatedAt={terminatedAt}
                                cancelledAt={cancelledAt}
                                size="medium"
                            />

                            <Box>
                                {!hasTransactionBeenTerminatedForecdly &&
                                    isForceTerminationEnabled &&
                                    !isTerminated && (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleTerminateTransaction()}
                                        >
                                            Terminate
                                        </Button>
                                    )}
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems="center" gap={5}>
                        {/* <Stack direction="row" spacing={1}>
							<Typography color="textSecondary">Created at</Typography>
							<Typography>{formattedCreatedAt}</Typography>
						</Stack> */}
                        <Stack direction="row" spacing={1}>
                            <Typography color="textSecondary">Started on</Typography>
                            <Typography>{formattedStartedAt}</Typography>
                        </Stack>
                        {isTerminated && (
                            <Stack direction="row" spacing={1}>
                                <Typography color="textSecondary">Finished at</Typography>
                                <Typography>{formattedTerminatedAt}</Typography>
                            </Stack>
                        )}
                    </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary">
                    {transactionId}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default SingleDirectTransactionHeader;
