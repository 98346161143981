import { Typography } from '@mui/material';
import { RdvObjectSetFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldObjectSetCellViewProps {
	value: RdvObjectSetFieldValue;
}
const FieldObjectSetCellView = ({ value }: FieldObjectSetCellViewProps) => {
	if (value === null || value === undefined || value?.length === 0)
		return <Typography variant="body2">n.a.</Typography>;

	return <Typography variant="body2">{`${value.length} object(s)`}</Typography>;
};

export default FieldObjectSetCellView;
