import React from 'react';
import 'typeface-inter';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import '@rdv-fo/styles/index.css';
import App from './App';

import { store, persistor } from '@rdv-fo/store/configureStore';

const ReduxApp = () => {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</BrowserRouter>
	);
};

ReactDOM.render(<ReduxApp />, document.getElementById('root'));
