import { Checkboxes } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { FieldInputBaseProps } from './types';

import LabelFieldInput from './LabelFieldInput';
import { Stack, useTheme } from '@mui/material';

interface MultiSelectInputOptions {
	required: boolean;
	minSelected?: number;
	maxSelected?: number;
}

const validate = (value: string, options: MultiSelectInputOptions) => {
	const selectedValuesCount = value?.length || 0;

	const { minSelected, maxSelected, required } = options;

	const minimumSelected = required ? Math.max(minSelected ?? 0, 1) : minSelected;

	if (selectedValuesCount === 0 && required) return VALIDATION_MESSAGE.NOTHING_SELECTED_VIOLATION;

	if (minimumSelected && selectedValuesCount < minimumSelected)
		return VALIDATION_MESSAGE.MULTI_SELECT_MIN_SELECTED_VIOLATION.replace('{min_selected}', String(minSelected));

	if (maxSelected && selectedValuesCount > maxSelected)
		return VALIDATION_MESSAGE.MULTI_SELECT_MAX_SELECTED_VIOLATION.replace('{max_selected}', String(maxSelected));

	return undefined;
};

interface FieldInputMultiSelectProps extends FieldInputBaseProps {
	options?: string[];
	optionsUiConfig?: { label: string, value: string }[];
	minSelected?: number;
	maxSelected?: number;
}

const FieldInputMultiSelect = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	options,
	optionsUiConfig,
	minSelected,
	maxSelected,
}: FieldInputMultiSelectProps) => {

	let checkboxData = options?.map((opt) => {
		const label = optionsUiConfig?.find((optionConfig) => optionConfig.value === opt)?.label;
		return { label: label ?? opt, value: opt }
	});

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>

			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>
			<Checkboxes
				id={techName}
				name={techName}
				disabled={disabled}
				helperText={helperText}
				fieldProps={{
					validate: (value) => validate(value, { required, minSelected, maxSelected }),
				}}
				data={checkboxData ?? []}
				size="small"
			/>
		</Stack>
	);
};

export default FieldInputMultiSelect;
