import Typography from '@mui/material/Typography';

interface FieldValueAutoIncrementIdProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueAutoIncrementId= ({ fieldTechName, value, className }: FieldValueAutoIncrementIdProps) => {
	return (
		<Typography id={fieldTechName} className={className}>
			{value === null ? 'n.a.' : value}
		</Typography>
	);
};

export default FieldValueAutoIncrementId;
