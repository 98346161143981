import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButtonAdvance } from '@rdv-fo/components/common/LoadingButton';
import FieldDisplayValue from '../fields/display/FieldDisplayValue';
import TransactionMatchOverview from './TransactionMatchOverview';
import { acceptTransactedMatch, declineTransactedMatch, updateTransactedMatch } from '@rdv-fo/store/slices/matchSlice';
import { loadMyTransaction } from '@rdv-fo/store/slices/transactionSlice';
import { prepareTransactionPayment, selectLoading as preparingPayment } from '@rdv-fo/store/slices/paymentSlice';
import { TRANSACTION_STATUS } from '@rdv-fo/services/randevuApi';
import {
	RandevuEventKind,
	FieldCategoryKind,
	FieldAccessKind,
	MatchTypeRoleKind,
	NextStepKind,
	TransactionKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { sleep } from '@rdv-fo/services/sleep';
import OnboardingForm from '@rdv-fo/components/common/OnboardingForm';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';
import { mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { Box, Stack, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import ConditionalFieldDisplay from '../fields/display/ConditionalFieldDisplay';
import TransactionPaymentCheckoutButton from '@rdv-fo/components/transaction/TransactionPaymentCheckoutButton';

const useSupplyDataStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		featureImageFigure: {
			height: '350px',
			width: '100%',
			margin: 0,
			marginBottom: theme.spacing(3),
		},
		featureImage: {
			borderRadius: 15,
			height: '100%',
			backgroundRepeat: 'no-repeat',
			[theme.breakpoints.down('md')]: {
				backgroundSize: 'contain', // Mobile
			},
		},
	};
});

const SupplyData = ({ supplyFields, supplyTypeName }) => {
	const classes = useSupplyDataStyles();

	const fieldName = findFieldByCategory(supplyFields, FieldCategoryKind.Name);
	const fieldDescription = findFieldByCategory(supplyFields, FieldCategoryKind.Description);
	const fieldMainImage = findFieldByCategory(supplyFields, FieldCategoryKind.MainImage);
	const fieldPrice = findFieldByCategory(supplyFields, FieldCategoryKind.BasePrice);

	const dominantFields = [
		fieldDescription?.field_type?.id,
		fieldName?.field_type?.id,
		fieldMainImage?.field_type?.id,
		fieldPrice?.field_type?.id,
	];

	const discoveredSupplyRegularFields = supplyFields?.filter(
		(field) => !dominantFields.includes(field?.field_type?.id)
	);

	const discoveredSupplyRegularFieldValues = {};
	discoveredSupplyRegularFields?.forEach((field) =>
		Object.assign(discoveredSupplyRegularFieldValues, { [field?.field_type?.tech_name]: field?.value })
	);

	return (
		<>
			<figure className={classes.featureImageFigure}>
				<CardMedia
					image={fieldMainImage?.value?.url || '/'}
					alt={fieldName?.value}
					className={classes.featureImage}
				/>
			</figure>
			<Typography component="h3" variant="subtitle1" color="textSecondary" gutterBottom>
				{supplyTypeName}
			</Typography>
			<Typography component="h2" variant="h4" color="primary" gutterBottom>
				{fieldName?.value}
			</Typography>
			<Typography>{fieldDescription?.value}</Typography>
			<br />
			<br />

			{discoveredSupplyRegularFields?.map((field) => {
				return (
					<Grid key={field?.field_type.tech_name} container>
						<ConditionalFieldDisplay
							key={field?.field_type?.tech_name}
							fields={discoveredSupplyRegularFields}
							values={discoveredSupplyRegularFieldValues}
							conditions={field?.field_type?.conditions ?? []}
						>
							<FieldDisplayValue
								label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
								fieldTechName={field?.field_type.tech_name}
								optionsUiConfig={field?.field_type?.ui_config?.options}
								inputType={field?.field_type.input_type}
								inputOptions={field?.field_type.input_options}
								value={field.value}
							/>
						</ConditionalFieldDisplay>
					</Grid>
				);
			})}
		</>
	);
};

const useParticipantActionsStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		awaitingAcceptance: {
			display: 'flex',
			gap: 5,
			color: 'rgb(13, 60, 97)',
			backgroundColor: 'rgb(232, 244, 253)',
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			fontSze: '0.875rem',
			fontWight: 400,
			borderRadius: '4px',
			letterSpacing: '0.01071em',
			alignItems: 'center',
		},
		infoIconAcceptance: {
			color: 'rgb(13, 60, 97)',
		},
		awaitingBackofficeApproval: {
			display: 'flex',
			gap: 5,
			color: 'rgb(102, 60, 0)',
			backgroundColor: 'rgb(255, 244, 229)',
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			fontSze: '0.875rem',
			fontWight: 400,
			borderRadius: '4px',
			letterSpacing: '0.01071em',
			alignItems: 'center',
		},
		infoIconBackofficeApproval: {
			color: 'rgb(102, 60, 0)',
		},
		declinedOrRejectedMatch: {
			display: 'flex',
			gap: 5,
			color: 'rgb(95, 33, 32)',
			backgroundColor: 'rgb(255, 217, 213)',
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			fontSze: '0.875rem',
			fontWight: 400,
			borderRadius: '4px',
			letterSpacing: '0.01071em',
			alignItems: 'center',
		},
		infoIconDeclinedOrRejectedMatch: {
			color: 'rgb(95, 33, 32)',
		},
	};
});

const ParticipantActions = ({
	parentTransactionId,
	transactionId,
	transactionTypeTechName,
	transactionStatus,
	matchId,
	paymentTypes,
	participantMatchRole,
	nextSteps,
	backofficeApprovedAt,
	backofficeRejectedAt,
	consumerAcceptedAt,
	providerAcceptedAt,
	consumerDeclinedAt,
	providerDeclinedAt,
	className,
}) => {
	const dispatch = useAppDispatch();
	const classes = useParticipantActionsStyles();

	const hasMatchAcceptedOrClosedStep = nextSteps?.find((ns) =>
		[RandevuEventKind.MatchAccepted, RandevuEventKind.MatchClosed].includes(ns.config.event)
	);

	const hasMatchDeclinedStep = nextSteps?.find((step) => step.config.event === RandevuEventKind.MatchDeclined);
	const isPaymentStepAvailable = nextSteps?.find((step) => step.config?.event === RandevuEventKind.PaymentPrepared);

	const isConsumer = participantMatchRole === MatchTypeRoleKind.Consumer;
	const isTransactionPaid = TRANSACTION_STATUS.PAID.value === transactionStatus;

	const isMatchDeclined = providerDeclinedAt !== null || consumerDeclinedAt !== null;
	const isMatchRejected = backofficeRejectedAt !== null;

	const awaitingConsumerDecision =
		consumerAcceptedAt === null && consumerDeclinedAt === null && !(isMatchDeclined || isMatchRejected);

	const awaitingProviderDecision =
		providerAcceptedAt === null && providerDeclinedAt === null && !(isMatchDeclined || isMatchRejected);

	const awaitingBackofficeApproval =
		backofficeApprovedAt === null && backofficeRejectedAt === null && !(isMatchDeclined || isMatchRejected);

	const handleAcceptMatch = async (transactionId) => {
		await dispatch(acceptTransactedMatch({ id_transaction: transactionId }));
		await sleep(100);
		dispatch(loadMyTransaction({ id_transaction: transactionId }));
	};

	const handleDeclineMatch = async (transactionId) => {
		await dispatch(declineTransactedMatch({ id_transaction: transactionId }));
		await sleep(100);
		dispatch(loadMyTransaction({ id_transaction: transactionId }));
	};

	return (
		<Grid container spacing={2} className={className}>
			<Grid item xs={12}>
				<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
					{hasMatchDeclinedStep &&
					((isConsumer && awaitingConsumerDecision) || (!isConsumer && awaitingProviderDecision)) ? (
						<Grid item>
							<LoadingButtonAdvance
								variant="outlined"
								color="primary"
								style={{ gap: 10 }}
								onClick={() => handleDeclineMatch(transactionId)}
								disabled={false}
								loading={false}
							>
								Decline
							</LoadingButtonAdvance>
						</Grid>
					) : null}

					{hasMatchAcceptedOrClosedStep &&
					((isConsumer && awaitingConsumerDecision) || (!isConsumer && awaitingProviderDecision)) ? (
						<Grid item>
							<LoadingButtonAdvance
								variant="contained"
								color="primary"
								onClick={() => handleAcceptMatch(transactionId)}
								disabled={false}
								loading={false}
							>
								Accept
							</LoadingButtonAdvance>
						</Grid>
					) : null}
				</Grid>
			</Grid>

			{isMatchDeclined && (
				<Grid item xs={12}>
					<div className={classes.declinedOrRejectedMatch}>
						<InfoOutlinedIcon className={classes.infoIconDeclinedOrRejectedMatch} />
						{isConsumer && consumerDeclinedAt !== null && <Typography>You declined</Typography>}
						{isConsumer && providerDeclinedAt !== null && <Typography>Provider declined</Typography>}
						{!isConsumer && consumerDeclinedAt !== null && <Typography>Consumer declined</Typography>}
						{!isConsumer && providerDeclinedAt !== null && <Typography>You declined</Typography>}
					</div>
				</Grid>
			)}
			{isMatchRejected && (
				<Grid item xs={12}>
					<div className={classes.declinedOrRejectedMatch}>
						<InfoOutlinedIcon className={classes.infoIconDeclinedOrRejectedMatch} />
						<Typography>Rejected by the platform</Typography>
					</div>
				</Grid>
			)}

			{isPaymentStepAvailable && (
				<Box pt={2}>
					{(paymentTypes?.length ?? 0) === 0 && (
						<Typography>
							Cannot continue to payment because no payment type is assigned to current transaction type.
						</Typography>
					)}

					{(paymentTypes?.length ?? 0) >= 1 && (
						<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
							{paymentTypes?.map((pt) => {
								const btnLabel =
									`${isPaymentStepAvailable?.action_message} ${pt.name?.toLowerCase()}` ?? 'Checkout';
								const acceptedPaymentMethods = pt.payment_methods?.[0]?.accepted_methods ?? [];

								return (
									<TransactionPaymentCheckoutButton
										key={pt.tech_name}
										redirectBackTo={TransactionKind.ReverseAuction}
										transactionTechName={transactionTypeTechName}
										label={btnLabel}
										transactionId={transactionId}
										acceptedPaymentMethods={acceptedPaymentMethods}
										paymentTypeTechName={pt.tech_name}
									/>
								);
							})}
						</Stack>
					)}
				</Box>
			)}
		</Grid>
	);
};

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		root: {
			width: '100%',
			marginTop: theme.spacing(4),
		},
		title: {
			fontWeight: 700,
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(5),
		},
		participantActions: {
			marginTop: theme.spacing(3),
		},
		marginBottom: {
			marginBottom: theme.spacing(4),
		},
	};
});

const TransactionMatch = ({
	parentTransactionTypeTechName,
	transactionTypeTechName,
	transactionId,
	parentTransactionId,
	transactionStatus,
	transactionNextSteps,
	matchId,
	matchingToolFields,
	matchFields,
	matchPrice,
	matchPriceOverrideEnabled,
	participantMatchRole,
	availabilityRecord,
	supplyFields,
	supplyTypeName,
	providerFields,
	consumerFields,
	backofficeApprovedAt,
	backofficeRejectedAt,
	consumerAcceptedAt,
	providerAcceptedAt,
	consumerDeclinedAt,
	providerDeclinedAt,
	isMatchConfigurationPricingEnabled,
	paymentTypes,
}) => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const sharedObjects = useAppSelector(selectMySharedObjects);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);
	const matchFieldsWriteAccess = matchFields?.filter((field) => field.my_access === FieldAccessKind.Write);

	const handleSubmitUpdateMatchForm = async (values, form) => {
		const dirtyFields = getDirtyFields({ ...values }, form);

		const fieldInputs = mapFormValuesToFieldInputs(
			dirtyFields,
			values,
			sharedObjectTypeIds,
			matchFieldsWriteAccess
		);

		return dispatch(
			updateTransactedMatch({
				id_transaction: transactionId,
				dirty_fields: fieldInputs,
				current_fields: matchFieldsWriteAccess,
			})
		);
	};
	const nextManualStepsExist =
		transactionNextSteps?.filter((nextStep) => nextStep.type === NextStepKind.ManualTrigger).length > 0;

	return (
		<>
			<Grid id="transaction-match" container className={classes.root} spacing={2}>
				<Grid
					item
					id="section-update-match"
					className={classes.marginBottom}
					component="section"
					xs={12}
					sm={8}
				>
					<OnboardingForm // FIXME: DO NOT USE ONBOARDING FORM IN HERE!
						fields={matchFields}
						submitText={nextManualStepsExist ? 'Save' : 'Continue'}
						sharedObjects={sharedObjects}
						sharedObjectTypes={sharedObjectTypes}
						onSubmit={handleSubmitUpdateMatchForm}
						showSubmitBtn={matchFieldsWriteAccess?.length}
						showOptional={Boolean(matchFieldsWriteAccess?.length)}
					/>
				</Grid>
				<Grid id="section-supply-data" item component="section" xs={12} sm={8}>
					<SupplyData supplyFields={supplyFields} supplyTypeName={supplyTypeName} />
				</Grid>
				<Grid id="section-match-overview" component="section" item xs={12} sm={4}>
					<TransactionMatchOverview
						transactionId={transactionId}
						requestFields={matchingToolFields}
						consumerFields={consumerFields}
						supplyFields={supplyFields}
						providerFields={providerFields}
						isParticipantConsumer={participantMatchRole === MatchTypeRoleKind.Consumer}
						availabilityRecord={availabilityRecord}
						matchPriceOverrideEnabled={matchPriceOverrideEnabled}
						matchPrice={matchPrice}
						isMatchConfigurationPricingEnabled={isMatchConfigurationPricingEnabled}
					/>
					<ParticipantActions
						parentTransactionId={parentTransactionId}
						transactionTypeTechName={parentTransactionTypeTechName ?? transactionTypeTechName}
						transactionId={transactionId}
						transactionStatus={transactionStatus}
						matchId={matchId}
						backofficeApprovedAt={backofficeApprovedAt}
						backofficeRejectedAt={backofficeRejectedAt}
						consumerAcceptedAt={consumerAcceptedAt}
						providerAcceptedAt={providerAcceptedAt}
						consumerDeclinedAt={consumerDeclinedAt}
						providerDeclinedAt={providerDeclinedAt}
						paymentTypes={paymentTypes}
						participantMatchRole={participantMatchRole}
						className={classes.participantActions}
						nextSteps={transactionNextSteps}
					/>
				</Grid>
				<Divider className={classes.divider} />
			</Grid>
		</>
	);
};

export default TransactionMatch;
