import { Stack, Typography } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { Referral, ReferralStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { useMemo } from 'react';
import ReferralStatusChip from './ReferralStatusChip';

type ReferralRowDefinition = {
	status: ReferralStatusKind;
	referred_participant_user: { id: string; first_name: string; last_name: string; email: string };
	referred_participant_id: string;
	referred_participant_type: { id: string; name: string };
	referral_date: string;
};

const buildReferralsTableRows = (referrals: Referral[] = []): ReferralRowDefinition[] => {
	const rows = referrals.map((referral) => {
		return {
			status: referral.status,
			referred_participant_type: {
				id: referral.referee.type?.id,
				name: referral.referee.type?.name,
			},
			referred_participant_id: referral.referee?.id,
			referred_participant_user: {
				id: referral.referee?.user.id,
				first_name: referral.referee?.user.first_name as string,
				last_name: referral.referee?.user.last_name as string,
				email: referral.referee?.user.email as string,
			},
			referral_date: referral.created_at as string,
		};
	});

	return rows;
};

const NoDataView = () => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
			<Typography variant="subtitle2" sx={{ mt: 2 }}>
				No referrals yet
			</Typography>
		</Stack>
	);
};

interface ReferralsTableProps {
	referrals: Referral[];
	loading: boolean;
	disabled: boolean;
}

const ReferralsTable = ({ referrals = [], loading, disabled }: ReferralsTableProps) => {
	const columns = useMemo<MRT_ColumnDef<ReferralRowDefinition>[]>(
		() => [
			{
				accessorKey: 'referred_participant_user',
				header: 'User',
				Cell: ({ cell }) => {
					const data = cell.getValue<any>();
					return (
						<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
							<Typography>{`${data?.first_name} ${data?.last_name}`}</Typography>
							<Typography variant="body2" color="textSecondary">
								{data?.email}
							</Typography>
						</Stack>
					);
				},
			},
			{
				accessorKey: 'referred_participant_type',
				header: 'Type',
				Cell: ({ cell }) => {
					const data = cell.getValue<any>();
					return <Typography>{`${data?.name}`}</Typography>;
				},
			},
			{
				accessorKey: 'status',
				header: 'Status',
				Cell: ({ cell }) => {
					const data = cell.getValue<any>();
					return <ReferralStatusChip loading={false} status={data} />;
				},
			},
		],
		[]
	);

	const rows = buildReferralsTableRows(referrals);

	return (
		<>
			<MaterialReactTable
				initialState={{
					pagination: { pageSize: 50, pageIndex: 0 },
				}}
				state={{
					columnPinning: {
						right: ['_details'],
					},
				}}
				columns={columns}
				enablePinning={false}
				data={rows}
				enableTableHead={true}
				enableSorting={false}
				enablePagination={false}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnFilters={false} //disable all column filters
				enableColumnFilterModes={false}
				enableColumnActions={false}
				rowCount={referrals?.length}
				muiTableBodyProps={{
					children: referrals?.length < 1 ? <NoDataView /> : null,
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={{
					sx: {
						'&:hover': {
							backgroundColor: RDV_NEUTRAL_20,
						},
					},
				}}
				enableTopToolbar={false}
				enableRowActions={false}
				enableFullScreenToggle={false}
				enableRowOrdering={false}
				enableHiding={true}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				renderBottomToolbar={
					referrals?.length > 0 &&
					(() => {
						let label = `${referrals?.length} referrals`;
						if (referrals?.length === 1) label = `${referrals?.length} referral`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default ReferralsTable;
