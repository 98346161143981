import UserRejected from '@rdv-fo/components/participant/ParticipantRejected';

const ParticipantRejectedPage = () => {
	return (
		<>
			<UserRejected />
		</>
	);
};

export default ParticipantRejectedPage;
