import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';

interface LoadingButtonProps {
	loading: boolean;
	disabled?: boolean;
	children: React.ReactNode;
	label?: string;
	className?: string;
	[key: string]: any; // Allow any other prop
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
	loading,
	disabled,
	children,
	label,
	className: parentClassName,
	...rest
}) => {
	const styling = `${parentClassName}`;

	return (
		<Button disabled={disabled} variant="contained" color="primary" className={styling} {...rest}>
			{loading ? (
				<CircularProgress
					size={14}
					color="inherit"
					thickness={4}
					style={{ marginTop: '6px', marginBottom: '6px' }}
				/>
			) : (
				`${children}`
			)}
		</Button>
	);
};

interface LoadingButtonAdvanceProps {
	loading: boolean;
	disabled?: boolean;
	children: React.ReactNode;
	label?: string;
	className?: string;
	[key: string]: any; // Allow any other prop
}

export const LoadingButtonAdvance: React.FC<LoadingButtonAdvanceProps> = ({
	loading,
	disabled,
	children,
	label,
	className: parentClassName,
	...rest
}) => {
	const theme = useTheme();
	return (
		<Button
			disabled={disabled}
			variant="contained"
			color="primary"
			sx={{
				'&:disabled': {
					backgroundColor: theme.palette.primary.main,
					color: 'white',
					cursor: 'not-allowed',
				},
			}}
			{...rest}
		>
			{loading && (
				<CircularProgress
					size={14}
					color="inherit"
					thickness={4}
					style={{ marginTop: '6px', marginBottom: '6px', marginRight: '8px' }}
				/>
			)}
			{children}
		</Button>
	);
};

export default LoadingButton;
