import {
	InputKind,
	ObjectField,
	Field,
	FieldInput,
	FieldType,
	SimpleField,
	CurrencyKind,
	FieldParameterInput,
	FieldParameterType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RdvObjectFieldInput, RdvFileFieldValue } from '@rdv-fo/services/randevuApi/types/field/';

interface FormValues {
	[key: string]: FormField;
}

export type FormField =
	| BooleanFormField
	| IntegerFormField
	| DecimalFormField
	| TextFormField
	| DateFormField
	| DatetimeFormField
	| SingleSelectFormField
	| MultiSelectFormField
	| ImageFormField
	| ImageSetFormField
	| DocumentFormField
	| DocumentSetFormField
	| ObjectFormField
	| ObjectSetFormField
	| MonetaryValueFormField
	| LocationFormField;

export type BooleanFormField = boolean;
export type DecimalFormField = number;
export type IntegerFormField = number | null;
export type TextFormField = string | null;
export type DateFormField = string | null;
export type DatetimeFormField = string | null;
export type SingleSelectFormField = string[];
export type MultiSelectFormField = string[];
export type ImageFormField = File | RdvFileFieldValue | null;
export type DocumentFormField = File | RdvFileFieldValue | null;
export type ImageSetFormField = (File | RdvFileFieldValue)[];
export type DocumentSetFormField = File | RdvFileFieldValue;
export type LocationFormField = any; // FIXME: set proper type
/* export type LocationFormField = {
	input: string;
	lat: number;
	lng: number;
	country_code: string;
	country_name?: string;
	state?: string;
	county?: string;
	postal_code?: string;
	city?: string;
	neighbourhood?: string;
	street?: string;
	house_number?: string;
}; */
export type MonetaryValueFormField = {
	amount: number;
	currency: CurrencyKind;
};

export type ObjectFormField = {
	id_object?: string;
	fields: FormValues;
};
export type ObjectSetFormField = ObjectFormField[];

export const mapFieldsToFormValues = (fields: Field[], sharedObjectTypeIds: string[]): FormValues => {
	const formValues = fields?.reduce((acc, field: Field) => {
		const inputType = field.field_type.input_type;

		if (inputType === InputKind.Boolean) {
			if (!field.value) return { ...acc, [field.field_type.tech_name]: false };

			return {
				...acc,
				[field.field_type.tech_name]: field.value,
			};
		}
		if (inputType === InputKind.Object) {
			const objectField = field as ObjectField;
			if (sharedObjectTypeIds.includes(field.field_type.input_options.id_object_type)) {
				return {
					...acc,
					[objectField.field_type.tech_name]: objectField.value?.id_object,
					[`${objectField.field_type.tech_name}_field`]: objectField,
				};
			}
			const object_field_type_values = objectField.object_type?.fields?.reduce(
				(acc: FormValues, objectField: FieldType) => {
					return { ...acc, [objectField.tech_name]: null };
				},
				{}
			);
			if (!field.value) {
				return { ...acc, [field.field_type.tech_name]: object_field_type_values };
			}

			const object_field_values = objectField.value?.fields?.reduce((acc: FormValues, objectField: Field) => {
				return { ...acc, [objectField.field_type.tech_name]: objectField.value };
			}, {});

			return {
				...acc,
				[field.field_type.tech_name]: {
					...(objectField.value?.id_object ? { id_object: objectField.value?.id_object } : undefined),
					...object_field_type_values, // if any of the values is NULL -> meaning that a value has not been uploaded for the field we default its value to null to keep the input controlled
					...object_field_values,
				},
			};
		}

		if (inputType === InputKind.ObjectSet) {
			if (field.value === null) return { ...acc, [field.field_type.tech_name]: null };

			if (sharedObjectTypeIds.includes(field.field_type.input_options.id_object_type)) {
				const objectSet = field.value.reduce(
					(acc: any, fieldValue: any, i: number) => [...acc, fieldValue.id_object],
					[]
				);
				return { ...acc, [field.field_type.tech_name]: objectSet };
			}

			const objectSet: any = [];

			field.value.map((fieldObjectValue: any) => {
				const mappedObject: any = {
					...(fieldObjectValue.id_object ? { id_object: fieldObjectValue.id_object } : undefined),
					fields: fieldObjectValue.fields.reduce((acc: FormValues, fieldValue: SimpleField) => {
						return {
							...acc,
							[fieldValue.field_type?.tech_name]: fieldValue.value,
						};
					}, {}),
				};

				objectSet.push(mappedObject);
			});

			return { ...acc, [field.field_type.tech_name]: objectSet };
		}

		return { ...acc, [field.field_type.tech_name]: field.value };
	}, {});

	return formValues;
};

export const mapFormValuesToFieldInputs = (
	dirtyFormValues: FormValues,
	formValues: FormValues,
	sharedObjectTypeIds: string[],
	fields: Field[] = []
): FieldInput[] => {
	const fieldInputs = Object.keys(dirtyFormValues).map((dirtyFieldTechName) => {
		const dirtyFieldWithType = fields.find((field: Field) => field.field_type.tech_name === dirtyFieldTechName);
		if (dirtyFieldWithType === undefined) {
			console.log(`Cannot find type for the dirty field ${dirtyFieldTechName}`);
			return { tech_name: undefined, value: undefined };
		}

		const inputType = dirtyFieldWithType?.field_type.input_type;

		// Prepare payload for updating object fields
		if (inputType === InputKind.Object) {
			if (sharedObjectTypeIds.includes(dirtyFieldWithType?.field_type?.input_options?.id_object_type))
				return {
					tech_name: dirtyFieldTechName,
					value: { id_object: formValues[dirtyFieldTechName] },
				};
			let objectFieldTypes = null; //FIXME @Rokva this entire mapping function should be thrown away and replaced with use case specific mappers

			if ((dirtyFieldWithType as any)?.object_type?.fields) {
				objectFieldTypes = (dirtyFieldWithType as any)?.object_type?.fields;
			} else {
				objectFieldTypes = (dirtyFieldWithType as any)?.field_type.object_fields;
			}

			if (objectFieldTypes == null) throw new Error('Object field types not found');

			const objectFieldsUpdatePayload =
				objectFieldTypes?.map((field_type: FieldType) => {
					return {
						tech_name: field_type.tech_name,
						value:
							(formValues[dirtyFieldTechName] && formValues[dirtyFieldTechName][field_type.tech_name]) ??
							null,
					};
				}) ?? [];

			const object_field_input: any = { fields: objectFieldsUpdatePayload };

			if (formValues[dirtyFieldTechName]?.id_object) {
				// If object exists, we have to use its id_object, so we attached it to the payload
				// otherwise, a new instance of an object will be created
				object_field_input['id_object'] = formValues[dirtyFieldTechName].id_object;
			}

			return {
				tech_name: dirtyFieldTechName,
				value: object_field_input === undefined ? null : object_field_input,
			};
		}

		if (inputType === InputKind.ObjectSet) {
			const objectSetDirtyFormField: ObjectSetFormField = dirtyFormValues[dirtyFieldTechName];

			const object_set_field_input: RdvObjectFieldInput[] = [];

			objectSetDirtyFormField.map((objectDirtyFormField: ObjectFormField) => {
				const field_inputs =
					objectDirtyFormField.fields &&
					Object.keys(objectDirtyFormField.fields).map((field_tech_name: string) => {
						return {
							tech_name: field_tech_name,
							value: objectDirtyFormField.fields[field_tech_name],
						};
					});

				const isSharedObject = sharedObjectTypeIds.includes(
					dirtyFieldWithType?.field_type?.input_options?.id_object_type
				);

				const object_field_input: any = isSharedObject
					? {
							id_object: objectDirtyFormField,
					  }
					: {
							...(objectDirtyFormField.id_object
								? { id_object: objectDirtyFormField.id_object }
								: undefined),
							fields: field_inputs,
					  };

				object_set_field_input.push(object_field_input);
			});

			return { tech_name: dirtyFieldTechName, value: object_set_field_input };
		}

		return { tech_name: dirtyFieldTechName, value: formValues[dirtyFieldTechName] };
	});

	const res: {
		tech_name: string;
		value: any;
	}[] = [];
	fieldInputs.map((fieldInput) => {
		if (fieldInput.tech_name) res.push(fieldInput);

		return undefined;
	});

	return res;
};

export const mapFormValuesToParameterInputs = (
	dirtyFormValues: FormValues,
	formValues: FormValues,
	parameterTypes: FieldParameterType[] = []
): any[] => {
	const parameterInputs = Object.keys(dirtyFormValues)
		.map((dirtyFieldTechName) => {
			const dirtyParameterTypes = parameterTypes.find(
				(parameter_type: FieldParameterType) => parameter_type.tech_name === dirtyFieldTechName
			);

			if (dirtyParameterTypes === undefined) {
				console.log(`Cannot find type for the dirty field ${dirtyFieldTechName}`);
				return { tech_name: undefined, value: undefined };
			}

			const inputType = dirtyParameterTypes?.input_type;

			// Prepare payload for updating object fields
			if (inputType === InputKind.Object) {
				const objectFieldTypes = (dirtyParameterTypes as any)?.object_type?.fields;

				const objectFieldsUpdatePayload =
					objectFieldTypes?.map((field_type: FieldType) => {
						return {
							tech_name: field_type.tech_name,
							value:
								(formValues[dirtyFieldTechName] &&
									formValues[dirtyFieldTechName][field_type.tech_name]) ??
								null,
						};
					}) ?? [];

				const object_field_input: any = { fields: objectFieldsUpdatePayload };

				if (formValues[dirtyFieldTechName]?.id_object) {
					// If object exists, we have to use its id_object, so we attached it to the payload
					// otherwise, a new instance of an object will be created
					object_field_input['id_object'] = formValues[dirtyFieldTechName].id_object;
				}

				return {
					tech_name: dirtyFieldTechName,
					value: object_field_input === undefined ? null : object_field_input,
				};
			}

			if (inputType === InputKind.ObjectSet) {
				const objectSetDirtyFormField: ObjectSetFormField = dirtyFormValues[dirtyFieldTechName];

				const object_set_field_input: RdvObjectFieldInput[] = [];

				objectSetDirtyFormField.map((objectDirtyFormField: ObjectFormField) => {
					const field_inputs = Object.keys(objectDirtyFormField.fields).map((field_tech_name: string) => {
						return {
							tech_name: field_tech_name,
							value: objectDirtyFormField.fields[field_tech_name],
						};
					});

					const object_field_input: RdvObjectFieldInput = {
						...(objectDirtyFormField.id_object ? { id_object: objectDirtyFormField.id_object } : undefined),
						fields: field_inputs,
					};

					object_set_field_input.push(object_field_input);
				});

				return { tech_name: dirtyFieldTechName, value: object_set_field_input };
			}

			return {
				tech_name: dirtyFieldTechName,
				value:
					dirtyFieldTechName === 'qty' ? formValues[dirtyFieldTechName] ?? 1 : formValues[dirtyFieldTechName],
			};
		})
		.filter((parameterInput: any) => parameterInput.tech_name !== undefined);

	return parameterInputs.filter((parameterInput: any) => parameterInput.tech_name !== undefined);
};

export const parseToNull = (value: any) => {
	if (value === null || value === undefined || value === '') return null;

	return value;
};
