import { InputAdornment, Stack } from '@mui/material';
import { TextField } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { FieldInputBaseProps } from './types';

import LabelFieldInput from './LabelFieldInput';

const missingValue = ['', undefined, null];

// FIXME: set proper type
const parse = (value: any) => {
	if (['', undefined, null].includes(value)) return null;
	return parseInt(value.toString().replace('.', ''));
};
// FIXME: set proper type
const format = (value: any) => value;

interface IntegerInputOptions {
	required: boolean;
	minValue?: number;
	maxValue?: number;
}
// FIXME: set proper type
const validate = (value: any, options: IntegerInputOptions) => {
	const { minValue, maxValue, required } = options;

	// if the value is not required and the user did not provide any value, we do not want to validate it
	// if the value is not required, we validate the value only if its provided
	if (!required && missingValue.includes(value)) return undefined;

	if (required && missingValue.includes(value)) return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	if (minValue && value < minValue)
		return VALIDATION_MESSAGE.MIN_VALUE_VIOLATION.replace('{min_value}', String(minValue));
	if (maxValue && value > maxValue)
		return VALIDATION_MESSAGE.MAX_VALUE_VIOLATION.replace('{max_value}', String(maxValue));

	return undefined;
};

interface FieldInputIntegerProps extends FieldInputBaseProps {
	minValue?: number;
	maxValue?: number;
	unit?: string;
}
const FieldInputInteger = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	minValue,
	maxValue,
	unit,
}: FieldInputIntegerProps) => {
	const inputProps = {
		type: 'number',
		// inputMode: 'numeric',
		...(minValue && { min: minValue }),
		...(maxValue && { max: maxValue }),
	};

	const textFieldInputProps = {
		...(unit && { startAdornment: <InputAdornment position="start">{unit}</InputAdornment> }),
	};

	const fieldProps = {
		parse,
		format,
		validate: (value: any) => validate(value, { required, minValue, maxValue }),
	};

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>

			<TextField
				id={techName}
				name={techName}
				disabled={disabled}
				required={required}
				variant="outlined"
				size="small"
				margin="dense"
				fullWidth
				helperText={helperText}
				fieldProps={fieldProps}
				inputProps={inputProps}
				InputProps={textFieldInputProps}
			/>
		</Stack>
	);
};

export default FieldInputInteger;
