import { Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Form } from 'react-final-form';
import ClearIcon from '@mui/icons-material/Clear';
import { ParticipantType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { Select } from 'mui-rff';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import LabelFieldInput from '@rdv-fo/components/fields/input/LabelFieldInput';
import FieldInputText from '@rdv-fo/components/fields/input/FieldInputText';
import FieldInputSmartText from '@rdv-fo/components/fields/input/FieldInputSmartText';
import { FormApi } from 'final-form';

export type ReferParticipantUserFormValues = {
	participant_tech_name: string;
	name: string;
	email: string;
	first_name: string;
	last_name: string;
};

interface ReferParticipantUserDialogProps {
	open: boolean;
	participantTypes: ParticipantType[];
	onClose: () => void;
	onSubmit: (values: ReferParticipantUserFormValues, form: FormApi<ReferParticipantUserFormValues>) => any;
}

const ReferParticipantUserDialog = ({ open, participantTypes, onClose, onSubmit }: ReferParticipantUserDialogProps) => {
	const enabledParticipantType = participantTypes[0];

	const initFormValues = () => {
		return {
			participant_tech_name: enabledParticipantType?.tech_name,
		};
	};

	const participantTypeOptions = participantTypes?.map((pt) => ({ label: pt.name, value: pt.tech_name }));

	return (
		<Dialog aria-labelledby="refer-participant" open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle id="refer-participant-title">
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h4">Refer participant</Typography>
					<IconButton onClick={onClose}>
						<ClearIcon fontSize="medium" />
					</IconButton>
				</Stack>
			</DialogTitle>
			<Divider />
			<Form
				onSubmit={onSubmit}
				initialValues={initFormValues()}
				render={({ handleSubmit, submitting, pristine, values, hasValidationErrors }) => {
					return (
						<form onSubmit={handleSubmit} noValidate>
							<DialogContent>
								<Stack
									direction="column"
									alignItems="stretch"
									justifyContent="center"
									spacing={2}
									textAlign="left"
								>
									<>
										<LabelFieldInput
											text="What kind of participant do you want to refer?"
											htmlFor="participant_tech_name"
											required={true}
											showOptional={false}
										/>

										<Select
											name="participant_tech_name"
											disabled={submitting}
											required={true}
											data={participantTypeOptions}
											variant="outlined"
											formControlProps={{ margin: 'dense', size: 'small' }}
											fullWidth
										/>
									</>

									<FieldInputSmartText techName="email" label="Email" pattern="" format='EMAIL' required />
									<FieldInputText techName="first_name" label="First name" required />
									<FieldInputText techName="last_name" label="Last name" required />

									<Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
										<Button
											variant="text"
											onClick={() => onClose()}
											size="medium"
											color="inherit"
											disabled={submitting}
										>
											Cancel
										</Button>

										<LoadingButton
											type="submit"
											disabled={pristine || submitting || hasValidationErrors}
											loading={submitting}
											variant="contained"
											size="small"
											color="primary"
										>
											Submit
										</LoadingButton>
									</Stack>
								</Stack>
							</DialogContent>
						</form>
					);
				}}
			/>
		</Dialog>
	);
};

export default ReferParticipantUserDialog;
