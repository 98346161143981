import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import React from 'react';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';

import AddressFields from '@rdv-fo/components/checkout/AddressFields';
import CheckoutSummary from '@rdv-fo/components/checkout/CheckoutSummary';
import RedirectToHostedStripePaymentCheckout from '@rdv-fo/components/checkout/RedirectToHostedStripePaymentCheckout';
import {
	loadMyTransaction,
	selectMyTransaction,
	selectLoading as selectLoadingTransaction,
} from '@rdv-fo/store/slices/transactionSlice';
import { Box } from '@mui/material';
import { TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface CheckoutPageQueryParams {
	redirectBackTo: TransactionKind.SingleDirect | TransactionKind.MultipleDirect | TransactionKind.ReverseAuction
}

const CheckoutPage = () => {
	const dispatch = useAppDispatch();
	const { paymentId, transactionId, transactionTypeTechName } = useParams<{ transactionTypeTechName: string, paymentId: string; transactionId: string }>();
	const { redirectBackTo } = usePrimitiveQueryParams() as CheckoutPageQueryParams

	const history = useHistory();
	const transaction = useAppSelector(selectMyTransaction);
	const loadingTransaction = useAppSelector(selectLoadingTransaction);
	const isMatchConfigurationPricingEnabled = transaction?.type?.match_configuration?.pricing_enabled;

	useEffect(() => {
		dispatch(loadMyTransaction({ id_transaction: transactionId }));
	}, []);

	const handleGoToPreviousRoute = () => {
		history.goBack();
	};

	const dummyHandler = () => { };

	return (
		<>
			<Button
				size="medium"
				disableElevation
				startIcon={<ArrowBackIcon />}
				sx={{ textTransform: 'none', fontWeight: 600, fontSize: '0.875rem' }}
				onClick={handleGoToPreviousRoute}
			>
				Back
			</Button>
			<Typography component="h1" variant="h3" sx={{ fontWeight: 700, marginTop: 3, marginBottom: 5 }}>
				Checkout
			</Typography>
			<Form onSubmit={dummyHandler}>
				{({ handleSubmit, }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={4}>
								<Grid item xs={12} md={8}>
									<AddressFields name="billing" label="Billing Address" />
									<Box mt={4} />
									<AddressFields name="shipping" label="Shipping Address" />
								</Grid>
								{isMatchConfigurationPricingEnabled && transaction?.chargeable && (
									<Grid item xs={12} md={4}>
										<CheckoutSummary
											loading={loadingTransaction}
											chargeable={transaction?.chargeable}
										/>
									</Grid>
								)}
							</Grid>
						</form>
					);
				}}
			</Form>

			<Box pt={4}>
				<RedirectToHostedStripePaymentCheckout
					paymentId={paymentId}
					transactionId={transactionId}
					transactionTechName={transactionTypeTechName}
					redirectBackTo={redirectBackTo}
				/>
			</Box>
		</>
	);
};

export default CheckoutPage;
