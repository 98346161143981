// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState, useEffect } from 'react';

import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import Autocomplete from '@mui/material/Autocomplete';
import { Field, useForm } from 'react-final-form';
import { sendInfoToasty } from '@rdv-fo/store/slices/uiSlice';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { debounce } from 'lodash';
import { Loader } from '@googlemaps/js-api-loader';

import { FilterInputBaseProps } from './types';
import LabelFilterInput from './LabelFilterInput';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const loader = new Loader({
	apiKey: googleMapsApiKey ?? 'MISSING-GOOGLE-MAPS-API-KEY',
	libraries: ['places'],
});

const DEFAULT_RADIUS = 100.00000000000004;
const MIN_RADIUS = Math.log(100); // number represents minimal radius in meters
const MAX_RADIUS = Math.log(250000);
const LOG_SCALE = MAX_RADIUS - MIN_RADIUS;

const convertSliderValueToRadiusValue = (slider_value: number) => {
	return Math.exp(MIN_RADIUS + LOG_SCALE * slider_value);
};

const convertRadiusValueToSliderValue = (radius_value: number) => {
	return (Math.log(radius_value) - MIN_RADIUS) / (LOG_SCALE + 1);
};

interface FilterInputLocationProps extends FilterInputBaseProps { }

const FilterInputLocation = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
}: FilterInputLocationProps) => {
	const dispatch = useAppDispatch();

	const [google, setGoogle] = useState<any>(null);
	const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

	const autocompleteService = google?.maps?.places && new google.maps.places.AutocompleteService();
	const googleToken = google?.maps?.places && new google.maps.places.AutocompleteSessionToken();

	useEffect(() => {
		const asyncFn = async () => {
			const google = await loader.load();
			setGoogle(google);
		};
		asyncFn();
	}, []);

	// FIXME: set proper types
	const trimFloatPointPartForLargeDistance = (value: any) =>
		parseFloat(value) < 5 ? value : value.replace(/\.\d$/, '');

	const handleFindSuggestions = async (user_input: string) => {
		const callBackSetSuggestions = (predictions: any, status: any) => {
			if (status !== google?.maps.places.PlacesServiceStatus.OK || !predictions) {
				dispatch(sendInfoToasty(status));
				return;
			}
			setAutocompleteSuggestions(predictions);
		};

		autocompleteService.getQueryPredictions(
			{ input: user_input, sessionToken: googleToken },
			callBackSetSuggestions
		);
	};

	const handleDebouncedFindSuggestions = debounce(handleFindSuggestions, 300);

	const handleGeoCodeUserInput = async (user_input: any, radius: number, setFieldState: any) => {
		const geocoder = google?.maps && new google.maps.Geocoder();

		const response = await geocoder.geocode({ address: user_input });
		const geocodedResponse = response?.results?.[0];

		const addressComponents = geocodedResponse?.address_components;

		if (!addressComponents) {
			return;
		}

		setFieldState({
			input: user_input,
			lat: geocodedResponse.geometry.location.lat(),
			lng: geocodedResponse.geometry.location.lng(),
			radius,
		});
	};

	const form = useForm();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Field name={techName}>
					{(props) => {
						const { input, meta: { initial } } = props;

						return (
							<>
								{noLabel === false && (
									<LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />
								)}
								<Autocomplete
									id="location-autocomplete"
									options={autocompleteSuggestions}
									disabled={disabled}
									value={{ description: input?.value?.input ?? '' }}
									defaultValue={{ description: input?.value?.input ?? '' }}
									getOptionLabel={(option) => option.description ?? ''}
									onChange={(e, value) => {
										const selectedSuggestion = value;

										if (selectedSuggestion === null) {
											// User clicked on "X" to clear the input
											input.onChange(undefined); // -> remove from the form's state
											setAutocompleteSuggestions([{ description: input?.value?.input ?? '' }]);
											return;
										}

										// Geo coded selected suggestion
										console.log('input:', input);
										handleGeoCodeUserInput(
											selectedSuggestion?.description,
											input?.value?.radius ?? 0,
											input.onChange
										);
									}}
									onClose={(e: any) => {
										if (['', undefined, null].includes(e?.target?.textContent)) {
											// user did not select any auto complete option,
											// so delete the value from the form state
											input.onChange(initial);

											return;
										}
									}}
									renderInput={(params) => {
										return (
											<TextField
												{...params}
												onChange={(e) => {
													const user_input = e.target.value;

													const fieldValue = { ...input.value, input: user_input };
													input.onChange(fieldValue);

													if (user_input?.length > 2) {
														handleDebouncedFindSuggestions(user_input);
													}
												}}
												id={techName}
												name={techName}
												disabled={disabled}
												required={required}
												variant="outlined"
												size="small"
												margin="dense"
												fullWidth
											/>
										);
									}}
								/>
							</>
						);
					}}
				</Field>
			</Grid>
			<Grid item xs={12}>
				<Field name={`${techName}.radius`}>
					{(props) => {
						const { input } = props;

						const radiusValueInMeters: number | string = input.value;

						const formattedRadiusInKm = trimFloatPointPartForLargeDistance(
							(radiusValueInMeters / 1000).toFixed(0)
						);

						let sliderValue = 0;
						if (typeof radiusValueInMeters === 'number')
							sliderValue = convertRadiusValueToSliderValue(radiusValueInMeters);

						return (
							<>
								<Typography>
									Max distance{' '}
									{radiusValueInMeters >= 0 &&
										`${formattedRadiusInKm === '0.0' ? '0' : formattedRadiusInKm} km`}
								</Typography>
								<Slider
									defaultValue={0}
									value={sliderValue}
									valueLabelFormat={() => formattedRadiusInKm}
									valueLabelDisplay="auto"
									disabled={disabled || radiusValueInMeters === ''}
									step={0.01}
									min={0}
									max={1}
									onChange={(_, sliderValue) => {
										const radiusValueInMeter = convertSliderValueToRadiusValue(sliderValue);
										input.onChange(radiusValueInMeter);
										const currentValues = form.getFieldState(techName)?.value;
										form.change(techName, {
											input: currentValues.input,
											lat: currentValues.lat,
											lng: currentValues.lng,
											radius: parseInt(radiusValueInMeter),
										})
									}}
									onChangeCommitted={(_, sliderValue) => {
										const radiusValue = convertSliderValueToRadiusValue(sliderValue);
										input.onChange(Math.round(radiusValue));
									}}
								/>
							</>
						);
					}}
				</Field>
			</Grid>
		</Grid>
	);
};

export default FilterInputLocation;
