import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { useLocation, useParams } from 'react-router';

import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { buildQueryParams, parseDiscoveryFilters } from '@rdv-fo/app/lib/url';
import DiscoveryFilters from '@rdv-fo/components/discovery/DiscoveryFilters';
import GeneralLayout from '@rdv-fo/components/layout/types/GeneralLayout';
import PageWidth from '@rdv-fo/components/layout/PageWidth';
import { setSelectedFilters, discoverTransactionProviders, selectLoading, selectDiscoveryPageInfo, discoverMoreTransactionProviders } from '@rdv-fo/store/slices/discoverySlice';
import { replaceCurrentRouteWith } from '@rdv-fo/store/slices/uiSlice';
import { removeFilterKeys } from '@rdv-fo/app/lib/supply';

import { setSearchBarInputs } from '@rdv-fo/store/slices/discoverySlice';
import {
    selectCurrentTransactionType,
    selectTransactionTypes,
    setSelectedTransactionType,
} from '@rdv-fo/store/slices/transactionSlice';
import { mapFieldFiltersToNestedFieldFilters, parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords } from '@rdv-fo/app/lib/discovery';
import { selectPlatformName } from '@rdv-fo/store/slices/platformSlice';
import RdvDrawer from '@rdv-fo/components/common/RdvDrawer';
import {
    AvailabilityManagementKind,
    MatchingToolType,
    TransactionType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import DiscoveredProviders from '@rdv-fo/components/discovery/DiscoveredProviders';

const DiscoveredTransactionProvidersPage = () => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { transactionTypeTechName } = useParams<{ transactionTypeTechName: string }>();

    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const location = useLocation();
    const platformName = useAppSelector(selectPlatformName);
    const myTransactionTypes = useAppSelector(selectTransactionTypes);

    const queryParams = parseDiscoveryFilters(location.search);
    const { filters } = parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords(queryParams);
    const numberOfSelectedFilters = filters ? filters.length : 0;
    const pageInfo = useAppSelector(selectDiscoveryPageInfo);

    const contextTransactionType =
        myTransactionTypes?.find((tt) => tt.tech_name === transactionTypeTechName)

    const rootType = contextTransactionType?.root_type as MatchingToolType;
    const matchingUnitKind = rootType.match_type.matching_unit_type;

    const providerTypeFields = rootType?.match_type?.provider_type?.fields ?? [];

    const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);

    useEffect(() => {
        if (platformName) {
            const queryParams = parseDiscoveryFilters(location.search);

            const { filters, availability_params, available_supply_only } =
                parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords(queryParams);

            dispatch(
                discoverTransactionProviders({
                    transaction_tech_name: transactionTypeTechName,
                    where: {
                        fields: { AND: mapFieldFiltersToNestedFieldFilters(filters, providerTypeFields) },
                    },
                    first: 10
                })
            );
            if (openFiltersDrawer) setOpenFiltersDrawer(false);
        }
    }, [dispatch, platformName, location.search, transactionTypeTechName]);

    const handleDiscoverProviders = async (filterValues: any) => {
        await dispatch(
            replaceCurrentRouteWith(
                `${ROUTES.DISCOVERED_TRANSACTION_PROVIDERS.replace(':transactionTypeTechName', transactionTypeTechName)}${buildQueryParams(filterValues, providerTypeFields)}`
            )
        );
    };

    const handleClearFilterValues = async () => {
        dispatch(
            replaceCurrentRouteWith(
                routeBuilder(
                    `${ROUTES.DISCOVERED_TRANSACTION_PROVIDERS.replace(':transactionTypeTechName', transactionTypeTechName)}`
                )
            )
        );
    };

    const handleLoadMoreProviders = () => {
        const queryParams = parseDiscoveryFilters(location.search);

        const { filters, availability_params, available_supply_only } =
            parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords(queryParams);

        dispatch(
            discoverMoreTransactionProviders({
                transaction_tech_name: transactionTypeTechName,
                where: {
                    fields: { AND: mapFieldFiltersToNestedFieldFilters(filters, providerTypeFields) },
                },
                first: 10,
                after: pageInfo?.end_cursor
            })
        );
    }

    return (
        <GeneralLayout>
            <PageWidth paddingTop={theme.spacing(4) as any} paddingBottom={theme.spacing(4) as any}>
                <Box sx={{ minHeight: 'calc(100vh - 64px)' }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ height: '100%' }}
                        spacing={4}
                    >
                        <Grid id="discovery-filters" item xs={12} sm={3} sx={{ height: 'auto', mt: 2 }}>
                            {isMobileScreen ? (
                                <>
                                    <Button variant="text" color="inherit" onClick={() => setOpenFiltersDrawer(true)}>
                                        Filters{` (${numberOfSelectedFilters})`}
                                    </Button>
                                    <RdvDrawer
                                        open={openFiltersDrawer}
                                        title=""
                                        loading={false}
                                        onClose={() => setOpenFiltersDrawer(false)}
                                    >
                                        <DiscoveryFilters
                                            disabled={loading}
                                            onDiscoverWithFilters={handleDiscoverProviders}
                                            typeFieldTypes={providerTypeFields}
                                            availabilityManagement={AvailabilityManagementKind.None}
                                            onClearFilters={handleClearFilterValues}
                                            matchingUnitType={matchingUnitKind}
                                            numberOfSelectedFilters={numberOfSelectedFilters}
                                            useDeprecatedFilterInputs={false}
                                        />
                                    </RdvDrawer>
                                </>
                            ) : (
                                <DiscoveryFilters
                                    disabled={loading}
                                    onDiscoverWithFilters={handleDiscoverProviders}
                                    typeFieldTypes={providerTypeFields}
                                    availabilityManagement={AvailabilityManagementKind.None}
                                    onClearFilters={handleClearFilterValues}
                                    matchingUnitType={matchingUnitKind}
                                    numberOfSelectedFilters={numberOfSelectedFilters}
                                    useDeprecatedFilterInputs={false}
                                />
                            )}
                        </Grid>
                        <Grid id="discovery-results" item xs={12} sm={9} sx={{ minHeight: '100%', overflowY: 'scroll', pb: 8 }}>
                            <DiscoveredProviders transactionTypeTechName={transactionTypeTechName} />
                        </Grid>
                        {pageInfo?.has_next_page &&
                            <>
                                <Grid id="discovery-filters" item xs={12} sm={3}>
                                </Grid>
                                <Grid id="discovery-pagination" item xs={9} sx={{ pb: 4 }}>
                                    <Stack width='100%' alignItems='center'>

                                        <Button variant='text' color='primary' onClick={handleLoadMoreProviders}>
                                            Load more
                                        </Button>
                                    </Stack>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </PageWidth>
        </GeneralLayout>
    );
};

export default DiscoveredTransactionProvidersPage;
