import Typography from '@mui/material/Typography';
import { formatDateLong } from '@rdv-fo/app/lib/datetime';

interface FieldValueDateProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueDate = ({ fieldTechName, value, className }: FieldValueDateProps) => {
	return (
		<Typography id={fieldTechName} className={className}>
			{value === null ? 'n.a.' : formatDateLong(value)}
		</Typography>
	);
};

export default FieldValueDate;
