export const DRAWER_WIDTH = 300;
export const MAX_WIDTH = 800;

export const LINKS = {
	RANDEVU: {
		BACKOFFICE: 'https://backoffice.randevu.technology',
		LANDING_PAGE: 'https://randevu.tech',
		TERMS: 'https://randevu.tech',
		PRIVACY: 'https://randevu.tech',
	},
};

export const RANDEVU_PRIMARY_COLOR = '#8b5fff';
