import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Typography from '@mui/material/Typography';
import ROUTES from '@rdv-fo/common/routes';
import AuthLayout from '@rdv-fo/components/layout/types/AuthLayout';
import ProtectedLayout from '@rdv-fo/components/layout/types/ProtectedLayout';
import ScrollToTop from '@rdv-fo/components/layout/ScrollToTop';
import { createRandevuTheme } from '@rdv-fo/styles/theme';
import LandingPage from '@rdv-fo/pages/LandingPage';
import AuthRoute from '@rdv-fo/components/layout/routes/AuthRoute';
import ProtectedRoute from '@rdv-fo/components/layout/routes/ProtectedRoute';
import PrivateRoute from '@rdv-fo/components/layout/routes/PrivateRoute';
import ContactUsPage from '@rdv-fo/pages/ContactUsPage';
import AboutUsPage from '@rdv-fo/pages/AboutUsPage';
import PublicLayout from '@rdv-fo/components/layout/types/PublicLayout';
import DiscoveredTransactionSuppliesPage from '@rdv-fo/pages/DiscoveredTransactionSuppliesPage';
import DiscoveredTransactionSupplyDetailsPage from '@rdv-fo/pages/DiscoveredTransactionSupplyDetailsPage';
import ToastyMessages from '@rdv-fo/components/common/ToastyMessages';
import RedirectTo from '@rdv-fo/components/common/RedirectTo';
import DomainPage from '@rdv-fo/pages/DomainPage';
import NotFoundPage from '@rdv-fo/pages/NotFoundPage';
import PlatformErrorPage from '@rdv-fo/pages/PlatformErrorPage';
import {
	initializePlatform,
	selectInitializingPlatform,
	selectGlobalUiConfig,
	selectPlatformDisplayName,
} from '@rdv-fo/store/slices/platformSlice';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import { selectCurrentUser, selectToken } from '@rdv-fo/store/slices/authSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ReauthenticateDialog from '@rdv-fo/components/security/ReauthenticateDialog';
import { determineCustomDomainName, determineDomainName, determinePlatformNameFromUrl } from '@rdv-fo/app/lib/url';

import { setSearchBarInputs } from '@rdv-fo/store/slices/discoverySlice';
import PrivateLayout from '@rdv-fo/components/layout/types/PrivateLayout';

import { ParticipantStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import DiscoveredTransactionProviderDetailsPage from '@rdv-fo/pages/DiscoveredTransactionProviderDetailsPage';
import { loadMySharedObjects, loadMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import { loadMyConnectionTypes } from '@rdv-fo/store/slices/connectionSlice';
import DiscoveredTransactionProvidersPage from './pages/DiscoverTransactionProvidersPage';

const isForcedLogoutRequested = ({ pathname, queryParams }) => {
	const allowedRoutesForForcedLogout = pathname.endsWith('/sign-in') || pathname.endsWith('/welcome');
	const forcedLogoutQueryParamAvailable = queryParams.startsWith('?force-logout=true');

	return allowedRoutesForForcedLogout && forcedLogoutQueryParamAvailable;
};

const clearForceLogoutRequest = ({ queryParams }) => queryParams.replace('?force-logout=true&', '');

const isGuestModeRequested = ({ pathname, queryParams }) => {
	return pathname.endsWith('/welcome') && queryParams.startsWith('?force-logout=true');
};

const App = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();

	const currentUser = useAppSelector(selectCurrentUser);
	const isLoggedIn = useAppSelector(selectToken);
	const initializing = useAppSelector(selectInitializingPlatform);
	const globalUiConfig = useAppSelector(selectGlobalUiConfig);
	const platformDisplayName = useAppSelector(selectPlatformDisplayName);

	const title = platformDisplayName
		? `${platformDisplayName} - ${globalUiConfig?.primary_slogan}`
		: 'randevu - Automatically Generated Frontend';

	const domainName = determineCustomDomainName();

	// Startup
	useEffect(() => {
		let redirectTo = undefined;
		let init_mode = 'none';
		if (isGuestModeRequested({ pathname: location.pathname, queryParams: location.search })) {
			redirectTo = ROUTES.LANDING_PAGE;
			init_mode = 'guest';
		} else if (isForcedLogoutRequested({ pathname: location.pathname, queryParams: location.search })) {
			const queryParams = clearForceLogoutRequest({ queryParams: location.search });
			let route = `${ROUTES.SIGN_IN}?${queryParams}`;
			redirectTo = route;
			init_mode = 'guest';
		} else if (currentUser) init_mode = 'participant';

		dispatch(
			initializePlatform({
				platform_name: determinePlatformNameFromUrl(),
				domain_name: domainName,
				init_mode: init_mode,
				redirectTo: redirectTo,
			})
		);

		// FIXME: VALIDATE IF THIS CAN BE REMOVED
		dispatch(setSearchBarInputs({})); // Necessary in case of MP matching unit type changed
	}, [dispatch, domainName]);

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(loadMySharedObjectTypes());
			dispatch(loadMySharedObjects({ where: {} }));

			// DIRTY WORKAROUND -> this logic should be on init platform
			dispatch(loadMyConnectionTypes());
		}
	}, [dispatch, isLoggedIn]);

	/* 	// Startup
	useEffect(() => {
		if (initializing === 'idle') {
			console.log('******** Evaluating platform and user config ********');

			const urlWhitelisted = ROUTES_WHITELIST.includes(location.pathname);

		
		

			//	const guestTransactionTypes = resolvedActionGuestTT.payload;
			//	const guestsAllowed = guestTransactionTypes?.length;
			//	if (!guestsAllowed && !urlWhitelisted && !currentUser) {
			console.log('No guests allowed, redirecting..');
			sendWarningToasty('No guests allowed on the platform. You have to log-in first.');

			//		return dispatch(goToRoute(ROUTES.LANDING_PAGE));
			//	}
		}
	}, [dispatch, initializing]); */

	// Non-approved and rejected user guards
	useEffect(() => {
		if ([ParticipantStatusKind.Onboarding].includes(currentUser?.status))
			dispatch(goToRoute(routeBuilder(ROUTES.PARTICIPANT_ONBOARDING)));
		if (currentUser?.status === ParticipantStatusKind.Rejected)
			dispatch(goToRoute(routeBuilder(ROUTES.PARTICIPANT_REJECTED)));
	}, [dispatch, currentUser]);

	if (initializing === 'loading') {
		return (
			<>
				<RedirectTo />
				<ToastyMessages />
				<Typography variant="h3" align="center" sx={{ mt: 16, pt: 32 }}>
					Loading platform...
				</Typography>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={`${platformDisplayName} - ${globalUiConfig?.primary_slogan}`} />
				{globalUiConfig?.favicon_url && (
					<link rel="icon" type="image/png" href={globalUiConfig?.favicon_url} sizes="16x16" />
				)}
			</Helmet>
			<StyledEngineProvider injectFirst>
				<ThemeProvider
					theme={createRandevuTheme(globalUiConfig?.primary_color, globalUiConfig?.secondary_color)}
				>
					<CssBaseline />
					<ReauthenticateDialog />
					<ScrollToTop />
					<ToastyMessages />
					<RedirectTo />
					<Switch>
						<Route exact path={ROUTES.PLATFORM_ERROR} component={PlatformErrorPage} />
						<Route exact path={ROUTES.DOMAIN} component={DomainPage} />
						<Route exact path={ROUTES.LANDING_PAGE} component={LandingPage} />
						<Route exact path={ROUTES.NOT_FOUND_PAGE} component={NotFoundPage} />

						{/* <Route path={ROUTES.CONTACT} component={ContactUsPage} /> */}
						<Route path={ROUTES.ABOUT} component={AboutUsPage} />
						<Route path={ROUTES.DISCOVERED_TRANSACTION_SUPPLIES} component={DiscoveredTransactionSuppliesPage} />
						<Route path={ROUTES.DISCOVERED_TRANSACTION_SUPPLY_DETAILS} component={DiscoveredTransactionSupplyDetailsPage} />
						<Route path={ROUTES.DISCOVERED_TRANSACTION_PROVIDERS} component={DiscoveredTransactionProvidersPage} />
						<Route path={ROUTES.DISCOVERED_TRANSACTION_PROVIDER_DETAILS} component={DiscoveredTransactionProviderDetailsPage} />
						<Route path={ROUTES.PUBLIC} component={PublicLayout} />

						{/* PrivateRoutes verifies that user is logged-in AND not onboarded */}
						<PrivateRoute path={ROUTES.PRIVATE} component={PrivateLayout} />

						{/* Only for logged-in AND successfully onboarded users */}
						<ProtectedRoute path={ROUTES.PROTECTED} component={ProtectedLayout} />
						{/* AuthRoute verifies that user is not logged-in */}
						<AuthRoute path={ROUTES.AUTH} component={AuthLayout} queryParams={location.search} />
					</Switch>
				</ThemeProvider>
			</StyledEngineProvider>
		</>
	);
};

export default App;
