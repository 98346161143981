import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import withStyles from '@mui/styles/withStyles';

const Accordion = withStyles({
	root: {
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 0,
			padding: 0,
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
	root: {
		cursor: 'pointer',
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		paddingLeft: theme.spacing(2),
		minHeight: theme.spacing(3),
		'&$expanded': {
			minHeight: theme.spacing(5),
		},
		borderRadius: theme.spacing(1),
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		cursor: 'pointer',
		margin: 0,
		'&$expanded': {
			margin: 0,
		},
	},
	expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
	},
}))(MuiAccordionDetails);

export { Accordion, AccordionDetails, AccordionSummary };
