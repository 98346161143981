import { Chip as MuiChip, Skeleton } from '@mui/material/';

import useChipStyles from '@rdv-fo/components/common/chip/chipColors';

interface ImportJobReportStatusChipProps {
	loading?: boolean;
	size?: 'small' | 'medium';
	startedAt?: string;
	finishedAt?: string;
	reportExists?: boolean;
}
const ImportJobReportStatusChip = ({
	loading = false,
	size = 'medium',
	startedAt,
	finishedAt,
	reportExists
}: ImportJobReportStatusChipProps) => {
	const classes = useChipStyles();

	if (loading) return <Skeleton variant="rounded" width={80} height={35} />;

	let status = 'REQUESTED';
	if (startedAt) status = 'ONGOING';
	if (finishedAt) status = 'FINISHED';

	let colorClass;
	let value;

	switch (status) {
		case 'REQUESTED':
			colorClass = classes.orange;
			value = 'Requested';
			break;

		case 'ONGOING':
			colorClass = classes.blue;
			value = 'Ongoing';
			break;

		case 'FINISHED':
			if (reportExists) {
				colorClass = classes.gray;
				value = 'Finished';
			} else {
				colorClass = classes.red;
				value = 'Failed'
			}
			break;

		default:
			colorClass = classes.gray;
			value = 'Unknown';
	}

	const customClasses = `${colorClass}`;

	return <MuiChip label={value} className={customClasses} size={size} />;
};

export default ImportJobReportStatusChip;
