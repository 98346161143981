import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	MutationAcceptTransactedMatchArgs,
	MutationDeclineTransactedMatchArgs,
	MutationUpdateTransactedMatchArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

const UPDATE_TRANSACTED_MATCH = gql`
	mutation UPDATE_TRANSACTED_MATCH($id_transaction: ID!, $fields: [FieldInput!]) {
		updateTransactedMatch(id_transaction: $id_transaction, fields: $fields)
	}
`;
const updateTransactedMatch = ({ id_transaction, fields }: MutationUpdateTransactedMatchArgs) => {
	return JSON.stringify({
		query: print(UPDATE_TRANSACTED_MATCH),
		variables: {
			id_transaction,
			fields,
		},
	});
};


const ACCEPT_TRANSACTED_MATCH = gql`
	mutation ACCEPT_TRANSACTED_MATCH($id_transaction: ID!) {
		acceptTransactedMatch(id_transaction: $id_transaction)
	}
`;
const acceptTransactedMatch = ({ id_transaction }: MutationAcceptTransactedMatchArgs) => {
	return JSON.stringify({
		query: print(ACCEPT_TRANSACTED_MATCH),
		variables: {
			id_transaction,
		},
	});
};

const DECLINE_TRANSACTED_MATCH = gql`
	mutation DECLINE_TRANSACTED_MATCH($id_transaction: ID!) {
		declineTransactedMatch(id_transaction: $id_transaction)
	}
`;
const declineTransactedMatch = ({ id_transaction }: MutationDeclineTransactedMatchArgs) => {
	return JSON.stringify({
		query: print(DECLINE_TRANSACTED_MATCH),
		variables: {
			id_transaction,
		},
	});
};

export { acceptTransactedMatch, declineTransactedMatch, updateTransactedMatch };
