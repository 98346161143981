import { Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Form } from 'react-final-form';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField } from 'mui-rff';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import LabelFieldInput from '@rdv-fo/components/fields/input/LabelFieldInput';

import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { overrideMatchPrice, overrideMatchPriceFailed } from '@rdv-fo/store/slices/transactionSlice';

export type MatchPriceOverrideFormValues = {
	amount: number;
};

const parseLocalePrice = (value: any) => {
	if (!value) return null;

	const decimalSeparator = '.';
	const thousandSeparator = ',';
	const sanitizedValue = value
		.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
		.replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.');

	const floatValue = parseFloat(sanitizedValue);

	if (isNaN(floatValue)) return null;

	return parseInt((floatValue * 100).toFixed(0));
};

const formatLocalePrice = (value: any) => {
	if (!value) return null;

	const options = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	};

	return new Intl.NumberFormat('en-EN', options).format(value / 100);
};


interface MatchPriceOverrideFormDialogProps {
	open: boolean;
	matchPrice: any;
	transactionId: string;
	onClose: () => void;
}

const MatchPriceOverrideFormDialog = ({
	open,
	transactionId,
	matchPrice,
	onClose,
}: MatchPriceOverrideFormDialogProps) => {
	const dispatch = useAppDispatch();

	const initFormValues = () => {
		return {
			amount: matchPrice?.amount ?? matchPrice,
		};
	};

	const handleSubmitOverrideMatchPrice = async (values: MatchPriceOverrideFormValues) => {
		const resolvedAction = await dispatch(
			overrideMatchPrice({ id_transaction: transactionId, price: { amount: values.amount } })
		);

		if (resolvedAction.type === overrideMatchPriceFailed.type) return resolvedAction.payload;

		onClose();
	};

	return (
		<Dialog aria-labelledby="override-match-price" open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle id="override-match-price-title">
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h4">Change total price</Typography>
					<IconButton onClick={onClose}>
						<ClearIcon fontSize="medium" />
					</IconButton>
				</Stack>
			</DialogTitle>
			<Divider />
			<Form
				onSubmit={handleSubmitOverrideMatchPrice}
				initialValues={initFormValues()}
				render={({ handleSubmit, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} noValidate>
							<DialogContent>
								<Stack
									direction="column"
									alignItems="stretch"
									justifyContent="center"
									spacing={2}
									textAlign="left"
								>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<LabelFieldInput text={'Price'} htmlFor={'price'} />

										<TextField
											id="price"
											name="amount"
											disabled={submitting}
											required={true}
											variant="outlined"
											size="small"
											fieldProps={{
												parse: (value) => parseLocalePrice(value),
												format: (value) => formatLocalePrice(value),
											}}
											margin="dense"
											fullWidth
										/>
									</div>
									<Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
										<Button
											variant="text"
											onClick={() => onClose()}
											size="medium"
											color="inherit"
											disabled={submitting}
										>
											Cancel
										</Button>

										<LoadingButton
											type="submit"
											disabled={pristine || submitting}
											loading={submitting}
											variant="contained"
											size="small"
											color="primary"
										>
											Submit
										</LoadingButton>
									</Stack>
								</Stack>
							</DialogContent>
						</form>
					);
				}}
			/>
		</Dialog>
	);
};

export default MatchPriceOverrideFormDialog;
