import { Field } from 'react-final-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Radio } from '../../rff';
import LabelFilterInput from './LabelFilterInput';
import { FilterInputBaseProps } from './types';

const parse = (value: string) => {
	if (value === 'yes') return true;
	if (value === 'no') return false;
	if (value === 'unknown') return null;

	return undefined;
};
const format = (value: boolean | null) => {
	if (value === true) return 'yes';
	if (value === false) return 'no';
	if (value == null) return '';

	return '';
};

interface FilterInputBooleanProps extends FilterInputBaseProps { }

const FilterInputBoolean = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
}: FilterInputBooleanProps) => {
	return (
		<Grid container direction="column">
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}
			<Grid item>
				<FormControlLabel
					control={
						<Field
							name={techName}
							parse={parse as any}
							format={format}
							component={Radio}
							type="radio"
							value={"yes" as any}
							disabled={disabled}
						/>
					}
					label="Yes"
				/>
				<FormControlLabel
					control={
						<Field
							name={techName}
							parse={parse as any}
							format={format}
							component={Radio}
							type="radio"
							value={"no" as any}
							disabled={disabled}
						/>
					}
					label="No"
				/>

				<FormControlLabel
					control={
						<Field
							name={techName}
							parse={parse as any}
							format={format}
							component={Radio}
							type="radio"
							value={"" as any}
							disabled={disabled}
						/>
					}
					label="I do not care"
				/>
			</Grid>
		</Grid>
	);
};

export default FilterInputBoolean;
