import Typography from '@mui/material/Typography';
interface FieldValueImageProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
	altText?: string;
}
const FieldValueImage = ({ fieldTechName, altText, value, className }: FieldValueImageProps) => {
	let unavailable = value === null || value === undefined || value?.url === null;

	return unavailable ? (
		<Typography id={fieldTechName} className={className}>
			n.a.
		</Typography>
	) : (
		<img id={fieldTechName} src={value?.url} alt={altText ?? 'Field image'} style={{maxWidth: 200,
			maxHeight: 200,
			objectFit: 'scale-down',
			borderRadius: 15, }}/>
	);
};

export default FieldValueImage;
