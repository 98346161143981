import React from 'react';
import AuthPageStyle from '@rdv-fo/components/layout/AuthPageStyle';
import RequestPasswordResetForm from '@rdv-fo/components/security/RequestPasswordResetForm';
import { selectForgotPasswordPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const ForgotPasswordPage = () => {
	const { style, image_position, image_url, title, description, form, form_submitted_view } = useAppSelector(selectForgotPasswordPageConfig) ?? {};

	return (
		<>
			<AuthPageStyle
				style={style}
				imagePosition={image_position}
				image={image_url}
			>
				<RequestPasswordResetForm title={title} description={description} form={form} form_submitted_view={form_submitted_view} />
			</AuthPageStyle>
		</>
	);
};

export default ForgotPasswordPage;
