import Typography from '@mui/material/Typography';

interface FieldValueSmartTextProps {
	value?: string;
	className: any;
	fieldTechName: string;
	format: 'EMAIL' | 'URL' | 'CUSTOM' | 'email' | 'url' | 'custom';
}

const FieldValueSmartText = ({ fieldTechName, value, format, className }: FieldValueSmartTextProps) => {
	const renderValue = value == null ? 'n.a.' : value;

	if (value === null || (value !== null && (format === 'CUSTOM' || format === 'custom')))
		return (
			<Typography id={fieldTechName} className={className}>
				{renderValue}
			</Typography>
		);

	if (format === 'EMAIL' || format === 'email')
		return (
			<Typography id={fieldTechName} className={className} component="a" href={`mailto:${renderValue}`}>
				{renderValue}
			</Typography>
		);

	if (format === 'URL' || format === 'url') {
		return (
			<Typography id={fieldTechName} className={className} component="a" target="_blank" href={renderValue}>
				{renderValue}
			</Typography>
		);
	}

	return (
		<Typography id={fieldTechName} className={className} >
			{renderValue}
		</Typography>
	);
};

export default FieldValueSmartText;
