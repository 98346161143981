import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { SupplyType, ImportSupplyDataTemplate } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import DownloadFile from '@rdv-fo/components/common/DownloadFile';
import { Form } from 'react-final-form';
import { Select } from 'mui-rff';
import LabelFieldInput from '@rdv-fo/components/fields/input/LabelFieldInput';
import OptionLabel from '@rdv-fo/components/common/OptionLabel';

interface ImportTemplateOptionsProps {
	supplyTypes: SupplyType[];
	onDownload: (url: string, fileName: string) => void;
}
const ImportTemplateOptions = ({ supplyTypes, onDownload }: ImportTemplateOptionsProps) => {
	const supplyTypesWithEnabledImports = supplyTypes
		?.filter((st: SupplyType) => st.bulk_import_enabled)

	const supplyTypeOptions = supplyTypesWithEnabledImports
		.map((st) => ({
			label: <OptionLabel primary={st.name} />,
			value: st.tech_name,
		}));

	const importDisabled = supplyTypesWithEnabledImports?.length === 0;
	const multipleSupplyTypeImports = supplyTypesWithEnabledImports?.length > 0;

	return (
		<>
			{importDisabled && (
				<Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 6 }}>
					<Typography variant="subtitle1">Import feature is disabled</Typography>
					<Typography variant="body2">Please contact customer support for more details</Typography>
				</Stack>
			)}
			{!importDisabled && (
				<Form
					onSubmit={() => { }}
					initialValues={{
						selected_supply_type: supplyTypes?.filter((st) => st.bulk_import_enabled)?.[0].tech_name,
					}}
					render={({ values }) => {
						const enabledTemplates =
							supplyTypes
								?.filter((st: SupplyType) => st.tech_name === values.selected_supply_type)
								.flatMap((st) => st.data_import_templates)
								.filter((template: ImportSupplyDataTemplate) => template.is_active) ?? [];

						const enabledTemplatesFound = enabledTemplates?.length > 0;

						return (
							<Stack
								direction="column"
								alignItems="stretch"
								justifyContent="center"
								spacing={4}
								sx={{ width: '100%' }}
							>
								{multipleSupplyTypeImports && (
									<Box>
										<LabelFieldInput
											text="For what kind of supply do you want templates?"
											htmlFor="supply_types"
											required={false}
											showOptional={false}
										/>

										<Select
											name="selected_supply_type"
											disabled={false}
											required={false}
											data={supplyTypeOptions}
											variant="outlined"
											formControlProps={{ margin: 'dense', size: 'small' }}
											fullWidth
										/>
									</Box>
								)}

								{!enabledTemplatesFound && (
									<Stack
										direction="column"
										justifyContent="center"
										alignItems="center"
										spacing={2}
										sx={{ mt: 6 }}
									>
										<Typography variant="subtitle1">Import templates not available</Typography>
										<Typography variant="body2">
											Your import templates will appear here after platform operator enables them
										</Typography>
									</Stack>
								)}
								{enabledTemplatesFound && (
									<Stack
										direction="column"
										justifyContent="flex-start"
										alignItems="flex-start"
										spacing={2}
										sx={{ width: '100%', mt: 2 }}
									>
										{enabledTemplates?.map((template) => {
											return (
												<Box key={template.id} sx={{ width: '100%' }}>
													<DownloadFile
														label={template.name}
														labelSecondary={template.version}
														url={template?.template?.url}
														physicalFileName={template?.template?.name + '.xlsx'} //FIXME @Rokva - expose file_name
														onDownload={onDownload}
													/>
												</Box>
											);
										})}
									</Stack>
								)}
							</Stack>
						);
					}}
				/>
			)}
		</>
	);
};

export default ImportTemplateOptions;
