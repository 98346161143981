import Typography from '@mui/material/Typography';

interface FieldValueMultiSelectProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
	optionsUiConfig: { label: string, value: string }[];
}
const FieldValueMultiSelect = ({ fieldTechName, value, className, optionsUiConfig }: FieldValueMultiSelectProps) => {
	let noValues = value?.length === 0;

	value = value?.map((selectValue: string) => optionsUiConfig.find((option) => option.value === selectValue)?.label ?? selectValue)

	return (
		<Typography id={fieldTechName} className={className}>
			{noValues ? 'n.a.' : value?.join(', ')}
		</Typography>
	);
};

export default FieldValueMultiSelect;
