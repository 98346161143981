import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { findFieldByTechName } from '@rdv-fo/app/lib/fieldsHelper';
import { CurrencyKind, ShoppingCartItemForConsumer } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FieldDisplayValue from '../../fields/display/FieldDisplayValue';
import { formatPriceAmount } from '../../supply/supplyHelpers';
import { DeleteOutlined } from '@mui/icons-material';
import ConditionalFieldDisplay from '../../fields/display/ConditionalFieldDisplay';

interface ShoppingCartItemProps {
	itemId: string;
	imgUrl: string;
	fields: any;
	qty: number | undefined;
	itemPrice: number | undefined;
	totalPrice: number | undefined;
	name: string;
	currency: CurrencyKind;
	fees: any[];
	discounts: any[];
	renderActions: boolean;
	showPrice: boolean;
	onOpenRemoveItemDialog?: (itemId: string) => void;
	onOpenEditItemDrawer?: (itemId: string) => void;
}

const ShoppingCartItem = ({
	itemId,
	showPrice,
	renderActions,
	imgUrl,
	fields,
	qty,
	itemPrice,
	totalPrice,
	name,
	currency,
	fees,
	discounts,
	onOpenEditItemDrawer = () => { },
	onOpenRemoveItemDialog = () => { },
}: ShoppingCartItemProps) => {
	const fieldValues = {};

	fields?.forEach((field: any) => Object.assign(fieldValues, { [field?.field_type?.tech_name]: field?.value }));

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
			<Stack direction="row" spacing={2}>
				<Stack direction="column">
					<img src={imgUrl} alt="" width="150px" height="100px" style={{ objectFit: 'scale-down' }} />
				</Stack>
				<Stack direction="column" spacing={1}>
					<Typography variant="h4">{name}</Typography>
					<Box>
						<Typography variant="body1">Quantity: {qty}</Typography>
						{showPrice && (
							<Typography variant="body1">
								Item price: {itemPrice ? formatPriceAmount({ amount: itemPrice, currency }) : 'n.a'}
							</Typography>
						)}
						{showPrice && (
							<Typography variant="body1">
								Total: {totalPrice ? formatPriceAmount({ amount: totalPrice, currency }) : 'n.a'}
							</Typography>
						)}
						{fields?.map((field: any) => (
							<ConditionalFieldDisplay
								key={field?.field_type?.tech_name}
								fields={fields}
								values={fieldValues}
								conditions={field?.field_type?.conditions ?? []}
							>
								<FieldDisplayValue
									key={field?.field_type.tech_name}
									label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
									fieldTechName={field?.field_type.tech_name}
									inputType={field?.field_type.input_type}
									inputOptions={field?.field_type.input_options}
									optionsUiConfig={field?.field_type?.ui_config?.options}
									value={field.value}
								/>
							</ConditionalFieldDisplay>
						))}
						{showPrice &&
							discounts.map((discount) => (
								<>
									<Typography fontWeight="bold">{discount?.description}</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount: discount?.value,
											currency,
										})}
									</Typography>
								</>
							))}
						{showPrice &&
							fees.map((fee) => (
								<>
									<Typography fontWeight="bold">{fee?.description}</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount: fee?.value,
											currency,
										})}
									</Typography>
								</>
							))}
					</Box>
				</Stack>
			</Stack>
			{renderActions && (
				<Stack direction="row" spacing={2}>
					<Button
						onClick={() => onOpenEditItemDrawer(itemId)}
						color="inherit"
						startIcon={<EditOutlinedIcon />}
					>
						Edit
					</Button>
					<IconButton onClick={() => onOpenRemoveItemDialog(itemId)}>
						<DeleteOutlined color="error" fontSize="small" />
					</IconButton>
				</Stack>
			)}
		</Stack>
	);
};

interface ShoppingCartItemsProps {
	items: ShoppingCartItemForConsumer[][];
	matchParameterTypeTechNames: string[];
	renderActions?: boolean;
	showPrice: boolean;
	onOpenRemoveItemDialog?: (itemId: string) => void;
	onOpenEditItemDrawer?: (itemId: string) => void;
}

const ShoppingCartItems = ({
	items,
	showPrice,
	renderActions = true,
	matchParameterTypeTechNames,
	onOpenRemoveItemDialog,
	onOpenEditItemDrawer,
}: ShoppingCartItemsProps) => {
	return (
		<Stack direction="column" spacing={5}>
			{items.map((item) => {
				return (
					<>
						{item.map((item, i) => {
							const matchParamFields = item?.fields?.filter((field) =>
								matchParameterTypeTechNames.includes(field?.field_type?.tech_name)
							);
							const qtyField = findFieldByTechName(item?.fields, 'qty')?.value;
							const consumerSupplyFees =
								item?.price?.platform_fee_items?.filter((fee) => fee.is_consumer_fee) ?? [];
							const discountItems = item?.price?.discount_items ?? [];

							const providerImage = findFieldByTechName(item?.provider_fields, 'profile_image')?.value
								?.url;
							const providerName = `Provider ${i + 1}`;

							return i < 1 ? (
								<>
									<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
										{providerImage && (
											<img
												src={providerImage}
												width="40px"
												height="40px"
												alt={providerName}
												style={{ borderRadius: 20, objectFit: 'contain' }}
											/>
										)}

										<Typography variant="h4">{`${providerName}`}</Typography>
									</Stack>
									<ShoppingCartItem
										showPrice={showPrice}
										renderActions={renderActions}
										itemId={item.id}
										onOpenEditItemDrawer={onOpenEditItemDrawer}
										onOpenRemoveItemDialog={onOpenRemoveItemDialog}
										discounts={discountItems}
										imgUrl={findFieldByTechName(item.supply_fields, 'main_image')?.value?.url}
										fields={matchParamFields}
										qty={item?.price?.qty ?? qtyField}
										itemPrice={item?.price?.unit_price}
										totalPrice={item?.price?.total_before_tax}
										currency={item?.price?.currency ?? CurrencyKind.Eur}
										name={findFieldByTechName(item?.supply_fields, 'name')?.value}
										fees={consumerSupplyFees}
									/>
								</>
							) : (
								<ShoppingCartItem
									showPrice={showPrice}
									renderActions={renderActions}
									itemId={item.id}
									onOpenEditItemDrawer={onOpenEditItemDrawer}
									onOpenRemoveItemDialog={onOpenRemoveItemDialog}
									discounts={discountItems}
									imgUrl={findFieldByTechName(item.supply_fields, 'main_image')?.value?.url}
									fields={matchParamFields}
									qty={item?.price?.qty ?? qtyField}
									itemPrice={item?.price?.unit_price}
									totalPrice={item?.price?.total_before_tax}
									currency={item?.price?.currency ?? CurrencyKind.Eur}
									name={findFieldByTechName(item?.supply_fields, 'name')?.value}
									fees={consumerSupplyFees}
								/>
							);
						})}
						<Divider />
					</>
				);
			})}
		</Stack>
	);
};

export default ShoppingCartItems;
