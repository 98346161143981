import React from 'react';

import { FormHelperText } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const FormError = ({ message }) => {
	return (
		<FormHelperText id={'error-message'} error focused style={{ display: 'flex', alignItems: 'center' }}>
			<span>
				<ReportProblemIcon fontSize="small" color="error" />
			</span>
			<span style={{ marginLeft: '5px' }}>{message}</span>
		</FormHelperText>
	);
};

export default FormError;
