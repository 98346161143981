import React from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';

import { Form } from 'react-final-form';
import { TextField, DatePicker, DateTimePicker } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
	FieldCategoryKind,
	MatchingUnitKind,
	InputKind,
	MatchingToolType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

import { LoadingButtonAdvance } from '@rdv-fo/components/common/LoadingButton';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import { setSearchBarInputs, selectSearchBarInputs } from '@rdv-fo/store/slices/discoverySlice';
import ROUTES from '@rdv-fo/common/routes';
import { parse, format } from '@rdv-fo/app/lib/datetime';
import { buildQueryParams } from '@rdv-fo/app/lib/url';
import { selectDefaultTransactionType } from '@rdv-fo/store/slices/transactionSlice';
import { AVAILABILITY_QUERY_PARAMS } from '@rdv-fo/app/lib/discovery';
import { Stack, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		btnSearch: {
			width: '100%',
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
	};
});


const DiscoverSupplySearchBar = () => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const defaultTransactionType = useAppSelector(selectDefaultTransactionType);
	const searchBarInputs = useAppSelector(selectSearchBarInputs);

	const rootType = defaultTransactionType?.root_type as MatchingToolType;

	const supplyFieldTypes = rootType?.match_type?.supply_type?.fields;
	const supplyDescriptionFieldType = supplyFieldTypes?.find((ft) => ft.category === FieldCategoryKind.Description);
	const supplyMainTimestampFieldType = supplyFieldTypes?.find((ft) => ft.category === FieldCategoryKind.MainDatetime);
	const matchingUnitType = rootType?.match_type.matching_unit_type;
	const isTimeHourBased = [MatchingUnitKind.PerHour, MatchingUnitKind.PerEvent].includes(matchingUnitType);
	const isPerEventBased = matchingUnitType === MatchingUnitKind.PerEvent;
	

	const isRentingOrBookingBased = [MatchingUnitKind.PerHour, MatchingUnitKind.PerDay].includes(matchingUnitType);

	const dateFormat = isTimeHourBased ? 'dd/MM/yy HH:mm' : 'dd. MMM yyyy';
	const showSupplyDescriptionInput =
		supplyDescriptionFieldType && [MatchingUnitKind.PerEvent, MatchingUnitKind.PerUnit].includes(matchingUnitType);

	const renderDatePickers = ({ from, to }: { from: string; to: string }) => {
		return (
			<>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<LocalizationProvider dateAdapter={DateFnsUtils}>
						<DatePicker
							name={from}
							label="From"
							InputProps={{ onKeyDown: (e) => e.preventDefault() }}
							inputFormat={dateFormat}
							fieldProps={{
								inputVariant: 'outlined',
								parse,
								format,
							}}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={DateFnsUtils}>
						<DatePicker
							name={to}
							label="To"
							InputProps={{ onKeyDown: (e) => e.preventDefault() }}
							inputFormat={dateFormat}
							fieldProps={{
								inputVariant: 'outlined',
								parse,
								format,
							}}
						/>
					</LocalizationProvider>
				</Stack>
			</>
		);
	};

	const renderDatetimePickers = ({ from, to }: { from: string; to: string }) => {
		return (
			<>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<LocalizationProvider dateAdapter={DateFnsUtils}>
						<DateTimePicker
							name={from}
							label="From"
							InputProps={{ onKeyDown: (e) => e.preventDefault() }}
							inputFormat={dateFormat}
							fieldProps={{
								inputVariant: 'outlined',
								parse,
								format,
							}}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={DateFnsUtils}>
						<DateTimePicker
							name={to}
							label="To"
							InputProps={{ onKeyDown: (e) => e.preventDefault() }}
							inputFormat={dateFormat}
							fieldProps={{
								inputVariant: 'outlined',
								parse,
								format,
							}}
						/>
					</LocalizationProvider>
				</Stack>
			</>
		);
	};

	const renderDateInputs = ({ from, to }: { from: string; to: string }) => {
		if (isTimeHourBased) return renderDatetimePickers({ from, to });
		return renderDatePickers({ from, to });
	};

	const handleSubmitSearch = async (values: any) => {
		await dispatch(setSearchBarInputs(values));
		const descriptionFieldTechName = values ? Object.keys(values)[0] : null;
		dispatch(
			goToRoute(
				`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES.replace(
					':transactionTypeTechName',
					defaultTransactionType?.tech_name ?? ''
				)}${buildQueryParams(values, [
					{ tech_name: descriptionFieldTechName ?? '', input_type: InputKind.Text },
				])}`
			)
		);
	};

	const DateInputs = () => {
		if (isPerEventBased)
			return renderDateInputs({
				from: `${supplyMainTimestampFieldType?.id ?? ''}.min_date`,
				to: `${supplyMainTimestampFieldType?.id ?? ''}.max_date`,
			});
		if (isTimeHourBased)
			return renderDateInputs({
				from: AVAILABILITY_QUERY_PARAMS.START_DATE_TIME,
				to: AVAILABILITY_QUERY_PARAMS.END_DATE_TIME,
			});
		return renderDateInputs({ from: AVAILABILITY_QUERY_PARAMS.START_DATE, to: AVAILABILITY_QUERY_PARAMS.END_DATE });
	};

	const showDateInputs = isRentingOrBookingBased;
	return (
		<>
			<Card sx={{ width: '100%', borderRadius: 2 }}>
				<CardContent
					sx={{
						paddingTop: 2,
						paddingLeft: 2,
						paddingRight: 2,
						'&:last-child': {
							paddingBottom: 2,
						},
					}}
				>
					<Form onSubmit={handleSubmitSearch} initialValues={searchBarInputs}>
						{({ handleSubmit, submitting }) => {
							return (
								<form onSubmit={handleSubmit}>
									<Stack
										direction="column"
										justifyContent="center"
										alignItems="stretch"
										spacing={2}
										sx={{ pt: 2, pb: 2, pl: 1, pr: 1 }}
									>
										{showDateInputs && <DateInputs />}
										{showSupplyDescriptionInput && (
											<TextField
												name={supplyDescriptionFieldType.tech_name}
												variant="outlined"
												fullWidth
												label="Description"
											/>
										)}

										<LoadingButtonAdvance
											type="submit"
											color="primary"
											variant="contained"
											className={classes.btnSearch}
											loading={submitting}
											disabled={submitting}
											fullWidth={true}
										>
											{defaultTransactionType?.ui_config?.prepare ?? 'Search'}
										</LoadingButtonAdvance>
									</Stack>
								</form>
							);
						}}
					</Form>
				</CardContent>
			</Card>
		</>
	);
};

export default DiscoverSupplySearchBar;
