import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';

import { useHistory, useParams } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';

import SupplyUpdateForm from '@rdv-fo/components/supply/SupplyUpdateForm';
import PageWidth from '@rdv-fo/components/layout/PageWidth';
import {
	selectLoading,
	loadMySupply,
	selectMySupply,
	activateSupply,
	deactivateSupply,
	updateSupplyFailed,
	createSupplyVariant,
	createSupplyVariantFailed,
	selectLoadingSupplyVariant,
	updateSupplyVariant,
	selectErrors,
	deactivateSupplyVariant,
	activateSupplyVariant,
} from '@rdv-fo/store/slices/supplySlice';
import ROUTES from '@rdv-fo/common/routes';
import { Divider, Stack, Typography } from '@mui/material';
import SupplyVariantsTable from '@rdv-fo/components/supply/SupplyVariantsTable';
import CreateOrUpdateSupplyVariantDialog from '@rdv-fo/components/supply/CreateOrUpdateSupplyVariantDialog';
import { mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import { FormApi } from 'final-form';
import { isNotFound } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import BackButton from '@rdv-fo/components/common/BackButton';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';

const isSupplyNotFound = (errors: any) => isNotFound(errors) && errors[0].path[0] === 'mySupply';

const UpdateMySupplyPage = () => {
	const { supplyId } = useParams() as any;
	const dispatch = useAppDispatch();
	const supply = useAppSelector(selectMySupply);
	const loadingSupplyVariant = useAppSelector(selectLoadingSupplyVariant);
	const [openCreateOrUpdateSupplyVariantDialog, setOpenCreateOrUpdateSupplyVariantDialog] = useState<boolean>(false);
	const [supplyVariantId, setSupplyVariantId] = useState<string | null>(null);
	const [currentlySelectedVariant, setCurrentlySelectedVariant] = useState<any>(null);
	const errors = useAppSelector(selectErrors);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const history = useHistory();
	const sharedObjects = useAppSelector(selectMySharedObjects);
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);

	useEffect(() => {
		if (isSupplyNotFound(errors)) {
			history.replace(ROUTES.HOME);
		}
	}, [errors, history]);

	useEffect(() => {
		const variant = supply?.variants?.find((supply) => supply.id === supplyVariantId);
		setCurrentlySelectedVariant(variant);
	}, [supplyVariantId, supply]);

	const handleOpenSupplyVariantDialog = (supplyId: string | null) => {
		setSupplyVariantId(supplyId);
		setOpenCreateOrUpdateSupplyVariantDialog(true);
	};

	const handleCloseSupplyVariantDialog = () => {
		setOpenCreateOrUpdateSupplyVariantDialog(false);
		setSupplyVariantId(null);
	};

	const handleSubmitUpdateSupplyVariant = async (values: any, form: FormApi) => {
		const dirtyFields = getDirtyFields({ ...values }, form);
		const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypeIds, supply?.fields);
		const remainingQty = dirtyFields?.remaining_qty;

		const resolvedAction = await dispatch(
			updateSupplyVariant({
				id: supplyVariantId ?? '',
				dirty_fields: fieldInputs,
				current_fields: currentlySelectedVariant.fields,
				remaining_qty: remainingQty,
			})
		);
		if (resolvedAction?.type === updateSupplyFailed.type) return resolvedAction.payload;

		handleCloseSupplyVariantDialog();
	};
	const handleSubmitCreateSupplyVariant = async (values: any, form: FormApi) => {
		const dirtyFields = getDirtyFields({ ...values }, form);
		const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypeIds, supply?.fields);

		const resolvedAction = await dispatch(
			createSupplyVariant({
				tech_name: supply?.type.tech_name ?? '',
				variant_fields: fieldInputs,
				variant_group: supply?.variant_group ?? '',
			})
		);
		if (resolvedAction?.type === createSupplyVariantFailed.type) return resolvedAction.payload;

		handleCloseSupplyVariantDialog();
	};

	const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(true);
	const loading = useAppSelector(selectLoading);

	useEffect(() => {
		dispatch(loadMySupply({ id: supplyId }));
		const loadingSpinnerTimeout = setTimeout(() => {
			setLoadingSpinnerAllowed(false);
		}, 500);

		return () => {
			clearTimeout(loadingSpinnerTimeout);
		};
	}, []);

	const handleToggleIsActive = async () => {
		if (supply?.is_active) return dispatch(deactivateSupply({ id: supply?.id }));
		if (supply?.id) return dispatch(activateSupply({ id: supply?.id }));
	};

	const handleToggleIsActiveVariant = async (id: string, isActive: boolean) => {
		if (isActive) return dispatch(deactivateSupplyVariant({ id }));
		return dispatch(activateSupplyVariant({ id }));
	};

	const variantsAvailable = supply?.variants && supply?.variants?.length > 0;

	return loading ? (
		<Grid container sx={{ height: 'calc(100vh - 150px)' }}>
			{loadingSpinnerAllowed ? <LoadingIndicator loading={loading} /> : null}
		</Grid>
	) : (
		<PageWidth maxWidth="md">
			<Stack direction="column" spacing={5}>
				<CreateOrUpdateSupplyVariantDialog
					open={openCreateOrUpdateSupplyVariantDialog}
					sharedObjectTypes={sharedObjectTypes}
					sharedObjects={sharedObjects}
					onClose={handleCloseSupplyVariantDialog}
					mode={supplyVariantId == null ? 'create' : 'update'}
					onSubmit={
						supplyVariantId == null ? handleSubmitCreateSupplyVariant : handleSubmitUpdateSupplyVariant
					}
					supplyVariant={currentlySelectedVariant}
					variantFields={supply?.fields as any}
				/>

				<Grid container direction="row" justifyContent="space-between">
					<Grid item xs={6} width="100%">
						<BackButton label="Manage supplies" to={ROUTES.MY_SUPPLIES} />
					</Grid>
					<Grid item xs={6} width="100%" textAlign="right">
						<Button
							color={supply?.is_active ? 'error' : 'success'}
							size="medium"
							disableElevation
							variant="outlined"
							sx={{ textTransform: 'none' }}
							onClick={handleToggleIsActive}
						>
							{supply?.is_active ? 'Disable my supply' : 'Enable my supply'}
						</Button>
					</Grid>
				</Grid>

				<SupplyUpdateForm supply={supply} />

				{supply?.variant_group && (
					<>
						<Divider />

						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
							<Typography variant="subtitle1">Variants</Typography>
							{variantsAvailable && (
								<Button
									variant="text"
									startIcon={<AddOutlinedIcon fontSize="small" />}
									onClick={() => handleOpenSupplyVariantDialog(null)}
								>
									Add new
								</Button>
							)}
						</Stack>
						{variantsAvailable ? (
							<SupplyVariantsTable
								fields={supply?.fields as any}
								supplies={supply.variants as any}
								loading={loadingSupplyVariant}
								onEditSupplyVariant={handleOpenSupplyVariantDialog}
								onToggleIsActive={handleToggleIsActiveVariant}
							/>
						) : (
							<>
								<Typography variant="body1">No variants created.</Typography>
								<Button
									variant="text"
									startIcon={<AddOutlinedIcon fontSize="small" />}
									onClick={() => handleOpenSupplyVariantDialog(null)}
								>
									Add variant
								</Button>
							</>
						)}
					</>
				)}
			</Stack>
		</PageWidth>
	);
};

export default UpdateMySupplyPage;
