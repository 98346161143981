const decodeToJSONObject = (encodedValue) => {
	try {
		return JSON.parse(atob(encodedValue));
	} catch (ex) {
		console.warn('Failed decoding string into JSON object');
		return {};
	}
};

export default decodeToJSONObject;
