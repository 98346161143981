import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		} else {
			// If current location and target location are the same page, than smoothly scroll up
			try {
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			} catch (error) {
				// just a fallback for older browsers
				window.scrollTo(0, 0);
			}
		}
	}

	render() {
		return null;
	}
}

export default withRouter(ScrollToTop);
