import Grid from '@mui/material/Grid';

import { TextField } from 'mui-rff';
import { FilterInputBaseProps } from './types';

import LabelFilterInput from './LabelFilterInput';

const parse = (value: any) => {
	if (['', undefined, null].includes(value)) return null;
	return Number(value);
};
// FIXME: set proper type
const format = (value: any) => {
	if (['', undefined, null].includes(value)) return '';
	return value;
};
const FilterInputAutoIncrementId = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
}: FilterInputBaseProps) => {

	const fieldProps = {
		parse,
		format,
	};
	return (
		<Grid container>
			{(noLabel === false && label) && (
				<LabelFilterInput text={label} htmlFor={techName} required={required} />
			)}

			<TextField
				name={`${techName}.max_value`}
				disabled={disabled}
				margin="dense"
				type='number'
				variant="outlined"
				fieldProps={{
					...fieldProps,
					parse: (value: string) => Number(value)
				}}
				fullWidth
				size="small"
				placeholder="5"
			/>

			<TextField
				name={`${techName}.min_value`}
				disabled={disabled}
				margin="dense"
				variant="outlined"
				type='number'
				fullWidth
				size="small"
				placeholder="0"
				fieldProps={fieldProps}
			/>
		</Grid>
	);
};

export default FilterInputAutoIncrementId;
