import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	QueryDiscoverTransactionSupplyDetailsArgs,
	QueryDiscoverTransactionProviderDetailsArgs,
	QueryDiscoverTransactionSuppliesArgs,
	QueryDiscoverTransactionProvidersArgs,
	QueryDiscoverTransactionSuppliesNewArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { FIELDS_WITH_FIELD_TYPE_FRAGMENT, FIELDS_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT } from '../fragments';

const DISCOVER_TRANSACTION_SUPPLIES = gql`
	query DISCOVER_TRANSACTION_SUPPLIES(
		$transaction_tech_name: String!
		$where: SupplyDiscoverFilter!
		$availability_params: [FieldParameterInput!]
		$available_supply_only: Boolean
		$after: String
		$first: Int
	) {
		discoverTransactionSuppliesNEW(
			transaction_tech_name: $transaction_tech_name
			where: $where
			availability_params: $availability_params
			available_supply_only: $available_supply_only
			after: $after
			first: $first
		) {
			page_info {
				end_cursor
				start_cursor
				has_next_page
				has_previous_page
			}
			edges {
				cursor
				node {
				id
				type {
					id
					name
					match_configurations {
						pricing_enabled
					}
				}

				fields {
					...FieldsFragment
				}
				provider {
					id_participant
					fields {
						...FieldsFragment
					}
				}
				variant_group
				variants {
					id
					variant_group
					fields {
						...FieldsFragment
					}
				}
			}
		}
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const discoverTransactionSupplies = ({
	transaction_tech_name,
	where,
	availability_params,
	available_supply_only,
	after,
	first
}: QueryDiscoverTransactionSuppliesNewArgs) => {
	return JSON.stringify({
		query: print(DISCOVER_TRANSACTION_SUPPLIES),
		variables: {
			transaction_tech_name,
			where,
			availability_params,
			available_supply_only,
			after,
			first
		},
	});
};

const DISCOVER_TRANSACTION_SUPPLY_DETAILS = gql`
	query DISCOVER_TRANSACTION_SUPPLY_DETAILS($transaction_tech_name: String!, $id_supply: ID!) {
		discoverTransactionSupplyDetails(transaction_tech_name: $transaction_tech_name, id_supply: $id_supply) {
			id
			type {
				name
				match_configurations {
					pricing_enabled
				}
			}
			availability
			fields {
				...FieldWithVariantsFragment
			}
			variant_group
			variants {
				id
				availability
				variant_group
				fields {
					...FieldWithVariantsFragment
				}
			}
			provider {
				id_participant
				fields {
					...FieldWithVariantsFragment
				}
			}
		}
	}
	${FIELDS_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT}
`;

const discoverTransactionSupplyDetails = ({
	id_supply,
	transaction_tech_name,
}: QueryDiscoverTransactionSupplyDetailsArgs) => {
	return JSON.stringify({
		query: print(DISCOVER_TRANSACTION_SUPPLY_DETAILS),
		variables: {
			transaction_tech_name: transaction_tech_name,
			id_supply: id_supply,
		},
	});
};

const DISCOVER_TRANSACTION_PROVIDER_DETAILS = gql`
	query DISCOVER_TRANSACTION_PROVIDER_DETAILS($transaction_tech_name: String!, $id_participant: ID!) {
		discoverTransactionProviderDetails(
			transaction_tech_name: $transaction_tech_name
			id_participant: $id_participant
		) {
			id_participant
			fields {
				...FieldsFragment
			}
			type {
				id
				name
			}
			collections {
				id
				name
				supplies_count
				created_at
			}
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const discoverTransactionProviderDetails = ({
	id_participant,
	transaction_tech_name,
}: QueryDiscoverTransactionProviderDetailsArgs) => {
	return JSON.stringify({
		query: print(DISCOVER_TRANSACTION_PROVIDER_DETAILS),
		variables: {
			transaction_tech_name,
			id_participant,
		},
	});
};
const DISCOVER_TRANSACTION_PROVIDERS = gql`
	query DISCOVER_TRANSACTION_PROVIDERS($transaction_tech_name: String!, $where: ProviderDiscoveryFilter!, $after: String, $before: String, $first: Int, $last: Int) {
		discoverTransactionProviders(
			transaction_tech_name: $transaction_tech_name
			where: $where
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			page_info {
				end_cursor
				start_cursor
				has_next_page
				has_previous_page
			}
			edges {
				cursor
				node {
					id_participant
					fields {
						...FieldsFragment
					}
					type {
						id
						name
					}
				}
			}
			
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const discoverTransactionProviders = ({
	transaction_tech_name,
	where,
	after,
	before,
	first,
	last
}: QueryDiscoverTransactionProvidersArgs) => {
	return JSON.stringify({
		query: print(DISCOVER_TRANSACTION_PROVIDERS),
		variables: {
			transaction_tech_name,
			where,
			after,
			before,
			first,
			last
		},
	});
};

export { discoverTransactionSupplies, discoverTransactionProviders, discoverTransactionSupplyDetails, discoverTransactionProviderDetails };
