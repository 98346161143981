import { Paper, Stack, Typography } from '@mui/material'
import { RDV_NEUTRAL_10 } from '@rdv-fo/styles/theme'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const ShoppingCartPaymentSuccessView = () => {
    return (
        <Stack component={Paper} elevation={0} sx={{ backgroundColor: RDV_NEUTRAL_10 }} padding={4} justifyContent='center' alignItems='center' spacing={2}>
            <CheckCircleIcon color='success' fontSize='large' />
            <Typography variant='h4'>Payment was successful.</Typography>
            <Typography variant='body1'>Thank you for your order.</Typography>
        </Stack>
    )
}

export default ShoppingCartPaymentSuccessView