import { CurrencyKind, SupplyStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface FormatPriceAmountArg {
	amount: number;
	currency: CurrencyKind;
	unit?: number;
}
export const formatPriceAmount = ({ amount, currency, unit = 100 }: FormatPriceAmountArg) =>
	(amount / unit).toLocaleString('en-US', {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
		style: 'currency',
		currency: currency,
	});

export enum SupplyStatusTab {
	enabled = 'enabled',
	errors = 'errors',
	disabled = 'disabled',
	onboarding = 'onboarding',
	all = 'all',
}

export const VALID_SUPPLY_QUERY_PARAM_STATUS: string[] = Object.values(SupplyStatusTab);

export class SupplyMappers {
	static toSupplyStatusFilter = (queryParamSupplyStatus: SupplyStatusTab | null | undefined): { status: SupplyStatusKind[], is_active?: boolean } | undefined => {
		if (!queryParamSupplyStatus) return {
			status: [SupplyStatusKind.Onboarded],
			is_active: true
		};

		if (SupplyStatusTab.enabled === queryParamSupplyStatus) return {
			status: [SupplyStatusKind.Onboarded],
			is_active: true
		};

		if (SupplyStatusTab.errors === queryParamSupplyStatus) return {
			status: [SupplyStatusKind.Draft],
		};

		if (SupplyStatusTab.disabled === queryParamSupplyStatus) return {
			status: [SupplyStatusKind.Onboarded],
			is_active: false
		};
		if (SupplyStatusTab.onboarding === queryParamSupplyStatus) return {
			status: [SupplyStatusKind.Onboarding],
		};

		if (SupplyStatusTab.all === queryParamSupplyStatus) return undefined;

		return undefined;
	};
}
