import { CardMedia, Grid, Typography, useTheme } from "@mui/material";
import { findFieldByCategory } from "@rdv-fo/app/lib/fieldsHelper";
import { Field, FieldCategoryKind } from "@rdv-fo/services/randevuApi/types/generatedTypes";
import ConditionalFieldDisplay from "../fields/display/ConditionalFieldDisplay";
import FieldDisplayValue from "../fields/display/FieldDisplayValue";

interface SupplyDataProps {
    supplyFields: Field[];
    supplyTypeName?: string;
}

const SupplyData = ({ supplyFields, supplyTypeName }: SupplyDataProps) => {
    const theme = useTheme();
    const fieldName = findFieldByCategory(supplyFields, FieldCategoryKind.Name);
    const fieldDescription = findFieldByCategory(supplyFields, FieldCategoryKind.Description);
    const fieldMainImage = findFieldByCategory(supplyFields, FieldCategoryKind.MainImage);
    const fieldPrice = findFieldByCategory(supplyFields, FieldCategoryKind.BasePrice);

    const dominantFields = [
        fieldDescription?.field_type?.id,
        fieldName?.field_type?.id,
        fieldMainImage?.field_type?.id,
        fieldPrice?.field_type?.id,
    ];

    const discoveredSupplyRegularFields = supplyFields?.filter(
        (field) => !dominantFields.includes(field?.field_type?.id)
    );

    const discoveredSupplyRegularFieldValues = {};
    discoveredSupplyRegularFields?.forEach((field) =>
        Object.assign(discoveredSupplyRegularFieldValues, { [field?.field_type?.tech_name]: field?.value })
    );

    return (
        <>
            <figure style={{
                borderRadius: 15,
                height: '100%',
                backgroundRepeat: 'no-repeat',
            }}>
                <CardMedia
                    image={fieldMainImage?.value?.url || '/'}
                    sx={{
                        borderRadius: 15,
                        height: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',

                    }}
                />
            </figure>
            {supplyTypeName && <Typography component="h3" variant="subtitle1" color="textSecondary" gutterBottom>
                {supplyTypeName}
            </Typography>}
            <Typography component="h2" variant="h4" color="primary" gutterBottom>
                {fieldName?.value}
            </Typography>
            <Typography>{fieldDescription?.value}</Typography>
            <br />
            <br />

            {discoveredSupplyRegularFields?.map((field) => {
                return (
                    <Grid key={field?.field_type.tech_name} container>
                        <ConditionalFieldDisplay
                            key={field?.field_type?.tech_name}
                            fields={discoveredSupplyRegularFields}
                            values={discoveredSupplyRegularFieldValues}
                            conditions={field?.field_type?.conditions ?? []}
                        >
                            <FieldDisplayValue
                                label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
                                fieldTechName={field?.field_type.tech_name}
                                optionsUiConfig={field?.field_type?.ui_config?.options ?? undefined}
                                inputType={field?.field_type.input_type}
                                inputOptions={field?.field_type.input_options}
                                value={field.value}
                            />
                        </ConditionalFieldDisplay>
                    </Grid>
                );
            })}
        </>
    );
};

export default SupplyData;