import { Stack, Typography } from '@mui/material';

interface OptionLabelProps {
	primary: string;
	secondary?: string;
}
const OptionLabel = ({ primary, secondary }: OptionLabelProps) => {
	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ width: '100%' }}>
			<Typography sx={{ width: 'max-content' }}>{primary}</Typography>
			{secondary && (
				<Typography variant="body2" color="textSecondary">
					{secondary}
				</Typography>
			)}
		</Stack>
	);
};

export default OptionLabel;
