import { Stack, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { FieldType, InputKind, Object, ObjectType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';
import { Select } from 'mui-rff';
import FieldValueObject from '../display/FieldValueObject';
import { useForm, useFormState } from 'react-final-form';

interface FieldInputObjectSetProps extends FieldInputBaseProps {
    objectTypeFields: FieldType[];
    maxObjects?: number;
    minObjects?: number;
    sharedObjectTypes: ObjectType[];
    sharedObjects: Object[];
}

export const FieldInputSharedObjectSet = ({
    techName,
    label,
    labelProps,
    disabled = false,
    required = false,
    objectTypeFields = [],
    maxObjects,
    minObjects,
    sharedObjects,
}: FieldInputObjectSetProps) => {
    const theme = useTheme();
    const form = useForm();

    const sharedObjectOptions = [...sharedObjects?.map((so) => {
        const label = so?.fields?.find((field) => [InputKind.Text, InputKind.Select].includes(field?.field_type?.input_type))?.value
        return { label: label ?? so.id, value: so.id }
    })]
    const fieldValue = useFormState()?.values?.[techName];

    return (
        <>
            <div>
                <LabelFieldInput
                    text={label}
                    htmlFor={techName}
                    required={required}
                    showOptional={labelProps?.showOptional}
                />
                <div style={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(2) }}>
                    <FieldArray name={techName}>
                        {({ fields, meta: { error } }) => {

                            return (
                                <>
                                    <Grid item container gap={10} xs={12} sx={{ marginLeft: 3, marginTop: 2 }}>
                                        {error && (
                                            <Typography variant="overline" color="red">
                                                {error[FORM_ERROR]}
                                            </Typography>
                                        )}

                                        {fields?.map((name, index) => {
                                            const currentlySelectedOption = sharedObjects.find((so) => so.id === fieldValue[index]);
                                            return (
                                                <Grid item xs={11} key={index}>
                                                    <Stack direction='column' spacing={2}>
                                                        <Stack direction='row' spacing={4}>

                                                            <Select data={sharedObjectOptions} name={`${techName}.[${index}]`} required={required} disabled={disabled} label={label} />
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                                onClick={() => {
                                                                    if (index === 0 && fields.length === 1)
                                                                        form.change(techName, null);
                                                                    else fields.remove(index);
                                                                }}
                                                                size="small"
                                                                startIcon={<ClearIcon />}
                                                                style={{ padding: 1 }}
                                                                disabled={disabled}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </Stack>
                                                        <FieldValueObject fieldTechName={techName + index} value={currentlySelectedOption as any} className="" />

                                                    </Stack>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>

                                    <Button
                                        size="small"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        disabled={disabled}
                                        onClick={() => {
                                            fields.push(null);
                                        }}
                                    >
                                        Add new
                                    </Button>
                                </>
                            );
                        }}
                    </FieldArray>
                </div>
            </div>
        </>
    );
};

export default FieldInputSharedObjectSet;
