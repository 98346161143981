import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import PageWidth from '../layout/PageWidth';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { selectLandingPageConfig } from '@rdv-fo/store/slices/platformSlice';

const AboutUsSection = ({ width }) => {
	const dispatch = useAppDispatch();
	const landing_page_config = useAppSelector(selectLandingPageConfig);

	return (
		<>
			<section id="about-us-section">
				<PageWidth>
					<Stack
						sx={{ p: { xs: 4, sm: 4 } }}
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={2}
					>
						<Typography color="primary" variant="subtitle2" align="center">
							{landing_page_config?.about.subtitle ?? 'Subtitle'}
						</Typography>

						<Typography variant="h2" align="center">
							{landing_page_config?.about.title ?? 'About us title'}
						</Typography>
						<Typography variant="body1" align="center" maxWidth="sm">
							{landing_page_config?.about.text ?? 'Description'}
						</Typography>

						{/* {landing_page_config?.about?.contact_button_enabled && (
							<Button
								color="primary"
								variant="contained"
								onClick={() => dispatch(goToRoute(routeBuilder(ROUTES.CONTACT)))}
							>
								Contact us
							</Button>
						)} */}
					</Stack>
				</PageWidth>
			</section>
		</>
	);
};

export default AboutUsSection;
