import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { selectPlatformDisplayName } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		paper: {
			padding: theme.spacing(6),
			margin: theme.spacing(3, 0),
			[theme.breakpoints.down('lg')]: {
				margin: theme.spacing(3, 2),
			},
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.secondary.main,
			alignSelf: 'center',
		},
		subtitle: {
			paddingTop: theme.spacing(1.5),
		},
		platformName: {
			paddingBottom: theme.spacing(3),
		},
		flex: {
			width: '100%',
		},
	};
});

const MissingEmailVerificationParamsView = () => {
	const classes = useStyles();

	const platformDisplayName = useAppSelector(selectPlatformDisplayName);

	return (
		<>
			<Paper elevation={2} className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<div className={classes.flex}>
					<Typography component="h1" align="center" variant="h5" className={classes.platformName}>
						{platformDisplayName}
					</Typography>
				</div>
				<Typography component="h2" variant="h5" gutterBottom align="left">
					Uuuups, your link is broken
				</Typography>
				<Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
					Something went wrong the link you are using for confirming your account.
				</Typography>
				<Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
					Please double check if you are using the correct link from the email.
				</Typography>
			</Paper>
		</>
	);
};

export default MissingEmailVerificationParamsView;
