import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Stack } from '@mui/system';
import { CurrencyKind, TransferStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import TransferEarningBreakdown from './TransferEarningBreakdown';
import TransferStatusChip from './TransferStatusChip';

interface TransferDetailsDrawerProps {
	open: boolean;
	status: TransferStatusKind;
	id: string;
	orderId: string;
	netEarnings: any;
	grossEarnings: any;
	platformFees: any;
	currency: CurrencyKind;
	onClose: () => void;
}

const TransferDetailsDrawer = ({
	open,
	id,
	orderId,
	status,
	grossEarnings,
	netEarnings,
	platformFees,
	currency,
	onClose,
}: TransferDetailsDrawerProps) => {
	return (
		<>
			<Drawer
				anchor="right"
				open={open}
				onClose={onClose}
				PaperProps={{
					sx: {
						width: { xs: '100%', sm: '40%' },
						padding: 5,
					},
				}}
			>
				<Box
					sx={{
						pl: 3,
						pr: 2,
						pt: 3,
					}}
				>
					<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3} mb={3}>
						<IconButton onClick={onClose} sx={{ p: 0, m: 0 }}>
							<Stack direction="row" justifyContent="flex-end" alignItems="center">
								<CloseIcon sx={{ color: 'black' }} fontSize="small" />
							</Stack>
						</IconButton>
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							mb={3}
						>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
								<Typography variant="h2">Transfer details</Typography>
								<TransferStatusChip status={status} />
							</Stack>
							<Typography variant="caption">{id}</Typography>
						</Stack>
						<br />
						<br />
						<Stack
							width="100%"
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							mb={3}
						>
							<Typography variant="h3">Order</Typography>
							<Typography variant="caption">{orderId}</Typography>
						</Stack>
						<br />
						<br />
						<Stack
							width="100%"
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={3}
							mb={3}
						>
							<Typography variant="h3">Earning breakdown</Typography>
							<TransferEarningBreakdown
								grossEarning={grossEarnings}
								netEarning={netEarnings}
								orderItems={platformFees}
								currency={currency}
							/>
						</Stack>
					</Stack>
				</Box>
			</Drawer>
		</>
	);
};

export default TransferDetailsDrawer;
