import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { pick } from 'lodash';
import { FormSpy } from 'react-final-form';

import { Form } from 'react-final-form';
import { DatePicker, DateTimePicker } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';
import arrayMutators from 'final-form-arrays';

import Grid from '@mui/material/Grid';

import { createAndStartSingleDirectTransaction } from '@rdv-fo/store/slices/transactionSlice';
import { setSearchBarInputs, selectSearchBarInputs } from '@rdv-fo/store/slices/discoverySlice';
import { sendToastyMessage } from '@rdv-fo/store/slices/uiSlice';
import { parse, format } from '@rdv-fo/app/lib/datetime';
import { validators } from '@rdv-fo/app/lib/validation';
import LoadingButton from '../common/LoadingButton';
import InputsContainer from '../InputsContainer';
import { AVAILABILITY_QUERY_PARAMS } from '@rdv-fo/app/lib/discovery';
import {
	FieldParameterType,
	FieldType,
	MatchingUnitKind,
	Object as SharedObject,
	ObjectType,
	SupplyPrice,
	CurrencyKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { mapFormValuesToFieldInputs, mapFormValuesToParameterInputs } from '@rdv-fo/app/lib/formHelpers';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import FieldInput from '../fields/input/FieldInput';
import { calculateSupplyPrice } from '@rdv-fo/store/slices/discoverySlice';
import { formatPriceAmount } from '../supply/supplyHelpers';
import { FormApi } from 'final-form';

interface CreateDirectMatchFormProps {
	transactionTechName: string;
	supplyId: string;
	supplyPrice: SupplyPrice;
	requestFieldTypes: FieldType[];
	matchParameterTypes: FieldParameterType[];
	isGuest: boolean;
	isMatchConfigurationPricingEnabled: boolean;
	buttonLabel?: string;
	inputsDisabled?: boolean; // FIXME: -> rename to disabled
	sharedObjects: SharedObject[]; // FIXME: -> can this be removed?
	sharedObjectTypes: ObjectType[]; // FIXME: -> can this be removed?
	matchingUnitType: MatchingUnitKind; // FIXME: -> can this be removed?
}
const CreateAndStartDirectTransactionForm = ({
	transactionTechName,
	isGuest,
	supplyId,
	supplyPrice,
	requestFieldTypes,
	matchParameterTypes,
	sharedObjectTypes,
	sharedObjects,
	inputsDisabled = false,
	isMatchConfigurationPricingEnabled,
	matchingUnitType,
	buttonLabel = 'Create match',
}: CreateDirectMatchFormProps) => {
	const dispatch = useAppDispatch();

	const searchBarInputs = useAppSelector(selectSearchBarInputs); // FIXME: GET RID OF THIS DEPENDENCY!
	const [loading, setLoading] = useState(false);
	const [calculatedSupplyPrice, setCalculatedSupplyPrice] = useState<SupplyPrice>(supplyPrice);
	const matchParameterTechNames = matchParameterTypes?.map((mt) => mt.tech_name);
	const priceAffectingTechNames = matchParameterTechNames;
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);

	const handleSubmitCreateAndStartDirectTransactionForm = async (values: any, form: FormApi) => {
		// FIXME: add type for form values
		const checkNewPrice = values?.submit_type === 'calculate_price';
		if (checkNewPrice && isMatchConfigurationPricingEnabled) {
			form.change('submit_type', undefined);
			return handleSubmitCalculateSupplyPrice(values);
		}

		if (checkNewPrice && !isMatchConfigurationPricingEnabled) {
			form.change('submit_type', undefined);
			return;
		}

		if (isGuest) {
			dispatch(sendToastyMessage({ message: 'You have to be logged in to participate', severity: 'info' }));
			return undefined;
		}

		// FIXME: can this loading be removed?
		setLoading(true);

		// EXTRACT MTT FIELD VALUES FROM THE FORM
		const requestFieldsFormValues = pick(
			values,
			requestFieldTypes?.map((ft) => ft.tech_name)
		);

		// COMPOSE MATCHING TOOL FIELD TO ENABLE FILE UPLOAD
		// FIXME: add proper type
		const requestFields: any = requestFieldTypes
			?.map((matchingToolFieldType) => {
				const key = Object.keys(requestFieldsFormValues).find(
					(mttFieldTechName) => mttFieldTechName === matchingToolFieldType.tech_name
				);
				if (key) {
					const matchingToolField = {
						value: requestFieldsFormValues[key],
						field_type: {
							...matchingToolFieldType,
						},
					};

					return matchingToolField;
				}
			})
			.filter((val) => val !== undefined);

		// MAP MATCHING TOOL FIELD FORM VALUE TO RANDEVU FIELD INPUT
		const requestFieldInputs = mapFormValuesToFieldInputs(
			requestFieldsFormValues,
			values,
			sharedObjectTypeIds,
			requestFields
		);

		await dispatch(setSearchBarInputs({ ...values }));

		await dispatch(
			createAndStartSingleDirectTransaction({
				transaction_tech_name: transactionTechName,
				id_supply: supplyId,
				matching_tool_dirty_fields: requestFieldInputs,
				matching_tool_fields: requestFields,
			})
		);
	};

	const isTimeHourBased = MatchingUnitKind.PerHour === matchingUnitType;
	const dateFormat = isTimeHourBased ? 'dd/MM/yy HH:mm' : 'dd. MMM yyyy';

	const isBookingBased = [MatchingUnitKind.PerHour, MatchingUnitKind.PerDay].includes(matchingUnitType);
	const isPerUnitBased = MatchingUnitKind.PerUnit === matchingUnitType;
	const isPerEventBased = MatchingUnitKind.PerEvent === matchingUnitType;

	const consumerSupplyFees = calculatedSupplyPrice?.platform_fee_items?.filter((fee) => fee.is_consumer_fee) ?? [];

	const priceCurrency = calculatedSupplyPrice?.currency ?? CurrencyKind.Eur;

	// For booking based MPs, we want from and to dates to be required
	const fieldValidators: any = {};
	if (isBookingBased && isTimeHourBased) {
		fieldValidators.start_datetime = [validators.required];
		fieldValidators.end_datetime = [validators.required];
	}
	if (isBookingBased && !isTimeHourBased) {
		fieldValidators.start_date = [validators.required];
		fieldValidators.end_date = [validators.required];
	}

	const getInitValues = (searchBarInputs: any) => {
		// FIXME: add proper type for searchBarInputs
		let initValues = searchBarInputs;
		initValues[`${AVAILABILITY_QUERY_PARAMS.QUANTITY}`] = 1;

		matchParameterTypes.forEach((mpt) => {
			Object.assign(initValues, { [mpt.tech_name]: mpt?.initial_value?.value });
		});
		return initValues;
	};

	const handleSubmitCalculateSupplyPrice = (values: any) => {
		// FIXME: add proper type for values
		const matchParameterValues = pick(values, priceAffectingTechNames);

		const match_parameter_inputs = mapFormValuesToParameterInputs(
			matchParameterValues,
			matchParameterValues,
			matchParameterTypes
		);

		dispatch(
			calculateSupplyPrice({
				id_supply: supplyId,
				match_parameters: match_parameter_inputs ?? [],
			})
		);
	};

	const requestFieldTypesWithoutMatchParamTypes = (requestFieldTypes ?? [])?.filter(
		(ft) => !matchParameterTechNames.includes(ft.tech_name)
	);

	useEffect(() => {
		if (supplyPrice !== null) setCalculatedSupplyPrice(supplyPrice);
	}, [supplyPrice]);

	return (
		<Form
			onSubmit={handleSubmitCreateAndStartDirectTransactionForm}
			initialValues={getInitValues({ ...searchBarInputs })}
			validate={(values) => validators.validate(fieldValidators, values)}
			mutators={{
				...arrayMutators,
			}}
		>
			{({ form, values, submitFailed, dirtySinceLastSubmit, handleSubmit, submitting }) => {
				return (
					<form onSubmit={handleSubmit} method="POST" id="search-form">
						<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
							<FormSpy
								// Submit form when match parameter type changes
								subscription={{
									dirtyFieldsSinceLastSubmit: true,
									pristine: true,
									hasValidationErrors: true,
								}}
								onChange={(props) => {
									const { hasValidationErrors, dirtyFieldsSinceLastSubmit, pristine } = props;

									const dirtyFields = Object.keys(dirtyFieldsSinceLastSubmit);
									const isPriceAffectingFieldDirty =
										dirtyFields.filter((f) => priceAffectingTechNames.includes(f))?.length > 0;

									if (isPriceAffectingFieldDirty && !hasValidationErrors) {
										form.change('submit_type', 'calculate_price');
										form.submit();
									}
								}}
							/>

							{matchParameterTypes?.map((parameterType) => (
								<FieldInput
									key={parameterType.tech_name}
									sharedObjects={sharedObjects}
									label={parameterType?.ui_config?.label ?? parameterType?.name}
									helperText={parameterType?.ui_config?.helper_text}
									optionsUiConfig={parameterType?.ui_config?.options ?? []}
									fieldTechName={parameterType?.tech_name}
									inputType={parameterType?.input_type}
									inputOptions={parameterType?.input_options}
									sharedObjectTypes={sharedObjectTypes}
									required={parameterType.is_required}
									disabled={inputsDisabled}
								/>
							))}

							<InputsContainer
								fieldTypes={requestFieldTypesWithoutMatchParamTypes}
								disabled={inputsDisabled}
								showInputs={true}
								treatInputsAsFilters={false}
								sharedObjectTypes={sharedObjectTypes}
								sharedObjects={sharedObjects}
							/>
							{isPerUnitBased || isPerEventBased ? (
								<></>
							) : (
								<Grid container spacing={2}>
									{isTimeHourBased && (
										<>
											<Grid item xs={12} sm={6}>
												<LocalizationProvider dateAdapter={DateFnsUtils}>
													<DateTimePicker
														name={'start_datetime'}
														label="From"
														InputProps={{ onKeyDown: (e) => e.preventDefault() }}
														inputFormat={dateFormat}
														fieldProps={{
															inputVariant: 'outlined',
															parse,
															format,
														}}
													/>
												</LocalizationProvider>
											</Grid>
											<Grid item xs={12} sm={6}>
												<LocalizationProvider dateAdapter={DateFnsUtils}>
													<DateTimePicker
														name={'end_datetime'}
														label="To"
														InputProps={{ onKeyDown: (e) => e.preventDefault() }}
														inputFormat={dateFormat}
														fieldProps={{
															inputVariant: 'outlined',
															parse,
															format,
														}}
													/>
												</LocalizationProvider>
											</Grid>
										</>
									)}
									{!isTimeHourBased && (
										<>
											<Grid item xs={12} sm={6}>
												<LocalizationProvider dateAdapter={DateFnsUtils}>
													<DatePicker
														name={'start_date'}
														label="From"
														InputProps={{ onKeyDown: (e) => e.preventDefault() }}
														fieldProps={{
															inputVariant: 'outlined',
															parse,
															format,
														}}
													/>
												</LocalizationProvider>
											</Grid>
											<Grid item xs={12} sm={6}>
												<LocalizationProvider dateAdapter={DateFnsUtils}>
													<DatePicker
														name={'end_date'}
														label="To"
														InputProps={{ onKeyDown: (e) => e.preventDefault() }}
														fieldProps={{
															inputVariant: 'outlined',
															parse,
															format,
														}}
													/>
												</LocalizationProvider>
											</Grid>
										</>
									)}
								</Grid>
							)}
							<Box sx={{ width: '100%', pb: 2 }}>
								{/* FIXME: refactor to only one LoadingButton component and write it in typescript */}
								{/* @ts-ignore */}
								<LoadingButton
									type="submit"
									size="large"
									disabled={submitting || (submitFailed && !dirtySinceLastSubmit)}
									fullWidth
									loading={submitting}
								>
									{buttonLabel}
								</LoadingButton>
							</Box>
							{isMatchConfigurationPricingEnabled && (
								<>
									<Box sx={{ width: '100%', pb: 2 }}>
										<Typography sx={{ mt: 2 }} variant="body2" align="center">
											You won't be charged yet
										</Typography>
									</Box>

									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">{`${formatPriceAmount({
											amount: calculatedSupplyPrice?.unit_price,
											currency: priceCurrency,
										})} x ${values?.qty ?? 1}`}</Typography>
										<Typography fontWeight="bold">
											{formatPriceAmount({
												amount: calculatedSupplyPrice?.unit_price * calculatedSupplyPrice?.qty,
												currency: priceCurrency,
											})}
										</Typography>
									</Stack>

									{calculatedSupplyPrice?.addon_items?.length > 0 &&
										calculatedSupplyPrice?.addon_items.map((add_on) => (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">{add_on?.description}</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: add_on?.value,
														currency: priceCurrency,
													})}
												</Typography>
											</Stack>
										))}
									{calculatedSupplyPrice?.discount_items?.length > 0 &&
										calculatedSupplyPrice?.discount_items.map((discount) => (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">{discount?.description}</Typography>
												<Typography fontWeight="bold">
													-
													{formatPriceAmount({
														amount: discount?.value,
														currency: priceCurrency,
													})}
												</Typography>
											</Stack>
										))}
									<Divider flexItem />

									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">Total before taxes</Typography>
										<Typography fontWeight="bold">
											{formatPriceAmount({
												amount: calculatedSupplyPrice?.total_before_tax,
												currency: priceCurrency,
											})}
										</Typography>
									</Stack>

									{calculatedSupplyPrice?.tax_items?.length > 0 &&
										calculatedSupplyPrice?.tax_items.map((tax) => (
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">{tax?.description}</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: tax?.value,
														currency: priceCurrency,
													})}
												</Typography>
											</Stack>
										))}
									<Divider flexItem />
									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">Total</Typography>
										<Typography fontWeight="bold">
											{formatPriceAmount({
												amount:
													calculatedSupplyPrice?.total_before_tax +
													calculatedSupplyPrice?.total_taxes,
												currency: priceCurrency,
											})}
										</Typography>
									</Stack>

									{consumerSupplyFees.length > 0 && (
										<>
											{consumerSupplyFees.map((consumer_fee) => (
												<Stack
													direction="row"
													sx={{ width: '100%' }}
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={2}
												>
													<Typography fontWeight="bold">
														{consumer_fee?.description}
													</Typography>
													<Typography fontWeight="bold">
														{formatPriceAmount({
															amount: consumer_fee?.value,
															currency: priceCurrency,
														})}
													</Typography>
												</Stack>
											))}
											<Divider flexItem />
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">Adjusted total</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount:
															calculatedSupplyPrice?.total_before_tax +
															calculatedSupplyPrice?.total_taxes +
															calculatedSupplyPrice?.total_fees,
														currency: priceCurrency,
													})}
												</Typography>
											</Stack>
										</>
									)}
									<Typography sx={{ mt: 2 }} variant="body2" align="center">
										Additional cost may apply
									</Typography>
								</>
							)}
						</Stack>
					</form>
				);
			}}
		</Form>
	);
};

export default CreateAndStartDirectTransactionForm;
