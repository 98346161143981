const routeBuilder = (rawRoute, params) => {
	let route = rawRoute;
	if (!params) return route;

	params.forEach((param) => {
		route = route.replace(param[0], param[1]);
	});

	return route;
};

export default routeBuilder;
