import { Divider, Stack, Typography } from '@mui/material';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import { CurrencyKind, Order, PriceItemKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import React from 'react';

interface OrderDetailsSummaryProps {
	order: Order;
	consumerName: string;
	consumerUserFirstLastName: string;
}

const OrderDetailsSummary = ({ order, consumerName, consumerUserFirstLastName }: OrderDetailsSummaryProps) => {
	const orderItemsWithoutPrice = order?.order_items?.filter((oi) => oi.type !== PriceItemKind.PriceWithQty);

	const currency = order?.currency ?? CurrencyKind.Eur;
	return (
		<Stack direction="column" spacing={1}>
			<Stack
				direction="row"
				sx={{ width: '100%' }}
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={2}
			>
				<Typography variant="body1">Total articles</Typography>
				<Typography variant="body2">
					{formatPriceAmount({
						amount: order?.gross_value,
						currency,
					})}
				</Typography>
			</Stack>
			<Divider />
			<Stack
				direction="row"
				sx={{ width: '100%' }}
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={2}
			>
				<Typography variant="body2" fontWeight="bold">
					Subtotal
				</Typography>
				<Typography variant="body2">
					{formatPriceAmount({
						amount: order?.gross_value,
						currency,
					})}
				</Typography>
			</Stack>
			{orderItemsWithoutPrice.map((oi) => (
				<Stack
					direction="row"
					sx={{ width: '100%' }}
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={2}
				>
					<Typography variant="body2">{oi.description}</Typography>
					<Typography variant="body2">
						{formatPriceAmount({
							amount: oi.value,
							currency,
						})}
					</Typography>
				</Stack>
			))}
			<Divider />
			<Stack
				direction="row"
				sx={{ width: '100%' }}
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={2}
			>
				<Typography variant="body2" fontWeight="bold">
					Total
				</Typography>
				<Typography variant="body2">
					{formatPriceAmount({
						amount: order?.net_value,
						currency,
					})}
				</Typography>
			</Stack>
			<Divider />
			<Stack
				direction="row"
				sx={{ width: '100%' }}
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={2}
			>
				<Typography variant="body2" fontWeight="bold">
					Consumer
				</Typography>
				<Typography variant="body2">{`${consumerName} (${consumerUserFirstLastName})`}</Typography>
			</Stack>
		</Stack>
	);
};

export default OrderDetailsSummary;
