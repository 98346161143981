export const VALIDATION_MESSAGE = {
	REGEX_PATTERN_VIOLATION: 'The input does not meet the required format',
	REQUIRED_VALUE_MISSING_VIOLATION: 'This field is required',
	MIN_VALUE_VIOLATION: 'The value has to be equal to or great than {min_value}',
	MAX_VALUE_VIOLATION: 'The value has to be equal to or less than {max_value}',
	MIN_LENGTH_VIOLATION: 'Minimum {min_length} characters required',
	MAX_LENGTH_VIOLATION: 'Maximum {max_length} character(s) allowed',
	MAX_FILE_SIZE_VIOLATION: 'Maximum size of {max_size} MB per file has been exceeded',
	MAX_NUMBER_OF_FILES_EXCEEDED: 'Maximum allowed number of files is {files_count}',
	MIN_NUMBER_OF_FILES_NOT_SATISFIED: 'Minimum required number of files is {files_count}',
	MAX_NUMBER_OF_OBJECTS_EXCEEDED: 'Maximum allowed number of objects is {files_count}',
	MIN_NUMBER_OF_OBJECTS_NOT_SATISFIED: 'Minimum required number of objects is {files_count}',
	NOTHING_SELECTED_VIOLATION: 'Please select an option',
	MULTI_SELECT_MIN_SELECTED_VIOLATION: 'At least {min_selected} required',
	MULTI_SELECT_MAX_SELECTED_VIOLATION: 'Maximum {max_selected} allowed',
	SPECIAL_CHARACTERS_VIOLATION: 'Special characters are not allowed',
	EMAIL_VIOLATION: 'Enter valid email',
	NO_NUMBER_VIOLATION: 'Must include at least one digit',
	NO_UPPERCASE_VIOLATION: 'Must must include at least one uppercase character',
	NO_LOWERCASE_VIOLATION: 'Must include at least one lowercase character',
	NO_SPECIAL_CHARACTERS_VIOLATION: 'Must include at least one special character',
	MINIMUM_8_CHARACTERS_VIOLATION: 'Must contain at least 8 characters ',
};
