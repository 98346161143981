import AuthPageStyle from '@rdv-fo/components/layout/AuthPageStyle';
import SignInForm from '@rdv-fo/components/security/SignInForm';
import { SecurityPageStyleKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { selectSignInPagePageConfig } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const SignInPage = () => {
	const { style, image_url, image_position, title, eyebrow, description, form } = useAppSelector(selectSignInPagePageConfig) ?? {};

	return (
		<AuthPageStyle
			style={style}
			imagePosition={image_position}
			image={image_url}
		>
			<SignInForm title={title} eyebrow={eyebrow} description={description} form={form} sx={SecurityPageStyleKind.Simple !== style ? { paddingLeft: 0, marginLeft: 0 } : undefined} />
		</AuthPageStyle>
	);
};

export default SignInPage;
