import randevu from '@rdv-fo/services/randevuApi';
import { isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import {
	FieldInput,
	MatchTypeRoleKind,
	MutationAddSupplyToShoppingCartArgs,
	MutationRemoveShoppingCartItemArgs,
	MutationTriggerManualTransactionTransitionArgs,
	QueryMyShoppingCartOrderArgs,
	QueryMyShoppingCartOrdersArgs,
	ShoppingCart,
	ShoppingCartFilter,
	ShoppingCartLight,
	ShoppingCartOrder,
	ShoppingCartOrderLight,
	TransactionFilter,
	TransactionKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { sendSuccessToasty, sendUnexpectedErrorToasty } from './uiSlice';
import type { AppDispatch, RootState } from '@rdv-fo/store/configureStore';
import THUNKS from '../thunkMap';
import { isEmpty } from 'lodash';

interface ShoppingCartSliceState {
	items: ShoppingCartLight[] | ShoppingCartOrderLight[];
	item: ShoppingCart | null;
	order: ShoppingCartOrder | null;
	shoppingCart: ShoppingCart | null;
	loading: boolean;
	errors: any | null; // FIXME: set proper type
}

const initialState: ShoppingCartSliceState = {
	items: [],
	item: null,
	shoppingCart: null,
	order: null,
	loading: false,
	errors: null,
};

const slice = createSlice({
	name: 'shoppingCart',
	initialState,
	reducers: {
		loadMyActiveShoppingCartRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.item = null;
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		loadMyActiveShoppingCartFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		activeShoppingCartLoaded: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.item = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		loadMyShoppingCartRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.shoppingCart = null;
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		loadMyShoppingCartFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		shoppingCartLoaded: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<ShoppingCart>) => {
			shoppingCart.shoppingCart = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		loadMyShoppingCartsRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		loadMyShoppingCartsFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		shoppingCartsLoaded: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<ShoppingCartLight[]>) => {
			shoppingCart.items = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		loadMyShoppingCartOrdersRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		loadMyShoppingCartOrdersFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		shoppingCartOrdersLoaded: (
			shoppingCart: ShoppingCartSliceState,
			action: PayloadAction<ShoppingCartOrderLight[]>
		) => {
			shoppingCart.items = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},

		addSupplyToShoppingCartRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		addSupplyToShoppingCartFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		supplyAddedToShoppingCart: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.item = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		removeItemFromShoppingCartRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		removeItemFromShoppingCartFaild: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		itemRemovedFromShoppingCart: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.item = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		triggerActiveShoppingCartManualTransitionRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		triggerActiveShoppingCartManualTransitionFailed: (
			shoppingCart: ShoppingCartSliceState,
			action: PayloadAction<any>
		) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		activeShoppingCartManualTransitionTriggered: (
			shoppingCart: ShoppingCartSliceState,
			action: PayloadAction<any>
		) => {
			shoppingCart.item = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		triggerManualTransitionRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		triggerManualTransitionFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		manualTransitionTriggered: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.shoppingCart = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		triggerOrderManualTransitionRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		triggerOrderManualTransitionFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		orderManualTransitionTriggered: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.order = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		manualTransitionTriggeredAndNewShoppingCartCreated: (
			shoppingCart: ShoppingCartSliceState,
			action: PayloadAction<any>
		) => {
			shoppingCart.item = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		updateShoppingCartItemRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		updateShoppingCartItemFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		shoppingCartItemUpdated: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.item = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
		loadShoppingCartOrderRequested: (shoppingCart: ShoppingCartSliceState) => {
			shoppingCart.order = null;
			shoppingCart.loading = true;
			shoppingCart.errors = null;
		},
		loadShoppingCartOrderFailed: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<any>) => {
			shoppingCart.loading = false;
			shoppingCart.errors = action.payload;
		},
		shoppingCartOrderLoaded: (shoppingCart: ShoppingCartSliceState, action: PayloadAction<ShoppingCartOrder>) => {
			shoppingCart.order = action.payload;
			shoppingCart.errors = null;
			shoppingCart.loading = false;
		},
	},
});

export const {
	loadMyActiveShoppingCartRequested,
	loadMyActiveShoppingCartFailed,
	activeShoppingCartLoaded,
	addSupplyToShoppingCartRequested,
	addSupplyToShoppingCartFailed,
	supplyAddedToShoppingCart,
	removeItemFromShoppingCartRequested,
	removeItemFromShoppingCartFaild,
	itemRemovedFromShoppingCart,
	triggerManualTransitionRequested,
	triggerActiveShoppingCartManualTransitionRequested,
	triggerActiveShoppingCartManualTransitionFailed,
	activeShoppingCartManualTransitionTriggered,
	triggerManualTransitionFailed,
	manualTransitionTriggered,
	updateShoppingCartItemRequested,
	updateShoppingCartItemFailed,
	shoppingCartItemUpdated,
	loadMyShoppingCartsRequested,
	loadMyShoppingCartsFailed,
	shoppingCartsLoaded,
	loadMyShoppingCartRequested,
	loadMyShoppingCartFailed,
	shoppingCartLoaded,
	loadMyShoppingCartOrdersRequested,
	loadMyShoppingCartOrdersFailed,
	shoppingCartOrdersLoaded,
	loadShoppingCartOrderRequested,
	loadShoppingCartOrderFailed,
	shoppingCartOrderLoaded,
	manualTransitionTriggeredAndNewShoppingCartCreated,
	triggerOrderManualTransitionRequested,
	triggerOrderManualTransitionFailed,
	orderManualTransitionTriggered,
} = slice.actions;

export default slice.reducer;

/////////////////////
// ACTION CREATORS //
/////////////////////

export const loadMyActiveShoppingCart = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadMyActiveShoppingCartRequested());

	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

	const { shopping_cart, errors } = await randevuService.transactions.myActiveShoppingCart();

	if (isSessionExpired(errors))
		return handleSessionExpired({
			dispatch,
			state: getState(),
			failedAction: loadMyActiveShoppingCartFailed,
			reauthenticationCallback: {
				callbackThunkKey: THUNKS.LOAD_MY_ACTIVE_SHOPPING_CART.key,
				payload: {},
			},
		} as any);

	if (errors) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadMyActiveShoppingCartFailed(errors));
	}

	return dispatch(activeShoppingCartLoaded({ ...shopping_cart! }));
};

interface LoadMyShoppingCartRequest {
	idCart: string;
}

export const loadMyShoppingCart =
	({ idCart }: LoadMyShoppingCartRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(loadMyShoppingCartRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { shopping_cart, errors } = await randevuService.transactions.myShoppingCart({ id_cart: idCart });

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyShoppingCartFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_ACTIVE_SHOPPING_CART.key,
					payload: {},
				},
			} as any);

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMyShoppingCartFailed(errors));
		}

		return dispatch(shoppingCartLoaded(shopping_cart!));
	};

export const loadMyShoppingCartOrder =
	({ id_cart_order }: QueryMyShoppingCartOrderArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(loadShoppingCartOrderRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { transaction, errors } = await randevuService.transactions.myShoppingCartOrder({
			id_cart_order,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadShoppingCartOrderFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_SHOPPING_CART_ORDER.key,
					payload: { id_cart_order },
				},
			} as any);

		if (!isEmpty(errors) || !transaction) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadShoppingCartOrderRequested(errors));
		}

		return dispatch(shoppingCartOrderLoaded(transaction!));
	};

export const loadMyShoppingCartOrders =
	({ where }: QueryMyShoppingCartOrdersArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(loadMyShoppingCartOrdersRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { transactions: shopping_carts, errors } = await randevuService.transactions.myShoppingCartOrders({
			where,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyShoppingCartOrdersFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_SHOPPING_CART_ORDERS.key,
					payload: { where },
				},
			} as any);

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMyShoppingCartOrdersFailed(errors));
		}

		return dispatch(shoppingCartOrdersLoaded(shopping_carts!));
	};

interface LoadMyShoppingCartsRequest {
	where: ShoppingCartFilter;
	limit: number;
	offset: number;
}

export const loadMyMultiDirectTransactions =
	({ where, limit = 20, offset = 0 }: LoadMyShoppingCartsRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(loadMyShoppingCartsRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { transactions, errors } = await randevuService.transactions.myMultiDirectTransactions({
			where,
			limit,
			offset,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyShoppingCartsFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_SHOPPING_CARTS.key,
					payload: { where, limit, offset },
				},
			} as any);

		if (!isEmpty(errors)) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMyShoppingCartsFailed(errors));
		}

		return dispatch(shoppingCartsLoaded(transactions));
	};
export const addSupplyToShoppingCart =
	({ id_cart, id_supply, fields }: MutationAddSupplyToShoppingCartArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(addSupplyToShoppingCartRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { supply_added, errors } = await randevuService.transactions.addSupplyToShoppingCart({
			id_cart,
			id_supply,
			fields,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: addSupplyToShoppingCartFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.ADD_SUPPLY_TO_SHOPPING_CART.key,
					payload: { id_cart, id_supply, fields },
				},
			});

		if (errors || supply_added === false) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(addSupplyToShoppingCartFailed(errors));
		}

		const { shopping_cart, errors: fetchErrors } = await randevuService.transactions.myActiveShoppingCart();

		if (fetchErrors || shopping_cart === null) {
			dispatch(sendUnexpectedErrorToasty(fetchErrors));
			return dispatch(addSupplyToShoppingCartFailed(errors));
		}

		dispatch(sendSuccessToasty('Added to cart'));

		return dispatch(supplyAddedToShoppingCart({ ...shopping_cart! }));
	};

interface UpdateShoppingCartItemRequest {
	cartId: string;
	itemId: string;
	fields: FieldInput[];
}

export const updateShoppingCartItem =
	({ cartId, itemId, fields }: UpdateShoppingCartItemRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(updateShoppingCartItemRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { shopping_cart_item, errors } = await randevuService.transactions.updateShoppingCartItem({
			id_cart: cartId,
			id_item: itemId,
			fields,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: updateShoppingCartItemFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.UPDATE_SHOPPING_CART_ITEM.key,
					payload: { itemId, cartId, fields },
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(updateShoppingCartItemFailed(errors));
		}

		const { shopping_cart, errors: fetchErrors } = await randevuService.transactions.myActiveShoppingCart();

		if (fetchErrors) {
			dispatch(sendUnexpectedErrorToasty(fetchErrors));
			return dispatch(removeItemFromShoppingCartFaild(errors));
		}
		if (shopping_cart === null) {
			return dispatch(removeItemFromShoppingCartFaild(errors));
		}

		return dispatch(shoppingCartItemUpdated({ ...shopping_cart! }));
	};

interface RemoveItemFromShoppingCartRequest {
	cartId: string;
	itemId: string;
}

export const removeItemFromShoppingCart =
	({ cartId, itemId }: RemoveItemFromShoppingCartRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(removeItemFromShoppingCartRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { removed, errors } = await randevuService.transactions.removeItemFromShoppingCart({
			id_cart: cartId,
			id_item: itemId,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: addSupplyToShoppingCartFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.REMOVE_ITEM_FROM_SHOPPING_CART.key,
					payload: { cartId, itemId },
				},
			});

		if (errors || removed === false) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(removeItemFromShoppingCartFaild(errors));
		}

		const { shopping_cart, errors: fetchErrors } = await randevuService.transactions.myActiveShoppingCart();

		if (fetchErrors || shopping_cart === null) {
			dispatch(sendUnexpectedErrorToasty(fetchErrors));
			return dispatch(removeItemFromShoppingCartFaild(errors));
		}

		return dispatch(itemRemovedFromShoppingCart({ ...shopping_cart! }));
	};

interface TriggerActiveShoppingCartManualTransitionRequest {
	idTransaction: string;
	transitionTechName: string;
}

export const triggerActiveShoppingCartManualTransition =
	({ idTransaction, transitionTechName }: TriggerActiveShoppingCartManualTransitionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(triggerActiveShoppingCartManualTransitionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
			id_transaction: idTransaction,
			transition_tech_name: transitionTechName,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: triggerManualTransitionFailed });

		if (errors || triggered === false) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(triggerActiveShoppingCartManualTransitionFailed(errors));
		}

		const { shopping_cart, errors: fetchErrors } = await randevuService.transactions.myActiveShoppingCart();

		if (fetchErrors) {
			dispatch(sendUnexpectedErrorToasty(fetchErrors));
			return dispatch(triggerActiveShoppingCartManualTransitionFailed(errors));
		}

		if (!shopping_cart) {
			const { my_transaction_types: participantTransactionTypes, errors: participantErrors } =
				await randevuService.transactions.myTransactionTypes();

			const multipleDirectTT = participantTransactionTypes?.find(
				(tt) => tt.type === TransactionKind.MultipleDirect && tt.my_role === MatchTypeRoleKind.Consumer
			);
			if (participantErrors) {
				dispatch(sendUnexpectedErrorToasty(participantErrors));
				return dispatch(triggerActiveShoppingCartManualTransitionFailed(participantErrors));
			}

			const { shopping_cart: newShoppingCart, errors } = await randevuService.transactions.createShoppingCart({
				fields: [],
				transaction_tech_name: multipleDirectTT?.tech_name,
			});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(triggerActiveShoppingCartManualTransitionFailed(errors));
			}

			return dispatch(manualTransitionTriggeredAndNewShoppingCartCreated({ ...newShoppingCart! }));
		}

		return dispatch(activeShoppingCartManualTransitionTriggered({ ...shopping_cart! }));
	};

export const triggerMultiDirectOrderManualTransition =
	({ id_transaction, transition_tech_name }: MutationTriggerManualTransactionTransitionArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(triggerOrderManualTransitionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
			id_transaction: id_transaction,
			transition_tech_name: transition_tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: triggerManualTransitionFailed });

		if (errors || triggered === false) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(triggerOrderManualTransitionFailed(errors));
		}

		const { transaction: shopping_cart, errors: fetchErrors } =
			await randevuService.transactions.myShoppingCartOrder({
				id_cart_order: id_transaction,
			});

		if (fetchErrors) {
			dispatch(sendUnexpectedErrorToasty(fetchErrors));
			return dispatch(triggerOrderManualTransitionFailed(errors));
		}

		return dispatch(orderManualTransitionTriggered(shopping_cart));
	};
interface TriggerShoppingCartManualTransitionRequest {
	idTransaction: string;
	transitionTechName: string;
}

export const triggerShoppingCartManualTransition =
	({ idTransaction, transitionTechName }: TriggerShoppingCartManualTransitionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(triggerManualTransitionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
			id_transaction: idTransaction,
			transition_tech_name: transitionTechName,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: triggerManualTransitionFailed });

		if (errors || triggered === false) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(triggerManualTransitionFailed(errors));
		}

		const { shopping_cart: currentShoppingCart, errors: currentShoppingCartErrors } =
			await randevuService.transactions.myShoppingCart({ id_cart: idTransaction });

		const { shopping_cart: activeShoppingCart, errors: fetchErrors } =
			await randevuService.transactions.myActiveShoppingCart();

		if (fetchErrors) {
			dispatch(sendUnexpectedErrorToasty(fetchErrors));
			return dispatch(triggerManualTransitionFailed(errors));
		}
		if (currentShoppingCartErrors) {
			dispatch(sendUnexpectedErrorToasty(currentShoppingCartErrors));
			return dispatch(triggerManualTransitionFailed(errors));
		}

		if (!activeShoppingCart) {
			dispatch(manualTransitionTriggered({ ...currentShoppingCart! }));
			const { my_transaction_types: participantTransactionTypes, errors: participantErrors } =
				await randevuService.transactions.myTransactionTypes();

			const multipleDirectTT = participantTransactionTypes?.find(
				(tt) => tt.type === TransactionKind.MultipleDirect && tt.my_role === MatchTypeRoleKind.Consumer
			);
			if (participantErrors) {
				dispatch(sendUnexpectedErrorToasty(participantErrors));
				return dispatch(triggerManualTransitionFailed(participantErrors));
			}

			const { shopping_cart: newShoppingCart, errors } = await randevuService.transactions.createShoppingCart({
				fields: [],
				transaction_tech_name: multipleDirectTT?.tech_name,
			});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(triggerManualTransitionFailed(errors));
			}

			return dispatch(manualTransitionTriggeredAndNewShoppingCartCreated({ ...newShoppingCart! }));
		}

		return dispatch(manualTransitionTriggered({ ...currentShoppingCart! }));
	};
/////////////////////
//   SELECTORS     //
/////////////////////
export const selectMyMultiDirectTransactions = (state: RootState) => state.shoppingCart.items;
export const selectMyActiveShoppingCart = (state: RootState) => state.shoppingCart.item;
export const selectMyShoppingCart = (state: RootState) => state.shoppingCart.shoppingCart;
export const selectMyOrder = (state: RootState) => state.shoppingCart.order;
export const selectLoading = (state: RootState) => state.shoppingCart.loading;
