const isFileSizeAcceptable = ({ maxAllowedSize, fileSize }) => {
	if (!maxAllowedSize) return true;

	let maxFileSizeInBytes = maxAllowedSize * 1000000;

	if (fileSize > maxFileSizeInBytes) return false;

	return true;
};

export default isFileSizeAcceptable;
