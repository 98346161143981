import gql from 'graphql-tag';
import { print } from 'graphql';
import { MutationReferParticipantUserArgs } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const REFER_PARTICIPANT_USER = gql`
	mutation REFER_PARTICIPANT_USER(
		$email: String!
		$first_name: String!
		$last_name: String!
		$participant_tech_name: String!
		$fields: [FieldInput!]
	) {
		referParticipantUser(
			email: $email
			first_name: $first_name
			last_name: $last_name
			participant_tech_name: $participant_tech_name
			fields: $fields
		) {
			created_at
			status
			referee {
				id
				type {
					name
				}
				user {
					id
					first_name
					last_name
					email
				}
			}
		}
	}
`;

const referParticipantUser = ({
	participant_tech_name,
	first_name,
	last_name,
	email,
	fields,
}: MutationReferParticipantUserArgs) => {
	return JSON.stringify({
		query: print(REFER_PARTICIPANT_USER),
		variables: {
			email: String(email),
			first_name: String(first_name),
			last_name: String(last_name),
			participant_tech_name: String(participant_tech_name),
			fields,
		},
	});
};

export { referParticipantUser };
