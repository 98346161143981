import { Form } from 'react-final-form';

import Button from '@mui/material/Button';
import LoadingButton from './LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationModal = ({ children, title, open, onConfirm, onCancel, confirmDisabled }) => {
	const onCancelClick = () => {
		onCancel();
	};

	return (
		<Form onSubmit={onConfirm}>
			{({ handleSubmit, submitting, submitFailed, dirtySinceLastSubmit }) => {
				return (
					<form onSubmit={handleSubmit} method="POST" id="confirmation-form">
						<Dialog
							aria-labelledby="confirmation-form"
							open={open}
							fullWidth
							maxWidth="xs"
							BackdropProps={{
								sx: { backdropFilter: 'blur(4px)', backgroundColor: 'rgba(0,0,30,0.5)' },
							}}
							PaperProps={{
								style: {
									padding: '16px',
									borderRadius: 15,
								},
							}}
						>
							<DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>

							<DialogContent>{children}</DialogContent>

							<DialogActions
								sx={{
									display: 'flex',
									flexDirection: 'column',
									paddingTop: 2,
									paddingBottom: 2,
									paddingLeft: 3,
									paddingRight: 3,
								}}
							>
								<LoadingButton
									type="submit"
									color="primary"
									variant="contained"
									form="confirmation-form"
									loading={submitting}
									disabled={confirmDisabled || submitting || (submitFailed && !dirtySinceLastSubmit)}
									fullWidth
								>
									Confirm
								</LoadingButton>
								<br />
								<Button onClick={onCancelClick} color="inherit">
									Cancel
								</Button>
							</DialogActions>
						</Dialog>
					</form>
				);
			}}
		</Form>
	);
};

export default ConfirmationModal;
