import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { Link as RouterLink } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { selectDefaultTransactionType, setSelectedTransactionType } from '@rdv-fo/store/slices/transactionSlice';

const ProviderDashboardBar = () => {
	const dispatch = useAppDispatch();
	const defaultTransactionType = useAppSelector(selectDefaultTransactionType);

	const [raised, setRaised] = useState(false);
	const toggleRaised = () => setRaised(!raised);

	return (
		<>
			<Card
				sx={{ width: '100%', borderRadius: 2 }}
				raised={raised}
				onMouseOver={toggleRaised}
				onMouseOut={toggleRaised}
			>
				<CardContent
					sx={{
						paddingTop: 5,
						paddingLeft: 3,
						paddingRight: 3,
						'&:last-child': {
							paddingBottom: 5,
						},
					}}
				>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<Button
								size="large"
								color="primary"
								variant="contained"
								sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}
								component={RouterLink}
								to={routeBuilder(ROUTES.MY_SUPPLIES)}
								onClick={() => dispatch(setSelectedTransactionType(defaultTransactionType))}
							>
								Manage your supply
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

export default ProviderDashboardBar;
