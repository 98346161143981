import * as fileMutations from './fileMutations';
import {
	MutationPrepareFileUploadArgs,
	InputKind,
	FileKind,
	FileUpload,
	FieldInput,
	Field,
	ObjectField,
	ObjectSetField,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RdvFileFieldInput, RdvFileFieldValue } from '@rdv-fo/services/randevuApi/types/field/';

interface UploadToFileStorageArgs {
	destination_url: string;
	file: File; // JS FILE
}

interface UploadFileArgs {
	parentPropertyName: string;
	name?: string;
	description?: string;
	is_public: boolean
	file: File; // JS FILE
}

interface UploadedFile {
	/** propertyName is the "parent" that holds the file. E.g. participant's field named mainImage */
	parentPropertyName: string;
	id_file: string;
	url: string;
}

interface UploadFieldsFilesArgs {
	field_inputs: FieldInput[];
	fields: Field[];
}

interface UploadFileForFieldArgs {
	field_input: FieldInput;
	is_public: boolean;
	field: Field;
}

class FilesService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}
	/**
	 * Uploads file to an object storage (e.g. S3)
	 * @param destination_url: URL where the file should be uploaded
	 * @param file: physical file (JS file) that should be uploaded
	 * @returns
	 */
	async uploadToFileStorage({ destination_url, file }: UploadToFileStorageArgs) {
		const headers = {
			'Content-Type': file.type,
		};

		const response = await fetch(destination_url, {
			method: 'PUT',
			headers,
			body: file,
		});

		return response.ok;
	}

	/**
	 * Return randevu file type for a native file
	 * @param file JS File that was selected via file explorer
	 * @returns randevu file type
	 */
	getFileType(file: File) {
		const result = {
			type: FileKind.Image,
			extension: '',
		};

		if (file.type.startsWith('image/')) {
			result.type = FileKind.Image;
			result.extension = file.type.split('/')[1];

			return result;
		}


		if (file.type.startsWith('application/') || file.type === 'text/csv') {
			result.type = FileKind.Document;
			result.extension = file.type.split('/')[1];

			return result;
		}

		return result;
	}

	async prepareFileUpload({ file_name, type, extension, name, description, is_public }: MutationPrepareFileUploadArgs) {
		const payload = fileMutations.prepareFileUpload({ file_name, type, extension, name, description, is_public });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { upload_config: data?.prepareFileUpload as FileUpload, errors };
	}
	async uploadFile({
		parentPropertyName,
		name,
		description,
		file,
		is_public
	}: UploadFileArgs): Promise<{ uploaded_file: UploadedFile | null; errors: any }> {
		const typeDetails = this.getFileType(file);

		if (typeDetails.type === FileKind.Image && typeDetails.extension === '')
			throw new Error('Cannot upload the file because the file extension is unknown!');

		const { upload_config, errors: prepareFileUploadErrors } = await this.prepareFileUpload({
			name: name,
			description: description,
			file_name: file.name,
			extension: typeDetails.extension,
			type: typeDetails.type,
			is_public
		});

		if (prepareFileUploadErrors) {
			// expected is only session expired error
			return { uploaded_file: null, errors: prepareFileUploadErrors };
		}

		// TODO: handle error when uploading to a file storage
		const uploaded = await this.uploadToFileStorage({ destination_url: upload_config.upload_url, file });

		const uploaded_file = {
			parentPropertyName: parentPropertyName,
			id_file: upload_config.id,
			url: upload_config.upload_url,
		};

		return { uploaded_file, errors: null };
	}

	public async doFileUploadForSingleFieldInput({ field_input, field }: UploadFileForFieldArgs): Promise<FieldInput> {
		if ([InputKind.Image, InputKind.Document].includes(field.field_type.input_type)) {
			if (field_input.value instanceof File) {
				// Upload file
				const { uploaded_file, errors } = await this.uploadFile({
					parentPropertyName: field_input.tech_name ?? '',
					file: field_input.value,
					is_public: field.field_type.input_options.is_public
				});

				field_input.value = { id_file: uploaded_file?.id_file } as RdvFileFieldInput;
			}
		}

		if ([InputKind.ImageSet, InputKind.DocumentSet].includes(field.field_type.input_type)) {
			if (Array.isArray(field_input.value)) {
				// Loop through the array look if any JS file is provided, if so, upload it
				await Promise.all(
					field_input.value.map(async (file: File | RdvFileFieldValue, index: number) => {
						if (file instanceof File) {
							// Upload file
							const { uploaded_file, errors } = await this.uploadFile({
								parentPropertyName: `${field_input.tech_name}[${index}]`,
								is_public: field.field_type.input_options.is_public,
								file: file,
							});

							field_input.value[index] = { id_file: uploaded_file?.id_file } as RdvFileFieldInput;
						}
					})
				);
			}
		}

		if ([InputKind.Object].includes(field.field_type.input_type)) {
			// Upload files for object fields
			// console.log(' Handle file upload for object input type');

			const object_field = field as ObjectField;
			const object_field_inputs = field_input.value.fields;

			let fields = object_field.value?.fields ?? [];

			if (fields.length === 0) {
				fields = object_field?.object_type?.fields?.map((ft) => ({
					value: null,
					field_type: ft,
					id: 'workaround-id',
					current_accesses: {},
				}));
			}

			await this.uploadFieldInputsFiles({
				field_inputs: object_field_inputs,
				fields: fields,
			});
		}
		if ([InputKind.ObjectSet].includes(field.field_type.input_type)) {
			// console.log('Upload files for object fields within object set');
			const object_set_field = field as ObjectSetField;
			const object_set_field_input: any = field_input.value;

			await Promise.all(
				object_set_field_input.map(async (object_field_input: any, index: number) => {
					let fields = object_set_field?.value?.[index]?.fields ?? [];

					if (fields.length === 0) {
						fields = object_set_field?.object_type?.fields?.map((ft) => ({
							value: null,
							field_type: ft,
							id: 'workaround-id',
							current_accesses: {},
						}));
					}

					await this.uploadFieldInputsFiles({
						field_inputs: object_field_input.fields,
						fields: fields,
					});
				})
			);
		}

		return field_input;
	}

	async uploadFieldInputsFiles({
		field_inputs,
		fields,
	}: UploadFieldsFilesArgs): Promise<{ field_inputs: FieldInput[] | null; errors: any }> {
		if (field_inputs == undefined || field_inputs.length === 0 || !Array.isArray(fields))
			return { field_inputs: [], errors: null };

		if (fields === undefined || fields.length === 0)
			throw Error('Cannot upload files for fields inputs because the fields are missing');

		if (field_inputs.length > fields.length)
			throw Error(
				`Cannot upload files for fields because less fields ${fields.length} are provided than fields inputs (${field_inputs.length})`
			);

		// TODO: HANDLE SESSION EXPIRED ERRORS

		// upload files in parallel
		const field_inputs_with_uploaded_files = await Promise.all(
			field_inputs?.map(async (field_input: FieldInput) => {
				const corresponding_field = fields.find(
					(field) => field.field_type.tech_name === field_input.tech_name
				);

				if (!corresponding_field) throw new Error(`Cannot find field for field input ${field_input.tech_name}`);

				const field_input_with_uploaded_file = await this.doFileUploadForSingleFieldInput({
					field_input,
					is_public: corresponding_field?.field_type?.input_options?.is_public,
					field: corresponding_field,
				});

				return field_input_with_uploaded_file;
			})
		);

		return {
			field_inputs: field_inputs_with_uploaded_files,
			errors: null,
		};
	}
}

export default FilesService;
