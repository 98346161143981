import { Divider, Stack, Typography } from '@mui/material';
import { CurrencyKind, PriceItemKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import React from 'react';
import { formatPriceAmount } from '../supply/supplyHelpers';

interface TransferEarningBreakdownProps {
	netEarning: any;
	grossEarning: any;
	orderItems: any[];
	currency: CurrencyKind;
}

const TransferEarningBreakdown = ({
	netEarning,
	grossEarning,
	orderItems,
	currency,
}: TransferEarningBreakdownProps) => {
	const formattedGrossEarnings = formatPriceAmount({
		amount: grossEarning,
		currency,
	});
	const formattedNetEarnings = formatPriceAmount({
		amount: netEarning,
		currency,
	});

	const formatPlatformFee = (fee: number) =>
		formatPriceAmount({
			amount: fee,
			currency,
		});

	const platformFees = orderItems.filter((oi) => oi.type === PriceItemKind.PlatformFee);
	return (
		<Stack direction="column" width="100%" spacing={1}>
			<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
				<Typography>Gross order value</Typography>
				<Typography>{formattedGrossEarnings}</Typography>
			</Stack>
			{platformFees.map((platformFee) => (
				<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
					<Typography>- {platformFee.description}</Typography>
					<Typography>{formatPlatformFee(platformFee.value)}</Typography>
				</Stack>
			))}
			<Divider />
			<Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
				<Typography fontWeight={500}>= Net order value</Typography>
				<Typography fontWeight={500}>{formattedNetEarnings}</Typography>
			</Stack>
		</Stack>
	);
};

export default TransferEarningBreakdown;
