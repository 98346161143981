import unifyFieldValue from '@rdv-fo/services/randevuApi/helpers/fieldHelper';
import {
	Supply,
	MutationUpdateSupplyArgs,
	MutationCreateSupplyArgs,
	MutationTriggerManualSupplyOnboardingTransitionArgs,
	QueryMySupplyArgs,
	MutationActivateSupplyArgs,
	MutationDeactivateSupplyArgs,
	SupplyType,
	MutationCreateSupplyVariantArgs,
	QueryCalculateSupplyPriceArgs,
	SupplyPrice,
	MutationUpdateMySimpleStockSupplyAvailabilityArgs,
	SimpleStockAvailabilityData,
	QueryIsSupplyAvailableArgs,
	Collection,
	QueryMyCollectionArgs,
	MutationCreateCollectionArgs,
	QueryMySuppliesArgs,
	MutationUpdateCollectionArgs,
	DataImportJobStatusReport,
	MutationRequestSupplyDataImportJobArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

import * as supplyQueries from './supplyQueries';
import * as supplyMutations from './supplyMutations';

class SuppliesService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async updateSupply({ id, fields }: MutationUpdateSupplyArgs): Promise<{ supply: Supply | null; errors: any }> {
		const payload = supplyMutations.updateSupply({ id, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { supply: data?.updateSupply, errors };
	}

	async activateSupply({ id }: MutationActivateSupplyArgs): Promise<{ supply: Supply | null; errors: any }> {
		const payload = supplyMutations.activateSupply({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const supply = data?.activateSupply;
		const fields = unifyFieldValue(supply?.fields);
		if (supply && fields !== undefined) {
			supply.fields = fields;
		}
		return { supply: supply, errors };
	}

	async deactivateSupply({ id }: MutationDeactivateSupplyArgs): Promise<{ supply: Supply | null; errors: any }> {
		const payload = supplyMutations.deactivateSupply({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const supply = data?.deactivateSupply;
		const fields = unifyFieldValue(supply?.fields);
		if (supply && fields !== undefined) {
			supply.fields = fields;
		}

		return { supply: supply, errors };
	}

	async updateMySimpleStockSupplyAvailability({
		id_supply,
		remaining_qty,
	}: MutationUpdateMySimpleStockSupplyAvailabilityArgs): Promise<{ remaining_qty: Number; errors: any }> {
		const payload = supplyMutations.updateMySimpleStockSupplyAvailability({ id_supply, remaining_qty });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const availability_data: SimpleStockAvailabilityData = data?.updateMySimpleStockSupplyAvailability;

		return { remaining_qty: availability_data?.remaining_qty, errors };
	}

	async createSupply({
		tech_name,
		fields,
	}: MutationCreateSupplyArgs): Promise<{ supply: Supply | null; errors: any }> {
		const payload = supplyMutations.createSupply({ tech_name, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const supply = data?.createSupply;

		const unified_fields = unifyFieldValue(supply?.fields);
		if (supply && unified_fields !== undefined) {
			supply.fields = unified_fields;
		}

		return { supply: supply, errors };
	}
	async createSupplyVariant({
		tech_name,
		variant_fields,
		variant_group,
	}: MutationCreateSupplyVariantArgs): Promise<{ supply: Supply | null; errors: any }> {
		const payload = supplyMutations.createSupplyVariant({ tech_name, variant_fields, variant_group });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const supply: Supply = data?.createSupplyVariant;

		if (supply) supply.fields = unifyFieldValue(supply.fields);

		return { supply: supply, errors };
	}
	async mySupplyTypes(): Promise<{ supply_types: SupplyType[] | null; errors: any }> {
		const payload = supplyQueries.mySupplyTypes();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { supply_types: data?.mySupplyTypes, errors };
	}

	async triggerManualSupplyOnboardingTransition({
		id_supply,
		transition_tech_name,
	}: MutationTriggerManualSupplyOnboardingTransitionArgs): Promise<{ triggered: boolean | null; errors: any }> {
		const payload = supplyMutations.triggerManualSupplyOnboardingTransition({ id_supply, transition_tech_name });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { triggered: data?.triggerManualSupplyOnboardingTransition, errors };
	}

	async mySupply({ id }: QueryMySupplyArgs): Promise<{ supply: Supply | null; errors: any }> {
		const payload = supplyQueries.mySupply({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const supply: Supply = data?.mySupply;

		const fields = unifyFieldValue(supply?.fields);
		if (supply) {
			supply.fields = fields;
			supply.variants = supply.variants?.map((variant) => {
				variant.fields = unifyFieldValue(variant?.fields);
				return variant;
			});
		}

		return { supply: supply, errors };
	}
	async isSupplyAvailable({
		id_supply,
		availability_params,
	}: QueryIsSupplyAvailableArgs): Promise<{ is_available: Boolean; errors: any }> {
		const payload = supplyQueries.isSupplyAvailable({ id_supply, availability_params });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { is_available: data?.isSupplyAvailable, errors };
	}

	async mySupplies({ where }: QueryMySuppliesArgs): Promise<{ supplies: Supply[] | null; errors: any }> {
		const payload = supplyQueries.mySupplies({ where });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let supplies: Supply[] = data?.mySupplies;

		supplies = supplies?.map((supply: Supply) => {
			const fields = unifyFieldValue(supply?.fields);

			supply.fields = fields;

			supply.variants = supply.variants?.map((variant) => {
				variant.fields = unifyFieldValue(variant?.fields);
				return variant;
			});

			return supply as Supply;
		});
		return { supplies: supplies, errors };
	}
	async myCollections(): Promise<{ collections: Collection[] | null; errors: any }> {
		const payload = supplyQueries.myCollections();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let collections = data?.myCollections;

		return { collections, errors };
	}
	async updateMyCollection({
		id,
		name,
		ids_supply,
	}: MutationUpdateCollectionArgs): Promise<{ collection: Collection | null; errors: any }> {
		const payload = supplyMutations.updateMyCollection({ id, name, ids_supply });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let collection = data?.updateCollection;

		return { collection, errors };
	}

	async myCollection({ id }: QueryMyCollectionArgs): Promise<{ collection: Collection | null; errors: any }> {
		const payload = supplyQueries.myCollection({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const collection = data?.myCollection;

		return { collection, errors };
	}

	async createCollection({
		name,
	}: MutationCreateCollectionArgs): Promise<{ collection: Collection | null; errors: any }> {
		const payload = supplyMutations.createCollection({ name });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { collection: data?.createCollection, errors };
	}

	async calculateSupplyPrice({
		id_supply,
		match_parameters,
	}: QueryCalculateSupplyPriceArgs): Promise<{ price: SupplyPrice | null; errors: any }> {
		const payload = supplyQueries.calculateSupplyPrice({ id_supply, match_parameters });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const price = data?.calculateSupplyPrice;

		return { price, errors };
	}

	async myDataImportJobReports(): Promise<{
		import_reports: DataImportJobStatusReport[];
		errors: any;
	}> {
		const payload = supplyQueries.myDataImportJobReports();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { import_reports: data?.myDataImportJobReports, errors };
	}

	async requestSupplyDataImportJob({
		supply_tech_name,
		id_template,
		auto_activate_supplies,
		id_data_file,
	}: MutationRequestSupplyDataImportJobArgs): Promise<{ import_report: DataImportJobStatusReport; errors: any }> {
		const payload = supplyMutations.requestSupplyDataImportJob({
			supply_tech_name,
			id_template,
			auto_activate_supplies,
			id_data_file,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { import_report: data?.requestSupplyDataImportJob, errors };
	}
}

export default SuppliesService;
