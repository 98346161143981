import Container from '@mui/material/Container';
import ParticipantOnboardingForm from '@rdv-fo/components/participant/ParticipantOnboardingForm';

const ParticipantOnboardingPage = () => {
	return (
		<>
			<Container maxWidth="sm">
				<ParticipantOnboardingForm />
			</Container>
		</>
	);
};

export default ParticipantOnboardingPage;
