import { useState } from 'react';

import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { Link as RouterLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Container } from '@mui/material';
import { Stack } from '@mui/material';

import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import ROUTES from '@rdv-fo/common/routes';
import RandevuInputLabel from '@rdv-fo/components/common/RandevuInputLabel';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { requestPasswordReset, requestPasswordResetFailed } from '@rdv-fo/store/slices/authSlice';
import FormError from '../FormError';
import { selectPlatformName } from '@rdv-fo/store/slices/platformSlice';

const RequestPasswordResetForm = ({ title, description, form, form_submitted_view }) => {
	const platformName = useAppSelector(selectPlatformName);
	const [resetPassRequestSent, setResetPassRequestSent] = useState(false);

	const dispatch = useAppDispatch();

	const doSubmit = async (values) => {
		const resolvedAction = await dispatch(
			requestPasswordReset({
				email: values.email,
				marketplace_name: platformName,
			})
		);

		if (resolvedAction.type === requestPasswordResetFailed.type) return resolvedAction.payload;

		setResetPassRequestSent(true);
	};

	return (
		<Container maxWidth="sm">
			{resetPassRequestSent ? (
				<>
					<Stack direction="column" spacing={1} sx={{ marginBottom: 4 }}>
						<Typography component="h1" variant="h1">
							{form_submitted_view?.title}
						</Typography>
						<Typography variant="body1" color="textSecondary">
							{form_submitted_view?.description}
						</Typography>
					</Stack>
				</>
			) : (
				<>
					<Stack direction="column" spacing={1} sx={{ marginBottom: 4 }}>
						<Typography component="h1" variant="h1">
							{title}
						</Typography>
						<Typography variant="body1" color="textSecondary">
							{description}
						</Typography>
					</Stack>

					<Form onSubmit={doSubmit}>
						{({ handleSubmit, submitError, submitting, submitFailed, dirtySinceLastSubmit }) => {
							return (
								<form onSubmit={handleSubmit} method="POST" id="request-pass-reset-form">
									<Grid container spacing={2}>
										{submitError && (
											<Grid item xs={12}>
												<FormError message={submitError} />
											</Grid>
										)}
										<Grid item xs={12}>
											<RandevuInputLabel text={form?.field_email?.label} htmlFor="email" />
											<TextField
												id="email"
												name="email"
												placeholder={form?.field_email?.placeholder}
												type="email"
												variant="outlined"
												margin="normal"
												disabled={submitting}
												size="small"
												required
												fullWidth
												autoComplete="email"
											/>
										</Grid>

										<Grid item xs={12}>
											<LoadingButton
												type="submit"
												form="request-pass-reset-form"
												loading={submitting}
												disabled={submitting || (submitFailed && !dirtySinceLastSubmit)}
												fullWidth
											>
												{form?.button_submit?.label}
											</LoadingButton>
										</Grid>
										<Grid item xs={12}>
											<Stack
												direction="row"
												justifyContent="flex-start"
												alignItems="center"
												spacing={0.5}
											>
												<Typography variant="body1">
													{form?.redirect_to_sign_in?.text}
												</Typography>
												<Button
													variant="text"
													color="primary"
													sx={{ fontWeight: 800 }}
													component={RouterLink}
													to={routeBuilder(ROUTES.SIGN_IN)}
												>
													{form?.redirect_to_sign_in?.link_label}
												</Button>
											</Stack>
										</Grid>
									</Grid>
								</form>
							);
						}}
					</Form>
				</>
			)}
		</Container>
	);
};

export default RequestPasswordResetForm;
