import LabelFilterInput from './LabelFilterInput';

import { FilterInputBaseProps } from './types';
import { Stack, Typography, Box } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Radios, TextField } from 'mui-rff';
import { FieldArray } from 'react-final-form-arrays';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

// FIXME: set proper types
const parse = (value: any) => {
	if (['', undefined, null].includes(value)) return null;
	return value;
};

// FIXME: set proper type
const format = (value: any) => {
	if (['', undefined, null].includes(value)) return '';
	return value;
};


const FilterInputSmartTextSetDeprecated = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
}: FilterInputBaseProps) => {
	return (
		<Stack spacing={2}>
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}

			<FieldArray name={`${techName}.values`}>
				{({ fields, meta }) => {
					return (
						<>
							<Stack spacing={2}>
								{fields?.map((name, index) => {
									return (
										<Box key={index}>
											<Stack
												direction="row"
												justifyContent="flex-start"
												alignItems="flex-start"
												spacing={4}
											>
												<TextField
													id={name}
													name={name}
													type="text"
													variant="outlined"
													size="small"
													margin="dense"
													disabled={disabled}
													fullWidth={true}
													fieldProps={{
														parse,
														format,
													}}
												/>

												<IconButton
													disabled={disabled}
													onClick={() => fields.remove(index)}
													aria-label="remove"
												>
													<ClearIcon />
												</IconButton>
											</Stack>
										</Box>
									);
								})}
								<Box>
									<Button
										size="small"
										color="primary"
										startIcon={<AddIcon />}
										disabled={disabled}
										onClick={() => {
											fields.push(null);
										}}
									>
										Add new
									</Button>
								</Box>
							</Stack>
						</>
					);
				}}
			</FieldArray>

			<Typography>How many should be matched?</Typography>
			<TextField
				name={`${techName}.min_hits`}
				type="number"
				variant="outlined"
				size="small"
				disabled={disabled}
				fullWidth
				inputProps={{
					step: 1,
					min: 1,
				}}
			/>
		</Stack>
	);
};

export default FilterInputSmartTextSetDeprecated;
