import { Box, Divider } from '@mui/material';
import ImportJobReportStatusChip from './ImportJobReportStatusChip';
import { Button, Stack, Typography } from '@mui/material';
import { formatDateTimeHumanFriendly } from '@rdv-fo/app/lib/datetime';

interface ImportJobReportCardProps {
	jobId: string;
	requestedAt: string;
	dataName: string;
	dataUrl: string;
	templateVersion: string;
	successfulRecords?: number;
	totalRecords?: number;
	startedAt?: string;
	finishedAt?: string;
	reportUrl?: string;
	reportName: string;
	onDownloadFile: (url: string, fileName: string) => void;
}
const ImportJobReportCard = ({
	jobId,
	totalRecords,
	successfulRecords,
	startedAt,
	finishedAt,
	requestedAt,
	dataName,
	dataUrl,
	templateVersion,
	reportUrl,
	reportName,
	onDownloadFile
}: ImportJobReportCardProps) => {
	const recordsLabel = totalRecords ? `${successfulRecords} / ${totalRecords}` : '-';

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
			<Box>
				<ImportJobReportStatusChip startedAt={startedAt} finishedAt={finishedAt} />
			</Box>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<Typography variant="body1" fontWeight="600" onClick={() => onDownloadFile(dataName, dataUrl)} sx={{
					'&:hover': {
						cursor: 'pointer',
						color: 'black',
					}
				}}>
					{dataName}
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{formatDateTimeHumanFriendly(requestedAt)}
				</Typography>
			</Stack>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<Typography variant="body2">{templateVersion}</Typography>
				<Typography variant="body2">{jobId}</Typography>{' '}
			</Stack>

			<Typography>{recordsLabel}</Typography>
			{
				reportUrl && (
					<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
						<Button color="primary" variant="text" size="small" onClick={() => onDownloadFile(reportUrl, reportName)}>
							Download report
						</Button>
					</Stack>
				)
			}
			<Divider />
		</Stack >
	);
};

export default ImportJobReportCard;
