import { Field, FieldCategoryKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

export const findFieldByCategory = (fields: Field[] | undefined, category: FieldCategoryKind): Field | undefined =>
	fields?.find((field) => field.field_type?.category === category);

export const getFirstLastName = (
	fields: Field[] | undefined
): { firstName: string | undefined; lastName: string | undefined } => {
	if (!fields)
		return {
			firstName: undefined,
			lastName: undefined,
		};
	const firstName = fields.find((field: Field) => field?.field_type.category === FieldCategoryKind.FirstName)?.value;
	const lastName = fields.find((field: Field) => field?.field_type.category === FieldCategoryKind.LastName)?.value;
	return { firstName, lastName };
};

export const findFieldByTechName = (fields: Field[], tech_name: string) => {
	return fields?.find((field) => field?.field_type?.tech_name === tech_name);
};
