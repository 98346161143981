import { Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import BackButton from '@rdv-fo/components/common/BackButton'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { createSharedObject, loadMySharedObjects, loadMySharedObjectTypes, selectLoading, selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';
import SharedObjectsTable from '@rdv-fo/components/shared-objects/SharedObjectsTable';
import SharedObjectTypeFilter from '@rdv-fo/components/shared-objects/SharedObjectTypeFilter';
import CreateSharedObjectModal from '@rdv-fo/components/shared-objects/CreateSharedObjectDialog';

const MySharedObjectsPage = () => {
    const [openCreateSharedObjectDialog, setOpenCreateSharedObjectDialog] = useState<boolean>(false);
    const dispatch = useDispatch();
    const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
    const sharedObjects = useAppSelector(selectMySharedObjects);
    const [selectedObjectTypeId, setSelectedObjectTypeId] = useState<string | undefined>(sharedObjectTypes?.[0]?.id)
    const currentlySelectedObjectType = sharedObjectTypes.find((ot) => ot.id === selectedObjectTypeId)
    const loading = useAppSelector(selectLoading)
    const [currentFormPage, setCurrentFormPage] = useState<'type' | 'details' | 'update'>('type')
    const sharedObjectsExist = sharedObjects.length > 0;

    const handleClickNextPage = () => {
        setCurrentFormPage('details')
    }

    const handleCancel = () => {
        setOpenCreateSharedObjectDialog(false)
        setCurrentFormPage('type')
    }

    const handleBack = () => {
        setCurrentFormPage('type')
    }

    const handleSubmitCreateSharedObjectForm = async (values: any) => {
        const fields = [] as any;
        Object.entries(values).forEach(([key, value]: any) => {
            key !== 'tech_name' && key !== 'is_public' && fields.push({ tech_name: key, value: value })
        })

        const resolvedAction = await dispatch(createSharedObject({ fields, is_public: values.is_public ? values.is_public.includes(true) : false, tech_name: values.tech_name, id: '' }));


        setOpenCreateSharedObjectDialog(false)
        setCurrentFormPage('type')
    }

    useEffect(() => {
        dispatch(loadMySharedObjectTypes());
    }, [])


    useEffect(() => {
        dispatch(loadMySharedObjects({ where: { tech_name: currentlySelectedObjectType?.tech_name } }));
    }, [selectedObjectTypeId])

    return (
        <>
            <CreateSharedObjectModal loading={loading} sharedObjects={sharedObjects} sharedObjectTypes={sharedObjectTypes} onBack={handleBack} currentPage={currentFormPage} onClickNext={handleClickNextPage} open={openCreateSharedObjectDialog} onClose={handleCancel} onSubmit={handleSubmitCreateSharedObjectForm} sharedObjectTypeTechNamesOptions={sharedObjectTypes.map((so) => ({ value: so.tech_name, label: so.name }))} />
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
                <Box>
                    <BackButton />
                </Box>

                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Typography component="h1" variant="h1">
                        Shared Objects
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenCreateSharedObjectDialog(true)}
                    >
                        Add new
                    </Button>
                </Stack>
            </Stack>
            <Box sx={{ mt: 5 }}>
                {loading ? (
                    <Stack direction="column" sx={{ pt: 4 }}>
                        <Skeleton width="100%" height={50} />
                        <Skeleton width="100%" height={50} />
                        <Skeleton width="100%" height={50} />
                        <Skeleton width="100%" height={50} />
                    </Stack>) :
                    <SharedObjectsTable
                        sharedObjects={sharedObjects}
                        loading={loading}
                        disabled={false}
                        typeFilter={<SharedObjectTypeFilter selectedTypeId={selectedObjectTypeId} types={sharedObjectTypes} onFilter={setSelectedObjectTypeId} />}
                    />
                }
            </Box>
        </>
    )
}

export default MySharedObjectsPage