import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link as RouterLink } from 'react-router-dom';
import ROUTES from '@rdv-fo/common/routes';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

interface BackButtonProps {
	to?: string;
	label?: string;
	toVariant?: 'custom' | 'native';
}
const BackButton = ({ to = ROUTES.DASHBOARD, label = 'Dashboard', toVariant = 'custom' }: BackButtonProps) => {
	const history = useHistory();
	return (
		<Button
			variant="text"
			color="inherit"
			{...(toVariant === 'custom' && { component: RouterLink, to: to })}
			{...(toVariant === 'native' && { onClick: () => history.goBack() })}
			startIcon={<KeyboardBackspaceIcon />}
			sx={{ textTransform: 'uppercase' }}
		>
			{label}
		</Button>
	);
};

export default BackButton;
