import Typography from '@mui/material/Typography';

interface FieldValueIntegerProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
	unit?: string;
}
const FieldValueInteger = ({ fieldTechName, unit, value, className }: FieldValueIntegerProps) => {
	return (
		<Typography id={fieldTechName} className={className}>
			{value === null ? 'n.a.' : unit ? `${value} ${unit} ` : `${value} `}
		</Typography>
	);
};

export default FieldValueInteger;
