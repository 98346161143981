import { sleep } from '@rdv-fo/services/sleep';
import { extractErrorMessages } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import type { AppDispatch, RootState } from '@rdv-fo/store/configureStore';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ToastNotification {
	message: string;
	severity: 'success' | 'info' | 'warning' | 'error';
}

interface ReauthenticationCallbackRequest {
	callbackThunkKey: string;
	payload?: any | undefined;
}
interface UiSliceState {
	targetRoute: string | null;
	pushRoute: boolean;
	toastMessages: ToastNotification | null;
	reauthenticationCallback: ReauthenticationCallbackRequest | null;
}

const initialState: UiSliceState = {
	targetRoute: null,
	pushRoute: true,
	toastMessages: null,
	reauthenticationCallback: null,
};

const slice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		routeTo: (
			ui: UiSliceState,
			action: PayloadAction<{
				routeTo: string;
				pushRoute?: boolean;
			}>
		) => {
			ui.targetRoute = action.payload.routeTo;
			ui.pushRoute = action?.payload?.pushRoute ?? true;
		},
		redirected: (ui: UiSliceState) => {
			ui.pushRoute = true;
			ui.targetRoute = null;
		},
		toastMessageSet: (ui: UiSliceState, action: PayloadAction<ToastNotification>) => {
			ui.toastMessages = action.payload;
		},
		toastMessagesCleared: (ui: UiSliceState) => {
			ui.toastMessages = null;
		},

		setReauthenticationCallback: (ui, action) => {
			ui.reauthenticationCallback = action.payload;
		},
	},
});

export const { routeTo, redirected, toastMessageSet, toastMessagesCleared, setReauthenticationCallback } =
	slice.actions;

export const goToRoute = (targetRoute: string) => (dispatch: AppDispatch) => {
	return dispatch(routeTo({ routeTo: targetRoute, pushRoute: true }));
};
export const replaceCurrentRouteWith = (targetRoute: string) => (dispatch: AppDispatch) => {
	return dispatch(routeTo({ routeTo: targetRoute, pushRoute: false }));
};

export const redirectExternal =
	({ url, keepHistory }: { url: string; keepHistory: boolean }) =>
	(dispatch: AppDispatch) => {
		if (keepHistory) return window.location.assign(url);

		return window.location.replace(url);
	};

export const sendToastyMessage =
	(toasty: { message: string; severity: 'warning' | 'success' | 'info' | 'error' }) => (dispatch: AppDispatch) => {
		dispatch(resetToastyMessages());
		return dispatch(toastMessageSet(toasty));
	};

export const sendWarningToasty = (message: string) => (dispatch: AppDispatch) => {
	return dispatch(sendToastyMessage({ message, severity: 'warning' }));
};
export const sendInfoToasty = (message: string) => (dispatch: AppDispatch) => {
	return dispatch(sendToastyMessage({ message, severity: 'info' }));
};
export const sendSuccessToasty = (message: string) => (dispatch: AppDispatch) => {
	return dispatch(sendToastyMessage({ message, severity: 'success' }));
};
export const sendErrorToasty = (message: string) => (dispatch: AppDispatch) => {
	return dispatch(sendToastyMessage({ message, severity: 'error' }));
};

export const resetToastyMessages = (waitTime?: number) => async (dispatch: AppDispatch) => {
	await sleep(waitTime ?? 1000);
	return dispatch(toastMessagesCleared());
};

export default slice.reducer;

// FIXME: use randevu type for unexpected errors!
export const sendUnexpectedErrorToasty = (errors: any) => (dispatch: AppDispatch) =>
	dispatch(
		toastMessageSet({
			message: extractErrorMessages(errors) ?? 'Unexpected error occurred',
			severity: 'error',
		})
	);

// Selectors
export const selectTargetRoute = (state: RootState) => state.ui.targetRoute;
export const selectPushRoute = (state: RootState) => state.ui.pushRoute;
export const selectToastyMessages = (state: RootState) => state.ui.toastMessages;
