import Divider from '@mui/material/Divider';
import Header from '@rdv-fo/components/landing-page/Header';
import Footer from '@rdv-fo/components/landing-page/Footer';
import HeroSection from '@rdv-fo/components/landing-page/HeroSection';
import AboutUsSection from '@rdv-fo/components/landing-page/AboutUsSection';
import UniqueSellingPoints from '@rdv-fo/components/landing-page/UniqueSellingPoints';
import HowItWorksSection from '@rdv-fo/components/landing-page/HowItWorksSection';
import { Box, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { selectInitializingPlatform, selectLandingPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { useEffect } from 'react';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';

const LandingPage = () => {
	const dispatch = useAppDispatch();
	const pageConfig = useAppSelector(selectLandingPageConfig) ?? {};
	const isLandingPageEnabled = pageConfig?.enabled;
	const isHowItWorksSegmentEnabled = pageConfig?.how_it_works?.enabled;
	const isAboutSegmentEnabled = pageConfig?.about?.enabled;
	const isUSPSegmentEnabled = pageConfig?.unique_selling_points?.enabled;
	const initPlatform = useAppSelector(selectInitializingPlatform);

	useEffect(() => {
		if (!isLandingPageEnabled && initPlatform === 'succeeded') dispatch(goToRoute(routeBuilder(ROUTES.DASHBOARD)));
	}, [isLandingPageEnabled, dispatch, initPlatform]);

	return (
		<div
			style={{
				minHeight: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'stretch',
				backgroundColor: 'white',
			}}
		>
			<Header />

			<main style={{ flexGrow: 1 }}>
				<HeroSection />
				<Divider />

				<Stack direction="column" justifyContent="center" alignItems="center" spacing={10} sx={{ mt: 5 }}>
					{isHowItWorksSegmentEnabled && (
						<>
							<HowItWorksSection />
							<Divider flexItem />
						</>
					)}
					{isUSPSegmentEnabled && (
						<>
							<UniqueSellingPoints />
							<Box />
							<Divider flexItem />
						</>
					)}
					{isAboutSegmentEnabled && (
						<>
							<AboutUsSection />
						</>
					)}
				</Stack>
			</main>
			<Divider />
			<Box
				component="footer"
				sx={{
					flexShrink: 0,
					minHeight: '5vw',
					pt: 8,
					pb: 8,
				}}
			>
				<Footer />
			</Box>
		</div>
	);
};

export default LandingPage;
