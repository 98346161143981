import { useParams } from 'react-router-dom';
import React from 'react';

import { useAppDispatch, } from '@rdv-fo/store/configureStore';

import Typography from '@mui/material/Typography';

import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import { replaceCurrentRouteWith } from '@rdv-fo/store/slices/uiSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import { TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';


interface PaymentProcessingPageQueryParams {
	redirectBackTo: TransactionKind.SingleDirect | TransactionKind.MultipleDirect | TransactionKind.ReverseAuction
}

const PaymentProcessingPage = () => {
	const { paymentId, transactionId, transactionTypeTechName } = useParams<{ transactionTypeTechName: string, paymentId: string; transactionId: string }>();
	const { redirectBackTo, } = usePrimitiveQueryParams() as PaymentProcessingPageQueryParams;

	const dispatch = useAppDispatch();


	const reverseAuctionDetailsRoute = routeBuilder(ROUTES.REVERSE_AUCTION_TRANSACTION_DETAILS, [
		[':transactionTypeTechName', transactionTypeTechName],
		[':transactionId', transactionId],
	]);

	let singleDirectTransactionDetailsRoute = routeBuilder(ROUTES.SINGLE_DIRECT_TRANSACTION_DETAILS, [
		[':transactionTypeTechName', transactionTypeTechName],
		[':transactionId', transactionId],
		[':matchingToolId?/', ''],
		[':matchId?', ''],
	]);

	let multiDirectTransactionDetailsRoute = routeBuilder(ROUTES.MULTI_DIRECT_TRANSACTION_DETAILS, [
		[':transactionTypeTechName', transactionTypeTechName],
		[':transactionId', transactionId],
	]);

	let backToRoute = reverseAuctionDetailsRoute;
	if (TransactionKind.SingleDirect === redirectBackTo) backToRoute = singleDirectTransactionDetailsRoute;
	if (TransactionKind.MultipleDirect === redirectBackTo) backToRoute = multiDirectTransactionDetailsRoute;
	if (TransactionKind.ReverseAuction === redirectBackTo) backToRoute = reverseAuctionDetailsRoute;



	setTimeout(() => {
		dispatch(replaceCurrentRouteWith(backToRoute));
	}, 5500);

	return (
		<>
			<Typography component="h1" variant="h5" align="center" sx={{ mt: 5 }}>
				Processing your payment...
			</Typography>
			<LoadingIndicator loading={true} height={20} />
		</>
	);
};

export default PaymentProcessingPage;
