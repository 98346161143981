import { SxProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { RDV_NEUTRAL_100 } from '@rdv-fo/styles/theme';

interface BannerProps {
    title?: string;
    goldColor?: boolean;
    action?: React.ReactNode;
    severity?: 'info' | 'warning' | 'success' | 'error';
    text: string;
    sx?: SxProps;
}
const Banner = ({ title, text, goldColor = true, severity = 'info', sx, action }: BannerProps) => {
    const goldSxProps = {
        color: RDV_NEUTRAL_100,
        '& .MuiAlert-icon': {
            color: RDV_NEUTRAL_100,
        },
    };

    return (
        <Stack sx={{ width: '100%', ...sx }} spacing={2}>
            <Alert severity={severity} sx={goldColor ? goldSxProps : undefined} action={action}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {text}
            </Alert>
        </Stack>
    );
};

export default Banner;
