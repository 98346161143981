import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { useHistory } from 'react-router';
import ROUTES from '@rdv-fo/common/routes';
import {
	Field,
	TransactionLight,
	FieldType,
	InputKind,
	ObjectField,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import { RdvCellValue } from '@rdv-fo/app/lib/tableHelpers';
import { capitalize } from 'lodash';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import TransactionStatusChip from './TransactionStatusChip';

export const buildTransactionsTableRows = (transactions: TransactionLight[]): any[] => {
	const rows = transactions?.map((transaction) => {
		const fieldsValues = transaction.order_fields?.reduce((acc, field: Field) => {
			const inputType = field.field_type.input_type;

			if (inputType === InputKind.Object) {
				const object_field_type = { ...field } as ObjectField;

				let res = undefined;
				if (object_field_type.value === null) {
					res = object_field_type.object_type?.fields?.reduce((innerAcc, objectFieldType: FieldType) => {
						return {
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectFieldType.tech_name}`]: {
									value: null,
									input_type: objectFieldType.input_type,
								},
							},
						};
					}, {});
				} else {
					res = object_field_type.value?.fields?.reduce((innerAcc, objectField: Field) => {
						return {
							...innerAcc,
							[field.field_type.tech_name]: {
								// @ts-expect-error did not find other way how to silent typescript
								...innerAcc[field.field_type.tech_name],
								[`${field.field_type.tech_name}_${objectField.field_type.tech_name}`]: {
									value: objectField.value,
									input_type: objectField.field_type.input_type,
								},
							},
						};
					}, {});
				}

				return { ...acc, ...res };
			}

			return {
				...acc,
				[field.field_type.tech_name]: {
					value: field.value,
				},
			};
		}, {});

		return {
			_id: { value: transaction.id, input_type: InputKind.Text },
			my_role: { value: capitalize(transaction.my_role), input_type: InputKind.Text },
			created_at: { value: transaction.created_at, input_type: InputKind.Datetime },
			initiated_at: { value: transaction.initiated_at, input_type: InputKind.Datetime },
			terminated_at: { value: transaction.terminated_at, input_type: InputKind.Datetime },
			cancelled_at: { value: transaction.cancelled_at, input_type: InputKind.Datetime },
			status: { value: transaction.state?.status, input_type: InputKind.Text },
			//	email: { value: transaction?.participant?.users?.[0]?.email, input_type: InputKind.Text },
			...fieldsValues,
		};
	});

	return rows;
};

export const buildTransactionsTableColumns = (fields: Field[]): MRT_ColumnDef<any>[] => {
	const defaultTransactionColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: 'created_at',
			header: 'Created',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'my_role',
			header: 'Your role',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},

		{
			accessorKey: 'initiated_at',
			header: 'Started',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'terminated_at',
			header: 'Finished',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'status',
			header: 'State',
			Cell: ({ cell, row }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;

				return (
					<TransactionStatusChip
						status={data?.value}
						createdAt={row?.original?.created_at?.value}
						startedAt={row?.original?.initiated_at?.value}
						cancelledAt={row?.original?.cancelled_at?.value}
						terminatedAt={row?.original?.terminated_at?.value}
					/>
				);
			},
		},
	];

	const fieldTypes = fields?.map((field) => field.field_type) ?? [];
	const objectTypes = fields?.map((field: any) => field.object_type).filter((field) => field) ?? [];

	// const fieldColumns = buildColumns(fieldTypes, objectTypes);

	const columns = defaultTransactionColumns;

	return columns;
};

const NoDataView = () => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
			<Typography variant="subtitle1">No transactions yet</Typography>
			<Typography variant="body2">Your transactions will appear hear</Typography>
		</Stack>
	);
};

interface TransactionsTableProps {
	transactions: TransactionLight[];
	disabled: boolean;
	transactionTypeTechName: string;
}

const TransactionsTable = ({ transactions = [], disabled, transactionTypeTechName }: TransactionsTableProps) => {
	const transactionColumnDefinitions = buildTransactionsTableColumns(transactions?.[0]?.order_fields ?? []);

	const transactionRow = buildTransactionsTableRows(transactions) ?? [];
	const history = useHistory();


	return (
		<>
			<MaterialReactTable
				initialState={{
					columnVisibility: {
						_id: false,
					},
					pagination: { pageSize: 50, pageIndex: 0 },
				}}
				columns={transactionColumnDefinitions}
				enablePinning={false}
				data={transactionRow}
				enableTableHead={true}
				enableSorting={false}
				enablePagination={false}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnFilters={false} //disable all column filters
				enableColumnFilterModes={false}
				rowCount={transactions?.length}
				muiTableBodyProps={{
					children: transactions?.length < 1 ? <NoDataView /> : null,
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={{
					sx: {
						'&:hover': {
							backgroundColor: RDV_NEUTRAL_20,
						},
					},
				}}
				positionActionsColumn="first"
				enableRowActions={true}
				renderRowActions={({ row }) => {
					const transactionId = row.original?._id?.value;
					const targetRoute = routeBuilder(ROUTES.REVERSE_AUCTION_TRANSACTION_DETAILS, [
						[':transactionTypeTechName', transactionTypeTechName],
						[':transactionId', transactionId],
						[':matchingToolId?/', ''],
						[':matchId?', ''],
					]);
					return (
						<Button variant="text" color="primary" onClick={() => history.push(targetRoute)}>
							Details
						</Button>
					);
				}}
				enableFullScreenToggle={false}
				enableColumnActions={false}
				enableRowOrdering={false}
				enableHiding={true}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				renderBottomToolbar={
					transactions?.length > 0 &&
					(() => {
						let label = `${transactions?.length} transactions`;
						if (transactions?.length === 1) label = `${transactions?.length} transactions`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default TransactionsTable;
