import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';

import UpdateMySupplyPage from '@rdv-fo/pages/UpdateMySupplyPage';
import DiscoveredTransactionSuppliesPage from '@rdv-fo/pages/DiscoveredTransactionSuppliesPage';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { DRAWER_WIDTH } from '@rdv-fo/app/constants';

import { selectPlatformDisplayName } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		layoutRoot: {
			display: 'flex',
		},
		layoutContent: {
			width: '100%',
			padding: theme.spacing(3),
		},
		toolbar: theme.mixins.toolbar,
		drawer: {
			width: DRAWER_WIDTH,
		},
		drawerPaper: {
			width: DRAWER_WIDTH,
		},
		platformName: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
	};
});

const PublicLayout = () => {
	const classes = useStyles();
	const platformDisplayName = useAppSelector(selectPlatformDisplayName);

	return (
		<>
			<div className={classes.layoutRoot}>
				<Drawer
					className={classes.drawer}
					variant="permanent"
					anchor={'left'}
					classes={{ paper: classes.drawerPaper }}
				>
					<Typography
						color="primary"
						align="center"
						variant="h6"
						className={classes.platformName}
						component={RouterLink}
						underline="none"
						to={routeBuilder(ROUTES.LANDING_PAGE)}
					>
						{platformDisplayName}
					</Typography>

					<Divider />
				</Drawer>

				<main className={classes.layoutContent}>
					<div className={classes.toolbar}></div>
					<Switch>
						<Route
							exact
							path={routeBuilder(ROUTES.DISCOVERED_TRANSACTION_SUPPLIES)}
							component={DiscoveredTransactionSuppliesPage}
						/>
						<Route exact path={routeBuilder(ROUTES.UPDATE_MY_SUPPLY)} component={UpdateMySupplyPage} />
						<Redirect to={routeBuilder(ROUTES.LANDING_PAGE)} />{' '}
					</Switch>
				</main>
			</div>
		</>
	);
};

export default PublicLayout;
