import * as platformQueries from './platformQueries';
import {
	QueryGetFrontofficeConfigurationArgs,
	FrontofficeConfiguration,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

class PlatformService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async getFrontofficeConfiguration({
		environment,
		marketplace_name,
		domain_name,
	}: QueryGetFrontofficeConfigurationArgs): Promise<{ config: FrontofficeConfiguration | null; errors: any }> {
		const payload = platformQueries.foConfig({ environment, marketplace_name, domain_name });

		const { data, errors } = await this.randevu.call({ payload });

		return { config: data?.getFrontofficeConfiguration, errors };
	}
}

export default PlatformService;
