import React from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import {
	updateMyFields,
	selectCurrentUser,
	updateMyFieldsFailed,
	triggerManualParticipantOnboardingTransition,
	selectLoading,
} from '@rdv-fo/store/slices/authSlice';
import OnboardingForm from '@rdv-fo/components/common/OnboardingForm';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import FlowManualActionsPanel from '@rdv-fo/components/transaction/FlowManualActionsPanel';
import ParticipantStatusChip from '@rdv-fo/components/participant/ParticipantStatusChip';
import { mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { useTheme } from '@mui/material';
import { selectOnboardingPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import { NextStepKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		paper: {
			padding: theme.spacing(4),
			width: '100%',
			borderRadius: 15,
		},

		marginBottom: {
			marginBottom: theme.spacing(3),
		},
	};
});

const ParticipantOnboardingForm = () => {
	const classes = useStyles();
	const dispatch = useAppDispatch();

	const currentUser = useAppSelector(selectCurrentUser);
	const loading = useAppSelector(selectLoading);

	const { title } = useAppSelector(selectOnboardingPageConfig);

	const onboardingFields = currentUser?.fields;

	const sharedObjects = useAppSelector(selectMySharedObjects);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const sharedObjectTypesIds = sharedObjectTypes?.map((sot) => sot.id)

	const handleSubmitOnboardingForm = async (values, form) => {
		const dirtyFields = getDirtyFields({ ...values }, form);

		const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypesIds, currentUser?.fields);

		const resolvedAction = await dispatch(
			updateMyFields({
				id: currentUser.id,
				dirty_fields: fieldInputs,
				current_fields: currentUser?.fields,
			})
		);

		if (resolvedAction.type === updateMyFieldsFailed.type) return resolvedAction.payload;
	};

	const handleTriggerManualParticipantOnboardingTransition = (objectId, transitionTechName) => {
		dispatch(
			triggerManualParticipantOnboardingTransition({
				id_participant: objectId,
				transition_tech_name: transitionTechName,
			})
		);
	};

	const nextManualStepsExist = currentUser?.onboarding_progress?.next_steps?.filter((nextStep) => nextStep.type === NextStepKind.ManualTrigger).length > 0;

	return (
		<>
			<Paper elevation={3} className={classes.paper}>
				<Grid container spacing={2} alignItems="flex-start">
					<Grid item xs={9}>
						<Typography component="h1" variant="h4">
							{title}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						{currentUser?.onboarding_progress?.status ? (
							<div className={classes.chip}>
								<ParticipantStatusChip status={currentUser?.onboarding_progress?.status} />
							</div>
						) : null}
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" display="inline">
							For {currentUser?.type?.name}
						</Typography>
					</Grid>
				</Grid>

				<Grid container direction="column">
					<FlowManualActionsPanel
						objectId={currentUser?.id}
						nextSteps={currentUser?.onboarding_progress?.next_steps}
						message={currentUser?.onboarding_progress?.message}
						onTriggerManualTransition={handleTriggerManualParticipantOnboardingTransition}
						loading={loading}
					/>
					<OnboardingForm submitText={nextManualStepsExist ? 'Save' : 'Continue'} sharedObjects={sharedObjects} sharedObjectTypes={sharedObjectTypes} fields={onboardingFields} onSubmit={handleSubmitOnboardingForm} loading={loading} />
				</Grid>
			</Paper>
		</>
	);
};

export default ParticipantOnboardingForm;
