import devEnvConfig from '@rdv-fo/common/.localDevelopmentConfig';
import { FieldType, InputKind, MarketplaceEnvironmentKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

export const marketplaceDomainLocationRegEx = /^\/[0-9A-Za-z -]+$/; // captures '/foo' or '/bar foo' or '/foo-bar' but not /foo/bar
export const getIsMarketplaceDomainLocation = (location: Location) =>
	location.toString().match(marketplaceDomainLocationRegEx);

export const determineDomainName = () => window.location.href.split('//')[1]?.split('/')[0];
export const determineCustomDomainName = () => {
	const domain = window.location.href.split('//')[1]?.split('/')[0];
	return domain?.replace(/^www\./, '') || '';
};

export const determinePlatformNameFromUrl = () => {
	const domainName = determineDomainName();
	const isLocalEnv = domainName.includes('localhost');
	const isCustomDomain = !domainName.includes('randevu.technology');
	const domainChunks = domainName.split('.');

	if (isCustomDomain) return domainChunks[domainChunks.length - 2];

	return isLocalEnv ? devEnvConfig.PLATFORM_NAME : domainName.split('.')[0].replace('-sandbox', '');
};

export const determineEnvironment = () => {
	const domainName = determineDomainName();
	const isLocalEnv = domainName.includes('localhost');

	if (isLocalEnv) {
		return devEnvConfig.ENVIRONMENT === MarketplaceEnvironmentKind.Sandbox
			? MarketplaceEnvironmentKind.Sandbox
			: MarketplaceEnvironmentKind.Production;
	} else {
		return domainName.includes('-sandbox')
			? MarketplaceEnvironmentKind.Sandbox
			: MarketplaceEnvironmentKind.Production;
	}
};

export const buildQueryParams = (
	params: Array<string>,
	fieldTypes: FieldType[] | { tech_name: string; input_type: InputKind }[]
) => {
	const paramsArr = [];
	for (let key in params) {
		const fieldType = fieldTypes?.find((ft) => ft.tech_name === key);
		if (params[key] != null) {
			if (params[key] && typeof params[key] === 'object' && params[key] != null) {
				// Filter value is an object so we stringify it
				paramsArr.push(`${encodeURIComponent(`${key}`)}=${encodeURIComponent(JSON.stringify(params[key]))}`);
			} else {
				fieldType?.input_type === InputKind.Text
					? paramsArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(`"${params[key]}"`)}`) // make sure text inputs are quoted
					: paramsArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
			}
		}
	}
	return '?' + paramsArr.join('&');
};

export const parseDiscoveryFilters = (
	urlSearch: string | string[][] | Record<string, string> | URLSearchParams | undefined
) => {
	const urlParams = new URLSearchParams(urlSearch);
	const filters = {};
	for (let key of urlParams.keys()) {
		const value = urlParams.get(key);
		let parsedValue;
		try {
			parsedValue = JSON.parse(value as string);
		} catch {
			parsedValue = value;
		}

		Object.assign(filters, { [key]: parsedValue });
	}
	return filters;
};
