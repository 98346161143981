import { Typography } from '@mui/material';
import { RdvTextFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldTextCellViewProps {
	value: RdvTextFieldValue;
}
const FieldTextCellView = ({ value }: FieldTextCellViewProps) => {
	if (value == null) return <Typography>n.a.</Typography>;

	return <Typography variant="body2">{value}</Typography>;
};

export default FieldTextCellView;
