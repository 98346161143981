interface RdvDashboardTile {
	title: string;
	target_route: string;
	ids_participant_type: string[] | null[];
}

export const filterDashboardTileAndCurrentUserType = (participantTypeIds: string[], tile: RdvDashboardTile) => {
	if (tile?.ids_participant_type.length < 1) return true;

	const participantTypesHit = [] as boolean[];

	tile?.ids_participant_type?.forEach((id: string | null) =>
		id ? participantTypesHit.push(participantTypeIds?.includes(id)) : false
	);

	if (participantTypesHit?.includes(true)) return true;

	return false;
};
