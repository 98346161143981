import { Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

interface SupplyIsActiveIndicatorProps {
	isActive: boolean;
}
const SupplyIsActiveIndicator = ({ isActive }: SupplyIsActiveIndicatorProps) => {
	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
			<CircleIcon color={isActive ? 'success' : 'error'} sx={{ fontSize: 10 }} />
			<Typography variant="body2">{isActive ? 'Enabled' : 'Disabled'}</Typography>
		</Stack>
	);
};

export default SupplyIsActiveIndicator;
