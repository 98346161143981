import { Grid, Typography, useTheme } from "@mui/material";
import { MatchTypeRoleKind, NextStep, PaymentType, RandevuEventKind, TransactionKind, TransactionStatusKind } from "@rdv-fo/services/randevuApi/types/generatedTypes";
import { useAppDispatch } from "@rdv-fo/store/configureStore";
import { acceptSingleDirectTransactedMatchConsumer, declineSingleDirectTransactedMatchConsumer } from "@rdv-fo/store/slices/transactionSlice";
import { LoadingButtonAdvance } from "../../common/LoadingButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TransactionPaymentCheckoutButton from "../TransactionPaymentCheckoutButton";


interface ParticipantActionsProps {
    transactionId: string;
    transactionStatus: string,
    transactionTechName: string,
    matchId: string,
    paymentTypes: PaymentType[],
    participantMatchRole: MatchTypeRoleKind,
    nextSteps: NextStep[],
    backofficeApprovedAt: string,
    backofficeRejectedAt: string,
    consumerAcceptedAt: string,
    providerAcceptedAt: string,
    consumerDeclinedAt: string,
    providerDeclinedAt: string,
}

const SingleDirectConsumerParticipantActions = ({
    transactionId,
    transactionStatus,
    transactionTechName,
    paymentTypes,
    participantMatchRole,
    nextSteps,
    backofficeApprovedAt,
    backofficeRejectedAt,
    consumerAcceptedAt,
    providerAcceptedAt,
    consumerDeclinedAt,
    providerDeclinedAt,
}: ParticipantActionsProps) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const hasMatchAcceptedOrClosedStep = nextSteps?.find((ns: NextStep) =>
        [RandevuEventKind.MatchAccepted, RandevuEventKind.MatchClosed].includes(ns.config.event)
    );

    const hasMatchDeclinedStep = nextSteps?.find((step: NextStep) => step.config.event === RandevuEventKind.MatchDeclined);
    const isPaymentStepAvailable = nextSteps?.find((step: NextStep) => step.config?.event === RandevuEventKind.PaymentPrepared);

    const isConsumer = participantMatchRole === MatchTypeRoleKind.Consumer;
    const isMatchDeclined = providerDeclinedAt !== null || consumerDeclinedAt !== null;
    const isMatchRejected = backofficeRejectedAt !== null;

    const awaitingConsumerDecision =
        consumerAcceptedAt === null && consumerDeclinedAt === null && !(isMatchDeclined || isMatchRejected);

    const awaitingProviderDecision =
        providerAcceptedAt === null && providerDeclinedAt === null && !(isMatchDeclined || isMatchRejected);

    const awaitingBackofficeApproval =
        backofficeApprovedAt === null && backofficeRejectedAt === null && !(isMatchDeclined || isMatchRejected);

    const allowCheckout = isPaymentStepAvailable;

    const handleAcceptMatch = async (transactionId: string) => {
        await dispatch(acceptSingleDirectTransactedMatchConsumer({ id_transaction: transactionId }));
    };

    const handleDeclineMatch = async (transactionId: string) => {
        await dispatch(declineSingleDirectTransactedMatchConsumer({ id_transaction: transactionId }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                    {hasMatchDeclinedStep &&
                        ((isConsumer && awaitingConsumerDecision) || (!isConsumer && awaitingProviderDecision)) ? (
                        <Grid item>
                            <LoadingButtonAdvance
                                variant="outlined"
                                color="primary"
                                style={{ gap: 10 }}
                                onClick={() => handleDeclineMatch(transactionId)}
                                disabled={false}
                                loading={false}
                            >
                                Decline
                            </LoadingButtonAdvance>
                        </Grid>
                    ) : null}

                    {hasMatchAcceptedOrClosedStep &&
                        ((isConsumer && awaitingConsumerDecision) || (!isConsumer && awaitingProviderDecision)) ? (
                        <Grid item>
                            <LoadingButtonAdvance
                                variant="contained"
                                color="primary"
                                onClick={() => handleAcceptMatch(transactionId)}
                                disabled={false}
                                loading={false}
                            >
                                Accept
                            </LoadingButtonAdvance>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>

            {isMatchDeclined && (
                <Grid item xs={12}>
                    <div style={{
                        display: 'flex',
                        gap: 5,
                        color: 'rgb(95, 33, 32)',
                        backgroundColor: 'rgb(255, 217, 213)',
                        paddingTop: theme.spacing(1.5),
                        paddingBottom: theme.spacing(1.5),
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        borderRadius: '4px',
                        letterSpacing: '0.01071em',
                        alignItems: 'center',
                    }}>
                        <InfoOutlinedIcon sx={{ color: 'rgb(95, 33, 32)', }} />
                        {isConsumer && consumerDeclinedAt !== null && <Typography>You declined</Typography>}
                        {isConsumer && providerDeclinedAt !== null && <Typography>Provider declined</Typography>}
                        {!isConsumer && consumerDeclinedAt !== null && <Typography>Consumer declined</Typography>}
                        {!isConsumer && providerDeclinedAt !== null && <Typography>You declined</Typography>}
                    </div>
                </Grid>
            )}
            {isMatchRejected && (
                <Grid item xs={12}>
                    <div style={{
                        display: 'flex',
                        gap: 5,
                        color: 'rgb(95, 33, 32)',
                        backgroundColor: 'rgb(255, 217, 213)',
                        paddingTop: theme.spacing(1.5),
                        paddingBottom: theme.spacing(1.5),
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        borderRadius: '4px',
                        letterSpacing: '0.01071em',
                        alignItems: 'center',
                    }}>
                        <InfoOutlinedIcon sx={{ color: 'rgb(95, 33, 32)', }} />
                        <Typography>Rejected by the platform</Typography>
                    </div>
                </Grid>
            )}

            {allowCheckout && paymentTypes.map((pt: PaymentType) => {

                // use UI config from the payment type in here
                const btnLabel = `${isPaymentStepAvailable?.action_message} ${pt.name?.toLowerCase()}` ?? ''
                const acceptedPaymentMethods = pt.payment_methods?.[0]?.accepted_methods ?? []

                return (


                    <Grid item xs={12} id={pt.tech_name}>
                        {(isMatchDeclined === false && isMatchRejected === false) && (
                            <TransactionPaymentCheckoutButton
                                redirectBackTo={TransactionKind.SingleDirect}
                                transactionId={transactionId}
                                transactionTechName={transactionTechName}
                                acceptedPaymentMethods={acceptedPaymentMethods}
                                paymentTypeTechName={pt.tech_name}
                                label={btnLabel}
                            />)}
                    </Grid>)
            })

            }
        </Grid>
    );
};

export default SingleDirectConsumerParticipantActions;