import FormLabel from '@mui/material/FormLabel';
import { useTheme } from '@mui/material';

const RandevuInputLabel = ({ htmlFor, text }) => {
	const theme = useTheme();

	return (
		<FormLabel sx={{color: theme.palette.common.black,}} htmlFor={htmlFor}>
			{text}
		</FormLabel>
	);
};

export default RandevuInputLabel;
