import { format as dateFormat, getYear } from 'date-fns';
import { floor } from 'lodash';

export const formatDatetimeShort = (datetimeString) => {
	if ([undefined, null, ''].includes(datetimeString)) return null;
	return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
		new Date(datetimeString)
	);
};

export const formatDateLong = (datetimeString) => {
	if ([undefined, null, ''].includes(datetimeString)) return null;
	try {
		return new Intl.DateTimeFormat('en-GB', { dateStyle: 'long' }).format(new Date(datetimeString));
	} catch (error) {
		return null;
	}
};

export const parse = (value) => ([null, undefined, ''].includes(value) ? null : value?.toISOString());
export const format = (value) => ([null, undefined, ''].includes(value) ? null : new Date(value));

export const formatDateTimeHumanFriendly = (datetimeString) => {
	if ([undefined, null, ''].includes(datetimeString)) return null;
	try {
		const date = new Date(datetimeString);
		let givenYear = getYear(date);
		const currentYear = getYear(new Date());
		if (givenYear < currentYear) {
			// Given year is the past
			return dateFormat(date, 'dd MMM yyyy, H:mm');
		}
		return dateFormat(date, 'd MMM, H:mm');
	} catch (error) {
		return null;
	}
};
export const formatDateHumanFriendly = (datetimeString) => {
	if ([undefined, null, ''].includes(datetimeString)) return null;
	try {
		const date = new Date(datetimeString);
		let givenYear = getYear(date);
		const currentYear = getYear(new Date());
		if (givenYear < currentYear) {
			// Given year is the past
			return dateFormat(date, 'dd MMM yyyy');
		}
		return dateFormat(date, 'd MMM');
	} catch (error) {
		return null;
	}
};

export const getTimeAgoString = (timestamp) => {
	const currentTime = new Date();
	const updatedTime = new Date(timestamp);

	const timeDifference = currentTime - updatedTime;

	if (timeDifference < 60000) {
		// Less than a minute
		return 'Updated just now';
	} else if (timeDifference < 3600000) {
		// Less than an hour
		const minutes = floor(timeDifference / 60000);
		return `Updated ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
	} else if (timeDifference < 86400000) {
		// Less than a day
		const hours = floor(timeDifference / 3600000);
		return `Updated ${hours} hour${hours > 1 ? 's' : ''} ago`;
	} else if (timeDifference < 2592000000) {
		// Less than 30 days (approximately a month)
		const days = floor(timeDifference / 86400000);
		return `Updated ${days} day${days > 1 ? 's' : ''} ago`;
	} else if (timeDifference < 31536000000) {
		// Less than a year
		const months = floor(timeDifference / 2592000000);
		return `Updated ${months} month${months > 1 ? 's' : ''} ago`;
	} else {
		// More than a year
		const years = floor(timeDifference / 31536000000);
		return `Updated ${years} year${years > 1 ? 's' : ''} ago`;
	}
};
