import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { NextStep, NextStepKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { useTheme } from '@mui/material';

interface FlowManualActionsPanelProps {
	loading: boolean;
	nextSteps: NextStep[];
	message: string;
	objectId: string;
	onTriggerManualTransition: (objectId: string, transitionTechName: string) => void;
	buttonVariant?: 'outlined' | 'contained';
}

const FlowManualActionsPanel = ({ loading, nextSteps, message, objectId, onTriggerManualTransition, buttonVariant = 'outlined' }: FlowManualActionsPanelProps) => {
	const theme = useTheme();
	// FIXME: move manualNextSteps to the interface (LoD)
	const filteredNextSteps = nextSteps?.filter((nextStep) => nextStep.type === NextStepKind.ManualTrigger);

	return (
		<Grid component="section" container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2} alignContent="flex-start">
					{message && <Grid item xs={12}>
						<Typography component="h2" variant="h4" sx={{ paddingTop: 3, paddingBottom: 3 }}>
							{message}
						</Typography>
					</Grid>}
					{filteredNextSteps?.length > 0 && (
						<Grid item>
							<Grid container spacing={2} direction='row'>
								{filteredNextSteps.map((nextStep) => (
									<Grid item key={nextStep?.config?.transition_tech_name}>
										<Button
											key={nextStep.action_message}
											variant={buttonVariant}
											color="primary"
											disabled={loading}
											onClick={() => {
												onTriggerManualTransition(
													objectId,
													nextStep.config?.transition_tech_name
												);
											}}
										>
											{nextStep.action_message}
										</Button>
									</Grid>
								))}
							</Grid>
							{loading ? (
								<LinearProgress sx={{
									marginTop: 1,
									height: '1px'
								}} />
							) : (
								<div style={{
									marginTop: theme.spacing(1),
									height: '1px',
								}} />
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default FlowManualActionsPanel;
