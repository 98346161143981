import { Select } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { FieldInputBaseProps } from './types';

import LabelFieldInput from './LabelFieldInput';
import { Stack } from '@mui/material';

interface SingleSelectInputOptions {
	required: boolean;
	minLength?: number;
	maxLength?: number;
}

const validate = (value: string, options: SingleSelectInputOptions) => {
	const { required } = options;

	if (required && value === null) {
		return VALIDATION_MESSAGE.NOTHING_SELECTED_VIOLATION;
	}

	return undefined;
};

interface FieldInputSingleSelectProps extends FieldInputBaseProps {
	options?: string[];
	optionsUiConfig?: { label: string, value: string }[];
}
const FieldInputSingleSelect = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	optionsUiConfig,
	options,
}: FieldInputSingleSelectProps) => {
	let selectData = [];

	if (!required) selectData.push({ label: ' -- None -- ', value: null as any });

	options?.forEach((opt: string) => {
		const label = optionsUiConfig?.find((optionConfig) => optionConfig.value === opt)?.label;
		selectData.push({ label: label ?? opt, value: opt });
	});

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>
			<Select
				name={techName}
				disabled={disabled}
				required={required}
				data={selectData}
				variant="outlined"
				formControlProps={{ margin: 'dense', size: 'small' }}
				fullWidth
				helperText={helperText}
				fieldProps={{
					validate: (value) => validate(value, { required }),
					allowNull: true,
				}}
			/>
		</Stack>
	);
};

export default FieldInputSingleSelect;
