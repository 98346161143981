import React, { useEffect } from 'react'

import Typography from '@mui/material/Typography';

import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { loadMyMultiDirectTransactions, selectMyMultiDirectTransactions, selectLoading } from '@rdv-fo/store/slices/shoppingCartSlice';
import MultiDirectTransactionsTable from '@rdv-fo/components/transaction/multi-direct/MultiDirectTransactionsTable';
import { ShoppingCartLight } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { useParams } from 'react-router';


const ListMyMultiDirectTransactionsPage = () => {
    const dispatch = useAppDispatch();
    const { transactionTypeTechName } = useParams<{ transactionTypeTechName: string }>();

    const transactions = useAppSelector(selectMyMultiDirectTransactions) as ShoppingCartLight[];
    const loading = useAppSelector(selectLoading);

    useEffect(() => {
        dispatch(loadMyMultiDirectTransactions({ where: { transaction_tech_name: transactionTypeTechName }, limit: 50, offset: 0 }));
    }, [dispatch, transactionTypeTechName])

    return (
        <>
            <Stack direction="row" width='100%' justifyContent='space-between'>
                <Typography component="h1" variant="h1">
                    Multi direct transactions
                </Typography>
            </Stack>
            {transactions && <MultiDirectTransactionsTable transactionTechName={transactionTypeTechName} transactions={transactions ?? []} loading={loading} />}
        </>
    )
}

export default ListMyMultiDirectTransactionsPage