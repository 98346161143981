import gql from 'graphql-tag';
import { print } from 'graphql';

import { MutationUpdateTransactionOrderArgs } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const UPDATE_TRANSACTION_ORDER = gql`
	mutation UPDATE_TRANSACTION_ORDER(
		$id_transaction: ID!
		$name: String
		$supply_filter: [FieldFilterInput!]
		$provider_filter: [FieldFilterInput!]
		$consumer_filter: [FieldFilterInput!]
		$fields: [FieldInput!]
	) {
		updateTransactionOrder(
			id_transaction: $id_transaction
			name: $name
			supply_filter: $supply_filter
			provider_filter: $provider_filter
			consumer_filter: $consumer_filter
			fields: $fields
		)
	}
`;

export const updateTransactionOrder = ({
	id_transaction,
	name,
	supply_filter,
	provider_filter,
	consumer_filter,
	fields,
}: MutationUpdateTransactionOrderArgs) => {
	return JSON.stringify({
		query: print(UPDATE_TRANSACTION_ORDER),
		variables: {
			id_transaction,
			name,
			supply_filter,
			provider_filter,
			consumer_filter,
			fields,
		},
	});
};
