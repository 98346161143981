import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import Typography from '@mui/material/Typography';
import {
	ConnectionStatusKind,
	Field,
	FieldAccessKind,
	ObjectField,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { Divider, Stack } from '@mui/material';
import {
	loadMyConnection,
	selectMyConnection,
	selectLoading,
	updateConnectionFailed,
	updateConnection,
	acceptConnection,
	acceptConnectionFailed,
	declineConnectionFailed,
	declineConnection,
	selectAcceptingConnection,
	selectDecliningConnection,
} from '@rdv-fo/store/slices/connectionSlice';
import { selectPlatformConnectedParticipantPageConfig } from '@rdv-fo/store/slices/platformSlice';
import BackButton from '@rdv-fo/components/common/BackButton';
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';
import ConnectionStatusChip from '@rdv-fo/components/connections/ConnectionStatusChip';
import FieldInput from '@rdv-fo/components/fields/input/FieldInput';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { mapFieldsToFormValues, mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import { FormApi } from 'final-form';
import ROUTES from '@rdv-fo/common/routes';

const ConnectionDetailsPage = () => {
	const { id, connectionTechName } = useParams<{ id: string, connectionTechName: string }>();
	const dispatch = useAppDispatch();
	const loading = useAppSelector(selectLoading);
	const connection = useAppSelector(selectMyConnection);
	const page_config = useAppSelector(selectPlatformConnectedParticipantPageConfig);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);
	const acceptingConnection = useAppSelector(selectAcceptingConnection);
	const decliningConnection = useAppSelector(selectDecliningConnection);

	const participantFields = connection?.participant.fields ?? [];
	const connectionFields = connection?.fields ?? [];
	const connectionFieldsAvailable = connectionFields.length > 0;

	const initFormValues = (participantFields: Field[], connectionFields: Field[]) => {
		let participantValues = mapFieldsToFormValues(participantFields, sharedObjectTypeIds);
		let connectionValues = mapFieldsToFormValues(connectionFields, sharedObjectTypeIds);

		return {
			participant: participantValues,
			...connectionValues,
		};
	};

	const handleSubmitUpdateConnectionFields = async (values: any, form: FormApi) => {
		const dirtyFields = getDirtyFields({ ...values }, form);

		const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypeIds, connection?.fields);

		const resolvedAction = await dispatch(
			updateConnection({
				id: connection!.id,
				dirty_fields: fieldInputs,
				current_fields: connection?.fields ?? [],
			})
		);
		if (resolvedAction?.type === updateConnectionFailed.type) return resolvedAction.payload;
	};

	const handleSubmitAcceptConnection = async (connectionId: string) => {
		const resolvedAction = await dispatch(
			acceptConnection({
				id: connectionId,
			})
		);
		if (resolvedAction?.type === acceptConnectionFailed.type) return resolvedAction.payload;
	};
	const handleSubmitDeclineConnection = async (connectionId: string) => {
		const resolvedAction = await dispatch(
			declineConnection({
				id: connectionId,
			})
		);
		if (resolvedAction?.type === declineConnectionFailed.type) return resolvedAction.payload;
	};

	useEffect(() => {
		if (id) dispatch(loadMyConnection({ id }));
	}, [id, dispatch]);

	const showUpdateConnectionFieldsButton =
		connectionFields?.find((field) => field.my_access === FieldAccessKind.Write) !== undefined;

	let showAcceptButton = false;
	let showDeclineButton = false;

	if (connection) {
		showAcceptButton =
			![ConnectionStatusKind.Rejected, ConnectionStatusKind.Declined, ConnectionStatusKind.Established].includes(
				connection?.status
			) && !connection?.accepted_at;
		showDeclineButton =
			![ConnectionStatusKind.Rejected, ConnectionStatusKind.Established, ConnectionStatusKind.Declined].includes(
				connection?.status
			) &&
			!connection?.accepted_at &&
			!connection?.declined_at;
	}

	return (
		<>
			<Form
				onSubmit={handleSubmitUpdateConnectionFields}
				initialValues={initFormValues(participantFields, connectionFields)}
				mutators={{
					...arrayMutators,
				}}
			>
				{({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
					const saveButtonEnabled = !pristine && !hasValidationErrors;

					return (
						<>
							<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
								<BackButton label="Connections" to={ROUTES.MY_CONNECTIONS.replace(":connectionTechName", connectionTechName)} />

								<Stack
									direction={{ xs: 'column', md: 'row' }}
									justifyContent="space-between"
									alignItems={{ xs: 'flex-start', md: 'center' }}
									spacing={{ xs: 6, md: 2 }}
									sx={{
										width: '100%',
									}}
								>
									<Stack
										direction="column"
										justifyContent="flex-start"
										alignItems="flex-start"
										spacing={0.5}
									>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={2}
										>
											<Typography component="h1" variant="h1">
												{page_config?.title?.trim() ?? 'Connection details'}
											</Typography>
											<ConnectionStatusChip loading={loading} status={connection?.status} />
										</Stack>
										<Typography variant="body2" color="textSecondary">
											{connection?.id}
										</Typography>
									</Stack>
									<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
										{showAcceptButton && (
											<>
												<LoadingButton
													disabled={acceptingConnection || decliningConnection || submitting}
													variant="contained"
													size="small"
													color="primary"
													onClick={() => handleSubmitAcceptConnection(id)}
													loading={acceptingConnection}
												>
													Accept
												</LoadingButton>
											</>
										)}
										{showDeclineButton && (
											<>
												<LoadingButton
													disabled={acceptingConnection || decliningConnection || submitting}
													variant="contained"
													size="small"
													color="primary"
													onClick={() => handleSubmitDeclineConnection(id)}
													loading={decliningConnection}
												>
													Decline
												</LoadingButton>
											</>
										)}
									</Stack>
								</Stack>
							</Stack>

							<Box sx={{ mt: 5 }}>
								{loading ? (
									<Stack direction="column" sx={{ pt: 4 }}>
										<Skeleton width="100%" height={50} />
										<Skeleton width="100%" height={50} />
										<Skeleton width="100%" height={50} />
										<Skeleton width="100%" height={50} />
									</Stack>
								) : (
									<form onSubmit={handleSubmit} noValidate>
										<Stack
											direction="column"
											justifyContent="flex-start"
											alignItems="stretch"
											spacing={2}
										>
											{participantFields?.map((field: Field) => (
												<FieldInput
													sharedObjects={[]}
													sharedObjectTypes={[]}
													label={
														field?.field_type?.ui_config?.label ?? field.field_type?.name
													}
													optionsUiConfig={field?.field_type?.ui_config?.options ?? []}
													helperText={field?.field_type?.ui_config?.helper_text}
													fieldTechName={`participant.${field?.field_type.tech_name}`}
													inputType={field?.field_type.input_type}
													inputOptions={field?.field_type.input_options}
													required={field?.field_type.is_required}
													disabled={true}
													objectTypeFields={(field as ObjectField)?.object_type?.fields}
												/>
											))}

											<Box sx={{ pt: 4, pb: 4, width: '100%' }}>
												<Divider flexItem variant="middle" />
											</Box>

											{connectionFieldsAvailable && (
												<>
													{connectionFields?.map((field: Field) => (
														<FieldInput
															sharedObjects={[]}
															sharedObjectTypes={[]}
															label={
																field?.field_type?.ui_config?.label ??
																field.field_type?.name
															}
															optionsUiConfig={
																field?.field_type?.ui_config?.options ?? []
															}
															helperText={field?.field_type?.ui_config?.helper_text}
															fieldTechName={`${field?.field_type.tech_name}`}
															inputType={field?.field_type.input_type}
															inputOptions={field?.field_type.input_options}
															required={field?.field_type.is_required}
															disabled={
																field.my_access === FieldAccessKind.Read || submitting
															}
															objectTypeFields={
																(field as ObjectField)?.object_type?.fields
															}
														/>
													))}
												</>
											)}

											{showUpdateConnectionFieldsButton && (
												<Box>
													{/* 
                                    // @ts-ignore */}
													<LoadingButton
														disabled={!saveButtonEnabled}
														variant="contained"
														size="small"
														color="primary"
														type="submit"
														loading={submitting}
													>
														Save
													</LoadingButton>
												</Box>
											)}
										</Stack>
									</form>
								)}
							</Box>
						</>
					);
				}}
			</Form>
		</>
	);
};

export default ConnectionDetailsPage;
