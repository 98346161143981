import Container from '@mui/material/Container';

const PageWidth = ({
	padding = 0,
	paddingLeft = 0,
	paddingRight = 0,
	paddingBottom = 0,
	paddingTop = 0,
	children,
	maxWidth = 'lg',
	mobilePadding = false,
}) => {
	return (
		<Container
			name="page-width"
			maxWidth={maxWidth}
			sx={{
				height: '100%',
				p: padding,
				pl: mobilePadding ? 2 : paddingLeft,
				pr: mobilePadding ? 2 : paddingRight,
				pb: paddingBottom,
				pt: paddingTop,
			}}
		>
			{children}
		</Container>
	);
};

export default PageWidth;
