import React from 'react';
import { useAppSelector } from '@rdv-fo/store/configureStore';
import { Route, Redirect } from 'react-router-dom';

import ROUTES from '@rdv-fo/common/routes';

import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { ParticipantStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const AuthRoute = ({ queryParams, path, component: Component, render, ...rest }) => {
	// const domainName = determineDomainName();

	let currentUser = useAppSelector(selectCurrentUser);
	// const dispatch = useAppDispatch();

	// Skip redirects if landed from backoffice
	if (queryParams?.startsWith('?force-logout=true')) return Component ? <Component /> : null;

	// if (
	// 	currentUser &&
	// 	!domainName.toLowerCase().includes(currentUser.my_marketplace_name) &&
	// 	!domainName.includes('localhost')
	// ) {
	// 	console.log(
	// 		'Current user is signed-in in different platform than the one provided via context. Force user sign-out.'
	// 	);
	// 	dispatch(signOut({ options: { redirectTo: ROUTES.LANDING_PAGE } }));
	// 	currentUser = undefined;
	// }

	return (
		<Route
			{...rest}
			render={(props) => {
				if (ParticipantStatusKind.Onboarded === currentUser?.status) {
					console.log('Participant is already signed-in and in onboarded status. Redirect to landing page..');

					return (
						<Redirect
							to={{
								pathname: routeBuilder(ROUTES.DASHBOARD),
								state: { from: props.location },
							}}
						/>
					);
				}
				if ([ParticipantStatusKind.Onboarding].includes(currentUser?.status)) {
					console.log(
						'Participant is already signed-in and in onboarding/pending_approval status. Redirect to onboarding page..'
					);

					return (
						<Redirect
							to={{
								pathname: routeBuilder(ROUTES.PARTICIPANT_ONBOARDING),
								state: { from: props.location },
							}}
						/>
					);
				}
				if (ParticipantStatusKind.Rejected === currentUser?.status) {
					console.log(
						'Participant is already signed-in and in rejected status. Redirect to user rejected page..'
					);
					return (
						<Redirect
							to={{
								pathname: routeBuilder(ROUTES.PARTICIPANT_REJECTED),
								state: { from: props.location },
							}}
						/>
					);
				}

				return Component ? <Component {...props} /> : render(props);
			}}
		/>
	);
};

export default AuthRoute;
