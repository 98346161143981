import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import {
    FieldAccessKind,
    MatchTypeRoleKind,
    NextStepKind,
    NextStep,
    Field,
    SupplyPrice,
    PaymentType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import OnboardingForm from '@rdv-fo/components/common/OnboardingForm';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import { mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import { FormApi } from 'final-form';
import SingleDirectTransactionMatchOverview from './SingleDirectTransactionMatchOverview';
import SupplyData from '../SupplyData';
import { updateSingleDirectTransactionMatchForConsumer } from '@rdv-fo/store/slices/transactionSlice';
import SingleDirectConsumerParticipantActions from './SingleDirectConsumerParticipantActions';

interface TransactionMatchForConsumer {
    transactionId: string;
    transactionStatus: string;
    transactionTechName: string;
    transactionNextSteps: NextStep[];
    matchId: string;
    matchingToolFields: Field[];
    matchFields: Field[];
    matchPrice: SupplyPrice;
    matchPriceOverrideEnabled: boolean;
    supplyFields: Field[];
    providerFields: Field[];
    backofficeApprovedAt: string;
    backofficeRejectedAt: string;
    consumerAcceptedAt: string;
    providerAcceptedAt: string;
    consumerDeclinedAt: string;
    providerDeclinedAt: string;
    paymentTypes: PaymentType[]
    isMatchConfigurationPricingEnabled: boolean;
}

const SingleDirectTransactionMatchForConsumer = ({
    transactionId,
    transactionStatus,
    transactionTechName,
    transactionNextSteps,
    matchId,
    matchingToolFields,
    matchFields,
    matchPrice,
    matchPriceOverrideEnabled,
    supplyFields,
    providerFields,
    backofficeApprovedAt,
    backofficeRejectedAt,
    consumerAcceptedAt,
    providerAcceptedAt,
    consumerDeclinedAt,
    providerDeclinedAt,
    paymentTypes,
    isMatchConfigurationPricingEnabled,
}: TransactionMatchForConsumer) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const sharedObjects = useAppSelector(selectMySharedObjects);
    const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
    const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);
    const matchFieldsWriteAccess = matchFields?.filter((field) => field.my_access === FieldAccessKind.Write);

    const handleSubmitUpdateMatchForm = async (values: { [key: string]: string }, form: FormApi) => {
        const dirtyFields = getDirtyFields({ ...values }, form);

        const fieldInputs = mapFormValuesToFieldInputs(
            dirtyFields,
            values,
            sharedObjectTypeIds,
            matchFieldsWriteAccess
        );

        return dispatch(
            updateSingleDirectTransactionMatchForConsumer({
                id: transactionId,
                matchFields: fieldInputs,
                currentFields: matchFieldsWriteAccess,
            })
        );
    };
    const nextManualStepsExist =
        transactionNextSteps?.filter((nextStep) => nextStep.type === NextStepKind.ManualTrigger).length > 0;

    return (
        <>
            <Grid id="transaction-match" container sx={{
                width: '100%',
                marginTop: theme.spacing(4),
            }} spacing={2}>
                <Grid
                    item
                    id="section-update-match"
                    sx={{
                        marginBottom: theme.spacing(4),
                    }}
                    component="section"
                    xs={12}
                    sm={8}
                >
                    <OnboardingForm // FIXME: DO NOT USE ONBOARDING FORM IN HERE!
                        fields={matchFields}
                        submitText={nextManualStepsExist ? 'Save' : 'Continue'}
                        sharedObjects={sharedObjects}
                        sharedObjectTypes={sharedObjectTypes}
                        onSubmit={handleSubmitUpdateMatchForm}
                        showSubmitBtn={Boolean(matchFieldsWriteAccess?.length)}
                    />
                </Grid>
                <Grid id="section-supply-data" item component="section" xs={12} sm={8}>
                    <SupplyData supplyFields={supplyFields} />
                </Grid>
                <Grid id="section-match-overview" component="section" item xs={12} sm={4}>
                    <SingleDirectTransactionMatchOverview
                        transactionId={transactionId}
                        requestFields={matchingToolFields}
                        supplyFields={supplyFields}
                        participantFields={providerFields}
                        matchPriceOverrideEnabled={matchPriceOverrideEnabled}
                        matchPrice={matchPrice}
                        perspective='CONSUMER'
                        isMatchConfigurationPricingEnabled={isMatchConfigurationPricingEnabled}
                    />
                    <br />
                    <SingleDirectConsumerParticipantActions
                        transactionId={transactionId}
                        transactionStatus={transactionStatus}
                        transactionTechName={transactionTechName}
                        matchId={matchId}
                        backofficeApprovedAt={backofficeApprovedAt}
                        backofficeRejectedAt={backofficeRejectedAt}
                        consumerAcceptedAt={consumerAcceptedAt}
                        providerAcceptedAt={providerAcceptedAt}
                        consumerDeclinedAt={consumerDeclinedAt}
                        providerDeclinedAt={providerDeclinedAt}
                        paymentTypes={paymentTypes}
                        participantMatchRole={MatchTypeRoleKind.Consumer}
                        nextSteps={transactionNextSteps}
                    />
                </Grid>
                <Divider />
            </Grid>
        </>
    );
};

export default SingleDirectTransactionMatchForConsumer;
