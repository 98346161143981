import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { useLocation, useParams } from 'react-router';

import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { buildQueryParams, parseDiscoveryFilters } from '@rdv-fo/app/lib/url';
import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';

import DiscoveredSupplies from '@rdv-fo/components/discovery/DiscoveredSupplies';
import DiscoveryFilters from '@rdv-fo/components/discovery/DiscoveryFilters';
import GeneralLayout from '@rdv-fo/components/layout/types/GeneralLayout';
import PageWidth from '@rdv-fo/components/layout/PageWidth';
import { discoverTransactionSupplies, setSelectedFilters, selectDiscoverSupplyStatus, selectDiscoveryPageInfo, discoverMoreTransactionSupplies } from '@rdv-fo/store/slices/discoverySlice';
import { selectToken } from '@rdv-fo/store/slices/authSlice';
import { replaceCurrentRouteWith } from '@rdv-fo/store/slices/uiSlice';
import { removeFilterKeys } from '@rdv-fo/app/lib/supply';

import { setSearchBarInputs } from '@rdv-fo/store/slices/discoverySlice';
import {
	selectCurrentTransactionType,
	selectTransactionTypes,
	setSelectedTransactionType,
} from '@rdv-fo/store/slices/transactionSlice';
import { mapFieldFiltersToNestedFieldFilters, parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords } from '@rdv-fo/app/lib/discovery';
import { selectPlatformName } from '@rdv-fo/store/slices/platformSlice';
import RdvDrawer from '@rdv-fo/components/common/RdvDrawer';
import {
	AvailabilityManagementKind,
	MatchingToolType,
	TransactionType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';

const DiscoveredTransactionSuppliesPage = () => {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { transactionTypeTechName } = useParams<{ transactionTypeTechName: string }>();
	const discoverSupplyStatus = useAppSelector(selectDiscoverSupplyStatus);
	const pageInfo = useAppSelector(selectDiscoveryPageInfo);
	const hasNextPage = pageInfo?.has_next_page;

	const dispatch = useAppDispatch();
	const token = useAppSelector(selectToken);
	const location = useLocation();
	const platformName = useAppSelector(selectPlatformName);
	const currentTransactionType = useAppSelector(selectCurrentTransactionType);
	const myTransactionTypes = useAppSelector(selectTransactionTypes);

	const queryParams = parseDiscoveryFilters(location.search);
	const { filters } = parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords(queryParams);
	const numberOfSelectedFilters = filters ? filters.length : 0;

	const contextTransactionType =
		myTransactionTypes?.find((tt) => tt.tech_name === transactionTypeTechName) ??
		(currentTransactionType as TransactionType);

	dispatch(setSelectedTransactionType(contextTransactionType));

	const rootType = contextTransactionType?.root_type as MatchingToolType;
	const matchingUnitKind = rootType.match_type.matching_unit_type;
	const matchTypeId = rootType?.match_type?.id;
	const supplyTypeId = rootType?.match_type?.supply_type?.id;
	const transactionTechName = contextTransactionType?.tech_name;

	const supplyTypeFieldTypes = rootType?.match_type?.supply_type?.fields ?? [];

	const availabilityManagement =
		contextTransactionType?.match_configuration?.availability_management_type ?? AvailabilityManagementKind.None;

	const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);

	useEffect(() => {
		if (token && matchTypeId && supplyTypeId && platformName) {
			const queryParams = parseDiscoveryFilters(location.search);

			dispatch(setSelectedFilters(queryParams));

			const { filters, availability_params, available_supply_only } =
				parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords(queryParams);

			dispatch(
				discoverTransactionSupplies({
					transaction_tech_name: transactionTypeTechName,
					where: {
						fields: { AND: mapFieldFiltersToNestedFieldFilters(filters, supplyTypeFieldTypes) },
						ids_collections: []
					},
					first: 10,
					availability_params,
					available_supply_only: available_supply_only as boolean,
				})
			);
			if (openFiltersDrawer) setOpenFiltersDrawer(false);
		}
	}, [dispatch, platformName, location.search, token, matchTypeId, supplyTypeId, transactionTypeTechName]);

	const handleDiscoverSupplies = async (filterValues: any) => {
		await dispatch(setSearchBarInputs(removeFilterKeys(filterValues)));
		await dispatch(
			replaceCurrentRouteWith(
				`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES.replace(':transactionTypeTechName', transactionTypeTechName)}${buildQueryParams(filterValues, supplyTypeFieldTypes)}`
			)
		);
	};

	const handleClearFilterValues = async () => {
		dispatch(setSelectedFilters({}));
		dispatch(
			replaceCurrentRouteWith(
				routeBuilder(
					`${ROUTES.DISCOVERED_TRANSACTION_SUPPLIES.replace(':transactionTypeTechName', transactionTypeTechName)}`
				)
			)
		);
	};

	const handleLoadMoreSupplies = () => {
		const queryParams = parseDiscoveryFilters(location.search);

		const { filters, availability_params, available_supply_only } =
			parseQueryParamsIntoFieldFilterInputsAndAvailabilityRecords(queryParams);

		dispatch(
			discoverMoreTransactionSupplies({
				transaction_tech_name: transactionTypeTechName,
				where: {
					fields: { AND: mapFieldFiltersToNestedFieldFilters(filters, supplyTypeFieldTypes) },
				},
				first: 10,
				after: pageInfo?.end_cursor
			})
		);
	}

	return (
		<GeneralLayout>
			<PageWidth paddingTop={theme.spacing(4) as any} paddingBottom={theme.spacing(4) as any}>
				<Box sx={{ minHeight: 'calc(100vh - 64px)' }}>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						sx={{ height: '100%' }}
						spacing={4}
					>
						<Grid id="discovery-filters" item xs={12} sm={3} sx={{ height: 'auto', mt: 2 }}>
							{isMobileScreen ? (
								<>
									<Button variant="text" color="inherit" onClick={() => setOpenFiltersDrawer(true)}>
										Filters{` (${numberOfSelectedFilters})`}
									</Button>
									<RdvDrawer
										open={openFiltersDrawer}
										title=""
										loading={false}
										onClose={() => setOpenFiltersDrawer(false)}
									>
										<DiscoveryFilters
											disabled={discoverSupplyStatus === API_STATUS.LOADING}
											onDiscoverWithFilters={handleDiscoverSupplies}
											typeFieldTypes={supplyTypeFieldTypes}
											availabilityManagement={availabilityManagement}
											onClearFilters={handleClearFilterValues}
											matchingUnitType={matchingUnitKind}
											numberOfSelectedFilters={numberOfSelectedFilters}
											useDeprecatedFilterInputs={false}
										/>
									</RdvDrawer>
								</>
							) : (
								<DiscoveryFilters
									disabled={discoverSupplyStatus === API_STATUS.LOADING}
									onDiscoverWithFilters={handleDiscoverSupplies}
									typeFieldTypes={supplyTypeFieldTypes}
									availabilityManagement={availabilityManagement}
									onClearFilters={handleClearFilterValues}
									matchingUnitType={matchingUnitKind}
									numberOfSelectedFilters={numberOfSelectedFilters}
									useDeprecatedFilterInputs={false}
								/>
							)}
						</Grid>
						<Grid id="discovery-results" item xs={12} sm={9} sx={{ minHeight: '100%', overflowY: 'scroll', pb: 8 }}>
							<DiscoveredSupplies transactionTypeTechName={transactionTechName} />
						</Grid>
						{hasNextPage &&
							<>
								<Grid id="discovery-filters" item xs={12} sm={3}>
								</Grid>
								<Grid id="discovery-pagination" item xs={9} sx={{ pb: 4 }}>
									<Stack width='100%' alignItems='center'>

										<Button variant='text' color='primary' onClick={handleLoadMoreSupplies}>
											Load more
										</Button>
									</Stack>
								</Grid>
							</>
						}
					</Grid>
				</Box>
			</PageWidth>
		</GeneralLayout>
	);
};

export default DiscoveredTransactionSuppliesPage;
