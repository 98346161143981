import { Paper, Stack, Typography } from '@mui/material';

interface CollectionCardProps {
    id: string;
    name: string;
    isSelected: boolean;
    numberOfItems: number;
    onClick: (id: string) => void;
}

const CollectionCard = ({ id, name, numberOfItems, isSelected, onClick }: CollectionCardProps) => {
    return (
        <Stack direction="column" spacing={1}>
            <Stack
                direction="column"
                component={Paper}
                elevation={1}
                onClick={() => onClick(id)}
                sx={{
                    backgroundColor: isSelected ? 'primary.dark' : 'secondary.main',
                    cursor: 'pointer',
                    ':hover': { backgroundColor: 'primary.dark' },
                }}
                justifyContent="center"
                alignItems="center"
                width="13rem"
                height="13rem"
            >
                <Typography
                    variant="overline"
                    sx={{ wordBreak: 'break-word', textAlign: 'center', whiteSpace: 'break-spaces' }}
                    fontSize={18}
                >
                    {name}
                </Typography>
                <Typography variant="body2">
                    {numberOfItems === 1 ? `(${numberOfItems}) item` : `(${numberOfItems}) items`}
                </Typography>
            </Stack>
            <Typography variant="subtitle1">{name}</Typography>
        </Stack>
    );
};

export default CollectionCard;
