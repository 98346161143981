import { Button, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import PageWidth from '../layout/PageWidth';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { LINKS } from '@rdv-fo/app/constants';
import { useAppSelector } from '@rdv-fo/store/configureStore';
import { selectPlatformContactDetails, selectPlatformDisplayName } from '@rdv-fo/store/slices/platformSlice';
import LanguageIcon from '@mui/icons-material/Language';

const DotSeparator = () => (
	<Typography color="inherit" component="span" variant="body1">
		{` · `}
	</Typography>
);

const sxIcon = { fontSize: 20 };

const Footer = () => {
	const theme: Theme = useTheme();

	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const platformDisplayName = useAppSelector(selectPlatformDisplayName);
	const platformContactDetails = useAppSelector(selectPlatformContactDetails);
	const twitter = platformContactDetails?.twitter;
	const email = platformContactDetails?.email;
	const facebook = platformContactDetails?.facebook;
	const linkedin = platformContactDetails?.linkedin;
	const instagram = platformContactDetails?.instagram;
	const terms = platformContactDetails?.terms;
	const privacy = platformContactDetails?.privacy;
	const imprint = platformContactDetails?.imprint;
	const support = platformContactDetails?.support;

	return (
		<>
			<PageWidth>
				<Stack
					direction={isMobileScreen ? 'column' : 'row'}
					justifyContent="space-between"
					alignItems="center"
					spacing={isMobileScreen ? 3 : 2}
				>
					<Stack
						direction={isMobileScreen ? 'column' : 'row'}
						justifyContent="flex-start"
						alignItems="center"
						spacing={isMobileScreen ? 2 : 1}
					>
						<Stack direction="row" spacing={1}>
							<Typography color="inherit" display="inline" variant="body1">
								{`© ${new Date().getFullYear()} ${platformDisplayName} runs on `}
							</Typography>
							<Link
								color="inherit"
								target="_blank"
								href={LINKS.RANDEVU.LANDING_PAGE}
								sx={{
									textDecoration: 'none',
									'&:hover': {
										textDecoration: 'underline',
									},
								}}
							>
								randevu.tech
							</Link>
						</Stack>

						<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
							{!isMobileScreen && (terms || privacy || imprint || support) && <DotSeparator />}
							{terms && (
								<>
									<Link
										color="inherit"
										target="_blank"
										href={terms}
										sx={{
											textDecoration: 'none',
											'&:hover': {
												textDecoration: 'underline',
											},
										}}
									>
										Terms
									</Link>
								</>
							)}
							{privacy && (
								<>
									<DotSeparator />
									<Link
										color="inherit"
										target="_blank"
										href={privacy}
										sx={{
											textDecoration: 'none',
											'&:hover': {
												textDecoration: 'underline',
											},
										}}
									>
										Privacy
									</Link>
								</>
							)}
							{imprint && (
								<>
									<DotSeparator />
									<Link
										color="inherit"
										target="_blank"
										href={imprint}
										sx={{
											textDecoration: 'none',
											'&:hover': {
												textDecoration: 'underline',
											},
										}}
									>
										Imprint
									</Link>
								</>
							)}
							{support && (
								<>
									<DotSeparator />
									<Link
										color="inherit"
										target="_blank"
										href={support}
										sx={{
											textDecoration: 'none',
											'&:hover': {
												textDecoration: 'underline',
											},
										}}
									>
										Support
									</Link>{' '}
								</>
							)}
						</Stack>
					</Stack>

					<Stack
						direction="row"
						justifyContent={isMobileScreen ? 'space-between' : 'flex-end'}
						alignItems="center"
						{...(isMobileScreen && { sx: { width: '100%' } })}
						//sx={{ width: isMobileScreen ? 'auto' : '100%' }}
						spacing={2}
						sx={{ p: { sm: 1 } }}
					>
						<Button
							size="small"
							color="inherit"
							startIcon={<LanguageIcon />}
							onClick={() => alert('New languages are coming soon')}
						>
							English (US)
						</Button>

						<Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
							{email && (
								<IconButton
									href={`mailto:${email}`}
									target="_blank"
									rel="noopener"
									color="inherit"
									edge="start"
									size="small"
								>
									<MailOutlineIcon sx={sxIcon} />
								</IconButton>
							)}
							{linkedin && (
								<IconButton href={linkedin} target="_blank" rel="noopener" color="inherit" size="small">
									<LinkedInIcon sx={sxIcon} />
								</IconButton>
							)}
							{instagram && (
								<IconButton
									href={instagram}
									target="_blank"
									rel="noopener"
									color="inherit"
									size="small"
								>
									<InstagramIcon sx={sxIcon} />
								</IconButton>
							)}
							{twitter && (
								<IconButton href={twitter} target="_blank" rel="noopener" color="inherit" size="small">
									<TwitterIcon sx={sxIcon} />
								</IconButton>
							)}
							{facebook && (
								<IconButton
									href={facebook}
									target="_blank"
									rel="noopener"
									color="inherit"
									edge="end"
									size="small"
								>
									<FacebookIcon sx={sxIcon} />
								</IconButton>
							)}
						</Stack>
					</Stack>
				</Stack>
			</PageWidth>
		</>
	);
};

export default Footer;
