import { styled, Tab, TabProps, Tabs, TabsProps, Typography } from '@mui/material';
import { RDV_NEUTRAL_100 } from '@rdv-fo/styles/theme';

interface ParticipantType {
    id: string;
    name: string;
}

interface SharedObjectTypeFilterProps {
    selectedTypeId?: string;
    types: ParticipantType[];
    onFilter: (any: any) => void;
}


const RdvTabs = styled((props: TabsProps) => <Tabs {...props} />)(() => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
}));

const RdvTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
    root: {
        minWidth: 12,
        padding: 0,
        marginRight: theme.spacing(2.5),
        color: RDV_NEUTRAL_100,
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
    },
    selected: {
        fontWeight: 800,
    },
}));
const SharedObjectTypeFilter = ({ selectedTypeId: selectedType, types, onFilter }: SharedObjectTypeFilterProps) => {
    const selectedFilter = selectedType === undefined ? types[0]?.id : selectedType;
    const showFilter = types?.length > 1;

    return (
        <>
            {!showFilter && <Typography />}
            {showFilter && (
                <RdvTabs value={selectedFilter} indicatorColor="primary" textColor="primary">
                    {types?.map((type) => (
                        <RdvTab key={type.id} value={type.id} label={type.name} onClick={() => onFilter(type.id)} />
                    ))}
                </RdvTabs>
            )}
        </>
    );
};

export default SharedObjectTypeFilter;
