import { Chip } from '@mui/material'
import { TransferStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes'
import React from 'react'
import { Tooltip } from '@mui/material/';

const determineStatusChipColorAndLabelFromStatus = (status: TransferStatusKind) => {
    switch (status) {
        case TransferStatusKind.Created:
            return { label: 'Created', color: 'info' as 'info' }
        case TransferStatusKind.Failed:
            return { label: 'Failed', color: 'error' as 'error' }

    }
    return { label: 'Succeeded', color: 'success' as 'success' }
}

interface TransferStatusChipProps {
    status: TransferStatusKind;
    transferError?: any | null;
}

const TransferStatusChip = ({ status, transferError }: TransferStatusChipProps) => {
    const chipProps = determineStatusChipColorAndLabelFromStatus(status)

    if (transferError) {

        let msg = transferError.error_message;
        if (transferError.error_code) msg = msg + ` (Error code: ${transferError.error_code})`

        if (!msg) msg = "Transfer failed."

        return (
            <Tooltip title={msg}>
                <div>
                    <Chip color={chipProps.color} label={chipProps.label} />
                </div>
            </Tooltip >)
    }
    return (
        <Chip color={chipProps.color} label={chipProps.label} />
    )
}

export default TransferStatusChip