import Typography from '@mui/material/Typography';

import { Box, Stack } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import AccountCard from '@rdv-fo/components/participant/account/AccountCard';
import Grid from '@mui/material/Grid';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ROUTES from '@rdv-fo/common/routes';

const MyAccountPage = () => {
	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<BackButton />
				<Typography component="h1" variant="h1">
					Account
				</Typography>
			</Stack>
			<Box sx={{ mt: 5 }}>
				<Grid
					container
					direction="row"
					width="100%"
					spacing={2}
					justifyContent="flex-start"
					alignItems="flex-start"
				>
					<Grid item xs={12} md={4}>
						<AccountCard
							icon={<BadgeOutlinedIcon fontSize="large" />}
							title="Profile"
							description="Manage data about your profile"
							routeTo={ROUTES.PARTICIPANT_PROFILE}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<AccountCard
							icon={<PaymentOutlinedIcon fontSize="large" />}
							title="Payments & Transfers"
							description="Review payments, transfers, payouts, and merchant accounts"
							routeTo={ROUTES.PAYMENTS_AND_TRANSFERS}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<AccountCard
							icon={<HttpsOutlinedIcon fontSize="large" />}
							title="Log in & security"
							description="Update your password and secure your account"
							routeTo={ROUTES.MY_CREDENTIALS}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<AccountCard
							icon={<ToggleOffOutlinedIcon fontSize="large" />}
							title="Global preferences"
							description="Set your default language, currency, and timezone"
							comingSoon
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<AccountCard
							icon={<NotificationsActiveOutlinedIcon fontSize="large" />}
							title="Notifications"
							description="Choose notification preferences and how you want to be contacted"
							comingSoon
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default MyAccountPage;
