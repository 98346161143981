import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';
import { RDV_BLACK } from '@rdv-fo/styles/theme';

interface LabelFieldInputProps {
	htmlFor: string;
	text?: string;
	required?: boolean;
	showOptional?: boolean;
}
const LabelFieldInput = ({ htmlFor, text, required = false, showOptional = false }: LabelFieldInputProps) => {

	return (
		<FormLabel sx={{ color: RDV_BLACK, fontWeight: 500 }} htmlFor={htmlFor}>
			{text}
			{!required && showOptional && (
				<Typography color="textSecondary" display="inline" sx={{
					fontSize: '0.8em',
					paddingLeft: 1,
				}}>
					Optional
				</Typography>
			)}
		</FormLabel>
	);
};

export default LabelFieldInput;
