import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import randevu from '@rdv-fo/services/randevuApi';
import { isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { goToRoute, sendInfoToasty, sendSuccessToasty, sendUnexpectedErrorToasty } from './uiSlice';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { sleep } from '@rdv-fo/services/sleep';
import { MATCH_TYPE_ROLE_KIND } from '@rdv-fo/services/randevuApi';
import { sortByCreatedAt } from '../helpers/sortByCreatedAt';
import { setSearchBarInputs } from './discoverySlice';
import {
	Field,
	FieldInput,
	MatchTypeRoleKind,
	SingleDirectTransactionFilter,
	SingleDirectTransactionForConsumer,
	SingleDirectTransactionForConsumerEdge,
	SingleDirectTransactionForProvider,
	SingleDirectTransactionForProviderEdge,
	SupplyType,
	Transaction,
	TransactionKind,
	TransactionLight,
	TransactionType,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { activeShoppingCartLoaded } from './shoppingCartSlice';
import { AppDispatch, RootState } from '../configureStore';
import THUNKS from '../thunkMap';

interface PaginationMetadata {
	page_info: {
		has_next_page: boolean;
		has_previous_page: boolean;
		start_cursor?: string;
		end_cursor?: string;
	};
	total_count: number;
}

interface TransactionSliceState {
	item: Transaction | null;
	items: TransactionLight[];
	parentTransaction: Transaction | null;
	loading: boolean;
	errors: any;
	transactionTypes: TransactionType[];
	defaultTransactionType: TransactionType | null;
	currentTransactionType: TransactionType | null;
	mySupplyTypes: SupplyType[];
	singleDirectTransactions: SingleDirectTransactionForProvider[] | SingleDirectTransactionForConsumer[];
	singleDirectTransaction: SingleDirectTransactionForProvider | SingleDirectTransactionForConsumer | null;
	singleDirectTransactionsPaginationMetadata: PaginationMetadata | null;
}

const initialState: TransactionSliceState = {
	item: null,
	items: [],
	parentTransaction: null,
	loading: false,
	errors: null,
	transactionTypes: [],
	defaultTransactionType: null,
	currentTransactionType: null,
	mySupplyTypes: [],
	singleDirectTransactions: [],
	singleDirectTransaction: null,
	singleDirectTransactionsPaginationMetadata: null,
};

const slice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		loadMyTransactionsRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		loadMyTransactionsFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		myTransactionsLoaded: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = null;
			transaction.items = action.payload;
		},
		loadMyTransactionRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		loadMyTransactionFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		myTransactionLoaded: (
			transaction: TransactionSliceState,
			action: PayloadAction<{ transaction: Transaction }>
		) => {
			transaction.loading = false;
			transaction.errors = null;
			transaction.item = { ...action.payload.transaction };
		},
		parentTransactionRequested: (transaction: TransactionSliceState) => {
			transaction.parentTransaction = null;
		},
		parentTransactionLoaded: (
			transaction: TransactionSliceState,
			action: PayloadAction<{ transaction: Transaction }>
		) => {
			transaction.parentTransaction = action.payload.transaction;
		},
		loadMySingleDirectTransactionRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		loadMySingleDirectTransactionFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		mySingleDirectTransactionLoaded: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = null;
			transaction.singleDirectTransaction = {
				...action.payload.transaction,
			};
		},
		updateMySingleDirectTransactionMatchRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		updateMySingleDirectTransactionMatchFailed: (
			transaction: TransactionSliceState,
			action: PayloadAction<any>
		) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		mySingleDirectTransactionMatchUpdated: (transaction: TransactionSliceState) => {
			transaction.loading = false;
			transaction.errors = null;
		},
		resetTransaction: (transaction: TransactionSliceState) => {
			transaction.item = null;
		},
		setSelectedTransactionType: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.currentTransactionType = action.payload;
		},
		createAndStartSingleDirectTransactionRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		createAndStartSingleDirectTransactionFailed: (
			transaction: TransactionSliceState,
			action: PayloadAction<any>
		) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		singleDirectTransactionCreatedAndStarted: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = null;
		},
		initiateReverseAuctionTransactionRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		initiateReverseAuctionTransactionFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		reverseAuctionTransactionInitiated: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = null;
		},
		loadTransactionTypesRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		loadTransactionTypesFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		transactionTypesLoaded: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = null;
			transaction.transactionTypes = action.payload;
			transaction.defaultTransactionType = action.payload?.find((tt: TransactionType) => tt.is_default) ?? null;
			const currentTT = transaction.currentTransactionType;
			if (currentTT)
				transaction.currentTransactionType =
					action.payload?.find((tt: TransactionType) => tt.id === currentTT.id) ?? null;

			const mySupplyTypes: SupplyType[] = [];
			action.payload?.forEach((tt: any) => {
				const supplyType = tt.root_type?.match_type?.supply_type;
				const isSupplyInSupplyTypes = mySupplyTypes.find((st) => st.id === supplyType?.id);
				if (supplyType && tt.my_role === MATCH_TYPE_ROLE_KIND.PROVIDER.value && !isSupplyInSupplyTypes)
					mySupplyTypes.push(supplyType);
			});
			transaction.mySupplyTypes = mySupplyTypes;
		},
		resetTransactionTypes: (transaction: TransactionSliceState) => {
			transaction.loading = false;
			transaction.errors = null;
			transaction.transactionTypes = [];
			transaction.defaultTransactionType = null;
			transaction.currentTransactionType = null;
		},
		triggerManualTransitionRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		triggerManualTransitionFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		manualTransitionTriggered: (transaction: TransactionSliceState) => {
			transaction.loading = false;
			transaction.errors = null;
		},
		forceTransactionTerminationRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		forceTransactionTerminationFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		transactionTerminated: (transaction: TransactionSliceState) => {
			transaction.loading = false;
			transaction.errors = null;
		},
		overrideMatchPriceRequested: (transaction: TransactionSliceState) => {
			transaction.errors = null;
		},
		overrideMatchPriceFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.errors = action.payload;
		},
		matchPriceOverridden: (transaction: TransactionSliceState) => {
			transaction.errors = null;
		},
		loadMySingleDirectTransactionsRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
			transaction.singleDirectTransactions = [];
		},
		loadMySingleDirectTransactionsFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		mySingleDirectTransactionsLoaded: (transaction, action) => {
			transaction.singleDirectTransactionsPaginationMetadata = {
				page_info: action.payload.page_info,
				total_count: action.payload.total_count,
			};
			transaction.singleDirectTransactions = action.payload.edges.map(
				(edge: SingleDirectTransactionForConsumerEdge | SingleDirectTransactionForProviderEdge) => edge.node
			);
			transaction.errors = null;
			transaction.loading = false;
		},
		acceptSingleDirectMatchRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		acceptSingleDirectMatchFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		singleDirectMatchAccepted: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = null;
			// TODO: workaround until payment is attached to match
			transaction.singleDirectTransaction = {
				...action.payload.transaction,
			};
		},
		declineSingleDirectMatchRequested: (transaction: TransactionSliceState) => {
			transaction.loading = true;
			transaction.errors = null;
		},
		declineSingleDirectMatchFailed: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = action.payload;
		},
		singleDirectMatchDeclined: (transaction: TransactionSliceState, action: PayloadAction<any>) => {
			transaction.loading = false;
			transaction.errors = null;
			// TODO: workaround until payment is attached to match
			transaction.singleDirectTransaction = {
				...action.payload.transaction,
			};
		},
	},
});

export const {
	loadMyTransactionsRequested,
	loadMyTransactionsFailed,
	myTransactionsLoaded,
	loadMyTransactionRequested,
	loadMyTransactionFailed,
	myTransactionLoaded,
	resetTransaction,
	setSelectedTransactionType,
	createAndStartSingleDirectTransactionRequested,
	createAndStartSingleDirectTransactionFailed,
	singleDirectTransactionCreatedAndStarted,
	initiateReverseAuctionTransactionRequested,
	initiateReverseAuctionTransactionFailed,
	reverseAuctionTransactionInitiated,
	loadTransactionTypesRequested,
	loadTransactionTypesFailed,
	transactionTypesLoaded,
	resetTransactionTypes,
	triggerManualTransitionRequested,
	triggerManualTransitionFailed,
	manualTransitionTriggered,
	forceTransactionTerminationRequested,
	forceTransactionTerminationFailed,
	transactionTerminated,
	overrideMatchPriceRequested,
	overrideMatchPriceFailed,
	matchPriceOverridden,
	loadMySingleDirectTransactionsRequested,
	loadMySingleDirectTransactionsFailed,
	mySingleDirectTransactionsLoaded,
	loadMySingleDirectTransactionRequested,
	loadMySingleDirectTransactionFailed,
	mySingleDirectTransactionLoaded,
	updateMySingleDirectTransactionMatchRequested,
	updateMySingleDirectTransactionMatchFailed,
	mySingleDirectTransactionMatchUpdated,
	acceptSingleDirectMatchRequested,
	acceptSingleDirectMatchFailed,
	singleDirectMatchAccepted,
	declineSingleDirectMatchRequested,
	declineSingleDirectMatchFailed,
	singleDirectMatchDeclined,
	parentTransactionRequested,
	parentTransactionLoaded,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

interface LoadMyTransactionsRequest {
	order: 'NEWEST_FIRST';
}

export const loadMyTransactions =
	({ order }: LoadMyTransactionsRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my transactions`, order);

		dispatch(loadMyTransactionsRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		let { transactions, errors } = await randevuService.transactions.myTransactions();

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyTransactionsFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_TRANSACTIONS.key,
					payload: { order },
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMyTransactionsFailed(errors));
		}

		transactions = (transactions as any)?.sort((a: any, b: any) => sortByCreatedAt({ a, b, order })) ?? []; //FIXME @Rokva - use proper types

		return dispatch(myTransactionsLoaded(transactions));
	};

interface LoadMyTransactionRequest {
	id_transaction: string;
	id_parent_transaction?: string;
}

export const loadMyTransaction =
	({ id_transaction, id_parent_transaction }: LoadMyTransactionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my transaction ${id_transaction}`);

		dispatch(loadMyTransactionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { transaction, errors } = await randevuService.transactions.myTransaction({
			id: id_transaction,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyTransactionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_TRANSACTION.key,
					payload: { id_transaction, id_parent_transaction },
				},
			});

		if (errors || !transaction) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMyTransactionFailed(errors));
		}

		if (id_parent_transaction) {
			dispatch(parentTransactionRequested());
			const { transaction, errors } = await randevuService.transactions.myTransaction({
				id: id_parent_transaction,
			});

			if (errors || !transaction) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(loadMyTransactionFailed(errors));
			}

			dispatch(parentTransactionLoaded({ transaction }));
		}

		return dispatch(myTransactionLoaded({ transaction }));
	};

export const loadMySingleDirectTransactionForConsumer =
	({ id_transaction }: LoadMyTransactionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my single direct transaction for consumer ${id_transaction}`);

		dispatch(loadMySingleDirectTransactionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { transaction, errors } = await randevuService.transactions.mySingleDirectTransactionForConsumer({
			id: id_transaction,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyTransactionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_TRANSACTION.key,
					payload: { id_transaction },
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMySingleDirectTransactionFailed(errors));
		}

		return dispatch(mySingleDirectTransactionLoaded({ transaction }));
	};

interface UpdateSingleDirectTransactionMatchForConsumerRequest {
	id: string;
	matchFields: FieldInput[];
	currentFields: Field[];
}

export const updateSingleDirectTransactionMatchForConsumer =
	({ id, matchFields, currentFields }: UpdateSingleDirectTransactionMatchForConsumerRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(updateMySingleDirectTransactionMatchRequested());

		const randevuService = new randevu({
			token: getState().auth.token,
			apiKey: getState().platform.public_key,
		});

		// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
		const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

		if (isSessionExpired(expiredSession))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: updateMySingleDirectTransactionMatchFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.UPDATE_SINGLE_DIRECT_TRANSACTION_MATCH_FOR_CONSUMER.key,
					payload: { id, matchFields, currentFields },
				},
			});

		const { field_inputs: dirty_fields_with_uploaded_files, errors: uploadErrors } =
			await randevuService.files.uploadFieldInputsFiles({
				field_inputs: matchFields,
				fields: currentFields,
			});

		const { updated, errors } = await randevuService.transactions.updateSingleDirectMatch({
			id,
			match_fields: dirty_fields_with_uploaded_files,
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(updateMySingleDirectTransactionMatchFailed(errors));
		}

		await sleep(1000);

		const { transaction, errors: fetchErrors } =
			await randevuService.transactions.mySingleDirectTransactionForConsumer({
				id: id,
			});
		dispatch(mySingleDirectTransactionLoaded({ transaction }));

		return dispatch(mySingleDirectTransactionMatchUpdated());
	};
interface UpdateSingleDirectTransactionMatchForProviderRequest {
	id: string;
	matchFields: FieldInput[];
	currentFields: Field[];
}

export const updateSingleDirectTransactionMatchForProvider =
	({ id, matchFields, currentFields }: UpdateSingleDirectTransactionMatchForProviderRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(updateMySingleDirectTransactionMatchRequested());

		const randevuService = new randevu({
			token: getState().auth.token,
			apiKey: getState().platform.public_key,
		});

		// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
		const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

		if (isSessionExpired(expiredSession))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: updateMySingleDirectTransactionMatchFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.UPDATE_SINGLE_DIRECT_TRANSACTION_MATCH_FOR_PROVIDER.key,
					payload: { id, matchFields, currentFields },
				},
			});

		const { field_inputs: dirty_fields_with_uploaded_files, errors: uploadErrors } =
			await randevuService.files.uploadFieldInputsFiles({
				field_inputs: matchFields,
				fields: currentFields,
			});

		const { updated, errors } = await randevuService.transactions.updateDirectMatchForProvider({
			id,
			match_fields: dirty_fields_with_uploaded_files,
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(updateMySingleDirectTransactionMatchFailed(errors));
		}

		await sleep(1000);

		const { transaction, errors: fetchErrors } =
			await randevuService.transactions.mySingleDirectTransactionForProvider({
				id: id,
			});
		dispatch(mySingleDirectTransactionLoaded({ transaction }));

		return dispatch(mySingleDirectTransactionMatchUpdated());
	};
export const loadMySingleDirectTransactionForProvider =
	({ id_transaction }: LoadMyTransactionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my single direct transaction for provider ${id_transaction}`);

		dispatch(loadMySingleDirectTransactionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { transaction, errors } = await randevuService.transactions.mySingleDirectTransactionForProvider({
			id: id_transaction,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMySingleDirectTransactionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_SINGLE_DIRECT_TRANSACTION_FOR_PROVIDER.key,
					payload: { id_transaction },
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMyTransactionFailed(errors));
		}

		return dispatch(mySingleDirectTransactionLoaded({ transaction }));
	};

interface LoadMySingleDirectTransactionsRequest {
	where: SingleDirectTransactionFilter;
	before?: string;
	after?: string;
	first?: number;
	last?: number;
}

export const loadMySingleDirectTransactions =
	({ where, before, last, after, first }: LoadMySingleDirectTransactionsRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my single direct transactions`);

		dispatch(loadMySingleDirectTransactionsRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		let { transactions, errors } = await randevuService.transactions.mySingleDirectTransactionsForConsumer({
			where,
			before,
			last,
			after,
			first,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyTransactionsFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_SINGLE_DIRECT_TRANSACTIONS.key,
					payload: { where, before, last, after, first },
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMySingleDirectTransactionsFailed(errors));
		}

		return dispatch(mySingleDirectTransactionsLoaded(transactions));
	};

interface LoadMySingleDirectTransactionsForProvider {
	where: SingleDirectTransactionFilter;
	before?: string;
	after?: string;
	first?: number;
	last?: number;
}

export const loadMySingleDirectTransactionsForProvider =
	({ where, before, last, after, first }: LoadMySingleDirectTransactionsForProvider) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Load my single direct transactions for provider`);

		dispatch(loadMySingleDirectTransactionsRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		let { transactions, errors } = await randevuService.transactions.mySingleDirectTransactionsForProvider({
			where,
			before,
			last,
			after,
			first,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: loadMyTransactionsFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.LOAD_MY_SINGLE_DIRECT_TRANSACTIONS_FOR_PROVIDER.key,
					payload: { where, before, last, after, first },
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMySingleDirectTransactionsFailed(errors));
		}

		return dispatch(mySingleDirectTransactionsLoaded(transactions));
	};

interface CreateAndStartSingleDirectTransactionRequest {
	transaction_tech_name: string;
	id_supply: any; //FIXME @Rokva - use proper type
	matching_tool_dirty_fields: any; //FIXME @Rokva - use proper type
	matching_tool_fields: any; //FIXME @Rokva - use proper type
}

export const createAndStartSingleDirectTransaction =
	({
		transaction_tech_name,
		id_supply,
		matching_tool_dirty_fields,
		matching_tool_fields,
	}: CreateAndStartSingleDirectTransactionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log('Create and start single direct transaction', transaction_tech_name);

		dispatch(createAndStartSingleDirectTransactionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
		const { errors: expiredSession } = await randevuService.auth.verifyValidSession();
		if (isSessionExpired(expiredSession))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: createAndStartSingleDirectTransactionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.CREATE_AND_START_SINGLE_DIRECT_TRANSACTION.key,
					payload: { transaction_tech_name, id_supply, matching_tool_dirty_fields, matching_tool_fields },
				},
			});

		const { field_inputs: dirty_fields_with_uploaded_files, errors: uploadErrors } =
			await randevuService.files.uploadFieldInputsFiles({
				field_inputs: matching_tool_dirty_fields,
				fields: matching_tool_fields,
			});

		const { transaction, errors } = await randevuService.transactions.createSingleDirectTransaction({
			transaction_tech_name,
			id_supply,
			fields: dirty_fields_with_uploaded_files,
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(createAndStartSingleDirectTransactionFailed(errors));
		}

		if (transaction) {
			const { transaction: startedTransaction, errors: startErrors } =
				await randevuService.transactions.startSingleDirectTransaction({
					id: transaction.id,
				});
		}

		// FIXME: REMOVE completely
		dispatch(setSearchBarInputs({}));

		const transactionDetailsRoute = routeBuilder(ROUTES.SINGLE_DIRECT_TRANSACTION_DETAILS, [
			[':transactionId', transaction?.id],
			[':transactionTypeTechName', transaction_tech_name],
		]);

		dispatch(goToRoute(transactionDetailsRoute));

		return dispatch(singleDirectTransactionCreatedAndStarted(transaction));
	};

interface InitiateReverseAuctionTransactionRequest {
	transaction_tech_name: string;
	supply_filter: any; //FIXME @Rokva - use proper type
	provider_filter: any; //FIXME @Rokva - use proper type
	consumer_filter: any; //FIXME @Rokva - use proper type
	matching_tool_dirty_fields: any; //FIXME @Rokva - use proper type
	matching_tool_fields: any; //FIXME @Rokva - use proper type
}

export const initiateReverseAuctionTransaction =
	({
		transaction_tech_name,
		supply_filter,
		provider_filter,
		consumer_filter,
		matching_tool_dirty_fields,
		matching_tool_fields,
	}: InitiateReverseAuctionTransactionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log('Initiate reverse auction transaction', transaction_tech_name);

		dispatch(initiateReverseAuctionTransactionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
		const { errors: expiredSession } = await randevuService.auth.verifyValidSession();
		if (isSessionExpired(expiredSession))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: initiateReverseAuctionTransactionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.INITIATE_REVERSE_AUCTION_TRANSACTION.key,
					payload: {
						transaction_tech_name,
						supply_filter,
						provider_filter,
						consumer_filter,
						matching_tool_dirty_fields,
						matching_tool_fields,
					},
				},
			});

		const { field_inputs: dirty_fields_with_uploaded_files, errors: uploadErrors } =
			await randevuService.files.uploadFieldInputsFiles({
				field_inputs: matching_tool_dirty_fields,
				fields: matching_tool_fields,
			});

		const { transaction, errors } = await randevuService.transactions.prepareAndInitiateReverseAuctionTransaction({
			transaction_tech_name,
			supply_filter,
			provider_filter,
			consumer_filter,
			fields: dirty_fields_with_uploaded_files,
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(initiateReverseAuctionTransactionFailed(errors));
		}

		const transactionDetailsRoute = routeBuilder(ROUTES.REVERSE_AUCTION_TRANSACTION_DETAILS, [
			[':transactionTypeTechName', transaction_tech_name],
			[':transactionId', transaction?.id],
		]);

		dispatch(goToRoute(transactionDetailsRoute));

		return dispatch(reverseAuctionTransactionInitiated(transaction));
	};

export const loadMyTransactionTypes =
	({ asGuest = false }) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log('Load my transaction types..');

		dispatch(loadTransactionTypesRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		let myTransactionTypes;
		let errors;

		if (asGuest) {
			const { my_transaction_types: guestTransactionTypes, errors: guestErrors } =
				await randevuService.transactions.guestTransactionTypes();
			myTransactionTypes = guestTransactionTypes;
			errors = guestErrors;
		} else {
			const { my_transaction_types: participantTransactionTypes, errors: participantErrors } =
				await randevuService.transactions.myTransactionTypes();
			myTransactionTypes = participantTransactionTypes;
			errors = participantErrors;

			const multipleDirectTT = participantTransactionTypes?.find(
				(tt) => tt.type === TransactionKind.MultipleDirect && tt.my_role === MatchTypeRoleKind.Consumer
			);

			if (multipleDirectTT) {
				const { shopping_cart, errors } = await randevuService.transactions.myActiveShoppingCart();

				if (isSessionExpired(errors))
					return handleSessionExpired({
						dispatch,
						state: getState(),
						failedAction: loadTransactionTypesFailed,
						reauthenticationCallback: {
							callbackThunkKey: THUNKS.LOAD_MY_TRANSACTION_TYPES.key,
							payload: {
								asGuest,
							},
						},
					});

				if (errors) {
					dispatch(sendUnexpectedErrorToasty(errors));
					return dispatch(loadTransactionTypesFailed(errors));
				}

				if (!shopping_cart) {
					const { shopping_cart, errors } = await randevuService.transactions.createShoppingCart({
						fields: [],
						transaction_tech_name: multipleDirectTT.tech_name,
					});

					if (errors) {
						dispatch(sendUnexpectedErrorToasty(errors));
						return dispatch(loadTransactionTypesFailed(errors));
					}

					dispatch(activeShoppingCartLoaded(shopping_cart));
				} else {
					dispatch(activeShoppingCartLoaded(shopping_cart));
				}
			} else {
				dispatch(activeShoppingCartLoaded(null));
			}
		}
		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadTransactionTypesFailed(errors));
		}

		return dispatch(transactionTypesLoaded(myTransactionTypes));
	};

interface TriggerManualTransitionRequest {
	id_transaction: string;
	transition_tech_name: string;
}

export const triggerManualTransition =
	({ id_transaction, transition_tech_name }: TriggerManualTransitionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Trigger manual transition ${transition_tech_name}..`);

		dispatch(triggerManualTransitionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
			id_transaction,
			transition_tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: triggerManualTransitionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.TRIGGER_MANUAL_TRANSITION.key,
					payload: {
						id_transaction,
						transition_tech_name,
					},
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(triggerManualTransitionFailed(errors));
		}

		await sleep(100);

		await dispatch(loadMyTransaction({ id_transaction }));
		return dispatch(manualTransitionTriggered());
	};
export const triggerManualTransitionSingleDirectForProvider =
	({ id_transaction, transition_tech_name }: TriggerManualTransitionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Trigger manual transition ${transition_tech_name}..`);

		dispatch(triggerManualTransitionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
			id_transaction,
			transition_tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: triggerManualTransitionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.TRIGGER_MANUAL_TRANSITION.key,
					payload: {
						id_transaction,
						transition_tech_name,
					},
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(triggerManualTransitionFailed(errors));
		}

		await sleep(100);

		const { transaction, errors: fetchErrors } =
			await randevuService.transactions.mySingleDirectTransactionForProvider({
				id: id_transaction,
			});

		dispatch(mySingleDirectTransactionLoaded({ transaction }));
		return dispatch(manualTransitionTriggered());
	};
export const triggerManualTransitionSingleDirectForConsumer =
	({ id_transaction, transition_tech_name }: TriggerManualTransitionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Trigger manual transition ${transition_tech_name}..`);

		dispatch(triggerManualTransitionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { triggered, errors } = await randevuService.transactions.triggerManualTransactionTransition({
			id_transaction,
			transition_tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: triggerManualTransitionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.TRIGGER_MANUAL_TRANSITION.key,
					payload: {
						id_transaction,
						transition_tech_name,
					},
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(triggerManualTransitionFailed(errors));
		}

		await sleep(100);

		const { transaction, errors: fetchErrors } =
			await randevuService.transactions.mySingleDirectTransactionForConsumer({
				id: id_transaction,
			});

		dispatch(mySingleDirectTransactionLoaded({ transaction }));
		return dispatch(manualTransitionTriggered());
	};

interface ForceTransactionTerminationRequest {
	id_transaction: string;
}

export const forceTransactionTermination =
	({ id_transaction }: ForceTransactionTerminationRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(forceTransactionTerminationRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { terminated, errors } = await randevuService.transactions.forceTransactionTermination({
			id_transaction,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: triggerManualTransitionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.FORCE_TRANSACTION_TERMINATION.key,
					payload: {
						id_transaction,
					},
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(forceTransactionTerminationFailed(errors));
		}

		await dispatch(loadMyTransaction({ id_transaction }));
		return dispatch(transactionTerminated());
	};
export const forceSingleDirectTransactionTerminationConsumer =
	({ id_transaction }: ForceTransactionTerminationRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(forceTransactionTerminationRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { terminated, errors } = await randevuService.transactions.forceTransactionTermination({
			id_transaction,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: triggerManualTransitionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.FORCE_TRANSACTION_TERMINATION.key,
					payload: {
						id_transaction,
					},
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(forceTransactionTerminationFailed(errors));
		}

		const { transaction, errors: fetchErrors } =
			await randevuService.transactions.mySingleDirectTransactionForConsumer({
				id: id_transaction,
			});
		dispatch(mySingleDirectTransactionLoaded({ transaction }));
		return dispatch(transactionTerminated());
	};
export const forceSingleDirectTransactionTerminationProvider =
	({ id_transaction }: ForceTransactionTerminationRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(forceTransactionTerminationRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { terminated, errors } = await randevuService.transactions.forceTransactionTermination({
			id_transaction,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: triggerManualTransitionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.FORCE_TRANSACTION_TERMINATION.key,
					payload: {
						id_transaction,
					},
				},
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(forceTransactionTerminationFailed(errors));
		}

		const { transaction, errors: fetchErrors } =
			await randevuService.transactions.mySingleDirectTransactionForConsumer({
				id: id_transaction,
			});
		dispatch(mySingleDirectTransactionLoaded({ transaction }));
		return dispatch(transactionTerminated());
	};

interface OverrideMatchPriceRequest {
	id_transaction: string;
	price: any; //FIXME @Rokva - proper type
}

export const overrideMatchPrice =
	({ id_transaction, price }: OverrideMatchPriceRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(overrideMatchPriceRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { updated, errors } = await randevuService.transactions.overrideTransactedMatchPrice({
			id_transaction,
			price,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: overrideMatchPriceFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.OVERRIDE_MATCH_PRICE.key,
					payload: {
						id_transaction,
						price,
					},
				},
			});

		if (errors || !updated) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(overrideMatchPriceFailed(errors));
		}

		const { transaction } = await randevuService.transactions.myTransaction({
			id: id_transaction,
		});

		if (errors || !transaction) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadMyTransactionFailed(errors));
		}

		dispatch(matchPriceOverridden());
		dispatch(sendInfoToasty('Saved'));
		return dispatch(myTransactionLoaded({ transaction }));
	};

interface AcceptSingleDirectTransactedMatchConsumerRequest {
	id_transaction: string;
}

export const acceptSingleDirectTransactedMatchConsumer =
	({ id_transaction }: AcceptSingleDirectTransactedMatchConsumerRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Accept match..`);

		dispatch(acceptSingleDirectMatchRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { accepted, errors } = await randevuService.transactions.acceptTransactedMatch({ id_transaction });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: acceptSingleDirectMatchFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(acceptSingleDirectMatchFailed(errors));
		}

		const { transaction } = await randevuService.transactions.mySingleDirectTransactionForConsumer({
			id: id_transaction,
		});

		await dispatch(sendSuccessToasty('Match accepted'));
		return dispatch(singleDirectMatchAccepted({ transaction }));
	};

interface DeclineSingleDirectTransactedMatchConsumerRequest {
	id_transaction: string;
}

export const declineSingleDirectTransactedMatchConsumer =
	({ id_transaction }: DeclineSingleDirectTransactedMatchConsumerRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Decline match..`);

		dispatch(declineSingleDirectMatchRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { declined, errors } = await randevuService.transactions.declineTransactedMatch({ id_transaction });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: declineSingleDirectMatchFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(declineSingleDirectMatchFailed(errors));
		}

		const { transaction } = await randevuService.transactions.mySingleDirectTransactionForConsumer({
			id: id_transaction,
		});

		await dispatch(sendInfoToasty('Match declined'));

		return dispatch(singleDirectMatchDeclined({ transaction }));
	};
interface AcceptSingleDirectTransactedMatchProviderRequest {
	id_transaction: string;
}

export const acceptSingleDirectTransactedMatchProvider =
	({ id_transaction }: AcceptSingleDirectTransactedMatchProviderRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Accept match..`);

		dispatch(acceptSingleDirectMatchRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { accepted, errors } = await randevuService.transactions.acceptTransactedMatch({ id_transaction });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: acceptSingleDirectMatchFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(acceptSingleDirectMatchFailed(errors));
		}

		const { transaction } = await randevuService.transactions.mySingleDirectTransactionForProvider({
			id: id_transaction,
		});

		await dispatch(sendSuccessToasty('Match accepted'));
		return dispatch(singleDirectMatchAccepted({ transaction }));
	};

interface DeclineSingleDirectTransactedMatchProviderRequest {
	id_transaction: string;
}

export const declineSingleDirectTransactedMatchProvider =
	({ id_transaction }: DeclineSingleDirectTransactedMatchProviderRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Decline match..`);

		dispatch(declineSingleDirectMatchRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
		const { declined, errors } = await randevuService.transactions.declineTransactedMatch({ id_transaction });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: declineSingleDirectMatchFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(declineSingleDirectMatchFailed(errors));
		}

		const { transaction } = await randevuService.transactions.mySingleDirectTransactionForProvider({
			id: id_transaction,
		});

		await dispatch(sendInfoToasty('Match declined'));

		return dispatch(singleDirectMatchDeclined({ transaction }));
	};

/////////////////////
//   SELECTORS     //
/////////////////////

export const selectTransactionTypes = (state: RootState): TransactionType[] => state.transaction.transactionTypes;
export const selectLoading = (state: RootState): boolean => state.transaction.loading;
export const selectDefaultTransactionType = (state: RootState): TransactionType | null =>
	state.transaction.defaultTransactionType;
export const selectCurrentTransactionType = (state: RootState): TransactionType | null =>
	state.transaction.currentTransactionType;
export const selectMyTransactions = (state: RootState): TransactionLight[] => state.transaction.items;
export const selectMyParentTransaction = (state: RootState) => state.transaction.parentTransaction;
export const selectMySingleDirectTransactions = (
	state: RootState
): SingleDirectTransactionForConsumer[] | SingleDirectTransactionForProvider[] =>
	state.transaction.singleDirectTransactions;
export const selectMySingleDirectTransactionsPaginationMetadata = (state: RootState): PaginationMetadata | null =>
	state.transaction.singleDirectTransactionsPaginationMetadata;
export const selectMyTransaction = (state: RootState): Transaction | null => state.transaction.item;
export const selectMySingleDirectTransaction = (
	state: RootState
): SingleDirectTransactionForConsumer | SingleDirectTransactionForProvider | null =>
	state.transaction.singleDirectTransaction;
