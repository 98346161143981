import ErrorIcon from '@mui/icons-material/Error';
import { Divider } from '@mui/material';
import { Stack } from '@mui/system';
import ROUTES from '@rdv-fo/common/routes';
import { useHistory } from 'react-router';
import { RdvTab, RdvTabs } from '@rdv-fo/components/common/tabs/RdvTabs';
import { SupplyStatusTab } from './supplyHelpers';
import { RDV_NEUTRAL_00 } from '@rdv-fo/styles/theme';


interface SupplyStatusTabsProps {
    selectedTab: SupplyStatusTab;
}
const SupplyStatusTabs = ({ selectedTab }: SupplyStatusTabsProps) => {
    const history = useHistory();

    const navigateTo = (tab: SupplyStatusTab) => {
        const newLocation = {
            pathname: ROUTES.MY_SUPPLIES,
            search: `?status=${tab}`,
        };

        history.push(newLocation);
    };

    return (
        <Stack direction="column">
            <RdvTabs value={selectedTab} indicatorColor="primary" textColor="primary">
                <RdvTab value={SupplyStatusTab.enabled} label="Enabled" onClick={() => navigateTo(SupplyStatusTab.enabled)} />
                <RdvTab value={SupplyStatusTab.errors} label="Errors" onClick={() => navigateTo(SupplyStatusTab.errors)} />
                <RdvTab value={SupplyStatusTab.disabled} label="Disabled" onClick={() => navigateTo(SupplyStatusTab.disabled)} />
                <RdvTab value={SupplyStatusTab.onboarding} label="Onboarding" onClick={() => navigateTo(SupplyStatusTab.onboarding)} />
                <RdvTab value={SupplyStatusTab.all} label="All" onClick={() => navigateTo(SupplyStatusTab.all)} />
            </RdvTabs>
            <Divider sx={{ color: RDV_NEUTRAL_00 }} />
        </Stack>
    );
};

export default SupplyStatusTabs;
