import LabelFilterInput from './LabelFilterInput';

import { FilterInputBaseProps } from './types';

import { Radios } from 'mui-rff';


const parse = (value: 'yes' | 'no' | '') => {
    if (value === 'yes') return true;
    if (value === 'no') return false;

    return undefined;
};
const format = (value: boolean) => {
    if (value === true) return 'yes';
    if (value === false) return 'no';

    return '';
};

const FilterInputSmartTextSet = ({
    techName,
    label,
    noLabel,
    disabled = false,
    required = false,
}: FilterInputBaseProps) => {

    const options = [
        { label: 'Available', value: 'yes' },
        { label: 'Unavailable', value: 'no' },
        { label: 'Not relevant', value: '' },
    ];

    return (
        <>
            {noLabel === false ? <LabelFilterInput text={techName} htmlFor={techName} required /> : null}
            <Radios name={`${techName}.is_provided`} disabled={disabled} data={options} fieldProps={{ parse, format }} />
        </>
    );

};

export default FilterInputSmartTextSet;
