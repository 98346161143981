import { useTheme } from '@mui/material';
import { FieldType, Object, ObjectType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import ConditionalFieldInput from './ConditionalFieldInput';

import FieldInput from './FieldInput';
import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';

interface FieldInputObjectProps extends FieldInputBaseProps {
	objectTypeFields: FieldType[];
	sharedObjectTypes: ObjectType[];
	sharedObjects: Object[];
}

export const FieldInputObject = ({
	techName,
	label,
	labelProps,
	disabled = false,
	required = false,
	objectTypeFields = [],
	sharedObjectTypes,
	sharedObjects
}: FieldInputObjectProps) => {
	const theme = useTheme();

	return (
		<>
			<div>
				<LabelFieldInput
					text={label}
					htmlFor={techName}
					required={required}
					showOptional={labelProps?.showOptional}
				/>
				<div style={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(2) }}>
					{objectTypeFields?.map((field_type: FieldType) => {
						return (
							<div style={{ marginBottom: theme.spacing(1) }} key={`${techName}.${field_type.tech_name}`}>
								<ConditionalFieldInput
									key={field_type?.tech_name}
									parentStateTechName={techName}
									fields={objectTypeFields?.map((field) => {
										return { ...field, field_type: { ...field } };
									}) as any}
									conditions={field_type?.conditions ?? []}
								>

									<FieldInput
										sharedObjects={sharedObjects}
										fieldTechName={`${techName}.${field_type.tech_name}`}
										label={field_type?.ui_config?.label ?? field_type?.name}
										optionsUiConfig={field_type?.ui_config?.options ?? undefined}
										helperText={field_type?.ui_config?.helper_text ?? ''}
										inputType={field_type.input_type}
										inputOptions={field_type.input_options}
										disabled={disabled}
										sharedObjectTypes={sharedObjectTypes}
									/>
								</ConditionalFieldInput>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default FieldInputObject;
