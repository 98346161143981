import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { Form } from 'react-final-form';
import { Checkboxes, Select } from 'mui-rff';
import RandevuInputLabel from '../common/RandevuInputLabel';
import { FieldType, Object, ObjectType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldInput from '@rdv-fo/components/fields/input/FieldInput';
import Banner from '@rdv-fo/components/common/Banner';
import arrayMutators from 'final-form-arrays';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import ConditionalFieldInput from '../fields/input/ConditionalFieldInput';

interface SelectObjectTypeViewProps {
    sharedObjectTypeTechNamesOptions: { value: string, label: string }[];
}

const SelectObjectTypeView = ({ sharedObjectTypeTechNamesOptions }: SelectObjectTypeViewProps) => {
    const sharedObjectOptionsExist = sharedObjectTypeTechNamesOptions.length > 0

    return sharedObjectOptionsExist ? (
        <>
            <RandevuInputLabel htmlFor='tech_name' text="Object type" />
            <Select data={sharedObjectTypeTechNamesOptions} name='tech_name' />
            <Checkboxes data={[{ label: 'Is public', value: true }]} name="is_public" />
        </>
    ) : (
        <Banner title='Insufficient permissions to create shared objects' text='Unfortunately, you do not have the necessary permissions to create any shared objects. It appears that the platform operators have restricted your access for this action. If you require assistance or have any inquiries, kindly reach out to the platform operator for further clarification.' goldColor={false} severity='warning' />
    )
}
interface ObjectDetailsViewProps {
    fields: FieldType[];
    sharedObjectTypes: ObjectType[];
    sharedObjects: Object[];
}

const ObjectDetailsView = ({ fields, sharedObjects, sharedObjectTypes }: ObjectDetailsViewProps) => {
    const mockedFieldsWithActualFieldTypes = fields.map((field) => ({ ...field, field_type: { ...field } }))
    return (
        <>
            <Stack direction='column' spacing={2}>
                {fields?.map((field_type: FieldType, index) => {
                    return (
                        <ConditionalFieldInput
                            key={field_type.tech_name}
                            fields={mockedFieldsWithActualFieldTypes as any}
                            conditions={field_type?.conditions as any}
                        >
                            <Box>
                                <FieldInput
                                    key={index}
                                    label={
                                        field_type?.ui_config?.label ?? field_type?.name
                                    }
                                    sharedObjects={sharedObjects}
                                    sharedObjectTypes={sharedObjectTypes}
                                    optionsUiConfig={field_type?.ui_config?.options ?? undefined}
                                    helperText={field_type?.ui_config?.helper_text}
                                    fieldTechName={field_type.tech_name}
                                    inputType={field_type?.input_type}
                                    inputOptions={field_type.input_options}
                                    objectTypeFields={field_type.object_fields as any}
                                    required={field_type.is_required}
                                />
                            </Box>
                        </ConditionalFieldInput>
                    )
                })}
            </Stack>
        </>
    )
}

interface CreateSharedObjectModalProps {
    open: boolean;
    sharedObjectTypeTechNamesOptions: { value: string, label: string }[];
    currentPage: 'type' | 'details' | 'update';
    sharedObjectTypes: ObjectType[];
    sharedObjects: Object[];
    loading: boolean;
    onClose: () => void;
    onBack: () => void;
    onSubmit: (values: { tech_name: string }) => void;
    onClickNext: () => void;
}
const CreateSharedObjectModal = ({ open, sharedObjectTypes, sharedObjects, sharedObjectTypeTechNamesOptions, currentPage, loading, onClose, onSubmit, onClickNext, onBack }: CreateSharedObjectModalProps) => {

    return (
        <Dialog
            aria-labelledby="create-or-update-shared-object-dialog"
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            BackdropProps={{
                sx: {
                    backdropFilter: 'blur(5px)',
                    backgroundColor: 'rgba(0,0,30,0.5)',
                },
            }}
        >
            <DialogTitle id="create-or-update-shared-object-dialog-title">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={700}>
                        Add new shared object
                    </Typography>
                    <IconButton onClick={onClose}>
                        <ClearIcon fontSize="medium" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <Form
                onSubmit={onSubmit}
                initialValues={{ tech_name: null }}
                mutators={{
                    ...arrayMutators,
                }}
                render={({ handleSubmit, values, submitting, submitFailed, dirtySinceLastSubmit }) => {
                    const sharedObjectTypeFields = sharedObjectTypes.find((ot) => ot.tech_name === values.tech_name)?.fields

                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogContent>
                                {currentPage === 'type' && <SelectObjectTypeView sharedObjectTypeTechNamesOptions={sharedObjectTypeTechNamesOptions} />}
                                {currentPage === 'details' && <ObjectDetailsView sharedObjects={sharedObjects} sharedObjectTypes={sharedObjectTypes} fields={sharedObjectTypeFields ?? []} />}
                                {currentPage === 'update' && <Typography variant='body1'>Coming soon...</Typography>}
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                                <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' paddingX={2}>
                                    {currentPage === 'type' &&
                                        <>
                                            <Button variant='text' sx={{ color: 'black' }} onClick={onClose}>Cancel</Button>
                                            <Button variant='text' sx={{ color: 'black' }} disabled={values.tech_name === null} onClick={onClickNext}>Next</Button>
                                        </>
                                    }
                                    {currentPage === 'details' &&
                                        <>
                                            <Button disabled={loading || submitting} variant='text' sx={{ color: 'black' }} onClick={onBack}>Back</Button>
                                            <LoadingButton
                                                type="submit"
                                                loading={loading || submitting}
                                                disabled={loading || submitting || (submitFailed && !dirtySinceLastSubmit)}
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                            >
                                                Save
                                            </LoadingButton>
                                        </>
                                    }
                                </Stack>
                            </DialogActions>
                        </form>
                    )
                }}
            />

        </Dialog>
    )
}

export default CreateSharedObjectModal