import { Field } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RdvObjectFieldValue, RdvObjectSetFieldValue } from '@rdv-fo/services/randevuApi/types/field/';
import FieldDisplayValue from './FieldDisplayValue';
import Grid from '@mui/material/Grid';
import { FieldValueStylingProps, LabelFieldValueProps } from './types';
import { Typography } from '@mui/material';
import ConditionalFieldDisplay from './ConditionalFieldDisplay';

interface FieldValueObjectProps {
	value?: RdvObjectSetFieldValue;
	className: any;
	fieldTechName: string;
	valueProps?: FieldValueStylingProps;
	labelProps?: LabelFieldValueProps;
}
const FieldValueObjectSet = ({ fieldTechName, value, labelProps, valueProps, className }: FieldValueObjectProps) => {
	const objectSet = value;

	return (
		<>
			<Grid container direction="column" spacing={1}>
				{objectSet === null ? 'n.a.' : undefined}
				{objectSet?.map((object: RdvObjectFieldValue, index: number) => {
					const objectPosition = index;
					const objectFieldValues = {};
					object?.fields?.forEach((field) =>
						Object.assign(objectFieldValues, { [field?.field_type?.tech_name]: field?.value })
					);
					return (
						<Grid item key={object.id_object}>
							<Grid container direction="column" spacing={1}>
								<Grid item xs={12}>
									<Typography>{objectPosition + 1}.</Typography>
								</Grid>

								{object?.fields?.map((objectField: Field) => {
									return (
										<Grid item key={`${fieldTechName}.${objectField?.field_type.tech_name}`}>
											<ConditionalFieldDisplay
												key={objectField?.field_type?.tech_name}
												fields={object?.fields}
												values={objectFieldValues}
												conditions={objectField?.field_type?.conditions ?? []}
											>
												<FieldDisplayValue
													label={objectField?.field_type.name}
													labelProps={{ width: 6 }}
													fieldTechName={`${fieldTechName}.${objectField?.field_type.tech_name}`}
													inputType={objectField?.field_type.input_type}
													inputOptions={objectField?.field_type.input_options}
													value={objectField?.value}
												/>
											</ConditionalFieldDisplay>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default FieldValueObjectSet;
