import { useAppDispatch, useAppSelector } from "@rdv-fo/store/configureStore";
import { paymentPrepared, prepareTransactionPayment, selectLoading as preparingPayment, requestBankTransferCheckout } from '@rdv-fo/store/slices/paymentSlice';
import { LoadingButtonAdvance } from "../common/LoadingButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PaymentMethodKind, TransactionKind } from "@rdv-fo/services/randevuApi/types/generatedTypes";

import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { goToRoute } from "@rdv-fo/store/slices/uiSlice";


interface TransactionPaymentCheckoutButtonProps {
    transactionId: string;
    transactionTechName: string;
    paymentTypeTechName: string;
    acceptedPaymentMethods: PaymentMethodKind[];
    label: string;
    redirectBackTo: TransactionKind.SingleDirect | TransactionKind.MultipleDirect | TransactionKind.ReverseAuction
}

const TransactionPaymentCheckoutButton = ({ transactionId, transactionTechName, paymentTypeTechName, acceptedPaymentMethods, label, redirectBackTo }: TransactionPaymentCheckoutButtonProps) => {
    const loading = useAppSelector(preparingPayment);
    const dispatch = useAppDispatch();

    const handlePrepareTransactionPayment = async () => {

        const resolvedAction = await dispatch(
            prepareTransactionPayment({
                id_transaction: transactionId,
                tech_name: paymentTypeTechName
            })
        );


        if (paymentPrepared.type === resolvedAction.type) {

            const paymentId = resolvedAction.payload.id;


            if (acceptedPaymentMethods.includes(PaymentMethodKind.SepaTransfer)) {

                await dispatch(requestBankTransferCheckout({ id_payment: paymentId }))

                const route = routeBuilder(
                    ROUTES.BANK_TRANSFER_CHECKOUT
                        .replace(':transactionTypeTechName', transactionTechName)
                        .replace(':transactionId', transactionId)
                        .replace(':paymentId', paymentId)
                    + `?redirectBackTo=${redirectBackTo}&transactionTechName=${transactionTechName}`
                );
                dispatch(goToRoute(route));
            } else {

                const route = routeBuilder(
                    ROUTES.HOSTED_CHECKOUT
                        .replace(':transactionTypeTechName', transactionTechName)
                        .replace(':transactionId', transactionId)
                        .replace(':paymentId', paymentId)
                    + `?redirectBackTo=${redirectBackTo}&transactionTechName=${transactionTechName}`
                );
                dispatch(goToRoute(route));
            }


        }

    };

    return (
        <LoadingButtonAdvance
            loading={loading}
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={() => handlePrepareTransactionPayment()}
            sx={{
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '0.875rem',
            }}
            fullWidth={true}
            endIcon={<ArrowForwardIcon />}
        >
            {label}
        </LoadingButtonAdvance>
    );
};

export default TransactionPaymentCheckoutButton;