import {
	createSupply,
	loadMySupplies,
	loadMySupply,
	updateSupply,
	triggerManualSupplyOnboardingTransition,
	activateSupply,
	deactivateSupply,
	createSupplyVariant,
	updateSupplyVariant,
	loadMyCollections,
	loadMyCollection,
	createCollection,
	updateMyCollection,
	loadMyImportJobReports,
	requestSupplyDataImportJob,
	loadMySupplyTypes,
} from '@rdv-fo/store/slices/supplySlice';
import { calculateSupplyPrice } from '@rdv-fo/store/slices/discoverySlice';
import { loadCurrentUser } from '@rdv-fo/store/slices/authSlice';
import {
	createSharedObject,
	loadMySharedObjects,
	loadMySharedObjectTypes,
	updateSharedObject,
} from '@rdv-fo/store/slices/sharedObjectsSlice';
import {
	addSupplyToShoppingCart,
	loadMyActiveShoppingCart,
	loadMyShoppingCartOrder,
	loadMyShoppingCartOrders,
	loadMyMultiDirectTransactions,
	removeItemFromShoppingCart,
	updateShoppingCartItem,
} from '@rdv-fo/store/slices/shoppingCartSlice';
import { updateConnection } from '@rdv-fo/store/slices/connectionSlice';
import {
	createAndStartSingleDirectTransaction,
	forceTransactionTermination,
	initiateReverseAuctionTransaction,
	loadMySingleDirectTransactionForConsumer,
	loadMySingleDirectTransactionForProvider,
	loadMySingleDirectTransactions,
	loadMySingleDirectTransactionsForProvider,
	loadMyTransaction,
	loadMyTransactions,
	loadMyTransactionTypes,
	overrideMatchPrice,
	updateSingleDirectTransactionMatchForConsumer,
	updateSingleDirectTransactionMatchForProvider,
} from './slices/transactionSlice';

export interface ThunkPointer {
	key: string;
	getThunk: () => any;
}
const THUNKS: { [key: string]: ThunkPointer } = {
	LOAD_CURRENT_USER: {
		key: 'LOAD_CURRENT_USER',
		getThunk: () => loadCurrentUser,
	},
	UPDATE_SUPPLY_VARIANT: {
		key: 'UPDATE_SUPPLY_VARIANT',
		getThunk: () => updateSupplyVariant,
	},
	CREATE_SUPPLY: {
		key: 'CREATE_SUPPLY',
		getThunk: () => createSupply,
	},
	CALCULATE_SUPPLY_PRICE: {
		key: 'CALCULATE_SUPPLY_PRICE',
		getThunk: () => createSupplyVariant,
	},
	CREATE_SUPPLY_VARIANT: {
		key: 'CREATE_SUPPLY_VARIANT',
		getThunk: () => calculateSupplyPrice,
	},
	LOAD_MY_SUPPLY_TYPES: {
		key: 'LOAD_MY_SUPPLY_TYPES',
		getThunk: () => loadMySupplyTypes,
	},
	MY_IMPORT_REPORTS: {
		key: 'MY_IMPORT_REPORTS',
		getThunk: () => loadMyImportJobReports,
	},
	REQUEST_SUPPLY_DATA_IMPORT_JOB: {
		key: 'REQUEST_SUPPLY_DATA_IMPORT_JOB',
		getThunk: () => requestSupplyDataImportJob,
	},
	LOAD_MY_SUPPLIES: {
		key: 'LOAD_MY_SUPPLIES',
		getThunk: () => loadMySupplies,
	},
	LOAD_MY_SUPPLY: {
		key: 'LOAD_MY_SUPPLY',
		getThunk: () => loadMySupply,
	},
	UPDATE_SUPPLY: {
		key: 'UPDATE_SUPPLY',
		getThunk: () => updateSupply,
	},
	UPDATE_MY_COLLECTION: {
		key: 'UPDATE_MY_COLLECTION',
		getThunk: () => updateMyCollection,
	},
	LOAD_MY_COLLECTIONS: {
		key: 'LOAD_MY_COLLECTIONS',
		getThunk: () => loadMyCollections,
	},
	LOAD_MY_COLLECTION: {
		key: 'LOAD_MY_COLLECTION',
		getThunk: () => loadMyCollection,
	},
	CREATE_COLLECTION: {
		key: 'CREATE_COLLECTION',
		getThunk: () => createCollection,
	},
	TRIGGER_MANUAL_SUPPLY_ONBOARDING_TRANSITION: {
		key: 'TRIGGER_MANUAL_SUPPLY_ONBOARDING_TRANSITION',
		getThunk: () => triggerManualSupplyOnboardingTransition,
	},
	ACTIVATE_SUPPLY: {
		key: 'ACTIVATE_SUPPLY',
		getThunk: () => activateSupply,
	},
	DEACTIVATE_SUPPLY: {
		key: 'DEACTIVATE_SUPPLY',
		getThunk: () => deactivateSupply,
	},
	LOAD_MY_SHARED_OBJECTS: {
		key: 'LOAD_MY_SHARED_OBJECTS',
		getThunk: () => loadMySharedObjects,
	},
	LOAD_MY_SHARED_OBJECT_TYPES: {
		key: 'LOAD_MY_SHARED_OBJECT_TYPES',
		getThunk: () => loadMySharedObjectTypes,
	},
	CREATE_SHARED_OBJECT: {
		key: 'CREATE_SHARED_OBJECT',
		getThunk: () => createSharedObject,
	},
	UPDATE_SHARED_OBJECT: {
		key: 'UPDATE_SHARED_OBJECT',
		getThunk: () => updateSharedObject,
	},
	//SHOPPING CART
	ADD_SUPPLY_TO_SHOPPING_CART: {
		key: 'ADD_SUPPLY_TO_SHOPPING_CART',
		getThunk: () => addSupplyToShoppingCart,
	},
	REMOVE_ITEM_FROM_SHOPPING_CART: {
		key: 'REMOVE_ITEM_FROM_SHOPPING_CART',
		getThunk: () => removeItemFromShoppingCart,
	},
	UPDATE_SHOPPING_CART_ITEM: {
		key: 'UPDATE_SHOPPING_CART_ITEM',
		getThunk: () => updateShoppingCartItem,
	},
	LOAD_MY_ACTIVE_SHOPPING_CART: {
		key: 'LOAD_MY_ACTIVE_SHOPPING_CART',
		getThunk: () => loadMyActiveShoppingCart,
	},
	LOAD_MY_SHOPPING_CARTS: {
		key: 'LOAD_MY_SHOPPING_CARTS',
		getThunk: () => loadMyMultiDirectTransactions,
	},
	LOAD_MY_SHOPPING_CART_ORDERS: {
		key: 'LOAD_MY_SHOPPING_CART_ORDERS',
		getThunk: () => loadMyShoppingCartOrders,
	},
	LOAD_MY_SHOPPING_CART_ORDER: {
		key: 'LOAD_MY_SHOPPING_CART_ORDER',
		getThunk: () => loadMyShoppingCartOrder,
	},
	UPDATE_CONNECTION: {
		key: 'UPDATE_CONNECTION',
		getThunk: () => updateConnection,
	},
	LOAD_MY_TRANSACTIONS: {
		key: 'LOAD_MY_TRANSACTIONS',
		getThunk: () => loadMyTransactions,
	},
	LOAD_MY_TRANSACTION: {
		key: 'LOAD_MY_TRANSACTION',
		getThunk: () => loadMyTransaction,
	},
	LOAD_MY_SINGLE_DIRECT_TRANSACTION_FOR_CONSUMER: {
		key: 'LOAD_MY_SINGLE_DIRECT_TRANSACTION_FOR_CONSUMER',
		getThunk: () => loadMySingleDirectTransactionForConsumer,
	},
	UPDATE_SINGLE_DIRECT_TRANSACTION_MATCH_FOR_CONSUMER: {
		key: 'UPDATE_SINGLE_DIRECT_TRANSACTION_MATCH_FOR_CONSUMER',
		getThunk: () => updateSingleDirectTransactionMatchForConsumer,
	},
	UPDATE_SINGLE_DIRECT_TRANSACTION_MATCH_FOR_PROVIDER: {
		key: 'UPDATE_SINGLE_DIRECT_TRANSACTION_MATCH_FOR_PROVIDER',
		getThunk: () => updateSingleDirectTransactionMatchForProvider,
	},
	LOAD_MY_SINGLE_DIRECT_TRANSACTION_FOR_PROVIDER: {
		key: 'LOAD_MY_SINGLE_DIRECT_TRANSACTION_FOR_PROVIDER',
		getThunk: () => loadMySingleDirectTransactionForProvider,
	},
	LOAD_MY_SINGLE_DIRECT_TRANSACTIONS: {
		key: 'LOAD_MY_SINGLE_DIRECT_TRANSACTIONS',
		getThunk: () => loadMySingleDirectTransactions,
	},
	LOAD_MY_SINGLE_DIRECT_TRANSACTIONS_FOR_PROVIDER: {
		key: 'LOAD_MY_SINGLE_DIRECT_TRANSACTIONS_FOR_PROVIDER',
		getThunk: () => loadMySingleDirectTransactionsForProvider,
	},
	CREATE_AND_START_SINGLE_DIRECT_TRANSACTION: {
		key: 'CREATE_AND_START_SINGLE_DIRECT_TRANSACTION',
		getThunk: () => createAndStartSingleDirectTransaction,
	},
	INITIATE_REVERSE_AUCTION_TRANSACTION: {
		key: 'INITIATE_REVERSE_AUCTION_TRANSACTION',
		getThunk: () => initiateReverseAuctionTransaction,
	},
	LOAD_MY_TRANSACTION_TYPES: {
		key: 'LOAD_MY_TRANSACTION_TYPES',
		getThunk: () => loadMyTransactionTypes,
	},
	FORCE_TRANSACTION_TERMINATION: {
		key: 'FORCE_TRANSACTION_TERMINATION',
		getThunk: () => forceTransactionTermination,
	},
	OVERRIDE_MATCH_PRICE: {
		key: 'OVERRIDE_MATCH_PRICE',
		getThunk: () => overrideMatchPrice,
	},
};

export default THUNKS;
