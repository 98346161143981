import { Divider, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Form } from 'react-final-form';
import ClearIcon from '@mui/icons-material/Clear';
import { Field, FieldAccessKind, ObjectType, SupplyVariant } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import arrayMutators from 'final-form-arrays';
import FieldDisplayValue from '@rdv-fo/components/fields/display/FieldDisplayValue';
import ConditionalFieldDisplay from '@rdv-fo/components/fields/display/ConditionalFieldDisplay';
import { useTheme } from '@emotion/react';

const mapFieldValuesToFormInitValues = (fields: Field[]): any => {
    const initValues = {};
    fields?.forEach((field) => Object.assign(initValues, { [field.field_type.tech_name]: field.value }));
    return initValues;
};

const createDiscoveredParticipantDetailsInitialFormValues = (participantFields: Field[]) => {
    const initValues = mapFieldValuesToFormInitValues(participantFields)

    return initValues;
}

interface DiscoveredConnectionParticipantDetailsDialogProps {
    open: boolean;
    participantId: string | null;
    participantFields: Field[];
    onClose: () => void;
    onConnect: (id: string) => void;
}

const DiscoveredConnectionParticipantDetailsDialog = ({
    open,
    participantId,
    onConnect,
    participantFields,
    onClose,
}: DiscoveredConnectionParticipantDetailsDialogProps) => {
    const theme = useTheme() as any;
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const initValues = createDiscoveredParticipantDetailsInitialFormValues(participantFields);

    return (
        <Dialog
            aria-labelledby="view-discovered-connection-participant-details-dialog"
            open={open}
            fullScreen={isMobileScreen}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            BackdropProps={{
                sx: {
                    backdropFilter: 'blur(5px)',
                    backgroundColor: 'rgba(0,0,30,0.5)',
                },
            }}
        >
            <DialogTitle id="view-discovered-connection-participant-details-dialog-title">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={700}>
                        Participant details
                    </Typography>
                    <IconButton onClick={onClose}>
                        <ClearIcon fontSize="medium" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <Form
                onSubmit={() => {
                    if (participantId)
                        onConnect(participantId)
                }}
                initialValues={initValues}
                mutators={{
                    ...arrayMutators,
                }}
                render={({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <DialogContent>
                            <Stack
                                direction="column"
                                alignItems="stretch"
                                justifyContent="center"
                                spacing={2}
                                textAlign="left"
                            >
                                {participantFields?.map((field: Field) => {
                                    return (
                                        <ConditionalFieldDisplay
                                            key={field?.field_type?.tech_name}
                                            fields={participantFields}
                                            values={initValues}
                                            conditions={field?.field_type?.conditions ?? []}
                                        >

                                            <FieldDisplayValue
                                                label={
                                                    field?.field_type?.ui_config?.label ??
                                                    field?.field_type.name
                                                }
                                                optionsUiConfig={field?.field_type?.ui_config?.options as any}
                                                fieldTechName={field?.field_type.tech_name}
                                                inputType={field?.field_type.input_type}
                                                inputOptions={field?.field_type.input_options}
                                                value={field.value}
                                            />
                                        </ConditionalFieldDisplay>
                                    );
                                })}

                                <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
                                    <Button
                                        variant="text"
                                        onClick={() => onClose()}
                                        color="inherit"
                                        disabled={submitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        Connect
                                    </Button>
                                </Stack>
                            </Stack>
                        </DialogContent>
                    </form>
                )}
            />
        </Dialog>
    );
};

export default DiscoveredConnectionParticipantDetailsDialog;
