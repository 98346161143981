import { DateTimePicker } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@mui/material/Grid';
import { FilterInputBaseProps } from './types';
import { LocalizationProvider } from '@mui/x-date-pickers';

import LabelFilterInput from './LabelFilterInput';


// FIXME: set proper types
const parse = (value: any) => ([null, undefined, ''].includes(value) ? null : value?.toISOString());
// FIXME: set proper types
const format = (value: any) => ([null, undefined, ''].includes(value) ? null : new Date(value));

interface FilterInputDatetimeProps extends FilterInputBaseProps {}

const FilterInputDatetime = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
}: FilterInputDatetimeProps) => {

	return (
		<Grid spacing={1}>
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}
			<LocalizationProvider dateAdapter={DateFnsUtils}>
				<DateTimePicker
					name={`${techName}.min_date`}
					inputFormat="dd/MM/yyyy hh:mm a"
					InputProps={{ onKeyDown: e => e.preventDefault() }}
					fieldProps={{
						disabled,
						inputVariant: 'outlined',
						clearable: 'true',
						placeholder: 'From',
						parse,
						format,
					}}
				/>
			</LocalizationProvider>
			<LocalizationProvider dateAdapter={DateFnsUtils}>
				<DateTimePicker
					name={`${techName}.max_date`}
					InputProps={{ onKeyDown: e => e.preventDefault() }}
					inputFormat="dd/MM/yyyy hh:mm a"
					fieldProps={{
						disabled,
						inputVariant: 'outlined',
						clearable: 'true',
						placeholder: 'To',
						parse,
						format,
					}}
				/>
			</LocalizationProvider>

		</Grid>
	);
};

export default FilterInputDatetime;
