import { DatePicker } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';

import LabelFieldInput from './LabelFieldInput';
import { parse, format } from '@rdv-fo/app/lib/datetime';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { FieldInputBaseProps } from './types';
import { Stack } from '@mui/material';

interface DateInputOptions {
	required: boolean;
}
const validate = (value: any, options: DateInputOptions) => {
	const { required } = options;

	if (required && (value === null || value === undefined)) return 'Please fill in this field';

	return undefined;
};

interface FieldInputDateProps extends FieldInputBaseProps {
	pastOnly?: boolean;
	futureOnly?: boolean;
	unit?: string;
}

export const FieldInputDate = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	pastOnly = false,
	futureOnly = false,
}: FieldInputDateProps) => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>

			<div>
				<LocalizationProvider dateAdapter={DateFnsUtils}>
					<DatePicker
						name={techName}
						required={required}
						inputFormat="dd/MM/yyyy"
						InputProps={{ onKeyDown: (e) => e.preventDefault() }}
						fieldProps={{
							parse,
							format,
							validate: (value) => validate(value, { required }),
							disabled,
							disableFuture: pastOnly,
							disablePast: futureOnly,
							inputVariant: 'outlined',
							clearable: required,
							helperText: helperText,
						}}
					/>
				</LocalizationProvider>
			</div>
		</Stack>
	);
};

export default FieldInputDate;
