import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import GeneralLayout from '@rdv-fo/components/layout/types/GeneralLayout';

const AboutUsPage = () => {
	return (
		<>
			<GeneralLayout>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					sx={{ mt: 20, mb: 20 }}
				>
					<Typography component="h1" variant="h3" align="center">
						Our Story
					</Typography>

					<Typography component="h1" variant="h4" align="center">
						This page is under construction
					</Typography>
				</Stack>
			</GeneralLayout>
		</>
	);
};

export default AboutUsPage;
