import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Stack } from '@mui/material';

interface UserSignedUpViewProps {
	viewUiConfig: any;
}

const UserSignedUpView = ({ viewUiConfig }: UserSignedUpViewProps) => {
	return (
		<Container maxWidth="sm">
			<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
				<Typography component="h3" variant="subtitle2">
					{viewUiConfig.eyebrow}
				</Typography>
				<Typography component="h1" variant="h1">
					{viewUiConfig.title}
				</Typography>
				<Typography variant="body1" component="h2">
					{viewUiConfig.description}
				</Typography>
			</Stack>
		</Container>
	);
};

export default UserSignedUpView;
