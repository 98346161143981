import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import { Box, Divider, Grid, Paper, Skeleton, Stack } from '@mui/material';
import ShoppingCartEmptyState from '@rdv-fo/components/transaction/multi-direct/ShoppingCartEmptyState';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import {
	loadMyShoppingCart,
	selectLoading,
	selectMyShoppingCart,
	triggerShoppingCartManualTransition,
} from '@rdv-fo/store/slices/shoppingCartSlice';
import ShoppingCartItems from '@rdv-fo/components/transaction/multi-direct/ShoppingCartItems';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import FlowManualActionsPanel from '@rdv-fo/components/transaction/FlowManualActionsPanel';
import { selectCalculatedSupplyPrice } from '@rdv-fo/store/slices/discoverySlice';
import { useParams } from 'react-router';
import { CurrencyKind, MatchStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import ShoppingCartPaymentSuccessView from '@rdv-fo/components/transaction/multi-direct/ShoppingCartPaymentSuccessView';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_50 } from '@rdv-fo/styles/theme';

const MultiDirectTransactionDetailsPage = () => {
	const dispatch = useAppDispatch();

	const { transactionId } = useParams<{ transactionId: string }>();
	const calculatedSupplyPrice = useAppSelector(selectCalculatedSupplyPrice);
	const shoppingCart = useAppSelector(selectMyShoppingCart);
	const [currentItemId, setCurrentItemId] = useState<string | null>(null);
	const currentItem = currentItemId ? shoppingCart?.items?.find((item) => item.id === currentItemId) : null;

	const isMatchConfigurationPricingEnabled = shoppingCart?.type?.match_configuration?.pricing_enabled ?? false;

	useEffect(() => {
		dispatch(loadMyShoppingCart({ idCart: transactionId }));
	}, []);

	const loading = useAppSelector(selectLoading);

	const supplyTypeName = (shoppingCart?.type?.root_type as any)?.match_type?.supply_type?.name;
	const transactionTypeTechName = shoppingCart?.type?.tech_name ?? '';

	const shoppingCartItemsExist = shoppingCart?.items?.length && shoppingCart?.items?.length > 0;
	const matchParameterTypeTechNamesWithoutQty: any =
		shoppingCart?.type?.match_configuration?.match_parameter_types?.reduce((acc, mpt): any => {
			if (mpt?.tech_name === 'qty') return acc;

			return [...acc, mpt?.tech_name];
		}, []);

	let totalArticles = 0;
	shoppingCart?.items?.forEach((item) => (totalArticles = totalArticles + (item?.price?.total_before_tax ?? 0)));

	let totalDiscounts = 0;
	shoppingCart?.items?.forEach((item) => {
		const discountItems = item?.price?.discount_items;
		discountItems?.forEach((discountItem) => {
			totalDiscounts = totalDiscounts + (discountItem?.value ?? 0);
		});
	});

	const shoppingCartItemsGroupedByProvider = {} as any;
	shoppingCart?.items?.forEach((item) => {
		const currItems = shoppingCartItemsGroupedByProvider?.[item.id_provider] ?? [];
		Object.assign(shoppingCartItemsGroupedByProvider, { [item.id_provider]: [...currItems, item] });
	});

	let totalFees = 0;
	shoppingCart?.items?.forEach((item) => (totalFees = totalFees + (item?.price?.total_fees ?? 0)));

	const nextSteps = shoppingCart?.state?.next_steps ?? [];
	const message = shoppingCart?.state?.message ?? '';
	const status = shoppingCart?.state?.status ?? '';

	const handleTriggerManualTransition = (objectId: string, transitionTechName: string) => {
		dispatch(
			triggerShoppingCartManualTransition({
				idTransaction: objectId,
				transitionTechName: transitionTechName,
			})
		);
	};

	const cumulativeSupplyPrice = {} as any;
	shoppingCart?.items?.forEach((item) => {
		if (Object.keys(cumulativeSupplyPrice)?.length < 1) {
			Object.assign(cumulativeSupplyPrice, item?.price);
			if (item?.price?.unit_price && item?.price?.qty)
				Object.assign(cumulativeSupplyPrice, { total_price: item.price.unit_price * item.price.qty });
		} else {
			if (item?.price?.unit_price && item?.price?.qty)
				cumulativeSupplyPrice.total_price = cumulativeSupplyPrice.total_price +=
					item.price.unit_price * item.price.qty;
			cumulativeSupplyPrice.total_before_tax = cumulativeSupplyPrice.total_before_tax +=
				item?.price?.total_before_tax;
			cumulativeSupplyPrice.total_taxes = cumulativeSupplyPrice.total_taxes += item?.price?.total_taxes;
			cumulativeSupplyPrice.total_fees = cumulativeSupplyPrice.total_fees += item?.price?.total_fees;
			cumulativeSupplyPrice.total_consumer_fees = cumulativeSupplyPrice.total_consumer_fees +=
				item?.price?.total_consumer_fees;
			cumulativeSupplyPrice.discount_items = [
				...cumulativeSupplyPrice.discount_items,
				...(item?.price?.discount_items ? item?.price?.discount_items : []),
			];
			cumulativeSupplyPrice.addon_items = [
				...cumulativeSupplyPrice.addon_items,
				...(item?.price?.addon_items ? item?.price?.addon_items : []),
			];
			cumulativeSupplyPrice.tax_items = [
				...cumulativeSupplyPrice.tax_items,
				...(item?.price?.tax_items ? item?.price?.tax_items : []),
			];
			cumulativeSupplyPrice.platform_fee_items = [
				...cumulativeSupplyPrice.platform_fee_items,
				...(item?.price?.platform_fee_items ? item?.price?.platform_fee_items : []),
			];
		}
	});

	const isPaymentSuccessful = Boolean(shoppingCart?.items?.find((item) => item.status === MatchStatusKind.Paid));

	const currency = shoppingCart?.chargeable?.currency ?? CurrencyKind.Eur;

	return (
		<>
			<Stack direction="row" width="100%" justifyContent="space-between">
				<Typography component="h1" variant="h1">
					Shopping cart details
				</Typography>
				<Box>
					{(nextSteps?.length > 0 || message) && (
						<FlowManualActionsPanel
							objectId={shoppingCart?.id ?? ''}
							nextSteps={nextSteps}
							message={''}
							onTriggerManualTransition={handleTriggerManualTransition}
							loading={loading}
							buttonVariant="contained"
						/>
					)}
				</Box>
			</Stack>
			{shoppingCartItemsExist ? (
				<Grid container direction="row" sx={{ mt: 5 }} justifyContent="space-between">
					<Grid item xs={12} md={7}>
						<ShoppingCartItems
							showPrice={isMatchConfigurationPricingEnabled}
							renderActions={false}
							items={Object.values(shoppingCartItemsGroupedByProvider)}
							matchParameterTypeTechNames={matchParameterTypeTechNamesWithoutQty}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<Stack direction="column" spacing={3}>
							<Typography variant="h4">Summary</Typography>
							{isPaymentSuccessful ? (
								<Stack direction="column" spacing={2}>
									<Stack direction="column" spacing={-1}>
										<Typography variant="overline" component="p" sx={{ color: RDV_NEUTRAL_50 }}>
											{status}
										</Typography>
										<Typography variant="body1" fontWeight={'bold'}>
											{message}
										</Typography>
									</Stack>
									<ShoppingCartPaymentSuccessView />
								</Stack>
							) : (
								<>
									<Typography variant="overline" component="p" sx={{ color: RDV_NEUTRAL_50 }}>
										{status}
									</Typography>
									{message && (
										<Stack
											component={Paper}
											elevation={0}
											sx={{ backgroundColor: RDV_NEUTRAL_10 }}
											padding={4}
											justifyContent="center"
											alignItems="center"
											spacing={2}
										>
											<Typography variant="h4">{message}</Typography>
										</Stack>
									)}
								</>
							)}
							<Stack direction="column" spacing={1}>
								<Stack
									direction="row"
									sx={{ width: '100%' }}
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={2}
								>
									<Typography fontWeight="bold">Total price</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount: cumulativeSupplyPrice?.total_price,
											currency,
										})}
									</Typography>
								</Stack>

								{calculatedSupplyPrice?.addon_items &&
									calculatedSupplyPrice?.addon_items?.length > 0 &&
									calculatedSupplyPrice?.addon_items.map((add_on: any) => (
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">{add_on?.description}</Typography>
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount: add_on?.value,
													currency,
												})}
											</Typography>
										</Stack>
									))}
								{calculatedSupplyPrice?.discount_items &&
									calculatedSupplyPrice?.discount_items?.length > 0 &&
									calculatedSupplyPrice?.discount_items.map((discount: any) => (
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">{discount?.description}</Typography>
											<Typography fontWeight="bold">
												-
												{formatPriceAmount({
													amount: discount?.value,
													currency,
												})}
											</Typography>
										</Stack>
									))}
								{cumulativeSupplyPrice?.platform_fee_items?.length > 0 &&
									cumulativeSupplyPrice?.platform_fee_items.map((platform_fee: any) => (
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">{platform_fee?.description}</Typography>
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount: platform_fee?.value,
													currency,
												})}
											</Typography>
										</Stack>
									))}
								<Divider flexItem />

								<Stack
									direction="row"
									sx={{ width: '100%' }}
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={2}
								>
									<Typography fontWeight="bold">Total before taxes</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount: cumulativeSupplyPrice?.total_before_tax,
											currency,
										})}
									</Typography>
								</Stack>

								{cumulativeSupplyPrice?.tax_items?.length > 0 &&
									cumulativeSupplyPrice?.tax_items.map((tax: any) => (
										<Stack
											direction="row"
											sx={{ width: '100%' }}
											justifyContent="space-between"
											alignItems="flex-start"
											spacing={2}
										>
											<Typography fontWeight="bold">{tax?.description}</Typography>
											<Typography fontWeight="bold">
												{formatPriceAmount({
													amount: tax?.value,
													currency,
												})}
											</Typography>
										</Stack>
									))}
								<Divider flexItem />
								<Stack
									direction="row"
									sx={{ width: '100%' }}
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={2}
								>
									<Typography fontWeight="bold">Total</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount:
												cumulativeSupplyPrice?.total_before_tax +
												cumulativeSupplyPrice?.total_taxes,
											currency,
										})}
									</Typography>
								</Stack>

								{cumulativeSupplyPrice?.total_consumer_fees ? (
									<Stack
										direction="row"
										sx={{ width: '100%' }}
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={2}
									>
										<Typography fontWeight="bold">Total consumer fees</Typography>
										<Typography fontWeight="bold">
											{formatPriceAmount({
												amount: cumulativeSupplyPrice?.total_consumer_fees,
												currency,
											})}
										</Typography>
									</Stack>
								) : (
									<></>
								)}
								<Divider flexItem />
								<Stack
									direction="row"
									sx={{ width: '100%' }}
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={2}
								>
									<Typography fontWeight="bold">Adjusted total</Typography>
									<Typography fontWeight="bold">
										{formatPriceAmount({
											amount:
												cumulativeSupplyPrice?.total_before_tax +
												cumulativeSupplyPrice?.total_taxes +
												cumulativeSupplyPrice?.total_fees,
											currency,
										})}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			) : (
				<Box sx={{ mt: 5 }}>
					{loading ? (
						<Skeleton height={300} />
					) : (
						<ShoppingCartEmptyState
							supplyTypeName={supplyTypeName}
							transactionTypeTechName={transactionTypeTechName}
						/>
					)}
				</Box>
			)}
		</>
	);
};

export default MultiDirectTransactionDetailsPage;
