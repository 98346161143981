import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';

import FieldDisplayValue from '@rdv-fo/components/fields/display/FieldDisplayValue';
import { CurrencyKind, Field, FieldCategoryKind, SupplyPrice } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { Button, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MatchPriceOverrideFormDialog from '../MatchPriceOverrideFormDialog';
import { useState } from 'react';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import ConditionalFieldDisplay from '@rdv-fo/components/fields/display/ConditionalFieldDisplay';

interface RowProps {
    label: string;
    value: any;
}
const Row = ({ label, value }: RowProps) => {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="subtitle2">{label}</Typography>
            <Typography>{value}</Typography>
        </Stack>
    );
};

interface SingleDirectTransactionMatchOverviewProps {
    transactionId: string;
    requestFields: Field[];
    supplyFields: Field[];
    participantFields: Field[];
    perspective: 'CONSUMER' | 'PROVIDER';
    matchPriceOverrideEnabled: boolean; // TODO: can this be removed?
    isMatchConfigurationPricingEnabled: boolean; // TODO: can this be removed?
    matchPrice: SupplyPrice;
}
const SingleDirectTransactionMatchOverview = ({
    transactionId,
    requestFields,
    supplyFields,
    participantFields,
    matchPriceOverrideEnabled,
    perspective,
    matchPrice,
    isMatchConfigurationPricingEnabled,
}: SingleDirectTransactionMatchOverviewProps) => {
    const requestFieldsWithoutQuantity = requestFields?.filter((rf) => rf?.field_type?.tech_name !== 'qty');
    const requestFieldValuesWithoutQuantity = {};
    requestFieldsWithoutQuantity?.forEach((field) =>
        Object.assign(requestFieldValuesWithoutQuantity, { [field?.field_type?.tech_name]: field?.value })
    );
    const [openMatchPriceOverride, setOpenMatchPriceOverride] = useState(false);

    const participantFieldValues = {};
    participantFields?.forEach((field) =>
        Object.assign(participantFieldValues, { [field?.field_type?.tech_name]: field?.value })
    );

    const currency = matchPrice?.currency ?? CurrencyKind.Eur;
    const fieldPrice = findFieldByCategory(supplyFields, FieldCategoryKind.BasePrice);
    const formattedMatchUnitPrice = formatPriceAmount({
        amount: matchPrice?.unit_price ?? 0,
        currency: currency,
    });

    const quantity = matchPrice?.qty;

    const formattedSubtotalMatchAmount = formatPriceAmount({
        amount: matchPrice?.total_before_tax,
        currency: currency,
    });

    const formattedTotalTaxesMatchAmount = formatPriceAmount({
        amount: matchPrice?.total_taxes,
        currency: currency,
    });

    const formattedTotalMatchAmount = formatPriceAmount({
        amount: matchPrice?.total_before_tax + matchPrice?.total_taxes,
        currency: currency,
    });
    const formattedAdjustedTotalMatchAmount = formatPriceAmount({
        amount: matchPrice?.total_before_tax + matchPrice?.total_taxes + matchPrice?.total_fees,
        currency: currency,
    });

    return (
        <Paper id="transaction-match-overview" component="section" elevation={0}>
            {isMatchConfigurationPricingEnabled && (
                <>
                    <MatchPriceOverrideFormDialog
                        transactionId={transactionId}
                        open={openMatchPriceOverride}
                        matchPrice={matchPrice}
                        onClose={() => setOpenMatchPriceOverride(false)}
                    />
                    <Typography variant="h5" component="h3" sx={{ fontWeight: 700, marginBottom: 2 }}>
                        Overview
                    </Typography>

                    <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
                        {quantity && <Row label="Quantity" value={quantity} />}
                        {fieldPrice && <Row label="Unit price" value={formattedMatchUnitPrice} />}
                        {matchPrice?.addon_items?.map((add_on) => (
                            <Row
                                label={add_on?.description}
                                value={formatPriceAmount({
                                    amount: add_on.value,
                                    currency: currency,
                                })}
                            />
                        ))}{' '}
                        {matchPrice?.discount_items?.map((discount) => (
                            <Row
                                label={discount?.description}
                                value={`-${formatPriceAmount({
                                    amount: discount.value,
                                    currency: currency,
                                })}`}
                            />
                        ))}
                        <Divider flexItem />
                        {fieldPrice && <Row label="Subtotal" value={formattedSubtotalMatchAmount} />}
                        {fieldPrice && <Row label="Taxes" value={formattedTotalTaxesMatchAmount} />}
                        <Divider flexItem />
                        {fieldPrice && <Row label="Total" value={formattedTotalMatchAmount} />}
                        <Divider flexItem />
                        {matchPrice?.platform_fee_items?.length > 0 && (
                            <>
                                {matchPrice?.platform_fee_items?.map((fee) => (
                                    <Row
                                        label={fee?.description}
                                        value={formatPriceAmount({
                                            amount: fee.value,
                                            currency: currency,
                                        })}
                                    />
                                ))}
                                <Divider flexItem />
                                {<Row label="Adjusted total" value={formattedAdjustedTotalMatchAmount} />}
                            </>
                        )}
                        {matchPriceOverrideEnabled && (
                            <Stack>
                                <Button
                                    color="error"
                                    size="small"
                                    variant="outlined"
                                    startIcon={<EditIcon />}
                                    onClick={() => setOpenMatchPriceOverride(true)}
                                >
                                    Change total price
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </>
            )}

            {requestFieldsWithoutQuantity?.length > 0 && (
                <>
                    {isMatchConfigurationPricingEnabled && <Divider sx={{ mt: 3, mb: 3 }} />}

                    {requestFieldsWithoutQuantity?.map((field) => {
                        return (
                            <Grid key={field?.field_type.tech_name}>
                                <ConditionalFieldDisplay
                                    key={field?.field_type?.tech_name}
                                    fields={requestFieldsWithoutQuantity}
                                    values={requestFieldValuesWithoutQuantity}
                                    conditions={field?.field_type?.conditions ?? []}
                                >
                                    <FieldDisplayValue
                                        label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
                                        fieldTechName={field?.field_type.tech_name}
                                        inputType={field?.field_type.input_type}
                                        inputOptions={field?.field_type.input_options}
                                        optionsUiConfig={field?.field_type?.ui_config?.options ?? []}
                                        value={field.value}
                                    />
                                </ConditionalFieldDisplay>
                            </Grid>
                        );
                    })}
                </>
            )}

            {participantFields?.length > 0 && (
                <>
                    {isMatchConfigurationPricingEnabled && requestFieldsWithoutQuantity?.length > 0 && (
                        <Divider sx={{ mt: 3, marginBottom: 3 }} />
                    )}

                    <Typography sx={{ mt: 3 }} variant="subtitle1" gutterBottom>
                        {perspective === 'CONSUMER' ? 'Provider' : 'Consumer'}
                    </Typography>

                    {participantFields?.map((field) => {
                        return (
                            <Grid key={field?.field_type.tech_name}>
                                <ConditionalFieldDisplay
                                    key={field?.field_type?.tech_name}
                                    fields={participantFields}
                                    values={participantFieldValues}
                                    conditions={field?.field_type?.conditions ?? []}
                                >
                                    <FieldDisplayValue
                                        label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
                                        fieldTechName={field?.field_type.tech_name}
                                        inputType={field?.field_type.input_type}
                                        optionsUiConfig={field?.field_type?.ui_config?.options ?? []}
                                        inputOptions={field?.field_type.input_options}
                                        value={field.value}
                                    />
                                </ConditionalFieldDisplay>
                            </Grid>
                        );
                    })}
                </>
            )}
        </Paper>
    );
};

export default SingleDirectTransactionMatchOverview;
