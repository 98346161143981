import Typography from '@mui/material/Typography';

interface FieldValueBooleanProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className?: any;
	fieldTechName: string;
}

const FieldValueBoolean = ({ fieldTechName, value, className }: FieldValueBooleanProps) => {
	return (
		<Typography id={fieldTechName} className={className}>
			{value === true && 'Yes'}
			{value === false && 'No'}
			{value === null && 'n.a.'}
		</Typography>
	);
};

export default FieldValueBoolean;
