import { configureStore, Action } from '@reduxjs/toolkit';

import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore, persistReducer } from 'redux-persist';
import type { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import rootReducer, { RootReducer } from '@rdv-fo/store/rootReducer';

import { determinePlatformNameFromUrl } from '@rdv-fo/app/lib/url';

const STORE_VERSION = 150;
const platformName = determinePlatformNameFromUrl();

export const persistConfig = {
	key: `rfo-${platformName}`,
	storage,
	version: STORE_VERSION,
	blacklist: ['platform'],
};

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer);

export const store = configureStore({
	devTools: process.env.NODE_ENV !== 'production',
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;

// Use throughout app instead of plain `useDispatch` and `useAppSelector`
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
