import unifyFieldValue from '@rdv-fo/services/randevuApi/helpers/fieldHelper';
import * as authMutations from './authMutations';
import * as authQueries from './authQueries';

import {
	MutationLoginParticipantArgs,
	MutationRegisterParticipantArgs,
	MutationRequestParticipantPasswordResetArgs,
	MutationResetParticipantPasswordArgs,
	MutationVerifyParticipantArgs,
	User,
	MutationTriggerManualParticipantOnboardingTransitionArgs,
	Participant,
	MutationUpdateMyEmailArgs,
	MutationUpdateMyPasswordArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

class AuthService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async signUp({
		first_name,
		last_name,
		email,
		participant_tech_name,
	}: MutationRegisterParticipantArgs): Promise<{ signed_up: boolean | null; errors: any }> {
		const payload = authMutations.registerParticipant({
			first_name,
			last_name,
			email,
			participant_tech_name,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { signed_up: data?.registerParticipant, errors };
	}

	async signIn({ email, password }: MutationLoginParticipantArgs): Promise<{ token: string | null; errors: any }> {
		const payload = authMutations.signIn({ email, password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { token: data?.loginParticipant?.token, errors };
	}

	async signInAsGuest(): Promise<{ token: string | null; errors: any }> {
		const payload = authMutations.signInAsGuest();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { token: data?.getGuestToken?.token, errors };
	}

	async signOut(): Promise<{ signed_out: boolean | null; errors: any }> {
		const payload = authMutations.signOut();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { signed_out: data?.logoutMe, errors };
	}

	async verifyValidSession(): Promise<{ valid: boolean | null; errors: any }> {
		const payload = authQueries.verifyValidSession();
		const response = await this.randevu.call({ payload });
		return {
			valid: response?.data?.me?.id !== null,
			errors: response?.errors,
		};
	}

	async requestParticipantPasswordReset({
		email,
	}: MutationRequestParticipantPasswordResetArgs): Promise<{ requested: boolean | null; errors: any }> {
		const payload = authMutations.requestParticipantPasswordReset({
			email,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { requested: data?.requestParticipantPasswordReset, errors };
	}

	async triggerManualParticipantOnboardingTransition({
		id_participant,
		transition_tech_name,
	}: MutationTriggerManualParticipantOnboardingTransitionArgs): Promise<{ triggered: boolean | null; errors: any }> {
		const payload = authMutations.triggerManualParticipantOnboardingTransition({
			id_participant,
			transition_tech_name,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { triggered: data?.triggerManualParticipantOnboardingTransition, errors };
	}

	async getCurrentUser(): Promise<{ user: User | null; errors: any }> {
		const payload = authQueries.getCurrentUser();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const current_user = data?.me;

		const fields = unifyFieldValue(current_user?.fields);

		if (current_user) {
			current_user.fields = fields;
		}

		return { user: current_user, errors };
	}

	async getMyParticipants(): Promise<{ participants: Participant[] | null; errors: any }> {
		const payload = authQueries.getMyParticipants();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let participants = data?.myParticipants;

		participants = participants?.map((participant: Participant) => {
			const fields = unifyFieldValue(participant?.fields);

			participant.fields = fields;

			return participant;
		});

		return { participants: participants, errors };
	}

	async verifyParticipantAccount({
		token,
		password,
	}: MutationVerifyParticipantArgs): Promise<{ verified: boolean | null; errors: any }> {
		const payload = authMutations.verifyParticipantAccount({ token, password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { verified: data?.verifyParticipant, errors };
	}

	async resetParticipantPassword({
		token,
		password,
	}: MutationResetParticipantPasswordArgs): Promise<{ password_set: boolean | null; errors: any }> {
		const payload = authMutations.resetParticipantPassword({ token, password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { password_set: data?.resetParticipantPassword, errors };
	}
	async updateMyEmail({
		current_password,
		new_email,
	}: MutationUpdateMyEmailArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = authMutations.updateMyEmail({ current_password, new_email });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { updated: data?.updateMyEmail, errors };
	}
	async updateMyPassword({
		current_password,
		new_password,
	}: MutationUpdateMyPasswordArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = authMutations.updateMyPassword({ current_password, new_password });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { updated: data?.updateMyPassword, errors };
	}
}

export default AuthService;
