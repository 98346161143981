import Typography from '@mui/material/Typography';
import { formatDateLong } from '@rdv-fo/app/lib/datetime';

interface FieldValueDateSetProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueDateSet = ({ fieldTechName, value, className }: FieldValueDateSetProps) => {
	return (
		<Typography id={fieldTechName} className={className}>
			{value === null || value?.length === 0 || value === undefined
				? 'n.a.'
				: value.map((date: string) => formatDateLong(date)).join(', ')}
		</Typography>
	);
};

export default FieldValueDateSet;
