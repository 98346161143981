import { Form } from 'react-final-form';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import arrayMutators from 'final-form-arrays';

import InputsContainer from '../../InputsContainer';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';

// workaround until we make another component to be used on MatchDetailsPage
const MatchDetailsView = ({ transactionTechName, matchDetails = false, children }) => {
	return (
		<>
			{!matchDetails && (
				<Paper
					sx={{
						padding: 4,
						width: '100%',
					}}
					elevation={0}
				>
					<Typography component="h1" variant="h1" gutterBottom>
						Enter your requirements
					</Typography>
					<br />
					<br />
					{children}
				</Paper>
			)}

			{matchDetails && <>{children}</>}
		</>
	);
};

const CreateReverseAuctionRequestForm = ({
	transactionTechName,
	supplyFilterFieldsTypes = [],
	matchingOrderFieldTypes = [],
	availabilityFields = [],
	inputsDisabled = false,
	showSubmitBtn = true,
	matchDetailsPage = false, // workaround until we make another component to be used on MatchDetailsPage
	submitButtonLabel,
	sharedObjectTypes,
	sharedObjects,
	initValues,
	onSubmit,
}) => {
	return (
		<Form
			onSubmit={onSubmit}
			mutators={{
				...arrayMutators,
			}}
			initialValues={initValues}
		>
			{({ handleSubmit, submitting }) => {
				return (
					<MatchDetailsView transactionTechName={transactionTechName} matchDetails={matchDetailsPage}>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Grid container direction="column" spacing={2}>
										{supplyFilterFieldsTypes?.length > 0 && (
											<>
												<Grid item xs={12} component="section" id="supply-fields-section">
													<InputsContainer
														sharedObjects={sharedObjects}
														sharedObjectTypes={sharedObjectTypes}
														fieldTypes={supplyFilterFieldsTypes}
														treatInputsAsFilters
														disabled={inputsDisabled}
														showInputs={true}
													/>
												</Grid>
												{availabilityFields?.length > 0 && (
													<Grid
														item
														xs={12}
														component="section"
														id="availability-fields-section"
													>
														<InputsContainer
															fieldTypes={availabilityFields}
															sharedObjectTypes={sharedObjectTypes}
															sharedObjects={sharedObjects}
															disabled={inputsDisabled}
															showInputs={true}
														/>
													</Grid>
												)}
											</>
										)}
									</Grid>
									{matchingOrderFieldTypes?.length > 0 && (
										<>
											{!matchDetailsPage && (
												<Divider
													sx={{
														marginTop: 8,
														marginBottom: 8,
													}}
												/>
											)}
											<Grid item xs={12} component="section" id="transaction-options-section">
												<InputsContainer
													sharedObjects={sharedObjects}
													sharedObjectTypes={sharedObjectTypes}
													fieldTypes={matchingOrderFieldTypes}
													disabled={inputsDisabled}
													showInputs={true}
												/>
											</Grid>
										</>
									)}
								</Grid>
							</Grid>
							{showSubmitBtn && (
								<Grid item xs={12} sm={12}>
									<LoadingButton
										type="submit"
										color="primary"
										variant="contained"
										sx={{ marginTop: 4 }}
										loading={submitting}
										disabled={submitting}
									>
										{submitButtonLabel}
									</LoadingButton>
								</Grid>
							)}
						</form>
					</MatchDetailsView>
				);
			}}
		</Form>
	);
};
export default CreateReverseAuctionRequestForm;
