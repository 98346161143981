import gql from 'graphql-tag';
import { print } from 'graphql';
import { QueryMySharedObjectsArgs } from '../../types/generatedTypes';
import { FIELDS_WITH_FIELD_TYPE_FRAGMENT } from '../fragments';

const MY_SHARED_OBJECT_TYPES = gql`
	query MY_SHARED_OBJECT_TYPES {
        mySharedObjectTypes {
            id
            name
            tech_name
            fields{
				id
				name
				tech_name
				input_type
				input_options
				is_required
				ui_config {
					helper_text
					input_control
					label
					options {
						value
						label
					}
				}
				conditions {
					type
					payload
				}
            }
        }
    }
`;

const mySharedObjectTypes = () => {
	return JSON.stringify({
		query: print(MY_SHARED_OBJECT_TYPES),
	});
};

const MY_SHARED_OBJECTS = gql`
	query MY_SHARED_OBJECTS($where: SharedObjectFilter) {
        mySharedObjects(where: $where) {
            id
            id_object_type
            fields {
				...FieldsFragment
			}
        }
    }
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const mySharedObjects = ({ where = {} }: QueryMySharedObjectsArgs) => {
	return JSON.stringify({
		query: print(MY_SHARED_OBJECTS),
		variables: { where }
	});
};


export { mySharedObjectTypes, mySharedObjects }