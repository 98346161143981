import { Chip as MuiChip, Tooltip } from '@mui/material/';
import useChipStyles from '@rdv-fo/components/common/chip/chipColors';
import { TRANSACTION_STATUS } from '@rdv-fo/services/randevuApi';

const TransactionStatusChip = ({ status, size = 'small', createdAt, startedAt, terminatedAt, cancelledAt }) => {
	const classes = useChipStyles();

	let colorClass;
	let value;

	const cancelled = cancelledAt !== null && cancelledAt !== undefined;
	const finished = terminatedAt !== null && terminatedAt !== undefined;
	const drafted = startedAt === null || startedAt === undefined;

	switch (status) {
		case TRANSACTION_STATUS.ONGOING.value:
			colorClass = classes.blue;
			value = TRANSACTION_STATUS.ONGOING.label;
			break;

		case TRANSACTION_STATUS.CHECKOUT.value:
			colorClass = classes.orange;
			value = TRANSACTION_STATUS.CHECKOUT.label;
			break;

		case TRANSACTION_STATUS.PAID.value:
			colorClass = classes.green;
			value = TRANSACTION_STATUS.PAID.label;
			break;

		case TRANSACTION_STATUS.FAILED.value:
			colorClass = classes.red;
			value = TRANSACTION_STATUS.FAILED.label;
			break;

		case TRANSACTION_STATUS.DECLINED.value:
			colorClass = classes.red;
			value = TRANSACTION_STATUS.DECLINED.label;
			break;

		case TRANSACTION_STATUS.REJECTED.value:
			colorClass = classes.red;
			value = TRANSACTION_STATUS.REJECTED.label;
			break;

		default:
			colorClass = classes.blue;
			value = status;
			colorClass = classes.chipGray;
	}

	let toolTip = 'Transaction ongoing';
	if (finished) toolTip = 'Transaction finished';
	if (cancelled) toolTip = 'Transaction cancelled';
	if (drafted) toolTip = 'Transaction is in draft';

	return (
		<Tooltip title={toolTip}>
			<MuiChip label={value} sx={{ color: colorClass, fontWeight: 600 }} size={size} clickable={false} />
		</Tooltip>
	);
};

export default TransactionStatusChip;
