import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/material';

import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import ConnectionsTable from '@rdv-fo/components/connections/ConnectionsTable';
import {
	loadMyConnections,
	loadMyConnectionTypes,
	selectLoading,
	selectMyConnections,
	selectMyConnectionTypes,
} from '@rdv-fo/store/slices/connectionSlice';
import { useEffect } from 'react';
import { selectPlatformConnectionsPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import { useHistory, useParams } from 'react-router';
import BackButton from '@rdv-fo/components/common/BackButton';
import { Link as RouterLink } from 'react-router-dom';
import ROUTES from '@rdv-fo/common/routes';

const determineQueryParams = (columnVisibility: Record<string, boolean>, otherQueryParams: any) => {
	return [
		Object.entries(columnVisibility)
			.map(([key, value]) => {
				return value ? `initialColumnVisibility=${key}` : undefined;
			})
			.filter((value) => value)
			.join('&'),
		Object.entries(otherQueryParams)
			.map(([key, value]) =>
				value instanceof Array ? value.map((value) => `${key}=${value}`).join('&') : `${key}=${value}`
			)
			.join('&'),
	]
		.filter((value) => value)
		.join('&');
};

interface RdvConnectionsTitle {
	label: string;
	ids_participant_type: string[] | Array<null>;
}
export const filterConnectionsPageTitle = (participantTypeIds: string[], title: RdvConnectionsTitle) => {
	if (title?.ids_participant_type?.length < 1) return true;

	const participantTypesHit = [] as boolean[];
	title?.ids_participant_type?.forEach((id: string | null) =>
		id ? participantTypesHit.push(participantTypeIds?.includes(id)) : false
	);
	if (participantTypesHit.includes(true)) return true;

	return false;
};

const MyConnectionsPage = () => {
	const dispatch = useAppDispatch();
	const myConnections = useAppSelector(selectMyConnections);
	const { connectionTechName } = useParams<{ connectionTechName: string }>();

	const loading = useAppSelector(selectLoading);
	const currentParticipantTypeId = useAppSelector(selectCurrentUser)?.type?.id;
	const page_config = useAppSelector(selectPlatformConnectionsPageConfig);
	const pageTitle = page_config?.title.filter((title: any) =>
		filterConnectionsPageTitle(currentParticipantTypeId, title)
	)[0];
	const queryParams = usePrimitiveQueryParams() as any;
	const history = useHistory();

	const connectionTypes = useAppSelector(selectMyConnectionTypes);
	const connectionType = connectionTypes?.find((ct) => ct.tech_name === connectionTechName);

	const initialColumnVisibilityFromQueryParams = {};
	const { initialColumnVisibility, ...otherQueryParams } = queryParams;

	if (initialColumnVisibility instanceof Array) {
		initialColumnVisibility.forEach((column: string) =>
			Object.assign(initialColumnVisibilityFromQueryParams, { [column]: true })
		);
	} else if (initialColumnVisibility)
		Object.assign(initialColumnVisibilityFromQueryParams, { [initialColumnVisibility]: true });

	const replicateColumnVisibilityIntoQueryParams = (columnVisibility: any) => {
		history.replace({ search: '?' + determineQueryParams(columnVisibility, otherQueryParams) });
	};

	useEffect(() => {
		dispatch(loadMyConnections({ where: { connection_tech_name: connectionTechName } }));
		dispatch(loadMyConnectionTypes());
	}, [dispatch, connectionTechName]);

	return (
		<>
			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				spacing={2}
				justifyContent="space-between"
				alignItems="space-between"
			>
				<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
					<BackButton />
					<Typography component="h1" variant="h1">
						{pageTitle?.label ?? 'Manage connections'}
					</Typography>
				</Stack>
				{connectionType?.participant_discovery_enabled && (
					<Stack>
						<Button
							variant="contained"
							to={ROUTES.DISCOVER_CONNECTION_PARTICIPANTS.replace(
								':connectionTechName',
								connectionTechName
							)}
							component={RouterLink}
						>
							Browse participants
						</Button>
					</Stack>
				)}
			</Stack>
			<Box sx={{ mt: 5 }}>
				{loading ? (
					<Stack direction="column" sx={{ pt: 4 }}>
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
					</Stack>
				) : (
					<ConnectionsTable
						connectionTechName={connectionTechName}
						loading={loading}
						connections={myConnections}
						onColumnVisibilityChanged={replicateColumnVisibilityIntoQueryParams}
						initialColumnVisibility={initialColumnVisibilityFromQueryParams}
					/>
				)}
			</Box>
		</>
	);
};

export default MyConnectionsPage;
