import { Chip } from '@mui/material'
import { PaymentError, PaymentStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes'
import React from 'react'
import { Tooltip } from '@mui/material/';


const determineStatusChipColorAndLabelFromStatus = (status: PaymentStatusKind) => {
    switch (status) {
        case PaymentStatusKind.Authorized:
            return { label: 'Authorized', color: 'info' as 'info' }
        case PaymentStatusKind.Failed:
            return { label: 'Failed', color: 'error' as 'error' }
        case PaymentStatusKind.Captured:
            return { label: 'Captured', color: 'primary' as 'primary' }
        case PaymentStatusKind.CheckoutRequested:
            return { label: 'Checkout requested', color: 'default' as 'default' }
        case PaymentStatusKind.Prepared:
            return { label: 'Prepared', color: 'secondary' as 'secondary' }
        case PaymentStatusKind.Completed:

    }
    return { label: 'Completed', color: 'success' as 'success' }
}

interface PaymentStatusChipProps {
    status: PaymentStatusKind;
    paymentError?: PaymentError
}

const PaymentStatusChip = ({ status, paymentError }: PaymentStatusChipProps) => {
    const chipProps = determineStatusChipColorAndLabelFromStatus(status)

    if (paymentError) {

        let msg = paymentError.error_message;
        if (paymentError.error_code) msg = msg + ` (Error code: ${paymentError.error_code})`

        if (!msg) msg = "Declined by payment processor."

        return (
            <Tooltip title={msg}>
                <div>
                    <Chip color={chipProps.color} label={chipProps.label} />
                </div>
            </Tooltip >)
    }

    return (
        <Chip color={chipProps.color} label={chipProps.label} />
    )
}

export default PaymentStatusChip;