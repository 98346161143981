import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/material';

import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import {
	loadMyReferrals,
	referParticipantUser,
	referralParticipantUserFailed,
	selectLoading,
	selectMyReferrals,
} from '@rdv-fo/store/slices/connectionSlice';
import { useEffect } from 'react';
import BackButton from '@rdv-fo/components/common/BackButton';
import ReferralsTable from '@rdv-fo/components/referrals/ReferralsTable';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import ReferParticipantUserDialog, {
	ReferParticipantUserFormValues,
} from '@rdv-fo/components/referrals/ReferParticipantUserDialog';
import { selectPlatformParticipantTypes } from '@rdv-fo/store/slices/platformSlice';

const MyReferralsPage = () => {
	const dispatch = useAppDispatch();
	const myReferrals = useAppSelector(selectMyReferrals);
	const participantTypes = useAppSelector(selectPlatformParticipantTypes);
	const loading = useAppSelector(selectLoading);
	const [openReferParticipantUserDialog, setOpenReferParticipantUserDialog] = useState(false);

	useEffect(() => {
		dispatch(loadMyReferrals());
	}, [dispatch]);

	const handleSubmitReferParticipantUserForm = async (values: ReferParticipantUserFormValues) => {
		const resolvedAction = await dispatch(referParticipantUser({ ...values }));

		if (resolvedAction.type === referralParticipantUserFailed.type) return resolvedAction.payload;

		setOpenReferParticipantUserDialog(false);
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-end">
				<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
					<BackButton />
					<Typography component="h1" variant="h1">
						{'Referrals'}
					</Typography>
				</Stack>

				<Button
					disabled={loading}
					startIcon={<AddIcon />}
					variant="contained"
					onClick={() => setOpenReferParticipantUserDialog(true)}
				>
					New referral
				</Button>
			</Stack>
			<Box sx={{ mt: 5 }}>
				{loading ? (
					<Stack direction="column" sx={{ pt: 4 }}>
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
					</Stack>
				) : (
					<>
						<ReferParticipantUserDialog
							participantTypes={participantTypes ?? []}
							open={openReferParticipantUserDialog}
							onClose={() => setOpenReferParticipantUserDialog(false)}
							onSubmit={handleSubmitReferParticipantUserForm}
						/>
						<ReferralsTable loading={loading} disabled={loading} referrals={myReferrals} />
					</>
				)}
			</Box>
		</>
	);
};

export default MyReferralsPage;
