import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const NotFoundPage = () => (
	<Grid container style={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
		<Grid item>
			<Typography variant="h4">Page not found ¯\_(ツ)_/¯</Typography>
		</Grid>
	</Grid>
);
export default NotFoundPage;
