import { Typography } from '@mui/material';
import { RdvAutoIncFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldIntegerCellViewProps {
	value: RdvAutoIncFieldValue;
}
const FieldAutoIncCellView = ({ value }: FieldIntegerCellViewProps) => {
	return <Typography variant="body2">{value}</Typography>;
};

export default FieldAutoIncCellView;
