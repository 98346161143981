import { Link, Typography } from '@mui/material';
import { RdvDocumentFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldDocumentCellViewProps {
	value: RdvDocumentFieldValue;
}
const FieldDocumentCellView = ({ value }: FieldDocumentCellViewProps) => {
	if (value === null || value === undefined) return <Typography>n.a.</Typography>;

	return (
		<Link variant="body2" href={value?.url} target="#">
			{value?.name ?? value?.file_name}
		</Link>
	);
};

export default FieldDocumentCellView;
