import React, { useEffect, useState, useCallback } from 'react';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { selectToastyMessages, sendToastyMessage } from '@rdv-fo/store/slices/uiSlice';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';

const Alert = React.forwardRef(function Alert(props, ref) {
	return (
		<div ref={ref}>
			<MuiAlert elevation={6} variant="filled" {...props} />
		</div>
	);
});

const ToastyMessages = () => {
	const dispatch = useAppDispatch();
	const toastyNotification = useAppSelector(selectToastyMessages);
	const [snackPack, setSnackPack] = useState([]);
	const [open, setOpen] = useState(false);
	const [notificationInfo, setNotificationInfo] = useState(undefined);

	const createNotification = useCallback(() => {
		setNotificationInfo(undefined);
		setSnackPack((prev) => [
			...prev,
			{
				message: toastyNotification?.message,
				key: new Date().getTime(),
				actionText: toastyNotification?.actionText,
				actionCallback: toastyNotification?.actionCallback,
				severity: toastyNotification?.severity ?? 'info',
			},
		]);
	}, [
		toastyNotification?.actionCallback,
		toastyNotification?.actionText,
		toastyNotification?.severity,
		toastyNotification?.message,
	]);

	useEffect(() => {
		if (snackPack.length && !notificationInfo) {
			// Set a new snack when we don't have an active one
			setNotificationInfo({ ...snackPack[0] });
			setSnackPack((prev) => prev.slice(1));
			setOpen(true);
		} else if (snackPack.length && notificationInfo && open) {
			// Close an active snack when a new one is added
			setOpen(false);
		}
	}, [snackPack, notificationInfo, open]);

	useEffect(() => {
		if (toastyNotification) {
			createNotification();
		}
	}, [toastyNotification, createNotification, dispatch]);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const handleExited = () => {
		dispatch(sendToastyMessage(null));
	};

	return (
		<Slide direction="left" in={open}>
			<div>
				<Snackbar
					key={notificationInfo ? notificationInfo.key : undefined}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={open}
					TransitionProps={{ onExited: handleExited }}
					autoHideDuration={3500}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						sx={{ mt: 1, alignItems: 'center' }}
						severity={notificationInfo?.severity}
					>
						{notificationInfo?.message}

						{notificationInfo?.actionCallback ? (
							<Button
								sx={{
									'&:hover': {
										color: 'white',
									},
									marginRight: 0,
									marginLeft: 2,
								}}
								color="secondary"
								size="small"
								variant="outlined"
								onClick={notificationInfo.actionCallback}
							>
								{notificationInfo.actionText}
							</Button>
						) : undefined}
					</Alert>
				</Snackbar>
			</div>
		</Slide>
	);
};

export default ToastyMessages;
