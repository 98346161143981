import { Stack, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import {
    DEFAULT_PAGE_SIZE,
    buildPaymentsTableColumns,
    buildPaymentsTableRows,
} from '@rdv-fo/app/lib/tableHelpers';
import { Payment } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const NoDataView = () => {
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
                No payments yet
            </Typography>
        </Stack>
    );
};

interface PaymentsTableProps {
    payments: Payment[];
    loading: boolean;
    disabled: boolean;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    onPreviousPage: () => void;
    onNextPage: () => void;
}

const PaymentsTable = ({
    payments = [],
    loading,
    disabled,
    hasPreviousPage,
    hasNextPage,
    onPreviousPage,
    onNextPage
}: PaymentsTableProps) => {
    const paymentColumns = buildPaymentsTableColumns();

    const paymentRows = buildPaymentsTableRows(payments);

    return (
        <>
            <MaterialReactTable
                columns={paymentColumns}
                data={paymentRows}
                initialState={{
                    pagination: { pageSize: DEFAULT_PAGE_SIZE, pageIndex: 0 },
                }}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
                    showFirstButton: false,
                    showLastButton: false,
                    backIconButtonProps: {
                        disabled: !hasPreviousPage || loading,
                        onClick: onPreviousPage,
                    },
                    nextIconButtonProps: {
                        disabled: !hasNextPage || loading,
                        onClick: onNextPage,
                    },
                }}

                manualPagination={true} // server-side pagination
                enablePinning={false}
                enableSorting={false}
                enablePagination={true}
                enableDensityToggle={false}
                enableGlobalFilter={false}
                enableColumnFilters={false} //disable all column filters
                enableColumnFilterModes={false}
                enableColumnActions={false}
                muiTableBodyProps={{
                    children: payments?.length < 1 ? <NoDataView /> : null,
                }}
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: '0',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        '&:hover': {
                            backgroundColor: RDV_NEUTRAL_20,
                        },
                    },
                }}
                enableFullScreenToggle={false}
                enableRowOrdering={false}
                enableHiding={false}
                muiTableHeadCellProps={{
                    sx: {
                        backgroundColor: RDV_NEUTRAL_10,
                        fontSize: '0.889rem',
                        fontWeight: 600,
                        letterSpacing: 0,
                        lineHeight: '1.4rem',
                    },
                }}
                enableTableFooter={false}
            />
        </>
    );
};

export default PaymentsTable;
