import { Typography } from '@mui/material';
import { RdvMonetaryValueFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldMonetaryValueCellViewProps {
	value: RdvMonetaryValueFieldValue;
}
const FieldMonetaryValueCellView = ({ value }: FieldMonetaryValueCellViewProps) => {
	if (value === null || value === undefined) return <Typography>-</Typography>;

	const amount = value.amount === 0 ? 0 : value.amount / 100;

	return (
		<Typography variant="body2">
			{`${amount} ${value.currency}`}
		</Typography>
	);
};

export default FieldMonetaryValueCellView;
