import { Checkboxes, Select, SelectData } from 'mui-rff';
import LabelFieldInput from '@rdv-fo/components/fields/input/LabelFieldInput';
import { Form } from 'react-final-form';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import FormFieldFile from '@rdv-fo/components/common/form/FormFieldFile';
import { ImportSupplyDataTemplate, SupplyType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { validators } from '@rdv-fo/app/lib/validation';
import OptionLabel from '@rdv-fo/components/common/OptionLabel';

export type ImportSupplyDataFormValues = {
	id_template: string;
	selected_supply_tech_name: string;
	auto_activate_supplies: boolean;
	data_file: File;
};

interface ImportSupplyDataFormProps {
	supplyTypes: SupplyType[];
	onSubmit: (values: ImportSupplyDataFormValues) => void;
}
const ImportSupplyDataForm = ({ supplyTypes, onSubmit }: ImportSupplyDataFormProps) => {
	const supplyTypeOptions = supplyTypes
		?.filter((st: SupplyType) => st.bulk_import_enabled)
		.map((st) => ({
			label: <OptionLabel primary={st.name} />,
			value: st.tech_name,
		}));

	const multipleSupplyTypeImports = supplyTypeOptions?.length > 0;

	const initFormValues = (supplyTypeOptions: SelectData[], supplyTypes: SupplyType[]) => {
		const selected_supply_tech_name = supplyTypeOptions[0]?.value;
		const template = supplyTypes
			?.find((st) => st.tech_name === selected_supply_tech_name)
			?.data_import_templates?.find((template) => template.is_active);

		return {
			selected_supply_tech_name: selected_supply_tech_name,
			id_template: template?.id,
			auto_activate_supplies: true,
		};
	};

	const formFieldValidators = {
		id_template: [validators.required],
		selected_supply_tech_name: [validators.required],
		data_file: [validators.required],
	};
	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initFormValues(supplyTypeOptions, supplyTypes)}
			validate={(values) => validators.validate(formFieldValidators, values)}
			render={({ handleSubmit, submitting, pristine, values, hasValidationErrors }) => {
				const enabledTemplates =
					supplyTypes
						?.filter((st: SupplyType) => st.tech_name === values.selected_supply_tech_name)
						.flatMap((st) => st.data_import_templates)
						.filter((template: ImportSupplyDataTemplate) => template.is_active) ?? [];

				const enabledTemplatesFound = enabledTemplates?.length > 0;

				const templateOptions = enabledTemplates.map((template) => {
					return {
						label: <OptionLabel primary={template.name} secondary={template.version} />,
						value: template.id,
					};
				});

				const showSelectFile = values.id_template !== null;

				return (
					<form id="upload-supply-data-form" onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
						<Stack
							direction="column"
							alignItems="stretch"
							justifyContent="center"
							spacing={4}
							sx={{ width: '100%' }}
						>
							{multipleSupplyTypeImports && (
								<Box>
									<LabelFieldInput
										text="What data do you want to upload?"
										htmlFor="selected_supply_tech_name"
										required={false}
										showOptional={false}
									/>

									<Select
										name="selected_supply_tech_name"
										disabled={false}
										required={false}
										data={supplyTypeOptions}
										variant="outlined"
										formControlProps={{ margin: 'dense', size: 'small' }}
										fullWidth
									/>
								</Box>
							)}
							{!enabledTemplatesFound && (
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									spacing={2}
									sx={{ mt: 6 }}
								>
									<Typography variant="subtitle1">No import templates yet</Typography>
									<Typography variant="body2">
										Your import templates will appear here after platform operator enables them
									</Typography>
								</Stack>
							)}
							{enabledTemplatesFound && (
								<>
									<Box>
										<LabelFieldInput
											text="Select template you want to use"
											htmlFor="id_template"
											required={true}
											showOptional={false}
										/>

										<Select
											name="id_template"
											disabled={submitting}
											required={true}
											data={templateOptions}
											variant="outlined"
											formControlProps={{ margin: 'dense', size: 'small' }}
											fullWidth
										/>
									</Box>

									<Checkboxes
										name="auto_activate_supplies"
										required={true}
										size="small"
										data={{
											label: 'Automatically activate successfully processed data records',
											value: true,
											disabled: submitting,
										}}
									/>

									{showSelectFile && (
										<FormFieldFile name="data_file" allowedFileExtensions={['csv']} />
									)}

									<LoadingButton
										type="submit"
										color="primary"
										variant="contained"
										form="upload-supply-data-form"
										loading={submitting}
										disabled={submitting || pristine || hasValidationErrors}
										fullWidth
									>
										Confirm upload
									</LoadingButton>
								</>
							)}
						</Stack>
					</form>
				);
			}}
		/>
	);
};

export default ImportSupplyDataForm;
