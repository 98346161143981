import React, { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';

import { Box, Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import OnboardingForm from '@rdv-fo/components/common/OnboardingForm';
import {
	loadMySupply,
	updateSupply,
	loadMySupplyFailed,
	selectMySupply,
	selectErrors,
} from '@rdv-fo/store/slices/supplySlice';
import { getDirtyFields } from '@rdv-fo/app/lib/formHelper';
import FlowManualActionsPanel from '@rdv-fo/components/transaction/FlowManualActionsPanel';
import SUPPLY_STATUS from '@rdv-fo/services/randevuApi/enums/supplyStatus';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { triggerManualSupplyOnboardingTransition, selectLoading } from '@rdv-fo/store/slices/supplySlice';
import SupplyStatusChip from '@rdv-fo/components/supply/SupplyStatusChip';
import { mapFormValuesToFieldInputs } from '@rdv-fo/app/lib/formHelpers';
import { useHistory } from 'react-router';
import { isNotFound } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import { capitalize } from 'lodash';
import { NextStepKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import BackButton from '../common/BackButton';
import { loadMySharedObjects, selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';

const isSupplyNotFound = (errors) => isNotFound(errors) && errors[0].path[0] === 'mySupply';

const SupplyOnboardingForm = ({ supplyId }) => {
	const dispatch = useAppDispatch();
	const supply = useAppSelector(selectMySupply);
	const loading = useAppSelector(selectLoading);
	const sharedObjects = useAppSelector(selectMySharedObjects)
	const supplyDetailsRoute = routeBuilder(ROUTES.UPDATE_MY_SUPPLY, [[':supplyId', supplyId]]);
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes)
	const sharedObjectTypesIds = sharedObjectTypes?.map((sot) => sot.id);
	const errors = useAppSelector(selectErrors);
	const history = useHistory();

	useEffect(() => {
		if (isSupplyNotFound(errors)) {
			history.replace(ROUTES.HOME);
		}
	}, [errors, history]);

	const handleSubmitOnboardingForm = async (values, form) => {
		const dirtyFields = getDirtyFields({ ...values }, form);

		const fieldInputs = mapFormValuesToFieldInputs(dirtyFields, values, sharedObjectTypesIds, supply?.fields);

		const resolvedAction = await dispatch(
			updateSupply({
				id: supplyId,
				dirty_fields: fieldInputs,
				current_fields: supply?.fields,
			})
		);

		if (resolvedAction?.type === loadMySupplyFailed.type) return resolvedAction.payload;

		const newStatus = resolvedAction.payload?.status;
		if (newStatus === SUPPLY_STATUS.ONBOARDED.value) dispatch(goToRoute(supplyDetailsRoute));
	};

	useEffect(() => {
		const init = async () => {
			console.log('Initially load supply which should be onboarded');
			const resolvedAction = await dispatch(loadMySupply({ id: supplyId }));
			if (resolvedAction.type.includes('Failed')) return;
			const status = resolvedAction.payload?.status;
			if (status !== SUPPLY_STATUS.ONBOARDING.value) {
				dispatch(goToRoute(supplyDetailsRoute));
			}
		};
		init();
	}, []);

	const handleTriggerManualSupplyOnboardingTransition = (objectId, transitionTechName) => {
		dispatch(
			triggerManualSupplyOnboardingTransition({
				id_supply: objectId,
				transition_tech_name: transitionTechName,
			})
		);
	};
	const manualTransitionsAsNextStepsExist =
		supply?.onboarding_progress?.next_steps?.find((nextStep) => nextStep.type === NextStepKind.ManualTrigger) !==
		undefined;
	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
				<Box>
					<BackButton label="Manage supplies" to={ROUTES.MY_SUPPLIES} />
				</Box>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					{loading ? (
						<Skeleton width="100%" height={50} />
					) : (
						<Typography variant="body1" fontWeight="bold">
							{capitalize(supply?.type?.name)} onboarding
						</Typography>
					)}
					{loading ? (
						<Skeleton width="100%" height={50} />
					) : (
						<SupplyStatusChip
							status={supply?.status}
							onboardingStateLabel={supply?.onboarding_progress?.status}
						/>
					)}
				</Stack>

				{loading ? (
					<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={4}>
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
					</Stack>
				) : (
					<>
						<Typography variant="h2">{capitalize(supply?.onboarding_progress?.message)}</Typography>
						{manualTransitionsAsNextStepsExist && (
							<FlowManualActionsPanel
								objectId={supply?.id}
								nextSteps={supply?.onboarding_progress?.next_steps}
								message={''}
								onTriggerManualTransition={handleTriggerManualSupplyOnboardingTransition}
								loading={loading}
							/>
						)}
						<OnboardingForm
							submitText={manualTransitionsAsNextStepsExist ? 'Save' : 'Continue'}
							sharedObjects={sharedObjects}
							fields={supply?.fields}
							onSubmit={handleSubmitOnboardingForm}
							loading={loading}
							sharedObjectTypes={sharedObjectTypes}
						/>
					</>
				)}
			</Stack>
		</>
	);
};

export default SupplyOnboardingForm;
