import { createTheme, PaletteColorOptions, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

export const RDV_DEFAULT_PRIMARY_COLOR = '#00A0F9';
export const RDV_DEFAULT_SECONDARY_COLOR = '#FD8A8A';

export const RDV_BACKGROUND_COLOR = '#F9F9FA';
export const RDV_BLACK = '#06191D';

export const RDV_NEUTRAL_00 = '#fcfcfd';
export const RDV_NEUTRAL_10 = '#f1f3f3';
export const RDV_NEUTRAL_20 = '#dde1e2';
export const RDV_NEUTRAL_30 = '#c4cbcc';
export const RDV_NEUTRAL_40 = '#a6aeb0';
export const RDV_NEUTRAL_50 = '#828c8e';
export const RDV_NEUTRAL_60 = '#6a7577';
export const RDV_NEUTRAL_70 = '#505e60';
export const RDV_NEUTRAL_80 = '#38474a';
export const RDV_NEUTRAL_90 = '#1e3033';
export const RDV_NEUTRAL_100 = '#06191D';

declare module '@mui/material/styles' {
	interface Palette {
		light1: PaletteColorOptions;
	}
	interface PaletteOptions {
		light1?: PaletteColorOptions;
	}
}

/**
 * Maps the model for UI theme configuration coming from the backend
 * into the model which is understood by MUI
 */
const createThemeConfig = ({
	primaryColor = RDV_DEFAULT_PRIMARY_COLOR,
	secondaryColor = RDV_DEFAULT_SECONDARY_COLOR,
}: {
	primaryColor?: string;
	secondaryColor?: string;
}): ThemeOptions => {
	// Theme UI config which controls design
	let muiThemeOptions = {
		themeName: 'custom-theme',
		palette: {
			primary: { main: primaryColor, contrastText: '#fff' },
			secondary: { main: secondaryColor, contrastText: '#fff' },
		},
		typography: {
			fontFamily: 'Inter',
			fontSize: 16,
			h1: {
				fontSize: '2.441rem',
				fontWeight: 800,
				letterSpacing: 0,
				lineHeight: '1.3',
			},
			h2: {
				fontSize: '1.953rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: '1.3',
			},
			h3: {
				fontSize: '1.563rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: '1.3',
			},
			h4: {
				fontSize: '1.25rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: '1.3',
			},
			subtitle1: {
				fontSize: '1.125rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: '1.3',
			},
			subtitle2: {
				fontSize: '1rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: '1.4',
			},
			body1: {
				fontSize: '1rem',
				fontWeight: 400,
				letterSpacing: 0,
				lineHeight: '1.4',
			},
			body2: {
				fontSize: '0.889rem',
				fontWeight: 400,
				letterSpacing: 0,
				lineHeight: '1.4',
			},
			caption: {
				fontSize: '0.79rem',
				fontWeight: 400,
				letterSpacing: 0,
				lineHeight: '1.3',
			},
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 10,
						textTransform: 'none' as const,
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: 10,
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						borderRadius: '10px',
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					size: 'small' as any, // Set the default size for all TextField components
				},
			},
		},
	};

	// General UI config which controls components

	return muiThemeOptions;
};

export const createRandevuTheme = (primaryColor?: string, secondaryColor?: string) => {
	let defaultTheme = createTheme();

	if (primaryColor === undefined) return responsiveFontSizes(defaultTheme);

	const themeOptions = createThemeConfig({ primaryColor, secondaryColor });

	return responsiveFontSizes(createTheme(themeOptions));
};
