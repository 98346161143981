import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';

import ParticipantAccountPage from '@rdv-fo/pages/ParticipantProfilePage';
import MySuppliesPage from '@rdv-fo/pages/MySuppliesPage';
import MyCollectionsPage from '@rdv-fo/pages/MyCollectionsPage';
import SupplyOnboardingPage from '@rdv-fo/pages/SupplyOnboardingPage';
import UpdateMySupplyPage from '@rdv-fo/pages/UpdateMySupplyPage';
import DiscoveredTransactionSuppliesPage from '@rdv-fo/pages/DiscoveredTransactionSuppliesPage';
import CreateReverseAuctionRequestPage from '@rdv-fo/pages/CreateReverseAuctionRequestPage';

import MyTransactionsPage from '@rdv-fo/pages/MyTransactionsPage';
import ReverseAuctionTransactionDetailsPage from '@rdv-fo/pages/ReverseAuctionTransactionDetailsPage';
import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { DRAWER_WIDTH } from '@rdv-fo/app/constants';
import GeneralLayout from './GeneralLayout';
import PageWidth from '../PageWidth';
import CheckoutPage from '@rdv-fo/pages/CheckoutPage';
import PaymentProcessingPage from '@rdv-fo/pages/PaymentProcessingPage';
import DashboardPage from '@rdv-fo/pages/DashboardPage';
import MyConnectionsPage from '@rdv-fo/pages/connections/MyConnectionsPage';
import ConnectionDetailsPage from '@rdv-fo/pages/connections/ConnectionDetailsPage';
import { selectPlatformDisplayName } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';
import MySharedObjectsPage from '@rdv-fo/pages/MySharedObjectsPage';
import MyAccountPage from '@rdv-fo/pages/MyAccountPage';
import MyPaymentsPayoutsPage from '@rdv-fo/pages/MyPaymentsTransfersPage';
import ShoppingCartPage from '@rdv-fo/pages/ShoppingCartPage';
import ListMyMultiDirectTransactionsPage from '@rdv-fo/pages/ListMyMultiDirectTransactionsPage';
import MultiDirectTransactionDetailsPage from '@rdv-fo/pages/MultiDirectTransactionDetailsPage';
import ListMultiDirectOrdersPage from '@rdv-fo/pages/ListMyMultiDirectMatchesPage';
import MultiDirectOrderDetailsPage from '@rdv-fo/pages/MultiDirectOrderDetailsPage';
import MyCredentialsPage from '@rdv-fo/pages/MyCredentialsPage';
import UpdateMyCollectionPage from '@rdv-fo/pages/UpdateMyCollectionPage';
import DiscoveredConnectionPage from '@rdv-fo/pages/connections/DiscoveredConnectionPage';
import MyReferralsPage from '@rdv-fo/pages/MyReferralsPage';
import MyImportsPage from '@rdv-fo/pages/MyImportsPage';
import MySingleDirectTransactionsPage from '@rdv-fo/pages/MySingleDirectTransactionsPage';
import SingleDirectTransactionDetailsPage from '@rdv-fo/pages/SingleDirectTransactionDetailsPage';
import UpdateMySharedObjectPage from '@rdv-fo/pages/UpdateMySharedObjectPage';
import BankTransferCheckoutPage from '@rdv-fo/pages/BankTransferCheckoutPage';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		layoutRoot: {
			display: 'flex',
		},
		layoutContent: {
			width: '100%',
			padding: theme.spacing(3),
		},
		toolbar: theme.mixins.toolbar,
		drawer: {
			width: DRAWER_WIDTH,
		},
		drawerPaper: {
			width: DRAWER_WIDTH,
		},
		platformName: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
	};
});

const ProtectedLayout = () => {
	const classes = useStyles();
	const platformDisplayName = useAppSelector(selectPlatformDisplayName);
	const theme = useTheme();

	return (
		<Switch>
			<GeneralLayout>
				<PageWidth mobilePadding={true} paddingTop={theme.spacing(6)} paddingBottom={theme.spacing(6)}>
					<Route exact path={routeBuilder(ROUTES.MY_ACCOUNT)} component={MyAccountPage} />
					<Route exact path={routeBuilder(ROUTES.PAYMENTS_AND_TRANSFERS)} component={MyPaymentsPayoutsPage} />
					<Route exact path={routeBuilder(ROUTES.MY_CREDENTIALS)} component={MyCredentialsPage} />
					<Route exact path={routeBuilder(ROUTES.PARTICIPANT_PROFILE)} component={ParticipantAccountPage} />
					<Route exact path={routeBuilder(ROUTES.MY_SHARED_OBJECTS)} component={MySharedObjectsPage} />

					<Route exact path={routeBuilder(ROUTES.SUPPLY_ONBOARDING)} component={SupplyOnboardingPage} />
					<Route exact path={routeBuilder(ROUTES.MY_IMPORTS)} component={MyImportsPage} />
					<Route exact path={routeBuilder(ROUTES.MY_SUPPLIES)} component={MySuppliesPage} />
					<Route exact path={routeBuilder(ROUTES.UPDATE_MY_SUPPLY)} component={UpdateMySupplyPage} />
					<Route exact path={routeBuilder(ROUTES.MY_COLLECTIONS)} component={MyCollectionsPage} />
					<Route exact path={routeBuilder(ROUTES.UPDATE_MY_COLLECTION)} component={UpdateMyCollectionPage} />
					<Route
						exact
						path={routeBuilder(ROUTES.UPDATE_MY_SHARED_OBJECT)}
						component={UpdateMySharedObjectPage}
					/>

					<Route
						exact
						path={routeBuilder(ROUTES.CREATE_REQUEST_REVERSE_TRANSACTION)}
						component={CreateReverseAuctionRequestPage}
					/>
					<Route exact path={routeBuilder(ROUTES.DASHBOARD)} component={DashboardPage} />
					<Route exact path={routeBuilder(ROUTES.MY_REFERRALS)} component={MyReferralsPage} />

					<Route exact path={routeBuilder(ROUTES.MY_CONNECTIONS)} component={MyConnectionsPage} />
					<Route
						exact
						path={routeBuilder(ROUTES.DISCOVER_CONNECTION_PARTICIPANTS)}
						component={DiscoveredConnectionPage}
					/>
					<Route exact path={routeBuilder(ROUTES.CONNECTION_DETAILS)} component={ConnectionDetailsPage} />

					<Route exact path={routeBuilder(ROUTES.MY_TRANSACTIONS)} component={MyTransactionsPage} />

					<Route
						exact
						path={routeBuilder(ROUTES.MY_SINGLE_DIRECT_TRANSACTIONS)}
						component={MySingleDirectTransactionsPage}
					/>
					<Route
						exact
						path={routeBuilder(ROUTES.REVERSE_AUCTION_TRANSACTION_DETAILS)}
						component={ReverseAuctionTransactionDetailsPage}
					/>
					<Route
						exact
						path={routeBuilder(ROUTES.SINGLE_DIRECT_TRANSACTION_DETAILS)}
						component={SingleDirectTransactionDetailsPage}
					/>
					<Route
						exact
						path={routeBuilder(ROUTES.PAYMENT_PROCESSING_FOR_TRANSACTION)}
						component={PaymentProcessingPage}
					/>

					<Route exact path={routeBuilder(ROUTES.HOSTED_CHECKOUT)} component={CheckoutPage} />
					<Route
						exact
						path={routeBuilder(ROUTES.BANK_TRANSFER_CHECKOUT)}
						component={BankTransferCheckoutPage}
					/>

					<Route exact path={routeBuilder(ROUTES.SHOPPING_CART)} component={ShoppingCartPage} />
					<Route
						exact
						path={routeBuilder(ROUTES.MULTI_DIRECT_TRANSACTION_DETAILS)}
						component={MultiDirectTransactionDetailsPage}
					/>
					<Route
						exact
						path={routeBuilder(ROUTES.MY_MULTI_DIRECT_TRANSACTIONS)}
						component={ListMyMultiDirectTransactionsPage}
					/>
					<Route
						exact
						path={routeBuilder(ROUTES.MY_MULTI_DIRECT_MATCHES)}
						component={ListMultiDirectOrdersPage}
					/>
					<Route
						exact
						path={routeBuilder(ROUTES.MY_MULTI_DIRECT_MATCHES_DETAILS)}
						component={MultiDirectOrderDetailsPage}
					/>
				</PageWidth>
			</GeneralLayout>
			<div className={classes.layoutRoot}>
				<Drawer
					className={classes.drawer}
					variant="permanent"
					anchor={'left'}
					classes={{ paper: classes.drawerPaper }}
				>
					<Typography color="primary" align="center" variant="h6" className={classes.platformName}>
						{platformDisplayName}
					</Typography>

					<Divider />
				</Drawer>

				<main className={classes.layoutContent}>
					<div className={classes.toolbar}></div>

					<Route
						exact
						path={routeBuilder(ROUTES.DISCOVERED_TRANSACTION_SUPPLIES)}
						component={DiscoveredTransactionSuppliesPage}
					/>

					<Redirect to={routeBuilder(ROUTES.LANDING_PAGE)} />
				</main>
			</div>
		</Switch>
	);
};

export default ProtectedLayout;
