import Grid from '@mui/material/Grid';

import { TextField } from 'mui-rff';
import { FilterInputBaseProps } from './types';

import LabelFilterInput from './LabelFilterInput';

// FIXME: set proper type
const parse = (value: any) => {
	if (['', undefined, null].includes(value)) return null;
	return Number(value);
};
// FIXME: set proper type
const format = (value: any) => {
	if (['', undefined, null].includes(value)) return '';
	return value;
};

interface FilterInputIntegerProps extends FilterInputBaseProps {
	min?: number;
	max?: number;
	unit?: string;
}

interface IntegferTextFieldInputProps {
	type: string;
	inputMode: 'numeric' | 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'decimal' | undefined;
	min?: number;
	max?: number;
	step: number;
}

const FilterInputInteger = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
	min,
	max,
	unit,
}: FilterInputIntegerProps) => {
	let labelWithUnit = unit ? `${label} (${unit})` : label;

	const inputProps: IntegferTextFieldInputProps = {
		type: 'number',
		inputMode: 'numeric', // FIXME: MOVE TO STRING AND FORBID INPUTTING ANYTHING ELSE THAN DIGITS
		...(min && { min: min }),
		...(max && { max: max }),
		step: 1,
	};

	const fieldProps = {
		parse,
		format,
	};

	return (
		<Grid container>
			{noLabel === false && (
				<LabelFilterInput text={labelWithUnit ?? ''} htmlFor={techName} required={required} />
			)}
			<TextField
				name={`${techName}.min_value`}
				disabled={disabled}
				margin="dense"
				variant="outlined"
				size="small"
				fullWidth
				placeholder="From"
				fieldProps={fieldProps}
				inputProps={inputProps} // FIXME: remove ts-ignore from the above of the file and fix inputProps issue
			/>

			<TextField
				name={`${techName}.max_value`}
				disabled={disabled}
				margin="dense"
				variant="outlined"
				fullWidth
				size="small"
				placeholder="To"
				fieldProps={fieldProps}
				inputProps={inputProps} // FIXME: remove ts-ignore from the above of the file and fix inputProps issue
			/>
		</Grid>
	);
};

export default FilterInputInteger;
