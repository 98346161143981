import { Typography } from '@mui/material';
import { RdvSelectFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldSelectCellViewProps {
	value: RdvSelectFieldValue;
	optionsUiConfig: { label: string, value: string }[];
}
const FieldSelectCellView = ({ value, optionsUiConfig }: FieldSelectCellViewProps) => {
	if (value == null) return <Typography>-</Typography>;

	value = optionsUiConfig.find((valueConfig) => valueConfig.value === value)?.label ?? value

	return <Typography variant="body2">{value}</Typography>;
};

export default FieldSelectCellView;
