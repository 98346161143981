import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import GoogleMapReact from 'google-map-react';
import { useHistory, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ASSETS } from '@rdv-fo/app/assets';

import {
	discoverTransactionSupplyDetails,
	selectDiscoveredSupplyDetails,
	selectLoading,
	selectCalculatedSupplyPrice,
} from '@rdv-fo/store/slices/discoverySlice';
import { selectToken } from '@rdv-fo/store/slices/authSlice';
import GeneralLayout from '@rdv-fo/components/layout/types/GeneralLayout';
import PageWidth from '@rdv-fo/components/layout/PageWidth';
import CreateAndStartDirectTransactionForm from '@rdv-fo/components/transaction/CreateAndStartDirectTransactionForm';
import FieldDisplayValue from '@rdv-fo/components/fields/display/FieldDisplayValue';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import CardMedia from '@mui/material/CardMedia';
import { formatDatetimeShort } from '@rdv-fo/app/lib/datetime';
import {
	AvailabilityManagementKind,
	CurrencyKind,
	Field,
	FieldCategoryKind,
	InputKind,
	MatchTypeRoleKind,
	SupplyStatusKind,
	TransactionKind,
	MatchingToolType,
	SupplyPrice,
	DiscoveredSupplyVariant,
	DiscoveredSupply,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';

import RoomIcon from '@mui/icons-material/Room';
import { selectTransactionTypes } from '@rdv-fo/store/slices/transactionSlice';
import { formatPriceAmount } from '@rdv-fo/components/supply/supplyHelpers';
import ProviderPreview from '@rdv-fo/components/supply/ProviderPreview';
import ROUTES from '@rdv-fo/common/routes';
import { Form } from 'react-final-form';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { selectMySharedObjects, selectMySharedObjectTypes } from '@rdv-fo/store/slices/sharedObjectsSlice';
import { selectIsSupplyAvailable } from '@rdv-fo/store/slices/supplySlice';
import CreateShoppingCartMatchForm from '@rdv-fo/components/transaction/multi-direct/CreateShoppingCartMatchForm';
import ConditionalFieldDisplay from '@rdv-fo/components/fields/display/ConditionalFieldDisplay';
import { Select } from 'mui-rff';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const getVariantFields = (fields: Field[]): Field[] => {
	return fields.reduce((acc: any, field: Field) => {
		if (field.field_type.is_variant_identifier || field.field_type.is_variant_specific) {
			if (InputKind.MultiSelect === field?.field_type?.input_type) {
				const fieldValueWithUiConfig = field.value?.map(
					(value: any) =>
						field.field_type.ui_config.options?.find((option) => option.value === value)?.label ?? value
				);
				return [
					...acc,
					`${field.field_type?.ui_config?.label ?? field.field_type.name}:  ${fieldValueWithUiConfig ? fieldValueWithUiConfig?.join(', ') : null
					} `,
				];
			}
			if (InputKind.Select === field?.field_type?.input_type) {
				const fieldValueWithUiConfig =
					field.field_type.ui_config.options?.find((option) => option.value === field.value)?.label ??
					field.value;
				return [
					...acc,
					`${field.field_type?.ui_config?.label ?? field.field_type.name}:  ${fieldValueWithUiConfig} `,
				];
			}
			return [...acc, `${field.field_type?.ui_config?.label ?? field.field_type.name}:  ${field.value} `];
		}

		return acc;
	}, []);
};

const buildSupplyVariantOptions = (variants: (DiscoveredSupplyVariant | DiscoveredSupply)[]) => {
	return variants.map((variant: DiscoveredSupplyVariant | DiscoveredSupply) => {
		const variantFields = getVariantFields(variant.fields ?? []);
		return {
			label: (variantFields?.length ?? 0) > 0 ? variantFields?.join(' | ') : variant.id,
			value: variant.id,
		};
	}, []);
};

const DiscoveredTransactionSupplyDetailsPage = () => {
	const { supplyId, transactionTypeTechName } = useParams<{ supplyId: string; transactionTypeTechName: string }>();

	const history = useHistory();
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const discoveredSupply = useAppSelector(selectDiscoveredSupplyDetails);
	const discovering = useAppSelector(selectLoading);
	const isAvailable = useAppSelector(selectIsSupplyAvailable);

	const calculatedSupplyPrice = useAppSelector(selectCalculatedSupplyPrice) as SupplyPrice;
	const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
	const sharedObjects = useAppSelector(selectMySharedObjects);
	const token = useAppSelector(selectToken);
	const transactionTypes = useAppSelector(selectTransactionTypes);
	const currentTransactionType = transactionTypes?.find((tt) => tt.tech_name === transactionTypeTechName);
	const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(false);

	const contextTransactionType =
		transactionTypes?.length === 1
			? currentTransactionType
			: transactionTypes?.find((tt) => tt.tech_name === transactionTypeTechName);

	const isSingleDirectTransactionType = currentTransactionType?.type === TransactionKind.SingleDirect;
	const isMultipleDirectTransactionType = currentTransactionType?.type === TransactionKind.MultipleDirect;

	const rootType = contextTransactionType?.root_type as MatchingToolType;
	const isMatchConfigurationPricingEnabled =
		rootType?.match_type?.supply_type?.match_configurations?.[0]?.pricing_enabled ?? false;

	const mttFieldTypes = rootType?.fields ?? [];
	const contextMatchType = rootType?.match_type;
	const matchingUnitType = contextMatchType?.matching_unit_type ?? {};
	const providerFields = discoveredSupply?.provider?.fields ?? [];
	const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));

	if (!googleMapsApiKey) console.error('No google maps api key found');

	const discoverProviderDetailsEnabled = contextMatchType?.provider_discovery_enabled;
	const matchConfiguration = contextTransactionType?.match_configuration;

	useEffect(() => {
		if (token) {
			dispatch(
				discoverTransactionSupplyDetails({
					id_supply: supplyId,
					transaction_tech_name: transactionTypeTechName,
				})
			);
		}
	}, [supplyId, dispatch, token, transactionTypeTechName, matchConfiguration]);

	useEffect(() => {
		setTimeout(() => {
			setLoadingSpinnerAllowed(true);
		}, 500);
	}, []);

	const fieldDescription = findFieldByCategory(discoveredSupply?.fields, FieldCategoryKind.Description);

	const fieldName = findFieldByCategory(discoveredSupply?.fields, FieldCategoryKind.Name);

	const fieldMainImage = findFieldByCategory(discoveredSupply?.fields, FieldCategoryKind.MainImage);
	const fieldPrice = findFieldByCategory(discoveredSupply?.fields, FieldCategoryKind.BasePrice);
	const fieldDatetime = findFieldByCategory(discoveredSupply?.fields, FieldCategoryKind.MainDatetime);
	// TODO: use field category MAIN_LOCATION (when it becomes available) instead of fieldType
	const fieldLocation = discoveredSupply?.fields.find((f) => f.field_type.input_type === InputKind.Location);
	const isAvailabilityManagementNone =
		discoveredSupply?.availability?.availability_management_type === AvailabilityManagementKind.None;

	const dominantFields = [
		fieldDescription?.field_type?.tech_name,
		fieldName?.field_type?.tech_name,
		fieldMainImage?.field_type?.tech_name,
		fieldPrice?.field_type?.tech_name,
		fieldLocation?.field_type?.tech_name,
	];

	const discoveredSupplyFilteredFields = discoveredSupply?.fields.filter(
		(field) => !dominantFields.includes(field.field_type.tech_name)
	);

	const discoveredSupplyFilteredFieldValues = {};
	discoveredSupplyFilteredFields?.forEach((field) =>
		Object.assign(discoveredSupplyFilteredFieldValues, { [field?.field_type?.tech_name]: field?.value })
	);

	const formattedPrice = formatPriceAmount({
		currency: fieldPrice?.value?.currency ?? CurrencyKind.Eur,
		amount: fieldPrice?.value?.amount ?? 0,
	});

	const MapMarker = () => (
		<RoomIcon sx={{ color: theme.palette.secondary.main, marginLeft: '-10px', marginTop: '-10px' }} />
	);

	const navigateToVariantDetails = (variantId: string, transactionTypeTechName: string) => {
		history.push({
			pathname: ROUTES.DISCOVERED_TRANSACTION_SUPPLY_DETAILS.replace(':supplyId', variantId).replace(
				':transactionTypeTechName',
				transactionTypeTechName
			),
		});
	};

	let variants: (DiscoveredSupplyVariant | DiscoveredSupply)[] = [];

	if (discoveredSupply) variants = [discoveredSupply, ...(discoveredSupply?.variants ?? [])];

	return (
		<GeneralLayout>
			<PageWidth paddingTop={theme.spacing(2) as any} paddingBottom={theme.spacing(2) as any}>
				{discovering ? (
					<Grid container sx={{ height: 'calc(100vh - 64px)' }}>
						{loadingSpinnerAllowed ? <LoadingIndicator loading={discovering} /> : null}
					</Grid>
				) : (
					<>
						{(fieldName || fieldPrice || calculatedSupplyPrice?.unit_price) && (
							<Stack direction="column">
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={2}
									sx={{ mt: 6 }}
								>
									<Typography component="h1" variant="h1" align="left">
										{fieldName?.value}
									</Typography>

									{fieldPrice && isMatchConfigurationPricingEnabled && (
										<Typography component="h6" variant="h1">
											{formattedPrice}
										</Typography>
									)}
								</Stack>

								{isAvailabilityManagementNone ? (
									<Typography variant="body2"></Typography>
								) : isAvailable ? (
									<Typography variant="body2">In stock</Typography>
								) : (
									<Typography variant="body2">Out of stock</Typography>
								)}
							</Stack>
						)}
						<Grid container spacing={5} sx={{ alignItems: 'flex-start' }}>
							<Grid
								container
								sx={{ alignItems: 'flex-end', marginLeft: 2, marginRight: 2, marginBottom: 2 }}
							>
								<Grid item xs={6}>
									<Box>
										{fieldDatetime?.value && (
											<Typography>{formatDatetimeShort(fieldDatetime?.value)}</Typography>
										)}
									</Box>
								</Grid>
							</Grid>
							<Grid item xs={12} lg={8}>
								<Grid container>
									<Grid item xs={12}>
										{fieldMainImage && (
											<CardMedia
												image={fieldMainImage?.value?.url ?? ASSETS.RANDEVU.IMAGE_UNAVAILABLE}
												sx={{
													borderRadius: 1.5,
													minHeight: `calc(0.6*100vh)`,
													marginBottom: 4,
												}}
											/>
										)}
									</Grid>
									{fieldLocation?.value && (
										<Grid item sx={{ height: 80, borderRadius: 1.5, marginBottom: 4 }} xs={12}>
											<GoogleMapReact
												yesIWantToUseGoogleMapApiInternals
												bootstrapURLKeys={{
													key: googleMapsApiKey ?? '',
													libraries: ['places'],
												}}
												defaultCenter={{
													lat: fieldLocation?.value?.lat,
													lng: fieldLocation?.value?.lng,
												}}
												defaultZoom={14}
											>
												<MapMarker />
											</GoogleMapReact>
										</Grid>
									)}
									<Grid item sx={{ mb: 2 }} xs={12}>
										<Typography sx={{ mb: 1, fontWeight: 'bold' }} component="h4">
											{fieldDescription?.value}
										</Typography>
										<Grid container direction="column" spacing={1}>
											{discoveredSupplyFilteredFields?.map((field) => {
												return (
													<Grid key={field?.field_type.tech_name} item>
														<ConditionalFieldDisplay
															key={field?.field_type?.tech_name}
															fields={discoveredSupplyFilteredFields}
															values={discoveredSupplyFilteredFieldValues}
															conditions={field?.field_type?.conditions ?? []}
														>
															<FieldDisplayValue
																label={
																	field?.field_type?.ui_config?.label ??
																	field?.field_type.name
																}
																labelProps={{
																	width: isScreenSmall ? 4 : 2,
																}}
																optionsUiConfig={
																	field?.field_type?.ui_config?.options as any
																}
																fieldTechName={field?.field_type.tech_name}
																inputType={field?.field_type.input_type}
																inputOptions={field?.field_type.input_options}
																value={field.value}
															/>
														</ConditionalFieldDisplay>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} lg={4}>
								<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
									<ProviderPreview title="" fields={[]} removeBorder>
										<>
											{(discoveredSupply?.variants?.length ?? 0) > 0 && (
												<Form
													onSubmit={() => { }}
													initialValues={{ variant: discoveredSupply?.id }}
												>
													{({ handleSubmit, values, initialValues }) => {
														const initVariant = initialValues?.variant;
														const selectedVariant = values?.variant;

														// FIXME: very dirty workaround to change route if variant is selected
														if (initVariant !== selectedVariant)
															navigateToVariantDetails(
																selectedVariant,
																transactionTypeTechName
															);

														return (
															<form
																onSubmit={handleSubmit}
																method="POST"
																id="confirmation-form"
															>
																<Select
																	id="variant-select"
																	label="Variant"
																	name="variant"
																	data={buildSupplyVariantOptions(variants)}
																/>
															</form>
														);
													}}
												</Form>
											)}

											{isSingleDirectTransactionType && (
												<CreateAndStartDirectTransactionForm
													supplyId={supplyId}
													supplyPrice={calculatedSupplyPrice}
													sharedObjectTypes={sharedObjectTypes}
													sharedObjects={sharedObjects}
													transactionTechName={transactionTypeTechName}
													isMatchConfigurationPricingEnabled={
														isMatchConfigurationPricingEnabled
													}
													matchParameterTypes={
														matchConfiguration?.match_parameter_types ?? []
													}
													matchingUnitType={matchingUnitType}
													isGuest={
														MatchTypeRoleKind.Guest === contextTransactionType?.my_role
													}
													requestFieldTypes={mttFieldTypes}
													buttonLabel={contextTransactionType?.ui_config?.initiate}
												/>
											)}
											{!isSingleDirectTransactionType && (
												<CreateShoppingCartMatchForm
													supplyId={supplyId}
													supplyPrice={calculatedSupplyPrice}
													sharedObjectTypes={sharedObjectTypes}
													sharedObjects={sharedObjects}
													transactionTechName={transactionTypeTechName}
													isMatchConfigurationPricingEnabled={
														isMatchConfigurationPricingEnabled
													}
													matchConfigurationTechName={matchConfiguration?.tech_name}
													matchParameterTypes={
														matchConfiguration?.match_parameter_types ?? []
													}
													matchingUnitType={matchingUnitType}
													myRole={contextTransactionType?.my_role}
													requestFieldTypes={mttFieldTypes}
													price={formattedPrice}
													buttonLabel={contextTransactionType?.ui_config?.initiate}
												/>
											)}
										</>
									</ProviderPreview>

									{providerFields?.length > 0 && (
										<ProviderPreview
											title="Provider"
											fields={providerFields}
											labelWidth={4}
											removeBorder={true}
										>
											<>
												{discoverProviderDetailsEnabled && discoveredSupply && (
													<Button
														component={RouterLink}
														color="inherit"
														to={routeBuilder(
															ROUTES.DISCOVERED_TRANSACTION_PROVIDER_DETAILS,
															[
																[':transactionTypeTechName', transactionTypeTechName],
																[
																	':providerId',
																	discoveredSupply.provider.id_participant,
																],
															]
														)}
													>
														See details
													</Button>
												)}
											</>
										</ProviderPreview>
									)}
								</Stack>
							</Grid>
						</Grid>
					</>
				)}
			</PageWidth>
		</GeneralLayout>
	);
};

export default DiscoveredTransactionSupplyDetailsPage;
