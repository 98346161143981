import { Button, FormHelperText, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import isFileSizeAcceptable from '@rdv-fo/app/lib/fileSizeValidator';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { RdvFileFieldValue } from '@rdv-fo/services/randevuApi/types/field/';
import { Field } from 'react-final-form';

import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';

const missingValue = ['', undefined, null];

interface ImagePreviewProps {
	src: string;
	name?: string;
	disabled?: boolean;
	onRemove: () => void;
}
const DocumentPreview = ({ src, name, disabled = false, onRemove }: ImagePreviewProps) => {
	return (
		<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
			<Grid item>
				<Typography
					color="textSecondary"
					variant="body2"
					display="inline"
					component="a"
					target="_blank"
					rel="noopener"
					href={src}
				>
					{name}
				</Typography>
				<Button
					variant="text"
					color="primary"
					onClick={onRemove}
					size="small"
					startIcon={<ClearIcon />}
					sx={{ marginLeft: 2 }}
					disabled={disabled}
				>
					Remove
				</Button>
			</Grid>
		</Grid>
	);
};

interface DocumentSetInputOptions {
	required: boolean;
	maxSize?: number;
	maxDocs?: number;
	minDocs?: number;
}
const validate = (value: any, options: DocumentSetInputOptions) => {
	const { maxSize, maxDocs, required, minDocs } = options;

	if (!required && missingValue.includes(value)) return undefined;

	if (required && missingValue.includes(value)) return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	if (maxDocs && Object.keys(value)?.length > maxDocs)
		return VALIDATION_MESSAGE.MAX_NUMBER_OF_FILES_EXCEEDED.replace('{files_count}', String(maxDocs));
	if (minDocs && Object.keys(value)?.length < minDocs)
		return VALIDATION_MESSAGE.MIN_NUMBER_OF_FILES_NOT_SATISFIED.replace('{files_count}', String(minDocs));

	for (const file of value) {
		if (maxSize && file && !isFileSizeAcceptable({ maxAllowedSize: maxSize, fileSize: file.size }))
			return VALIDATION_MESSAGE.MAX_FILE_SIZE_VIOLATION.replace('{max_size}', String(maxSize));
	}

	return undefined;
};

const getAcceptedDocumentFormats = (allowedTypes: string[] | undefined) =>
	allowedTypes?.map((type) => '.' + type.toLowerCase()).join(', ');

interface FieldInputDocumentSetProps extends FieldInputBaseProps {
	allowedTypes: string[];
	maxSize?: number;
	maxDocs?: number;
	minDocs?: number;
}

export const FieldInputDocumentSet = ({
	techName,
	label,
	labelProps,
	disabled = false,
	required = false,
	allowedTypes,
	maxSize,
	maxDocs,
	minDocs,
}: FieldInputDocumentSetProps) => {
	const theme = useTheme();
	const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		const element = event.target as HTMLInputElement;
		element.value = '';
	};

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>

			<div>
				{/** FIXME: use RFF ARRAY instead of handling the array logic ourselves */}
				<Field name={techName} validate={(value) => validate(value, { required, maxSize, maxDocs, minDocs })}>
					{({ input: { value, onChange, ...input }, meta }) => {
						const { submitError, dirtySinceLastSubmit, error, pristine, submitFailed } = meta;

						const acceptedDocumentFormats = getAcceptedDocumentFormats(allowedTypes);

						const handleRemoveItem = (index: number) => {
							const fileList: (File | RdvFileFieldValue)[] = Array.from(value);

							fileList.splice(index, 1);

							const endArrayState: (File | RdvFileFieldValue)[] = [];
							for (let i = 0; i < fileList.length; i++) {
								const file: any = fileList[i];
								endArrayState.push(file);
							}

							onChange(endArrayState);
						};

						const handleAddItems = (files: FileList | null) => {
							if (files === null) return;

							const filesArray: File[] = Array.from(value);
							const newFilesArr = Array.from(files);

							const allFilesArray = filesArray.concat(newFilesArr);
							const endStateArray: (File | RdvFileFieldValue)[] = [];

							for (let i = 0; i < allFilesArray.length; i++) {
								const file: File = allFilesArray[i];
								endStateArray.push(file);
							}

							onChange(endStateArray);
						};

						const noDocumentSelected = value === null || value === undefined || value.length === 0;
						const documentsExist = value?.length > 0;

						return (
							<>
								{((error && !pristine) || (error && submitFailed)) && (
									<FormHelperText error={true}>{error}</FormHelperText>
								)}
								{submitError && !dirtySinceLastSubmit && (
									<FormHelperText error={true}>{submitError}</FormHelperText>
								)}
								<Typography variant="body2" color="textSecondary">
									Allowed formats: {acceptedDocumentFormats}
								</Typography>
								{maxSize && (
									<Typography color="textSecondary" variant="body2">
										{`Maximum allowed file size: ${maxSize} MB`}
									</Typography>
								)}
								{maxDocs && (
									<Typography color="textSecondary" variant="body2">
										{`Maximum number of documents: ${maxDocs}`}
									</Typography>
								)}
								<br />

								{noDocumentSelected && (
									<>
										<Button
											variant="outlined"
											color="primary"
											component="label"
											size="small"
											disabled={disabled}
											sx={{ marginTop: 2 }}
											startIcon={<CloudUploadOutlinedIcon />}
										>
											Choose documents
											<input
												{...input}
												id={techName}
												multiple
												name={techName}
												type="file"
												hidden
												onClick={onInputClick}
												onChange={(e) => handleAddItems(e.target.files)}
												accept={acceptedDocumentFormats}
											/>
										</Button>
										<br />
										<br />
									</>
								)}

								{documentsExist && (
									<>
										<Grid container spacing={3} direction="column">
											{value.map((img: File | RdvFileFieldValue, index: number) => {
												let src = '';
												src = img instanceof File ? URL.createObjectURL(img) : img.url;

												let name = '';
												name = img instanceof File ? img.name : img.file_name;

												return (
													<Grid item key={index}>
														<DocumentPreview
															src={src}
															name={name}
															onRemove={() => handleRemoveItem(index)}
														/>
													</Grid>
												);
											})}
										</Grid>

										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignContent: 'flex-start',
												gap: theme.spacing(8),
											}}
										>
											<Button
												variant="outlined"
												color="primary"
												component="label"
												size="small"
												sx={{ marginTop: 2 }}
												disabled={disabled}
												startIcon={<CloudUploadOutlinedIcon />}
											>
												Choose more documents
												<input
													{...input}
													id={techName}
													multiple
													name={techName}
													type="file"
													onClick={onInputClick}
													hidden
													onChange={(e) => handleAddItems(e.target.files)}
													accept={acceptedDocumentFormats}
												/>
											</Button>

											<Button
												sx={{ marginTop: 2.5 }}
												variant="text"
												color="primary"
												startIcon={<ClearIcon />}
												onClick={(e) => {
													if (value instanceof FileList) return onChange('');

													return onChange(null);
												}}
												size="small"
												disabled={disabled}
											>
												Remove all
											</Button>
										</div>
									</>
								)}
							</>
						);
					}}
				</Field>
			</div>
		</Stack>
	);
};

export default FieldInputDocumentSet;
