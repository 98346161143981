import React from 'react';

import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';

import { TextField } from 'mui-rff';

import FormLabel from '@mui/material/FormLabel';

import ConfirmationModal from '@rdv-fo/components/common/ConfirmationModal';
import {
	selectCurrentUser,
	selectReauthenticationStatus,
	reauthenticate,
	signOut,
	reauthenticationAborted,
} from '@rdv-fo/store/slices/authSlice';
import ROUTES from '@rdv-fo/common/routes';
import { RDV_BLACK } from '@rdv-fo/styles/theme';

const ReauthenticateModal = () => {
	const dispatch = useAppDispatch();
	const currentUser = useAppSelector(selectCurrentUser);
	const reauthenticationStatus = useAppSelector(selectReauthenticationStatus);

	const onConfirm = async (values: { password: string }) => {
		const resolvedAction = await dispatch(
			reauthenticate({ email: currentUser?.email, password: values?.password })
		);
		if (!resolvedAction) return { password: 'Incorrect password.' };
	};

	const onCancel = async () => {
		dispatch(reauthenticationAborted());
		dispatch(signOut({ options: { redirectTo: ROUTES.LANDING_PAGE } }));
	};

	return (
		<ConfirmationModal
			open={reauthenticationStatus === 'required'}
			title="Please confirm your password to continue"
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmDisabled={false}
		>
			<FormLabel htmlFor="password" sx={{ color: RDV_BLACK }}>
				Password
			</FormLabel>
			<TextField
				// TODO: Workaround for 'Enter' key not working
				onKeyPress={(event) => {
					if (event.key === 'Enter') {
						const form = document.getElementById('confirmation-form');
						form?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
					}
				}}
				margin="dense"
				variant="outlined"
				id="password"
				name="password"
				type="password"
				placeholder="Your password"
				fullWidth
				autoComplete="current-password"
				autoFocus
			/>
		</ConfirmationModal>
	);
};

export default ReauthenticateModal;
