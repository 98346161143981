import { Typography } from '@mui/material';
import { RdvIntegerFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldIntegerCellViewProps {
	value: RdvIntegerFieldValue;
}
const FieldIntegerCellView = ({ value }: FieldIntegerCellViewProps) => {
	if (value === null || value === undefined) return <Typography>-</Typography>;

	return <Typography variant="body2">{(value)}</Typography>;
};

export default FieldIntegerCellView;
