import unifyFieldValue from '../../helpers/fieldHelper';
import {
	MutationCreateSharedObjectArgs,
	MutationUpdateSharedObjectArgs,
	Object,
	ObjectType,
	QueryMySharedObjectsArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import * as sharedObjectQueries from './sharedObjectQueries';
import * as sharedObjectMutations from './sharedObjectMutations';

class ObjectsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async mySharedObjectTypes(): Promise<{ object_types: ObjectType[] | null; errors: any }> {
		const payload = sharedObjectQueries.mySharedObjectTypes();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { object_types: data?.mySharedObjectTypes, errors };
	}
	async createSharedObject({
		tech_name,
		is_public
	}: MutationCreateSharedObjectArgs): Promise<{ object: Object | null; errors: any }> {
		const payload = sharedObjectMutations.createSharedObject({ tech_name, is_public });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { object: data?.createSharedObject, errors };
	}
	async updateSharedObject({
		fields,
		id,
	}: MutationUpdateSharedObjectArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = sharedObjectMutations.updateSharedObject({ id, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { updated: data?.updateSharedObject, errors };
	}

	async mySharedObjects({
		where = {},
	}: QueryMySharedObjectsArgs): Promise<{ objects: Object[] | null; errors: any }> {
		const payload = sharedObjectQueries.mySharedObjects({ where });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const sharedObjects = data?.mySharedObjects;

		const sharedObjectsWithUnifiedFields = sharedObjects?.map((so: Object) => {
			so.fields = unifyFieldValue(so?.fields ?? []);

			return so;
		});

		return { objects: sharedObjectsWithUnifiedFields, errors };
	}
}

export default ObjectsService;
