import { Stack, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { buildConnectionsTableColumns, buildConnectionsTableRows } from '@rdv-fo/app/lib/tableHelpers';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import ROUTES from '@rdv-fo/common/routes';

const NoDataView = () => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
			<Typography variant="subtitle2" sx={{ mt: 2 }}>
				No connections yet
			</Typography>
		</Stack>
	);
};

interface ConnectionsTableProps {
	connectionTechName: string;
	connections: any;
	initialColumnVisibility: any;
	loading: boolean;
	onColumnVisibilityChanged: (columnVisibility: any) => void;
}

const ConnectionsTable = ({
	connectionTechName,
	connections = [],
	initialColumnVisibility,
	loading,
	onColumnVisibilityChanged,
}: ConnectionsTableProps) => {
	const history = useHistory();
	const connectionColumns = buildConnectionsTableColumns(connections?.[0]?.participant?.fields);

	const connectionRows = buildConnectionsTableRows(connections);
	const initialVisibilityHideEverything = {};
	const initialVisibilityShowEverything = {};

	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
		connectionColumns.map((cc) => {
			Object.assign(initialVisibilityHideEverything, { [cc.accessorKey as string]: false });
			Object.assign(initialVisibilityShowEverything, { [cc.accessorKey as string]: true });
		});
		const initialVisibilityShowOrHide =
			Object.values(initialColumnVisibility).length > 0
				? initialVisibilityHideEverything
				: initialVisibilityShowEverything;
		setColumnVisibility({
			...initialVisibilityShowOrHide,
			...initialColumnVisibility,
			status: true,
			_details: true,
			_id: false,
		});
	}, [connections?.[0]?.participant?.fields, connections, connectionColumns.length]);

	useEffect(() => {
		Object.values(columnVisibility).length > 0 && onColumnVisibilityChanged(columnVisibility);
	}, [columnVisibility]);

	return (
		<>
			<MaterialReactTable
				initialState={{
					pagination: { pageSize: 50, pageIndex: 0 },
				}}
				state={{
					columnVisibility: { ...columnVisibility },
					isLoading: loading
				}}
				columns={connectionColumns}
				enablePinning={false}
				data={connectionRows}
				enableTableHead={true}
				enableSorting={false}
				enablePagination={false}
				enableDensityToggle={false}
				onColumnVisibilityChange={setColumnVisibility}
				enableGlobalFilter={false}
				enableColumnFilters={false} //disable all column filters
				enableColumnFilterModes={false}
				enableColumnActions={false}
				rowCount={connections?.length}
				muiTableBodyProps={{
					children: connections?.length < 1 ? <NoDataView /> : null,
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={({ row }) => ({
					sx: {
						cursor: 'pointer',
						'&:hover': {
							cursor: 'pointer',
							backgroundColor: RDV_NEUTRAL_20,

						},
					},
					onClick: () => {
						const id = row?.original?._id;
						// FIXME:: make row anchor element
						history.push(ROUTES.CONNECTION_DETAILS.replace(":connectionTechName", connectionTechName).replace(':id', id))
					},
				})}

				enableRowActions={false}
				enableFullScreenToggle={false}
				enableRowOrdering={false}
				enableHiding={true}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				renderBottomToolbar={
					connections?.length > 0 &&
					(() => {
						let label = `${connections?.length} connections`;
						if (connections?.length === 1) label = `${connections?.length} connection`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default ConnectionsTable;
