import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import ROUTES from '@rdv-fo/common/routes';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import TableFrame from '@rdv-fo/components/common/TableFrame';
import MatchStatusChip from '@rdv-fo/components/common/chip/MatchStatusChip';
import { Transaction } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const Header = () => {
	return (
		<>
			<Grid item xs={2} sx={{ paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}>
				<Typography
					variant="caption"
					sx={{
						textTransform: 'uppercase',
						paddingTop: 4,
						paddingBottom: 4,
						paddingLeft: 0,
						paddingRight: 0,
					}}
				>
					Created at
				</Typography>
			</Grid>
			<Grid item xs={2} sx={{ paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}>
				<Typography
					variant="caption"
					sx={{
						textTransform: 'uppercase',
						paddingTop: 4,
						paddingBottom: 4,
						paddingLeft: 0,
						paddingRight: 0,
					}}
				>
					Status
				</Typography>
			</Grid>
			<Grid item xs={3} sx={{ paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}>
				<Typography
					variant="caption"
					sx={{
						textTransform: 'uppercase',
						paddingTop: 4,
						paddingBottom: 4,
						paddingLeft: 0,
						paddingRight: 0,
					}}
				>
					ID
				</Typography>
			</Grid>
			<Grid item xs={3} sx={{ paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}></Grid>

			<Grid item xs={2} sx={{ paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}>
				<Typography
					variant="caption"
					sx={{
						textTransform: 'uppercase',
						paddingTop: 4,
						paddingBottom: 4,
						paddingLeft: 0,
						paddingRight: 0,
					}}
				></Typography>
			</Grid>
			<Grid item xs={12} sx={{ padding: 0 }}>
				<Divider />
			</Grid>
		</>
	);
};

interface TableRowProps {
	id: string;
	status: string;
	createdAt: string;
	transactionTypeTechName: string;
	parentTransactionId: string;
}
const TableRow = ({ id, status, createdAt, transactionTypeTechName, parentTransactionId }: TableRowProps) => {
	const orderDetailsRoute = routeBuilder(ROUTES.REVERSE_AUCTION_TRANSACTION_DETAILS, [
		[':transactionTypeTechName', transactionTypeTechName],
		[':transactionId', id],
	]) + `?parentTransactionId=${parentTransactionId}`;

	const created_at = new Date(createdAt);
	const created_at_formatted =
		created_at &&
		created_at
			.toLocaleDateString('de-DE', {
				hour: '2-digit',
				minute: '2-digit',
			})
			.replaceAll('.', '/')
			.replace(',', '');

	return (
		<>
			<Grid id={`row-match-${id}`} container item xs={12} spacing={0} alignItems="center">
				<Grid item xs={2}>
					<Typography>{created_at_formatted}</Typography>
				</Grid>
				<Grid item xs={2}>
					<MatchStatusChip status={status} />
				</Grid>
				<Grid item xs={3}>
					<Typography>{id}</Typography>
				</Grid>

				<Grid item xs={3} />

				<Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							color="primary"
							size="small"
							variant="outlined"
							sx={{ marginLeft: 1.5 }}
							component={RouterLink}
							to={orderDetailsRoute}
						>
							Details
						</Button>
					</div>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</>
	);
};

interface SubTransactionsTable {
	parentTransactionId: string;
	loading?: boolean;
	transactionTypeTechName: string;
	items: Transaction[];
}
const SubTransactionsTable = ({ items, loading = false, transactionTypeTechName, parentTransactionId }: SubTransactionsTable) => {
	return (
		<>
			<TableFrame id="sub-transactions" loading={loading} skipCounter={true} header={<Header />}>
				{items?.map((subTransaction) => (
					<TableRow
						key={subTransaction.id}
						id={subTransaction.id}
						parentTransactionId={parentTransactionId}
						transactionTypeTechName={transactionTypeTechName}
						createdAt={subTransaction.created_at}
						status={subTransaction.state?.status ?? ''}
					/>
				))}
			</TableFrame>
		</>
	);
};

export default SubTransactionsTable;
