import {
	Connection,
	ConnectionType,
	MutationAcceptConnectionArgs,
	MutationCreateConnectionArgs,
	MutationDeclineConnectionArgs,
	MutationUpdateConnectionArgs,
	Participant,
	QueryDiscoverConnectionParticipantsArgs,
	QueryMyConnectionArgs,
	QueryMyConnectionsArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import * as connectionsQueries from './connectionQueries';
import * as connectionMutations from './connectionMutations';
import unifyFieldValue from '@rdv-fo/services/randevuApi/helpers/fieldHelper';

class ConnectionsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async myConnections({ where }: QueryMyConnectionsArgs): Promise<{ connections: Connection[]; errors: any }> {
		const payload = connectionsQueries.myConnections({ where });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const connections = data?.myConnections?.map((connection: Connection) => {
			const fields = unifyFieldValue(connection?.participant?.fields);
			if (connection) connection.participant.fields = fields;

			return connection;
		});

		return { connections: connections, errors };
	}
	async discoverConnectionParticipants({
		where,
		connection_tech_name,
	}: QueryDiscoverConnectionParticipantsArgs): Promise<{ participants: Participant[]; errors: any }> {
		const payload = connectionsQueries.discoverConnectionParticipants({ where, connection_tech_name });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const participants = data?.discoverConnectionParticipants?.map((dcp: Participant) => {
			dcp.fields = unifyFieldValue(dcp.fields);

			return dcp;
		});

		return { participants, errors };
	}
	async myConnectionTypes(): Promise<{ connection_types: ConnectionType[]; errors: any }> {
		const payload = connectionsQueries.myConnectionTypes();

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { connection_types: data?.myConnectionTypes, errors };
	}
	async myConnection({ id }: QueryMyConnectionArgs): Promise<{ connection: Connection; errors: any }> {
		const payload = connectionsQueries.myConnection({ id });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let connection = data?.myConnection;

		if (connection) {
			const connection_fields = unifyFieldValue(connection.fields);
			connection.fields = connection_fields;
			const participant_fields = unifyFieldValue(connection.participant?.fields);
			connection.participant.fields = participant_fields;
		}

		return { connection, errors };
	}

	async updateConnection({
		id,
		fields,
	}: MutationUpdateConnectionArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = connectionMutations.updateConnection({ id, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { updated: data?.updateConnection, errors };
	}
	async createConnection({
		connection_tech_name,
		id_participant,
		fields,
	}: MutationCreateConnectionArgs): Promise<{ connection: Connection | null; errors: any }> {
		const payload = connectionMutations.createConnection({ connection_tech_name, id_participant, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { connection: data?.createConnection, errors };
	}

	async acceptConnection({ id }: MutationAcceptConnectionArgs): Promise<{ accepted: boolean | null; errors: any }> {
		const payload = connectionMutations.acceptConnection({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { accepted: data?.acceptConnection, errors };
	}
	async declineConnection({ id }: MutationDeclineConnectionArgs): Promise<{ declined: boolean | null; errors: any }> {
		const payload = connectionMutations.declineConnection({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { declined: data?.declineConnection, errors };
	}
}

export default ConnectionsService;
