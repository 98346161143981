import React from 'react';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import RandevuInputLabel from '@rdv-fo/components/common/RandevuInputLabel';
import { loadCurrentUser, verifyParticipantAccount } from '@rdv-fo/store/slices/authSlice';
import { useState } from 'react';
import { useAppDispatch } from '@rdv-fo/store/configureStore';

const hasNumber = (myString) => {
	return /\d/.test(myString);
};

const hasUpperCase = (myString) => {
	return /[A-Z]/.test(myString);
};

const ConfirmParticipantAccountForm = ({ email, token, title, description, form }) => {
	const dispatch = useAppDispatch();

	const [showPassword, toggleShowPassword] = useState(false);

	const doSubmit = async (values) => {
		await dispatch(
			verifyParticipantAccount({
				password: values.newPassword,
				email,
				verification_token: token,
			})
		);
		await dispatch(loadCurrentUser());
	};

	const fieldProps = {
		validate: (value) => {
			// workaround until we add password strength meter
			if (value && !value.trim()) return 'Please choose your password.';
			if (value && !hasNumber(value)) return 'Please choose a password which contains at least 1 number.';
			if (value && !hasUpperCase(value))
				return 'Please choose a password which contains at least 1 capital letter.';
			if (value && value.length < 8) return 'Please choose a password with minimum 8 characters.';
		},
	};
	return (
		<Container maxWidth="sm">
			<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
				<Typography component="h1" variant="h1">
					{title}
				</Typography>
				<Typography variant="body1" color="textSecondary" component="h2">
					{description}
				</Typography>
			</Stack>

			<br />
			<br />
			<Form onSubmit={doSubmit}>
				{({ handleSubmit, submitting, submitFailed, dirtySinceLastSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<RandevuInputLabel text={form?.field_password?.label} htmlFor="newPassword" />
									<TextField
										name="newPassword"
										id="newPassword"
										type={showPassword ? 'text' : 'password'}
										variant="outlined"
										margin="normal"
										size="small"
										required
										autoComplete="newPassword"
										placeholder={form?.field_password?.placeholder}
										disabled={submitting}
										fieldProps={fieldProps}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => toggleShowPassword(!showPassword)}
														onMouseDown={() => toggleShowPassword(!showPassword)}
														size="large"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<LoadingButton
										loading={submitting}
										disabled={submitting || (submitFailed && !dirtySinceLastSubmit)}
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
									>
										{form?.button_submit?.label}
									</LoadingButton>
								</Grid>
							</Grid>
						</form>
					);
				}}
			</Form>
		</Container>
	);
};

export default ConfirmParticipantAccountForm;
