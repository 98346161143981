import { createSlice } from '@reduxjs/toolkit';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { FORM_ERROR } from 'final-form';
import { ParticipantStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

import ROUTES from '@rdv-fo/common/routes';
import API_STATUS from '@rdv-fo/services/randevuApi/enums/apiStatus';
import {
	isBackofficeUser,
	isDuplicate,
	isSessionExpired,
	hasSubmissionErrors,
	isFetchError,
	isInvalidCredentials,
	isInvalidObjectState,
} from '@rdv-fo/services/randevuApi/errors/errorHelper';
import { sleep } from '@rdv-fo/services/sleep';
import { loadMyTransactionTypes, setSelectedTransactionType, transactionTypesLoaded } from './transactionSlice';
import {
	goToRoute,
	redirectExternal,
	sendSuccessToasty,
	sendUnexpectedErrorToasty,
	sendWarningToasty,
} from './uiSlice';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import THUNKS from '@rdv-fo/store/thunkMap';
import randevu from '@rdv-fo/services/randevuApi';
import { loadMySupplyTypes } from './supplySlice';
import { isEmpty } from 'lodash';

const slice = createSlice({
	name: 'auth',
	initialState: {
		currentUser: null,
		token: '',
		loading: false,
		errors: null,
		userSignUpStatus: API_STATUS.IDLE,
		verifyParticipantAccountStatus: API_STATUS.IDLE,
		connectToStripeAccountStatus: API_STATUS.IDLE,
		onboardingFields: undefined,
		reauthenticationStatus: 'idle',
	},
	reducers: {
		signInUserRequested: (auth, action) => {
			auth.loading = true;
		},
		signInUserFailed: (auth, action) => {
			auth.errors = action.payload;
			auth.loading = false;
			auth.token = '';
		},
		userSignedIn: (auth, action) => {
			auth.token = action.payload;
			auth.errors = null;
		},
		signInUserAsGuestRequested: (auth, action) => {
			auth.loading = true;
		},
		signInUserAsGuestFailed: (auth, action) => {
			auth.errors = action.payload;
			auth.loading = false;
			auth.token = '';
		},
		userSignedInAsGuest: (auth, action) => {
			auth.currentUser = null;
			auth.token = action.payload;
			auth.errors = null;
		},
		signUpUserRequested: (auth, action) => {
			auth.loading = true;
			auth.errors = null;
			auth.userSignUpStatus = API_STATUS.LOADING;
		},
		signUpUserFailed: (auth, action) => {
			auth.loading = false;
			auth.errors = action.payload;
			auth.userSignUpStatus = API_STATUS.FAILED;
		},
		userSignedUp: (auth, action) => {
			auth.loading = false;
			auth.userSignUpStatus = action.payload;
		},
		signOutUserRequested: (auth, action) => {
			auth.loading = true;
		},
		userSignedOut: (auth, action) => {
			auth.currentUser = null;
			auth.loading = false;
			auth.errors = null;
			auth.token = '';
		},
		userSignUpStatusChanged: (auth, action) => {
			auth.userSignUpStatus = action.payload;
		},
		loadCurrentUserRequested: (auth, action) => {
			auth.loading = true;
			auth.errors = null;
		},
		currentUserLoaded: (auth, action) => {
			auth.loading = false;
			auth.errors = null;
			auth.currentUser = action.payload;
		},
		loadCurrentUserFailed: (auth, action) => {
			auth.loading = false;
			auth.errors = action.payload;
		},
		verifyParticipantAccountRequested: (auth, action) => {
			auth.loading = true;
			auth.verifyParticipantAccountStatus = API_STATUS.LOADING;
		},
		verifyParticipantAccountFailed: (auth, action) => {
			auth.loading = false;
			auth.error = action.payload;
			auth.verifyParticipantAccountStatus = API_STATUS.FAILED;
		},
		userAccountVerified: (auth, action) => {
			auth.loading = false;
			auth.verifyParticipantAccountStatus = API_STATUS.SUCCEEDED;
		},
		verifyParticipantAccountStatusChanged: (auth, action) => {
			auth.verifyParticipantAccountStatus = action.payload;
		},
		updateMyFieldsRequested: (auth, action) => {
			auth.errors = null;
			auth.loading = true;
			auth.onboardingFieldStatus = API_STATUS.LOADING;
		},
		updateMyFieldsFailed: (auth, action) => {
			auth.errors = action.payload;
			auth.loading = false;
			auth.onboardingFieldStatus = API_STATUS.FAILED;
		},
		myFieldsOnboarded: (auth, action) => {
			auth.errors = null;
			auth.loading = false;
			auth.onboardingFieldStatus = API_STATUS.SUCCEEDED;
		},
		requestPasswordResetRequested: (auth) => {
			auth.errors = null;
		},
		requestPasswordResetFailed: (auth, action) => {
			auth.errors = action.payload;
		},
		passwordReset: (auth) => {
			auth.errors = null;
		},
		passwordResetRequested: (auth) => {
			auth.errors = null;
		},
		passwordResetFailed: (auth, action) => {
			auth.errors = action.payload;
		},

		stripeConnectRequested: (auth) => {
			auth.connectToStripeAccountStatus = API_STATUS.LOADING;
		},
		stripeConnectFailed: (auth) => {
			auth.connectToStripeAccountStatus = API_STATUS.FAILED;
		},
		stripeConnectLinked: (auth) => {
			auth.connectToStripeAccountStatus = API_STATUS.FINISHED;
		},
		triggerManualParticipantOnboardingTransitionRequested: (auth, action) => {
			auth.loading = true;
			auth.errors = null;
		},
		triggerManualParticipantOnboardingTransitionFailed: (auth, action) => {
			auth.loading = false;
			auth.errors = action.payload;
		},
		manualParticipantOnboardingTransitionTriggered: (auth, action) => {
			auth.loading = false;
			auth.errors = null;
		},
		reauthenticationRequired: (auth) => {
			auth.reauthenticationStatus = 'required';
		},
		reauthenticationAborted: (auth) => {
			auth.reauthenticationStatus = 'idle';
		},
		userReauthenticated: (auth) => {
			auth.loading = false;
			auth.reauthenticationStatus = 'idle';
			auth.errors = null;
		},
		changeMyEmailRequested: (auth) => {
			auth.errors = null;
		},
		changeMyEmailFailed: (auth, action) => {
			auth.loading = false;
			auth.errors = action.payload;
		},
		myEmailChanged: (auth, action) => {
			auth.loading = false;
			auth.errors = null;
			auth.currentUser.email = action.payload.new_email;
		},
		closeMyParticipantAccountRequested: (auth) => {
			auth.errors = null;
		},
		closeMyParticipantAccountFailed: (auth, action) => {
			auth.loading = false;
			auth.errors = action.payload;
		},
		myParticipantAccountClosed: (auth, action) => {
			auth.loading = false;
			auth.errors = null;
		},
		changeMyPasswordRequested: (auth) => {
			auth.errors = null;
		},
		changeMyPasswordFailed: (auth, action) => {
			auth.loading = false;
			auth.errors = action.payload;
		},
		myPasswordChanged: (auth) => {
			auth.loading = false;
			auth.errors = null;
		},
	},
});

export const {
	changeMyEmailRequested,
	changeMyEmailFailed,
	myEmailChanged,
	changeMyPasswordRequested,
	changeMyPasswordFailed,
	myPasswordChanged,
	signInUserRequested,
	signInUserFailed,
	userSignedIn,
	signInUserAsGuestRequested,
	signInUserAsGuestFailed,
	userSignedInAsGuest,
	signUpUserRequested,
	signUpUserFailed,
	userSignedUp,
	signOutUserRequested,
	userSignedOut,
	userSignUpStatusChanged,
	loadCurrentUserRequested,
	loadCurrentUserFailed,
	currentUserLoaded,
	verifyParticipantAccountRequested,
	verifyParticipantAccountFailed,
	userAccountVerified,
	verifyParticipantAccountStatusChanged,
	updateMyFieldsRequested,
	updateMyFieldsFailed,
	myFieldsOnboarded,
	requestPasswordResetRequested,
	requestPasswordResetFailed,
	passwordReset,
	passwordResetRequested,
	passwordResetFailed,
	stripeConnectRequested,
	stripeConnectFailed,
	stripeConnectLinked,
	triggerManualParticipantOnboardingTransitionRequested,
	triggerManualParticipantOnboardingTransitionFailed,
	manualParticipantOnboardingTransitionTriggered,
	reauthenticationRequired,
	userReauthenticated,
	reauthenticationAborted,
	closeMyParticipantAccountRequested,
	closeMyParticipantAccountFailed,
	myParticipantAccountClosed,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////

export const signIn =
	({ email, password }) =>
	async (dispatch, getState) => {
		console.log('Sign in...');

		dispatch(signInUserRequested());

		const randevuService = new randevu({ apiKey: getState().platform.public_key });

		const { token, errors } = await randevuService.auth.signIn({ email, password });

		const invalidCredentialsMessage = 'You have entered an invalid email or password';
		if (hasSubmissionErrors(errors)) {
			let submissionErrors = {
				...(isInvalidCredentials(errors) && {
					email: invalidCredentialsMessage,
				}),
				...(isFetchError(errors) && { [FORM_ERROR]: errors[0].message }),
			};
			dispatch(sendWarningToasty(invalidCredentialsMessage));
			if (Object.keys(submissionErrors).length) return dispatch(signInUserFailed(submissionErrors));
		}

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(signInUserFailed(errors));
		}

		return dispatch(userSignedIn(token));
	};

export const reauthenticate =
	({ email, password }) =>
	async (dispatch, getState) => {
		console.log('Reauthenticate participant...');
		const resolvedAction = await dispatch(signIn({ email, password }));
		if (resolvedAction.type.includes('Failed')) return dispatch(signInUserFailed(resolvedAction.payload));

		await dispatch(loadCurrentUser());
		dispatch(userReauthenticated());

		const reauthenticationCallback = getState().ui?.reauthenticationCallback;
		if (!reauthenticationCallback?.callbackThunkKey) return;

		const thunkCallback = THUNKS[reauthenticationCallback.callbackThunkKey]?.getThunk();
		return dispatch(thunkCallback(reauthenticationCallback.payload));
	};

export const signInAsGuest = () => async (dispatch, getState) => {
	console.log('Sign in as guest...');

	dispatch(signInUserAsGuestRequested());

	const randevuService = new randevu({ apiKey: getState().platform?.public_key });

	const { token, errors } = await randevuService.auth.signInAsGuest();

	if (errors) {
		return dispatch(signInUserAsGuestFailed(errors));
	}

	return dispatch(userSignedInAsGuest(token));
};

// interface ForceGuestArgs {
// redirectTo?: string;
// }
export const forceGuest = (redirectTo) => async (dispatch, getState) => {
	// 1. Log out current participant if any was logged in
	let rdvService = new randevu({ token: getState().auth?.token ?? '', apiKey: getState().platform.public_key });
	await rdvService.auth.signOut(); // This may fail if token expired, but we don't care as BE has already auto logged out user

	// 2. Obtain token for a guest
	rdvService = new randevu({ apiKey: getState().platform.public_key });
	const { token, errors } = await rdvService.auth.signInAsGuest();

	if (errors) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(signInUserAsGuestFailed(errors));
	}

	// 3. Retrieve transactionTypes that allow guests
	rdvService = new randevu({ token, apiKey: getState().platform.public_key });
	const { my_transaction_types, errors: guestErrors } = await rdvService.transactions.guestTransactionTypes();

	if (guestErrors) {
		dispatch(sendUnexpectedErrorToasty(guestErrors));
		return dispatch(signInUserFailed(guestErrors));
	}

	// 4. Save transaction types into store
	dispatch(transactionTypesLoaded(my_transaction_types));

	if (redirectTo) await dispatch(goToRoute(routeBuilder(redirectTo)));

	return dispatch(userSignedInAsGuest(token));
};

export const requestPasswordReset =
	({ email }) =>
	async (dispatch, getState) => {
		console.log('Request password reset...');

		dispatch(requestPasswordResetRequested());

		const randevuService = new randevu({ apiKey: getState().platform.public_key });
		const { requested, errors } = await randevuService.auth.requestParticipantPasswordReset({ email });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(requestPasswordResetFailed(errors));
		}

		return dispatch(passwordReset());
	};

export const resetPassword =
	({ email, token: changePasswordToken, password }) =>
	async (dispatch, getState) => {
		console.log('Password reset...');

		dispatch(passwordResetRequested());
		const randevuService = new randevu({ apiKey: getState().platform.public_key });
		const { errors } = await randevuService.auth.resetParticipantPassword({
			token: changePasswordToken,
			password,
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(passwordResetFailed(errors));
		}

		const { token } = await randevuService.auth.signIn({
			email,
			password,
		});

		if (!token) return;

		dispatch(passwordReset());
		dispatch(userSignedIn(token));
		return dispatch(loadCurrentUser());
	};

export const signUpParticipant =
	({ first_name, last_name, email, participant_tech_name }) =>
	async (dispatch, getState) => {
		console.log(`Register user "${email}"..`);

		dispatch(signUpUserRequested());

		const randevuService = new randevu({ apiKey: getState().platform.public_key });
		const { signed_up, errors } = await randevuService.auth.signUp({
			first_name,
			last_name,
			email,
			participant_tech_name,
		});

		if (hasSubmissionErrors(errors)) {
			let submissionErrors = {
				...(isDuplicate(errors) && { email: 'Account with this email already exists.' }),
				...(isFetchError(errors) && { [FORM_ERROR]: errors[0].message }),
			};
			if (Object.keys(submissionErrors).length) return dispatch(signUpUserFailed(submissionErrors));
		}

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(signUpUserFailed(errors));
		}

		if (signed_up) return dispatch(userSignedUp(API_STATUS.SUCCEEDED));

		// Some unexpected error happened
		return dispatch(signUpUserFailed(errors));
	};

export const verifyParticipantAccount =
	({ password, verification_token, email }) =>
	async (dispatch, getState) => {
		console.log('Verify participant account..');

		dispatch(verifyParticipantAccountRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { verified, errors: verificationErrors } = await randevuService.auth.verifyParticipantAccount({
			token: verification_token,
			password,
		});

		if (isSessionExpired(verificationErrors)) {
			return dispatch(
				verifyParticipantAccountFailed({ newPassword: 'Your link has expired. Please request new one' })
			);
		}

		if (verificationErrors) {
			dispatch(sendUnexpectedErrorToasty(verificationErrors));
			return dispatch(
				verifyParticipantAccountFailed({
					newPassword: 'Unexpected error happened. Please request new verification email',
				})
			);
		}

		console.log('Sign in participant..');
		const { token, errors: signInErrors } = await randevuService.auth.signIn({
			email,
			password,
		});
		if (signInErrors) return dispatch(signInUserFailed(signInErrors));

		console.log('Load participant data..');
		randevuService.withAuth(token);
		const { user, errors } = await randevuService.auth.getCurrentUser();

		if (errors?.find((err) => err.message === 'INVALID_USER_STATE')) {
			console.log(
				'User is not onboarded, match types are not allowed. Ignore error and proceed to participant onboarding page....'
			);
		}

		// Current workaround until mapUserDataToCurrentUser gets refactored
		let userData = {};
		userData.me = user;

		dispatch(userSignedIn(token));
		const { participants, errors: participantErrors } = await randevuService.auth.getMyParticipants();
		dispatch(currentUserLoaded({ ...user, ...participants[0] }));

		dispatch(userAccountVerified());

		console.log('Go to participant onboarding page..');
		return dispatch(goToRoute(routeBuilder(ROUTES.PARTICIPANT_ONBOARDING)));
	};

export const loadCurrentUser = () => async (dispatch, getState) => {
	console.log('Load current user...');

	dispatch(loadCurrentUserRequested());
	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
	const { user, errors } = await randevuService.auth.getCurrentUser();

	if (isSessionExpired(errors))
		return await handleSessionExpired({
			dispatch,
			state: getState(),
			failedAction: loadCurrentUserFailed,
			reauthenticationCallback: {
				callbackThunkKey: 'LOAD_CURRENT_USER',
			},
		});

	// Current workaround until mapUserDataToCurrentUser gets refactored
	let userData = {};
	userData.me = user;

	if (isBackofficeUser(errors)) {
		return dispatch(
			loadCurrentUserFailed({
				[FORM_ERROR]:
					'You are trying to sign-in with your backoffice account. Please use your platform account to sign-in.',
			})
		);
	}

	if (errors) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadCurrentUserFailed(errors));
	}

	console.log('Load users transaction types..');
	const { participants, errors: participantErrors } = await randevuService.auth.getMyParticipants();
	const isAnyUserParticipantOnboarded = participants?.find(
		(participant) => participant.status === ParticipantStatusKind.Onboarded
	)
		? true
		: false;
	if (isAnyUserParticipantOnboarded) {
		const resolvedTransactionAction = await dispatch(loadMyTransactionTypes({}));
		console.log('Load users supply types..');
		await dispatch(loadMySupplyTypes());
		const defaultTransactionType = resolvedTransactionAction?.payload?.find((tt) => tt.is_default);
		if (resolvedTransactionAction.type.match(/failed/i)) return;
		dispatch(setSelectedTransactionType(defaultTransactionType));
	}

	return dispatch(currentUserLoaded({ ...user, ...participants?.[0] }));
};

export const signOut = (params) => async (dispatch, getState) => {
	console.log('Sign out current user...');

	dispatch(signOutUserRequested());

	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

	await randevuService.auth.signOut(); // This may fail if token expired, but we don't care as BE has already auto logged out user

	// FIXME: CLEAR STORE AFTER USER LOGS OUT (KEEP ONLY PUBLIC API KEY TO FORCE GUEST PERSPECTIVE)
	//await dispatch(userSignedOut());

	await dispatch(forceGuest());

	if (params?.options?.redirectTo) {
		return await dispatch(goToRoute(routeBuilder(params.options.redirectTo)));
	}
};

export const updateMyFields =
	({ id, dirty_fields = [], current_fields = [] }) =>
	async (dispatch, getState) => {
		console.log('Update participant fields..');

		dispatch(updateMyFieldsRequested());
		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
		const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

		if (isSessionExpired(expiredSession))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: updateMyFieldsFailed });

		const { field_inputs: dirty_fields_with_uploaded_files, errors: uploadErrors } =
			await randevuService.files.uploadFieldInputsFiles({
				field_inputs: dirty_fields,
				fields: current_fields,
			});

		const { updated, errors } = await randevuService.participants.updateParticipant({
			id,
			fields: dirty_fields_with_uploaded_files,
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(updateMyFieldsFailed(errors));
		}

		console.log('Wait before refetching onboarding fields to verify current participant status');
		await sleep(1000);

		const { data: reloadedUser, errors: reloadUserErrors } = await dispatch(loadCurrentUser());

		if (reloadUserErrors) {
			dispatch(sendUnexpectedErrorToasty(reloadUserErrors));
			return dispatch(updateMyFieldsFailed(errors));
		}

		const updatedUser = reloadedUser?.me;

		// if (USER_STATUS.ONBOARDING.value === updatedUser?.status) {
		// 	// If inside onboarding flow, reload fields
		// 	if (updatedUser?.onboarding_progress.auto_pilot_mode === false) {
		// 		console.log(`Participant remained in onboarding status because custom onboarding journey is enabled`);
		// 		dispatch(myFieldsOnboarded());
		// 		return await dispatch(loadCurrentUser());
		// 	}

		// 	console.log(`Participant remained in onboarding status because mandatory fields are missing`);
		// 	let submissionErrors = {};
		// 	updatedUser.fields.forEach((field) => {
		// 		if (field.field_type.is_required && field.value === null) {
		// 			submissionErrors[field.id] = 'Mandatory field is missing. Please providing the missing value';
		// 		}
		// 	});

		// 	return dispatch(updateMyFieldsFailed(submissionErrors));
		// }

		// if (getIsObjectPendingApproval(updatedUser)) {
		// 	console.log(`Participant is pending BO approval.`);
		// 	dispatch(myFieldsOnboarded());
		// 	return await dispatch(loadCurrentUser());
		// }

		if (ParticipantStatusKind.Onboarded === updatedUser?.status) {
			console.log(`Participant successfully onboarded.`);

			dispatch(myFieldsOnboarded());

			// to avoid race-conditions, wait a bit
			// before refreshing current user
			await sleep(1000);
			await dispatch(loadCurrentUser(updatedUser));

			return dispatch(goToRoute(routeBuilder(ROUTES.DASHBOARD)));
		}

		return dispatch(myFieldsOnboarded());
	};

export const linkParticipantAccountToStripeConnectAccount =
	({ id_integration_provider, refresh_url, return_url }) =>
	async (dispatch, getState) => {
		console.log('Connect user account to Stripe Connect Account...');

		dispatch(stripeConnectRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { url, errors } = await randevuService.payments.requestStripeConnectAccountConnection({
			id_integration_provider,
			refresh_url,
			return_url,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: stripeConnectFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(stripeConnectFailed(errors));
		}

		dispatch(stripeConnectLinked(url));

		return dispatch(redirectExternal({ url }));
	};

export const triggerManualParticipantOnboardingTransition =
	({ id_participant, transition_tech_name }) =>
	async (dispatch, getState) => {
		console.log(`Trigger manual transition ${transition_tech_name}..`);

		dispatch(triggerManualParticipantOnboardingTransitionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { triggered, errors } = await randevuService.auth.triggerManualParticipantOnboardingTransition({
			id_participant,
			transition_tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				getState,
				failedAction: triggerManualParticipantOnboardingTransitionFailed,
			});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(triggerManualParticipantOnboardingTransitionFailed(errors));
		}

		await sleep(100);

		await dispatch(loadCurrentUser());
		return dispatch(manualParticipantOnboardingTransitionTriggered());
	};

export const changeMyEmail =
	({ current_password, new_email }) =>
	async (dispatch, getState) => {
		console.log(`Change my email`);

		dispatch(changeMyEmailRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { updated, errors } = await randevuService.auth.updateMyEmail({
			current_password,
			new_email,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				getState,
				failedAction: changeMyEmailFailed,
			});

		if (hasSubmissionErrors(errors)) {
			let submissionErrors = {
				...(isDuplicate(errors) && {
					new_email: 'Account with this email already exists. Please choose another email.',
				}),
				...(isInvalidCredentials(errors) && {
					current_password: "'You have entered an invalid password.",
				}),
			};
			if (Object.keys(submissionErrors).length) return dispatch(changeMyEmailFailed(submissionErrors));
		}

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(changeMyEmailFailed(errors));
		}

		if (updated) dispatch(sendSuccessToasty('Saved'));

		const { token, errors: signInErrors } = await randevuService.auth.signIn({
			email: new_email,
			password: current_password,
		});

		if (signInErrors) {
			dispatch(sendUnexpectedErrorToasty(signInErrors));
			return dispatch(changeMyEmailFailed(signInErrors));
		}

		dispatch(userSignedIn(token));
		return dispatch(myEmailChanged({ new_email }));
	};
export const changeMyPassword =
	({ current_password, new_password }) =>
	async (dispatch, getState) => {
		dispatch(changeMyPasswordRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { updated, errors } = await randevuService.auth.updateMyPassword({
			current_password,
			new_password,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				getState,
				failedAction: changeMyEmailFailed,
			});

		if (hasSubmissionErrors(errors)) {
			let submissionErrors = {
				...(isDuplicate(errors) && {
					new_password:
						'Your new password cannot be the same as your current password. Please choose a different password.',
				}),
				...(isInvalidCredentials(errors) && {
					current_password: 'You have entered an invalid password.',
				}),
			};
			if (Object.keys(submissionErrors).length) return dispatch(changeMyPasswordFailed(submissionErrors));
		}

		if (!isEmpty(errors)) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(changeMyPasswordFailed(errors));
		}

		if (updated) dispatch(sendSuccessToasty('Saved'));

		const { token, errors: signInErrors } = await randevuService.auth.signIn({
			email: getState().auth.currentUser.email,
			password: new_password,
		});

		if (signInErrors) {
			dispatch(sendUnexpectedErrorToasty(signInErrors));
			return dispatch(changeMyEmailFailed(signInErrors));
		}

		dispatch(userSignedIn(token));
		return dispatch(myPasswordChanged());
	};

export const closeMyParticipantAccount =
	({ id_participant, current_password }) =>
	async (dispatch, getState) => {
		dispatch(closeMyParticipantAccountRequested());

		let randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { deactivated, errors } = await randevuService.participants.closeMyParticipantAccount({
			id_participant,
			current_password,
		});

		if (isSessionExpired(errors)) {
			const currentUser = getState().auth.currentUser;

			const { token, errors } = await randevuService.auth.signIn({
				email: currentUser?.email,
				password: current_password,
			});

			if (errors)
				return dispatch(
					closeMyParticipantAccountFailed({
						current_password: 'You have entered an invalid password.',
					})
				);

			randevuService = new randevu({
				token: token,
				apiKey: getState().platform.public_key,
			});
		}

		if (hasSubmissionErrors(errors)) {
			let submissionErrors = {
				...(isInvalidCredentials(errors) && {
					current_password: 'You have entered an invalid password.',
				}),
				...(isInvalidObjectState(errors) && {
					current_password: errors[0].extensions?.remark,
				}),
			};
			if (Object.keys(submissionErrors).length)
				return dispatch(closeMyParticipantAccountFailed(submissionErrors));
		}

		if (!isEmpty(errors) || deactivated === false) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(closeMyParticipantAccountFailed(errors));
		}

		return dispatch(myParticipantAccountClosed());
	};
/////////////////////
//   SELECTORS     //
/////////////////////
export const selectLoading = (state) => state.auth.loading;
export const selectErrors = (state) => state.auth.errors;
export const selectToken = (state) => state.auth.token;
export const selectUserSignUpStatus = (state) => state.auth.userSignUpStatus;
export const selectIsSignedIn = (state) => state.auth.token;
export const selectCurrentUser = (state) => state.auth.currentUser;
export const selectIsAccountVerified = (state) => state.auth.verifyParticipantAccountStatus === API_STATUS.SUCCEEDED;
export const selectConnectToStripeAccountStatus = (state) => state.auth.connectToStripeAccountStatus;
export const selectReauthenticationStatus = (state) => state.auth.reauthenticationStatus;
