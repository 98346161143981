import { format } from 'date-fns';

import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';

import FieldDisplayValue from '../fields/display/FieldDisplayValue';
import {
	AvailabilityRecord,
	CurrencyKind,
	Field,
	FieldCategoryKind,
	SupplyPrice,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { Button, Stack, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MatchPriceOverrideFormDialog from './MatchPriceOverrideFormDialog';
import { useState } from 'react';
import { formatPriceAmount } from '../supply/supplyHelpers';
import ConditionalFieldDisplay from '../fields/display/ConditionalFieldDisplay';

interface RowProps {
	label: string;
	value: any;
}
const Row = ({ label, value }: RowProps) => {
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
			<Typography variant="subtitle2">{label}</Typography>
			<Typography>{value}</Typography>
		</Stack>
	);
};

const useMatchStatusStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		lineItemRow: {
			marginTop: theme.spacing(2),
			display: 'flex',
			justifyContent: 'space-between',
		},
		lineItem: {
			fontWeight: 550,
			fontSize: '0.875rem',
		},
		bold: {
			fontWeight: 'bold',
		},
	};
});

interface TransactionMatchOverviewProps {
	transactionId: string;
	isParticipantConsumer: boolean;
	consumerFields: Field[];
	requestFields: Field[];
	supplyFields: Field[];
	providerFields: Field[];
	matchPriceOverrideEnabled: boolean; // TODO: can this be removed?
	isMatchConfigurationPricingEnabled: boolean; // TODO: can this be removed?
	matchPrice: SupplyPrice;
	availabilityRecord: AvailabilityRecord;
}
const TransactionMatchOverview = ({
	transactionId,
	isParticipantConsumer,
	requestFields,
	consumerFields,
	supplyFields,
	providerFields,
	availabilityRecord,
	matchPriceOverrideEnabled,
	matchPrice,
	isMatchConfigurationPricingEnabled,
}: TransactionMatchOverviewProps) => {
	const requestFieldsWithoutQuantity = requestFields?.filter((rf) => rf?.field_type?.tech_name != 'qty');
	const requestFieldValuesWithoutQuantity = {};
	requestFieldsWithoutQuantity?.forEach((field) =>
		Object.assign(requestFieldValuesWithoutQuantity, { [field?.field_type?.tech_name]: field?.value })
	);
	const classes = useMatchStatusStyles();
	const [openMatchPriceOverride, setOpenMatchPriceOverride] = useState(false);

	const participantFields = isParticipantConsumer ? providerFields : consumerFields;

	const participantFieldValues = {};
	participantFields?.forEach((field) =>
		Object.assign(participantFieldValues, { [field?.field_type?.tech_name]: field?.value })
	);

	const currency = matchPrice?.currency ?? CurrencyKind.Eur;
	const fieldPrice = findFieldByCategory(supplyFields, FieldCategoryKind.BasePrice);
	const formattedMatchUnitPrice = formatPriceAmount({
		amount: matchPrice?.unit_price ?? 0,
		currency: currency,
	});

	const start_datetime = availabilityRecord?.start_datetime;
	const end_datetime = availabilityRecord?.end_datetime;
	const start_date = availabilityRecord?.start_date;
	const end_date = availabilityRecord?.end_date;
	const quantity = matchPrice?.qty;

	// TODO: MOVE TO DATE FORMATTER HELPER CLASS
	const datetimeFormat = `dd.MM.yy HH:mm`;
	const dateFormat = `dd.MM.yy`;

	const formattedSubtotalMatchAmount = formatPriceAmount({
		amount: matchPrice?.total_before_tax,
		currency: currency,
	});

	const formattedTotalTaxesMatchAmount = formatPriceAmount({
		amount: matchPrice?.total_taxes,
		currency: currency,
	});

	const formattedTotalMatchAmount = formatPriceAmount({
		amount: matchPrice?.total_before_tax + matchPrice?.total_taxes,
		currency: currency,
	});
	const formattedAdjustedTotalMatchAmount = formatPriceAmount({
		amount: matchPrice?.total_before_tax + matchPrice?.total_taxes + matchPrice?.total_fees,
		currency: currency,
	});

	return (
		<Paper id="transaction-match-overview" component="section" elevation={0}>
			{isMatchConfigurationPricingEnabled && (
				<>
					<MatchPriceOverrideFormDialog
						transactionId={transactionId}
						open={openMatchPriceOverride}
						matchPrice={matchPrice}
						onClose={() => setOpenMatchPriceOverride(false)}
					/>
					<Typography variant="h5" component="h3" sx={{ fontWeight: 700, marginBottom: 2 }}>
						Overview
					</Typography>

					<Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
						{start_datetime && (
							<Row label="Start Date" value={format(new Date(start_datetime), datetimeFormat)} />
						)}
						{end_datetime && (
							<Row label="End Date" value={format(new Date(end_datetime), datetimeFormat)} />
						)}
						{start_date && <Row label="Start Date" value={format(new Date(start_date), dateFormat)} />}
						{end_date && <Row label="End Date" value={format(new Date(end_date), dateFormat)} />}
						{quantity && <Row label="Quantity" value={quantity} />}
						{fieldPrice && <Row label="Unit price" value={formattedMatchUnitPrice} />}
						{matchPrice?.addon_items?.map((add_on) => (
							<Row
								label={add_on?.description}
								value={formatPriceAmount({
									amount: add_on.value,
									currency: currency,
								})}
							/>
						))}{' '}
						{matchPrice?.discount_items?.map((discount) => (
							<Row
								label={discount?.description}
								value={`-${formatPriceAmount({
									amount: discount.value,
									currency: currency,
								})}`}
							/>
						))}
						<Divider flexItem />
						{fieldPrice && <Row label="Subtotal" value={formattedSubtotalMatchAmount} />}
						{fieldPrice && <Row label="Taxes" value={formattedTotalTaxesMatchAmount} />}
						<Divider flexItem />
						{fieldPrice && <Row label="Total" value={formattedTotalMatchAmount} />}
						{isParticipantConsumer && (
							<>
								<Divider flexItem />
								{matchPrice?.platform_fee_items?.length > 0 && (
									<>
										{matchPrice?.platform_fee_items?.map((fee) => (
											<Row
												label={fee?.description}
												value={formatPriceAmount({
													amount: fee.value,
													currency: currency,
												})}
											/>
										))}
										<Divider flexItem />
										{<Row label="Adjusted total" value={formattedAdjustedTotalMatchAmount} />}
									</>
								)}
							</>
						)}
						{matchPriceOverrideEnabled && (
							<Stack>
								<Button
									color="error"
									size="small"
									variant="outlined"
									startIcon={<EditIcon />}
									onClick={() => setOpenMatchPriceOverride(true)}
								>
									Change total price
								</Button>
							</Stack>
						)}
					</Stack>
				</>
			)}

			{requestFieldsWithoutQuantity?.length > 0 && (
				<>
					{isMatchConfigurationPricingEnabled && <Divider sx={{ mt: 3, mb: 3 }} />}

					{requestFieldsWithoutQuantity?.map((field) => {
						return (
							<Grid key={field?.field_type.tech_name}>
								<ConditionalFieldDisplay
									key={field?.field_type?.tech_name}
									fields={requestFieldsWithoutQuantity}
									values={requestFieldValuesWithoutQuantity}
									conditions={field?.field_type?.conditions ?? []}
								>
									<FieldDisplayValue
										label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
										fieldTechName={field?.field_type.tech_name}
										inputType={field?.field_type.input_type}
										inputOptions={field?.field_type.input_options}
										optionsUiConfig={field?.field_type?.ui_config?.options ?? []}
										value={field.value}
									/>
								</ConditionalFieldDisplay>
							</Grid>
						);
					})}
				</>
			)}

			{participantFields?.length > 0 && (
				<>
					{isMatchConfigurationPricingEnabled && requestFieldsWithoutQuantity?.length > 0 && (
						<Divider sx={{ marginTop: 3, marginBottom: 3 }} />
					)}

					<Typography variant="subtitle1" gutterBottom>
						{isParticipantConsumer ? 'Provider' : 'Consumer'}
					</Typography>

					{participantFields?.map((field) => {
						return (
							<Grid key={field?.field_type.tech_name}>
								<ConditionalFieldDisplay
									key={field?.field_type?.tech_name}
									fields={participantFields}
									values={participantFieldValues}
									conditions={field?.field_type?.conditions ?? []}
								>
									<FieldDisplayValue
										label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
										fieldTechName={field?.field_type.tech_name}
										valueProps={{ className: classes.bold }}
										inputType={field?.field_type.input_type}
										optionsUiConfig={field?.field_type?.ui_config?.options ?? []}
										inputOptions={field?.field_type.input_options}
										value={field.value}
									/>
								</ConditionalFieldDisplay>
							</Grid>
						);
					})}
				</>
			)}
		</Paper>
	);
};

export default TransactionMatchOverview;
