import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import config from '@rdv-fo/common/config';

const ParticipantsNotFoundView = ({ platformName }) => {
	return (
		<div>
			<Typography display="inline">It seems that platform </Typography>
			<Typography display="inline" color="primary">
				{platformName}{' '}
			</Typography>
			<Typography display="inline">does not have any </Typography>
			<Typography color="primary" display="inline">
				Participants{' '}
			</Typography>
			<Typography display="inline">defined or that you chose non-existing platform.</Typography>
			<br />
			<br />
			<Typography display="inline">To define Participants, please use </Typography>
			<Typography color="primary" display="inline">
				<Link href={config.backoffice} target="_blank" rel="noopener">
					randevu backoffice.
				</Link>
			</Typography>
		</div>
	);
};

export default ParticipantsNotFoundView;
