import { DatePicker } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';

import LabelFieldInput from './LabelFieldInput';
import { parse, format } from '@rdv-fo/app/lib/datetime';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Stack } from '@mui/material';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { FieldArray } from 'react-final-form-arrays';

import { FieldInputBaseProps } from './types';
import { useForm } from 'react-final-form';

interface DateInputOptions {
	required: boolean;
}
const validate = (value: any, options: DateInputOptions) => {
	const { required } = options;

	if (required && (value === null || value === undefined)) return 'Please fill in this field';

	return undefined;
};

interface FieldInputDateSetProps extends FieldInputBaseProps {
	pastOnly?: boolean;
	futureOnly?: boolean;

	minItems?: number;
	maxItems?: number;
}

export const FieldInputDateSet = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	pastOnly = false,
	futureOnly = false,
	minItems,
	maxItems,
}: FieldInputDateSetProps) => {
	const form = useForm();
	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>

			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>

			<div>
				<FieldArray name={`${techName}`}>
					{({ fields, meta }) => {
						const numberOfItemsInArray = fields.length;

						return (
							<>
								<Stack spacing={2}>
									{fields?.map((name, index) => {
										return (
											<Box key={index}>
												<Stack
													direction="row"
													justifyContent="flex-start"
													alignItems="flex-start"
													spacing={4}
												>
													<LocalizationProvider dateAdapter={DateFnsUtils}>
														<DatePicker
															name={name}
															required={required}
															disabled={disabled}
															InputProps={{
																onKeyDown: (e) => e.preventDefault(),
															}}
															inputFormat="dd/MM/yyyy"
															fieldProps={{
																parse,
																format,
																validate: (value) => validate(value, { required }),
																disabled,
																disableFuture: pastOnly,
																disablePast: futureOnly,
																inputVariant: 'outlined',
																clearable: required,
															}}
														/>
													</LocalizationProvider>

													<Button
														variant="text"
														color="primary"
														onClick={() => {
															if (index === 0 && fields.length === 1)
																form.change(techName, null);
															else fields.remove(index);
														}}
														size="small"
														startIcon={<ClearIcon />}
														style={{ padding: 1 }}
														disabled={disabled}
													>
														Remove
													</Button>
												</Stack>
											</Box>
										);
									})}
									<Box>
										{maxItems && (
											<>
												{numberOfItemsInArray !== undefined &&
													numberOfItemsInArray < maxItems ? (
													<Button
														size="small"
														color="primary"
														startIcon={<AddIcon />}
														disabled={disabled}
														onClick={() => {
															fields.push(undefined);
														}}
													>
														Add new
													</Button>
												) : (
													<Typography variant="body2" color="textSecondary">
														You are not allowed to add more because the maximum of{' '}
														{maxItems} allowed items is reached.
													</Typography>
												)}
											</>
										)}

										{maxItems === undefined && (
											<Button
												size="small"
												color="primary"
												startIcon={<AddIcon />}
												disabled={disabled}
												onClick={() => {
													fields.push(undefined);
												}}
											>
												Add new
											</Button>
										)}
									</Box>
								</Stack>
							</>
						);
					}}
				</FieldArray>
			</div>
		</Stack>
	);
};

export default FieldInputDateSet;
