import Typography from '@mui/material/Typography';

interface FieldValueMonetaryValueProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueMonetaryValue = ({ fieldTechName, value, className }: FieldValueMonetaryValueProps) => {
	let roundedValue = value?.amount / 100;
	let displayValue = `${roundedValue} ${value?.currency} `;

	return (
		<Typography id={fieldTechName} className={className}>
			{value === null ? 'n.a.' : `${displayValue} `}
		</Typography>
	);
};

export default FieldValueMonetaryValue;
