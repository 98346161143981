import { Button, FormHelperText, Typography } from '@mui/material';
import isFileSizeAcceptable from '@rdv-fo/app/lib/fileSizeValidator';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { RdvFileFieldValue } from '@rdv-fo/services/randevuApi/types/field/';
import { Field } from 'react-final-form';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';

interface FormFieldFileDisplayProps {
	label: string;
	onRemove: () => void;
}
const SelectedFileDisplay = ({ label, onRemove }: FormFieldFileDisplayProps) => {
	return (
		<Stack
			direction="row"
			sx={{ backgroundColor: '#E4E6ED', pl: 1.25, pr: 1.25, pt: 1, pb: 1, borderRadius: '8px' }}
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
		>
			<Typography variant="body2">{label}</Typography>
			<IconButton aria-label={`download ${label}`} size="small" onClick={onRemove}>
				<DeleteOutlinedIcon fontSize="inherit" sx={{ color: 'black' }} />
			</IconButton>
		</Stack>
	);
};

interface DocumentInputOptions {
	required: boolean;
	maxSize?: number;
}
const validate = (value: File | RdvFileFieldValue | undefined, options: DocumentInputOptions) => {
	const { maxSize, required } = options;

	// no need to validate already updated  file
	if (value as RdvFileFieldValue) return undefined;

	// no need to validate that its mandatory
	if (!required && value === undefined) return undefined;

	// validate its mandatory
	if (required && value === undefined) return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	const js_file = value as File;

	if (maxSize && js_file && !isFileSizeAcceptable({ maxAllowedSize: maxSize, fileSize: js_file.size }))
		return VALIDATION_MESSAGE.MAX_FILE_SIZE_VIOLATION.replace('{max_size}', String(maxSize));

	return undefined;
};

const getAcceptedDocumentFormats = (allowedFileExtensions: string[] | undefined) =>
	allowedFileExtensions?.map((type) => '.' + type.toLowerCase()).join(', ');

interface FormFieldFileProps {
	name: string;
	maxSize?: number;
	disabled?: boolean;
	required?: boolean;
	label?: string;
	buttonLabel?: string;
	allowedFileExtensions: string[];
}

const FormFieldFile = ({
	name,
	label,
	buttonLabel = 'Select file',
	disabled = false,
	required = false,
	allowedFileExtensions,
	maxSize,
}: FormFieldFileProps) => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<div>
				<Field name={name} validate={(value) => validate(value, { required, maxSize })}>
					{({ input: { value, onChange, ...input }, meta }) => {
						const { submitError, dirtySinceLastSubmit, error, pristine, submitFailed } = meta;
						let selectedDocumentUrl: any = undefined;
						if (value && value instanceof File) selectedDocumentUrl = URL.createObjectURL(value);
						if (value && !(value instanceof File)) selectedDocumentUrl = value.url;

						const acceptedDocumentFormats = getAcceptedDocumentFormats(allowedFileExtensions);

						return (
							<>
								{((error && !pristine) || (error && submitFailed)) && (
									<FormHelperText error={true}>{error}</FormHelperText>
								)}
								{submitError && !dirtySinceLastSubmit && (
									<FormHelperText error={true}>{submitError}</FormHelperText>
								)}
								{!selectedDocumentUrl && (
									<>
										{maxSize && (
											<Typography color="textSecondary" variant="body2">
												{`Maximum allowed file size: ${maxSize} MB`}
											</Typography>
										)}
										<Button
											variant="text"
											color="primary"
											component="label"
											size="small"
											startIcon={<UploadOutlinedIcon />}
											sx={{ marginTop: 1 }}
											disabled={disabled}
										>
											{buttonLabel}
											<input
												{...input}
												id={name}
												name={name}
												type="file"
												hidden
												accept={acceptedDocumentFormats}
												onChange={({ target }) => {
													// TODO: FIXME PREVENT CHOOSING FILES THAT ARE BIGGER THAN ALLOWED
													if (target.files !== null && target.files.length > 0)
														onChange(target.files[0]);
												}}
											/>
										</Button>
									</>
								)}

								{selectedDocumentUrl && (
									<SelectedFileDisplay
										label={value?.name || value?.file_name}
										onRemove={() => onChange(null)}
									/>
								)}
							</>
						);
					}}
				</Field>
			</div>
		</Stack>
	);
};

export default FormFieldFile;
