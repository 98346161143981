import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import { Box, Stack, Skeleton, Button } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import { useState } from 'react';

import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
	selectLoading,
	selectMySupplyTypes,
	loadMyCollections,
	selectMyCollections,
	loadMySupplyTypes,
	createCollectionFailed,
	createCollection,
} from '@rdv-fo/store/slices/supplySlice';
import CollectionsTable from '@rdv-fo/components/supply/collections/CollectionsTable';
import CreateCollectionDialog, {
	CreateCollectionFormValues,
} from '@rdv-fo/components/supply/collections/CreateCollectionDialog';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';
import ROUTES from '@rdv-fo/common/routes';

const MyCollectionsPage = () => {
	const dispatch = useAppDispatch();
	const collections = useAppSelector(selectMyCollections);
	const mySupplyTypes = useAppSelector(selectMySupplyTypes);
	const loading = useAppSelector(selectLoading);

	const [openCreateCollectionDialog, setOpenCreateCollectionDialog] = useState(false);

	const handleSubmitCreateCollectionForm = async (values: CreateCollectionFormValues) => {
		const resolvedAction = await dispatch(createCollection({ name: values.name }));
		if (resolvedAction.type === createCollectionFailed.type) return resolvedAction.payload;

		const route = routeBuilder(ROUTES.UPDATE_MY_COLLECTION, [[':collectionId', resolvedAction?.payload.id]]);
		dispatch(goToRoute(route));
	};

	useEffect(() => {
		dispatch(loadMySupplyTypes());
		dispatch(loadMyCollections());
	}, [dispatch]);

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
				<Box>
					<BackButton />
				</Box>

				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<Typography component="h1" variant="h1">
						Manage collections
					</Typography>
					{!loading && (
						<Button
							color="primary"
							variant="contained"
							size="medium"
							startIcon={<AddIcon />}
							onClick={() => setOpenCreateCollectionDialog(true)}
						>
							Add new
						</Button>
					)}
				</Stack>
			</Stack>
			<Box sx={{ mt: 5 }}>
				{loading ? (
					<Stack direction="column" sx={{ pt: 4 }}>
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
						<Skeleton width="100%" height={50} />
					</Stack>
				) : (
					<>
						<CreateCollectionDialog
							supplyTypes={mySupplyTypes}
							open={openCreateCollectionDialog}
							onClose={() => setOpenCreateCollectionDialog(false)}
							onSubmit={handleSubmitCreateCollectionForm}
						/>
						<CollectionsTable collections={collections} />
					</>
				)}
			</Box>
		</>
	);
};

export default MyCollectionsPage;
