import * as transactionMutations from './transactionMutations';
import * as matchMutations from './matchMutations';
import * as transactionQueries from './transactionQueries';
import * as transactionTypeQueries from './transactionTypeQueries';
import * as matchingToolMutations from './matchingToolMutations';
import unifyFieldValue from '@rdv-fo/services/randevuApi/helpers/fieldHelper';
import * as discoveryQueries from './discoveryQueries';

import {
	TransactionType,
	TransactionLight,
	Transaction,
	TransactionMatch,
	PaymentType,
	QueryMyShoppingCartsArgs,
	MutationTriggerManualTransactionTransitionArgs,
	QueryMyTransactionArgs,
	ShoppingCartLight,
	DiscoveredSupply,
	MutationPrepareAndInitiateReverseAuctionTransactionArgs,
	MutationAcceptTransactedMatchArgs,
	MutationDeclineTransactedMatchArgs,
	MutationUpdateTransactedMatchArgs,
	MutationUpdateTransactionOrderArgs,
	MutationForceTransactionTerminationArgs,
	QueryMySingleDirectTransactionsForProviderArgs,
	Provider,
	MutationOverrideTransactedMatchPriceArgs,
	SingleDirectTransactionForConsumerConnection,
	QueryMySingleDirectTransactionsArgs,
	SingleDirectTransactionForConsumerEdge,
	SingleDirectTransactionForProviderEdge,
	QueryMySingleDirectTransactionArgs,
	SingleDirectTransactionForConsumer,
	SingleDirectTransactionForProvider,
	MutationUpdateSingleDirectMatchArgs,
	MutationUpdateSingleDirectMatchForProviderArgs,
	MutationCreateSingleDirectTransactionArgs,
	MutationStartSingleDirectTransactionArgs,
	MutationAddSupplyToShoppingCartArgs,
	MutationCreateShoppingCartArgs,
	MutationRemoveShoppingCartItemArgs,
	MutationUpdateShoppingCartItemArgs,
	QueryMyShoppingCartArgs,
	QueryMyShoppingCartOrderArgs,
	QueryMyShoppingCartOrdersArgs,
	ShoppingCart,
	ShoppingCartItemForConsumer,
	ShoppingCartItemForProvider,
	ShoppingCartOrder,
	ShoppingCartOrderLight,
	QueryDiscoverTransactionSuppliesArgs,
	QueryDiscoverTransactionSupplyDetailsArgs,
	SupplyVariant,
	QueryDiscoverTransactionProviderDetailsArgs,
	QueryDiscoverTransactionProvidersArgs,
	ProviderEdge,
	PageInfo,
	DiscoveredSupplyEdge,
	QueryDiscoverTransactionSuppliesNewArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

class TransactionsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async myTransactionTypes(): Promise<{ my_transaction_types: TransactionType[] | null; errors: any }> {
		const payload = transactionTypeQueries.myTransactionTypes();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { my_transaction_types: data?.myTransactionTypes, errors };
	}

	async guestTransactionTypes(): Promise<{ my_transaction_types: TransactionType[] | null; errors: any }> {
		const payload = transactionTypeQueries.guestTransactionTypes();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { my_transaction_types: data?.myTransactionTypes, errors };
	}

	async mySingleDirectTransactionsForConsumer({
		where,
		after,
		before,
		first,
		last,
	}: QueryMySingleDirectTransactionsArgs): Promise<{
		transactions: SingleDirectTransactionForConsumerConnection;
		errors: any;
	}> {
		const payload = transactionQueries.mySingleDirectTransactionsForConsumer({ where, after, before, first, last });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const transactions = data?.mySingleDirectTransactions;

		if (transactions?.edges)
			transactions.edges = transactions?.edges?.map(
				(transaction_edge: SingleDirectTransactionForConsumerEdge) => {
					const transaction = transaction_edge.node;

					const request_fields = unifyFieldValue(transaction?.request?.fields);

					const requester_participant_fields = unifyFieldValue(
						transaction?.request?.requester_participant?.fields
					);
					const supply_fields = unifyFieldValue(transaction?.match?.supply_fields ?? []);

					const provider_fields = unifyFieldValue(transaction?.match?.provider_fields ?? []);
					const match_fields = unifyFieldValue(transaction?.match?.fields ?? []);

					if (request_fields) transaction.request.fields = request_fields;

					if (requester_participant_fields)
						transaction.request.requester_participant.fields = requester_participant_fields;

					if (supply_fields && transaction?.match) transaction.match.supply_fields = supply_fields;

					if (provider_fields && transaction?.match?.provider_fields)
						transaction.match.provider_fields = provider_fields;
					if (match_fields && transaction?.match) transaction.match.fields = match_fields;

					return transaction_edge;
				}
			);

		return { transactions, errors };
	}
	async mySingleDirectTransactionsForProvider({
		where,
		after,
		before,
		first,
		last,
	}: QueryMySingleDirectTransactionsForProviderArgs): Promise<{
		transactions: SingleDirectTransactionForConsumerConnection;
		errors: any;
	}> {
		const payload = transactionQueries.mySingleDirectTransactionsForProvider({ where, after, before, first, last });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const transactions = data?.mySingleDirectTransactionsForProvider;

		if (transactions?.edges)
			transactions.edges = transactions?.edges?.map(
				(transaction_edge: SingleDirectTransactionForProviderEdge) => {
					const transaction = transaction_edge.node;

					const request_fields = unifyFieldValue(transaction?.request?.fields);

					const supply_fields = unifyFieldValue(transaction?.match?.supply.fields ?? []);

					const consumer_fields = unifyFieldValue(transaction?.match?.consumer_fields ?? []);
					const match_fields = unifyFieldValue(transaction?.match?.fields ?? []);

					if (request_fields) transaction.request.fields = request_fields;

					if (supply_fields && transaction?.match) transaction.match.supply.fields = supply_fields;

					if (consumer_fields && transaction?.match?.consumer_fields)
						transaction.match.consumer_fields = consumer_fields;
					if (match_fields && transaction?.match) transaction.match.fields = match_fields;

					return transaction_edge;
				}
			);

		return { transactions, errors };
	}
	async myTransactions(): Promise<{ transactions: TransactionLight[] | null; errors: any }> {
		const payload = transactionQueries.myTransactions();
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let transactions = data?.myTransactions;

		transactions = transactions?.map((transaction: any) => {
			const order_fields = unifyFieldValue(transaction?.order_fields);
			const match_fields = unifyFieldValue(transaction?.match_fields);

			transaction.order_fields = order_fields;
			transaction.match_fields = match_fields;

			return transaction as TransactionLight;
		});

		return { transactions, errors };
	}

	async myTransaction({ id }: QueryMyTransactionArgs): Promise<{
		transaction: Transaction | null;
		errors: any;
	}> {
		const payload = transactionQueries.myTransaction({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const transaction = data?.myTransaction as Transaction;

		if (transaction) {
			const order_fields = unifyFieldValue(transaction?.order?.fields);
			if (order_fields) {
				transaction.order.fields = order_fields;
			}

			if (transaction?.order?.creator?.fields) {
				transaction.order.creator.fields = unifyFieldValue(transaction.order.creator.fields);
			}

			transaction.matches?.map((match: TransactionMatch) => {
				match.provider_fields = unifyFieldValue(match?.provider_fields);
				match.consumer_fields = unifyFieldValue(match?.consumer_fields);
				match.supply_fields = unifyFieldValue(match?.supply_fields);
				match.fields = unifyFieldValue(match?.fields);

				if (match.provider?.fields) {
					match.provider.fields = unifyFieldValue(match.provider.fields);
				}
				if (match.consumer?.fields) {
					match.consumer.fields = unifyFieldValue(match.consumer.fields);
				}
				if (match.supply?.fields) {
					match.supply.fields = unifyFieldValue(match.supply.fields);
				}
			});

			transaction.sub_transactions?.map((transactionLight: TransactionLight) => {
				if (transactionLight.order_fields) {
					transactionLight.order_fields = unifyFieldValue(transactionLight.order_fields);
				}
				if (transactionLight.match_fields) {
					transactionLight.match_fields = unifyFieldValue(transactionLight.match_fields);
				}
				if (transactionLight.provider_fields) {
					transactionLight.provider_fields = unifyFieldValue(transactionLight.provider_fields);
				}
				if (transactionLight.consumer_fields) {
					transactionLight.consumer_fields = unifyFieldValue(transactionLight.consumer_fields);
				}
				if (transactionLight.supply_fields) {
					transactionLight.supply_fields = unifyFieldValue(transactionLight.supply_fields);
				}
			});
		}

		return { transaction: transaction, errors };
	}
	async mySingleDirectTransactionForConsumer({ id }: QueryMySingleDirectTransactionArgs): Promise<{
		transaction: SingleDirectTransactionForConsumer | null;
		errors: any;
	}> {
		const payload = transactionQueries.mySingleDirectTransactionForConsumer({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const transaction = data?.mySingleDirectTransaction as SingleDirectTransactionForConsumer;

		if (transaction) {
			const order_fields = unifyFieldValue(transaction?.request?.fields);
			if (order_fields) {
				transaction.request.fields = order_fields;
			}

			if (transaction?.request?.requester_participant?.fields) {
				transaction.request.requester_participant.fields = unifyFieldValue(
					transaction.request.requester_participant.fields
				);
			}
			if (transaction.match && transaction.match.provider_fields)
				transaction.match.provider_fields = unifyFieldValue(transaction.match?.provider_fields);
			if (transaction.match && transaction.match.supply_fields)
				transaction.match.supply_fields = unifyFieldValue(transaction.match.supply_fields);
			if (transaction.match && transaction.match.fields)
				transaction.match.fields = unifyFieldValue(transaction.match.fields);
		}

		return { transaction: transaction, errors };
	}
	async mySingleDirectTransactionForProvider({ id }: QueryMySingleDirectTransactionArgs): Promise<{
		transaction: SingleDirectTransactionForProvider | null;
		errors: any;
	}> {
		const payload = transactionQueries.mySingleDirectTransactionForProvider({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const transaction = data?.mySingleDirectTransactionForProvider as SingleDirectTransactionForProvider;

		if (transaction) {
			const order_fields = unifyFieldValue(transaction?.request?.fields);
			if (order_fields) {
				transaction.request.fields = order_fields;
			}

			if (transaction.match && transaction.match.consumer_fields)
				transaction.match.consumer_fields = unifyFieldValue(transaction.match?.consumer_fields);
			if (transaction.match && transaction.match.supply.fields)
				transaction.match.supply.fields = unifyFieldValue(transaction.match.supply.fields);
			if (transaction.match && transaction.match.fields)
				transaction.match.fields = unifyFieldValue(transaction.match.fields);
		}

		return { transaction: transaction, errors };
	}

	async createSingleDirectTransaction({
		transaction_tech_name,
		id_supply,
		fields,
	}: MutationCreateSingleDirectTransactionArgs): Promise<{
		transaction: SingleDirectTransactionForConsumer | null;
		errors: any;
	}> {
		const payload = transactionMutations.createSingleDirectTransaction({
			transaction_tech_name,
			id_supply,
			fields,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let transaction = data?.createSingleDirectTransaction;

		const order_fields = unifyFieldValue(transaction?.order_fields);
		const match_fields = unifyFieldValue(transaction?.match_fields);

		if (transaction) {
			transaction.order_fields = order_fields;
			transaction.match_fields = match_fields;
		}

		return { transaction, errors };
	}
	async startSingleDirectTransaction({ id }: MutationStartSingleDirectTransactionArgs): Promise<{
		transaction: SingleDirectTransactionForConsumer | null;
		errors: any;
	}> {
		const payload = transactionMutations.startSingleDirectTransaction({
			id,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let transaction = data?.startSingleDirectTransaction;

		const order_fields = unifyFieldValue(transaction?.order_fields);
		const match_fields = unifyFieldValue(transaction?.match_fields);

		if (transaction) {
			transaction.order_fields = order_fields;
			transaction.match_fields = match_fields;
		}

		return { transaction, errors };
	}

	async prepareAndInitiateReverseAuctionTransaction({
		transaction_tech_name,
		supply_filter,
		provider_filter,
		consumer_filter,
		fields,
	}: MutationPrepareAndInitiateReverseAuctionTransactionArgs): Promise<{
		transaction: TransactionLight | null;
		errors: any;
	}> {
		const payload = transactionMutations.prepareAndInitiateReverseAuctionTransaction({
			transaction_tech_name,
			supply_filter,
			provider_filter,
			consumer_filter,
			fields,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let transaction = data?.prepareAndInitiateReverseAuctionTransaction;

		const order_fields = unifyFieldValue(transaction?.order_fields);
		const match_fields = unifyFieldValue(transaction?.match_fields);

		if (transaction) {
			transaction.order_fields = order_fields;
			transaction.match_fields = match_fields;
		}

		return { transaction, errors };
	}

	async triggerManualTransactionTransition({
		id_transaction,
		transition_tech_name,
	}: MutationTriggerManualTransactionTransitionArgs): Promise<{
		triggered: boolean | null;
		errors: any;
	}> {
		const payload = transactionMutations.triggerManualTransactionTransition({
			id_transaction,
			transition_tech_name,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { triggered: data?.triggerManualTransactionTransition, errors };
	}

	async acceptTransactedMatch({
		id_transaction,
	}: MutationAcceptTransactedMatchArgs): Promise<{ accepted: boolean | null; errors: any }> {
		const payload = matchMutations.acceptTransactedMatch({ id_transaction });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { accepted: data?.acceptTransactedMatch, errors };
	}
	async forceTransactionTermination({
		id_transaction,
	}: MutationForceTransactionTerminationArgs): Promise<{ terminated: boolean | null; errors: any }> {
		const payload = transactionMutations.forceTransactionTermination({ id_transaction });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { terminated: data?.forceTransactionTerminations, errors };
	}

	async declineTransactedMatch({
		id_transaction,
	}: MutationDeclineTransactedMatchArgs): Promise<{ declined: boolean | null; errors: any }> {
		const payload = matchMutations.declineTransactedMatch({ id_transaction });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { declined: data?.declineTransactedMatch, errors };
	}

	async updateTransactedMatch({
		id_transaction,
		fields,
	}: MutationUpdateTransactedMatchArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = matchMutations.updateTransactedMatch({ id_transaction, fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { updated: data?.updateTransactedMatch, errors };
	}
	async updateDirectMatchForProvider({
		id,
		match_fields,
	}: MutationUpdateSingleDirectMatchForProviderArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = transactionMutations.updateDirectMatchForProvider({ id, match_fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { updated: data?.updateDirectMatchForProvider, errors };
	}
	async updateSingleDirectMatch({
		id,
		match_fields,
	}: MutationUpdateSingleDirectMatchArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = transactionMutations.updateSingleDirectMatch({ id, match_fields });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { updated: data?.updateTransactedMatch, errors };
	}

	async updateTransactionOrder({
		id_transaction,
		name,
		supply_filter,
		provider_filter,
		consumer_filter,
		fields,
	}: MutationUpdateTransactionOrderArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = matchingToolMutations.updateTransactionOrder({
			id_transaction,
			name,
			supply_filter,
			provider_filter,
			consumer_filter,
			fields,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { updated: data?.updateTransactionOrder, errors };
	}

	async discoverTransactionSupplies({
		transaction_tech_name,
		where,
		availability_params,
		available_supply_only,
		after,
		first
	}: QueryDiscoverTransactionSuppliesNewArgs): Promise<{ supplies: DiscoveredSupply[]; page_info: PageInfo, errors: any }> {
		const payload = discoveryQueries.discoverTransactionSupplies({
			transaction_tech_name,
			where,
			availability_params,
			available_supply_only,
			after,
			first
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		let supplies = data?.discoverTransactionSuppliesNEW?.edges?.map((edge: DiscoveredSupplyEdge) => edge.node);

		if (supplies) {
			supplies = (supplies as DiscoveredSupply[]).map((supply) => {
				const fields = unifyFieldValue(supply.fields);

				supply.fields = fields;
				supply.provider.fields = unifyFieldValue(supply.provider.fields);
				if (supply.variants)
					supply.variants = supply.variants.map((variant: any) => {
						variant.fields = unifyFieldValue(variant.fields);
						return variant;
					});

				return supply;
			});
		}

		return { supplies: supplies ?? [], page_info: data?.discoverTransactionSupplies?.page_info, errors };
	}

	async discoverTransactionSupplyDetails({
		id_supply,
		transaction_tech_name,
	}: QueryDiscoverTransactionSupplyDetailsArgs): Promise<{ supply: DiscoveredSupply | null; errors: any }> {
		const payload = discoveryQueries.discoverTransactionSupplyDetails({ id_supply, transaction_tech_name });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const supply = data?.discoverTransactionSupplyDetails;

		if (supply) {
			supply.fields = unifyFieldValue(supply.fields);
			supply.provider.fields = unifyFieldValue(supply.provider.fields);

			if (supply.variants)
				supply.variants = supply.variants.map((variant: SupplyVariant) => {
					variant.fields = unifyFieldValue(variant.fields);
					return variant;
				});
		}

		return { supply: supply, errors };
	}
	async discoverTransactionProviderDetails({
		id_participant,
		transaction_tech_name,
	}: QueryDiscoverTransactionProviderDetailsArgs): Promise<{ provider: Provider | null; errors: any }> {
		const payload = discoveryQueries.discoverTransactionProviderDetails({ id_participant, transaction_tech_name });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const provider = data?.discoverTransactionProviderDetails;

		if (provider) {
			provider.fields = unifyFieldValue(provider.fields);
		}

		return { provider: provider, errors };
	}
	async discoverTransactionProviders({
		transaction_tech_name,
		where,
		after,
		before,
		first,
		last,
	}: QueryDiscoverTransactionProvidersArgs): Promise<{ providers: Provider[]; page_info: PageInfo; errors: any }> {
		const payload = discoveryQueries.discoverTransactionProviders({
			where,
			transaction_tech_name,
			after,
			before,
			first,
			last,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const providers = data?.discoverTransactionProviders.edges.map((providerEdge: ProviderEdge) => {
			const provider = providerEdge.node;
			if (provider) {
				provider.fields = unifyFieldValue(provider.fields);
			}
			return provider;
		});

		return { providers, page_info: data?.discoverTransactionProviders?.page_info, errors };
	}

	async overrideTransactedMatchPrice({
		id_transaction,
		price,
	}: MutationOverrideTransactedMatchPriceArgs): Promise<{ updated: boolean | null; errors: any }> {
		const payload = transactionMutations.overrideTransactedMatchPrice({ id_transaction, price });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { updated: data?.overrideTransactedMatchPrice, errors };
	}

	async myMultiDirectTransactions({
		where,
		limit,
		offset,
	}: QueryMyShoppingCartsArgs): Promise<{ transactions: ShoppingCartLight[]; errors: any }> {
		const payload = transactionQueries.myMultiDirectTransactions({ where, limit, offset });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const shopping_carts = data?.myShoppingCarts?.map((shopping_cart: ShoppingCart) => {
			if (shopping_cart.request?.fields)
				shopping_cart.request.fields = unifyFieldValue(shopping_cart.request.fields);

			if (shopping_cart.request?.requester_participant?.fields)
				shopping_cart.request.requester_participant.fields = unifyFieldValue(
					shopping_cart.request.requester_participant.fields
				);

			return shopping_cart;
		});

		return { transactions: shopping_carts, errors };
	}

	async myActiveShoppingCart(): Promise<{
		shopping_cart: ShoppingCart | null;
		errors: any;
	}> {
		const payload = transactionQueries.myActiveShoppingCart();

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const shopping_cart = data?.myActiveShoppingCart;
		if (shopping_cart) {
			if (shopping_cart.fields) shopping_cart.fields = unifyFieldValue(shopping_cart.fields);
			shopping_cart.items.map((item: ShoppingCartItemForConsumer) => {
				if (item?.fields) item.fields = unifyFieldValue(item.fields);
				if (item?.provider_fields) item.provider_fields = unifyFieldValue(item.provider_fields);
				if (item?.supply_fields) item.supply_fields = unifyFieldValue(item.supply_fields);
			});
		}

		return { shopping_cart: shopping_cart, errors };
	}
	async myShoppingCartOrder({
		id_cart_order,
	}: QueryMyShoppingCartOrderArgs): Promise<{ transaction: ShoppingCartOrder | null; errors: any }> {
		const payload = transactionQueries.myShoppingCartOrder({ id_cart_order });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const shopping_cart = data?.myShoppingCartOrder;
		if (shopping_cart) {
			shopping_cart.items = shopping_cart.items.map((item: ShoppingCartItemForProvider) => {
				if (item.fields) item.fields = unifyFieldValue(item.fields);
				if (item.supply_fields) item.supply_fields = unifyFieldValue(item.supply_fields);
				if (item.consumer_fields) item.consumer_fields = unifyFieldValue(item.consumer_fields);

				return item;
			});

			if (shopping_cart.request?.fields)
				shopping_cart.request.fields = unifyFieldValue(shopping_cart.request.fields);

			if (shopping_cart.request?.requester_participant?.fields)
				shopping_cart.request.requester_participant.fields = unifyFieldValue(
					shopping_cart.request.requester_participant.fields
				);
		}

		return { transaction: shopping_cart, errors };
	}
	async myShoppingCart({
		id_cart,
	}: QueryMyShoppingCartArgs): Promise<{ shopping_cart: ShoppingCart | null; errors: any }> {
		const payload = transactionQueries.myShoppingCart({ id_cart });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const shopping_cart = data?.myShoppingCart;
		if (shopping_cart) {
			if (shopping_cart.fields) shopping_cart.fields = unifyFieldValue(shopping_cart.fields);
			shopping_cart.items.map((item: ShoppingCartItemForConsumer) => {
				if (item?.fields) item.fields = unifyFieldValue(item.fields);
				if (item?.provider_fields) item.provider_fields = unifyFieldValue(item.provider_fields);
				if (item?.supply_fields) item.supply_fields = unifyFieldValue(item.supply_fields);
			});
		}

		return { shopping_cart: shopping_cart, errors };
	}

	async createShoppingCart({
		fields,
		transaction_tech_name,
	}: MutationCreateShoppingCartArgs): Promise<{ shopping_cart: ShoppingCart | null; errors: any }> {
		const payload = transactionMutations.createShoppingCart({ fields, transaction_tech_name });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { shopping_cart: data?.createShoppingCart, errors };
	}
	async addSupplyToShoppingCart({
		fields,
		id_cart,
		id_supply,
	}: MutationAddSupplyToShoppingCartArgs): Promise<{ supply_added: boolean; errors: any }> {
		const payload = transactionMutations.addSupplyToShoppingCart({ fields, id_cart, id_supply });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { supply_added: data?.addSupplyToShoppingCart, errors };
	}
	async myShoppingCartOrders({
		where,
	}: QueryMyShoppingCartOrdersArgs): Promise<{ transactions: ShoppingCartOrderLight[]; errors: any }> {
		const payload = transactionQueries.myShoppingCartOrders({ where });

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		const shopping_cart_orders = data?.myShoppingCartOrders?.map((shopping_cart: ShoppingCartOrderLight) => {
			if (shopping_cart.request?.fields)
				shopping_cart.request.fields = unifyFieldValue(shopping_cart.request.fields);

			if (shopping_cart.request?.requester_participant?.fields)
				shopping_cart.request.requester_participant.fields = unifyFieldValue(
					shopping_cart.request.requester_participant.fields
				);

			return shopping_cart;
		});

		return { transactions: shopping_cart_orders, errors };
	}
	async removeItemFromShoppingCart({
		id_cart,
		id_item,
	}: MutationRemoveShoppingCartItemArgs): Promise<{ removed: boolean; errors: any }> {
		const payload = transactionMutations.removeItemFromShoppingCart({ id_cart, id_item });

		const { data, errors } = await this.randevu.call({ payload });

		return { removed: data?.removeItemFromShoppingCart, errors };
	}
	async updateShoppingCartItem({
		id_cart,
		id_item,
		fields,
	}: MutationUpdateShoppingCartItemArgs): Promise<{ shopping_cart_item: ShoppingCartItemForConsumer; errors: any }> {
		const payload = transactionMutations.updateShoppingCartItem({ id_cart, id_item, fields });

		const { data, errors } = await this.randevu.call({ payload });

		return { shopping_cart_item: data?.updateShoppingCartItem, errors };
	}
}

export default TransactionsService;
