import { Typography } from '@mui/material';
import { RdvDocumentSetFieldValue } from '@rdv-fo/services/randevuApi/types/field';

import FieldDocumentCellView from './FieldDocumentCellView';

interface FieldDocumentSetCellViewProps {
	value: RdvDocumentSetFieldValue;
}
const FieldDocumentSetCellView = ({ value }: FieldDocumentSetCellViewProps) => {
	if (value === null || value === undefined || value?.length === 0)
		return <Typography variant="body2">n.a.</Typography>;

	if (value?.length === 1) return <FieldDocumentCellView value={value[0]} />;

	return <Typography variant="body2">{`${value.length} documents`}</Typography>;
};

export default FieldDocumentSetCellView;
