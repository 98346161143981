import Typography from '@mui/material/Typography';

import FieldValueDocument from './FieldValueDocument';
import FieldValueDate from './FieldValueDate';
import FieldValueDatetime from './FieldValueDatetime';
import FieldValueDateSet from './FieldValueDateSet';
import FieldValueMultiSelect from './FieldValueMultiSelect';
import FieldValueInteger from './FieldValueInteger';
import FieldValueDecimal from './FieldValueDecimal';
import FieldValueBoolean from './FieldValueBoolean';
import FieldValueSingleSelect from './FieldValueSingleSelect';
import FieldValueImage from './FieldValueImage';
import FieldValueText from './FieldValueText';
import FieldValueLocation from './FieldValueLocation';
import FieldValueMonetaryValue from './FieldValueMonetaryValue';
import FieldValueObject from './FieldValueObject';
import FieldValueObjectSet from './FieldValueObjectSet';
import { InputKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { FieldValueProps } from './types';
import FieldValueAutoIncrementId from './FieldValueAutoIncrementId';
import FieldValueTextSet from './FieldValueTextSet';
import FieldValueSmartText from './FieldValueSmartText';
import { Box, Stack } from '@mui/material';
import FieldValueImageSet from './FieldValueImageSet';
import FieldValueDocumentSet from './FieldValueDocumentSet';

const FieldDisplayValue = ({
	fieldTechName,
	label,
	labelProps,
	inputType,
	inputOptions,
	value,
	valueProps,
	optionsUiConfig,
}: FieldValueProps) => {
	const commonProps = {
		fieldTechName,
		value,
		className: valueProps?.className,
	};

	const determineFieldValue = (inputType: InputKind) => {
		switch (inputType) {
			case InputKind.Integer:
				return <FieldValueInteger unit={inputOptions?.unit} {...commonProps} />;
			case InputKind.AutoincId:
				return <FieldValueAutoIncrementId {...commonProps} />;
			case InputKind.Decimal:
				return <FieldValueDecimal unit={inputOptions?.unit} {...commonProps} />;
			case InputKind.Boolean:
				return <FieldValueBoolean {...commonProps} />;
			case InputKind.Document:
				return <FieldValueDocument {...commonProps} />;
			case InputKind.Date:
				return <FieldValueDate {...commonProps} />;
			case InputKind.DateSet:
				return <FieldValueDateSet {...commonProps} />;
			case InputKind.Datetime:
				return <FieldValueDatetime {...commonProps} />;
			case InputKind.MultiSelect:
				return <FieldValueMultiSelect optionsUiConfig={optionsUiConfig ?? []} {...commonProps} />;
			case InputKind.Select:
				return <FieldValueSingleSelect optionsUiConfig={optionsUiConfig ?? []} {...commonProps} />;
			case InputKind.Image:
				return <FieldValueImage {...commonProps} altText={label} />;
			case InputKind.Text:
				return <FieldValueText {...commonProps} />;
			case InputKind.SmartText:
				return <FieldValueSmartText {...commonProps} format={inputOptions?.format} />;
			case InputKind.SmartTextSet:
				return <Stack direction='column'>
					{value?.map((value: string) =>
						<FieldValueSmartText fieldTechName={commonProps.fieldTechName} className={commonProps.className} value={value} format={inputOptions?.format} />
					)}
				</Stack>
			case InputKind.TextSet:
				return <FieldValueTextSet {...commonProps} />;
			case InputKind.MonetaryValue:
				return <FieldValueMonetaryValue {...commonProps} />;
			case InputKind.Location:
				return <FieldValueLocation {...commonProps} />;
			case InputKind.Object:
				return <FieldValueObject {...commonProps} valueProps={valueProps} labelProps={labelProps} />;
			case InputKind.ObjectSet:
				return <FieldValueObjectSet {...commonProps} valueProps={valueProps} labelProps={labelProps} />;
			case InputKind.ImageSet:
				return <FieldValueImageSet {...commonProps} />;
			case InputKind.DocumentSet:
				return <FieldValueDocumentSet {...commonProps} />;
			default:
				return (
					<Typography variant="body1" component="span">
						{`Field value for ${inputType} is coming soon..`}
					</Typography>
				);
		}
	};

	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
			{label && (
				<Typography style={{ wordWrap: 'break-word' }} variant="body1" fontWeight="600">
					{label}
				</Typography>
			)}
			<Box>{determineFieldValue(inputType)}</Box>
		</Stack>
	);
};

export default FieldDisplayValue;
