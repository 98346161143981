import Typography from '@mui/material/Typography';
import { useAppSelector } from '@rdv-fo/store/configureStore';

import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import { Box, Button, Divider, Stack } from '@mui/material';
import { selectDiscoveredConnectionParticipants, selectLoading } from '@rdv-fo/store/slices/connectionSlice';
import { Connection } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { findFieldByTechName } from '@rdv-fo/app/lib/fieldsHelper';
import { useHistory } from 'react-router';
import ROUTES from '@rdv-fo/common/routes';

const NoConnectionsFound = () => {
    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h3" align="center">
                No results found 😕
            </Typography>
            <Typography variant="subtitle2" align="center">
                Please try again with new arguments.
            </Typography>
        </Stack>
    );
};

interface DiscoveredConnectionCardProps {
    name: string;
    firstName: string;
    lastName: string;
    isConnected: boolean;
    onOpenParticipantDetails: () => void;
    onConnectParticipant: () => void;
    onViewConnection: () => void;
}

const DiscoveredConnectionCard = ({ name, firstName, lastName, isConnected, onViewConnection, onConnectParticipant, onOpenParticipantDetails }: DiscoveredConnectionCardProps) => {
    return (
        <Stack direction='column' spacing={2}>
            <Typography variant='body1'>{name}</Typography>
            <Typography variant='body1'>{`${firstName ?? ''} ${lastName ?? ''}`}</Typography>
            {isConnected ?
                <Stack direction='row' width='100%' justifyContent='flex-end'>
                    <Button variant='text' onClick={() => onViewConnection()}>View connection</Button>
                </Stack>
                :
                <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center'>
                    <Button variant='text' onClick={() => onOpenParticipantDetails()}>Details</Button>
                    <Button variant='contained' onClick={() => onConnectParticipant()}>Connect</Button>
                </Stack>
            }
        </Stack>
    )
}

interface DiscoveredConnectionsProps {
    connectionTypeTechName: string;
    connections: Connection[];
    onOpenParticipantDetails: (id: string) => void;
    onConnectParticipant: (id: string) => void;
}

const DiscoveredConnectionsMobileView = ({ connectionTypeTechName, connections, onOpenParticipantDetails, onConnectParticipant }: DiscoveredConnectionsProps) => {
    const discoveredConnectionParticipants = useAppSelector(selectDiscoveredConnectionParticipants);
    const loading = useAppSelector(selectLoading);
    const history = useHistory();
    let noResultsFound = discoveredConnectionParticipants !== null && discoveredConnectionParticipants?.length < 1

    return (
        <Box sx={{ height: '100%' }}>
            {loading && (
                <Box sx={{ mt: 16 }}>
                    <LoadingIndicator loading={loading} />
                </Box>
            )}

            {!loading && (
                <Box>
                    {noResultsFound && <NoConnectionsFound />}
                    {discoveredConnectionParticipants.map((dcp) => {
                        const connection = connections.find((connection) => connection.participant.id === dcp.id)
                        const isConnected = Boolean(connection)

                        const nameFieldValue = findFieldByTechName(dcp.fields, 'name')?.value
                        const firstNameFieldValue = findFieldByTechName(dcp.fields, 'first_name')?.value
                        const lastNameFieldValue = findFieldByTechName(dcp.fields, 'last_name')?.value

                        return (
                            <Stack direction='column' spacing={2}>
                                <DiscoveredConnectionCard isConnected={isConnected} name={nameFieldValue} firstName={firstNameFieldValue} lastName={lastNameFieldValue} onConnectParticipant={() => onConnectParticipant(dcp.id)} onOpenParticipantDetails={() => onOpenParticipantDetails(dcp.id)} onViewConnection={() => history.push(ROUTES.CONNECTION_DETAILS.replace(':id', connection?.id ?? ''))} />
                                <Divider />
                            </Stack>

                        )
                    })}
                </Box>
            )}
        </Box>
    );
};

export default DiscoveredConnectionsMobileView;
