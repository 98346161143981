import { combineReducers, AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import platformReducer from '@rdv-fo/store/slices/platformSlice';
import supplyReducer from '@rdv-fo/store/slices/supplySlice';
import discoverySlice from '@rdv-fo/store/slices/discoverySlice';
import authReducer from '@rdv-fo/store/slices/authSlice';
import { userSignedOut } from '@rdv-fo/store/slices/authSlice';
import matchSlice from '@rdv-fo/store/slices/matchSlice';
import connectionSlice from '@rdv-fo/store/slices/connectionSlice';
import uiReducer from '@rdv-fo/store/slices/uiSlice';
import paymentSlice from '@rdv-fo/store/slices/paymentSlice';
import transactionSlice from '@rdv-fo/store/slices/transactionSlice';
import { determinePlatformNameFromUrl } from '@rdv-fo/app/lib/url';
import devEnvConfig from '@rdv-fo/common/.localDevelopmentConfig';
import sharedObjectsSlice from '@rdv-fo/store/slices/sharedObjectsSlice';
import shoppingCartSlice from '@rdv-fo/store/slices/shoppingCartSlice';

const platformName = determinePlatformNameFromUrl() ?? devEnvConfig.PLATFORM_NAME;

const version = '1.1.0'; // TODO: read from package.json
const platformSlicePersistConfig = {
	key: `rfo-${version}-${platformName}-platform`,
	storage: storage,
	blacklist: ['isPlatformInitComplete'],
};

const appReducer = combineReducers({
	platform: persistReducer(platformSlicePersistConfig, platformReducer),
	ui: uiReducer,
	auth: authReducer,
	supply: supplyReducer,
	discovery: discoverySlice,
	match: matchSlice,
	payment: paymentSlice,
	transaction: transactionSlice,
	connection: connectionSlice,
	sharedObjects: sharedObjectsSlice,
	shoppingCart: shoppingCartSlice
});

// Removed saved store by redux-persist after a user signs out.
const rootReducer = (state: any, action: AnyAction) => {
	if (action.type === userSignedOut.type) {
		state = undefined;
	}
	return appReducer(state, action);
};

export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
