import Typography from '@mui/material/Typography';

interface FieldValueTextProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueText = ({ fieldTechName, value, className }: FieldValueTextProps) => {
	const renderValue = value === null ? 'n.a.' : value;
	return (
		<Typography id={fieldTechName} className={className}>
			{renderValue}
		</Typography>
	);
};

export default FieldValueText;
