import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@rdv-fo/store/configureStore';

import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import { selectDiscoveredProviders, selectLoading } from '@rdv-fo/store/slices/discoverySlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import DiscoveredProviderCard from './DiscoveredProviderCard';
import { Provider } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const NoProvidersFound = () => {
    return (
        <Stack sx={{ mt: 4 }} direction="column" spacing={2}>
            <Typography variant="h3" align="center">
                No results found 😕
            </Typography>
            <Typography variant="subtitle2" align="center">
                Please try again with new arguments.
            </Typography>
        </Stack>
    );
};

interface DiscoveredProvidersProps {
    transactionTypeTechName: string;
}

const DiscoveredProviders = ({ transactionTypeTechName }: DiscoveredProvidersProps) => {
    const discoveredProviders = useAppSelector(selectDiscoveredProviders);
    const loading = useAppSelector(selectLoading)
    let discovering = loading
    let noResultsFound = loading && discoveredProviders?.length < 1;

    const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoadingSpinnerAllowed(true);
        }, 500);
    }, []);

    return (
        <Box sx={{ height: '100%' }}>
            {discovering && loadingSpinnerAllowed && (
                <Box sx={{ mt: 16 }}>
                    <LoadingIndicator loading={discovering} />
                </Box>
            )}

            {!discovering && (
                <Box>
                    {noResultsFound && <NoProvidersFound />}
                    <Grid container spacing={4}>
                        {discoveredProviders?.map((provider: Provider) => {
                            return (
                                <Grid item key={provider.id_participant} xs={12} sm={6} md={4}>
                                    <DiscoveredProviderCard provider={provider} transactionTypeTechName={transactionTypeTechName} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default DiscoveredProviders;
