import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { RdvBooleanFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldBooleanCellViewProps {
	value: RdvBooleanFieldValue;
}
const FieldBooleanCellView = ({ value }: FieldBooleanCellViewProps) => {
	if (value == null) return <Typography>n.a.</Typography>;

	if (value === true) return <CheckIcon fontSize="small" />;

	return <CloseIcon fontSize="small" />;
};

export default FieldBooleanCellView;
