import Typography from '@mui/material/Typography';

interface FieldValueDocumentProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueDocument = ({ fieldTechName, value, className }: FieldValueDocumentProps) => {
	return (
		<Typography
			id={fieldTechName}
			className={className}
			component="a"
			target="_blank"
			rel="noopener"
			href={value?.url}
			download={value?.url}
		>
			<b>{value === null ? 'n.a.' : value?.file_name}</b>
		</Typography>
	);
};

export default FieldValueDocument;
