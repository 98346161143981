import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';

import decodeToJSONObject from '@rdv-fo/app/lib/decodeToJSONObject';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';
import PasswordResetForm from '@rdv-fo/components/security/ResetPasswordForm';
import AuthPageStyle from '@rdv-fo/components/layout/AuthPageStyle';
import { useTheme } from '@mui/material';
import { selectPlatformDisplayName, selectResetPasswordPageConfig } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const MissingResetPasswordParams = () => {
	const theme = useTheme();
	const platformDisplayName = useAppSelector(selectPlatformDisplayName);

	return (
		<>
			<Paper
				elevation={2}
				sx={{
					padding: 6,
					margin: theme.spacing(3, 0),
					[theme.breakpoints.down('lg')]: {
						margin: theme.spacing(3, 2),
					},
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'start',
				}}
			>
				<Avatar sx={{ margin: 1, backgroundColor: theme.palette.secondary.main, alignSelf: 'center' }}>
					<LockOutlinedIcon />
				</Avatar>
				<div style={{ width: '100%' }}>
					<Typography component="h1" align="center" variant="h5" sx={{ paddingBottom: 3 }}>
						{platformDisplayName}
					</Typography>
				</div>
				<Typography component="h2" variant="h5" gutterBottom align="left">
					Oops, your link is broken
				</Typography>
				<Typography variant="subtitle2" color="textSecondary" sx={{ paddingTop: 1.5 }}>
					Something is wrong with the link you are using for resetting your password.
				</Typography>
				<Typography variant="subtitle2" color="textSecondary" sx={{ paddingTop: 1.5 }}>
					Please make sure you are using the link you received in your email.
				</Typography>
			</Paper>
		</>
	);
};

const ResetPasswordPage = () => {
	const { data } = usePrimitiveQueryParams();
	const { style, image_position, image_url, form, title } = useAppSelector(selectResetPasswordPageConfig) ?? {};

	const { token, email, marketplace_name } = decodeToJSONObject(data);

	const showInvalidUrl = [data, token, email, marketplace_name].includes(undefined);

	return (
		<>
			{showInvalidUrl && <MissingResetPasswordParams />}
			{!showInvalidUrl && (
				<AuthPageStyle
					style={style}
					imagePosition={image_position}
					image={image_url}
				>
					<PasswordResetForm email={email} token={token} marketplace_name={marketplace_name} form={form} title={title} />
				</AuthPageStyle>
			)}
		</>
	);
};

export default ResetPasswordPage;
