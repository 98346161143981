import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import { useAppSelector } from '@rdv-fo/store/configureStore';
import { selectLandingPageConfig } from '@rdv-fo/store/slices/platformSlice';

import PageWidth from '../layout/PageWidth';
import { ASSETS } from '@rdv-fo/app/assets';
import { Theme, useTheme } from '@mui/material';

const UniqueSellingPoints = () => {
	const theme: Theme = useTheme();

	const landing_page_config = useAppSelector(selectLandingPageConfig);

	return (
		<section id="usp-section">
			<PageWidth padding={0} paddingLeft={0} paddingRight={0} paddingBottom={0} paddingTop={0} maxWidth="lg">
				<Typography variant="h2" align="center" sx={{ mb: 8 }}>
					{landing_page_config?.unique_selling_points?.title ?? 'Unique Selling Points'}
				</Typography>
				<Stack
					sx={{ p: { xs: 4, sm: 4 } }}
					direction="column"
					justifyContent="center"
					alignItems="stretch"
					spacing={4}
				>
					<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={0}>
						<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
							<Grid item xs={12} sm={6}>
								<img
									style={{
										[theme.breakpoints.up('xs')]: {
											width: '78vw',
										},
										[theme.breakpoints.up('sm')]: {
											width: '36vw',
										},
										borderRadius: theme.spacing(1),
										maxWidth: '500px',
									}}
									src={
										landing_page_config?.unique_selling_points?.image_1_url ??
										ASSETS.RANDEVU.LANDING_PAGE_USP_SECTION.USP_1
									}
									alt=""
								></img>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
									<Typography color="primary" variant="subtitle2">
										{landing_page_config?.unique_selling_points?.text_1_subtitle ??
											'Browse our rich offer and find your perfect service'}
									</Typography>
									<Typography variant="h3">
										{landing_page_config?.unique_selling_points?.text_1_title ??
											'Chat with your providers and fix details in just a few minutes'}
									</Typography>
									<Typography variant="body1">
										{landing_page_config?.unique_selling_points?.text_1_description ??
											'Sit back and enjoy while your work is getting done'}
									</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Grid container spacing={2} style={{
							padding: '10% 0',
							width: '100%',
							alignContent: 'space-around',
							justifyContent: 'center',
							alignItems: 'cenmter',
							[theme.breakpoints.up('xs')]: {
								flexWrap: 'wrap-reverse',
							},
						}} >
							<Grid sx={{
								[theme.breakpoints.up('xs')]: {
									textAlign: 'end',
								},
								[theme.breakpoints.up('sm')]: {
									textAlign: 'end',
								},
							}} item xs={12} sm={6}>
								<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
									<Typography color="primary" variant="subtitle2" align="left">
										{landing_page_config?.unique_selling_points?.text_2_subtitle ??
											'Browse our rich offer and find your perfect service'}
									</Typography>
									<Typography variant="h3" align="left">
										{landing_page_config?.unique_selling_points?.text_2_title ??
											'Chat with your providers and fix details in just a few minutes'}
									</Typography>
									<Typography variant="body1" align="left">
										{landing_page_config?.unique_selling_points?.text_2_description ??
											'Sit back and enjoy while your work is getting done'}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6}>
								<img
									style={{
										[theme.breakpoints.up('xs')]: {
											width: '78vw',
										},
										[theme.breakpoints.up('sm')]: {
											width: '36vw',
										},
										borderRadius: theme.spacing(1),
										maxWidth: '500px',
									}}
									src={
										landing_page_config?.unique_selling_points?.image_2_url ??
										ASSETS.RANDEVU.LANDING_PAGE_USP_SECTION.USP_2
									}
									alt="unique point 2"
								></img>
							</Grid>
						</Grid>
						<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
							<Grid item xs={12} sm={6}>
								<img
									style={{
										[theme.breakpoints.up('xs')]: {
											width: '78vw',
										},
										[theme.breakpoints.up('sm')]: {
											width: '36vw',
										},
										borderRadius: theme.spacing(1),
										maxWidth: '500px',
									}}
									src={
										landing_page_config?.unique_selling_points?.image_3_url ??
										ASSETS.RANDEVU.LANDING_PAGE_USP_SECTION.USP_3
									}
									alt="Unique point 3"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
									<Typography color="primary" variant="subtitle2">
										{landing_page_config?.unique_selling_points?.text_3_subtitle ??
											'Browse our rich offer and find your perfect service'}
									</Typography>
									<Typography variant="h3">
										{landing_page_config?.unique_selling_points?.text_3_title ??
											'Chat with your providers and fix details in just a few minutes'}
									</Typography>
									<Typography variant="body1">
										{landing_page_config?.unique_selling_points?.text_3_description ??
											'Sit back and enjoy while your work is getting done'}
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</PageWidth>
		</section>
	);
};

export default UniqueSellingPoints;
