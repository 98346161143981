import { Link as RouterLink } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@rdv-fo/store/configureStore';

import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { Box, CardActionArea, CardMedia, Stack } from '@mui/material';
import { ASSETS } from '@rdv-fo/app/assets';

import { formatDatetimeShort } from '@rdv-fo/app/lib/datetime';

import { selectCurrentTransactionType } from '@rdv-fo/store/slices/transactionSlice';
import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';
import {
	CurrencyKind,
	FieldCategoryKind,
	MatchingUnitKind,
	SupplyStatusKind,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { RDV_NEUTRAL_00 } from '@rdv-fo/styles/theme';
import { formatPriceAmount } from './supplyHelpers';

const SupplyCard = ({ supply, transactionTypeTechName }) => {
	const currentTransactionType = useAppSelector(selectCurrentTransactionType);
	const fieldDescription = findFieldByCategory(supply?.fields, FieldCategoryKind.Description);
	const fieldName = findFieldByCategory(supply?.fields, FieldCategoryKind.Name);
	const fieldMainImage = findFieldByCategory(supply?.fields, FieldCategoryKind.MainImage);
	const fieldPrice = findFieldByCategory(supply?.fields, FieldCategoryKind.BasePrice);
	const fieldDatetime = findFieldByCategory(supply?.fields, FieldCategoryKind.MainDatetime);

	const isMatchConfigurationPricingEnabled = supply?.type?.match_configurations?.[0]?.pricing_enabled;

	const matchingUnitType = currentTransactionType?.root_type.match_type.matching_unit_type || {};
	let matchingUnitKindSuffix = '';
	if (matchingUnitType === MatchingUnitKind.PerDay) matchingUnitKindSuffix = '/day';
	if (matchingUnitType === MatchingUnitKind.PerHour) matchingUnitKindSuffix = '/hour';

	let formattedPriceAmount =
		fieldPrice?.value === null
			? ''
			: formatPriceAmount({
					currency: fieldPrice?.value?.currency ?? CurrencyKind.Eur,
					amount: fieldPrice?.value?.amount ?? 0,
			  });
	if (formattedPriceAmount) formattedPriceAmount = `${formattedPriceAmount}${matchingUnitKindSuffix}`;

	const variantsLabel = `${
		supply?.variants?.length + 1
	} variants`;
	const variantsExist =
		(supply?.variants?.length??0) > 0;

	return (
		<>
			<Card
				sx={{
					borderRadius: 1.5,
					display: 'flex',
					height: '100%',
					flexDirection: 'column',
					alignItems: 'stretch',
				}}
			>
				<CardActionArea
					component={RouterLink}
					to={routeBuilder(ROUTES.DISCOVERED_TRANSACTION_SUPPLY_DETAILS, [
						[':transactionTypeTechName', transactionTypeTechName],
						[':supplyId', supply.id],
					])}
					sx={{ height: '100%', objectFit: 'contain' }}
				>
					<CardMedia
						component="img"
						src={fieldMainImage?.value?.url || ASSETS.RANDEVU.IMAGE_UNAVAILABLE}
						title={fieldName?.value}
						sx={{ height: 200, objectFit: 'contain' }}
					/>
					<CardContent
						sx={{
							backgroundColor: RDV_NEUTRAL_00,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							overflowWrap: 'break-word',
							height: '100%',
						}}
					>
						<Stack direction="column" justifyContent="flex-end" alignItems="stretch" spacing={1}>
							{fieldName && (
								<Typography
									variant="subtitle2"
									sx={{
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
									}}
								>
									{fieldName?.value ?? '-'}
								</Typography>
							)}

							{fieldDatetime && (
								<Typography variant="body2">{formatDatetimeShort(fieldDatetime.value)}</Typography>
							)}
							{fieldDescription && (
								<Typography
									display="inline"
									variant="body2"
									sx={{
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										// display: '-webkit-box',
										'-webkit-line-clamp': 2,
										'-webkit-box-orient': 'vertical',
									}}
								>
									{fieldDescription?.value ?? '-'}
								</Typography>
							)}
							{/* {restOfFields?.map((field) => {
								return (
									<FieldDisplayValue
										key={field?.field_type.tech_name}
										label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
										fieldTechName={field?.field_type.tech_name}
										inputType={field?.field_type.input_type}
										inputOptions={field?.field_type.input_options}
										optionsUiConfig={field?.field_type?.ui_config?.options}
										value={field.value}
										valueProps={{ className: classes.singleLineEllipsis }}
									/>
								);
							})} */}
						</Stack>

						<Stack direction="column" width="100%" sx={{ mt: 2 }}>
							<Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
								<Box>{variantsExist && <Typography variant="body2">{variantsLabel}</Typography>}</Box>
								<Box>
									{fieldPrice && isMatchConfigurationPricingEnabled && (
										<Typography variant="subtitle2">{formattedPriceAmount}</Typography>
									)}
								</Box>
							</Stack>
						</Stack>
					</CardContent>
				</CardActionArea>
			</Card>
		</>
	);
};

export default SupplyCard;
