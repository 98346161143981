import { Grid, Stack } from '@mui/material';
import ROUTES from '@rdv-fo/common/routes';
import BackButton from '@rdv-fo/components/common/BackButton';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import PageWidth from '@rdv-fo/components/layout/PageWidth';
import SharedObjectUpdateForm from '@rdv-fo/components/shared-objects/SharedObjectUpdateForm';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { loadMySharedObjectTypes, loadMySharedObjects, selectLoading, selectMySharedObjectTypes, selectMySharedObjects } from '@rdv-fo/store/slices/sharedObjectsSlice';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';

const UpdateMySharedObjectPage = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const sharedObjects = useAppSelector(selectMySharedObjects);
    const sharedObjectTypes = useAppSelector(selectMySharedObjectTypes);
    const currentSharedObject = sharedObjects.find((so) => so.id == id);
    const loading = useAppSelector(selectLoading);
    const history = useHistory();
    const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(true);

    useEffect(() => {
        dispatch(loadMySharedObjects({}));
        dispatch(loadMySharedObjectTypes());
        const loadingSpinnerTimeout = setTimeout(() => {
            setLoadingSpinnerAllowed(false);
        }, 500);

        return () => {
            clearTimeout(loadingSpinnerTimeout);
        };
    }, [])

    useEffect(() => {
        if ((!loading && !currentSharedObject && !loadingSpinnerAllowed) || (!id && !loadingSpinnerAllowed))
            history.push(ROUTES.MY_SHARED_OBJECTS);
    }, [loadingSpinnerAllowed, loading])

    return (loading && loadingSpinnerAllowed) ? (
        <Grid container sx={{ height: 'calc(100vh - 150px)' }}>
            {loadingSpinnerAllowed ? <LoadingIndicator loading={loading} /> : null}
        </Grid>
    ) : (
        <PageWidth maxWidth="md">
            <Stack direction="column" spacing={5}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} width="100%">
                        <BackButton label="My shared objects" to={ROUTES.MY_SHARED_OBJECTS} />
                    </Grid>
                </Grid>

                <SharedObjectUpdateForm sharedObject={currentSharedObject} sharedObjectTypes={sharedObjectTypes} sharedObjects={sharedObjects} />
            </Stack>
        </PageWidth>
    );
}

export default UpdateMySharedObjectPage