import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { selectTargetRoute, selectPushRoute, redirected } from '@rdv-fo/store/slices/uiSlice';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';

const RedirectTo = () => {
	const dispatch = useAppDispatch();
	const targetRoute = useAppSelector(selectTargetRoute);
	const pushRoute = useAppSelector(selectPushRoute);

	useEffect(() => {
		if (targetRoute) {
			dispatch(redirected());
		}
	}, [targetRoute, dispatch]);

	return targetRoute ? <Redirect to={targetRoute} push={pushRoute} /> : null;
};

export default RedirectTo;
