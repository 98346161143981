import { Stack, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { DEFAULT_PAGE_SIZE, buildTransfersTableColumns, buildTransfersTableRows } from '@rdv-fo/app/lib/tableHelpers';
import { RdvFieldValue } from '@rdv-fo/services/randevuApi/types/field';

const NoDataView = () => {
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
                No transfers yet
            </Typography>
        </Stack>
    );
};

interface TransfersTableProps {
    transfers: any;
    loading: boolean;
    disabled: boolean;
    onOpenDrawer: (id: string | null) => void;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    onPreviousPage: () => void;
    onNextPage: () => void;
}

const TransfersTable = ({
    transfers = [],
    loading,
    disabled,
    onOpenDrawer, hasNextPage, hasPreviousPage, onNextPage, onPreviousPage
}: TransfersTableProps) => {
    const transferColumns = buildTransfersTableColumns({ onOpenDrawer });

    const transferRows = buildTransfersTableRows(transfers);

    return (
        <>
            <MaterialReactTable
                initialState={{
                    pagination: { pageSize: 20, pageIndex: 0 },
                }}
                columns={transferColumns}
                enablePinning={false}
                data={transferRows}
                enableSorting={false}
                enablePagination={true}
                enableDensityToggle={false}
                enableGlobalFilter={false}
                enableColumnFilters={false} //disable all column filters
                enableColumnFilterModes={false}
                enableColumnActions={false}
                rowCount={transfers?.length}
                muiTableBodyProps={{
                    children: transfers?.length < 1 ? <NoDataView /> : null,
                }}
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: '0',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        '&:hover': {
                            backgroundColor: RDV_NEUTRAL_20,
                        },
                    },
                }}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
                    showFirstButton: false,
                    showLastButton: false,
                    backIconButtonProps: {
                        disabled: !hasPreviousPage || loading,
                        onClick: onPreviousPage,
                    },
                    nextIconButtonProps: {
                        disabled: !hasNextPage || loading,
                        onClick: onNextPage,
                    },
                }}
                enableFullScreenToggle={false}
                enableRowOrdering={false}
                enableHiding={false}
                muiTableHeadCellProps={{
                    sx: {
                        backgroundColor: RDV_NEUTRAL_10,
                        fontSize: '0.889rem',
                        fontWeight: 600,
                        letterSpacing: 0,
                        lineHeight: '1.4rem',
                    },
                }}
                enableTableFooter={false}
            />
        </>
    );
};

export default TransfersTable;
