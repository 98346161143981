import LabelFilterInput from './LabelFilterInput';

import { FilterInputBaseProps } from './types';

import { DatePicker } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';



// FIXME: set proper types
const parse = (value: any) => {
	if (value === undefined || value === null) return null;

	return value?.toISOString().split('T')[0];
};

// FIXME: set proper types
const format = (value: any) => {
	if (value === undefined || value === null) return null;
	return new Date(value);
};
interface FilterInputDateProps extends FilterInputBaseProps { }

const FilterInputDate = ({ techName, label, noLabel, disabled = false, required = false }: FilterInputDateProps) => {

	return (
		<Grid spacing={1}>
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}
			<LocalizationProvider dateAdapter={DateFnsUtils}>
				<DatePicker
					name={`${techName}.min_date`}
					inputFormat="dd. MMM yyyy"
					InputProps={{ onKeyDown: e => e.preventDefault() }}
					disableMaskedInput
					fieldProps={{
						parse,
						format,
						disabled,
						inputVariant: 'outlined',
						clearable: true,
						placeholder: 'From',
					}}
				/>
			</LocalizationProvider>
			<LocalizationProvider dateAdapter={DateFnsUtils}>
				<DatePicker
					name={`${techName}.max_date`}
					InputProps={{ onKeyDown: e => e.preventDefault() }}
					disableMaskedInput
					inputFormat="dd. MMM yyyy"
					fieldProps={{
						parse,
						format,
						disabled,
						inputVariant: 'outlined',
						clearable: true,
						placeholder: 'To',
					}}
				/>
			</LocalizationProvider>
		</Grid>
	);
};

export default FilterInputDate;
