import { Box, Stack, Typography } from '@mui/material';

import FieldInputImage from './FieldInputImage';
import FieldInputImageSet from './FieldInputImageSet';
import FieldInputSingleSelect from './FieldInputSingleSelect';
import FieldInputDocument from './FieldInputDocument';
import FieldInputDocumentSet from './FieldInputDocumentSet';
import FieldInputDate from './FieldInputDate';
import FieldInputDateSet from './FieldInputDateSet';
import FieldInputDatetime from './FieldInputDatetime';
import FieldInputMultiSelect from './FieldInputMultiSelect';
import FieldInputInteger from './FieldInputInteger';
import FieldInputDecimal from './FieldInputDecimal';
import FieldInputBoolean from './FieldInputBoolean';
import FieldInputMonetaryValue from './FieldInputMonetaryValue';
import FieldInputText from './FieldInputText';
import FieldInputLocation from './FieldInputLocation';
import FieldInputObject from './FieldInputObject';
import FieldInputObjectSet from './FieldInputObjectSet';
import {
	InputKind,
	FieldType,
	ObjectType,
	Object,
	FieldTypeSelectUiConfigOption,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { LabelFieldInputProps } from './types';
import FieldInputAutoIncrementId from './FieldInputAutoIncrementId';
import FieldInputTextSet from './FieldInputTextSet';
import FieldInputSmartText from './FieldInputSmartText';
import FieldInputSharedObject from './FieldInputSharedObject';
import FieldInputSharedObjectSet from './FieldInputSharedObjectSet';
import FieldValueSmartText from '../display/FieldValueSmartText';
import { useFormState } from 'react-final-form';
import FieldInputSmartTextSet from './FieldInputSmartTextSet';

interface FieldInputProps {
	fieldTechName: string;
	label?: string;
	helperText?: string | null;
	labelProps?: LabelFieldInputProps;
	optionsUiConfig?: FieldTypeSelectUiConfigOption[];
	disabled?: boolean;
	required?: boolean;
	inputType: InputKind;
	inputOptions: any; // FIXME: use type from the backend
	objectTypeFields?: FieldType[];
	sharedObjectTypes: ObjectType[];
	sharedObjects: Object[];
	readOnlyField?: boolean;
}

const FieldInput = ({
	fieldTechName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	optionsUiConfig,
	inputType,
	inputOptions,
	objectTypeFields = [],
	sharedObjectTypes,
	sharedObjects,
	readOnlyField = false,
}: FieldInputProps) => {
	const fieldValue = useFormState()?.values?.[fieldTechName];

	const commonProps = {
		label: label,
		labelProps,
		helperText: helperText ?? '',
		techName: fieldTechName,
		disabled: disabled,
		required: required,
	};

	if (InputKind.Boolean === inputType) return <FieldInputBoolean {...commonProps} />;

	if (InputKind.Text === inputType)
		return (
			<FieldInputText
				{...commonProps}
				minLength={inputOptions?.min_length}
				maxLength={inputOptions?.max_length}
				keepNull={true}
			/>
		);

	if (InputKind.SmartText === inputType) {
		if (readOnlyField) {
			//TODO @Rokva -> Remove workaround
			return (
				<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
					{label && (
						<Typography style={{ wordWrap: 'break-word' }} variant="body1" fontWeight="600">
							{label}
						</Typography>
					)}
					<Box>
						<FieldValueSmartText
							value={fieldValue}
							{...commonProps}
							className=""
							fieldTechName={fieldTechName}
							format={inputOptions?.format}
						/>
					</Box>
				</Stack>
			);
		}
		return (
			<FieldInputSmartText
				{...commonProps}
				pattern={inputOptions?.pattern}
				format={inputOptions?.format}
				keepNull={true}
			/>
		);
	}
	if (InputKind.SmartTextSet === inputType) {
		return (
			<FieldInputSmartTextSet
				{...commonProps}
				pattern={inputOptions?.pattern}
				format={inputOptions?.format}
				keepNull={true}
			/>
		);
	}

	if (InputKind.TextSet === inputType)
		return (
			<FieldInputTextSet
				{...commonProps}
				minLength={inputOptions.min_single_length}
				maxLength={inputOptions.max_single_length}
				minItems={inputOptions?.min_texts_in_set}
				maxItems={inputOptions?.max_texts_in_set}
			/>
		);

	if (InputKind.Integer === inputType)
		return (
			<FieldInputInteger
				{...commonProps}
				minValue={inputOptions?.min_value}
				maxValue={inputOptions?.max_value}
				unit={inputOptions?.unit}
			/>
		);

	if (InputKind.Decimal === inputType)
		return (
			<FieldInputDecimal
				{...commonProps}
				minValue={inputOptions?.min_value}
				maxValue={inputOptions?.max_value}
				unit={inputOptions?.unit}
			/>
		);

	if (InputKind.Image === inputType)
		return (
			<FieldInputImage
				{...commonProps}
				allowedTypes={inputOptions?.allowed_types}
				maxSize={inputOptions?.max_size}
			/>
		);

	if (InputKind.ImageSet === inputType)
		return (
			<FieldInputImageSet
				{...commonProps}
				allowedTypes={inputOptions?.allowed_types}
				maxSize={inputOptions?.max_single_size}
				maxImages={inputOptions?.max_images_in_set}
				minImages={inputOptions?.min_images_in_set}
			/>
		);

	if (InputKind.Select === inputType)
		return (
			<FieldInputSingleSelect {...commonProps} options={inputOptions?.values} optionsUiConfig={optionsUiConfig} />
		);

	if (InputKind.Document === inputType)
		return (
			<FieldInputDocument
				{...commonProps}
				allowedTypes={inputOptions?.allowed_types}
				maxSize={inputOptions?.max_size}
			/>
		);

	if (InputKind.DocumentSet === inputType)
		return (
			<FieldInputDocumentSet
				{...commonProps}
				allowedTypes={inputOptions?.allowed_types}
				maxSize={inputOptions?.max_single_size}
				maxDocs={inputOptions?.max_docs_in_set}
				minDocs={inputOptions?.min_docs_in_set}
			/>
		);

	if (InputKind.Date === inputType)
		return (
			<FieldInputDate
				{...commonProps}
				pastOnly={inputOptions?.past_only}
				futureOnly={inputOptions?.future_only}
			/>
		);

	if (InputKind.DateSet === inputType)
		return (
			<FieldInputDateSet
				{...commonProps}
				pastOnly={inputOptions?.past_only}
				futureOnly={inputOptions?.future_only}
				minItems={inputOptions?.min_dates_in_set}
				maxItems={inputOptions?.max_dates_in_set}
			/>
		);

	if (InputKind.MultiSelect === inputType)
		return (
			<FieldInputMultiSelect
				{...commonProps}
				optionsUiConfig={optionsUiConfig}
				options={inputOptions?.values}
				minSelected={inputOptions?.min_selected}
				maxSelected={inputOptions?.max_selected}
			/>
		);

	if (InputKind.MonetaryValue === inputType)
		return (
			<FieldInputMonetaryValue
				{...commonProps}
				minValue={inputOptions?.min_value}
				maxValue={inputOptions?.max_value}
				currency={inputOptions?.currency}
			/>
		);

	if (InputKind.Datetime === inputType)
		return (
			<FieldInputDatetime
				{...commonProps}
				pastOnly={inputOptions?.past_only}
				futureOnly={inputOptions?.future_only}
			/>
		);

	if (InputKind.Object === inputType) {
		const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);
		const filteredSharedObjects = sharedObjects.filter((so) => so.id_object_type === inputOptions?.id_object_type);
		if (sharedObjectTypeIds?.includes(inputOptions?.id_object_type)) {
			return (
				<FieldInputSharedObject
					{...commonProps}
					sharedObjects={filteredSharedObjects}
					sharedObjectTypes={sharedObjectTypes}
				/>
			);
		}

		return (
			<FieldInputObject
				sharedObjects={sharedObjects}
				sharedObjectTypes={sharedObjectTypes}
				{...commonProps}
				objectTypeFields={objectTypeFields}
			/>
		);
	}

	if (InputKind.ObjectSet === inputType) {
		const sharedObjectTypeIds = sharedObjectTypes?.map((sot) => sot.id);
		if (sharedObjectTypeIds.includes(inputOptions?.id_object_type)) {
			const filteredSharedObjects = sharedObjects.filter(
				(so) => so.id_object_type === inputOptions?.id_object_type
			);
			return (
				<>
					<FieldInputSharedObjectSet
						{...commonProps}
						sharedObjects={filteredSharedObjects}
						sharedObjectTypes={sharedObjectTypes}
						objectTypeFields={objectTypeFields}
						minObjects={inputOptions?.min_objects_in_set}
						maxObjects={inputOptions?.max_objects_in_set}
					/>
				</>
			);
		}

		return (
			<FieldInputObjectSet
				{...commonProps}
				sharedObjects={sharedObjects}
				sharedObjectTypes={sharedObjectTypes}
				objectTypeFields={objectTypeFields}
				minObjects={inputOptions?.min_objects_in_set}
				maxObjects={inputOptions?.max_objects_in_set}
			/>
		);
	}

	if (InputKind.Location === inputType) return <FieldInputLocation {...commonProps} />;

	if (InputKind.AutoincId === inputType) return <FieldInputAutoIncrementId {...commonProps} />;

	return (
		<div>
			<Typography>Input for {inputType} field is coming soon...</Typography>
		</div>
	);
};

export default FieldInput;
