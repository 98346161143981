import { Link as RouterLink } from 'react-router-dom';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Button, Icon, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20, RDV_NEUTRAL_30 } from '@rdv-fo/styles/theme';
import ROUTES from '@rdv-fo/common/routes';
import { InputKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import { RdvCellValue } from '@rdv-fo/app/lib/tableHelpers';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { CollectionItem } from '@rdv-fo/store/slices/supplySlice';
import arrayMove from 'array-move';
import { FormApi } from 'final-form';
import { useTheme } from '@mui/material';

export const buildCollectionItemsTableRows = (items: CollectionItem[]): any[] => {
	const rows = items.map((item) => {
		return {
			_id: { value: item.id, input_type: InputKind.Text },
			name_and_image_and_variant_id: { value: { name: item.name, image: item.image, variantId: item.variantId } },
		};
	});

	return rows;
};



export const buildCollectionItemsTableColumns = (): MRT_ColumnDef<any>[] => {


	const defaultColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'name_and_image_and_variant_id',
			header: 'Name',
			Cell: ({ cell }) => {
				const data = cell.getValue<any>();
				const isImageAvailable = Boolean(data.value?.image);
				const theme = useTheme();
				return (
					<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
						{isImageAvailable ? <FieldView inputKind={InputKind.Image} value={data.value?.image} /> : <Icon sx={{ width: 40, height: 40, backgroundColor: theme.palette.grey[300], borderRadius: 1 }} />}
						<FieldView inputKind={InputKind.Text} value={`${data.value?.name} ${data.value?.variantId ? `- ${data.value?.variantId}` : ''}`} />
					</Stack>
				);
			},
		},
	];

	return defaultColumns;
};

const NoDataView = () => {
	return (
		<Stack mt={2} direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<Typography align="center" variant="body1">
				<DoDisturbIcon sx={{ height: 60, width: 60, color: RDV_NEUTRAL_30 }} />
			</Typography>
			<Typography align="center" variant="body1">
				Your collection is empty
			</Typography>
			<Typography align="center" fontWeight="bold" variant="body1">
				Add items to fill the collection
			</Typography>
		</Stack>
	);
};

interface CollectionItemsTableProps {
	items: CollectionItem[];
	onRemove: (supplyId: string) => void;
	form: FormApi;
}

const CollectionItemsTable = ({ items = [], onRemove, form }: CollectionItemsTableProps) => {
	if (items?.length === 0) return <NoDataView />;

	const collectionColumnDefinitions = buildCollectionItemsTableColumns();
	const collectionRow = buildCollectionItemsTableRows(items);



	const handleSortCollectionItems = ({ oldIndex: from, newIndex: to }: { oldIndex: number; newIndex: number }) => {
		const newOrder = arrayMove(items, from, to);

		form.change('items', newOrder);
		form.change('itemOrderTouched', true);
	}

	return (
		<>
			<MaterialReactTable
				initialState={{
					pagination: { pageSize: 50, pageIndex: 0 },
					columnVisibility: {
						_id: false,
					},
				}}
				localization={{
					actions: ""
				}}
				columns={collectionColumnDefinitions}
				enablePinning={false}
				data={collectionRow}
				enableTableHead={true}
				enableTopToolbar={false}
				enableSorting={false}
				muiTableBodyRowDragHandleProps={({ table }) => ({
					onDragEnd: () => {
						const { draggingRow, hoveredRow } = table.getState();

						if (hoveredRow && draggingRow) {
							const oldIndex = (draggingRow as MRT_Row<any>).index;
							const newIndex = (hoveredRow as MRT_Row<any>).index;
							if (oldIndex !== newIndex) handleSortCollectionItems({ oldIndex, newIndex });
						}
					},

					sx: { opacity: 1 },
				})}
				enablePagination={false}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnActions={false}
				enableHiding={false}
				enableColumnFilters={false}
				enableColumnFilterModes={false}
				rowCount={items?.length}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={{
					sx: {
						'&:hover': {
							backgroundColor: RDV_NEUTRAL_20,
						},
					},
				}}
				positionActionsColumn="last"
				enableRowActions={true}
				renderRowActions={({ row }) => {
					const supplyId = row.original?._id?.value;
					const targetRoute = routeBuilder(ROUTES.UPDATE_MY_SUPPLY, [[':supplyId', supplyId]]);

					return (
						<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
							<Button aria-label="remove" size="small" color="inherit" onClick={() => onRemove(supplyId)}>
								Remove
							</Button>
							<Button
								component={RouterLink}
								to={targetRoute}
								target="_blank"
								aria-label="view details"
								size="small"
								color="inherit"
							>
								Details
							</Button>
						</Stack>
					);
				}}
				enableFullScreenToggle={false}
				enableRowOrdering
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				renderBottomToolbar={
					items?.length > 0 &&
					(() => {
						let label = `${items?.length} items`;
						if (items?.length === 1) label = `${items?.length} item`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default CollectionItemsTable;
