import { RdvImageFieldValue } from '@rdv-fo/services/randevuApi/types/field/value.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from '@rdv-fo/store/configureStore';

import routeBuilder from '@rdv-fo/common/routeBuilder';
import ROUTES from '@rdv-fo/common/routes';
import { hasSubmissionErrors, isDuplicate, isNotFound } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import { sleep } from '@rdv-fo/services/sleep';
import randevu from '@rdv-fo/services/randevuApi';
import {
	goToRoute,
	sendSuccessToasty,
	sendInfoToasty,
	sendUnexpectedErrorToasty,
	sendErrorToasty,
} from '@rdv-fo/store/slices/uiSlice';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { isSessionExpired } from '@rdv-fo/services/randevuApi/errors/errorHelper';
import THUNKS from '@rdv-fo/store/thunkMap';
import {
	Supply,
	SupplyStatusKind,
	Field,
	SupplyType,
	FieldInput,
	MutationCreateSupplyArgs,
	FieldCategoryKind,
	MutationCreateCollectionArgs,
	Collection,
	MutationUpdateCollectionArgs,
	DataImportJobStatusReport,
	MutationDeactivateSupplyArgs,
	MutationActivateSupplyArgs,
	QueryMySuppliesArgs,
	MutationCreateSupplyVariantArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { findFieldByCategory } from '@rdv-fo/app/lib/fieldsHelper';
import { isEmpty } from 'lodash';

export interface CollectionItem {
	id: string;
	name: string;
	image?: RdvImageFieldValue;
	variantId?: string;
}

interface SupplySliceState {
	loading: boolean;
	creating: boolean;
	loadingSupplyVariant: boolean;
	errors: any; // FIXME: SET PROPER ERROR TYPE
	createSupplyStatus: 'idle' | 'init' | 'loading' | 'failed' | 'succeeded' | 'finished'; // FIXME: REMOVE THIS PROPER COMPLETELY
	createSupplyVariantStatus: 'idle' | 'init' | 'loading' | 'failed' | 'succeeded' | 'finished'; // FIXME: REMOVE THIS PROPER COMPLETELY
	loadMySuppliesStatus: 'idle' | 'init' | 'loading' | 'failed' | 'succeeded' | 'finished'; // FIXME: REMOVE THIS PROPER COMPLETELY
	loadMySupplyStatus: 'idle' | 'init' | 'loading' | 'failed' | 'succeeded' | 'finished'; // FIXME: REMOVE THIS PROPER COMPLETELY
	updateSupplyStatus: 'idle' | 'init' | 'loading' | 'failed' | 'succeeded' | 'finished'; // FIXME: REMOVE THIS PROPER COMPLETELY
	items: Supply[];
	item: Supply | null;
	collections: Collection[];
	collection: Collection | null;
	collectionItems: CollectionItem[];
	onboardingFields: Field[];
	mySupplyTypes: SupplyType[];
	importReports: DataImportJobStatusReport[];
	loadingImportReports: boolean;
}

const initialState: SupplySliceState = {
	loading: false,
	creating: false,
	loadingSupplyVariant: false,
	items: [],
	item: null,
	collections: [],
	collection: null,
	collectionItems: [],
	onboardingFields: [],
	errors: null,
	createSupplyStatus: 'idle',
	createSupplyVariantStatus: 'idle',
	loadMySupplyStatus: 'idle',
	updateSupplyStatus: 'idle',
	loadMySuppliesStatus: 'idle',
	mySupplyTypes: [],
	importReports: [],
	loadingImportReports: false,
};

const slice = createSlice({
	name: 'supply',
	initialState: initialState,
	reducers: {
		createSupplyRequested: (supply: SupplySliceState) => {
			supply.creating = true;
			supply.errors = null;
			supply.createSupplyStatus = 'loading';
		},
		createSupplyFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.creating = false;
			supply.errors = action.payload;
			supply.createSupplyStatus = 'failed';
		},
		supplyCreated: (supply: SupplySliceState, action: PayloadAction<{ id: string }>) => {
			supply.creating = false;
			supply.createSupplyStatus = 'succeeded';
		},
		createSupplyVariantRequested: (supply: SupplySliceState) => {
			supply.loadingSupplyVariant = true;
			supply.errors = null;
			supply.createSupplyVariantStatus = 'loading';
		},
		createSupplyVariantFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadingSupplyVariant = false;
			supply.errors = action.payload;
			supply.createSupplyVariantStatus = 'failed';
		},
		supplyVariantCreated: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadingSupplyVariant = false;
			const { variants, ...new_variant } = action.payload;
			if (supply.item && supply.item.variants) supply.item.variants = [...supply.item.variants, new_variant];
			supply.createSupplyVariantStatus = 'succeeded';
		},
		supplyCreatedStatusChanged: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.createSupplyStatus = action.payload;
		},

		supplyOnboardingFieldsLoaded: (supply: SupplySliceState, action: PayloadAction<{ fields: Field[] }>) => {
			supply.loading = false;
			supply.onboardingFields = action.payload.fields; // FIXME: REMOVE
		},
		loadMySuppliesRequested: (supply: SupplySliceState) => {
			supply.loadMySuppliesStatus = 'loading'; // FIXME: REMOVE THIS DEPENDENCY
		},
		loadMySuppliesFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadMySuppliesStatus = 'failed';
			supply.loading = false;
			supply.errors = action.payload;
		},
		mySuppliesLoaded: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.items = action.payload;
			supply.loadMySuppliesStatus = 'succeeded'; // FIXME: REMOVE THIS DEPENDENCY
		},
		loadMySupplyTypesRequested: (supply: SupplySliceState) => {
			supply.loading = true; // FIXME: REMOVE THIS DEPENDENCY
			supply.mySupplyTypes = [];
		},
		loadMySupplyTypesFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.errors = action.payload;
		},
		mySupplyTypesLoaded: (supply: SupplySliceState, action: PayloadAction<SupplyType[]>) => {
			supply.loading = false;
			supply.mySupplyTypes = action.payload;
		},
		loadMySupplyRequested: (supply: SupplySliceState) => {
			supply.item = null;
			supply.loading = true;
			supply.errors = null;
			supply.loadMySupplyStatus = 'loading'; // FIXME: REMOVE THIS DEPENDENCY
		},
		loadMySupplyFailed: (supply: SupplySliceState, action?: PayloadAction<any>) => {
			supply.loadMySupplyStatus = 'failed';
			supply.loading = false;
			supply.errors = action?.payload;
		},
		mySupplyLoaded: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.item = action.payload;
			supply.loadMySupplyStatus = 'succeeded';
		},
		updateSupplyRequested: (supply: SupplySliceState) => {
			supply.updateSupplyStatus = 'loading';
		},
		updateSupplyFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.updateSupplyStatus = 'failed';
			supply.errors = action.payload;
		},
		supplyUpdated: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.item = action.payload;
			supply.updateSupplyStatus = 'succeeded';
		},
		updateSupplyVariantRequested: (supply: SupplySliceState) => {
			supply.loadingSupplyVariant = true;
		},
		updateSupplyVariantFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadingSupplyVariant = false;
			supply.errors = action.payload;
		},
		supplyVariantUpdated: (supply: SupplySliceState, action: PayloadAction<any>) => {
			const { variants, ...newVariant } = action.payload;
			if (supply.item?.variants)
				supply.item.variants = supply.item?.variants.map((variant) =>
					variant.id === newVariant.id ? newVariant : variant
				);
			supply.loadingSupplyVariant = false;
		},
		updateSupplyStatusChanged: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.updateSupplyStatus = action.payload;
		},
		triggerManualSupplyOnboardingTransitionRequested: (supply: SupplySliceState) => {
			supply.loading = true;
			supply.errors = null;
		},
		triggerManualSupplyOnboardingTransitionFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.errors = action.payload;
		},
		manualSupplyOnboardingTransitionTriggered: (supply: SupplySliceState) => {
			supply.loading = false;
			supply.errors = null;
		},
		activateSupplyRequested: (supply: SupplySliceState) => {
			supply.loadMySuppliesStatus = 'loading';
		},
		activateSupplyFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadMySuppliesStatus = 'failed';
			supply.loading = false;
			supply.errors = action.payload;
		},
		mySupplyActivated: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.item = action.payload;
			supply.loadMySuppliesStatus = 'succeeded';
		},
		activateSupplyVariantRequested: (supply: SupplySliceState) => {
			supply.loadMySuppliesStatus = 'loading';
		},
		activateSupplyVariantFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadMySuppliesStatus = 'failed';
			supply.loading = false;
			supply.errors = action.payload;
		},
		mySupplyVariantActivated: (supply: SupplySliceState, action: PayloadAction<any>) => {
			if (supply.item && supply.item.variants)
				supply.item.variants = supply.item?.variants?.map((variant) =>
					variant.id === action.payload.id ? action.payload : variant
				);
			supply.loadMySuppliesStatus = 'succeeded';
		},
		deactivateSupplyRequested: (supply: SupplySliceState) => {
			supply.loadMySuppliesStatus = 'loading';
		},
		deactivateSupplyFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadMySuppliesStatus = 'failed';
			supply.loading = false;
			supply.errors = action.payload;
		},
		mySupplyDeactivated: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.item = action.payload;
			supply.loadMySuppliesStatus = 'succeeded';
		},
		deactivateSupplyVariantRequested: (supply: SupplySliceState) => {
			supply.loadMySuppliesStatus = 'loading';
		},
		deactivateSupplyVariantFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadMySuppliesStatus = 'failed';
			supply.errors = action.payload;
		},
		mySupplyVariantDeactivated: (supply: SupplySliceState, action: PayloadAction<any>) => {
			if (supply.item && supply.item.variants)
				supply.item.variants = supply.item?.variants?.map((variant) =>
					variant.id === action.payload.id ? action.payload : variant
				);
			supply.loadMySuppliesStatus = 'succeeded';
		},
		loadMyCollectionsRequested: (supply: SupplySliceState) => {
			supply.loading = true;
			supply.errors = null;
			supply.collections = [];
		},
		loadMyCollectionsFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.errors = action.payload;
		},
		myCollectionsLoaded: (supply: SupplySliceState, action: PayloadAction<Collection[]>) => {
			supply.loading = false;
			supply.collections = action.payload;
		},
		loadMyCollectionRequested: (supply: SupplySliceState) => {
			supply.loading = true;
			supply.errors = null;
			supply.collection = null;
			supply.collectionItems = [];
		},
		loadMyCollectionFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.errors = action.payload;
		},
		myCollectionLoaded: (
			supply: SupplySliceState,
			action: PayloadAction<{ collection: Collection; collection_items: CollectionItem[] }>
		) => {
			supply.loading = false;
			supply.collection = action.payload.collection;
			supply.collectionItems = action.payload.collection_items;
		},

		createCollectionRequested: (supply: SupplySliceState) => {
			supply.errors = null;
		},
		createCollectionFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.errors = action.payload;
		},
		collectionCreated: (supply: SupplySliceState, action: PayloadAction<Collection>) => {
			supply.loading = false;
			supply.collection = action.payload;
		},
		updateCollectionRequested: (supply: SupplySliceState) => {
			supply.errors = null;
		},
		updateCollectionFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.errors = action.payload;
		},
		collectionUpdated: (
			supply: SupplySliceState,
			action: PayloadAction<{ collection: Collection; collection_items: CollectionItem[] }>
		) => {
			supply.loading = false;
			supply.collection = action.payload.collection;
			supply.collectionItems = action.payload.collection_items;
		},
		loadMyImportJobReportsRequested: (supply: SupplySliceState) => {
			supply.loadingImportReports = true;
			supply.errors = null;
			supply.importReports = [];
		},
		loadMyImportJobReportsFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loadingImportReports = false;
			supply.errors = action.payload;
		},
		myImportJobReportsLoaded: (supply: SupplySliceState, action: PayloadAction<DataImportJobStatusReport[]>) => {
			supply.loadingImportReports = false;
			supply.importReports = action.payload;
		},

		requestSupplyDataImportJobRequested: (supply: SupplySliceState) => {
			supply.errors = null;
		},
		requestSupplyDataImportJobFailed: (supply: SupplySliceState, action: PayloadAction<any>) => {
			supply.loading = false;
			supply.errors = action.payload;
		},
		supplyDataImportJobRequested: (supply: SupplySliceState, action: PayloadAction<DataImportJobStatusReport>) => {
			supply.importReports.push(action.payload);
			supply.errors = null;
		},
	},
});

export const {
	loadMyCollectionsRequested,
	loadMyCollectionsFailed,
	myCollectionsLoaded,
	loadMyCollectionRequested,
	loadMyCollectionFailed,
	myCollectionLoaded,
	createCollectionRequested,
	createCollectionFailed,
	collectionCreated,
	createSupplyRequested,
	createSupplyFailed,
	supplyCreated,
	supplyCreatedStatusChanged,
	supplyOnboardingFieldsLoaded,
	loadMySuppliesRequested,
	loadMySuppliesFailed,
	mySuppliesLoaded,
	loadMySupplyRequested,
	loadMySupplyFailed,
	mySupplyLoaded,
	createSupplyVariantRequested,
	createSupplyVariantFailed,
	supplyVariantCreated,
	updateSupplyRequested,
	updateSupplyFailed,
	supplyUpdated,
	updateSupplyStatusChanged,
	loadMySupplyTypesRequested,
	loadMySupplyTypesFailed,
	mySupplyTypesLoaded,
	updateSupplyVariantRequested,
	updateSupplyVariantFailed,
	supplyVariantUpdated,
	triggerManualSupplyOnboardingTransitionRequested,
	triggerManualSupplyOnboardingTransitionFailed,
	manualSupplyOnboardingTransitionTriggered,
	activateSupplyRequested,
	activateSupplyFailed,
	mySupplyActivated,
	deactivateSupplyRequested,
	deactivateSupplyFailed,
	mySupplyDeactivated,
	activateSupplyVariantRequested,
	activateSupplyVariantFailed,
	mySupplyVariantActivated,
	deactivateSupplyVariantRequested,
	deactivateSupplyVariantFailed,
	mySupplyVariantDeactivated,
	updateCollectionRequested,
	updateCollectionFailed,
	collectionUpdated,
	loadMyImportJobReportsRequested,
	loadMyImportJobReportsFailed,
	myImportJobReportsLoaded,
	requestSupplyDataImportJobRequested,
	requestSupplyDataImportJobFailed,
	supplyDataImportJobRequested,
} = slice.actions;

export default slice.reducer;

/////////////////////
// 	 	THUNKS	   //
/////////////////////
export const createSupply =
	({ tech_name, fields }: MutationCreateSupplyArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(createSupplyRequested());
			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { supply, errors } = await randevuService.supplies.createSupply({ tech_name, fields });

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: createSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.CREATE_SUPPLY.key,
						payload: { tech_name, fields },
					},
				});

			if (errors || !supply) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(createSupplyFailed(errors));
			}

			console.log('Wait before fetching created supply status');
			const supplyId = supply.id;

			await sleep(500);

			const supplyOnboardingRoute = routeBuilder(ROUTES.SUPPLY_ONBOARDING, [[':supplyId', supplyId]]);
			const supplyDetailsRoute = routeBuilder(ROUTES.UPDATE_MY_SUPPLY, [[':supplyId', supplyId]]);

			if (supply.status === SupplyStatusKind.Onboarded) {
				dispatch(goToRoute(supplyDetailsRoute));
				dispatch(sendSuccessToasty('Supply onboarded'));
				return dispatch(supplyCreated({ id: supplyId }));
			}

			if (supply.status === SupplyStatusKind.Onboarding) {
				dispatch(sendSuccessToasty('Supply created'));
				dispatch(goToRoute(supplyOnboardingRoute));
				return dispatch(supplyCreated({ id: supplyId }));
			}

			dispatch(sendErrorToasty('Supply was supposed to be created, but an error happened.'));
			return dispatch(createSupplyFailed(''));
		};

export const createSupplyVariant =
	({ tech_name, variant_fields, variant_group }: MutationCreateSupplyVariantArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(createSupplyVariantRequested());
			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { supply, errors } = await randevuService.supplies.createSupplyVariant({
				tech_name,
				variant_fields,
				variant_group,
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: createSupplyVariantFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.CREATE_SUPPLY_VARIANT.key,
						payload: { tech_name, variant_fields, variant_group },
					},
				});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(createSupplyVariantFailed(errors));
			}
			// Wait for the supply onboarding progress to settle down
			sleep(1000);
			if (supply) {
				const { supply: retrievedSupply } = await randevuService.supplies.mySupply({
					id: supply!.id,
				});
				if (retrievedSupply) {
					supply.is_active = retrievedSupply.is_active;
					supply.status = retrievedSupply.status;
				}
			}
			return dispatch(supplyVariantCreated(supply));
		};

export const loadMySupplyTypes = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadMySupplyTypesRequested());
	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

	const { supply_types, errors } = await randevuService.supplies.mySupplyTypes();

	if (isSessionExpired(errors))
		return handleSessionExpired({
			dispatch,
			state: getState(),
			failedAction: createSupplyFailed,
			reauthenticationCallback: {
				callbackThunkKey: THUNKS.LOAD_MY_SUPPLY_TYPES.key,
				payload: {},
			},
		});

	if (errors || !supply_types) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadMySupplyTypesFailed(errors));
	}

	return dispatch(mySupplyTypesLoaded(supply_types ?? []));
};

export const loadMyImportJobReports = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadMyImportJobReportsRequested());
	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

	const { import_reports, errors } = await randevuService.supplies.myDataImportJobReports();

	if (isSessionExpired(errors))
		return handleSessionExpired({
			dispatch,
			state: getState(),
			failedAction: loadMyImportJobReportsFailed,
			reauthenticationCallback: {
				callbackThunkKey: THUNKS.MY_IMPORT_REPORTS.key,
				payload: {},
			},
		});

	if (errors || import_reports === null || import_reports === undefined) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadMyImportJobReportsFailed(errors));
	}

	return dispatch(myImportJobReportsLoaded(import_reports));
};

interface RequestSupplyDataImportRequestArgs {
	supply_tech_name: string;
	id_template: string;
	auto_activate_supplies: boolean;
	data_file: File;
}

export const requestSupplyDataImportJob =
	({ supply_tech_name, id_template, auto_activate_supplies, data_file }: RequestSupplyDataImportRequestArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(requestSupplyDataImportJobRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
			const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

			if (isSessionExpired(expiredSession))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: updateSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.REQUEST_SUPPLY_DATA_IMPORT_JOB.key,
						payload: { supply_tech_name, id_template, auto_activate_supplies, data_file },
					},
				});

			const { uploaded_file, errors: file_upload_error } = await randevuService.files.uploadFile({
				file: data_file,
				description: 'supply_data_records_to_be_imported',
				name: data_file.name,
				parentPropertyName: 'import_job',
				is_public: false
			});

			if (uploaded_file == null || !isEmpty(file_upload_error)) {
				dispatch(sendUnexpectedErrorToasty(file_upload_error));
				return dispatch(requestSupplyDataImportJobFailed(file_upload_error));
			}

			const { import_report, errors } = await randevuService.supplies.requestSupplyDataImportJob({
				supply_tech_name,
				id_template,
				auto_activate_supplies,
				id_data_file: uploaded_file.id_file,
			});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(requestSupplyDataImportJobFailed(file_upload_error));
			}

			dispatch(sendInfoToasty('Import data submitted'));

			return dispatch(supplyDataImportJobRequested(import_report));
		};

export const loadMySupplies =
	({ where }: QueryMySuppliesArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log('Load my supplies..');

			dispatch(loadMySuppliesRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { supplies, errors } = await randevuService.supplies.mySupplies({ where });

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: loadMySuppliesFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.LOAD_MY_SUPPLIES.key,
						payload: { where },
					},
				});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(loadMySuppliesFailed(JSON.stringify(errors)));
			}

			dispatch(mySuppliesLoaded(supplies));
		};

export const loadMySupply =
	({ id }: { id: string }) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(loadMySupplyRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { supply, errors } = await randevuService.supplies.mySupply({ id });

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: loadMySupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.LOAD_MY_SUPPLY.key,
						payload: { id },
					},
				});

			if (isNotFound(errors)) {
				const mySuppliesRoute = routeBuilder(ROUTES.MY_SUPPLIES);

				dispatch(loadMySupplyFailed(errors));
				return dispatch(goToRoute(mySuppliesRoute));
			}

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(loadMySupplyFailed(errors));
			}

			return dispatch(mySupplyLoaded(supply));
		};

interface UpdateSupplyRequest {
	id: string;
	dirty_fields: FieldInput[];
	current_fields: Field[];
	remaining_qty?: number;
}
export const updateSupply =
	({ id, dirty_fields = [], current_fields = [], remaining_qty }: UpdateSupplyRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Update supply '${id}'`);

			dispatch(updateSupplyRequested());

			const oldSupply = getState().supply.item;

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
			const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

			if (isSessionExpired(expiredSession))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: updateSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.UPDATE_SUPPLY.key,
						payload: { id, dirty_fields, current_fields, remaining_qty },
					},
				});

			const { field_inputs: dirty_fields_with_uploaded_files } = await randevuService.files.uploadFieldInputsFiles({
				field_inputs: dirty_fields,
				fields: current_fields,
			});

			const { errors } = await randevuService.supplies.updateSupply({
				id,
				fields: dirty_fields_with_uploaded_files,
			});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(updateSupplyFailed(errors));
			}

			if (remaining_qty !== undefined) {
				await randevuService.supplies.updateMySimpleStockSupplyAvailability({ id_supply: id, remaining_qty });
			}

			// TODO: Current workaround - reloading supply
			// In the future, the endpoint should return the updated supply itself
			const { supply, errors: updated_supply_errors } = await randevuService.supplies.mySupply({ id });

			if (updated_supply_errors) {
				dispatch(sendUnexpectedErrorToasty(updated_supply_errors));
				return dispatch(updateSupplyFailed(errors));
			}

			dispatch(sendInfoToasty('Saved'));

			const supplyDetailsRoute = routeBuilder(ROUTES.UPDATE_MY_SUPPLY, [[':supplyId', id]]);
			if (supply?.status === SupplyStatusKind.Onboarded && oldSupply?.status === SupplyStatusKind.Onboarding) {
				dispatch(goToRoute(supplyDetailsRoute));
			}
			return dispatch(supplyUpdated(supply));
		};

interface UpdateSupplyVariantRequest {
	id: string;
	dirty_fields: FieldInput[];
	current_fields: Field[];
	remaining_qty?: number;
}

export const updateSupplyVariant =
	({ id, dirty_fields = [], current_fields = [], remaining_qty }: UpdateSupplyVariantRequest) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Update supply '${id}'`);

			dispatch(updateSupplyVariantRequested());

			const oldSupply = getState().supply.item;

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
			const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

			if (isSessionExpired(expiredSession))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: updateSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.UPDATE_SUPPLY_VARIANT.key,
						payload: { id, dirty_fields, current_fields },
					},
				});

			const { field_inputs: dirty_fields_with_uploaded_files } = await randevuService.files.uploadFieldInputsFiles({
				field_inputs: dirty_fields,
				fields: current_fields,
			});

			const { errors } = await randevuService.supplies.updateSupply({
				id,
				fields: dirty_fields_with_uploaded_files,
			});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(updateSupplyVariantFailed(errors));
			}

			if (remaining_qty !== undefined) {
				await randevuService.supplies.updateMySimpleStockSupplyAvailability({ id_supply: id, remaining_qty });
			}

			// TODO: Current workaround - reloading supply
			// In the future, the endpoint should return the updated supply itself
			const { supply, errors: updated_supply_errors } = await randevuService.supplies.mySupply({ id });

			if (updated_supply_errors) {
				dispatch(sendUnexpectedErrorToasty(updated_supply_errors));
				return dispatch(updateSupplyVariantFailed(errors));
			}

			dispatch(sendInfoToasty('Saved'));

			const supplyDetailsRoute = routeBuilder(ROUTES.UPDATE_MY_SUPPLY, [[':supplyId', id]]);
			if (supply?.status === SupplyStatusKind.Onboarded && oldSupply?.status === SupplyStatusKind.Onboarding) {
				dispatch(goToRoute(supplyDetailsRoute));
			}
			return dispatch(supplyVariantUpdated(supply));
		};

export const triggerManualSupplyOnboardingTransition =
	({ id_supply, transition_tech_name }: { id_supply: string; transition_tech_name: string }) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Trigger manual transition ${transition_tech_name}..`);

			dispatch(triggerManualSupplyOnboardingTransitionRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { errors } = await randevuService.supplies.triggerManualSupplyOnboardingTransition({
				id_supply,
				transition_tech_name,
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: triggerManualSupplyOnboardingTransitionFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.TRIGGER_MANUAL_SUPPLY_ONBOARDING_TRANSITION.key,
						payload: { id_supply, transition_tech_name },
					},
				});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(triggerManualSupplyOnboardingTransitionFailed(errors));
			}

			await sleep(100);

			const resolvedAction = await dispatch(loadMySupply({ id: id_supply }));
			if (resolvedAction.type.includes('Failed')) return;
			const status = resolvedAction?.payload?.status;
			const supplyDetailsRoute = routeBuilder(ROUTES.UPDATE_MY_SUPPLY, [[':supplyId', id_supply]]);
			if (status !== SupplyStatusKind.Onboarding) {
				dispatch(goToRoute(supplyDetailsRoute));
			}
			return dispatch(manualSupplyOnboardingTransitionTriggered());
		};

export const activateSupply =
	({ id }: { id: string }) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Activate supply '${id}'`);

			dispatch(activateSupplyRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { supply, errors } = await randevuService.supplies.activateSupply({
				id,
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: activateSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.ACTIVATE_SUPPLY.key,
						payload: { id },
					},
				});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(activateSupplyFailed(errors));
			}

			dispatch(sendInfoToasty('Saved'));

			return dispatch(mySupplyActivated(supply));
		};
export const activateSupplyVariant =
	({ id }: { id: string }) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Activate supply '${id}'`);

			dispatch(activateSupplyVariantRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { supply, errors } = await randevuService.supplies.activateSupply({
				id,
			});

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: activateSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.ACTIVATE_SUPPLY.key,
						payload: { id },
					},
				});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(activateSupplyVariantFailed(errors));
			}

			dispatch(sendInfoToasty('Saved'));

			return dispatch(mySupplyVariantActivated(supply));
		};

export const deactivateSupply =
	({ id }: MutationActivateSupplyArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Deactivate supply '${id}'`);

			dispatch(deactivateSupplyRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { supply, errors } = await randevuService.supplies.deactivateSupply({
				id,
			});
			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: deactivateSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.DEACTIVATE_SUPPLY.key,
						payload: { id },
					},
				});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(deactivateSupplyFailed(errors));
			}

			dispatch(sendInfoToasty('Saved'));

			return dispatch(mySupplyDeactivated(supply));
		};
export const deactivateSupplyVariant =
	({ id }: MutationDeactivateSupplyArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			console.log(`Deactivate supply '${id}'`);

			dispatch(deactivateSupplyVariantRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

			const { supply, errors } = await randevuService.supplies.deactivateSupply({
				id,
			});
			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: deactivateSupplyFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.DEACTIVATE_SUPPLY.key,
						payload: { id },
					},
				});

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(deactivateSupplyVariantFailed(errors));
			}

			dispatch(sendInfoToasty('Saved'));

			return dispatch(mySupplyVariantDeactivated(supply));
		};

export const loadMyCollections = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	console.log('Load my collections..');

	dispatch(loadMyCollectionsRequested());

	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
	const { collections, errors } = await randevuService.supplies.myCollections();

	if (isSessionExpired(errors))
		return handleSessionExpired({
			dispatch,
			state: getState(),
			failedAction: loadMyCollectionsFailed,
			reauthenticationCallback: {
				callbackThunkKey: THUNKS.LOAD_MY_COLLECTIONS.key,
				payload: {},
			},
		});

	if (errors) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadMyCollectionsFailed(JSON.stringify(errors)));
	}

	dispatch(myCollectionsLoaded(collections!));
};

export const loadMyCollection =
	({ id }: { id: string }) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(loadMyCollectionRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { collection, errors } = await randevuService.supplies.myCollection({ id });

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: loadMyCollectionFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.LOAD_MY_COLLECTION.key,
						payload: { id },
					},
				});

			if (isNotFound(errors)) {
				const myCollectionsRote = routeBuilder(ROUTES.MY_COLLECTIONS);

				dispatch(loadMyCollectionFailed(errors));
				return dispatch(goToRoute(myCollectionsRote));
			}

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(loadMyCollectionFailed(errors));
			}

			if (!collection) {
				dispatch(sendUnexpectedErrorToasty('Unexpected error occuered, please try again later'));
				return dispatch(loadMyCollectionFailed('Unexpected error occuered, please try again later'));
			}

			const { supplies } = await randevuService.supplies.mySupplies({
				where: { id_collection: collection?.id },
			});
			const collection_items =
				supplies?.reduce((arr: CollectionItem[], supply: Supply) => {
					const variantSpecificFieldValue = supply?.fields?.find(
						(field) => field.field_type?.is_variant_identifier
					)?.value;
					const newItem: CollectionItem = {
						id: supply.id,
						name: findFieldByCategory(supply?.fields, FieldCategoryKind.Name)?.value,
						image: findFieldByCategory(supply?.fields, FieldCategoryKind.MainImage)?.value,
						variantId: variantSpecificFieldValue,
					};
					if (supply.onboarded_at) arr.push(newItem);
					return arr;
				}, []) ?? [];

			return dispatch(myCollectionLoaded({ collection, collection_items }));
		};
export const updateMyCollection =
	({ id, name, ids_supply }: MutationUpdateCollectionArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(updateCollectionRequested());

			const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });
			const { collection, errors } = await randevuService.supplies.updateMyCollection({ id, name, ids_supply });

			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: updateCollectionFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.UPDATE_MY_COLLECTION.key,
						payload: { id },
					},
				});

			if (isDuplicate(errors)) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(updateCollectionFailed(errors));
			}

			if (errors) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(updateCollectionFailed(errors));
			}

			if (!collection) {
				dispatch(sendUnexpectedErrorToasty('Unexpected error occuered, please try again later'));
				return dispatch(updateCollectionFailed('Unexpected error occuered, please try again later'));
			}

			const { supplies } = await randevuService.supplies.mySupplies({
				where: { id_collection: collection?.id },
			});
			const collection_items =
				supplies?.reduce((arr: CollectionItem[], supply: Supply) => {
					const variantSpecificFieldValue = supply?.fields?.find(
						(field) => field.field_type?.is_variant_identifier
					)?.value;
					const newItem: CollectionItem = {
						id: supply.id,
						name: findFieldByCategory(supply?.fields, FieldCategoryKind.Name)?.value,
						image: findFieldByCategory(supply?.fields, FieldCategoryKind.MainImage)?.value,
						variantId: variantSpecificFieldValue,
					};
					if (supply.onboarded_at) arr.push(newItem);
					return arr;
				}, []) ?? [];

			dispatch(sendSuccessToasty('Collection updated'));
			return dispatch(collectionUpdated({ collection, collection_items }));
		};

export const createCollection =
	({ name }: MutationCreateCollectionArgs) =>
		async (dispatch: AppDispatch, getState: () => RootState) => {
			dispatch(createCollectionRequested());
			const randevuService = new randevu({
				token: getState().auth.token,
				apiKey: getState().platform.public_key,
			});

			const { collection, errors } = await randevuService.supplies.createCollection({ name });
			if (isSessionExpired(errors))
				return handleSessionExpired({
					dispatch,
					state: getState(),
					failedAction: createCollectionFailed,
					reauthenticationCallback: {
						callbackThunkKey: THUNKS.CREATE_COLLECTION.key,
						payload: { name },
					},
				});

			if (hasSubmissionErrors(errors)) {
				let submissionErrors = {
					...(isDuplicate(errors) && { name: 'Collection with this name already exists.' }),
				};
				if (Object.keys(submissionErrors).length) return dispatch(createCollectionFailed(submissionErrors));
			}

			if (errors || !collection) {
				dispatch(sendUnexpectedErrorToasty(errors));
				return dispatch(createSupplyFailed(errors));
			}

			dispatch(sendSuccessToasty('Collection created'));
			return dispatch(collectionCreated(collection));
		};
/////////////////////
//   SELECTORS     //
/////////////////////
export const selectLoading = (state: RootState) => state.supply.loading;
export const selectLoadingSupplyVariant = (state: RootState) => state.supply.loadingSupplyVariant;
export const selectErrors = (state: RootState) => state.supply.errors;
export const selectMySupply = (state: RootState) => state.supply.item;
export const selectMySupplies = (state: RootState) => state.supply.items;
export const selectLoadMySuppliesStatus = (state: RootState) => state.supply.loadMySuppliesStatus;
export const selectUpdateSupplyStatus = (state: RootState) => state.supply.updateSupplyStatus;
export const selectMySupplyTypes = (state: RootState) => state.supply.mySupplyTypes ?? [];
export const selectIsSupplyAvailable = (state: RootState) => state.discovery.isSupplyAvailable;
export const selectMyCollections = (state: RootState) => state.supply.collections;
export const selectMyCollection = (state: RootState) => state.supply.collection;
export const selectMyCollectionItems = (state: RootState) => state.supply.collectionItems;
export const selectMyImportJobReports = (state: RootState) => state.supply.importReports;
export const selectLoadingImportReports = (state: RootState) => state.supply.loadingImportReports;
