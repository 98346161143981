import { Paper, Stack, Typography } from '@mui/material';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { goToRoute } from '@rdv-fo/store/slices/uiSlice';

export interface NavigationTileProps {
	title: string;
	routeTo: string;
}

const NavigationTile = ({ title, routeTo }: NavigationTileProps) => {
	const dispatch = useAppDispatch();

	return (
		<Paper
			sx={{ p: 3, borderRadius: '12px', cursor: 'pointer' }}
			elevation={2}
			onClick={() => {
				dispatch(goToRoute(routeBuilder(routeTo)));
			}}
		>
			<Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={2} height={160}>
				<Typography variant="h4">{title}</Typography>
			</Stack>
		</Paper>
	);
};

export default NavigationTile;
