import { Box, Drawer, IconButton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import React from 'react'

import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
interface RdvDrawerProps {
    title: string;
    open: boolean;
    loading: boolean;
    onClose: () => void;
    children?: any;
    width?: string;
}

const RdvDrawer = ({ loading, open, onClose, children, title, width = '600px' }: RdvDrawerProps) => {

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: { xs: '100%', sm: width },
                    padding: 5
                },
            }}
        >
            <Stack direction='column' pl={2} spacing={3}>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3} mb={3}>
                        <Typography variant="h2">{title}</Typography>
                        <IconButton onClick={onClose} sx={{ p: 0, m: 0 }}>
                            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
                                <CloseIcon sx={{ color: 'black' }} fontSize='small' />
                            </Stack>
                        </IconButton>
                    </Stack>
                </Box>
                {children}
            </Stack>


        </Drawer>
    )
}

export default RdvDrawer