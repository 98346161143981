// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Loader } from '@googlemaps/js-api-loader';
import Autocomplete from '@mui/material/Autocomplete';
import { sendInfoToasty } from '@rdv-fo/store/slices/uiSlice';
import { debounce } from 'lodash';
import { TextField } from 'mui-rff';
import { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { RdvLocationFieldInput } from '@rdv-fo/services/randevuApi/types/field/';

import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';
import { Stack } from '@mui/material';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const loader = new Loader({
	apiKey: googleMapsApiKey ?? 'MISSING-GOOGLE-MAPS-API-KEY',
	libraries: ['places'],
});

const mapGoogleMapsAddressComponentsToLocationFieldInput = (
	entered_input: string,
	address_components: any,
	lat: number,
	lng: number
): RdvLocationFieldInput => {
	const house_number = address_components?.find((component: any) => component.types.includes('street_number'));
	const street = address_components?.find((component: any) => component.types.includes('route'));
	const county = address_components?.find((component: any) =>
		component.types.includes('administrative_area_level_2')
	);
	const city = address_components?.find((component: any) => component.types.includes('locality'));
	const state = address_components?.find((component: any) => component.types.includes('administrative_area_level_1'));
	const neighborhood = address_components?.find((component: any) => component.types.includes('sublocality'));
	const country = address_components?.find((component: any) => component.types.includes('country'));
	const postal_code = address_components?.find((component: any) => component.types.includes('postal_code'));

	const field_input = {
		lat,
		lng,
		input: entered_input,
		country_code: country?.short_name,
		country_name: country?.long_name,
		state: state?.long_name,
		county: county?.long_name,
		city: city?.long_name,
		postal_code: postal_code?.long_name,
		neighbourhood: neighborhood?.long_name,
		street: street?.long_name,
		house_number: house_number?.long_name,
	};

	return field_input;
};

type FieldInputLocationProps = FieldInputBaseProps;

// FIXME: remove ts-check from the beginning of the file and fix this input
const FieldInputLocation = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
}: FieldInputLocationProps) => {
	const dispatch = useAppDispatch();

	const [google, setGoogle] = useState(null);
	const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

	const autocompleteService = google?.maps?.places && new google.maps.places.AutocompleteService();
	const googleToken = google?.maps?.places && new google.maps.places.AutocompleteSessionToken();
	const asyncFn = async () => {
		const google = await loader.load();
		setGoogle(google);
	};

	useEffect(() => {
		asyncFn();

	}, []);

	const handleFindSuggestions = async (user_input: string) => {
		const callBackSetSuggestions = (predictions: any, status: any) => {
			if (status !== google?.maps.places.PlacesServiceStatus.OK || !predictions) {
				dispatch(sendInfoToasty(status));
				return;
			}
			setAutocompleteSuggestions(predictions);
		};

		autocompleteService.getQueryPredictions(
			{ input: user_input, sessionToken: googleToken },
			callBackSetSuggestions
		);
	};

	const handleDebouncedFindSuggestions = debounce(handleFindSuggestions, 300);

	const handleGeoCodeUserInput = async (user_input: any, setFieldState: any) => {
		const geocoder = google?.maps && new google.maps.Geocoder();

		const response = await geocoder.geocode({ address: user_input });
		const geocodedResponse = response?.results?.[0];

		const addressComponents = geocodedResponse?.address_components;

		if (!addressComponents) return;

		const location_field_input = mapGoogleMapsAddressComponentsToLocationFieldInput(
			user_input,
			geocodedResponse?.address_components,
			geocodedResponse.geometry.location.lat(),
			geocodedResponse.geometry.location.lng()
		);

		setFieldState(location_field_input);
	};

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={0}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>

			<Field name={techName}>
				{(props) => {
					const { input } = props;
					return (
						<Autocomplete
							id="location-autocomplete"
							options={autocompleteSuggestions}
							disabled={disabled}
							value={{ description: input?.value?.input ?? '' }}
							defaultValue={{ description: input?.value?.input ?? '' }}
							getOptionLabel={(option) => option.description ?? ''}
							onChange={(e, value) => {
								const selectedSuggestion = value;

								if (selectedSuggestion === null) {
									// User clicked on "X" to clear the input
									input.onChange(null); // -> set the value to null
									setAutocompleteSuggestions([{ description: input?.value?.input ?? '' }]);
									return;
								}
								// Geo coded selected suggestion
								handleGeoCodeUserInput(selectedSuggestion?.description, input.onChange);
							}}
							onClose={(e: any) => {
								if (['', undefined, null].includes(e?.target?.textContent)) {
									// user did not select any auto complete option,
									// so delete the value from the form state
									input.onChange(undefined);

									return;
								}
							}}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										onChange={(e) => {
											const user_input = e.target.value;

											const fieldValue = { ...input.value, input: user_input };
											input.onChange(fieldValue);

											if (user_input?.length > 2) {
												handleDebouncedFindSuggestions(user_input);
											}
										}}
										id={techName}
										name={techName}
										disabled={disabled}
										required={required}
										helperText={helperText}
										variant="outlined"
										size="small"
										margin="dense"
										fullWidth
									/>
								);
							}}
						/>
					);
				}}
			</Field>
		</Stack>
	);
};

export default FieldInputLocation;
