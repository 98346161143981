import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import React from 'react';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import {
    selectLoading,
    selectTransactionTypes,
    loadMySingleDirectTransactionForConsumer,
    selectMySingleDirectTransaction,
    loadMyTransactionTypes,
    loadMySingleDirectTransactionForProvider,
    triggerManualTransitionSingleDirectForConsumer,
    triggerManualTransitionSingleDirectForProvider,
} from '@rdv-fo/store/slices/transactionSlice';
import FlowManualActionsPanel from '@rdv-fo/components/transaction/FlowManualActionsPanel';
import { MatchTypeRoleKind, SingleDirectTransactionForConsumer, SingleDirectTransactionForProvider, SupplyPrice, TransactionType } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import SingleDirectTransactionMatchForConsumer from '@rdv-fo/components/transaction/single-direct/SingleDirectTransactionMatchForConsumer';
import SingleDirectTransactionMatchForProvider from '@rdv-fo/components/transaction/single-direct/SingleDirectTransactionMatchForProvider';
import SingleDirectTransactionHeader from '@rdv-fo/components/transaction/single-direct/SingleDirectTransactionHeader';

const SingleDirectTransactionDetailsPage = () => {
    const dispatch = useAppDispatch();
    const { transactionId: transactionIdUrl, transactionTypeTechName } = useParams<{ transactionTypeTechName: string, transactionId: string }>();
    const loading = useAppSelector(selectLoading);
    const transaction = useAppSelector(selectMySingleDirectTransaction) as any;
    const myTransactionTypes = useAppSelector(selectTransactionTypes);

    const currentTransactionType = myTransactionTypes?.find((tt: TransactionType) => tt.tech_name === transactionTypeTechName) as any;
    const participantRole = currentTransactionType?.my_role;
    const isConsumer = participantRole == MatchTypeRoleKind.Consumer
    const isMatchConfigurationPricingEnabled = currentTransactionType?.root_type?.match_type?.supply_type?.match_configurations?.[0]?.pricing_enabled;

    const isForceTerminationEnabled = currentTransactionType?.forced_termination_enabled;
    const hasTransactionBeenTerminatedForecdly = transaction?.forcedly_terminated;

    const paymentTypes = transaction?.type?.payment_types ?? [];

    const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState<boolean>(true);

    useEffect(() => {

        let timeoutId: any;

        const asyncFn = async () => {
            setLoadingSpinnerAllowed(false);
            timeoutId = setTimeout(() => {
                setLoadingSpinnerAllowed(false);
            }, 500);

            dispatch(loadMyTransactionTypes({ asGuest: false }));
            if (isConsumer)
                dispatch(loadMySingleDirectTransactionForConsumer({ id_transaction: transactionIdUrl }));
            else
                dispatch(loadMySingleDirectTransactionForProvider({ id_transaction: transactionIdUrl }));
        };

        asyncFn();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [dispatch, transactionIdUrl]);

    const transactionMatch = transaction?.match
    const matchFields = transactionMatch?.fields ?? [];
    const matchPriceOverrideEnabled = false //scaffold

    const handleTriggerManualTransition = (objectId: string, transitionTechName: string) => {
        if (isConsumer)
            dispatch(
                triggerManualTransitionSingleDirectForConsumer({
                    id_transaction: objectId,
                    transition_tech_name: transitionTechName,
                })
            );
        else
            dispatch(
                triggerManualTransitionSingleDirectForProvider({
                    id_transaction: objectId,
                    transition_tech_name: transitionTechName,
                })
            );
    };

    return (
        <>
            {(!loadingSpinnerAllowed && transaction && transactionMatch && transaction.state.status) ? (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <SingleDirectTransactionHeader
                            transactionId={transaction?.id}
                            transactionTechName={transactionTypeTechName}
                            isForceTerminationEnabled={isForceTerminationEnabled}
                            hasTransactionBeenTerminatedForecdly={hasTransactionBeenTerminatedForecdly}
                            createdAt={transaction?.created_at}
                            startedAt={transaction?.initiated_at}
                            terminatedAt={transaction?.terminated_at}
                            status={transaction?.state?.status}
                            cancelledAt={transaction?.cancelled_at}
                            participantRole={participantRole}
                        />
                        {(transaction?.state?.next_steps?.length && transaction?.state?.next_steps?.length > 0 || transaction?.state?.message) && (
                            <FlowManualActionsPanel
                                objectId={transaction?.id}
                                nextSteps={transaction?.state?.next_steps ?? []}
                                message={transaction?.state?.message ?? ''}
                                onTriggerManualTransition={handleTriggerManualTransition}
                                loading={loading}
                            />
                        )}
                        {isConsumer ? <SingleDirectTransactionMatchForConsumer
                            transactionId={transaction?.id}
                            transactionStatus={transaction.state.status}
                            transactionTechName={transaction.type?.tech_name ?? ""}
                            transactionNextSteps={transaction?.state?.next_steps ?? []}
                            matchId={transactionMatch?.id ?? ''}
                            matchFields={matchFields}
                            matchingToolFields={transaction?.request?.fields}
                            matchPrice={transactionMatch.price as SupplyPrice}
                            matchPriceOverrideEnabled={matchPriceOverrideEnabled}
                            providerFields={transactionMatch?.provider_fields}
                            supplyFields={transactionMatch?.supply_fields}
                            backofficeApprovedAt={transactionMatch?.backoffice_approved_at}
                            consumerAcceptedAt={transactionMatch?.consumer_accepted_at}
                            providerAcceptedAt={transactionMatch?.provider_accepted_at}
                            backofficeRejectedAt={transactionMatch?.backoffice_rejected_at}
                            consumerDeclinedAt={transactionMatch?.consumer_declined_at}
                            providerDeclinedAt={transactionMatch?.provider_declined_at}
                            paymentTypes={paymentTypes}
                            isMatchConfigurationPricingEnabled={isMatchConfigurationPricingEnabled}
                        /> :
                            <SingleDirectTransactionMatchForProvider
                                transactionId={transaction?.id}
                                transactionStatus={transaction.state.status}
                                transactionTechName={transaction.type?.tech_name ?? ""}
                                transactionNextSteps={transaction?.state?.next_steps ?? []}
                                matchId={transactionMatch?.id ?? ''}
                                matchFields={matchFields}
                                matchingToolFields={transaction?.request?.fields}
                                matchPrice={transactionMatch.price as SupplyPrice}
                                matchPriceOverrideEnabled={matchPriceOverrideEnabled}
                                consumerFields={transactionMatch?.consumer_fields}
                                supplyFields={transactionMatch?.supply?.fields}
                                backofficeApprovedAt={transactionMatch?.backoffice_approved_at}
                                consumerAcceptedAt={transactionMatch?.consumer_accepted_at}
                                providerAcceptedAt={transactionMatch?.provider_accepted_at}
                                backofficeRejectedAt={transactionMatch?.backoffice_rejected_at}
                                consumerDeclinedAt={transactionMatch?.consumer_declined_at}
                                providerDeclinedAt={transactionMatch?.provider_declined_at}
                                paymentTypeTechName={"NEBO TODO"}
                                isMatchConfigurationPricingEnabled={isMatchConfigurationPricingEnabled}
                            />
                        }
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 180px)',
                    }}
                >
                    {loading ? <CircularProgress /> : null}
                </Grid>
            )}
        </>
    );
};

export default SingleDirectTransactionDetailsPage;
