import { Container, Grid, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ASSETS } from '@rdv-fo/app/assets';
import { ImagePositionKind, SecurityPageStyleKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import PageWidth from './PageWidth';

const Content = ({ children }) => {
	return (
		<Grid container justifyContent="center" alignItems="center">
			<Grid item xs={12}>
				{children}
			</Grid>
		</Grid>
	);
};

const useCoverImageStyles = makeStyles(() => ({
	imageOuterWrapper: {
		width: '50vw',
		height: '100%',
		position: 'relative',
	},
	imageWrapper: {
		width: '50vw',
		height: '100%',
		position: 'relative',
	},
	image: {
		objectFit: 'cover',
		height: '100%',
		maxHeight: '100%',
		width: '100%',
		maxWidth: '100%',
	},
}));

const CoverImage = ({ alt, url }) => {
	const classes = useCoverImageStyles();
	return (
		<div className={classes.imageOuterWrapper}>
			<div className={classes.imageWrapper}>
				<img src={url} alt={alt} className={classes.image} />
			</div>
		</div>
	);
};

const useCoverLayoutRightStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		pageSplit: {
			display: 'flex',
			position: 'relative',
			height: '100%',
		},
		form: {
			order: 1,
			width: '100%',
			display: 'flex',
			alignItems: 'flex-start',
			paddingTop: theme.spacing(15),

			[theme.breakpoints.down('lg')]: {
				paddingTop: theme.spacing(6),
				paddingBottom: theme.spacing(12),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
			},
		},
		image: {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
			flex: '0 0 50%',
			maxWidth: '50%',
			order: 2,
			minHeight: 'calc(100vh - 64px)', //64px is the height of the app bar
			position: 'relative',
		},
	};
});

const CoverLayoutRight = ({ imageUrl, children }) => {
	const classes = useCoverLayoutRightStyles();
	return (
		<div className={classes.pageSplit}>
			<div className={classes.form}>{children}</div>
			<div className={classes.image}>
				<CoverImage url={imageUrl} alt="sign-in-image" />
			</div>
		</div>
	);
};

const useCoverLayoutLeftStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		pageSplit: {
			display: 'flex',
			position: 'relative',
			height: '100%',
		},

		form: {
			order: 2,
			width: '100%',
			display: 'flex',
			alignItems: 'start',

			paddingLeft: theme.spacing(6),
			paddingRight: theme.spacing(6),
			paddingTop: theme.spacing(30),

			[theme.breakpoints.down('xl')]: {
				paddingTop: theme.spacing(15),
			},
			[theme.breakpoints.down('md')]: {
				paddingTop: theme.spacing(6),
				paddingBottom: theme.spacing(12),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
			},
		},
		image: {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
			flex: '0 0 50%',
			maxWidth: '50%',
			order: 1,
			minHeight: 'calc(100vh - 64px)', //64px is the height of the app bar
			position: 'relative',
			direction: 'rtl',
		},
	};
});

const CoverLayoutLeft = ({ alt, imageUrl, children }) => {
	const classes = useCoverLayoutLeftStyles();
	return (
		<div className={classes.pageSplit}>
			<div className={classes.image}>
				<CoverImage url={imageUrl} alt={alt} />
			</div>
			<div className={classes.form}>{children}</div>
		</div>
	);
};

const useSimpleLayoutStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		page: {
			minHeight: 'calc(100vh - 64px)',
		},
		wrapper: {
			paddingTop: theme.spacing(12),
			[theme.breakpoints.down('lg')]: {
				paddingTop: theme.spacing(6),
				paddingBottom: theme.spacing(12),
			},
		},
	};
});

const SimpleLayout = ({ children }) => {
	const classes = useSimpleLayoutStyles();
	return (
		<PageWidth>
			<Container id="auth-form-max-width" maxWidth="md" className={classes.page}>
				<div className={classes.wrapper}>{children}</div>
			</Container>
		</PageWidth>
	);
};

const AuthPageStyle = ({ style = SecurityPageStyleKind.Simple, imagePosition, image, children }) => {
	if (SecurityPageStyleKind.Simple === style) return <SimpleLayout>{children}</SimpleLayout>;

	const img = image ?? ASSETS.RANDEVU.SECURITY_PAGE_DEFAULT_COVER_IMAGE;

	if (ImagePositionKind.Right === imagePosition)
		return (
			<CoverLayoutRight imageUrl={img} alt="sign-in-image">
				<Content>{children}</Content>
			</CoverLayoutRight>
		);

	return (
		<CoverLayoutLeft imageUrl={img} alt="sign-in-image">
			<Content>{children}</Content>
		</CoverLayoutLeft>
	);
};

export default AuthPageStyle;
