import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import LabelFieldInput from '@rdv-fo/components/fields/input/LabelFieldInput';
import { validators } from '@rdv-fo/app/lib/validation';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useAppDispatch } from '@rdv-fo/store/configureStore';
import { changeMyEmail, changeMyEmailFailed } from '@rdv-fo/store/slices/authSlice';
import { useState } from 'react';

export type ChangeEmailFormValues = {
	current_password: string;
	new_email: string;
};

interface UpdateMyEmailDialogProps {
	open: boolean;
	onClose: () => void;
}

const UpdateMyEmailDialog = ({ open, onClose }: UpdateMyEmailDialogProps) => {
	const dispatch = useAppDispatch();
	const [showPassword, setShowPassword] = useState(false);

	const fieldValidators = {
		new_email: [validators.required, validators.email],
		current_password: [validators.required],
	};

	const handleSubmitChangeMyEmail = async (values: any) => {
		const resolvedAction = await dispatch(
			changeMyEmail({ current_password: values.current_password, new_email: values.new_email })
		);

		if (resolvedAction.type === changeMyEmailFailed.type) return resolvedAction.payload;

		onClose();
	};

	return (
		<Dialog
			fullWidth
			open={open}
			maxWidth="sm"
			onClose={() => {
				onClose();
			}}
		>
			<DialogTitle variant="h4">
				Change email
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: 'black',
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<Form
					onSubmit={handleSubmitChangeMyEmail}
					validate={(values) => validators.validate(fieldValidators, values)}
				>
					{({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
						const disableFormElement = submitting || pristine || hasValidationErrors;

						return (
							<form id="change-user-email-form" onSubmit={handleSubmit}>
								<Stack direction="column" spacing={2} alignItems="stretch">
									<div>
										<LabelFieldInput
											text="New email"
											htmlFor="new_email"
											required={true}
											showOptional={false}
										/>
										<TextField
											id="new_email"
											name="new_email"
											required={true}
											variant="outlined"
											size="small"
											type="email"
											fullWidth
											disabled={submitting}
										/>
									</div>
									<div>
										<LabelFieldInput
											text="Current password"
											htmlFor="current_password"
											required={true}
											showOptional={false}
										/>
										<TextField
											id="current_password"
											name="current_password"
											required={true}
											variant="outlined"
											size="small"
											type={showPassword ? 'text' : 'password'}
											fullWidth
											disabled={submitting}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowPassword(!showPassword)}
															onMouseDown={() => setShowPassword(!showPassword)}
															size="large"
														>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>{' '}
									</div>
								</Stack>

								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={2}
									sx={{ mt: 6, mb: 0.5 }}
								>
									<Button
										color="inherit"
										size="small"
										onClick={() => {
											onClose();
										}}
										disabled={false}
									>
										Cancel
									</Button>
									<Stack direction="row" spacing={2}>
										<LoadingButton
											color="primary"
											type="submit"
											variant="contained"
											form="change-user-email-form"
											loading={submitting}
											disabled={disableFormElement}
											size="small"
											onClick={handleSubmit}
										>
											Confirm
										</LoadingButton>
									</Stack>
								</Stack>
							</form>
						);
					}}
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default UpdateMyEmailDialog;
