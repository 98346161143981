import gql from 'graphql-tag';

export const FLOW_STATE_FRAGMENT = gql`
	fragment FlowStateFragment on FlowState {
		status
		status_tech_name
		next_steps {
			type
			action_message
			config
		}
	}
`;
export const DEPRECATED_TRANSACTION_STATE_FRAGMENT = gql`
	fragment DeprecatedTransactionStateFragment on TransactionState {
		status
		status_tech_name
		next_steps {
			type
			action_message
			config
		}
	}
`;
export const SIMPLE_FIELD_WITH_FIELD_TYPE_FRAGMENT = gql`
	fragment SimpleFieldFragment on SimpleField {
		my_access
		simpleValue: value
		field_type {
			id
			name
			tech_name
			input_type
			ui_config {
				helper_text
				input_control
				label
				options {
					value
					label
				}
			}
			input_options
			is_required
			is_nullable
			category
			conditions {
				type
				payload
			}
		}
	}
`;

export const OBJECT_FIELD_WITH_FIELD_TYPE_FRAGMENT = gql`
	fragment ObjectFieldFragment on ObjectField {
		my_access
		objectValue: value {
			id_object
			fields {
				...SimpleFieldFragment
			}
		}
		field_type {
			id
			name
			tech_name
			input_type
			ui_config {
				helper_text
				input_control
				label
				options {
					value
					label
				}
			}
			input_options
			is_required
			is_nullable
			category
			conditions {
				type
				payload
			}
		}
		object_type {
			id
			name
			fields {
				id
				name
				tech_name
				input_type
				ui_config {
					helper_text
					input_control
					label
					options {
						value
						label
					}
				}
				conditions {
					type
					payload
				}
				input_options
			}
		}
	}
	${SIMPLE_FIELD_WITH_FIELD_TYPE_FRAGMENT}
`;

export const OBJECT_SET_FIELD_WITH_FIELD_TYPE_FRAGMENT = gql`
	fragment ObjectSetFieldFragment on ObjectSetField {
		id
		my_access
		objectSetValue: value {
			id_object
			fields {
				...SimpleFieldFragment
			}
		}
		field_type {
			id
			name
			tech_name
			input_type
			ui_config {
				helper_text
				input_control
				label
				options {
					value
					label
				}
			}
			input_options
			is_required
			is_nullable
			category
			conditions {
				type
				payload
			}
		}
		object_type {
			id
			name
			fields {
				id
				name
				tech_name
				input_type
				ui_config {
					helper_text
					input_control
					label
					options {
						value
						label
					}
				}
				conditions {
					type
					payload
				}
				input_options
			}
		}
	}
	${SIMPLE_FIELD_WITH_FIELD_TYPE_FRAGMENT}
`;

export const FIELDS_WITH_FIELD_TYPE_FRAGMENT = gql`
	fragment FieldsFragment on Field {
		...SimpleFieldFragment
		...ObjectFieldFragment
		...ObjectSetFieldFragment
	}
	${SIMPLE_FIELD_WITH_FIELD_TYPE_FRAGMENT}
	${OBJECT_FIELD_WITH_FIELD_TYPE_FRAGMENT}
	${OBJECT_SET_FIELD_WITH_FIELD_TYPE_FRAGMENT}
`;

export const SIMPLE_FIELD_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT = gql`
	fragment SimpleFieldWithVariantsFragment on SimpleField {
		my_access
		simpleValue: value
		field_type {
			id
			name
			tech_name
			input_type
			is_variant_identifier
			is_variant_specific
			ui_config {
				helper_text
				input_control
				label
				options {
					value
					label
				}
			}
			input_options
			is_required
			is_nullable
			category
			conditions {
				type
				payload
			}
		}
	}
`;

export const OBJECT_FIELD_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT = gql`
	fragment ObjectFieldWithVariantsFragment on ObjectField {
		my_access
		objectValue: value {
			id_object
			fields {
				...SimpleFieldFragment
			}
		}
		field_type {
			id
			name
			tech_name
			input_type
			is_variant_identifier
			is_variant_specific
			ui_config {
				helper_text
				input_control
				label
				options {
					value
					label
				}
			}
			input_options
			is_required
			is_nullable
			category
			conditions {
				type
				payload
			}
		}
		object_type {
			id
			name
			fields {
				id
				name
				tech_name
				input_type
				is_variant_identifier
				is_variant_specific
				ui_config {
					helper_text
					input_control
					label
					options {
						value
						label
					}
				}
				conditions {
					type
					payload
				}
				input_options
			}
		}
	}
	${SIMPLE_FIELD_WITH_FIELD_TYPE_FRAGMENT}
`;

export const OBJECT_SET_FIELD_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT = gql`
	fragment ObjectSetFieldWithVariantsFragment on ObjectSetField {
		id
		my_access
		objectSetValue: value {
			id_object
			fields {
				...SimpleFieldFragment
			}
		}
		field_type {
			id
			name
			tech_name
			input_type
			is_variant_identifier
			is_variant_specific
			ui_config {
				helper_text
				input_control
				label
				options {
					value
					label
				}
			}
			input_options
			is_required
			is_nullable
			category
			conditions {
				type
				payload
			}
		}
		object_type {
			id
			name
			fields {
				id
				name
				tech_name
				input_type
				is_variant_identifier
				is_variant_specific
				ui_config {
					helper_text
					input_control
					label
					options {
						value
						label
					}
				}
				conditions {
					type
					payload
				}
				input_options
			}
		}
	}
	${SIMPLE_FIELD_WITH_FIELD_TYPE_FRAGMENT}
`;

export const FIELDS_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT = gql`
	fragment FieldWithVariantsFragment on Field {
		...SimpleFieldWithVariantsFragment
		...ObjectFieldWithVariantsFragment
		...ObjectSetFieldWithVariantsFragment
	}
	${SIMPLE_FIELD_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT}
	${OBJECT_FIELD_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT}
	${OBJECT_SET_FIELD_WITH_VARIANTS_AND_FIELD_TYPE_FRAGMENT}
`;

export const PRICE_FRAGMENT = gql`
	fragment PriceFragment on SupplyPrice {
		currency
		unit_price
		qty
		total_before_tax
		total_taxes
		total_fees
		addon_items {
			description
			value
		}
		discount_items {
			description
			value
		}
		tax_items {
			description
			value
		}
		platform_fee_items {
			description
			value
			is_provider_fee
			is_consumer_fee
		}
	}
`;

export const CHARGEABLE_FRAGMENT = gql`
	fragment ChargeableFragment on Chargeable {
		currency
		total_before_tax
		total_taxes
		total_fees
		total_after_tax
		total_to_charge
		chargeable_items {
			description
			value
			type
			data
		}
	}
`;
