import Typography from '@mui/material/Typography';

interface FieldValueTextSetProps {
	value?: string[]; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
}
const FieldValueTextSet = ({ fieldTechName, value, className }: FieldValueTextSetProps) => {
	const renderValue = value === null ? 'n.a.' : value?.join(', ');

	return (
		<Typography id={fieldTechName} className={className}>
			{renderValue}
		</Typography>
	);
};

export default FieldValueTextSet;
