import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import Radio from '@mui/material/Radio';
import { RadioProps } from '@mui/material';

interface RadioWrapperProps extends FieldRenderProps<any, HTMLElement>, RadioProps { }

const RadioWrapper: React.FC<RadioWrapperProps> = ({ input: { checked, value, name, onChange, ...restInput }, meta, ...rest }) => (
	<Radio {...rest} name={name} inputProps={restInput} onChange={onChange} checked={checked} value={value} />
);

export default RadioWrapper;
