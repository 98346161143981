import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink } from 'react-router-dom';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import ROUTES from '@rdv-fo/common/routes';
import { Collection, InputKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import { RdvCellValue } from '@rdv-fo/app/lib/tableHelpers';
import routeBuilder from '@rdv-fo/common/routeBuilder';

export const buildCollectionsTableRows = (collections: Collection[]): any[] => {
	const rows = collections?.map((collection) => {
		return {
			_id: { value: collection.id, input_type: InputKind.Text },
			name: { value: collection.name, input_type: InputKind.Text },
			supplies_count: {
				value: [0, null, undefined].includes(collection.supplies_count) ? '-' : collection.supplies_count,
				input_type: InputKind.Text,
			},

			created_at: { value: collection.created_at, input_type: InputKind.Datetime },
		};
	});

	return rows;
};

export const buildCollectionsTableColumns = (): MRT_ColumnDef<any>[] => {
	const defaultColumns: MRT_ColumnDef<any>[] = [
		{
			accessorKey: '_id',
			header: 'ID',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
		{
			accessorKey: 'name',
			header: 'Name',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={InputKind.Text} value={data.value} />;
			},
		},

		{
			accessorKey: 'supplies_count',
			header: '# of items',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},

		{
			accessorKey: 'created_at',
			header: 'Created',
			Cell: ({ cell }) => {
				const data = cell.getValue<RdvCellValue>() as RdvCellValue;
				return <FieldView inputKind={data.input_type} value={data.value} />;
			},
		},
	];

	return defaultColumns;
};

const NoDataView = () => {
	return (
		<Stack mt={2} direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
			<Typography align="center" variant="subtitle1">
				No collections yet
			</Typography>
			<Typography align="center" variant="body2">
				Your collection will appear hear
			</Typography>
		</Stack>
	);
};

interface CollectionsTableProps {
	collections: Collection[];
}

const CollectionsTable = ({ collections = [] }: CollectionsTableProps) => {
	const transactionColumnDefinitions = buildCollectionsTableColumns();

	const transactionRow = buildCollectionsTableRows(collections) ?? [];

	return (
		<>
			<MaterialReactTable
				initialState={{
					pagination: { pageSize: 50, pageIndex: 0 },
					columnVisibility: {
						_id: false,
					},
				}}
				columns={transactionColumnDefinitions}
				enablePinning={false}
				data={transactionRow}
				enableTableHead={true}
				enableTopToolbar={false}
				enableSorting={false}
				enablePagination={false}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnActions={false}
				enableHiding={false}
				enableColumnFilters={false}
				enableColumnFilterModes={false}
				rowCount={collections?.length}
				muiTableBodyProps={{
					children: collections?.length < 1 ? <NoDataView /> : null,
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={{
					sx: {
						'&:hover': {
							backgroundColor: RDV_NEUTRAL_20,
						},
					},
				}}
				positionActionsColumn="last"
				enableRowActions={true}
				renderRowActions={({ row }) => {
					const collectionId = row.original?._id?.value;
					const targetRoute = routeBuilder(ROUTES.UPDATE_MY_COLLECTION, [[':collectionId', collectionId]]);

					return (
						<IconButton component={RouterLink} to={targetRoute} aria-label="view details" size="small">
							<ArrowForwardIcon fontSize="small" sx={{ color: 'black' }} />
						</IconButton>
					);
				}}
				enableFullScreenToggle={false}
				enableRowOrdering={false}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				renderBottomToolbar={
					collections?.length > 0 &&
					(() => {
						let label = `${collections?.length} collections`;
						if (collections?.length === 1) label = `${collections?.length} collection`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default CollectionsTable;
