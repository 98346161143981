import PaymentsService from './payments';
import TransactionsService from './transactions';
import AuthService from './auth/authService';
import FilesService from './files';
import ParticipantsService from './participants';
import PlatformService from './platforms';
import SuppliesService from './supplies';
import ConnectionsService from './connections/connectionsService';
import ObjectsService from './objects/objectsService';
import ReferralService from './referrals/referralService';

export default {
	PaymentsService,
	TransactionsService,
	AuthService,
	FilesService,
	ParticipantsService,
	PlatformService,
	SuppliesService,
	ConnectionsService,
	ObjectsService,
	ReferralService,
};
