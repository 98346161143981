import * as paymentQueries from './paymentQueries';
import * as paymentMutations from './paymentMutations';
import {
	QueryGetPaymentArgs,
	MutationRequestMerchantAccountConnectionArgs,
	MutationPrepareTransactionPaymentArgs,
	Payment,
	Transfer,
	MutationRequestPaymentCheckoutConnectionArgs,
	MutationRequestPayoutArgs,
	MutationRequestStripeDashboardSignInLinkArgs,
	MutationRequestBankTransferCheckoutArgs,
	QueryMyPaymentsArgs,
	PaymentConnection,
	QueryMyTransfersArgs,
	TransferConnection,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

class PaymentsService {
	private randevu: any;

	constructor(randevu: any) {
		this.randevu = randevu;
	}

	async myPayment({ id }: QueryGetPaymentArgs): Promise<{ payment: Payment | null; errors: any }> {
		const payload = paymentQueries.myPayment({ id });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { payment: data?.myPayment, errors };
	}
	async myPayments({
		where,
		after,
		before,
		first,
		last,
	}: QueryMyPaymentsArgs): Promise<{ payment_connection: PaymentConnection | null; errors: any }> {
		const payload = paymentQueries.myPayments({ where, after, before, first, last });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { payment_connection: data?.myPayments, errors };
	}

	async prepareTransactionPayment({
		id_transaction,
		tech_name,
	}: MutationPrepareTransactionPaymentArgs): Promise<{ payment: Payment; errors: any }> {
		const payload = paymentMutations.prepareTransactionPayment({
			id_transaction,
			tech_name,
		});

		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { payment: data?.prepareTransactionPayment, errors };
	}

	/**
	 * Calls PSP to obtain an URL where randevu participant can execute the payment
	 * @param
	 * @returns URL from PSP where randevu participant can execute payment
	 */
	async requestPaymentCheckoutConnection({
		id_payment,
		success_url,
		cancel_url,
	}: MutationRequestPaymentCheckoutConnectionArgs): Promise<{ url: string; errors: any }> {
		const payload = paymentMutations.requestPaymentCheckoutConnection({ id_payment, success_url, cancel_url });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { url: data?.requestPaymentCheckoutConnection.url, errors };
	}

	async requestBankTransferCheckout({
		id_payment,
	}: MutationRequestBankTransferCheckoutArgs): Promise<{ payment: Payment; errors: any }> {
		const payload = paymentMutations.requestBankTransferCheckout({ id_payment });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });

		return { payment: data?.requestBankTransferCheckout, errors };
	}

	/**
	 * Allows provider to connect its randevu account (participant account) to an merchant account at given PSP
	 * E.g. Provider gets URL to connect its randevu account to its Stripe account (if no Stripe account is available, provider will have to create one)
	 * E.g. Provider gets URL to connect its randevu account to its PayPal account (if no PayPal account is available, provider will have to create one)
	 * @param
	 * @returns URL to create/update an merchant account
	 */
	async requestMerchantAccountConnection({
		id_integration_provider,
		refresh_url,
		return_url,
	}: MutationRequestMerchantAccountConnectionArgs): Promise<{ url: Payment | null; errors: any }> {
		const payload = paymentMutations.requestMerchantAccountConnection({
			id_integration_provider,
			refresh_url,
			return_url,
		});
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { url: data?.requestMerchantAccountConnection?.url, errors };
	}

	/**
	 * Composed method to allow provider to connect its randevu account to its Stripe Account
	 * @param
	 * @returns URL to Stripe
	 */
	async requestStripeConnectAccountConnection({
		id_integration_provider,
		refresh_url,
		return_url,
	}: MutationRequestMerchantAccountConnectionArgs): Promise<{ url: Payment | null; errors: any }> {
		return await this.requestMerchantAccountConnection({
			id_integration_provider,
			refresh_url,
			return_url,
		});
	}

	async myTransfers({
		where,
		after,
		before,
		first,
		last,
	}: QueryMyTransfersArgs): Promise<{ transfer_connection: TransferConnection; errors: any }> {
		const payload = paymentQueries.myTransfers({ where, after, before, first, last });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { transfer_connection: data?.myTransfers, errors };
	}
	async requestPayout({
		id_merchant_account,
	}: MutationRequestPayoutArgs): Promise<{ requested: Boolean; errors: any }> {
		const payload = paymentMutations.requestPayout({ id_merchant_account });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { requested: data?.requestPayout, errors };
	}
	async requestStripeDashboardSignInLink({
		id_merchant_account,
	}: MutationRequestStripeDashboardSignInLinkArgs): Promise<{ url: string; errors: any }> {
		const payload = paymentMutations.requestStripeDashboardSignInLink({ id_merchant_account });
		const { data, errors } = await this.randevu.assertPublicApiKey().call({ payload });
		return { url: data?.requestStripeDashboardSignInLink, errors };
	}
}

export default PaymentsService;
