import LabelFilterInput from './LabelFilterInput';

import { FilterInputBaseProps } from './types';
import { Stack, Typography, Box } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker, TextField } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { FieldArray } from 'react-final-form-arrays';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

// FIXME: set proper types
const parse = (value: any) => {
	if (value === undefined || value === null) return null;

	return value?.toISOString().split('T')[0];
};

// FIXME: set proper types
const format = (value: any) => {
	if (value === undefined || value === null) return null;
	return new Date(value);
};
interface FilterInputDateProps extends FilterInputBaseProps {
	options: any;
}

const FilterInputDateSet = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
	options,
	useDeprecatedFilterInputs
}: FilterInputDateProps) => {
	return (
		<Stack spacing={2}>
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}

			<FieldArray name={`${techName}.values`}>
				{({ fields, meta }) => {
					return (
						<>
							<Stack spacing={2}>
								{fields?.map((name, index) => {
									return (
										<Box key={index}>
											<Stack
												direction="row"
												justifyContent="flex-start"
												alignItems="flex-start"
												spacing={4}
											>
												<LocalizationProvider dateAdapter={DateFnsUtils}>
													<DatePicker
														name={name}
														required={required}
														disabled={disabled}
														InputProps={{
															onKeyDown: (e) => e.preventDefault(),
														}}
														inputFormat="dd/MM/yyyy"
														fieldProps={{
															parse,
															format,

															disabled,

															inputVariant: 'outlined',
															clearable: required,
														}}
													/>
												</LocalizationProvider>

												<IconButton
													disabled={disabled}
													onClick={() => fields.remove(index)}
													aria-label="remove"
												>
													<ClearIcon />
												</IconButton>
											</Stack>
										</Box>
									);
								})}
								<Box>
									<Button
										size="small"
										color="primary"
										startIcon={<AddIcon />}
										disabled={disabled}
										onClick={() => {
											fields.push(undefined);
										}}
									>
										Add new
									</Button>
								</Box>
							</Stack>
						</>
					);
				}}
			</FieldArray>

			{useDeprecatedFilterInputs &&
				<>
					<Typography>How many dates should at least be matched?</Typography>
					<TextField
						name={`${techName}.min_hits`}
						type="number"
						variant="outlined"
						size="small"
						disabled={disabled}
						fullWidth
						inputProps={{
							step: 1,
							max: options?.length,
							min: 1,
						}}
					/>
				</>
			}
		</Stack>
	);
};

export default FilterInputDateSet;
