import { Chip as MuiChip } from '@mui/material/';
import getEnumKeyByValue from '@rdv-fo/app/lib/getEnumKeyByValue';

import { ParticipantStatusKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import useChipStyles from '../common/chip/chipColors';

const ParticipantStatusChip = ({ status }) => {
	const classes = useChipStyles();

	let colorClass;
	let value;

	switch (status) {
		case ParticipantStatusKind.PendingEmailVerification:
			colorClass = classes.blue;
			value = getEnumKeyByValue(ParticipantStatusKind.PendingEmailVerification, ParticipantStatusKind);
			break;

		case ParticipantStatusKind.Onboarded:
			colorClass = classes.green;
			value = getEnumKeyByValue(ParticipantStatusKind.Onboarded, ParticipantStatusKind);
			break;

		case ParticipantStatusKind.Rejected:
			colorClass = classes.red;
			value = getEnumKeyByValue(ParticipantStatusKind.Rejected, ParticipantStatusKind);
			break;

		default:
			colorClass = classes.chipGray;
			value = status;
	}

	const customClasses = `${colorClass} ${classes.chip}`;

	return <MuiChip label={value} className={customClasses} size="small" />;
};

export default ParticipantStatusChip;
