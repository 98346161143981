import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { FieldArray } from 'react-final-form-arrays';
import { TextField } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';

import LabelFieldInput from './LabelFieldInput';
import { FieldInputBaseProps } from './types';
import { useForm } from 'react-final-form';
import { parseToNull } from '@rdv-fo/app/lib/formHelpers';

const missingValue = ['', undefined, null];

interface TextInputOptions {
    required: boolean;
    minLength?: number;
    maxLength?: number;
    pattern: string;
    format: 'URL' | 'EMAIL' | 'CUSTOM';
}

const validate = (value: string, options: TextInputOptions) => {
    const { required, pattern, format, minLength, maxLength } = options;

    // if the value is not required and the user did not provide any value, we do not want to validate it
    // if the value is not required, we validate the value only if its provided
    if (!required && missingValue.includes(value)) return undefined;

    if (pattern) {
        const regex = new RegExp(pattern);

        const isValid = regex.test(value);

        if (!isValid) return VALIDATION_MESSAGE.REGEX_PATTERN_VIOLATION
    }

    if (minLength && value?.length < minLength)
        return VALIDATION_MESSAGE.MIN_LENGTH_VIOLATION.replace('{min_length}', String(minLength));
    if (maxLength && value?.length > maxLength)
        return VALIDATION_MESSAGE.MAX_LENGTH_VIOLATION.replace('{max_length}', String(maxLength));

    if (required && (missingValue.includes(value) || !value?.trim()))
        return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

    return undefined;
};

interface FieldInputSmartTextSetProps extends FieldInputBaseProps {
    minLength?: number;
    maxLength?: number;
    pattern: string;
    format: 'URL' | 'EMAIL' | 'CUSTOM';
    minItems?: number;
    maxItems?: number;
    keepNull?: boolean;

}

export const FieldInputSmartTextSet = ({
    techName,
    label,
    labelProps,
    helperText,
    disabled = false,
    required = false,
    pattern,
    format,
    minLength,
    maxLength,
    keepNull = false,
    minItems,
    maxItems,
}: FieldInputSmartTextSetProps) => {
    const fieldProps = {
        validate: (value: string) => validate(value, { required, pattern, format, minLength, maxLength }),
        ...(keepNull && { parse: parseToNull }),
    };
    const form = useForm();

    return (
        <>
            <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
                <LabelFieldInput
                    text={label}
                    htmlFor={techName}
                    required={required}
                    showOptional={labelProps?.showOptional}
                />
                <FieldArray name={`${techName}`}>
                    {({ fields, meta }) => {
                        const numberOfItemsInArray = fields.length;

                        return (
                            <>
                                <Stack spacing={2}>
                                    {fields?.map((name, index) => {
                                        return (
                                            <Box key={index}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={4}
                                                >
                                                    <TextField
                                                        id={name}
                                                        name={name}
                                                        disabled={disabled}
                                                        required={required}
                                                        helperText={helperText}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        fullWidth
                                                        {...(format === 'EMAIL' && { type: 'email' })}
                                                        {...(format === 'URL' && { type: 'url' })}
                                                        fieldProps={fieldProps}
                                                    />

                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        onClick={() => {
                                                            if (index === 0 && fields.length === 1)
                                                                form.change(techName, null);
                                                            else fields.remove(index);
                                                        }}
                                                        size="small"
                                                        startIcon={<ClearIcon />}
                                                        style={{ padding: 1 }}
                                                        disabled={disabled}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        );
                                    })}
                                    <Box>
                                        {maxItems && (
                                            <>
                                                {numberOfItemsInArray !== undefined &&
                                                    numberOfItemsInArray < maxItems ? (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                        disabled={disabled}
                                                        onClick={() => {
                                                            fields.push(undefined);
                                                        }}
                                                    >
                                                        Add new
                                                    </Button>
                                                ) : (
                                                    <Typography variant="body2" color="textSecondary">
                                                        You are not allowed to add more because the maximum of{' '}
                                                        {maxItems} allowed items is reached.
                                                    </Typography>
                                                )}
                                            </>
                                        )}

                                        {maxItems === undefined && (
                                            <Button
                                                size="small"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                disabled={disabled}
                                                onClick={() => {
                                                    fields.push(undefined);
                                                }}
                                            >
                                                Add new
                                            </Button>
                                        )}
                                    </Box>
                                </Stack>
                            </>
                        );
                    }}
                </FieldArray>
            </Stack>
        </>
    );
};

export default FieldInputSmartTextSet;
