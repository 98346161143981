import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

interface DownloadFileProps {
	label: string;
	labelSecondary?: string;
	url: string;
	physicalFileName: string;
	onDownload: (url: string, fileName: string) => void;
}
const DownloadFile = ({ label, labelSecondary, url, physicalFileName, onDownload }: DownloadFileProps) => {
	return (
		<Stack
			direction="row"
			sx={{ backgroundColor: '#E4E6ED', pl: 1.25, pr: 1.25, pt: 1, pb: 1, borderRadius: '8px' }}
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ width: '100%' }}>
				<Typography>{label}</Typography>
				{labelSecondary && <Typography variant="body2">{labelSecondary}</Typography>}
			</Stack>
			<IconButton aria-label={`download ${label}`} size="small" onClick={() => onDownload(url, physicalFileName)}>
				<DownloadOutlinedIcon fontSize="inherit" sx={{ color: 'black' }} />
			</IconButton>
		</Stack>
	);
};

export default DownloadFile;
