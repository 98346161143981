// TODO: VERIFY IF CAN BE REMOVED FROM THE CODE BASE
export const removeFilterKeys = (values) => {
	return Object.keys(values).reduce((acc, key) => {
		if (!key.match(/^fit-/)) {
			const newValue = typeof values[key] === 'object' ? { ...values[key] } : values[key];
			return { ...acc, [key]: newValue };
		}
		return { ...acc };
	}, {});
};
