import randevu from '@rdv-fo/services/randevuApi';
import {
	hasSubmissionErrors,
	isDuplicate,
	isInvalidParameter,
	isSessionExpired,
} from '@rdv-fo/services/randevuApi/errors/errorHelper';
import {
	Connection,
	ConnectionType,
	Field,
	FieldInput,
	MutationAcceptConnectionArgs,
	MutationDeclineConnectionArgs,
	MutationReferParticipantUserArgs,
	Participant,
	QueryDiscoverConnectionParticipantsArgs,
	QueryMyConnectionArgs,
	QueryMyConnectionsArgs,
	Referral,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleSessionExpired } from '../helpers/handleSessionExpired';
import { sendInfoToasty, sendSuccessToasty, sendUnexpectedErrorToasty } from './uiSlice';
import type { AppDispatch, RootState } from '@rdv-fo/store/configureStore';
import THUNKS from '../thunkMap';
import { sleep } from '@rdv-fo/services/sleep';
import { SLEEP_TIME_FOR_ASYNC_OPERATIONS } from '../helpers/storeHelpers';

interface ConnectionSliceState {
	items: Connection[];
	types: ConnectionType[];
	item: Connection | null;
	discoveredParticipants: Participant[];
	discoveredParticipant: Participant | null;
	loading: boolean;
	connectParticipantsLoading: boolean;
	referrals: Referral[];
	updatingConnection: boolean;
	acceptingConnection: boolean;
	decliningConnection: boolean;
	errors: any | null; // FIXME: set proper type
}

const initialState: ConnectionSliceState = {
	items: [],
	types: [],
	discoveredParticipants: [],
	discoveredParticipant: null,
	item: null,
	loading: false,
	connectParticipantsLoading: false,
	referrals: [],
	updatingConnection: false,
	acceptingConnection: false,
	decliningConnection: false,
	errors: null,
};

const slice = createSlice({
	name: 'connection',
	initialState,
	reducers: {
		loadConnectionsRequested: (connection: ConnectionSliceState) => {
			connection.items = [];
			connection.loading = true;
			connection.errors = null;
		},
		loadConnectionsFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.loading = false;
			connection.errors = action.payload;
		},
		connectionsLoaded: (connection: ConnectionSliceState, action: PayloadAction<Connection[]>) => {
			connection.items = action.payload;
			connection.errors = null;
			connection.loading = false;
		},
		createConnectionRequested: (connection: ConnectionSliceState) => {
			connection.item = null;
			connection.loading = true;
			connection.errors = null;
		},
		createConnectionFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.loading = false;
			connection.errors = action.payload;
		},
		connectionCreated: (connection: ConnectionSliceState, action: PayloadAction<Connection>) => {
			connection.item = action.payload;
			connection.items = connection.items.map((item) => {
				if (item.id === action.payload.id) return action.payload;

				return item;
			});
			connection.errors = null;
			connection.loading = false;
		},
		createConnectionWithDiscoveredParticipantRequested: (connection: ConnectionSliceState) => {
			connection.item = null;
			connection.connectParticipantsLoading = true;
			connection.errors = null;
		},
		createConnectionWithDiscoveredParticipantFailed: (
			connection: ConnectionSliceState,
			action: PayloadAction<any>
		) => {
			connection.connectParticipantsLoading = false;
			connection.errors = action.payload;
		},
		connectionCreatedWithDiscoveredParticipant: (
			connection: ConnectionSliceState,
			action: PayloadAction<Connection>
		) => {
			connection.items = [...connection.items, action.payload];
			connection.errors = null;
			connection.connectParticipantsLoading = false;
		},
		loadConnectionTypesRequested: (connection: ConnectionSliceState) => {
			connection.types = [];
			connection.loading = true;
			connection.errors = null;
		},
		loadConnectionTypesFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.loading = false;
			connection.errors = action.payload;
		},
		connectionTypesLoaded: (connection: ConnectionSliceState, action: PayloadAction<ConnectionType[]>) => {
			connection.types = action.payload;
			connection.errors = null;
			connection.loading = false;
		},
		discoverConnectionParticipantsRequested: (connection: ConnectionSliceState) => {
			connection.discoveredParticipants = [];
			connection.loading = true;
			connection.errors = null;
		},
		discoverConnectionParticipantsFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.loading = false;
			connection.errors = action.payload;
		},
		connectionParticipantsDiscovered: (connection: ConnectionSliceState, action: PayloadAction<Participant[]>) => {
			connection.discoveredParticipants = action.payload;
			connection.errors = null;
			connection.loading = false;
		},
		discoverConnectionParticipantRequested: (connection: ConnectionSliceState) => {
			connection.discoveredParticipant = null;
			connection.loading = true;
			connection.errors = null;
		},
		discoverConnectionParticipantFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.loading = false;
			connection.errors = action.payload;
		},
		connectionParticipantDiscovered: (connection: ConnectionSliceState, action: PayloadAction<Participant>) => {
			connection.discoveredParticipant = action.payload;
			connection.errors = null;
			connection.loading = false;
		},
		loadConnectionRequested: (connection: ConnectionSliceState) => {
			connection.item = null;
			connection.loading = true;
			connection.errors = null;
		},
		loadConnectionFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.loading = false;
			connection.errors = action.payload;
		},
		connectionLoaded: (connection: ConnectionSliceState, action: PayloadAction<Connection>) => {
			connection.item = action.payload;
			connection.errors = null;
			connection.loading = false;
		},
		updateConnectionRequested: (connection: ConnectionSliceState) => {
			connection.updatingConnection = true;
			connection.errors = null;
		},
		updateConnectionFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.updatingConnection = false;
			connection.errors = action.payload;
		},
		connectionUpdated: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.updatingConnection = false;
			connection.item = action.payload;
		},
		acceptConnectionRequested: (connection: ConnectionSliceState) => {
			connection.errors = null;
			connection.acceptingConnection = true;
		},
		acceptConnectionFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.errors = action.payload;
			connection.acceptingConnection = false;
		},
		connectionAccepted: (connection: ConnectionSliceState, action: PayloadAction<Connection>) => {
			connection.item = action.payload;
			connection.acceptingConnection = false;
		},
		declineConnectionRequested: (connection: ConnectionSliceState) => {
			connection.errors = null;
			connection.decliningConnection = true;
		},
		declineConnectionFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.errors = action.payload;
			connection.decliningConnection = false;
		},
		connectionDeclined: (connection: ConnectionSliceState, action: PayloadAction<Connection>) => {
			connection.item = action.payload;
			connection.decliningConnection = false;
		},
		loadReferralsRequested: (connection: ConnectionSliceState) => {
			connection.referrals = [];
			connection.loading = true;
			connection.errors = null;
		},
		loadReferralsFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.loading = false;
			connection.errors = action.payload;
		},
		referralsLoaded: (connection: ConnectionSliceState, action: PayloadAction<Referral[]>) => {
			connection.referrals = action.payload;
			connection.errors = null;
			connection.loading = false;
		},
		referralParticipantUserRequested: (connection: ConnectionSliceState) => {
			connection.errors = null;
		},
		referralParticipantUserFailed: (connection: ConnectionSliceState, action: PayloadAction<any>) => {
			connection.errors = action.payload;
		},
		participantUserReferred: (connection: ConnectionSliceState, action: PayloadAction<Referral>) => {
			connection.referrals.push(action.payload);
			connection.errors = null;
		},
	},
});

export const {
	loadReferralsRequested,
	loadReferralsFailed,
	referralsLoaded,
	acceptConnectionRequested,
	acceptConnectionFailed,
	connectionAccepted,
	declineConnectionRequested,
	declineConnectionFailed,
	connectionDeclined,
	loadConnectionsRequested,
	loadConnectionsFailed,
	connectionLoaded,
	loadConnectionRequested,
	loadConnectionFailed,
	connectionsLoaded,
	updateConnectionRequested,
	updateConnectionFailed,
	connectionUpdated,
	createConnectionWithDiscoveredParticipantRequested,
	createConnectionWithDiscoveredParticipantFailed,
	loadConnectionTypesRequested,
	loadConnectionTypesFailed,
	connectionTypesLoaded,
	discoverConnectionParticipantsRequested,
	discoverConnectionParticipantsFailed,
	connectionParticipantsDiscovered,
	referralParticipantUserRequested,
	referralParticipantUserFailed,
	participantUserReferred,
	createConnectionRequested,
	createConnectionFailed,
	connectionCreated,
	discoverConnectionParticipantRequested,
	discoverConnectionParticipantFailed,
	connectionParticipantDiscovered,
	connectionCreatedWithDiscoveredParticipant,
} = slice.actions;

export default slice.reducer;

/////////////////////
// ACTION CREATORS //
/////////////////////

export const loadMyConnections =
	({ where }: QueryMyConnectionsArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(loadConnectionsRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { connections, errors } = await randevuService.connections.myConnections({ where });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: loadConnectionsFailed } as any);

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadConnectionsFailed(errors));
		}

		return dispatch(connectionsLoaded(connections));
	};

interface CreateConnectonRequest {
	connectionTypeTechName: string;
	idParticipant: string;
	fields: FieldInput[];
}

export const createConnection =
	({ connectionTypeTechName, idParticipant, fields }: CreateConnectonRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(createConnectionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { connection, errors } = await randevuService.connections.createConnection({
			connection_tech_name: connectionTypeTechName,
			id_participant: idParticipant,
			fields,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: loadConnectionsFailed } as any);

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(createConnectionFailed(errors));
		}

		if (!connection) {
			dispatch(sendUnexpectedErrorToasty('Error, please try again'));
			return dispatch(createConnectionFailed('Error, please try again'));
		}

		dispatch(sendSuccessToasty('Request sent!'));

		return dispatch(connectionCreated(connection));
	};
export const createConnectionWithDiscoveredParticipant =
	({ connectionTypeTechName, idParticipant, fields }: CreateConnectonRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(createConnectionWithDiscoveredParticipantRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { connection, errors } = await randevuService.connections.createConnection({
			connection_tech_name: connectionTypeTechName,
			id_participant: idParticipant,
			fields,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: createConnectionWithDiscoveredParticipantFailed,
			} as any);

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(createConnectionWithDiscoveredParticipantFailed(errors));
		}

		if (!connection) {
			dispatch(sendUnexpectedErrorToasty('Error, please try again'));
			return dispatch(createConnectionWithDiscoveredParticipantFailed('Error, please try again'));
		}

		return dispatch(connectionCreatedWithDiscoveredParticipant(connection));
	};

export const discoverConnectionParticipants =
	({ connection_tech_name, where }: QueryDiscoverConnectionParticipantsArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(discoverConnectionParticipantsRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { participants, errors } = await randevuService.connections.discoverConnectionParticipants({
			where,
			connection_tech_name: connection_tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: discoverConnectionParticipantsFailed,
			} as any);

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(discoverConnectionParticipantsFailed(errors));
		}

		return dispatch(connectionParticipantsDiscovered(participants));
	};

export const loadMyConnectionTypes = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadConnectionTypesRequested());

	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

	const { connection_types, errors } = await randevuService.connections.myConnectionTypes();

	if (isSessionExpired(errors))
		return handleSessionExpired({ dispatch, state: getState(), failedAction: loadConnectionTypesFailed } as any);

	if (errors) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadConnectionTypesFailed(errors));
	}

	return dispatch(connectionTypesLoaded(connection_types));
};
export const loadMyConnection =
	({ id }: QueryMyConnectionArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(loadConnectionRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { connection, errors } = await randevuService.connections.myConnection({ id });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: loadConnectionFailed } as any);

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(loadConnectionFailed(errors));
		}

		return dispatch(connectionLoaded(connection));
	};

interface UpdateConnectionRequest {
	id: string;
	dirty_fields: FieldInput[];
	current_fields: Field[];
}
export const updateConnection =
	({ id, dirty_fields = [], current_fields = [] }: UpdateConnectionRequest) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		console.log(`Update connection '${id}'`);

		if (dirty_fields?.length < 1) return;

		dispatch(updateConnectionRequested());

		const oldSupply = getState().connection.item;

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		// VERIFY THAT SESSION IS NOT EXPIRED BEFORE UPLOADING THE FILES
		const { errors: expiredSession } = await randevuService.auth.verifyValidSession();

		if (isSessionExpired(expiredSession))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: updateConnectionFailed,
				reauthenticationCallback: {
					callbackThunkKey: THUNKS.UPDATE_CONNECTION.key,
					payload: { id, dirty_fields, current_fields },
				},
			});

		const { field_inputs: dirty_fields_with_uploaded_files, errors: uploadErrors } =
			await randevuService.files.uploadFieldInputsFiles({
				field_inputs: dirty_fields,
				fields: current_fields,
			});

		const { updated, errors } = await randevuService.connections.updateConnection({
			id,
			fields: dirty_fields_with_uploaded_files ?? [],
		});

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(updateConnectionFailed(errors));
		}

		await sleep(SLEEP_TIME_FOR_ASYNC_OPERATIONS);

		const { connection, errors: updated_supply_errors } = await randevuService.connections.myConnection({ id });

		dispatch(sendSuccessToasty('Saved'));

		return dispatch(connectionUpdated(connection));
	};

export const acceptConnection =
	({ id }: MutationAcceptConnectionArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(acceptConnectionRequested());

		const randevuService = new randevu({
			token: getState().auth.token,
			apiKey: getState().platform.public_key,
		});
		const { accepted, errors } = await randevuService.connections.acceptConnection({ id });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: acceptConnectionFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(acceptConnectionFailed(errors));
		}

		const { connection, errors: connectionErrors } = await randevuService.connections.myConnection({ id });

		if (connectionErrors) {
			dispatch(sendUnexpectedErrorToasty(errors));
		}

		await sleep(SLEEP_TIME_FOR_ASYNC_OPERATIONS);
		await dispatch(sendInfoToasty('Accepted'));
		return dispatch(connectionAccepted(connection));
	};
export const declineConnection =
	({ id }: MutationDeclineConnectionArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(declineConnectionRequested());

		const randevuService = new randevu({
			token: getState().auth.token,
			apiKey: getState().platform.public_key,
		});
		const { declined, errors } = await randevuService.connections.declineConnection({ id });

		if (isSessionExpired(errors))
			return handleSessionExpired({ dispatch, state: getState(), failedAction: declineConnectionFailed });

		if (errors) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(declineConnectionFailed(errors));
		}

		const { connection, errors: connectionErrors } = await randevuService.connections.myConnection({ id });

		if (connectionErrors) {
			dispatch(sendUnexpectedErrorToasty(errors));
		}

		await sleep(SLEEP_TIME_FOR_ASYNC_OPERATIONS);
		await dispatch(sendInfoToasty('Declined'));
		return dispatch(connectionDeclined(connection));
	};

export const loadMyReferrals = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	dispatch(loadReferralsRequested());

	const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

	const { referrals, errors } = await randevuService.referrals.myReferrals();

	if (isSessionExpired(errors))
		return handleSessionExpired({
			dispatch,
			state: getState(),
			failedAction: loadReferralsFailed,
		} as any);

	if (errors) {
		dispatch(sendUnexpectedErrorToasty(errors));
		return dispatch(loadReferralsFailed(errors));
	}

	return dispatch(referralsLoaded(referrals));
};

export const referParticipantUser =
	({ first_name, last_name, email, participant_tech_name }: MutationReferParticipantUserArgs) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(referralParticipantUserRequested());

		const randevuService = new randevu({ token: getState().auth.token, apiKey: getState().platform.public_key });

		const { referral, errors } = await randevuService.referrals.referParticipantUser({
			first_name,
			last_name,
			email,
			participant_tech_name,
		});

		if (isSessionExpired(errors))
			return handleSessionExpired({
				dispatch,
				state: getState(),
				failedAction: referralParticipantUserFailed,
			} as any);

		if (hasSubmissionErrors(errors)) {
			let submissionErrors = {
				...(isDuplicate(errors) && { email: 'User already invited.' }),
				...(isInvalidParameter(errors) && { email: 'Not a valid email.' }),
			};
			if (Object.keys(submissionErrors).length) return dispatch(referralParticipantUserFailed(submissionErrors));
		}

		if (errors || referral === null) {
			dispatch(sendUnexpectedErrorToasty(errors));
			return dispatch(referralParticipantUserFailed(errors));
		}

		return dispatch(participantUserReferred(referral));
	};

/////////////////////
//   SELECTORS     //
/////////////////////
export const selectMyConnections = (state: RootState) => state.connection.items;
export const selectMyConnectionTypes = (state: RootState) => state.connection.types;
export const selectMyConnection = (state: RootState) => state.connection.item;
export const selectLoading = (state: RootState) => state.connection.loading;
export const selectConnectParticipantsLoading = (state: RootState) => state.connection.connectParticipantsLoading;
export const selectAcceptingConnection = (state: RootState) => state.connection.acceptingConnection;
export const selectDecliningConnection = (state: RootState) => state.connection.decliningConnection;
export const selectDiscoveredConnectionParticipants = (state: RootState) => state.connection.discoveredParticipants;
export const selectMyReferrals = (state: RootState) => state.connection.referrals;
