export const API_STATUS = {
	IDLE: 'idle',
	INIT: 'init',
	LOADING: 'loading',
	FAILED: 'failed',
	SUCCEEDED: 'succeeded',
	FINISHED: 'finished',
};

export default API_STATUS;
