import Stack from '@mui/material/Stack';

import FilterInput from '@rdv-fo/components/fields/filter/FilterInput';
import FieldInput from '@rdv-fo/components/fields/input/FieldInput';
import LoadingIndicator from '@rdv-fo/components/common/LoadingIndicator';
import ConditionalFieldInput from './fields/input/ConditionalFieldInput';

// FIXME: REFACTOR INPUTS CONTAINER PROPERLY!
const InputsContainer = ({ fieldTypes, sharedObjects, disabled, showInputs, treatInputsAsFilters, sharedObjectTypes }) => {
	return (
		<>
			{showInputs ? (
				<>
					<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={3}>
						{fieldTypes?.map((field_type) => {
							return (
								<>
									{treatInputsAsFilters ? (
										<FilterInput
											label={field_type?.name}
											fieldTechName={field_type?.tech_name}
											inputType={field_type?.input_type}
											inputOptions={field_type?.input_options}
											required={field_type?.is_required}
											disabled={disabled}
											optionsUiConfig={field_type?.ui_config?.options}
										/>
									) : (
										<ConditionalFieldInput
											key={field_type?.tech_name}
											fields={fieldTypes?.map((field) => {
												return { ...field, field_type: { ...field } };
											})}
											conditions={field_type?.conditions}
										>
											<FieldInput
												label={field_type?.ui_config?.label ?? field_type?.name}
												helperText={field_type?.ui_config?.helper_text}
												optionsUiConfig={field_type?.ui_config?.options}
												fieldTechName={field_type?.tech_name}
												inputType={field_type?.input_type}
												inputOptions={field_type?.input_options}
												objectTypeFields={field_type?.object_fields}
												required={field_type.is_required}
												disabled={disabled}
												sharedObjectTypes={sharedObjectTypes}
												sharedObjects={sharedObjects}
											/>
										</ConditionalFieldInput>
									)}
								</>
							);
						})}
					</Stack>
				</>
			) : (
				<LoadingIndicator loading={!showInputs} height={15} />
			)}
		</>
	);
};

export default InputsContainer;
