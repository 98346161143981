import { Box, Button, Divider, Drawer, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { findFieldByTechName } from '@rdv-fo/app/lib/fieldsHelper';
import { CurrencyKind, FieldAccessKind, SupplyPrice } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldInput from '../../fields/input/FieldInput';
import { Form, FormSpy } from 'react-final-form';
import { SaveOutlined } from '@mui/icons-material';
import { formatPriceAmount } from '../../supply/supplyHelpers';
import ConditionalFieldInput from '../../fields/input/ConditionalFieldInput';

interface EditShoppingCartItemDrawerProps {
	currentItem: any;
	matchParameterTypeTechNames: string[];
	matchParameterTypes: any[];
	open: boolean;
	sharedObjects: any[];
	sharedObjectTypes: any[];
	calculatedSupplyPrice: SupplyPrice | null;
	loading: boolean;
	showPrice: boolean;
	initialValues: any;
	onClose: () => void;
	onSubmit: (values: any, form: any) => void;
}

const EditShoppingCartItemDrawer = ({
	currentItem,
	showPrice,
	onSubmit,
	initialValues,
	loading,
	calculatedSupplyPrice,
	matchParameterTypes,
	matchParameterTypeTechNames,
	open,
	sharedObjectTypes,
	sharedObjects,
	onClose,
}: EditShoppingCartItemDrawerProps) => {
	const DRAWER_WIDTH = '600px';

	const imageField = findFieldByTechName(currentItem?.supply_fields, 'main_image');
	const nameField = findFieldByTechName(currentItem?.supply_fields, 'name');

	const matchParamFields = currentItem?.fields?.filter((field: any) =>
		matchParameterTypeTechNames.includes(field?.field_type?.tech_name)
	);
	const matchFields = currentItem?.fields;
	const priceAffectingTechNames = matchParamFields.map((mpf: any) => mpf.field_type.tech_name);

	const consumerSupplyFees = currentItem?.price?.platform_fee_items?.filter((fee: any) => fee.is_consumer_fee) ?? [];

	const currency = calculatedSupplyPrice?.currency ?? CurrencyKind.Eur;

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					width: { xs: '90%', sm: DRAWER_WIDTH },
					padding: 5,
				},
			}}
		>
			<Stack direction="column" pl={2} spacing={3}>
				<Box>
					<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3} mb={3}>
						<IconButton onClick={onClose} sx={{ p: 0, m: 0 }}>
							<Stack direction="row" justifyContent="flex-end" alignItems="center">
								<CloseIcon sx={{ color: 'black' }} fontSize="small" />
							</Stack>
						</IconButton>
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
							mb={3}
						>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
								<Typography variant="h2">Edit shopping cart item</Typography>
							</Stack>
						</Stack>
					</Stack>
					<Stack direction="row" spacing={1}>
						<img src={imageField?.value?.url} alt="" width="150px" height="100px" style={{ objectFit: 'scale-down', }} />
						<Typography variant="h4">{nameField?.value}</Typography>
					</Stack>
				</Box>
				<Form onSubmit={onSubmit} initialValues={initialValues}>
					{({ handleSubmit, submitting, values, form }) => {
						return (
							<form onSubmit={handleSubmit}>
								<FormSpy
									// Submit form when match parameter type changes
									subscription={{
										dirtyFieldsSinceLastSubmit: true,
										pristine: true,
										hasValidationErrors: true,
									}}
									onChange={(props) => {
										const { dirtyFieldsSinceLastSubmit, pristine, hasValidationErrors } = props;

										const dirtyFields = Object.keys(dirtyFieldsSinceLastSubmit);
										const isPriceAffectingFieldDirty =
											dirtyFields.filter((f) => priceAffectingTechNames.includes(f))?.length > 0;

										if (isPriceAffectingFieldDirty && !pristine && !hasValidationErrors) {
											form.change('submit_type', 'calculate_price');
											form.submit();
										}
									}}
								/>
								<Stack direction="column" spacing={2}>
									{matchFields.map((mpf: any) => {
										console.log('mpf:', mpf);
										return (
											<ConditionalFieldInput
												key={mpf?.field_type.tech_name}
												fields={matchFields}
												conditions={mpf?.field_type?.conditions}
											>
												<FieldInput
													label={mpf.field_type?.ui_config?.label ?? mpf.field_type?.name}
													sharedObjects={sharedObjects}
													sharedObjectTypes={sharedObjectTypes}
													optionsUiConfig={mpf?.field_type?.ui_config?.options ?? undefined}
													helperText={mpf.field_type?.ui_config?.helper_text}
													fieldTechName={mpf.field_type.tech_name}
													disabled={mpf?.my_access !== FieldAccessKind.Write}
													inputType={mpf?.field_type?.input_type}
													required={mpf?.field_type?.is_required}
													inputOptions={mpf?.field_type.input_options}
													objectTypeFields={(mpf as any)?.object_type?.fields}
												/>
											</ConditionalFieldInput>
										);
									})}
								</Stack>
								{showPrice ? (
									calculatedSupplyPrice ? (
										<>
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">
													{`${calculatedSupplyPrice?.unit_price / 100} x ${calculatedSupplyPrice?.qty ?? 1
														}`}
												</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount:
															calculatedSupplyPrice?.unit_price *
															calculatedSupplyPrice?.qty,
														currency,
													})}
												</Typography>
											</Stack>

											{calculatedSupplyPrice?.addon_items &&
												calculatedSupplyPrice?.addon_items?.length > 0 &&
												calculatedSupplyPrice?.addon_items.map((add_on: any) => (
													<Stack
														direction="row"
														sx={{ width: '100%' }}
														justifyContent="space-between"
														alignItems="flex-start"
														spacing={2}
													>
														<Typography fontWeight="bold">{add_on?.description}</Typography>
														<Typography fontWeight="bold">
															{formatPriceAmount({
																amount: add_on?.value,
																currency,
															})}
														</Typography>
													</Stack>
												))}
											{calculatedSupplyPrice?.discount_items &&
												calculatedSupplyPrice?.discount_items?.length > 0 &&
												calculatedSupplyPrice?.discount_items.map((discount: any) => (
													<Stack
														direction="row"
														sx={{ width: '100%' }}
														justifyContent="space-between"
														alignItems="flex-start"
														spacing={2}
													>
														<Typography fontWeight="bold">
															{discount?.description}
														</Typography>
														<Typography fontWeight="bold">
															-
															{formatPriceAmount({
																amount: discount?.value,
																currency,
															})}
														</Typography>
													</Stack>
												))}
											{calculatedSupplyPrice?.platform_fee_items?.length > 0 &&
												calculatedSupplyPrice?.platform_fee_items.map((platform_fee: any) => (
													<Stack
														direction="row"
														sx={{ width: '100%' }}
														justifyContent="space-between"
														alignItems="flex-start"
														spacing={2}
													>
														<Typography fontWeight="bold">
															{platform_fee?.description}
														</Typography>
														<Typography fontWeight="bold">
															{formatPriceAmount({
																amount: platform_fee?.value,
																currency,
															})}
														</Typography>
													</Stack>
												))}
											<Divider flexItem />

											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">Total before taxes</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount: calculatedSupplyPrice?.total_before_tax,
														currency,
													})}
												</Typography>
											</Stack>

											{calculatedSupplyPrice?.tax_items?.length > 0 &&
												calculatedSupplyPrice?.tax_items.map((tax: any) => (
													<Stack
														direction="row"
														sx={{ width: '100%' }}
														justifyContent="space-between"
														alignItems="flex-start"
														spacing={2}
													>
														<Typography fontWeight="bold">{tax?.description}</Typography>
														<Typography fontWeight="bold">
															{formatPriceAmount({
																amount: tax?.value,
																currency,
															})}
														</Typography>
													</Stack>
												))}
											<Divider flexItem />
											<Stack
												direction="row"
												sx={{ width: '100%' }}
												justifyContent="space-between"
												alignItems="flex-start"
												spacing={2}
											>
												<Typography fontWeight="bold">Total</Typography>
												<Typography fontWeight="bold">
													{formatPriceAmount({
														amount:
															calculatedSupplyPrice?.total_before_tax +
															calculatedSupplyPrice?.total_taxes,
														currency,
													})}
												</Typography>
											</Stack>

											{consumerSupplyFees.length > 0 && (
												<>
													{consumerSupplyFees.map((consumer_fee: any) => (
														<Stack
															direction="row"
															sx={{ width: '100%' }}
															justifyContent="space-between"
															alignItems="flex-start"
															spacing={2}
														>
															<Typography fontWeight="bold">
																{consumer_fee?.description}
															</Typography>
															<Typography fontWeight="bold">
																{formatPriceAmount({
																	amount: consumer_fee?.value,
																	currency,
																})}
															</Typography>
														</Stack>
													))}
													<Divider flexItem />
													<Stack
														direction="row"
														sx={{ width: '100%' }}
														justifyContent="space-between"
														alignItems="flex-start"
														spacing={2}
													>
														<Typography fontWeight="bold">Adjusted total</Typography>
														<Typography fontWeight="bold">
															{formatPriceAmount({
																amount:
																	calculatedSupplyPrice?.total_before_tax +
																	calculatedSupplyPrice?.total_taxes +
																	calculatedSupplyPrice?.total_fees,
																currency,
															})}
														</Typography>
													</Stack>
												</>
											)}
										</>
									) : (
										<>
											<Skeleton width="100%" />

											<Skeleton width="100%" />
											<Skeleton width="100%" />
											<Skeleton width="100%" />
											<Divider flexItem />
											<Skeleton width="100%" />
											<Skeleton width="100%" />
											<Divider flexItem />
											<Skeleton width="100%" />
										</>
									)
								) : (
									<></>
								)}
								<br />
								<Button
									disabled={loading}
									type="submit"
									fullWidth
									color="primary"
									variant="contained"
									startIcon={<SaveOutlined />}
								>
									Apply changes
								</Button>
							</form>
						);
					}}
				</Form>
			</Stack>
		</Drawer>
	);
};

export default EditShoppingCartItemDrawer;
