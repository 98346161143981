import { print } from 'graphql';
import gql from 'graphql-tag';
import {
	QueryDiscoverConnectionParticipantsArgs,
	QueryMyConnectionArgs,
	QueryMyConnectionsArgs,
} from '../../types/generatedTypes';

const MY_CONNECTIONS = gql`
	query MY_CONNECTIONS($where: ConnectionFilter!) {
		myConnections(where: $where) {
			id
			status
			participant {
				id
				fields {
					... on SimpleField {
						simpleValue: value
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
					}
					... on ObjectField {
						my_access
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										id
										name
										tech_name
										input_type
										input_options
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
									}
								}
							}
						}
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
								input_options
							}
						}
					}

					... on ObjectSetField {
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										id
										name
										tech_name
										input_type
										input_options
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
									}
								}
							}
						}
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
							}
						}
					}
				}
			}
		}
	}
`;

const myConnections = ({ where }: QueryMyConnectionsArgs) => {
	return JSON.stringify({
		query: print(MY_CONNECTIONS),
		variables: { where },
	});
};
const MY_CONNECTION_TYPES = gql`
	query MY_CONNECTION_TYPES {
		myConnectionTypes {
			id
			name
			tech_name
			is_active
			participant_type {
				tech_name
				id
				name
				fields {
					id
					name
					tech_name
					input_options
					input_type
				}
			}
			participant_discovery_enabled
			fields {
				id
				name
				tech_name
			}
		}
	}
`;

const myConnectionTypes = () => {
	return JSON.stringify({
		query: print(MY_CONNECTION_TYPES),
	});
};
const DISCOVER_CONNECTION_PARTICIPANTS = gql`
	query DISCOVER_CONNECTION_PARTICIPANTS($connection_tech_name: String!, $where: ConnectionDiscoveryFilter!) {
		discoverConnectionParticipants(connection_tech_name: $connection_tech_name, where: $where) {
			id
			fields {
				... on SimpleField {
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									input_options
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									input_options
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
						}
					}
				}
			}
		}
	}
`;

const discoverConnectionParticipants = ({ connection_tech_name, where }: QueryDiscoverConnectionParticipantsArgs) => {
	return JSON.stringify({
		query: print(DISCOVER_CONNECTION_PARTICIPANTS),
		variables: { connection_tech_name, where },
	});
};
const MY_CONNECTION = gql`
	query MY_CONNECTION($id: ID!) {
		myConnection(id: $id) {
			id
			status
			established_at
			created_at
			accepted_at
			declined_at
			participant_accepted_at
			participant_declined_at
			backoffice_approved_at
			backoffice_rejected_at
			fields {
				... on SimpleField {
					my_access
					simpleValue: value
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
				}
				... on ObjectField {
					my_access
					objectValue: value {
						id_object
						fields {
							... on SimpleField {
								my_access
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									input_options
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
							input_options
						}
					}
				}

				... on ObjectSetField {
					objectSetValue: value {
						id_object
						fields {
							... on SimpleField {
								simpleValue: value
								field_type {
									id
									name
									tech_name
									input_type
									input_options
									ui_config {
										helper_text
										input_control
										label
										options {
											value
											label
										}
									}
								}
							}
						}
					}
					field_type {
						id
						name
						tech_name
						input_type
						input_options
						ui_config {
							helper_text
							input_control
							label
							options {
								value
								label
							}
						}
					}
					object_type {
						id
						name
						fields {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
							conditions {
								type
								payload
							}
						}
					}
				}
			}
			participant {
				id
				fields {
					... on SimpleField {
						simpleValue: value
						my_access
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
					}
					... on ObjectField {
						my_access
						objectValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									field_type {
										id
										name
										tech_name
										input_type
										input_options
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
									}
								}
							}
						}
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
								input_options
							}
						}
					}

					... on ObjectSetField {
						objectSetValue: value {
							id_object
							fields {
								... on SimpleField {
									simpleValue: value
									my_access
									field_type {
										id
										name
										tech_name
										input_type
										input_options
										ui_config {
											helper_text
											input_control
											label
											options {
												value
												label
											}
										}
									}
								}
							}
						}
						field_type {
							id
							name
							tech_name
							input_type
							input_options
							ui_config {
								helper_text
								input_control
								label
								options {
									value
									label
								}
							}
						}
						object_type {
							id
							name
							fields {
								id
								name
								tech_name
								input_type
								input_options
								ui_config {
									helper_text
									input_control
									label
									options {
										value
										label
									}
								}
								conditions {
									type
									payload
								}
							}
						}
					}
				}
			}
		}
	}
`;

const myConnection = ({ id }: QueryMyConnectionArgs) => {
	return JSON.stringify({
		query: print(MY_CONNECTION),
		variables: {
			id: String(id),
		},
	});
};

export { myConnections, myConnection, myConnectionTypes, discoverConnectionParticipants };
