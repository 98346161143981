import { Typography } from '@mui/material';
import { RdvDecimalFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldDecimalCellViewProps {
	value: RdvDecimalFieldValue;
}
const FieldDecimalCellView = ({ value }: FieldDecimalCellViewProps) => {
	if (value === null || value === undefined) return <Typography>-</Typography>;

	return <Typography variant="body2">{(value)}</Typography>;
};

export default FieldDecimalCellView;
