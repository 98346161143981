import LabelFilterInput from './LabelFilterInput';
import { Field, FormSpy } from 'react-final-form';
import { Checkboxes, TextField } from 'mui-rff';
import { Typography } from '@mui/material';
import { OnChange } from 'react-final-form-listeners';
import Grid from '@mui/material/Grid';
import { FilterInputBaseProps } from './types';

interface FilterInputMultiSelectProps extends FilterInputBaseProps {
	options: string[];
	optionsUiConfig?: { label: string, value: string }[];

}

const FilterInputMultiSelect = ({
	techName,
	label,
	noLabel,
	disabled = false,
	required = false,
	options,
	optionsUiConfig,
	useDeprecatedFilterInputs,
}: FilterInputMultiSelectProps) => {
	let checkboxData: any[] = [];

	options?.forEach((opt: string) => {
		const label = optionsUiConfig?.find((optionConfig) => optionConfig.value === opt)?.label;
		checkboxData.push({ label: label ?? opt, value: opt });
	});

	if (checkboxData === undefined) return null;

	return (
		<Grid container direction="column">
			{noLabel === false && <LabelFilterInput text={label ?? ''} htmlFor={techName} required={required} />}
			<Field name={`${techName}.min_hits`} subscription={{}}>
				{({ input: { onChange } }) => (
					<FormSpy subscription={{}}>
						{({ form }) => (
							<OnChange name={`${techName}.values`}>
								{(value: any) => {
									if (value.length === 0) {
										onChange('');
									}

									if (value.length === 1) {
										onChange(1);
									}
								}}
							</OnChange>
						)}
					</FormSpy>
				)}
			</Field>
			<Checkboxes name={`${techName}.values`} data={checkboxData} size="small" disabled={disabled} />
			{/* 
			 FIXME: VERIFY THIS CAN BE DELETED

			 export const clearLocationFilter = ([newEntry], state, { changeValue }) => changeValue(state, newEntry.key, () => undefined);


			<FormSpy subscription={{}}>
				{({ form }) => (
					<OnChange name={`${techName}.values`}>
						{(value: any) => {
							if (value.length === 0) {
								form.mutators.clearLocationFilter({ key: techName, value: undefined });
							}
						}}
					</OnChange>
				)}
			</FormSpy> */}
			{useDeprecatedFilterInputs && <>
				<Typography fontWeight={500}>How many options should be matched?</Typography>
				<TextField
					name={`${techName}.min_hits`}
					type="number"
					variant="outlined"
					size="small"
					disabled={disabled}
					fullWidth
					inputProps={{
						step: 1,
						max: options?.length,
						min: 1,
					}}
				/>
			</>}
		</Grid>
	);
};

export default FilterInputMultiSelect;
