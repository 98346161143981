import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import FieldDisplayValue from '../fields/display/FieldDisplayValue';
import { useTheme } from '@mui/material';
import ConditionalFieldDisplay from '../fields/display/ConditionalFieldDisplay';
import { Field } from '@rdv-fo/services/randevuApi/types/generatedTypes';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		bold: {
			fontWeight: 'bold',
		},
		fieldLabel: {
			height: theme.spacing(1),
		},
	};
});

interface ProviderPreviewProps {
	fields: Field[];
	title: string;
	boldValues?: boolean,
	removeBorder?: boolean,
	labelWidth?: any
	children?: JSX.Element | JSX.Element[] | null | undefined;
}
const ProviderPreview = ({ fields, title, children, boldValues = false, removeBorder = false, labelWidth }: ProviderPreviewProps) => {
	const classes = useStyles();
	const theme = useTheme();

	const fieldValues = {};
	fields?.forEach((field) => Object.assign(fieldValues, { [field?.field_type?.tech_name]: field?.value }))

	return (
		<Grid
			container
			direction="column"
			sx={{
				...(removeBorder === false && {
					borderRadius: 15,
					borderStyle: 'solid',
					borderColor: theme.palette.grey[300],
					borderWidth: 1,
					padding: 2,
				}),
			}}
			spacing={1}
		>
			<Grid item>
				<Typography variant="subtitle1">{title}</Typography>
			</Grid>

			<Grid item>
				<Grid container direction="column" spacing={1}>
					{fields?.map((field) => {
						return (
							<Grid item key={field?.field_type.tech_name}>
								<ConditionalFieldDisplay
									key={field?.field_type?.tech_name}
									fields={fields}
									values={fieldValues}
									conditions={field?.field_type?.conditions ?? []}
								>

									<FieldDisplayValue
										label={field?.field_type?.ui_config?.label ?? field?.field_type.name}
										labelProps={{ width: labelWidth, className: classes.fieldLabel }}
										fieldTechName={field?.field_type.tech_name}
										inputType={field?.field_type.input_type}
										inputOptions={field?.field_type.input_options}
										value={field?.value}
										optionsUiConfig={field?.field_type?.ui_config?.options as any}
										valueProps={{ className: boldValues ? classes.bold : undefined }}
									/>
								</ConditionalFieldDisplay>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
			{children ? <Grid item>{children}</Grid> : null}
		</Grid>
	);
};

export default ProviderPreview;
