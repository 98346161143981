import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MaterialReactTable, {
	MRT_FullScreenToggleButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleDensePaddingButton,
} from 'material-react-table';
import { RDV_NEUTRAL_10, RDV_NEUTRAL_100, RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { buildSharedObjectsTableColumns, buildSharedObjectsTableRows } from '@rdv-fo/app/lib/tableHelpers';
import { Object } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ROUTES from '@rdv-fo/common/routes';

const NoDataView = () => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
			<Typography variant="body2">No shared objects created</Typography>
		</Stack>
	);
};

interface SharedObjectsTableProps {
	sharedObjects: Object[];
	loading: boolean;
	disabled: boolean;
	typeFilter: any;
}

const SharedObjectsTable = ({ sharedObjects = [], loading, disabled, typeFilter }: SharedObjectsTableProps) => {
	const sharedObjectsTableColumns = buildSharedObjectsTableColumns(sharedObjects?.[0]?.fields ?? []);

	const sharedObjectsTableRows = buildSharedObjectsTableRows(sharedObjects);

	return (
		<>
			<MaterialReactTable
				initialState={{
					pagination: { pageSize: 50, pageIndex: 0 },
				}}
				columns={sharedObjectsTableColumns}
				enablePinning={false}
				data={sharedObjectsTableRows}
				enableTableHead={true}
				enableSorting={false}
				enablePagination={false}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnFilters={false} //disable all column filters
				enableColumnFilterModes={false}
				rowCount={sharedObjects?.length}
				muiTableBodyProps={{
					children: sharedObjects?.length < 1 ? <NoDataView /> : null,
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '0',
					},
				}}
				muiTableBodyRowProps={{
					sx: {
						'&:hover': {
							backgroundColor: RDV_NEUTRAL_20,
						},
					},
				}}
				enableFullScreenToggle={false}
				enableRowOrdering={false}
				enableHiding={true}
				muiTableHeadCellProps={{
					sx: {
						backgroundColor: RDV_NEUTRAL_10,
						fontSize: '0.889rem',
						fontWeight: 600,
						letterSpacing: 0,
						lineHeight: '1.4rem',
					},
				}}
				enableTableFooter={false}
				positionActionsColumn="first"
				enableRowActions={true}
				renderRowActions={({ row }) => {
					const sharedObjectId = row.original?._id?.value;

					return (
						<IconButton component={Link} to={ROUTES.UPDATE_MY_SHARED_OBJECT.replace(':id', sharedObjectId)} aria-label="view details" size="small">
							<EditOutlined fontSize="small" sx={{ color: 'black' }} />
						</IconButton>
					);
				}}
				renderTopToolbar={({ table }) => {
					return (
						<Stack direction="column" sx={{ mb: 2 }}>
							<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
								<Box overflow="scroll">
									<Stack
										direction="row"
										justifyContent="flex-end"
										alignItems="center"
										width="max-content"
									>
										{typeFilter}
									</Stack>
								</Box>
								<Stack direction="column">
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										<MRT_FullScreenToggleButton sx={{ color: RDV_NEUTRAL_100 }} table={table} />
										<MRT_ShowHideColumnsButton sx={{ color: RDV_NEUTRAL_100 }} table={table} />
										<MRT_ToggleDensePaddingButton sx={{ color: RDV_NEUTRAL_100 }} table={table} />
									</Stack>
								</Stack>
							</Stack>
							<Divider sx={{ color: RDV_NEUTRAL_20 }} />
						</Stack>
					);
				}}
				renderBottomToolbar={
					sharedObjects?.length > 0 &&
					(() => {
						let label = `${sharedObjects?.length} objects`;
						if (sharedObjects?.length === 1) label = `${sharedObjects?.length} object`;

						return (
							<Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
								{label}
							</Typography>
						);
					})
				}
			/>
		</>
	);
};

export default SharedObjectsTable;
