import { TextField } from 'mui-rff';
import { VALIDATION_MESSAGE } from '@rdv-fo/app/lib/validationMessages';
import { FieldInputBaseProps } from './types';

import LabelFieldInput from './LabelFieldInput';
import { parseToNull } from '@rdv-fo/app/lib/formHelpers';

const missingValue = ['', undefined, null];

interface TextInputOptions {
	required: boolean;
	pattern: string;
	format: 'URL' | 'EMAIL' | 'CUSTOM';
}

const validate = (value: string, options: TextInputOptions) => {
	const { required, pattern, format } = options;

	// if the value is not required and the user did not provide any value, we do not want to validate it
	// if the value is not required, we validate the value only if its provided
	if (!required && missingValue.includes(value)) return undefined;

	if (pattern) {
		const regex = new RegExp(pattern);

		const isValid = regex.test(value);

		if (!isValid) return VALIDATION_MESSAGE.REGEX_PATTERN_VIOLATION
	}

	if (required && (missingValue.includes(value) || !value?.trim()))
		return VALIDATION_MESSAGE.REQUIRED_VALUE_MISSING_VIOLATION;

	return undefined;
};

interface FieldInputSmartTextProps extends FieldInputBaseProps {
	pattern: string;
	format: 'URL' | 'EMAIL' | 'CUSTOM';
	keepNull?: boolean;
}
const FieldInputSmartText = ({
	techName,
	label,
	labelProps,
	helperText,
	disabled = false,
	required = false,
	pattern,
	format,
	keepNull = false,
}: FieldInputSmartTextProps) => {
	const fieldProps = {
		validate: (value: string) => validate(value, { required, pattern, format }),
		...(keepNull && { parse: parseToNull }),
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<LabelFieldInput
				text={label}
				htmlFor={techName}
				required={required}
				showOptional={labelProps?.showOptional}
			/>
			<TextField
				id={techName}
				name={techName}
				disabled={disabled}
				required={required}
				helperText={helperText}
				variant="outlined"
				size="small"
				margin="dense"
				fullWidth
				{...(format === 'EMAIL' && { type: 'email' })}
				{...(format === 'URL' && { type: 'url' })}
				fieldProps={fieldProps}
			/>
		</div>
	);
};

export default FieldInputSmartText;
