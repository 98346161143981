import gql from 'graphql-tag';
import { print } from 'graphql';
import {
	MutationTriggerManualTransactionTransitionArgs,
	MutationPrepareAndInitiateReverseAuctionTransactionArgs,
	MutationForceTransactionTerminationArgs,
	MutationOverrideTransactedMatchPriceArgs,
	MutationUpdateSingleDirectMatchForProviderArgs,
	MutationUpdateSingleDirectMatchArgs,
	MutationStartSingleDirectTransactionArgs,
	MutationCreateSingleDirectTransactionArgs,
	MutationAddSupplyToShoppingCartArgs,
	MutationCreateShoppingCartArgs,
	MutationRemoveShoppingCartItemArgs,
	MutationUpdateShoppingCartItemArgs,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';

import { FIELDS_WITH_FIELD_TYPE_FRAGMENT } from '../fragments';

const CREATE_SINGLE_DIRECT_TRANSACTION = gql`
	mutation CREATE_SINGLE_DIRECT_TRANSACTION($transaction_tech_name: String!, $id_supply: ID, $fields: [FieldInput!]) {
		createSingleDirectTransaction(
			transaction_tech_name: $transaction_tech_name
			id_supply: $id_supply
			fields: $fields
		) {
			id
		}
	}
`;

const createSingleDirectTransaction = ({
	transaction_tech_name,
	id_supply,
	fields,
}: MutationCreateSingleDirectTransactionArgs) => {
	return JSON.stringify({
		query: print(CREATE_SINGLE_DIRECT_TRANSACTION),
		variables: { transaction_tech_name, id_supply, fields },
	});
};

const START_SINGLE_DIRECT_TRANSACTION = gql`
	mutation START_SINGLE_DIRECT_TRANSACTION($id: ID!) {
		startSingleDirectTransaction(id: $id) {
			id
		}
	}
`;

const startSingleDirectTransaction = ({ id }: MutationStartSingleDirectTransactionArgs) => {
	return JSON.stringify({
		query: print(START_SINGLE_DIRECT_TRANSACTION),
		variables: { id },
	});
};

const PREPARE_AND_INITIATE_REVERSE_AUCTION_TRANSACTION = gql`
	mutation PREPARE_AND_INITIATE_REVERSE_AUCTION_TRANSACTION(
		$transaction_tech_name: String
		$supply_filter: [FieldFilterInput!]!
		$provider_filter: [FieldFilterInput!]!
		$consumer_filter: [FieldFilterInput!]!
		$fields: [FieldInput!]
	) {
		prepareAndInitiateReverseAuctionTransaction(
			transaction_tech_name: $transaction_tech_name
			supply_filter: $supply_filter
			consumer_filter: $consumer_filter
			provider_filter: $provider_filter
			fields: $fields
		) {
			id
		}
	}
`;

const prepareAndInitiateReverseAuctionTransaction = ({
	transaction_tech_name,
	supply_filter,
	provider_filter,
	consumer_filter,
	fields,
}: MutationPrepareAndInitiateReverseAuctionTransactionArgs) => {
	return JSON.stringify({
		query: print(PREPARE_AND_INITIATE_REVERSE_AUCTION_TRANSACTION),
		variables: { transaction_tech_name, supply_filter, provider_filter, consumer_filter, fields },
	});
};

const TRIGGER_MANUAL_TRANSACTION_TRANSITION = gql`
	mutation TRIGGER_MANUAL_TRANSACTION_TRANSITION($id_transaction: ID!, $transition_tech_name: String!) {
		triggerManualTransactionTransition(id_transaction: $id_transaction, transition_tech_name: $transition_tech_name)
	}
`;

const triggerManualTransactionTransition = ({
	id_transaction,
	transition_tech_name,
}: MutationTriggerManualTransactionTransitionArgs) => {
	return JSON.stringify({
		query: print(TRIGGER_MANUAL_TRANSACTION_TRANSITION),
		variables: { id_transaction, transition_tech_name },
	});
};

const FORCE_TRANSACTION_TERMINATION = gql`
	mutation FORCE_TRANSACTION_TERMINATION($id_transaction: ID!) {
		forceTransactionTermination(id_transaction: $id_transaction)
	}
`;
const forceTransactionTermination = ({ id_transaction }: MutationForceTransactionTerminationArgs) => {
	return JSON.stringify({
		query: print(FORCE_TRANSACTION_TERMINATION),
		variables: {
			id_transaction,
		},
	});
};

const OVERRIDE_MATCH_PRICE = gql`
	mutation OVERRIDE_MATCH_PRICE($id_transaction: ID!, $price: JSONObject!) {
		overrideTransactedMatchPrice(id_transaction: $id_transaction, price: $price)
	}
`;

const overrideTransactedMatchPrice = ({ id_transaction, price }: MutationOverrideTransactedMatchPriceArgs) => {
	return JSON.stringify({
		query: print(OVERRIDE_MATCH_PRICE),
		variables: { id_transaction, price },
	});
};

const UPDATE_DIRECT_MATCH_FOR_PROViDER = gql`
	mutation UPDATE_DIRECT_MATCH_FOR_PROViDER($id: ID!, $match_fields: [FieldInput!]) {
		updateSingleDirectMatchForProvider(id: $id, match_fields: $match_fields) {
			id
		}
	}
`;

const updateDirectMatchForProvider = ({ id, match_fields }: MutationUpdateSingleDirectMatchForProviderArgs) => {
	return JSON.stringify({
		query: print(UPDATE_DIRECT_MATCH_FOR_PROViDER),
		variables: { id, match_fields },
	});
};
const UPDATE_SINGLE_DIRECT_MATCH = gql`
	mutation UPDATE_DIRECT_MATCH($id: ID!, $match_fields: [FieldInput!]) {
		updateSingleDirectMatch(id: $id, match_fields: $match_fields) {
			id
		}
	}
`;

const updateSingleDirectMatch = ({ id, match_fields }: MutationUpdateSingleDirectMatchArgs) => {
	return JSON.stringify({
		query: print(UPDATE_SINGLE_DIRECT_MATCH),
		variables: { id, match_fields },
	});
};

const CREATE_SHOPPING_CART = gql`
	mutation CREATE_SHOPPING_CART($fields: [FieldInput!], $transaction_tech_name: String) {
		createShoppingCart(fields: $fields, transaction_tech_name: $transaction_tech_name) {
			id
			type {
				id
				tech_name
				root_type {
					... on MatchingToolType {
						match_type {
							supply_type {
								name
							}
						}
					}
					... on MatchType {
						supply_type {
							name
						}
					}
				}
				match_configuration {
					id
					name
					tech_name
					matching_unit_type
					availability_management_type
					match_parameter_types {
						name
						tech_name
						input_type
						input_options
						is_required
						initial_value
						ui_config {
							label
							helper_text
						}
					}
				}
			}
			fields {
				...FieldsFragment
			}
			state {
				status
				status_tech_name
				message
				next_steps {
					type
					action_message
					config
				}
			}
			items {
				id
			}
			created_at
			prepared_at
			initiated_at
			terminated_at
			cancelled_at
		}
	}
	${FIELDS_WITH_FIELD_TYPE_FRAGMENT}
`;

const createShoppingCart = ({ fields, transaction_tech_name }: MutationCreateShoppingCartArgs) => {
	return JSON.stringify({
		query: print(CREATE_SHOPPING_CART),
		variables: { fields, transaction_tech_name },
	});
};
const ADD_SUPPLY_TO_SHOPPING_CART = gql`
	mutation ADD_SUPPLY_TO_SHOPPING_CART($id_cart: ID!, $id_supply: ID!, $fields: [FieldInput!]) {
		addSupplyToShoppingCart(id_cart: $id_cart, id_supply: $id_supply, fields: $fields)
	}
`;

const addSupplyToShoppingCart = ({ id_cart, id_supply, fields }: MutationAddSupplyToShoppingCartArgs) => {
	return JSON.stringify({
		query: print(ADD_SUPPLY_TO_SHOPPING_CART),
		variables: { id_cart, id_supply, fields },
	});
};
const REMOVE_ITEM_FROM_SHOPPING_CART = gql`
	mutation REMOVE_ITEM_FROM_SHOPPING_CART($id_cart: ID!, $id_item: ID!) {
		removeShoppingCartItem(id_cart: $id_cart, id_item: $id_item)
	}
`;

const removeItemFromShoppingCart = ({ id_cart, id_item }: MutationRemoveShoppingCartItemArgs) => {
	return JSON.stringify({
		query: print(REMOVE_ITEM_FROM_SHOPPING_CART),
		variables: { id_cart, id_item },
	});
};

const UPDATE_SHOPPING_CART_ITEM = gql`
	mutation UPDATE_SHOPPING_CART_ITEM($id_cart: ID!, $id_item: ID!, $fields: [FieldInput!]!) {
		updateShoppingCartItem(id_cart: $id_cart, id_item: $id_item, fields: $fields)
	}
`;

const updateShoppingCartItem = ({ id_cart, id_item, fields }: MutationUpdateShoppingCartItemArgs) => {
	return JSON.stringify({
		query: print(UPDATE_SHOPPING_CART_ITEM),
		variables: { id_cart, id_item, fields },
	});
};

export {
	createSingleDirectTransaction,
	prepareAndInitiateReverseAuctionTransaction,
	triggerManualTransactionTransition,
	forceTransactionTermination,
	overrideTransactedMatchPrice,
	updateDirectMatchForProvider,
	updateSingleDirectMatch,
	startSingleDirectTransaction,
	createShoppingCart,
	addSupplyToShoppingCart,
	removeItemFromShoppingCart,
	updateShoppingCartItem,
};
