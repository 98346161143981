import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useHistory } from 'react-router';
import ROUTES from '@rdv-fo/common/routes';
import {
    InputKind,
    ShoppingCartLight,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import FieldView from '@rdv-fo/components/fields/tableCell/FieldView';
import routeBuilder from '@rdv-fo/common/routeBuilder';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TransactionMappers, MultiDirectTransactionRow } from '@rdv-fo/app/lib/transactionHelpers';
import { useMemo } from 'react';
import TransactionStatusChip from '../TransactionStatusChip';

import { DEFAULT_OPTIONS_MRT_TABLE, DEFAULT_STYLES_MRT_TABLE, } from '@rdv-fo/app/lib/tableHelpers';

const NoDataView = () => {
    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="subtitle1">No shopping carts yet</Typography>
            <Typography variant="body2">Your shopping carts will appear hear</Typography>
        </Stack>
    );
};

interface MultiDirectTransactionsTableProps {
    transactionTechName: string;
    transactions: ShoppingCartLight[];
    loading: boolean;
}

const MultiDirectTransactionsTable = ({ transactions, transactionTechName, loading }: MultiDirectTransactionsTableProps) => {

    const history = useHistory();

    const columns = useMemo<MRT_ColumnDef<MultiDirectTransactionRow>[]>(
        () => [
            {
                accessorKey: '_id',
                header: 'ID',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Text} value={data} />;
                },
            },
            {
                accessorKey: 'state',
                header: 'State',
                Cell: ({ cell, row }) => {
                    const data = cell.getValue<string>();
                    return (
                        <TransactionStatusChip
                            status={data}
                            createdAt={row.original?.created_at}
                            startedAt={row.original?.initiated_at}
                            cancelledAt={row.original?.cancelled_at}
                            terminatedAt={row.original?.terminated_at}
                        />
                    );
                },
            },
            {
                accessorKey: 'created_at',
                header: 'Created',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Datetime} value={data} />;
                },
            },
            {
                accessorKey: 'initiated_at',
                header: 'Started',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Datetime} value={data} />;
                },
            },
            {
                accessorKey: 'terminated_at',
                header: 'Finished',
                Cell: ({ cell }) => {
                    const data = cell.getValue<string>();
                    return <FieldView inputKind={InputKind.Datetime} value={data} />;
                },
            },


        ],
        []
    );
    const rows = useMemo(
        () => TransactionMappers.toMultiDirectTransactionRow(transactions),
        [transactions]
    );

    return (
        <>
            <MaterialReactTable
                {...DEFAULT_STYLES_MRT_TABLE}
                {...DEFAULT_OPTIONS_MRT_TABLE}
                initialState={{
                    columnVisibility: {
                        _id: false,
                    },
                    pagination: { pageSize: 50, pageIndex: 0 },
                    isLoading: loading
                }}
                columns={columns}
                enablePinning={false}
                data={rows}
                enableTableHead={true}
                enableSorting={false}
                enablePagination={false}
                enableDensityToggle={false}
                enableGlobalFilter={false}
                enableColumnFilters={false} //disable all column filters
                enableColumnFilterModes={false}
                rowCount={transactions?.length}
                muiTableBodyProps={{
                    children: transactions?.length < 1 ? <NoDataView /> : null,
                }}


                positionActionsColumn="last"
                enableRowActions={true}
                renderRowActions={({ row }) => {
                    const transactionId = row.original._id;
                    const terminated = row.original?.terminated_at;
                    const targetRoute = terminated ? routeBuilder(ROUTES.MULTI_DIRECT_TRANSACTION_DETAILS, [
                        [':transactionTypeTechName', transactionTechName],
                        [':transactionId', transactionId],
                    ]) : ROUTES.SHOPPING_CART;
                    return (
                        <IconButton onClick={() => history.push(targetRoute)}>
                            <ArrowForwardIcon />
                        </IconButton>
                    );
                }}
                enableFullScreenToggle={false}
                enableColumnActions={false}
                enableRowOrdering={false}
                enableHiding={true}
                enableTableFooter={false}
                renderBottomToolbar={
                    transactions?.length > 0 &&
                    (() => {
                        let label = `${transactions?.length} shopping carts`;
                        if (transactions?.length === 1) label = `${transactions?.length} shopping cart`;

                        return (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2, pl: 2, pr: 2 }}>
                                {label}
                            </Typography>
                        );
                    })
                }
            />
        </>
    );
};

export default MultiDirectTransactionsTable;
