import { signInAsGuest, reauthenticationRequired } from '@rdv-fo/store/slices/authSlice';
import { setReauthenticationCallback } from '@rdv-fo/store/slices/uiSlice';
import THUNKS from '@rdv-fo/store/thunkMap';
import type { AppDispatch, RootState } from '@rdv-fo/store/configureStore';

interface HandleSessionExpiredArgs {
	dispatch: AppDispatch;
	state: RootState;
	failedAction?: any;
	reauthenticationCallback?: {
		callbackThunkKey: string;
		payload?: any;
	};
}
export const handleSessionExpired = async ({
	dispatch,
	state,
	failedAction,
	reauthenticationCallback,
}: HandleSessionExpiredArgs) => {
	if (failedAction) dispatch(failedAction());

	const { currentUser } = state.auth;
	if (currentUser) {
		dispatch(setReauthenticationCallback(reauthenticationCallback));
		return dispatch(reauthenticationRequired());
	}

	await dispatch(signInAsGuest());

	if (!reauthenticationCallback?.callbackThunkKey) return;

	const thunkCallback = THUNKS[reauthenticationCallback.callbackThunkKey]?.getThunk();
	return dispatch(thunkCallback(reauthenticationCallback.payload));
};
