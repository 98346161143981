import { TextField } from 'mui-rff';
import { FieldInputBaseProps } from './types';

import LabelFieldInput from './LabelFieldInput';
import { Stack } from '@mui/material';

const FieldInputAutoIncrementId = ({ techName, label, labelProps, helperText }: FieldInputBaseProps) => {
	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1}>
			<LabelFieldInput text={label} htmlFor={techName} required={false} showOptional={labelProps?.showOptional} />

			<TextField
				id={techName}
				name={techName}
				disabled={true}
				required={false}
				variant="outlined"
				size="small"
				margin="dense"
				fullWidth
				helperText={helperText}
			/>
		</Stack>
	);
};

export default FieldInputAutoIncrementId;
