import { useState } from 'react';
import { Stack } from '@mui/material';

import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';

import { useAppDispatch } from '@rdv-fo/store/configureStore';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import LoadingButton from '@rdv-fo/components/common/LoadingButton';
import RandevuInputLabel from '@rdv-fo/components/common/RandevuInputLabel';
import { resetPassword, passwordResetFailed } from '@rdv-fo/store/slices/authSlice';
import FormError from '../FormError';
import { validators } from '@rdv-fo/app/lib/validation';

const ResetPasswordForm = ({ email, token, marketplace_name, form, title }) => {
	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useAppDispatch();

	const doSubmit = async (values) => {
		const resolvedAction = await dispatch(
			resetPassword({
				email,
				token,
				password: values.password,
				marketplace_name: marketplace_name,
			})
		);

		if (resolvedAction.type === passwordResetFailed.type) return resolvedAction.payload;
	};

	const fieldValidators = {
		password: [
			validators.required,
			validators.hasNumber,
			validators.minimum8chars,
			validators.hasUpperCase,
			validators.hasLowerCase,
			validators.hasSpecialChars,
		],
	};

	return (
		<Container maxWidth="sm">
			<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
				<Typography component="h1" variant="h1">
					{title}
				</Typography>
			</Stack>

			<br />
			<Form onSubmit={doSubmit} validate={(values) => validators.validate(fieldValidators, values)}>
				{({ handleSubmit, submitError, submitting, submitFailed, dirtySinceLastSubmit }) => {
					const submitButtonDisabled = submitting || (submitFailed && !dirtySinceLastSubmit);
					return (
						<form onSubmit={handleSubmit} noValidate method="POST" id="pass-reset-form">
							<Grid container spacing={2}>
								{submitError && (
									<Grid item xs={12}>
										<FormError message={submitError} />
									</Grid>
								)}
								<Grid item xs={12}>
									<RandevuInputLabel text={form?.field_password?.label} htmlFor="password" />
									<TextField
										id="password"
										name="password"
										type={showPassword ? 'text' : 'password'}
										variant="outlined"
										margin="normal"
										placeholder={form?.field_password?.placeholder}
										disabled={submitting}
										size="small"
										required
										fullWidth
										autoComplete="current-password"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword(!showPassword)}
														onMouseDown={() => setShowPassword(!showPassword)}
														size="large"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>

								<Grid item xs={12}>
									<LoadingButton
										type="submit"
										form="pass-reset-form"
										loading={submitting}
										disabled={submitButtonDisabled}
										fullWidth
									>
										{form?.button_submit?.label}
									</LoadingButton>
								</Grid>
							</Grid>
						</form>
					);
				}}
			</Form>
		</Container>
	);
};

export default ResetPasswordForm;
