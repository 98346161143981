import Typography from '@mui/material/Typography';
import { formatDateTimeHumanFriendly } from '@rdv-fo/app/lib/datetime';
import { RdvDateFieldValue } from '@rdv-fo/services/randevuApi/types/field';

interface FieldDateCellViewProps {
	value: RdvDateFieldValue;
}
const FieldDateCellView = ({ value }: FieldDateCellViewProps) => {
	return <Typography variant="body2">{value == null ? '-' : formatDateTimeHumanFriendly(value)}</Typography>;
};

export default FieldDateCellView;
