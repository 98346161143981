import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@rdv-fo/store/configureStore';
import React from 'react';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import {
	loadMyTransaction,
	selectMyTransaction,
	triggerManualTransition,
	selectLoading,
	selectTransactionTypes,
	selectMyParentTransaction,
} from '@rdv-fo/store/slices/transactionSlice';
import TransactionHeader from '@rdv-fo/components/transaction/TransactionHeader';
import FlowManualActionsPanel from '@rdv-fo/components/transaction/FlowManualActionsPanel';
import TransactionReverseAuction from '@rdv-fo/components/transaction/reverse-auction/TransactionReverseAuction';
import TransactionMatch from '@rdv-fo/components/transaction/TransactionMatch';
import { MatchTypeRoleKind, TransactionKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { Box } from '@mui/material';
import { usePrimitiveQueryParams } from '@rdv-fo/app/lib/useQueryParams';

const ReverseAuctionTransactionDetailsPage = () => {
	const dispatch = useAppDispatch();
	const { transactionId: transactionIdUrl, transactionTypeTechName } = useParams();
	const { parentTransactionId } = usePrimitiveQueryParams();

	const loading = useAppSelector(selectLoading);
	const transaction = useAppSelector(selectMyTransaction);
	const myTransactionTypes = useAppSelector(selectTransactionTypes);
	const parentTransaction = useAppSelector(selectMyParentTransaction);

	const [transactionLoaded, setTransactionLoaded] = useState(false);
	const [loadingSpinnerAllowed, setLoadingSpinnerAllowed] = useState(false);

	const transactionTechName = transaction?.type?.tech_name;
	const paymentTypes = parentTransaction?.type?.payment_types ?? [];

	const isSubTransaction = transaction?.type?.type === TransactionKind.SubTransaction;
	const isSingleDirect = transaction?.type?.type === TransactionKind.SingleDirect;

	const currentTransactionType = myTransactionTypes?.find((tt) => tt.tech_name === transactionTechName);

	const isMatchConfigurationPricingEnabled =
		currentTransactionType?.root_type?.match_type?.supply_type?.match_configurations?.[0]?.pricing_enabled;

	const isForceTerminationEnabled = currentTransactionType?.forced_termination_enabled;
	const hasTransactionBeenTerminatedForecdly = transaction?.forcedly_terminated;

	// Manually find out the field type of a supply filter field
	const supplyFilterFieldsWithoutType = transaction?.order?.supply_filter;
	const supplyFilterFieldsWithType = currentTransactionType?.root_type?.match_type?.supply_type?.fields
		?.map((fieldType) => ({
			field_type: fieldType,
			value: supplyFilterFieldsWithoutType?.find((filterField) => filterField.tech_name === fieldType.tech_name)
				?.value,
		}))
		.filter((supplyFilter) => supplyFilter.value !== null && supplyFilter.value !== undefined);

	useEffect(() => {
		let timeoutId;

		const asyncFn = async () => {
			setLoadingSpinnerAllowed(false);
			timeoutId = setTimeout(() => {
				setLoadingSpinnerAllowed(true);
			}, 500);
			setTransactionLoaded(false);
			await dispatch(loadMyTransaction({ id_transaction: transactionIdUrl }));

			setTransactionLoaded(true);
		};

		asyncFn();

		return () => {
			clearTimeout(timeoutId);
		};
	}, [dispatch, transactionIdUrl]);

	const transactionMatch = transaction?.matches?.[0];
	const matchFields = transactionMatch?.fields;
	const matchPriceOverrideEnabled =
		transactionMatch?.price_override_unlocked && MatchTypeRoleKind.Provider === transaction?.my_role;

	const handleTriggerManualTransition = (objectId, transitionTechName) => {
		dispatch(
			triggerManualTransition({
				id_transaction: objectId,
				transition_tech_name: transitionTechName,
			})
		);
	};

	return (
		<>
			{transactionLoaded ? (
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<TransactionHeader
							transactionId={transaction?.id}
							transactionTechName={transactionTechName}
							isForceTerminationEnabled={isForceTerminationEnabled}
							hasTransactionBeenTerminatedForecdly={hasTransactionBeenTerminatedForecdly}
							createdAt={transaction?.created_at}
							startedAt={transaction?.initiated_at}
							terminatedAt={transaction?.terminated_at}
							status={transaction?.state?.status}
						/>
						{(transaction?.state?.next_steps?.length > 0 || transaction?.state?.message) && (
							<FlowManualActionsPanel
								objectId={transaction?.id}
								nextSteps={transaction?.state?.next_steps}
								message={transaction?.state?.message}
								onTriggerManualTransition={handleTriggerManualTransition}
								loading={loading}
							/>
						)}
						{isSubTransaction || isSingleDirect ? (
							<TransactionMatch
								parentTransactionId={parentTransactionId}
								transactionId={transaction?.id}
								transactionStatus={transaction?.state?.status}
								transactionNextSteps={transaction?.state?.next_steps}
								matchId={transactionMatch?.id}
								matchFields={matchFields}
								matchingToolFields={transaction?.order?.fields}
								matchPrice={transactionMatch?.price}
								matchPriceOverrideEnabled={matchPriceOverrideEnabled}
								availabilityRecord={transaction?.order?.availability}
								participantMatchRole={transaction.my_role}
								consumerFields={transactionMatch?.consumer_fields}
								providerFields={transactionMatch?.provider_fields}
								supplyFields={transactionMatch?.supply_fields}
								supplyTypeName={transactionMatch?.supply?.type?.name}
								backofficeApprovedAt={transactionMatch?.backoffice_approved_at}
								consumerAcceptedAt={transactionMatch?.consumer_accepted_at}
								providerAcceptedAt={transactionMatch?.provider_accepted_at}
								backofficeRejectedAt={transactionMatch?.backoffice_rejected_at}
								consumerDeclinedAt={transactionMatch?.consumer_declined_at}
								providerDeclinedAt={transactionMatch?.provider_declined_at}
								transactionTypeTechName={transactionTypeTechName}
								parentTransactionTypeTechName={transactionTypeTechName}
								paymentTypes={paymentTypes}
								isMatchConfigurationPricingEnabled={isMatchConfigurationPricingEnabled}
							/>
						) : (
							<Box sx={{ mt: 4 }}>
								<TransactionReverseAuction
									transactionTypeTechName={transactionTypeTechName}
									transactionId={transactionIdUrl}
									participantMatchRole={transaction?.my_role}
									availabilityRequirement={transaction?.order?.availability}
									matchingToolFields={transaction?.order?.fields}
									supplyFilterFieldsWithType={supplyFilterFieldsWithType}
									subTransactions={transaction?.sub_transactions}
								/>
							</Box>
						)}
					</Grid>
				</Grid>
			) : (
				<Grid
					container
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'calc(100vh - 180px)',
					}}
				>
					{loadingSpinnerAllowed ? <CircularProgress /> : null}
				</Grid>
			)}
		</>
	);
};

export default ReverseAuctionTransactionDetailsPage;
