import { useLocation } from 'react-router';
import Stack from '@mui/material/Stack';
import arrayMutators from 'final-form-arrays';

import { Grid, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import FiltersContainer from '../FiltersContainer';

import { parseDiscoveryFilters } from '@rdv-fo/app/lib/url';
import { AvailabilityManagementKind, FieldType, MatchingUnitKind } from '@rdv-fo/services/randevuApi/types/generatedTypes';

interface DiscoveryFiltersProps {

	availabilityManagement: AvailabilityManagementKind,
	typeFieldTypes: FieldType[]
	matchingUnitType: MatchingUnitKind;
	disabled: boolean;
	numberOfSelectedFilters: number;
	useDeprecatedFilterInputs?: boolean;
	onDiscoverWithFilters: (values: any) => Promise<void>;
	onClearFilters: () => Promise<void>;
}
const DiscoveryFilters = ({ disabled, typeFieldTypes, matchingUnitType, availabilityManagement, numberOfSelectedFilters, useDeprecatedFilterInputs = true, onDiscoverWithFilters, onClearFilters }: DiscoveryFiltersProps) => {
	const location = useLocation();
	const queryParams = parseDiscoveryFilters(location.search);
	const filtersAvailable = typeFieldTypes.length > 0;

	const submitDiscoveryFilters = async (values: any) => {
		await onDiscoverWithFilters(values)
	};

	const handleClearFilterInputs = async (form: any) => {

		form.reset({});
		await onClearFilters()
	};

	return (
		<>
			{!filtersAvailable && (
				<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
					<Typography variant="subtitle1">
						You did not enable any filters for discovery within the given transaction type context
					</Typography>
					<Typography variant="subtitle2">Please use the Backoffice application to enable filters</Typography>
				</Stack>
			)}
			{filtersAvailable && (
				<Grid item xs={12}>
					<Form
						onSubmit={submitDiscoveryFilters}
						initialValues={queryParams}
						mutators={{
							...arrayMutators,
						}}
					>
						{({ handleSubmit, form }) => {
							return (
								<form onSubmit={handleSubmit}>
									<FiltersContainer
										showInputs={true}
										numberOfSelectedFilters={numberOfSelectedFilters}
										availabilityManagementKind={availabilityManagement}
										matchingUnitType={matchingUnitType}
										supplyFilters={typeFieldTypes}
										disabled={disabled}
										showClearBtn
										onDiscover={handleSubmit}
										onClearFilterInputs={() => {
											handleClearFilterInputs(form)
										}}
										useDeprecatedFilterInputs={useDeprecatedFilterInputs}
									/>
								</form>
							);
						}}
					</Form>
				</Grid>
			)}
		</>
	);
};

export default DiscoveryFilters;
