import { Paper, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { selectCurrentUser } from '@rdv-fo/store/slices/authSlice';
import { getFirstLastName } from '@rdv-fo/app/lib/fieldsHelper';

import { selectPlatformDisplayName } from '@rdv-fo/store/slices/platformSlice';
import { useAppSelector } from '@rdv-fo/store/configureStore';

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		paper: {
			padding: theme.spacing(6),
		},
	};
});
const UserRejected = () => {
	const classes = useStyles();
	const platformDisplayName = useAppSelector(selectPlatformDisplayName);

	const currentUser = useAppSelector(selectCurrentUser);
	const { firstName } = getFirstLastName(currentUser?.fields);

	return (
		<>
			<Container maxWidth="md">
				<Paper elevation={3} className={classes.paper}>
					<Typography>Hi {firstName}, </Typography>
					<br />
					<Typography display="inline">Unfortunately you cannot participate at</Typography>
					<Typography display="inline" color="primary">
						{` ${platformDisplayName}`}
					</Typography>
					<Typography display="inline"> because you are</Typography>
					<Typography display="inline" color="primary">
						{` rejected`}
					</Typography>
					<Typography display="inline"> by the platform's administration.</Typography>
					<br />
					<br />
					<Typography> For further information, please reach out to the platform's admin team.</Typography>
				</Paper>
			</Container>
		</>
	);
};

export default UserRejected;
