import { saveAs } from 'file-saver';

export const downloadAssetToHardDisk = async (url: string, file_name: string) => {
	const response = await fetch(url);

	if (response.status === 200) {
		const file_to_download = await response.blob();

		const blob = new Blob([file_to_download], {
			type: file_to_download.type,
		});

		return saveAs(blob, file_name);
	}

	if (response.status === 403) {
		return new Error(`Access denied to file "${file_name}"`);
	}

	return Error(`Unexpected error while downloading file "${file_name}"`);
};
