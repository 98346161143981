import Typography from '@mui/material/Typography';

interface FieldValueDecimalProps {
	value?: any; // FIXME: SET THE RIGHT PROPS ON THE BACKEND AND USE GENERATED TYPE IN HERE
	className: any;
	fieldTechName: string;
	unit?: string;
}

const FieldValueDecimal = ({ fieldTechName, unit, value, className }: FieldValueDecimalProps) => {
	return (
		<Typography id={fieldTechName} className={className} component="span">
			{value === null ? 'n.a.' : unit ? `${value} ${unit} ` : `${value} `}
		</Typography>
	);
};

export default FieldValueDecimal;
